import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { CapturaModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { CapturaModalComponent } from '../captura-modal/captura-modal.component';
import { CapturaService } from '../../domain/services/captura.service';
import { CapturaStatusType } from '../../domain/types';
import { CapturaComponent } from '../captura.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-captura-list-modal',
  templateUrl: './captura-list-modal.component.html'
})
export class CapturaListModalComponent extends CapturaComponent implements OnInit {
  @ViewChild('modal') modal: CapturaModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;  
    
  pacienteId: string;
  pacienteNome: string;

  constructor(
    injector: Injector,
    readonly service: CapturaService,
    readonly typesService: TypesService
  ) {
    super(injector, service);    
  }  

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.order = [[0, 'asc'], [1, 'asc']];
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if ((Number.isInteger(this.searchStatus.searchTerm)) && this.searchStatus.searchTerm >= 0) {
      dataTablesParameters.searches.push(this.searchStatus);
    }
    if (this.pacienteId) {
      dataTablesParameters.searches.push(new ModelSearch('pacienteId', OptionSearchType.Equals, SearchType.Guid, this.pacienteId));
    }
    (this.searchRegistroAtivo.searchTerm) && dataTablesParameters.searches.push(this.searchRegistroAtivo);
    dataTablesParameters.selectColumns = this.selectColumns;
    return dataTablesParameters;
  }

  show(pacienteId, pacienteNome) {
    this.pacienteId = pacienteId;
    this.pacienteNome = pacienteNome;
    this.searchStatus.searchTerm = -1;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();    
  }  
  
  showItem(model: CapturaModel) {    
    if (model.status === CapturaStatusType.Pendente) {
      this.edit(model);
    } else {
      this.detail(model);      
    }
  }
}
