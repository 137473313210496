import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { BaseCrudService } from "projects/ProjetoBaseAngular/domain/services/base-crud.service";
import { WhatsChatModel, WhatsMsgAtendenteDto } from "../models";

@Injectable({
  providedIn: 'root'
})
export class WhatsChatAtendenteService extends BaseCrudService<WhatsChatModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'WhatsChatAtendente';
  }

  criarChatAtendente(dto: WhatsMsgAtendenteDto): Observable<WhatsChatModel> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/CriarChatAtendente`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  enviarMensagem(chatId: string, dto: WhatsMsgAtendenteDto): Observable<boolean> {
    debugger
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/EnviarMensagem/${chatId}`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  enviarImagem(chatId: string, dto: WhatsMsgAtendenteDto): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/EnviarImagem/${chatId}`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  enviarDoc(chatId: string, dto: WhatsMsgAtendenteDto): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/EnviarDoc/${chatId}`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  finalizarChat(chatId: string, dto: WhatsMsgAtendenteDto): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/FinalizarChat/${chatId}`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  cancelarChat(chatId: string, dto: WhatsMsgAtendenteDto): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/CancelarChat/${chatId}`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getChatAtivoByFone(fone: string): Observable<WhatsChatModel> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetChatAtivoByFone/${fone}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  transferirChat(chatId: string, usuarioId: string): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/TransferirChat/${chatId}/${usuarioId}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  atualizarMsgVista(chatId: string): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/AtualizarMsgVista/${chatId}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}