import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ClassificacaoAgendamentoModalComponent } from './classificacao-agendamento-modal/classificacao-agendamento-modal.component';
import { ClassificacaoAgendamentoModel } from '../domain/models';
import { ClassificacaoAgendamentoService } from '../domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-classificacao-agendamento',
  templateUrl: './classificacao-agendamento.component.html'
})
export class ClassificacaoAgendamentoComponent extends BaseCrudComponent<ClassificacaoAgendamentoModel> implements OnInit {
  @ViewChild('modal') modal: ClassificacaoAgendamentoModalComponent;

  searchNome = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);  
  OptionSearchType: typeof OptionSearchType = OptionSearchType;
  
  selectColumns = [ 
    { data: 'nome'},
    { data: 'retorno'},
    { data: 'registroAtivo'}
  ];

  constructor(
    injector: Injector,
    readonly service: ClassificacaoAgendamentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    (this.searchNome.searchTerm) && searches.push(this.searchNome);

    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }
}
