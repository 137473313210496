<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Protocolo de Documento</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input matInput disabled [value]="form.value.codigo" type="text" />
                <mat-error>Informe um Código válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-10">
              <mat-form-field>
                <mat-label>Titulo</mat-label>
                <input matInput formControlName="titulo" type="text" />
                <mat-error>Informe um Titulo válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Detalhes</mat-label>
                <textarea matInput formControlName="detalhes" rows="4"></textarea>
                <mat-error>Informe um Detalhes válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <mat-form-field>
                <mat-label>Cód./CPF Paciente</mat-label>
                <input matInput [disabled]="detailMode"
                  [value]="paciente?.pessoa?.cnpjCpf" type="text"
                  (blur)="pacienteSelect.changeDocumento($event.target.value)" maxlength="18" required />
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.showSelect()" matTooltip="Selecionar Paciente">
                  <mat-icon>search</mat-icon>
                </button>
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.create()" matTooltip="Adicionar Paciente">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-md-7">
              <mat-form-field>
                <mat-label>Paciente</mat-label>
                <input matInput disabled [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia" type="text" />
                <button [disabled]="isBusy || !paciente?.id" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Responsavel</mat-label>
                <input matInput formControlName="responsavel" type="text" />
                <mat-error>Informe um Responsavel válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-slide-toggle matSuffix color="primary" formControlName="entregue">Entregue</mat-slide-toggle>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Data de Entrega</mat-label>
                <input *ngIf="form.value.entregue" matInput formControlName="dataEntrega" type="date" />
                <input *ngIf="!form.value.entregue" matInput disabled [value]="form.value.dataEntrega" type="date" />
                <mat-error>Informe um Data de Entrega válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Entregue Para</mat-label>
                <input *ngIf="form.value.entregue" matInput formControlName="entreguePara" type="text" />
                <input *ngIf="!form.value.entregue" matInput disabled [value]="form.value.entreguePara" type="text" />                
                <mat-error>Informe um Entregue Para válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>