import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { PushComponent } from './push.component';
import { PushModalComponent } from './push-modal/push-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PushComponent,
    PushModalComponent
  ]
})
export class PushModule { }
