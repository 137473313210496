import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { QuestionarioRespModel } from '../models';


@Injectable({
  providedIn: 'root'
})
export class QuestionarioRespService extends BaseCrudService<QuestionarioRespModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'questionarioResp';
  }

  getByQuestionarioId(id, dataInicial, dataFinal: string): Observable<QuestionarioRespModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByQuestionarioId/${id}`, {
        headers: this.getAuthHeaderJson().headers,
        params: { dataInicial, dataFinal }
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByQuestionarioFiltro(id, filtroId, dataInicial, dataFinal: string): Observable<QuestionarioRespModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByQuestionarioFiltro/${id}`, {
        headers: this.getAuthHeaderJson().headers,
        params: {filtroId, dataInicial, dataFinal }
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  enviar(model: QuestionarioRespModel): Observable<any> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Enviar`, JSON.stringify(model), this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
