import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AgendaEsperaComponent } from './agenda-espera.component';
import { AgendaEsperaModalComponent } from './agenda-espera-modal/agenda-espera-modal.component';
import { PacienteModule } from '../../paciente/paciente/paciente.module';

@NgModule({
  imports: [    
    SharedModule,
    PacienteModule
  ],
  declarations: [
    AgendaEsperaComponent,
    AgendaEsperaModalComponent
  ],
  exports: [
    AgendaEsperaComponent
  ]
})
export class AgendaEsperaModule { }
