import { NgModule } from "@angular/core";
import { GaleriaModule } from "projects/ProjetoBaseAngular/app/admin/galeria/galeria.module";
import { SharedModule } from "projects/ProjetoBaseAngular/app/shared.module";
import { EmpresaModalComponent } from "./empresa-modal/empresa-modal.component";
import { EmpresaComponent } from "./empresa.component";

@NgModule({
    imports: [
      SharedModule,
      GaleriaModule
    ],
    declarations: [
      EmpresaComponent,
      EmpresaModalComponent
    ]
  })
  export class EmpresaModule { }
  