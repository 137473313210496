import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { EstadoModel, CidadeModel, ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ArquivoService, CidadeService, EstadoService } from 'projects/ProjetoBaseAngular/domain/services';
import { PessoaType, SexoType } from 'projects/ProjetoBaseAngular/domain/types';
import { StatusCurriculoType } from 'projects/admin/src/domain/types';
import { CurriculoModel, VagaModel } from '../../domain/models';
import { CurriculoService, VagaService } from '../../domain/services';

@Component({
  selector: 'app-curriculo-modal',
  templateUrl: './curriculo-modal.component.html'
})
export class CurriculoModalComponent extends BaseCrudModalComponent<CurriculoModel> implements OnInit {
  @ViewChild('curriculoModal') modal: ModalDirective;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;


  estados: EstadoModel[];
  cidades: CidadeModel[];

  vagas: VagaModel[];
  file: any = {};

  tipos = [
    { text: 'Física', value: PessoaType.Fisica },
  ];

  sexos = [
    { text: 'Não Definido', value: SexoType.NaoDefinido },
    { text: 'Masculino', value: SexoType.Masculino },
    { text: 'Feminino', value: SexoType.Feminino }
  ];

  statusCurriculo = [
    { text: 'Pendente', value: StatusCurriculoType.Pendente },
    { text: 'Visto', value: StatusCurriculoType.Visto },
    { text: 'Contratado', value: StatusCurriculoType.Contratado },
    { text: 'Não Atende', value: StatusCurriculoType.NaoAtende }
  ];

  cpfMask = '000.000.000-00';
  cpfPlaceholder = '000.000.000-00';

  constructor(
    injector: Injector,
    readonly curriculoService: CurriculoService,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService,
    private readonly vagaService: VagaService,
    private readonly arquivoService: ArquivoService

  ) {
    super(injector, curriculoService);
  }

  protected initializeForm(curriculo: CurriculoModel) {
    this.form = this.formBuilder.group({
      id: curriculo.id,
      dataInclusao: [curriculo.dataInclusao, Validators.required],
      dataAlteracao: curriculo.dataAlteracao,
      observacoes: [curriculo.observacoes, Validators.maxLength(500)],
      cpf: [curriculo.cpf, [
        Validators.required,
        Validators.maxLength(18)]
      ],
      nome: [curriculo.nome, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(60)]
      ],
      sexo: [curriculo.sexo, Validators.required],
      site: [curriculo.site, [
        Validators.maxLength(50)]
      ],
      dataNascimento: [this.fromJsonDate(curriculo.dataNascimento)],
      imagemUrl: [curriculo.imagemUrl],
      imagemUrlBase: [curriculo.imagemUrlBase],
      imagemPath: [curriculo.imagemPath],
      celular: [curriculo.celular, [
        Validators.maxLength(15)]
      ],
      telefone: [curriculo.telefone, [
        Validators.maxLength(15)]
      ],
      email: [curriculo.email, [
        Validators.maxLength(50)]
      ],

      arquivoCurriculoUrl: [curriculo.arquivoCurriculoUrl],
      arquivoCurriculoUrlBase: [curriculo.arquivoCurriculoUrlBase],
      arquivoCurriculoPath: [curriculo.arquivoCurriculoPath],

      // arquivoCurriculoUrl: [curriculo.arquivoCurriculoUrl, [
      //   Validators.maxLength(200)]
      // ],
      // arquivoCurriculoFile: [curriculo.arquivoCurriculoFile],
      vagaId: [curriculo.vagaId],
      observacaoInterna: [curriculo.observacaoInterna, [
        Validators.maxLength(200)]
      ],
      areaAtuacao: [curriculo.areaAtuacao, [
        Validators.maxLength(60)]
      ],
      status: [curriculo.status],
      ruaAvenida: [curriculo.ruaAvenida, [
        Validators.minLength(5),
        Validators.maxLength(50)]
      ],
      numero: [curriculo.numero, Validators.maxLength(10)],
      complemento: [curriculo.complemento, Validators.maxLength(50)],
      bairro: [curriculo.bairro, Validators.maxLength(50)],
      cep: [curriculo.cep, Validators.maxLength(10)],
      estadoId: curriculo.estadoId,
      cidadeId: curriculo.cidadeId
    });

    console.log(this.form.value.arquivoCurriculoUrl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.vagaService.getAll().subscribe(vagas => this.vagas = vagas);
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  beforeShowCreate(model: CurriculoModel) {
    model = super.beforeShowCreate(model);
    model.sexo = SexoType.NaoDefinido;
    model.status = StatusCurriculoType.Pendente;
    return model;
  }

  beforeShowEdit(model: CurriculoModel) {
    model.estadoId && this.changeEstado(model.estadoId);
    return model;
  }

  changeEstado(estadoId: string) {
    this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
  }

  changeCep(cep: string) {
    if (cep) {
      this.commonService.queryPostalCode(cep).subscribe((endereco: any) => {
        this.form.patchValue({
          ruaAvenida: endereco.logradouro,
          bairro: endereco.bairro
        });

        const estadoId = this.estados.find(x => x.uf === endereco.uf).id;
        this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => {
          this.cidades = cidades;
          const cidadeId = this.cidades.find(x => x.codigoIbge === endereco.ibge).id;

          this.form.patchValue({
            estadoId, cidadeId
          });
        });
      });
    }
  }

  async save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    const curriculo: CurriculoModel = Object.assign({}, new CurriculoModel(), this.form.value);
    if (this.fileUpload.nativeElement.files.length != 0) {
      const fileUpload = this.fileUpload.nativeElement;
      this.file = { data: fileUpload.files[0], inProgress: true, completed: false };
      const formData = new FormData();
      formData.append('file', this.file.data);

      this.arquivoService.upload(curriculo.id, curriculo.id, curriculo.nome, formData).subscribe(
        (fileInfo) => {
          this.file.inProgress = false;
          this.file.completed = true;
          curriculo.arquivoCurriculoUrlBase = fileInfo.urlBase;
          curriculo.arquivoCurriculoPath = fileInfo.path;
          this.form.patchValue({
            arquivoCurriculoUrlBase: fileInfo.urlBase,
            arquivoCurriculoPath: fileInfo.path
          });
          super.save();
        },
        (errors) => {
          this.file.inProgress = false;
          this.errors = errors;
        });
    } else {
      super.save();
    }
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }
}
