<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
<div class="card">
  <div class="header">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>{{titulo}}</h2>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Inicial</mat-label>
          <input matInput [(ngModel)]="dataInicial" type="date" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Final</mat-label>
          <input matInput [(ngModel)]="dataFinal" type="date" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Modelo</mat-label>
          <mat-select [(ngModel)]="modeloRelatorio">
            <mat-option *ngFor="let item of listaModeloRelatorio" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Tipo</mat-label>
          <mat-select [(ngModel)]="tipoRelatorio">
            <mat-option *ngFor="let item of listaTipoRelatorio" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="modeloRelatorio === 1">
        <div class="card">
          <div class="header">
            <h2>
              <button mat-icon-button type="button" (click)="selectAll(1)" *ngIf="!convenioTotalSelect">
                <mat-icon>check_box_outline_blank</mat-icon>
              </button>
              <button mat-icon-button type="button" (click)="deSelectAll(1)" *ngIf="convenioTotalSelect">
                <mat-icon>check_box</mat-icon>
              </button>
              Convênios
            </h2>
          </div>
          <div class="body">
            <mat-selection-list #matListConvenio [(ngModel)]="listaConvenioIdSelecionado"
              (selectionChange)="onSelectionChange(1)" style="height: 200px; overflow: auto;">
              <mat-list-option *ngFor="let item of listaConvenio" checkboxPosition="before" color="primary"
                [value]="item.value">
                {{item.text}}
              </mat-list-option>
            </mat-selection-list>
            <hr>
            Total: {{ listaConvenioIdSelecionado.length }}
          </div>
        </div>
      </div>
      <div class="col-md-6"  *ngIf="modeloRelatorio === 3">
        <div class="card">
          <div class="header">
            <h2>
              <button mat-icon-button type="button" (click)="selectAll(2)" *ngIf="!medicoTotalSelect">
                <mat-icon>check_box_outline_blank</mat-icon>
              </button>
              <button mat-icon-button type="button" (click)="deSelectAll(2)" *ngIf="medicoTotalSelect">
                <mat-icon>check_box</mat-icon>
              </button>
              Médicos
            </h2>
          </div>
          <div class="body">
            <mat-selection-list #matListMedico [(ngModel)]="listaProfissionalIdSelecionado"
              (selectionChange)="onSelectionChange(2)" style="height: 200px; overflow: auto;">
              <mat-list-option *ngFor="let item of listaProfissional" checkboxPosition="before" color="primary"
                [value]="item.value">
                {{item.text}}
              </mat-list-option>
            </mat-selection-list>
            <hr>
            Total: {{ listaProfissionalIdSelecionado.length }}
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="modeloRelatorio === 2">
        <div class="card">
          <div class="header">
            <h2>
              <button mat-icon-button type="button" (click)="selectAll(3)" *ngIf="!equipamentoTotalSelect">
                <mat-icon>check_box_outline_blank</mat-icon>
              </button>
              <button mat-icon-button type="button" (click)="deSelectAll(3)" *ngIf="equipamentoTotalSelect">
                <mat-icon>check_box</mat-icon>
              </button>
              Equipamentos
            </h2>
          </div>
          <div class="body">
            <mat-selection-list #matListEquipamento [(ngModel)]="listaEquipamentoIdSelecionado"
              (selectionChange)="onSelectionChange(3)" style="height: 200px; overflow: auto;">
              <mat-list-option *ngFor="let item of listaEquipamento" checkboxPosition="before" color="primary"
                [value]="item.id">
                {{item.nomeApresentacao}}
              </mat-list-option>
            </mat-selection-list>
            <hr>
            Total: {{ listaEquipamentoIdSelecionado.length }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="header">
            <h2>
              <button mat-icon-button type="button" (click)="selectAll(4)" *ngIf="!procedimentoTotalSelect">
                <mat-icon>check_box_outline_blank</mat-icon>
              </button>
              <button mat-icon-button type="button" (click)="deSelectAll(4)" *ngIf="procedimentoTotalSelect">
                <mat-icon>check_box</mat-icon>
              </button>
              Procedimentos
            </h2>
          </div>
          <div class="body">
            <mat-selection-list #matListProcedimento [(ngModel)]="listaProcedimentoIdSelecionado"
              (selectionChange)="onSelectionChange(4)" style="height: 200px; overflow: auto;">
              <mat-list-option *ngFor="let item of listaProcedimento" checkboxPosition="before" color="primary"
                [value]="item.value">
                {{item.text}}
              </mat-list-option>
            </mat-selection-list>
            <hr>
            Total: {{ listaProcedimentoIdSelecionado.length }}
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12 text-center">
        <button mat-flat-button color="primary" [disabled]="isBusy || !isAllowed()" (click)="print()">
          <span>Gerar Relatório</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="text-center font-weight-bold">{{titulo}}</h4>
          <div class="row">
            <div class="col-md-9">
              <strong>Período:</strong> {{dataInicial | date: 'dd/MM/yyyy'}} à {{dataFinal | date: 'dd/MM/yyyy'}}
            </div>
            <div class="col-md-3">
              <strong>Data de Emissão:</strong> {{dataAtual}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <strong>Modelo:</strong> {{tituloModelo}} - {{tituloTipo}}
            </div>
          </div>
          <br>
          <div *ngIf="tipoRelatorio == 1">
            <table border="1" cellpadding="5" cellspacing="5" style="width: 100%;" *ngIf="listaImpressao.length > 0">
              <thead>
                <tr style="background-color: silver;">
                  <th class="text-center" colspan="2">{{colunaApresentacao}}</th>
                  <th class="text-center">Qtd.</th>
                  <th class="text-center">Qtd.(%)</th>
                  <th class="text-center">Valor Total</th>
                  <th class="text-center">Valor(%)</th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-item [ngForOf]="listaImpressao" let-isLast="last">
                  <tr>
                    <td colspan="2">
                      {{item.nome}}
                    </td>
                    <td class="text-right">
                      {{item.quantidade}}
                    </td>
                    <td class="text-right">
                      {{item.percentualQtd | currency: 'BRL':''}}
                    </td>
                    <td class="text-right">
                      {{item.valorTotal | currency: 'BRL':''}}
                    </td>
                    <td class="text-right">
                      {{item.percentualValor | currency: 'BRL':''}}
                    </td>
                  </tr>
                  <ng-container *ngIf="isLast && !contentPrinted">
                    {{ onContentPrinted() }}
                 </ng-container>
                </ng-template>
                <tr style="background-color: silver;">
                  <td class="text-center" colspan="2">
                    <strong>Total</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{qtdRegistros}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{100 | currency: 'BRL':''}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{totalGeral | currency: 'BRL':''}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{100 | currency: 'BRL':''}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="tipoRelatorio == 2">
            <table border="1" cellpadding="5" cellspacing="5" style="width: 100%;" *ngIf="listaImpressao.length > 0">
              <thead>
                <tr style="background-color: silver;">
                  <th class="text-center" colspan="2">Procedimento</th>
                  <th class="text-center">Qtd.</th>
                  <th class="text-center">Qtd.(%)</th>
                  <th class="text-center">Valor Total</th>
                  <th class="text-center">Valor(%)</th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-item [ngForOf]="listaImpressao" let-isLast="last">
                  <tr>
                    <td colspan="2">
                      <strong>{{colunaApresentacao}}: {{item.nome}}</strong>
                    </td>
                    <td class="text-right">
                      <strong>{{item.quantidade}}</strong>
                    </td>
                    <td class="text-right">
                      <strong>{{item.percentualQtd | currency: 'BRL':''}}</strong>
                    </td>
                    <td class="text-right">
                      <strong>{{item.valorTotal | currency: 'BRL':''}}</strong>
                    </td>
                    <td class="text-right">
                      <strong>{{item.percentualValor | currency: 'BRL':''}}</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let subItem of item.itens">
                    <td colspan="2">
                      {{subItem.procedimento}}
                    </td>
                    <td class="text-right">
                      {{subItem.quantidade}}
                    </td>
                    <td class="text-right">
                      {{subItem.percentualQtd | currency: 'BRL':''}}
                    </td>
                    <td class="text-right">
                      {{subItem.valorTotal | currency: 'BRL':''}}
                    </td>
                    <td class="text-right">
                      {{subItem.percentualValor | currency: 'BRL':''}}
                    </td>
                  </tr>
                  <ng-container *ngIf="isLast && !contentPrinted">
                    {{ onContentPrinted() }}
                 </ng-container>
                </ng-template>
                <tr style="background-color: silver;">
                  <td class="text-center" colspan="2">
                    <strong>Total</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{qtdRegistros}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{100 | currency: 'BRL':''}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{totalGeral | currency: 'BRL':''}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{100 | currency: 'BRL':''}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>