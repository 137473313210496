import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfissionalSaudeComponent } from './profissional-saude.component';
import { ProfissionalSaudeModalComponent } from './profissional-saude-modal/profissional-saude-modal.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { IconeModule } from '../../Icone/icone/icone.module';
import { AgendaDisponibilidadeModule } from '../../atendimento/agenda-disponibilidade/agenda-disponibilidade.module';
import { AgendaBloqueioModule } from '../../atendimento/agenda-bloqueio/agenda-bloqueio.module';
import { PerfilAnamneseModule } from '../../atendimento/perfil-anamnese/perfil-anamnese.module';
import { ProfissionalSaudeTextoPadraoModule } from '../profissional-saude-texto-padrao/profissional-saude-texto-padrao.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule,
    IconeModule,
    AgendaDisponibilidadeModule,
    AgendaBloqueioModule,
    PerfilAnamneseModule,
    ProfissionalSaudeTextoPadraoModule
  ],
  declarations: [
    ProfissionalSaudeComponent,
    ProfissionalSaudeModalComponent,
  ]
})
export class ProfissionalSaudeModule { }
