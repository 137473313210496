import { NgModule } from '@angular/core';


import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { PaginasComponent } from './paginas.component';
import { PaginaModalComponent } from './pagina-modal/pagina-modal.component';


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PaginasComponent,
    PaginaModalComponent
  ]
})
export class PaginasModule { }
