import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { CidadesComponent } from './cidades.component';
import { CidadeModalComponent } from './cidade-modal/cidade-modal.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    CidadesComponent,
    CidadeModalComponent
  ]
})
export class CidadesModule { }
