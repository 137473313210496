import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { LayoutMensagemComponent } from './layout-mensagem.component';
import { LayoutMensagemModalComponent } from './layout-mensagem-modal/layout-mensagem-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [    
    SharedModule,
    GaleriaModule,
  ],
  declarations: [
    LayoutMensagemComponent,
    LayoutMensagemModalComponent
  ]
})
export class LayoutMensagemModule { }
