import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';
import { PushModule } from './push/push.module';

@NgModule({
  imports: [
    SharedModule,
    PushModule
  ]
})
export class NotificacaoModule { }
