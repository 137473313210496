import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { AcaoModel, ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { AcaoService } from 'projects/ProjetoBaseAngular/domain/services';
import { AcaoModalComponent } from './acoes-modal/acao-modal.component';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-categoria-produtos',
  templateUrl: './acoes.component.html'
})
export class AcoesComponent extends BaseCrudComponent<AcaoModel> implements OnInit {
  @ViewChild('acaoModal') modal: AcaoModalComponent;

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'nome' },
    { data: 'descricao' },
    { data: 'codigo' }
  ];

  constructor(
    injector: Injector,
    readonly acaoService: AcaoService,
  ) {
    super(injector, acaoService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = [];
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  create() {
    Swal.fire({
      title: 'Não é possivel criar registros!',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      focusConfirm: true,
      confirmButtonText: 'Ok',
    });
  }
}
