import { Injectable } from '@angular/core';
import { BaseTypesService } from 'projects/ProjetoBaseAngular/domain/services/base-types.service';
import { OptionSearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { AgendamentoLogType, AgendamentoStatusParcialType, AgendamentoStatusType, AgendamentoType, AtendimentoRecebtoStatusType, AtendimentoStatusType, AtendimentoType, CapturaStatusType, CaracterAtendimentoType, ConfirmacaoLoteStatusType, DocSusType, LaudoStatusType, LayoutMesangemType, ProcedimentoServicoType, ProcedimentoTabelaType, ReceitaType, RecorrenciaPeriodoType, ViaType } from '../../app/admin/atendimento/domain/types';
import { ContatoPreAgendamentoStatusType, ContatoStatusType, TurnoType } from '../../app/admin/contato/domain/types';
import { PacienteProntuarioType, SangueType, TermoControleType, TermoStatusType} from '../types';
import { DayOfWeekType, EstadoCivilType, LocalPublicacaoType, PacienteIndicadoPorType, PesquisaQuestaoType, PesquisaStatusCorType, QuestionarioPosicaoCardType, RacaType, StatusPublicacaoType } from '../types';
import { WhatsChatMsgCampoType, SolicitacaoOnlineStatusType, WhatsChatStatusType, WhatsChatType, BotAtendimentoType, BotFluxoType, BotNodoType, ConfPresencaFilaStatusType } from '../../app/admin/atendimento-online/domain/types';

@Injectable({
  providedIn: 'root'
})
export class TypesService extends BaseTypesService {
  readonly MODULO_PORTAL = 'M1';
  readonly MODULO_ATENDIMENTO = 'M2';
  readonly MODULO_PESQUISA = 'M3';
  readonly MODULO_ATENDIMENTOONLINE = 'M10';

  AgendamentoStatusType: typeof AgendamentoStatusType = AgendamentoStatusType;
  AtendimentoStatusType: typeof AtendimentoStatusType = AtendimentoStatusType;
  DocSusType: typeof DocSusType = DocSusType;
  ReceitaType: typeof ReceitaType = ReceitaType;
  PesquisaQuestaoType: typeof PesquisaQuestaoType = PesquisaQuestaoType;
  StatusPublicacaoType: typeof StatusPublicacaoType = StatusPublicacaoType;
  LaudoStatusType: typeof LaudoStatusType = LaudoStatusType;
  CapturaStatusType: typeof CapturaStatusType = CapturaStatusType;

  usoFrequenteTypes = [
    { text: 'Uso Frequente', value: true },
    { text: 'Todos', value: false },
  ];

  racaType = [
    { text: 'Branca', value: RacaType.Branca },
    { text: 'Preta', value: RacaType.Preta },
    { text: 'Amarela', value: RacaType.Amarela },
    { text: 'Indígena', value: RacaType.Indigena }
  ];

  estadoCivilType = [
    { text: 'Solteiro(a)', value: EstadoCivilType.Solteiro },
    { text: 'Casado(a)', value: EstadoCivilType.Casado },
    { text: 'Separado(a)', value: EstadoCivilType.Separado },
    { text: 'Divorciado(a)', value: EstadoCivilType.Divorciado },
    { text: 'Viúvo(a)', value: EstadoCivilType.Viuvo },
  ];

  sangueType = [
    { text: 'Não Infor.', value: SangueType.NaoInformado },
    { text: 'A-', value: SangueType.Aneg },
    { text: 'A+', value: SangueType.Apos },
    { text: 'B-', value: SangueType.Bneg },
    { text: 'B+', value: SangueType.Bpos },
    { text: 'AB-', value: SangueType.ABneg },
    { text: 'AB+', value: SangueType.ABpos },
    { text: 'O-', value: SangueType.Oneg },
    { text: 'O+', value: SangueType.Opos }
  ];

  pacienteIndicadoPorType = [
    { text: 'Sem Indicação', value: PacienteIndicadoPorType.SemIndicacao },
    { text: 'Médico', value: PacienteIndicadoPorType.Medico },
    { text: 'Paciente', value: PacienteIndicadoPorType.Paciente },
    { text: 'Amigo', value: PacienteIndicadoPorType.Amigo },
    { text: 'Parente', value: PacienteIndicadoPorType.Parente },
    { text: 'Propaganda', value: PacienteIndicadoPorType.Propaganda },
  ];

  pacienteProntuarioType = [
    { text: 'Evolução', value: PacienteProntuarioType.Evolucao, color: 'bg-blue' },
    { text: 'Atestado', value: PacienteProntuarioType.Atestado, color: 'bg-orange' },
    { text: 'Receita', value: PacienteProntuarioType.Receita, color: 'bg-green' },
    { text: 'Solitação Exame', value: PacienteProntuarioType.SolitacaoExame, color: 'bg-purple' }
  ];

  agendamentoTypes = [
    { text: 'Agendamento', value: AgendamentoType.Agendamento, abreviacao: 'Age', color: 'bg-green' },
    { text: 'Retorno', value: AgendamentoType.Retorno, abreviacao: 'Ret', color: 'bg-blue' },
    { text: 'Encaixe', value: AgendamentoType.Encaixe, abreviacao: 'Enc', color: 'bg-orange' },
    { text: 'Recorrente', value: AgendamentoType.Recorrente, abreviacao: 'Rec', color: 'bg-purple' },    
  ];

  agendamentoStatusType = [
    { text: 'Disponível', value: AgendamentoStatusType.Disponivel, color: 'green' },
    { text: 'Agendado', value: AgendamentoStatusType.Agendado, color: '#2196F3' },
    { text: 'Confirmado', value: AgendamentoStatusType.Confirmado, color: 'blue' },
    { text: 'Lançado Entrada', value: AgendamentoStatusType.LancadoEntrada, color: 'brown' },
    { text: 'Atendido', value: AgendamentoStatusType.Atendido, color: 'indigo' },
    { text: 'Não Compareceu', value: AgendamentoStatusType.NaoCompareceu, color: 'purple' },
    { text: 'Encaixe', value: AgendamentoStatusType.Encaixe, color: '#FF5722' },
    { text: 'Cancelado', value: AgendamentoStatusType.Cancelado, color: 'red' },    
    { text: 'Bloqueado', value: AgendamentoStatusType.Bloqueado, color: 'gray' }
  ];

  agendamentoStatusParcialType = [
    { text: 'Pendente', value: AgendamentoStatusParcialType.Pendente, color: 'brown' },
    { text: 'Capturado', value: AgendamentoStatusParcialType.Capturado, color: 'orange' },
    { text: 'Laudado', value: AgendamentoStatusParcialType.Laudado, color: 'blue' },
    { text: 'Atendido', value: AgendamentoStatusParcialType.Capturado, color: 'indigo' }
  ];

  agendamentoLogType = [
    { text: 'Novo Registro', value: AgendamentoLogType.NovoRegistro, color: 'bg-green' },
    { text: 'Alteração', value: AgendamentoLogType.Alteracao, color: 'bg-orange' }
  ]; 
  
  //Código '\u00B1' representa o símbolo de mais ou menos
  recorrenciaPeriodoTypes = [
    { text: 'Semanal (7d)', value: RecorrenciaPeriodoType.Semanal },
    { text: '2 Semanas(14d)', value: RecorrenciaPeriodoType.Semana2 },
    { text: '4 Semanas(\u00B11 mês)', value: RecorrenciaPeriodoType.Semana4 },
    { text: '8 Semanas(\u00B12 meses)', value: RecorrenciaPeriodoType.Semana8 },
    { text: '12 Semanas(\u00B13 meses)', value: RecorrenciaPeriodoType.Semana12 },
    { text: '25 Semanas(\u00B16 meses)', value: RecorrenciaPeriodoType.Semana25 },
    { text: '52 Semanas(\u00B11 ano)', value: RecorrenciaPeriodoType.Semana52 }
  ];

  agendamentoStatusFiltroType = [
    { text: 'Disponível/Bloqueado', value: AgendamentoStatusType.Disponivel, color: 'green' },
    { text: 'Agendado', value: AgendamentoStatusType.Agendado, color: '#2196F3' },
    { text: 'Confirmado', value: AgendamentoStatusType.Confirmado, color: 'blue' },
    { text: 'Lançado Entrada', value: AgendamentoStatusType.LancadoEntrada, color: 'brown' },
    { text: 'Atendido', value: AgendamentoStatusType.Atendido, color: 'indigo' },
    { text: 'Não Compareceu', value: AgendamentoStatusType.NaoCompareceu, color: 'purple' },
    { text: 'Encaixe', value: AgendamentoStatusType.Encaixe, color: '#FF5722' },
    { text: 'Cancelado', value: AgendamentoStatusType.Cancelado, color: 'red' }    
  ];

  confirmacaoLoteStatusType = [
    { text: 'Não Executado', value: ConfirmacaoLoteStatusType.NaoExecutado, color: 'bg-blue' },
    { text: 'Sucesso-Enviado', value: ConfirmacaoLoteStatusType.Sucesso, color: 'bg-green' },
    { text: 'Sucesso-Fila', value: ConfirmacaoLoteStatusType.SucessoFila, color: 'bg-orange' },
    { text: 'Falha', value: ConfirmacaoLoteStatusType.Falha, color: 'bg-red' }
  ];

  atendimentoStatusType = [
    { text: 'Não Atendido', value: AtendimentoStatusType.NaoAtendido, color: 'purple' },
    { text: 'Esperando Atendimento', value: AtendimentoStatusType.EsperaAtendimento, color: 'orange' },
    { text: 'Em Atendimento', value: AtendimentoStatusType.EmAtendimento, color: 'blue' },
    { text: 'Atendido', value: AtendimentoStatusType.Atendido, color: 'green' },
    { text: 'Cancelado', value: AtendimentoStatusType.Cancelado, color: 'red' }
  ];

  atendimentoType = [
    { text: 'Atendimento', value: AtendimentoType.Atendimento, color: 'bg-blue', icon: 'calendar_month' },
    { text: 'Atendimento Não Agendado', value: AtendimentoType.NaoAgendado, color: 'bg-orange', icon: 'new_label' }
  ];

  localPublicacaoType = [
    { text: 'Noticia', value: LocalPublicacaoType.Noticia },
    { text: 'ResponsabilidadeSocial', value: LocalPublicacaoType.ResponsabilidadeSocial },
    { text: 'Blog', value: LocalPublicacaoType.Blog },
    { text: 'HotSite', value: LocalPublicacaoType.HotSite }
  ];

  procedimentoTabelaType = [
    { text: 'Brasindice', value: ProcedimentoTabelaType.Brasindice },
    { text: 'TUSS', value: ProcedimentoTabelaType.Tuss }
  ];

  procedimentoServicoType = [
    { text: 'Interno', value: ProcedimentoServicoType.Interno, color: 'bg-blue' },
    { text: 'Externo', value: ProcedimentoServicoType.Externo, color: 'bg-orange' }
  ];

  dayOfWeekType = [
    { text: 'Domingo', value: DayOfWeekType.Domingo },
    { text: 'Segunda', value: DayOfWeekType.Segunda },
    { text: 'Terça', value: DayOfWeekType.Terca },
    { text: 'Quarta', value: DayOfWeekType.Quarta },
    { text: 'Quinta', value: DayOfWeekType.Quinta },
    { text: 'Sexta', value: DayOfWeekType.Sexta },
    { text: 'Sábado', value: DayOfWeekType.Sabado }
  ];

  viaType = [
    { text: 'Uso Oral', value: ViaType.UsoOral },
    { text: 'Uso Intra-Muscular', value: ViaType.UsoIntraMuscular },
    { text: 'Uso Intra-Venoso', value: ViaType.UsoIntraVenoso },
    { text: 'Uso Subcutânea', value: ViaType.UsoSubcutanea },
    { text: 'Uso Subvenal', value: ViaType.UsoSubvenal },
    { text: 'Uso Transvermica', value: ViaType.UsoTransvermica },
    { text: 'Uso Tópico', value: ViaType.UsoTopico },
    { text: 'Uso Nasal', value: ViaType.UsoNasal },
    { text: 'Uso Ocular', value: ViaType.UsoOcular },
    { text: 'Uso Cutâneo', value: ViaType.UsoCutaneo },
    { text: 'Uso Retal', value: ViaType.UsoRetal },
    { text: 'Uso Inalatório', value: ViaType.UsoInalatorio },
    { text: 'Uso Externo', value: ViaType.UsoExterno },
    { text: 'Uso Interno', value: ViaType.UsoInterno }
  ];

  receitaType = [
    { text: 'Receita Normal', value: ReceitaType.Normal, color: 'green' },
    { text: 'Receita Controlada', value: ReceitaType.Controlada, color: 'blue' }
  ];

  docSusType = [
    { text: 'BPA-I', value: DocSusType.BpaI },
    { text: 'APAC', value: DocSusType.Apac },
    { text: 'Laudo TFD', value: DocSusType.LaudoTfd }
  ];

  caracterAtendimentoType = [
    { text: 'Ambulatorio', value: CaracterAtendimentoType.Ambulatorio },
    { text: 'Hospitalar', value: CaracterAtendimentoType.Hospitalar }
  ];

  laudoStatusType = [
    { text: 'Pendente', value: LaudoStatusType.Pendente, color: 'red' },
    { text: 'Laudado', value: LaudoStatusType.Laudado, color: 'green' },
    { text: 'Finalizado', value: LaudoStatusType.Finalizado, color: 'orange' }
  ];

  capturaStatusType = [
    { text: 'Pendente', value: CapturaStatusType.Pendente, color: 'red' },
    { text: 'Capturado', value: CapturaStatusType.Capturado, color: 'green' },
    { text: 'Finalizado', value: CapturaStatusType.Finalizado, color: 'orange' }
  ];

  pesquisaQuestaoType = [
    { text: 'Seleção', value: PesquisaQuestaoType.Selecao },
    { text: 'Multi-Seleção', value: PesquisaQuestaoType.MultiSelecao },
    { text: 'Descritivo', value: PesquisaQuestaoType.Descritivo}
  ];

  statusPublicacaoType = [
    { text: 'Rascunho', value: StatusPublicacaoType.Rascunho, color: 'bg-grey' },
    { text: 'Agendada', value: StatusPublicacaoType.Agendada, color: 'bg-orange' },
    { text: 'Publicada', value: StatusPublicacaoType.Publicada, color: 'bg-green' },
    { text: 'Encerrada', value: StatusPublicacaoType.Encerrada, color: 'bg-red' }
  ];

  pesquisaStatusCorType = [
    { text: 'Verde', value: PesquisaStatusCorType.Verde, color: 'bg-green', colorHex: '#4CAF50' },
    { text: 'Laranja', value: PesquisaStatusCorType.Laranja, color: 'bg-orange', colorHex: '#FF9800' },
    { text: 'Vermelho', value: PesquisaStatusCorType.Vermelho, color: 'bg-red', colorHex: '#F24B50'},
    { text: 'Púrpura', value: PesquisaStatusCorType.Purpura, color: 'bg-purple', colorHex: '#9C27B0'},
  ];

  artigoStatusType = [
    { text: 'Não Publicado', value: false, color: 'bg-grey'},
    { text: 'Publicado', value: true, color: 'bg-green'}
  ];

  filterOptions = [
    { text: 'Começando com', value: OptionSearchType.StartWith },
    { text: 'Contendo', value: OptionSearchType.Contains },
    { text: 'Terminando com', value: OptionSearchType.EndWith },
    { text: 'Igual', value: OptionSearchType.Equals },
    { text: 'Diferente', value: OptionSearchType.NotEquals },
  ];

  layoutMesangemType = [
    { text: 'Confirmação Agendamento (WhatsApp)', value: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, color: 'bg-green', colorHex: '#4CAF50' },
    { text: 'Pesquisa - Envio de Link', value: LayoutMesangemType.PesquisaEnvioLink, color: 'bg-orange', colorHex: '#FF9800' },
    { text: 'Pesquisa - Agradecimento', value: LayoutMesangemType.PesquisaAgradecimento, color: 'bg-orange', colorHex: '#FF9800' },
  ];

  layoutMensagemPalavrasChaves = [
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{linkConfirmacao}}', texto: 'Link para confirmação de agendamento.' },
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{data}}', texto: 'Data do agendamento.' },
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{horario}}', texto: 'Horário do agendamento.' },
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{pacienteNome}}', texto: 'Nome completo do paciente.' },
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{profissionalEquipamentoNome}}', texto: 'Nome completo do profissional ou descrição do equipamento.' },
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{empresaNome}}', texto: 'Nome da empresa/clínica ou consultório.' },
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{empresaEndereco}}', texto: 'Endereço completo da empresa.' },
    {tipo: LayoutMesangemType.AgendamentoConfirmacaoWhatsApp, chave: '{{empresaCelular}}', texto: 'Número do celular da empresa' },
  ];
  
  contatoStatusType = [
    { text: 'Pendente', value: ContatoStatusType.Pendente, color: 'bg-red' },
    { text: 'Encaminhado', value: ContatoStatusType.Encaminhado, color: 'bg-orange' },
    { text: 'Resolvido', value: ContatoStatusType.Resolvido, color: 'bg-green',}
  ];

  contatoPreAgendamentoStatusType = [
    { text: 'Pendente', value: ContatoPreAgendamentoStatusType.Pendente, color: 'bg-orange' },
    { text: 'Em Atendimento', value: ContatoPreAgendamentoStatusType.EmAtendimento, color: 'bg-blue' },
    { text: 'Concluído', value: ContatoPreAgendamentoStatusType.Concluido, color: 'bg-green'},
    { text: 'Cancelado', value: ContatoPreAgendamentoStatusType.Cacelado, color: 'bg-red' }
  ];

  turnoType = [
    { text: 'Matutino', value: TurnoType.Matutino},
    { text: 'Vespertino', value: TurnoType.Vespertino},
    { text: 'Noturno', value: TurnoType.Noturno}
  ];

  atendimentoRecebtoStatusType = [
    { text: 'Pendente', value: AtendimentoRecebtoStatusType.Pendente, color: 'bg-orange'},
    { text: 'Recebido', value: AtendimentoRecebtoStatusType.Recebido, color: 'bg-green'}
  ];

  questionarioPosicaoCardType = [
    { text: 'Centro', value: QuestionarioPosicaoCardType.Centro},
    { text: 'Esquerda', value: QuestionarioPosicaoCardType.Esquerda},
    { text: 'Direita', value: QuestionarioPosicaoCardType.Direita}
  ];

  termoPalavrasChaves = [
    {chave: '{{pacienteNome}}', texto: 'Nome completo do paciente.' },
    {chave: '{{pacienteCpf}}', texto: 'CPF do paciente.' },
    {chave: '{{pacienteEndereco}}', texto: 'Endereço completo do paciente.' },
    {chave: '{{pacienteCelular}}', texto: 'Número de telefone do paciente.' },
    {chave: '{{data}}', texto: 'Data do termo.' },
    {chave: '{{empresaNome}}', texto: 'Nome da empresa/clínica ou consultório.' },
    {chave: '{{empresaEndereco}}', texto: 'Endereço completo da empresa.' },
    {chave: '{{empresaCelular}}', texto: 'Número do celular da empresa' },
  ];

  botAtendimentoTypes = [
    { text: 'Não é Informado', value: BotAtendimentoType.NaoInformado, color: 'bg-yellow', colorHex: '#FCBA03'},
    { text: 'Consulta/Retorno', value: BotAtendimentoType.ConsultaRetorno, color: 'bg-green', colorHex: '#008000'},
    { text: 'Exames', value: BotAtendimentoType.Exames, color: 'bg-blue', colorHex: '#0000FF'},
    { text: 'Receitas', value: BotAtendimentoType.Receitas, color: 'bg-orange', colorHex: '#FFA500'},
    { text: 'Atestados', value: BotAtendimentoType.Atestados, color: 'bg-red', colorHex: '#FF0000'},
    { text: 'Atendente', value: BotAtendimentoType.Atendente, color: 'bg-purple', colorHex: '#A020F0'}
  ];

  botFluxoTypes = [
    { text: 'Não é Informado', value: BotFluxoType.NaoInformado, color: 'bg-yellow', colorHex: '#FCBA03'},
    { text: 'Iniciado pelo Cliente', value: BotFluxoType.IniciadoCliente, color: 'bg-orange', colorHex: '#FFA500'},
    { text: 'Confirmação de Presença', value: BotFluxoType.ConfirmacaoPresenca, color: 'bg-red', colorHex: '#FF0000'},
    { text: 'Pesquisa de Satisfação', value: BotFluxoType.PesquisaSatisfacao, color: 'bg-purple', colorHex: '#A020F0'},
    { text: 'Aceite de Termo', value: BotFluxoType.AceiteTermo, color: 'bg-green', colorHex: '#008000'}
  ];

  whatsChatMsgCampoType = [
    { text: 'Ignorado', value: WhatsChatMsgCampoType.Ignorado},
    { text: 'Solicitação - Nome Paciente', value: WhatsChatMsgCampoType.SolicNomePaciente},
    { text: 'Solicitação - Nome Médico', value: WhatsChatMsgCampoType.SolicNomeMedico},
    { text: 'Solicitação - Convênio', value: WhatsChatMsgCampoType.SolicConvenio},
    { text: 'Solicitação - Mensagem', value: WhatsChatMsgCampoType.SolicMensagem},
    { text: 'Conf.Presença - Confirmação', value: WhatsChatMsgCampoType.PresencaConfirmacao},
    { text: 'Conf.Presença - Motivo Cancel.', value: WhatsChatMsgCampoType.PresencaMotivoCanc},
    { text: 'Aceite Termo - Confirmação', value: WhatsChatMsgCampoType.AceiteTermoConfirmacao},
  ];

  whatsChatStatusType = [
    { text: 'Ativo', value: WhatsChatStatusType.Ativo, class: 'status-ativo'},
    { text: 'Finalizado', value: WhatsChatStatusType.Finalizado, class: 'status-finalizado'},
    { text: 'Cancelado', value: WhatsChatStatusType.Cancelado, class: 'status-cancelado'}
  ];

  whatsChatType = [
    { text: 'Bot', value: WhatsChatType.Bot, class: 'origem-bot'},
    { text: 'Atendente', value: WhatsChatType.Atendente, class: 'origem-atend'}
  ];

  botNodoTypes = [
    { text: 'Início Fluxo', value: BotNodoType.InicioFluxo, color: 'bg-red', 
      help:'Define o início de fluxo de etapas de atendimento. Somente apresenta mensagem e passa para próxima etapa'},
    { text: 'Mensagem', value: BotNodoType.Mensagem, color: 'bg-orange',
      help:'Somente apresenta mensagem e passa para próxima etapa, não coleta dados.'},
    { text: 'Menu', value: BotNodoType.Menu, color: 'bg-orange',
      help:'Apresenta menu/submenu aguarda e valida resposta. Encaminha para próxima etapa, não coleta dados.'},
    { text: 'Atendimento Início', value: BotNodoType.AtendimentoInit, color: 'bg-blue',
      help:'Define para o Bot que próximas etapas irão coletar dados de um tipo específico de atendimento. Apresenta mensagem e passa para próxima etapa.'},
    { text: 'Atendimento Item', value: BotNodoType.AtendimentoItem, color: 'bg-green',
      help:'Etapa de coleta de dados para o tipo de atendimento específico.'},
    { text: 'Atendimento Fim', value: BotNodoType.AtendimentoFim, color: 'bg-purple',
      help:'Define para o Bot que é o final do atendimento. Apresenta mensagem final e encerra o fluxo de atendimento.'}
  ];

  solicitacaoOnlineStatusTypes = [
    { text: 'Pendente + Em Atendimento', value: SolicitacaoOnlineStatusType.Pendente_EmAtendimento, color: 'bg-green', class: 'atend'},
    { text: 'Pendente', value: SolicitacaoOnlineStatusType.Pendente, color: 'bg-orange', class: 'pendente' }, 
    { text: 'Atend.', value: SolicitacaoOnlineStatusType.EmAtendimento, color: 'bg-green', class: 'atend'},
    { text: 'Finalizado', value: SolicitacaoOnlineStatusType.Finalizado, color: 'bg-brown', class: 'finalizado'},
    { text: 'Cancelado', value: SolicitacaoOnlineStatusType.Cancelado, color: 'bg-red', class: 'cancelado'}
  ];

  presencaFilaStatusType = [
    { text: 'Pendente', value: ConfPresencaFilaStatusType.Pendente, color: 'orange' },
    { text: 'Enviado', value: ConfPresencaFilaStatusType.Enviado, color: 'blue' },
    { text: 'Cancelado - Vencimento', value: ConfPresencaFilaStatusType.CanceladaVencida, color: 'brown' },
    { text: 'Cancelado - Manual', value: ConfPresencaFilaStatusType.CanceladaManual, color: 'red' }
  ];

  termoStatusType = [
    { text: 'Pendente', value: TermoStatusType.Pendente, color: 'bg-orange' },
    { text: 'Finalizado', value: TermoStatusType.Finalizado, color: 'bg-blue' },
  ];

  termoControleType = [
    { text: 'Bot', value: TermoControleType.Bot, color: 'bg-orange' },
    { text: 'App', value: TermoControleType.App, color: 'bg-blue' },
  ];

  getProcedimentoTabelaTypeNome(value: ProcedimentoTabelaType) {
    return this.procedimentoTabelaType.find(x => x.value === value)?.text;
  }

  getProcedimentoServicoTypeNome(value: ProcedimentoServicoType) {
    return this.procedimentoServicoType.find(x => x.value === value)?.text;
  }
  
  getProcedimentoServicoTypeColor(value: ProcedimentoServicoType) {
    return this.procedimentoServicoType.find(x => x.value === value)?.color;
  }

  getLocalPublicacaoType(value: LocalPublicacaoType) {
    return this.localPublicacaoType.find(x => x.value === value)?.text;
  }

  getDayOfWeekType(value: DayOfWeekType) {
    return this.dayOfWeekType.find(x => x.value === value)?.text;
  }

  getViaTypeNome(value: ViaType) {
    return this.viaType.find(x => x.value === value)?.text;
  }

  agendamentoTypeNome(value: AgendamentoType) {
    return this.agendamentoTypes.find(x => x.value === value)?.text;
  }

  agendamentoTypeAbrev(value: AgendamentoType) {
    return this.agendamentoTypes.find(x => x.value === value)?.abreviacao;
  }

  agendamentoTypeColor(value: AgendamentoType) {
    return this.agendamentoTypes.find(x => x.value === value)?.color;
  }
  
  getAgendamentoStatusType(value: AgendamentoStatusType) {
    return this.agendamentoStatusType.find(x => x.value === value)?.text;
  }

  getAgendamentoStatusColorType(value: AgendamentoStatusType) {
    return this.agendamentoStatusType.find(x => x.value === value)?.color;
  }

  getAgendamentoStatusParcialType(value: AgendamentoStatusParcialType) {
    return this.agendamentoStatusParcialType.find(x => x.value === value)?.text;
  }

  getAgendamentoStatusParcialColorType(value: AgendamentoStatusParcialType) {
    return this.agendamentoStatusParcialType.find(x => x.value === value)?.color;
  }

  getAgendamentoLogType(value: AgendamentoLogType) {
    return this.agendamentoLogType.find(x => x.value === value)?.text;
  }

  getAgendamentoLogColorType(value: AgendamentoLogType) {
    return this.agendamentoLogType.find(x => x.value === value)?.color;
  }

  recorrenciaPeriodoTypeNome(value: RecorrenciaPeriodoType) {
    return this.recorrenciaPeriodoTypes.find(x => x.value === value)?.text;
  }

  getConfirmacaoLoteStatusType(value: ConfirmacaoLoteStatusType) {
    return this.confirmacaoLoteStatusType.find(x => x.value === value)?.text;
  }

  getConfirmacaoLoteStatusTypeColor(value: ConfirmacaoLoteStatusType) {
    return this.confirmacaoLoteStatusType.find(x => x.value === value)?.color;
  }

  getAtendimentoStatusType(value: AtendimentoStatusType) {
    return this.atendimentoStatusType.find(x => x.value === value)?.text;
  }

  getAtendimentoStatusColorType(value: AtendimentoStatusType) {
    return this.atendimentoStatusType.find(x => x.value === value)?.color;
  }

  getAtendimentoType(value: AtendimentoType) {
    return this.atendimentoType.find(x => x.value === value)?.text;
  }

  getAtendimentoTypeColor(value: AtendimentoType) {
    return this.atendimentoType.find(x => x.value === value)?.color;
  }

  getAtendimentoTypeIcon(value: AtendimentoType) {
    return this.atendimentoType.find(x => x.value === value)?.icon;
  }
  
  getReceitaType(value: ReceitaType) {
    return this.receitaType.find(x => x.value === value)?.text;
  }

  getReceitaTypeColor(value: ReceitaType) {
    return this.receitaType.find(x => x.value === value)?.color;
  }

  getDocSusType(value: DocSusType) {
    return this.docSusType.find(x => x.value === value)?.text;
  }

  getCaracterAtendimentoType(value: CaracterAtendimentoType) {
    return this.caracterAtendimentoType.find(x => x.value === value)?.text;
  }

  getEstadoCivilType(value: EstadoCivilType) {
    return this.estadoCivilType.find(x => x.value === value)?.text;
  }

  getRacaType(value: RacaType) {
    return this.racaType.find(x => x.value === value)?.text;
  }

  getSangueType(value: SangueType) {
    return this.sangueType.find(x => x.value === value)?.text;
  }

  getPacienteProntuarioTypeNome(value: PacienteProntuarioType) {
    return this.pacienteProntuarioType.find(x => x.value === value)?.text;
  }

  getPacienteProntuarioTypeColor(value: PacienteProntuarioType) {
    return this.pacienteProntuarioType.find(x => x.value === value)?.color;
  }

  getLaudoStatusType(value: LaudoStatusType) {
    return this.laudoStatusType.find(x => x.value === value)?.text;
  }  

  getLaudoStatusTypeColor(value: LaudoStatusType) {
    return this.laudoStatusType.find(x => x.value === value)?.color;
  }  

  getCapturaStatusType(value: CapturaStatusType) {
    return this.capturaStatusType.find(x => x.value === value)?.text;
  }  

  getCapturaStatusTypeColor(value: CapturaStatusType) {
    return this.capturaStatusType.find(x => x.value === value)?.color;
  }  

  getPesquisaQuestaoType(value: PesquisaQuestaoType) {
    return this.pesquisaQuestaoType.find(x => x.value === value)?.text;
  }

  getStatusPublicacaoType(value: StatusPublicacaoType) {
    return this.statusPublicacaoType.find(x => x.value === value)?.text;
  }

  getStatusPublicacaoTypeColor(value: StatusPublicacaoType) {
    return this.statusPublicacaoType.find(x => x.value === value)?.color;
  }

  getPesquisaStatusCorType(value: PesquisaStatusCorType) {
    return this.pesquisaStatusCorType.find(x => x.value === value)?.text;
  }

  getPesquisaStatusCorTypeColor(value: PesquisaStatusCorType) {
    return this.pesquisaStatusCorType.find(x => x.value === value)?.color;
  }

  getPesquisaStatusCorTypeColorHex(value: PesquisaStatusCorType) {
    return this.pesquisaStatusCorType.find(x => x.value === value)?.colorHex;
  }

  getArtigoStatusType(value: boolean) {
    return this.artigoStatusType.find(x => x.value === value)?.text;
  }

  getArtigoStatusTypeColor(value: boolean) {
    return this.artigoStatusType.find(x => x.value === value)?.color;
  }

  getLayoutMesangemType(value: LayoutMesangemType) {
    return this.layoutMesangemType.find(x => x.value === value)?.text;
  }

  getLayoutMesangemTypeColor(value: LayoutMesangemType) {
    return this.layoutMesangemType.find(x => x.value === value)?.color;
  }

  getLayoutMesangemTypeColorHex(value: LayoutMesangemType) {
    return this.layoutMesangemType.find(x => x.value === value)?.colorHex;
  }

  getLayoutMensagemPalavrasChaves(tipo: LayoutMesangemType):any[]{
    return this.layoutMensagemPalavrasChaves.filter(x => x.tipo == tipo);
  }

  getContatoStatusType(value: ContatoStatusType) {
    return this.contatoStatusType.find(x => x.value === value)?.text;
  }

  getContatoStatusColor(value: ContatoStatusType) {
    return this.contatoStatusType.find(x => x.value === value)?.color;
  }

  getContatoPreAgendamentoStatusType(value: ContatoPreAgendamentoStatusType) {
    return this.contatoPreAgendamentoStatusType.find(x => x.value === value)?.text;
  }

  getContatoPreAgendamentoStatusColor(value: ContatoPreAgendamentoStatusType) {
    return this.contatoPreAgendamentoStatusType.find(x => x.value === value)?.color;
  }

  getTurnoType(value: TurnoType) {
    return this.turnoType.find(x => x.value === value)?.text;
  }

  getAtendimentoRecebtoStatusType(value: AtendimentoRecebtoStatusType) {
    return this.atendimentoRecebtoStatusType.find(x => x.value == value)?.text;
  }

  getAtendimentoRecebtoStatusColor(value: AtendimentoRecebtoStatusType) {
    return this.atendimentoRecebtoStatusType.find(x => x.value == value)?.color;
  }

  getQuestionarioPosicaoCardType(value: QuestionarioPosicaoCardType) {
    return this.questionarioPosicaoCardType.find(x => x.value == value)?.text;
  }

  getBotAtendimentoTypeNome(value: BotAtendimentoType) {
    return this.botAtendimentoTypes.find(x => x.value === value)?.text;
  }
  
  getBotAtendimentoTypeColor(value: BotAtendimentoType) {
    return this.botAtendimentoTypes.find(x => x.value === value)?.color;
  }

  getBotAtendimentoTypeColorHex(value: number) {
    return this.botAtendimentoTypes.find(x => x.value === value)?.colorHex;
  }

  getBotFluxoTypeNome(value: BotFluxoType) {
    return this.botFluxoTypes.find(x => x.value === value)?.text;
  }
  
  getBotFluxoTypeColor(value:BotFluxoType) {
    return this.botFluxoTypes.find(x => x.value === value)?.color;
  }

  getBotFluxoTypeColorHex(value: number) {
    return this.botFluxoTypes.find(x => x.value === value)?.colorHex;
  }

  getWhatsChatTypeNome(value: WhatsChatType) {
    return this.whatsChatType.find(x => x.value === value)?.text;
  }

  getWhatsChatTypeClass(value: WhatsChatType) {
    return this.whatsChatType.find(x => x.value === value)?.class;
  }
  
  getWhatsChatStatusTypeNome(value: WhatsChatStatusType) {
    return this.whatsChatStatusType.find(x => x.value === value)?.text;
  }

  getWhatsChatStatusTypeClass(value: WhatsChatStatusType) {
    return this.whatsChatStatusType.find(x => x.value === value)?.class;
  }
  
  getSolicitacaoOnlineStatusTypeNome(value: SolicitacaoOnlineStatusType) {
    return this.solicitacaoOnlineStatusTypes.find(x => x.value === value)?.text;
  }
  
  getSolicitacaoOnlineStatusTypeColor(value: SolicitacaoOnlineStatusType) {
    return this.solicitacaoOnlineStatusTypes.find(x => x.value === value)?.color;
  }

  getSolicitacaoOnlineStatusTypeClass(value: SolicitacaoOnlineStatusType) {
    return this.solicitacaoOnlineStatusTypes.find(x => x.value === value)?.class;
  }

  getWhatsChatMsgCampoTypeNome(value: WhatsChatMsgCampoType) {
    return this.whatsChatMsgCampoType.find(x => x.value === value)?.text;
  }

  getBotNodoTypeNome(value: BotNodoType) {
    return this.botNodoTypes.find(x => x.value === value)?.text;
  }
  
  getBotNodoTypeColor(value: BotNodoType) {
    return this.botNodoTypes.find(x => x.value === value)?.color;
  }  

  getBotNodoTypeHelp(value: BotNodoType) {
    return this.botNodoTypes.find(x => x.value === value)?.help;
  }  

  getPresencaFilaStatusType(value: ConfPresencaFilaStatusType) {
    return this.presencaFilaStatusType.find(x => x.value === value)?.text;
  }
  
  getPresencaFilaStatusTypeColor(value: ConfPresencaFilaStatusType) {
    return this.presencaFilaStatusType.find(x => x.value === value)?.color;
  }  

  getTermoStatusTypeNome(value: TermoStatusType) {
    return this.termoStatusType.find(x => x.value === value)?.text;
  }
  
  getTermoStatusTypeColor(value: TermoStatusType) {
    return this.termoStatusType.find(x => x.value === value)?.color;
  }

  getTermoControleTypeNome(value: TermoControleType) {
    return this.termoControleType.find(x => x.value === value)?.text;
  }
  
  getTermoControleTypeColor(value: TermoControleType) {
    return this.termoControleType.find(x => x.value === value)?.color;
  }
}
