<div>
  <img src="assets/previews/layout1/header.png" class="img-fluid img-responsive" style="width: 100%;">
</div>
<div [style.background-color]="colorSecondary">
  <img src="assets/previews/layout1/menu.png" class="img-fluid img-responsive" style="width: 100%;">
</div>
<div>
  <img src="assets/previews/layout1/banner.png" class="img-fluid img-responsive" style="width: 100%;">
</div>
<div style="padding: 0 35px; margin: 0 auto;">
  <div [style.background-color]="colorSecondary">
    <img src="assets/previews/layout1/service.png" class="img-fluid img-responsive" style="width: 100%;">
  </div>
  <div [style.background-color]="colorPrimary">
    <img src="assets/previews/layout1/specialty.png" class="img-fluid img-responsive" style="width: 100%;">
  </div>
  <div [style.background-color]="colorSecondary">
    <img src="assets/previews/layout1/post.png" class="img-fluid img-responsive" style="width: 100%;">
  </div>
  <div [style.background-color]="colorPrimary">
    <img src="assets/previews/layout1/exam.png" class="img-fluid img-responsive" style="width: 100%;">
  </div>
</div>
<div>
  <img src="assets/previews/layout1/others.png" class="img-fluid img-responsive" style="width: 100%;">
</div>
<div [style.background-color]="colorPrimary">
  <img src="assets/previews/layout1/footer.png" class="img-fluid img-responsive" style="width: 100%;">
</div>
<div [style.background-color]="colorSecondary">
  <img src="assets/previews/layout1/signature.png" class="img-fluid img-responsive" style="width: 100%;">
</div>