import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { LayoutImpressaoModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LayoutImpressaoService extends BaseCrudService<LayoutImpressaoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'LayoutImpressao';
  }

  getAll(): Observable<LayoutImpressaoModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
