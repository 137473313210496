<div bsModal #servicoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="servicoModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Serviços</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group align-center">
                <div style="height: 150px; width: 100%; object-fit: contain;">
                  <img src="{{form.value.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                    *ngIf="form.value.imagemUrl">
                </div>
                <div class="align-center">
                  <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                    <mat-icon>add_photo_alternate</mat-icon>
                  </button>
                  <button mat-icon-button type="button" [disabled]="!form.value.imagemUrl" (click)="deselectImage()"
                    matTooltip="Desvincular Imagem">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
                <p class="font-15 col-deep-blue text-center">
                  O tamanho ideal para Imagens é <strong>800x600</strong>
                </p>
              </div>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Tipo Serviço</mat-label>
                    <mat-select formControlName="tipoServicoId" #focus>
                      <mat-option *ngFor="let tipoServico of tiposServico" [value]="tipoServico.id">{{tipoServico.nome}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione um Tipo de Serviço.</mat-error>
                  </mat-form-field>
                </div>                
                <div class="col-md-8">
                  <mat-form-field>
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="nome" type="text" />
                    <mat-hint align="end">Máx 50 caracteres</mat-hint>
                    <mat-error>Informe um Nome válido.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>URL Saiba Mais</mat-label>
                    <input matInput formControlName="saibaMaisUrl" type="text" />
                    <mat-hint align="end">Máx 200 caracteres</mat-hint>
                    <mat-error>Informe uma URL válida.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Descrição</mat-label>
                    <textarea matInput #descricao formControlName="descricao" rows="8"></textarea>
                    <mat-hint align="end">{{descricao.value.length}}</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <section>
                    <mat-checkbox formControlName="exame" color="primary">
                      Exame
                    </mat-checkbox>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>