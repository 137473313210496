import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { BaseDocContainerModel } from 'projects/ProjetoBaseAngular/domain/models/doc-movimento/base-doc-container-model';
import { ProfissionalRecursoModel } from '../../equipe/domain/models';
import { AtendimentoStatusType, AgendamentoStatusType, ProcedimentoTabelaType, ViaType, QuestAnamneseRespostaType, ReceitaType, DocSusType, CaracterAtendimentoType, LaudoStatusType, CapturaStatusType, ProcedimentoServicoType, LayoutMesangemType, AgendamentoLogType, AtendimentoRecebtoStatusType, AgendamentoStatusParcialType, ConfirmacaoLoteStatusType, RecorrenciaPeriodoType, AgendamentoType } from './types';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { DayOfWeekType } from 'projects/admin/src/domain/types';

export class AgendaBloqueioModel extends BaseModel {
  public profissionalRecursoId: string;
  public profissionalRecursoNome: string;
  public dataInicial: Date;
  public dataFinal: Date;
  public horarioInicial: string;
  public horarioFinal: string;
  public observacao: string;
}

export class AgendaDisponibilidadeModel extends BaseModel {
  public profissionalRecursoId: string;
  public classificacaoId: string;
  public diaSemana: number;
  public diasSemana: number[];
  public horarioInicial: string;
  public horarioFinal: string;
  public duracao: string;
}

export class AgendaDisponibilidadeEventualModel extends BaseModel {
  public profissionalRecursoId: string;
  public classificacaoId: string;
  public data: Date;
  public horarioInicial: string;
  public horarioFinal: string;
  public duracao: string;
}

export class SalaAtendimentoModel extends BaseModel {
  public nome: string;
}
export class AtestadoDto {
  public atendimentoId: string;
  public data: Date;
  public atestado: string;
  public profissionalId: string;
  public profissionalNome: string;
  public profissionalCrm: string;
  public profissionalCrn: string;
  public profissionalRqe: string;
}

export class AtestadoConvertMaskDto {
  public pacienteCpf: string;
  public pacienteNome: string;
  public profissionalNome: string;
  public profissionalCrm: string;
  public profissionalCrn: string;
  public profissionalRqe: string;
  public data: string;
}

export class AgendamentoModel extends BaseModel {
  public tipo: AgendamentoType;
  public status: AgendamentoStatusType;
  public statusParcial: AgendamentoStatusParcialType;
  public profissionalRecursoId: string;
  public pacienteAnotacao: boolean;
  public pacienteId: string;
  public nomePaciente: string;
  public telefonePaciente: string;
  public celularPaciente: string;
  public convenioId: string;
  public classificacaoId: string;
  public salaId: string;
  public data: Date;
  public horario: string;
  public observacoes: string;
  public preAtendimento: string;
  public motivoCancelamento: string;
  public dataSolicConfirmacao: Date;
  public agendamentoRecorrenteId: string;
  public itemRecorrencia: number;
  public totalItensRecorrencia: number;
}

export class ConfirmacaoDto {
  public selecionado: boolean;
  public id: string;
  public profissionalRecursoId: string;
  public profissionalRecursoNome: string;
  public data: Date;
  public horario: string;
  public classificacaoId: string;
  public classificacaoNome: string;
  public status: AgendamentoStatusType;
  public pacienteId: string;
  public nomePaciente: string;
  public codigoPaciente: string;
  public celularPaciente: string;
  public dataSolicConfirmacao: Date;

  public statusExecucao: ConfirmacaoLoteStatusType;
  public motivoFalha: string;
  public chatId: string;
  public isReenvio: boolean;
}

export class AgendamentoLogModel extends BaseModel {
  public agendamentoId: string;
  public usuarioId: string;
  public usuarioNome: string;
  public terminal: string;
  public log: string;
  public tipo: AgendamentoLogType;
}

export class AgendamentoRecorrenteModel extends BaseModel {
  profissionalRecursoId: string;
  pacienteId: string;
  convenioId: string;
  classificacaoId: string;
  dataInicial: Date;
  diaSemana: DayOfWeekType;
  periodo: RecorrenciaPeriodoType;
  horario: string;
  totalItens: number;
  observacoes: string;
}


export class AtendimentoModel extends BaseDocContainerModel {
  public status: AtendimentoStatusType;
  public statusRecebto: AtendimentoRecebtoStatusType;
  public profissionalRecursoId: string;
  public profissionalSolicitanteId: string;
  public profissionalExecutanteId: string;
  public agendamentoId: string;
  public pacienteId: string;
  public convenioId: string;
  public matriculaConvenio: string;

  public dataHoraEntrada: Date;
  public dataHoraInicial?: Date;
  public dataHoraFinal?: Date;

  public numeroNotaFiscal?: number;
  public gerouNotaFiscal: boolean;
  public anamnese: string;
  public diagnostico: string;
  public atestado: string;
  public examesFisicos: string;
  public procedimentos: AtendimentoProcedimentoModel[];
  public medicamentos: AtendimentoMedicamentoModel[];
}

export class AtendimentoValidaDesfazerDto
{
    public atendimentoId: string;
    public agendamentoId: string;
    public codigo : number;
    public podeDesfazer: boolean; 
    public texto : string;
}

export class AtendimentoDiagnosticoModel extends BaseModel {
  public atendimentoId: string;
  public perfilAnamneseQuestId: string;
  public perfilAnamneseQuest: PerfilAnamneseQuestModel;
  public resposta: string;
  public observacoes: string;
}

export class ClassificacaoAgendamentoModel extends BaseModel {
  public nome: string;
  public retorno: boolean;
}

export class ClassificacaoDto extends SelectDto {
  public retorno: boolean;
}

export class GrupoProcedimentoModel extends BaseModel {
  public nome: string;
}

export class ProcedimentoModel extends BaseModel {
  public codigo: number;
  public nome: string;
  public exame: boolean;
  public exameEntregueAto: boolean;
  public usoFrequente: boolean;
  public tipoServico: ProcedimentoServicoType;
  public grupoId: string;
  //public grupoNome: string;
  //public convenios: ProcedimentoConvenioModel[];
}

export class ProcedimentoConvenioModel extends BaseModel {
  public procedimentoId: string;
  public convenioId: string;
  public convenioNome: string;
  public codigoTabela: string;
  public procedimentoTabela: ProcedimentoTabelaType;
  public valor: number;
  public percLaudo: number;
  public percClinica: number;
}

export class ProcedimentoConvenioProfissionalModel extends BaseModel {
  public procedimentoConvenioId: string;
  public profissionalSaudeId: string;
  public profissionalNome: string;
  public percLaudo: number;
  public percClinica: number;
}

export class ProcedimentoPerguntaModel extends BaseModel {
  public procedimentoId: string;
  public codigo: number;
  public pergunta: string;
  public resposta: string;
  public unidadePergunta: string;
  public unidadeResposta: string;
  public formula: string;
}

export class AtendimentoProcedimentoModel extends BaseModel {
  public atendimentoId: string;
  public procedimentoId: string;
  public procedimento: ProcedimentoModel;
  public vlrUnitario: number;
  public vlrAcrescimo: number;
  public vlrDesconto: number;
  public vlrTotal: number;
  public percClinica: number;
  public numGuia: string;
  public senhaGuia: string;
}

export class AtendimentoMedicamentoModel extends BaseModel {
  public atendimentoId: string;
  public medicamentoId: string;
  public medicamento: MedicamentoModel;
  public vlrUnitario: number;
  public vlrAcrescimo: number;
  public vlrDesconto: number;
  public vlrTotal: number;
}

export class MedicamentoModel extends BaseModel {
  public codigo: string;
  public descricao: string;
  public concentracao: string;
  public qtdEmbalagem: string;
  public via: ViaType;
  public vlrUnitario: number;
  public formaUso: string;
  public apresentacao: string;
  public principioAtivo: string;
  public usoFrequente: boolean;
}

export class MedicamentoDto {
  public id: string;
  public descricao: string;
  public usoFrequente: boolean;
}

export class GrupoAnamneseModel extends BaseModel {
  public nome: string;
}

export class QuestAnamneseModel extends BaseModel {
  public tipoResposta: QuestAnamneseRespostaType;
  public grupoAnamneseId: string;
  public grupoAnamnese: GrupoAnamneseModel;
  public respostas: QuestAnamneseRepostaModel[];
  public descricaoPergunta: string;
  public respostaPadrao: string;
}

export class QuestAnamneseRepostaModel extends BaseModel {
  public questAnamneseId: string;
  public seq: number;
  public pontos: number;
  public resposta: string;
}

export class PerfilAnamneseModel extends BaseModel {
  public profissionalRecursoId: string;
  public profissionalRecurso: ProfissionalRecursoModel;
  public nome: string;
}

export class PerfilAnamneseQuestModel extends BaseModel {
  public perfilAnamneseId: string;
  public seq: number;
  public questAnamneseId: string;
  public questAnamnese: QuestAnamneseModel;
}

export class AtendimentoReceitaModel extends BaseModel {
  public atendimentoId: string;
  public seq: number;
  public tipo: ReceitaType;
  public descricao: string;
}

export class AtendimentoSolicExameModel extends BaseModel {
  public atendimentoId: string;
  public seq: number;
  public descricao: string;
  //public itens: AtendimentoSolicExameItemModel[];
}

export class AtendimentoSolicExameItemModel extends BaseModel {
  public atendimentoSolicExameItemId: string;
  public procedimentoId: string;
  public procedimentoNome: string;
  public procedimentoCodigo: number;
  public detalhes: string;
  public resultado: string;
}
export class DocSusModel extends BaseModel {
  public dataSolicitacao: Date;
  public tipo: DocSusType;
  public pacienteId: string;
  public profissionalSolicitanteId: string;
  public profissionalAutorizadorId: string;
  public descricaoDiagnostico: string;
  public cid10Principal: string;
  public cid10Secundario: string;
  public cid10CausasAssociada: string;
  public resumoAnamnese: string;
  public justifProcedimento: string;
  public caracterAtendimento: CaracterAtendimentoType;
  public diagnosticoInicial: string;
  public historicoDoenca: string;
  public exameFisico: string;
  public diagnosticoProvavel: string;
  public examesComplementares: string;
  public tratamentoRealizado: string;
  public tratamentoSolicitado: string;
  public justifTratamento: string;
  public justifAcompanante: string;
  public transpRodoviario: boolean;
  public transpAereo: boolean;
  public transpAmbulancia: boolean;
  public justifTransp: string;
  public procedimentos: DocSusProcedimentoModel[];
}

export class DocSusConfigModel extends BaseModel {
  public codigoCnes: string;
  public tfdImagemUrl: string;
  public tfdImagemUrlBase: string;
  public tfdImagemPath: string;
}

export class DocSusProcedimentoModel extends BaseModel {
  public docSusId: string;
  public procedimentoId: string;
  public procedimentoCodigo: number;
  public procedimentoNome: string;
  public quantidade: number;
}

export class AgendaEsperaModel extends BaseModel {
  public profissionalRecursoId: string;
  public profissionalRecurso: ProfissionalRecursoModel;
  public pacienteId: string;
  public convenioId: string;
  public observacao: string;
}

export class LayoutLaudoModel extends BaseModel {
  public nome: string;
  public texto: string;
}

export class TermoModel extends BaseModel {
  public nome: string;
  public texto: string;
}
export class LayoutImpressaoModel extends BaseModel {
  public cabecalho: string;
  public rodape: string;
}

export class LaudoModel extends BaseModel {
  public status: LaudoStatusType;
  public profissionalSolicitanteId: string;
  public profissionalLaudanteId: string;
  public profissionalTecnicoId: string;
  public pacienteId: string;
  public convenioId: string;
  public atendimentoId: string;
  public procedimentoId: string;
  public layoutLaudoId: string;
  public cabecalho: string;
  public texto: string;
  public rodape: string;
  public tokenVersion: number[];
}

export class CapturaModel extends BaseModel {
  public status: CapturaStatusType;
  public atendimentoId: string;
  public pacienteId: string;
  public equipamentoId: string;
}

export class CapturaImagemModel extends BaseModel {
  public capturaId: string;
  public procedimentoId: string;
  public procedimentoNome: string;
  public sequencia: number;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;
  public imagemBase64: string;
}

export class AgendamentoFilterDto {
  public start: string;
  public end: string;
  public dateList: string;
  public recursoIds: string[];
  public pacienteId: string;
  public pacienteAnotacao: boolean;
  public nomePaciente: string;
  public status?: AgendamentoStatusType;
  public listaStatus: AgendamentoStatusType[];
  public naoFiltrarData: boolean;
  public naoFiltrarRecurso: boolean;
}

export class AtendimentoFilterDto {
  public data: string;
  public recursoIds: string[];
  public pacienteId: string;
  public status: AtendimentoStatusType[];
  public naoFiltrarData: boolean;
  public naoFiltrarRecurso: boolean;
}

export class EquipamentoModel extends BaseModel {
  //public profissionalRecurso: ProfissionalRecursoModel;
  public nomeApresentacao: string;
  public preRequisitos: string;
  public capturaImagem: boolean;
  public usaConfirmacaoLote: boolean;
}

export class LayoutMensagemModel extends BaseModel {
  public nome: string;
  public texto: string;
  public tipo: LayoutMesangemType;
}

export class ProtocoloDocModel extends BaseModel {
  public usuarioIdAlteracao: string;
  public codigo: number;
  public titulo: string;
  public detalhes: string;
  public responsavel: string;
  public entregue: boolean;
  public dataEntrega: Date;
  public entreguePara: string;
  public pacienteId: string;
}

export class EntregaExameModel extends BaseModel {
  public usuarioIdAlteracao: string;
  public atendimentoId: string;
  public procedimentoId: string;
  public entregue: boolean;
  public dataEntrega: Date;
  public entreguePara: string;
  public observacoes: string;
}

export class ResultadoExameDto {
  public datas: string[];
  public procedimentoIds: string[];
  public itens: ResultadoExameItemDto[];
}

export class ResultadoExameItemDto {
  public data: string;
  public procedimentoId: string;
  public procedimentoNome: string;
  public resultado: string;
}

export class ProfissionalRecursoSelectDto extends SelectDto {
  public usaConfirmacaoLote: boolean;
}
