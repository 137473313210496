<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card">
        <div class="header">
          <h4 class="modal-title">Dashboard - Resultado Exame</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Inicial</mat-label>
                <input matInput [(ngModel)]="dataInicial" type="date" max="2999-12-31">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Final</mat-label>
                <input matInput [(ngModel)]="dataFinal" type="date" max="2999-12-31">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Tipo de gráfico</mat-label>
                <mat-select [(ngModel)]="chartType">
                  <mat-option *ngFor="let item of chartTypes" [value]="item.value">{{item.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <button mat-flat-button color="primary" type="button" [routerLink]="" (click)="executarFiltro()" class="button-row">
                <span>Executar</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div style="display: block;" *ngIf="!isBusy && (listaResultado?.length > 0)">
                <canvas #canvas height="100" baseChart [data]="chartData" [datasets]="chartDatasets"
                  [labels]="chartLabels" [colors]="chartColors" [chartType]="chartType" 
                  [options]="chartOptions"
                  [plugins]="chartPlugins" [legend]="chartLegend">
                </canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-flat-button color="primary" type="button" [routerLink]="" (click)="print()" class="button-row">
            <span>Imprimir</span>
          </button>
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>