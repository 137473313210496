import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AgendaBloqueioService } from '../../domain/services';
import { AgendaBloqueioModel } from '../../domain/models';

@Component({
  selector: 'app-agenda-bloqueio-modal',
  templateUrl: './agenda-bloqueio-modal.component.html'
})
export class AgendaBloqueioModalComponent extends BaseCrudModalComponent<AgendaBloqueioModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  private recursoId: string;

  constructor(
    injector: Injector,
    service: AgendaBloqueioService
  ) {
    super(injector, service);
  }


  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: AgendaBloqueioModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      profissionalRecursoId: [this.recursoId, [
        Validators.required
      ]],
      dataInicial: [this.fromJsonDate(model.dataInicial), [
        Validators.required
      ]],
      dataFinal: [this.fromJsonDate(model.dataFinal), [
        Validators.required
      ]],
      horarioInicial: [model.horarioInicial, [
        Validators.required
      ]],
      horarioFinal: [model.horarioFinal, [
        Validators.required
      ]],
      observacao: [model.observacao, [
        Validators.maxLength(500)
      ]],
    });
  }

  async showCreate(model: AgendaBloqueioModel) {
    this.recursoId = model.profissionalRecursoId;
    super.showCreate(model);
  }

  async showEditByRecursoId(id, recursoId) {
    this.recursoId = recursoId;
    super.showEdit(id);
  }

  ValidarDtInicial(dt: Date): boolean {
    if (this.newItem) {
      if (!this.dataValida(dt, new Date())){
        this.commonService.mensagem("Data inicial inválida.", "Não é possível bloquear data menor que a atual.", "info");
        return false;
      }
    }
    return true;
  }

  ValidarDtFinal() {
    const model: AgendaBloqueioModel = Object.assign({}, this.form.value);
    if (!this.dataValida(model.dataFinal,model.dataInicial)) {
      this.commonService.mensagem("Data Final inválida.", "Não é possível bloquear período com data final menor que inicial.", "info");
      return false;
    }
    return true;
  }

  dataValida(data1: Date, data2: Date): boolean {
    console.log("validar data");
    const dataTeste = this.fromJsonDate(data1);
    const dataComp = this.fromJsonDate(data2);
    if (dataTeste < dataComp) {
      return false;
    }
    return true;
  }


  async save() {
    const model: AgendaBloqueioModel = Object.assign({}, this.form.value);
    if (!this.ValidarDtInicial(model.dataInicial) || !this.ValidarDtFinal()){
      return;
    }

    super.save()
  }

}
