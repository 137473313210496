<div [innerHTML]="sanitizer.bypassSecurityTrustHtml(layoutImpressao?.cabecalho)">
</div>

<!-- <div class="row mb-4">
  <div class="col">
    <img [src]="tenant?.imagemUrl" height="250" />
  </div>
  <div class="col text-right">
    <p class="m-0 p-0 mt-1 font-weight-bold">Dra. Ana Cláudia D. Rodrigues</p>
    <p class="m-0 p-0"><small>CRM-SC 9234 / RQE 6763</small></p>

    <p class="m-0 p-0 mt-1 font-weight-bold">Dr. Mauricio Pucineli Parada</p>
    <p class="m-0 p-0"><small>CRM-SC 9234 / RQE 6763</small></p>

    <p class="m-0 p-0 mt-1 font-weight-bold">Dr. Nathan de Farias Faraco</p>
    <p class="m-0 p-0"><small>CRM-SC 9234 / RQE 6763</small></p>

    <p class="m-0 p-0 mt-1 font-weight-bold">Dr. Rafael Lichtenfels Schmitz</p>
    <p class="m-0 p-0"><small>CRM-SC 9234 / RQE 6763</small></p>

    <p class="m-0 p-0 mt-1 font-weight-bold">Dr. Ricardo Abrao Wyse</p>
    <p class="m-0 p-0"><small>CRM-SC 9234 / RQE 6763</small></p>

    <p class="m-0 p-0 mt-1 font-weight-bold">Dr. Ricardo Pereira da Silva</p>
    <p class="m-0 p-0"><small>CRM-SC 9234 / RQE 6763</small></p>

    <p class="m-0 p-0 mt-1 font-weight-bold">Dra. Marcia Regina Pereira</p>
    <p class="m-0 p-0"><small>CRM-SC 9234 / RQE 6763</small></p>
    <p class="m-0 p-0"><small>Responsável Tecnica</small></p>
  </div>
</div> -->