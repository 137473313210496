import { Component, Injector, OnInit } from '@angular/core';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { AgendamentoFilterDto, ConfirmacaoDto } from '../../domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { ProfissionalRecursoService } from '../../domain/services/profissional-recurso.service';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { ChatBotNodoService } from '../../../atendimento-online/domain/services';
import { AgendamentoService, ClassificacaoAgendamentoService } from '../../domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ChatBotNodoModel, ConfirmacaoPresencaFilaModel, ListaConfPresencaFilaDto } from '../../../atendimento-online/domain/models';
import * as moment from 'moment';
import { AgendamentoStatusType, ConfirmacaoLoteStatusType } from '../../domain/types';
import { ParseKeyPair, ParseMensagem } from './parse-mensagem';
import { WhatsChatService } from '../../../atendimento-online/domain/services/whatschat.service';
import { BotFluxoType, ConfPresencaFilaStatusType } from '../../../atendimento-online/domain/types';
import { ConfirmacaoPresencaFilaService } from '../../../atendimento-online/domain/services/confirmacao-presenca-fila.service';
import { PacienteModel } from '../../../paciente/domain/models';


@Component({
  selector: 'app-confirmacao-lote',
  templateUrl: './confirmacao-lote.component.html'
})
export class ConfirmacaoLoteComponent extends BaseComponent implements OnInit {
  isBusy: boolean = false;
  dataExecutar: string;

  listaConfirmacao: ConfirmacaoDto[] = [];

  listaRecursos: SelectDto[];
  listaClassificaoAgend: SelectDto[];
  totalSelect: boolean;

  filaNumeroWhats: string;
  filaStatus: ConfPresencaFilaStatusType;
  paciente: PacienteModel;

  listaFila: ConfirmacaoPresencaFilaModel[] = [];

  qtdSucesso: number;
  qtdFalha: number;

  constructor(
    injector: Injector,
    readonly service: AgendamentoService,
    readonly pacienteService: PacienteService,
    readonly profissionalRecursoService: ProfissionalRecursoService,
    readonly tenantService: TenantService,
    readonly chatBotNodoService: ChatBotNodoService,
    readonly whatsChatService: WhatsChatService,
    readonly classificacaoAgendamentoService: ClassificacaoAgendamentoService,
    readonly confirmacaoFilaService: ConfirmacaoPresencaFilaService,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.isAllowed()) {
      this.commonService.mensagem("Acesso Negado", "Verifique se você possuí acesso ou contratou este módulo", "warning");
      return;
    }

    this.filaStatus = ConfPresencaFilaStatusType.Pendente;
  }

  isAllowed(): boolean {
    //return super.isAllowed("Read", "Faturamento");
    return true;
  }

  async onDataExit(): Promise<boolean> {
    if (!moment(this.dataExecutar).isValid()){
      await this.commonService.mensagem("Informe uma data válida", "", "warning");
      this.dataExecutar = "";
      return false;
    }
    
    let dataExec = moment(this.dataExecutar).toDate();
    let dias = this.commonService.getDiasDiferenca(new Date(), dataExec);

    if (dias < 0) {
      this.commonService.mensagem("Data Inválida", "Somente datas do dia ou futuras podem ser utilizadas para envio de Confirmação em Lote", "warning");
      this.dataExecutar = "";
      return false;
    }

    return true;
  }

  async filtrar(): Promise<void> {
    if (!await this.onDataExit()) {
      return;
    }

    let listaRecursoDto = await this.profissionalRecursoService.getSelectList().toPromise();
    
    const agendamentoFilterDto = new AgendamentoFilterDto();
    agendamentoFilterDto.start = this.dataExecutar;
    agendamentoFilterDto.end = this.dataExecutar;
    agendamentoFilterDto.dateList = this.dataExecutar;
    agendamentoFilterDto.status = AgendamentoStatusType.Agendado;
    agendamentoFilterDto.naoFiltrarRecurso = false;
    agendamentoFilterDto.listaStatus = [AgendamentoStatusType.Agendado, AgendamentoStatusType.Encaixe];
    agendamentoFilterDto.recursoIds = [];

    listaRecursoDto.forEach(item => {
      if (item.usaConfirmacaoLote) {
        agendamentoFilterDto.recursoIds.push(item.value);
      }
    });

    this.commonService.spinnerOpen("Filtrando agendamentos...");
    this.listaConfirmacao = await this.service.getFilter(agendamentoFilterDto).toPromise();
    this.listaConfirmacao = this.commonService.orderBy(this.listaConfirmacao, "dataHoraInicial");

    for (const item of this.listaConfirmacao) {
      if (!item.dataSolicConfirmacao) {
        item.selecionado = true;
        item.statusExecucao = ConfirmacaoLoteStatusType.NaoExecutado;
      } else {
        item.selecionado = false;

        const modelFila = await this.confirmacaoFilaService.getByAgendamentoId(item.id).toPromise();
        if (modelFila.agendamentoId) {
          item.statusExecucao = ConfirmacaoLoteStatusType.SucessoFila;
        } else {
          item.statusExecucao = ConfirmacaoLoteStatusType.Sucesso;
        }
      }
    }

    this.commonService.spinnerClose();
  }

  async executar() {
    this.qtdSucesso = 0;
    this.qtdFalha = 0;

    this.commonService.spinnerOpen("Excutando envio de solicitação de confirmação");
    this.isBusy = true;
    
    const nodoInitConfPresenca = await this.chatBotNodoService.getAtendimentoInitByFluxo(BotFluxoType.ConfirmacaoPresenca).toPromise();

    let lista = this.listaConfirmacao.filter(x => x.selecionado === true);
    let listaAgrupada = this.commonService.groupBy(lista, "celularPaciente");

    for (const propCelular in listaAgrupada) {
      if (!propCelular) {
        this.setarSucessoFalha(listaAgrupada[propCelular], "Não há número de celular informado.");
      } else {
        let celular = this.resolveCelular(propCelular);
        const listaFilaDto = new ListaConfPresencaFilaDto();
        listaFilaDto.lista = [];
        listaFilaDto.reenvio = listaAgrupada[propCelular].some(x => x.isReenvio);

        for (const agendamento of listaAgrupada[propCelular]) {
          let tabela = this.prepararTabela(agendamento);
          const objParse = new ParseMensagem(tabela);

          const itemFila = new ConfirmacaoPresencaFilaModel();

          itemFila.agendamentoId = agendamento.id;
          itemFila.numeroWhatsApp = celular;
          itemFila.texto = objParse.parse(nodoInitConfPresenca.mensagem);
          itemFila.dataVencimento = agendamento.dataHoraInicial;
          itemFila.status = ConfPresencaFilaStatusType.Pendente;

          listaFilaDto.lista.push(itemFila);
        }

        try {
          await this.whatsChatService.criarFilaConfPresenca(listaFilaDto).toPromise();

          this.setarSucessoFalha(listaAgrupada[propCelular], "");

        } catch (errors) {
          let msgErro = "";
          errors.forEach((error: any) => {
            this.errors.push(error);
            msgErro += `${error}\n`;
          });

          this.setarSucessoFalha(listaAgrupada[propCelular], msgErro);
        }
      }
    }

    this.isBusy = false;
    this.commonService.spinnerClose();

    await this.commonService.mensagem("Execução finalizada", `Envios com : ${this.qtdSucesso}, Falhas: ${this.qtdFalha}`, "info");
  }

  async setarSucessoFalha(listaCelular: ConfirmacaoDto[], msgErro: string): Promise<void> {
    for (const agendamento of listaCelular) {
      agendamento.selecionado = false;

      if (!msgErro) {
        agendamento.dataSolicConfirmacao = new Date();

        const modelFila = await this.confirmacaoFilaService.getByAgendamentoId(agendamento.id).toPromise();
        if (modelFila.agendamentoId) {
          agendamento.statusExecucao = ConfirmacaoLoteStatusType.SucessoFila;
        } else {
          agendamento.statusExecucao = ConfirmacaoLoteStatusType.Sucesso;
        }

        this.qtdSucesso++;
      } else {
        agendamento.statusExecucao = ConfirmacaoLoteStatusType.Falha;
        agendamento.motivoFalha = msgErro;
        this.qtdFalha++;
      }
    }
  }

  async marcarReenvio() {
    let mensagem = "Selecionar mensagens já enviadas para Reenvio.";
    let mensagem2 = "Confirmar procedimento?";

    let result = await this.commonService.mensagemConfirmacao(mensagem, mensagem2, "question");
    if (result) {
      this.listaConfirmacao.forEach(item => {
        if (item.dataSolicConfirmacao) {
          item.selecionado = true;
          item.statusExecucao = ConfirmacaoLoteStatusType.NaoExecutado;
          item.isReenvio = true;
          item.dataSolicConfirmacao = null
        }
      });
    }

    this.totalSelect = true;
  }

  prepararTabela(agendamento: ConfirmacaoDto): any[] {
    let tabela: ParseKeyPair[] = [];
    tabela.push({ key: "#PacienteNome", value: agendamento.nomePaciente });
    tabela.push({ key: "#ProcedimentoNome", value: this.commonService.capitalizeNome(agendamento.classificacaoNome.toLowerCase()) });
    tabela.push({ key: "#Data", value: moment(agendamento.data).format('DD/MM/YYYY') });
    tabela.push({ key: "#Horario", value: agendamento.horario.substring(0, 5) });
    tabela.push({ key: "#ProfissionalNome", value: this.commonService.capitalizeNome(agendamento.profissionalRecursoNome.toLowerCase()) });
    tabela.push({ key: "#EquipamentoNome", value: this.commonService.capitalizeNome(agendamento.profissionalRecursoNome.toLowerCase()) });

    return tabela;
  }

  resolveCelular(celular: string): string {
    celular = celular.replace(/[^0-9]/g, '');
    if (!celular.startsWith('55')) {
      celular = `55${celular}`;
    }

    // qtd 14 = 55 48 1234-5678 mais que isso tem que cortar o 9 da frente
    if (celular.length > 12) {
      celular = celular.slice(0, 4) + celular.slice(5);
    }

    return celular;
  }


  getClassificacaoAgendamentoNome(id: string): string {
    return this.listaClassificaoAgend.find(x => x.value === id).text;
  }

  async tbSelect(item: any) {
    if (!item.dataSolicConfirmacao) {
      item.selecionado = true;
    } else {
      await this.commonService.mensagem("Atenção", "Registro já encaminhado", "info");
    }
  }

  tbDeSelect(item: any) {
    item.selecionado = false;
    this.totalSelect = false;
  }

  tbSelectAll() {
    this.listaConfirmacao.forEach(item => {
      if (!item.dataSolicConfirmacao) {
        item.selecionado = true;
        item.statusExecucao = ConfirmacaoLoteStatusType.NaoExecutado;
      }
    });

    this.totalSelect = true;
  }

  tbDeSelectAll() {
    this.listaConfirmacao.forEach(item => {
      if (!item.dataSolicConfirmacao) {
        item.selecionado = false;
        item.statusExecucao = ConfirmacaoLoteStatusType.NaoExecutado;
      }
    });

    this.totalSelect = false;
  }

  async filtrarFila() {
    this.listaFila = await this.confirmacaoFilaService.getByNumeroWhats(this.filaNumeroWhats, this.filaStatus).toPromise();
  }


  back() {
    this.errors = [];
    this.form = null;
    this.router.navigate(['agendamento'], { relativeTo: this.route.parent });
  }

  async onPacienteResponse(pacienteId: string) {
    this.paciente = await this.pacienteService.getById(pacienteId).toPromise();
    this.filaNumeroWhats = this.resolveCelular(this.paciente.pessoa.celular);
  }

}