import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { IconeModel, CategoriaIconeModel } from '../domain/models';
import { CategoriaIconeService, IconeService } from '../domain/services';
import { IconeModalComponent } from './icone-modal/icone-modal.component';

@Component({
  selector: 'app-icone',
  templateUrl: './icone.component.html'
})
export class IconeComponent extends BaseCrudComponent<IconeModel> implements OnInit {
  @ViewChild('iconeModal') modal: IconeModalComponent;

  categoriasIcone: CategoriaIconeModel[];

  searchCategorias = new ModelSearch('categoriaIcone.id', OptionSearchType.Equals, SearchType.Guid);

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  private readonly categoriaIconeService: CategoriaIconeService;

  selectColumns = [
    { data: 'iconeHtml', orderable: false },
    { data: 'nome' },
    { data: 'categoriaIcone.nome' },
  ];

  constructor(
    injector: Injector,
    readonly iconeService: IconeService
  ) {
    super(injector, iconeService);
    this.categoriaIconeService = injector.get<CategoriaIconeService>(CategoriaIconeService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchCategorias.searchTerm) && searches.push(this.searchCategorias);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[1, 'asc']];
    this.categoriaIconeService.getAll().subscribe(categoriasIcone => this.categoriasIcone = categoriasIcone);
  }
}
