import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { TypesService } from 'projects/admin/src/domain/services';
import { WhatsChatFiltroDto } from '../../domain/models';

@Component({
  selector: 'app-chatfiltro-modal',
  templateUrl: './chatfiltro-modal.component.html'
})
export class ChatFiltroModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  celularPaciente = '';
  filtro: WhatsChatFiltroDto;
  status: number;

  constructor(
    injector: Injector,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  show(filtro: WhatsChatFiltroDto) {
    if (!filtro) {
      filtro = new WhatsChatFiltroDto();
    }

    this.filtro = filtro;
    this.filtro.idWhatsApp = '';

    this.modal.show();
  }

  close(filtrar: boolean) {
    //injetar comando do usuário (se é para executar = true)
    this.filtro['filtrar'] = filtrar;

    this.modalClose.emit(this.filtro);

    this.modal.hide();
  }

  async onExitNumero() {
    let numFone: string = this.celularPaciente;
    this.filtro.idWhatsApp = this.commonService.celularToIdWhats(numFone);
    if (!numFone) {
      await this.commonService.mensagem("Atenção Número informado é inválido.", "Verifique se o número informado está correto.", "info");
      return;
    }
  }
}
