import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { BaseCrudService } from "projects/ProjetoBaseAngular/domain/services/base-crud.service";
import { ConfirmacaoPresencaFilaModel } from "../models";
import { ConfPresencaFilaStatusType } from "../types";

@Injectable({
  providedIn: 'root'
})
export class ConfirmacaoPresencaFilaService extends BaseCrudService<ConfirmacaoPresencaFilaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ConfirmacaoPresencaFila';
  }

  getByNumeroWhats(numeroWhats: string, status: ConfPresencaFilaStatusType): Observable<ConfirmacaoPresencaFilaModel[]> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByNumeroWhats/${numeroWhats}/${status}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByAgendamentoId(id: string): Observable<ConfirmacaoPresencaFilaModel> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByAgendamentoId/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}