import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ReceitaModalComponent } from './receita-modal/receita-modal.component';
import { ReceitaModeloModel } from '../domain/models';
import { ReceitaService } from '../domain/services/receita.service';

@Component({
  selector: 'app-receita',
  templateUrl: './receita.component.html'
})
export class ReceitaComponent extends BaseCrudComponent<ReceitaModeloModel> implements OnInit {
  @ViewChild('modal') modal: ReceitaModalComponent;

  constructor(
    injector: Injector,
    readonly service: ReceitaService
  ) {
    super(injector, service);
  }

  selectColumns = [    
    { data: 'nome' },
    { data: 'profissionalSaudeNomeApresentacao' },
  ];

  ngOnInit() {
    super.ngOnInit();
  }
}
