import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ServicoModel, TipoServicoModel } from '../../domain/models';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ServicoService, TipoServicoService } from '../../domain/services';
import { Validators } from '@angular/forms';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-servico-modal',
  templateUrl: './servico-modal.component.html'
})
export class ServicoModalComponent extends BaseCrudModalComponent<ServicoModel> implements OnInit {
  @ViewChild('servicoModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  tiposServico: TipoServicoModel[];

  constructor(
    injector: Injector,
    servicoService: ServicoService,
    private tipoServicoService: TipoServicoService
  ) {
    super(injector, servicoService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.tipoServicoService.getAll().subscribe(tiposServico => this.tiposServico = tiposServico);
  }

  protected initializeForm(model: ServicoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: [model.dataAlteracao],
      tipoServicoId: [model.tipoServicoId, Validators.required],
      nome: [model.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
      descricao: [model.descricao, [
        Validators.required
      ]],
      imagemUrl: [model.imagemUrl],
      imagemUrlBase: [model.imagemUrlBase],
      imagemPath: [model.imagemPath],
      saibaMaisUrl: [model.saibaMaisUrl, [
        Validators.maxLength(200)
      ]],
      exame: [model.exame]
    });
  }

  newModel(): ServicoModel {
    const model = super.newModel();
    model.exame = false;
    return model;
  }

  beforeShowCreate(model: ServicoModel): ServicoModel {
    return super.beforeShowCreate(model);
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }
}
