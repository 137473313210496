import { Component, Injector, OnInit, ViewChild } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { BlocoPosicaoType, BlocoType } from '../domain/enums';
import { BlocoModel } from '../domain/models';
import { BlocoService } from '../domain/services/bloco.service';
import { BannerModalComponent } from './banner-modal/banner-modal.component';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html'
})
export class BannersComponent extends BaseCrudComponent<BlocoModel> implements OnInit {
  @ViewChild('bannerModal') modal: BannerModalComponent;

  selectColumns = [
    { data: 'tipo' },
    { data: 'posicao' },
    { data: 'titulo' },
    { data: 'link.url' },
    { data: 'imagemUrl' },
    { data: 'registroAtivo' }
  ];

  constructor(
    injector: Injector,
    readonly blocoService: BlocoService
  ) {
    super(injector, blocoService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    const searchTipo = new ModelSearch('tipo', OptionSearchType.LessThanOrEqual, SearchType.Int, BlocoType.Html);
    searches.push(searchTipo);
    const searchPosicao = new ModelSearch('posicao', OptionSearchType.GreaterThanOrEqual, SearchType.Int, BlocoPosicaoType.DestaqueAbertura);
    searches.push(searchPosicao);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getTipo(tipo: BlocoType): string {
    switch (tipo) {
      case BlocoType.Html:
        return 'HTML';
      case BlocoType.Imagem:
        return 'Imagem';
    }
  }

  getPosicao(posicao: BlocoPosicaoType): string {
    switch (posicao) {
      case BlocoPosicaoType.DestaqueAbertura:
        return 'Destaque na Abertura';
      case BlocoPosicaoType.DestaqueCarrossel:
        return 'Destaque no Carrossel';
      case BlocoPosicaoType.DestaqueRodape:
        return 'Destaque no Rodapé';
    }
  }
}
