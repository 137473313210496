import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { ProcedimentoModel } from '../models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

@Injectable({
  providedIn: 'root'
})
export class ProcedimentoService extends BaseCrudService<ProcedimentoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Procedimento';
  }

  getByCodigo(codigo: number): Observable<ProcedimentoModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByCodigo/${codigo}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelectList(): Observable<SelectDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getInternoSelectList(): Observable<SelectDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetInternoSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  setUsoFrequenteById(id: string, usoFrequente: boolean): Observable<any> {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/SetUsoFrequenteById/${id}/${usoFrequente}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
