<div class="row">  
  <div class="col-lg-3 col-md-6" *ngFor="let tema of temas">
    <div class="card card-flex" style="background: #f6f6f6;">
      <div class="header">
        <h4>{{tema.descricao}}</h4>
      </div>      
      <a data-toggle="modal" data-target="{{'#modal-' + tema.codigo}}" style="cursor: pointer;">
        <div class="card">
          <div>
            <img src="assets-base/images/previews/browser-top.png" class="img-responsive" style="width: 100%;">
          </div>
          <app-previews [layout]="tema.codigo" [colorPrimary]="configSiteTenant.themeColorPrimary" [colorSecondary]="configSiteTenant.themeColorSecondary"></app-previews>
        </div>
      </a>      
      <div class="modal-footer">
        <!-- <a data-toggle="modal" data-target=@($"#modal-{tema.Codigo}") class="btn btn-default btn-lg waves-effect">
          <strong>Ver Detalhes</strong>
        </a>         -->
        <button [disabled]="!isAllowed('Ativar') || temaAtual === tema.codigo" id="{{'btn-' + tema.id}}" (click)="selecionarTema(tema.codigo)"
          class="btn btn-primary btn-lg">
          <strong>{{temaAtual === tema.codigo ? 'Ativado' : 'Selecionar'}}</strong>
        </button>
      </div>
    </div>
  </div>  
</div>