import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { PaginaService } from 'projects/ProjetoBaseAngular/domain/services';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { PaginaModalComponent } from './pagina-modal/pagina-modal.component';
import { PaginaModel, ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-admin-pagina',
  templateUrl: './paginas.component.html'
})
export class PaginasComponent extends BaseCrudComponent<PaginaModel> implements OnInit {
  @ViewChild('paginaModal') modal: PaginaModalComponent;

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'nome' },
    { data: 'permissionName' },
    { data: 'descricao' },
    { data: 'helpUrl' }
  ];

  constructor(
    injector: Injector,
    readonly paginaService: PaginaService
  ) {
    super(injector, paginaService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = [];
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
