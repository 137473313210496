import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { LaudoModel } from '../../domain/models';
import { LaudoService } from '../../domain/services';
import { LaudoStatusType } from '../../domain/types';
import { LaudoComponent } from '../laudo.component';

@Component({
  selector: 'app-laudo-list-modal',
  templateUrl: './laudo-list-modal.component.html'
})
export class LaudoListModalComponent extends LaudoComponent implements OnInit {
  @ViewChild('listModal') listModal: ModalDirective;

  pacienteId: string;
  pacienteNome: string;
  atendimentoId: string;

  constructor(
    injector: Injector,
    readonly service: LaudoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if ((Number.isInteger(this.searchStatus.searchTerm)) && this.searchStatus.searchTerm >= 0) {
      dataTablesParameters.searches.push(this.searchStatus);
    }
    if (this.pacienteId) {
      dataTablesParameters.searches.push(new ModelSearch('pacienteId', OptionSearchType.Equals, SearchType.Guid, this.pacienteId));
    }

    (this.searchRegistroAtivo.searchTerm) && dataTablesParameters.searches.push(this.searchRegistroAtivo);
    dataTablesParameters.selectColumns = this.selectColumns;
    return dataTablesParameters;
  }

  show(pacienteId, pacienteNome) {
    this.pacienteId = pacienteId;
    this.pacienteNome = pacienteNome;
    this.searchStatus.searchTerm = -1;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();
  }

  showItem(model: LaudoModel) {
    if (model.status === LaudoStatusType.Pendente) {
      this.edit(model);
    } else {
      this.detail(model);      
    }
  }
}
