import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AgendaDisponibilidadeComponent } from './agenda-disponibilidade.component';
import { AgendaDisponibilidadeModalComponent } from './agenda-disponibilidade-modal/agenda-disponibilidade-modal.component';
import { AgendaDisponibilidadeEventualModalComponent } from './agenda-disponibilidade-eventual-modal/agenda-disponibilidade-eventual-modal.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    AgendaDisponibilidadeComponent,
    AgendaDisponibilidadeModalComponent,
    AgendaDisponibilidadeEventualModalComponent
  ],
  exports: [
    AgendaDisponibilidadeComponent,
    AgendaDisponibilidadeModalComponent,
    AgendaDisponibilidadeEventualModalComponent
  ]
})
export class AgendaDisponibilidadeModule { }
