<div bsModal #modal="bs-modal" class="modal fade" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <h2>Resultado</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data Ref.</mat-label>
                <input matInput formControlName="dataReferencia" type="date" />
                <mat-error>Informe uma Data válida.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Resultado</mat-label>
                <input matInput formControlName="resultado" type="text" currencyMask />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observação</mat-label>
                <textarea matInput #observacao formControlName="observacao" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{observacao.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>