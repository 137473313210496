<aside id="rightsidebar" class="right-sidebar">
    <ul class="nav nav-tabs tab-nav-right" role="tablist">
        <li role="presentation" class="active"><a href="#settings" data-toggle="tab">Configurações</a></li>
        <li role="presentation"><a href="#skins" data-toggle="tab">Temas</a></li>        
    </ul>
    <div class="tab-content">
        <div role="tabpanel" class="tab-pane fade in active userTab" id="settings">
            <!-- <div class="user-info">                                    
                <div class="image">
                    <img src="@imageProfileUrl" width="48" height="48" alt="User" style="float: left">
                    <div class="name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">@Model?.Nome</div>
                    <div class="email">@Model?.UserName</div>
                </div>                    
            </div> -->
            <div class="menu">
                <ul class="list">
                    <!-- <li>
                        <a asp-area="" asp-controller="Manage" asp-action="Index">
                            <i class="material-icons">person</i>
                            <span>@StringsResource.EditProfile</span>
                        </a>
                    </li>
                    @if (User.IsInRoles(RolesConventionCore.AdminTenant))
                    {
                        <li>
                            <a href="/assinatura">
                                <i class="material-icons">star</i>
                                <span>Assinatura</span>
                            </a>
                        </li>
                    } -->
                    <li>
                        <a href="/help" target="_blank" rel="noopener">
                            <i class="material-icons">help_outline</i>
                            <span>Central de Ajuda</span>
                        </a>
                    </li>
                    <!-- <li>
                        <a data-toggle="modal" data-target="#termos_modal" onclick="loadText()">
                            <i class="material-icons">work</i>
                            <span>@SisResource.TermosUso</span>
                        </a>
                    </li> -->

                    <li>                        
                        <a (click)="logout()">
                            <i class="material-icons">input</i>
                            <span>Sair</span>
                        </a>                        
                    </li>
                </ul>
            </div>            
        </div>
        <div role="tabpanel" class="tab-pane fade" id="skins">
            <ul class="demo-choose-skin">
                <li *ngFor="let theme of themes" [ngClass]="{'active': selectedThemeCssClass == theme.cssClass}" (click)="setTheme(theme)">
                    <div class="{{theme.cssClass}}"></div>
                    <span>{{theme.name}}</span>
                </li>
            </ul>
        </div>
    </div>
</aside>