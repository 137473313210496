<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Histórico de Atestados</h2>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="listaAtestadoDto?.length > 0">
          <div [ngClass]="{'col-md-6': listaAtestadoDto.length > 2, 'col-md-5': listaAtestadoDto.length <= 2}"
            *ngFor="let atestado of listaAtestadoDto">
            <div class="card">
              <div class="header">
                <h2>{{atestado.data | date: 'dd/MM/yyyy'}} - ({{atestado.profissionalNome}})</h2>
              </div>
              <div class="body" style="height: 100px; overflow-y: auto; padding: 0px; padding-left: 5px;" 
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(atestado.descricaoHtml)">
              </div>
              <div class="footer">
                <div class="row">
                  <div class="col-sm-12">
                    <button [disabled]="!profissionalId || detailMode" mat-icon-button type="button" 
                      (click)="clonar(atestado)"
                      matTooltip="Clonar Atestado">
                      <mat-icon>note_add</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!(listaAtestadoDto?.length > 0)">
          <h4 class="text-center">Não foram encontrados registros históricos de atestado.</h4>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>