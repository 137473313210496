import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { CoresComponent } from './cores.component';
import { CorModalComponent } from './cor-modal/cor-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    CoresComponent,
    CorModalComponent
  ]
})
export class CoresModule { }
