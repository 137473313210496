import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from '../../galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { ConfigSiteTenantModel } from '../domain/models';
import { ConfigSiteTenantService } from '../domain/services/config-site-tenant.service';

@Component({
  selector: 'app-logotipo',
  templateUrl: './logotipo.component.html',
  styleUrls: ['./logotipo.component.scss']
})
export class LogotipoComponent extends BaseComponent implements OnInit {  
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;
  url: string;
  typeImgSelected: string;
    
  constructor(
    injector: Injector,    
    private readonly configSiteTenantService: ConfigSiteTenantService
  ) {
    super(injector);
    this.url = location.host;
  }  

  protected initializeForm(model: ConfigSiteTenantModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      imgFavIconUrl: [model.imgFavIconUrl],
      imgFavIconUrlBase: [model.imgFavIconUrlBase],
      imgFavIconPath: [model.imgFavIconPath],

      imgLogotipoUrl: [model.imgLogotipoUrl],      
      imgLogotipoUrlBase: [model.imgLogotipoUrlBase],
      imgLogotipoPath: [model.imgLogotipoPath],

      imgSharingUrl: [model.imgSharingUrl],
      imgSharingUrlBase: [model.imgSharingUrlBase],
      imgSharingPath: [model.imgSharingPath]
    });    
  }

  ngOnInit() {
    this.configSiteTenantService.get().subscribe(model => this.initializeForm(model));    
  }  
  
  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: ConfigSiteTenantModel = Object.assign({}, this.form.value);
    this.configSiteTenantService.editByTipo('Img', model).subscribe(
      () => {        
        this.snackBar.open('Salvo com sucesso!', 'Ok', {
          duration: 3000
        });
      },
      (errors) => this.verifyErrors(errors)
    );
  }

  showSelectImage(typeImgSelected: string) {
    this.typeImgSelected = typeImgSelected;
    this.imagemSelectModal.showSelect();
  }

  onResponse(imagem: ImagemModel) {
    switch (this.typeImgSelected) {
      case 'FavIcon':
        this.form.patchValue({
          imgFavIconUrl: imagem.url,
          imgFavIconUrlBase: imagem.urlBase,
          imgFavIconPath: imagem.path
        });
        break;

      case 'Logotipo':
        this.form.patchValue({
          imgLogotipoUrl: imagem.url,
          imgLogotipoUrlBase: imagem.urlBase,
          imgLogotipoPath: imagem.path
        });
        break;       

      case 'Sharing':
        this.form.patchValue({
          imgSharingUrl: imagem.url,
          imgSharingUrlBase: imagem.urlBase,
          imgSharingPath: imagem.path
        });
        break;                 
    }        
  }

  deselectImage(typeImgSelected: string) {
    switch (typeImgSelected) {
      case 'FavIcon':
        this.form.patchValue({
          imgFavIconUrl: null,
          imgFavIconUrlBase: null,
          imgFavIconPath: null
        });
        break;

      case 'Logotipo':
        this.form.patchValue({
          imgLogotipoUrl: null,
          imgLogotipoUrlBase: null,
          imgLogotipoPath: null
        });
        break;      

      case 'Sharing':
        this.form.patchValue({
          imgSharingUrl: null,
          imgSharingUrlBase: null,
          imgSharingPath: null
        });
        break;          
    }
  }
}
