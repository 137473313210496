import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { EstadoModel, CidadeModel, ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { ParceiroModalComponent } from './parceiro-modal/parceiro-modal.component';
import { ParceiroModel, TipoParceiroModel } from '../domain/models';
import { ParceiroService, TipoParceiroService } from '../domain/services';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-parceiro',
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent extends BaseCrudComponent<ParceiroModel> implements OnInit {
  @ViewChild('parceiroModal') modal: ParceiroModalComponent;

  parceiros: ParceiroModel[];
  tiposParceiros: TipoParceiroModel[];

  estados: EstadoModel[];
  cidades: CidadeModel[];

  searchEstado = new ModelSearch('estadoId', OptionSearchType.Equals, SearchType.Guid);
  searchCidade = new ModelSearch('cidadeId', OptionSearchType.Equals, SearchType.Guid);
  searchTipos = new ModelSearch('tipoIds', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'ordem' },
    { data: 'nome' },      
    { data: 'tipos.Select(x => x.tipoParceiro.nome) as tipoNomes', orderable: false},      
    { data: 'urlSite' },
    { data: '(cidade.nome + "/" + estado.uf) as cidadeUf', name: 'cidade.nome' }
  ];
  
  constructor(
    injector: Injector,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService,
    readonly parceiroService: ParceiroService,
    readonly tipoParceiroService: TipoParceiroService
  ) {
    super(injector, parceiroService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchEstado.searchTerm) && searches.push(this.searchEstado);
    (this.searchCidade.searchTerm) && searches.push(this.searchCidade);
    (this.searchTipos.searchTerm) && searches.push(this.searchTipos);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();    

    this.estadoService.getAll().subscribe(estados => this.estados = estados);
    this.tipoParceiroService.getAll().subscribe(tiposParceiros => this.tiposParceiros = tiposParceiros);
    this.atualizaOrdem();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.parceiros, event.previousIndex, event.currentIndex);
    const ids = this.parceiros.map(x => x.id);
    this.parceiroService.updateOrdens(ids).subscribe();
  }

  atualizaOrdem() {
    this.parceiroService.getAll().subscribe(parceiros => this.parceiros = parceiros);
  }

  changeEstado(estadoId) {
    if (estadoId) {
      this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
    } else {
      this.cidades = [];
    }
  }
}
