import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AtendimentoReceitaService, MedicamentoService } from '../../../domain/services';
import { AtendimentoReceitaModel, MedicamentoModel } from '../../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { DataTableDirective } from 'angular-datatables';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ReceitaService } from '../../../../modelos/domain/services/receita.service';

@Component({
  selector: 'app-atendimento-receita-modal',
  templateUrl: './atendimento-receita-modal.component.html'
})
export class AtendimentoReceitaModalComponent extends BaseCrudModalComponent<AtendimentoReceitaModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild(DataTableDirective) datatableMedicamento: DataTableDirective;
  @ViewChild(DataTableDirective) datatableModelos: DataTableDirective;
  @Input('atendimentoId') atendimentoId: string;
  @Input('profissionalId') profissionalId: string;

  dtOptionsMedicamento: DataTables.Settings = null;
  dtOptionsModelos: DataTables.Settings = null;

  listaMedicamento: any[] = [];
  listaModelo: SelectDto[];

  searchNome = new ModelSearch('descricao', OptionSearchType.Contains, SearchType.String);
  searchUsoFrequente = new ModelSearch('usoFrequente', OptionSearchType.Equals, SearchType.Boolean);
  searchAtivo = new ModelSearch('registroAtivo', OptionSearchType.Equals, SearchType.Boolean, true);

  searchProfissionalId = new ModelSearch('profissionalSaudeId', OptionSearchType.Equals, SearchType.Guid);

  constructor(
    injector: Injector,
    service: AtendimentoReceitaService,
    readonly medicamentoService: MedicamentoService,
    readonly receitaModeloService: ReceitaService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected init() {
    super.init();
    this.searchUsoFrequente.searchTerm = true;
    this.searchAtivo.searchTerm = true;

    this.searchProfissionalId.searchTerm = this.profissionalId;

    this.initMedicamentos();
    this.initModelos();
  }

  protected initializeForm(model: AtendimentoReceitaModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      atendimentoId: [this.atendimentoId],
      seq: [model.seq, Validators.required],
      tipo: [model.tipo, Validators.required],
      descricao: [model.descricao, Validators.required]
    });
  }

  initMedicamentos() {
    this.dtOptionsMedicamento = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      dom: '<r<t>ip>',
      pageLength: 10,
      searching: false,
      serverSide: true,
      processing: true,
      scrollY: "280px",
      scrollCollapse: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters = this.beforeDtRequestMedicamento(dataTablesParameters);
        this.medicamentoService.getResponse(dataTablesParameters).subscribe(
          (resp) => {
            this.listaMedicamento = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [
        { data: 'descricao' },
        { data: 'id', orderable: false }
      ]
    };
  }

  initModelos() {
    this.dtOptionsModelos = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      dom: '<rt<t>ip>',
      pageLength: 10,
      searching: false,
      serverSide: true,
      processing: true,
      scrollY: "280px",
      scrollCollapse: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters = this.beforeDtRequestModelos(dataTablesParameters);
        this.receitaModeloService.getResponse(dataTablesParameters).subscribe(resp => {
          this.listaModelo = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { data: 'nome' },
        { data: 'id', orderable: false }
      ]
    };
  }

  protected beforeDtRequestMedicamento(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    
    dataTablesParameters.searches.push(this.searchAtivo);
    (this.searchUsoFrequente.searchTerm) && dataTablesParameters.searches.push(this.searchUsoFrequente);
    (this.searchNome.searchTerm) && dataTablesParameters.searches.push(this.searchNome);

    dataTablesParameters.selectColumns = [{ data: 'descricao' }];

    return dataTablesParameters;
  }

  protected beforeDtRequestModelos(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];

    dataTablesParameters.searches.push(this.searchAtivo);
    dataTablesParameters.searches.push(this.searchProfissionalId);
    
    dataTablesParameters.selectColumns = [{ data: 'nome' }];

    return dataTablesParameters;
  }  

  filterMedicamento() {
    this.datatableMedicamento?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  filterModelos() {
    this.datatableModelos?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  onChangeOpcao() {
    this.filterMedicamento();
  }

  addMedicamento(id: string) {
    this.medicamentoService.getById(id).subscribe(x => {
      let descricao = this.form.value.descricao;
      descricao = descricao ? descricao += '\n' : '';
      const underlines: number = 30 - x.descricao.length;
      descricao += `${x.descricao}${underlines > 0 ? Array(underlines).join('_') : ' '}${x.concentracao} ${x.qtdEmbalagem}`;
      descricao += `${'\n'}${this.typesService.getViaTypeNome(x.via)}`;
      if (x.formaUso) {
        descricao += ` : ${x.formaUso}`;
      }
      this.form.patchValue({ descricao });
    });
  }

  async addModelo(id: string) {
    const model = await this.receitaModeloService.getById(id).toPromise();
    let descricao: string = "";

    if (this.form.value.descricao) {
      descricao = this.form.value.descricao;
    }
    descricao += descricao.length > 0 ? `\n${model.texto}` : model.texto;

    this.form.patchValue({ descricao });
  }

  isAllowedMedicamento(value: string) {
    const type = this.medicamentoService.getController();
    return super.isAllowed(value, type);
  }

  async addUsoFreq(model: MedicamentoModel) {
    let result = await this.commonService.mensagemConfirmacao("Adicionar medicamento em lista de uso frequente?", "", "question");
    if (result) {
      await this.medicamentoService.setUsoFrequenteById(model.id, true).toPromise();
    }
  }

  async remUsoFreq(model: MedicamentoModel) {
    let result = await this.commonService.mensagemConfirmacao("Remover medicamento da lista de uso frequente?", "", "question");
    if (result) {
      await this.medicamentoService.setUsoFrequenteById(model.id, false).toPromise();
      this.filterMedicamento();
    }
  }

  async editMedicamento(model: MedicamentoModel) {
    let result = await this.commonService.mensagemConfirmacao("Editar cadastro de medicamento?", "", "question");
    if (result) {
      //this.modalMedicamento.showEdit(model.id);
      //this.filter();
    }
  }

  async deleteMedicamento(id: string) {
    let result = await this.commonService.mensagemConfirmacao("Confirmar exclusão de medicamento?", "", "warning");
    if (result) {
      await this.medicamentoService.delete(id).toPromise();
      this.filterMedicamento();
    }
  }

  // tinymceConfigLocal(height?: number) {
  //   return {
  //     height,
  //     base_url: '/tinymce',
  //     suffix: '.min',
  //     language: 'pt_BR',
  //     language_url: '/assets-base/langs/pt_BR.js',
  //     entity_encoding: 'numeric',
  //     extended_valid_elements: 'i[class],script[src|async|defer|type|charset]',
  //     plugins: 'hr nonbreaking anchor toc wordcount',
  //     //selector: 'textarea',
  //     menubar: '',
  //     toolbar: '',
  //     setup: null
  //   }
  // }

  // beforeSave(model: AtendimentoReceitaModel): AtendimentoReceitaModel {

  //   model.descricao = model.descricao
  //     .replace(/<p>/g, '\n')
  //     .replace(/[</p>]/g, '')
  //     .replace(/&#160;/g, '\n');

  //   return super.beforeSave(model);
  // }
}
