<div bsModal #acaoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="acaoModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-lg">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Ações</h2>
				</div>
				<div class="modal-body">

					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>

					<div class="col-md-2">
						<mat-form-field>
							<mat-label>Código</mat-label>
							<input matInput formControlName="codigo" type="text" />
							<mat-error>Informe um Codigo válido.</mat-error>
						</mat-form-field>
					</div>

					<div class="col-md-6">
						<mat-form-field>
							<mat-label>Nome</mat-label>
              <input matInput formControlName="nome" type="text" />
              <mat-hint align="end">Máx 50 caracteres</mat-hint>
							<mat-error>Informe um Nome válido.</mat-error>
						</mat-form-field>
					</div>

					<div class="col-md-12">
						<mat-form-field>
							<mat-label>Descrição</mat-label>
							<textarea matInput #descricao formControlName="descricao" rows="1"
								maxlength="500"></textarea>
							<mat-hint align="end">{{descricao.value.length}} / 500</mat-hint>
						</mat-form-field>
					</div>

				</div>

				<div class="footer">
					<button mat-flat-button  [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
