<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header" [ngClass]="typesService.getAtendimentoTypeColor(tipoAtendimento)">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Lançamento de Entrada de Paciente</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Data/Hora Atendimento</mat-label>
                <input matInput formControlName="dataHoraEntrada" type="datetime-local" required />
                <mat-error>Informe uma Data válida.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Número NF</mat-label>
                <input matInput formControlName="numeroNotaFiscal" type="number" required
                  *ngIf="form.value.gerouNotaFiscal" />
                <input matInput disabled type="number" *ngIf="!form.value.gerouNotaFiscal" />
                <mat-slide-toggle matSuffix color="primary" matTooltip="Gerou Nota Fiscal"
                  formControlName="gerouNotaFiscal"></mat-slide-toggle>
                <mat-error>Informe um Número válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Tipo Atentimento</mat-label>
                <input matInput [value]="typesService.getAtendimentoType(tipoAtendimento)" disabled />
              </mat-form-field>
            </div>
            <div class="col-md-1 text-center">
              <button mat-mini-fab [ngClass]="typesService.getAtendimentoTypeColor(tipoAtendimento)" type="button">
                <mat-icon>{{typesService.getAtendimentoTypeIcon(tipoAtendimento)}}</mat-icon>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Profissional da Saúde/Equipamento</mat-label>
                <mat-select formControlName="profissionalRecursoId" disabled>
                  <mat-option *ngFor="let recurso of recursos" [value]="recurso.value">
                    {{recurso.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional da Saúde/Equipamento.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="isEquipamento">
              <mat-form-field>
                <mat-label>Profissional Solicitante</mat-label>
                <mat-select formControlName="profissionalSolicitanteId">
                  <mat-option *ngFor="let profissional of profissionais" [value]="profissional.value">
                    {{profissional.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="isEquipamento">
              <mat-form-field>
                <mat-label>Profissional Executante</mat-label>
                <mat-select formControlName="profissionalExecutanteId">
                  <mat-option *ngFor="let profissional of profissionais" [value]="profissional.value">
                    {{profissional.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Cód./CPF Paciente</mat-label>
                <input matInput [disabled]="form.value.pacienteAnotacao || detailMode"
                  [value]="paciente?.pessoa?.cnpjCpf" type="text"
                  (blur)="pacienteSelect.changeDocumento($event.target.value)" maxlength="18" required />
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.showSelect()" matTooltip="Selecionar Paciente">
                  <mat-icon>search</mat-icon>
                </button>
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.create()" matTooltip="Adicionar Paciente">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Paciente</mat-label>
                <input matInput disabled
                  [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia"
                  type="text" />
                <button [disabled]="isBusy || !paciente?.id" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Matrícula Convênio</mat-label>
                <input matInput formControlName="matriculaConvenio" type="text" maxlength="20"
                  (blur)="changeMatriculaConvenio($event.target.value)" #focus />
                <mat-hint align="end">Máx 20 caracteres</mat-hint>
                <mat-error>Informe um Nome</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Convênio</mat-label>
                <mat-select formControlName="convenioId">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Buscar (letras iniciais)..."
                      noEntriesFoundLabel="Nenhum resultado encontrado!" [formControl]="convenioFilterCtrl">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let item of filteredConvenios" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Convênio válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <h4 class="card-inside-title">Procedimentos</h4>
          <app-atendimento-procedimento (itemUpdate)="onProcedimentoResponse()" (autoSave)="autoSave()"
            [atendimentoId]="form.value.id" [convenioId]="form.value.convenioId"
            [profissionalRecursoId]="form.value.profissionalRecursoId"
            [profissionalExecutanteId]="form.value.profissionalExecutanteId" [detailMode]="detailMode">
          </app-atendimento-procedimento>
          <div class="row">
            <div class="col-md-3 pull-right">
              <mat-form-field>
                <mat-label>Vlr. Total</mat-label>
                <input matInput class="font-weight-bold text-right" disabled
                  value="{{form.value.total | currency:'BRL':''}}" currencyMask />
                <mat-error>Informe um Vlr. Total válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer" *ngIf="!detailMode">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="closeNoSave()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
      <div class="footer" *ngIf="detailMode">
        <button mat-flat-button type="button" (click)="editarNumFiscal(form.value.id, true)" color="primary"
          class="button-row">
          <mat-icon>receipt_long</mat-icon>
          <span> Núm.NF</span>
        </button>
        <button mat-flat-button type="button" (click)="editarNumFiscal(form.value.id, false)" color="primary"
          class="button-row">
          <mat-icon>cancel</mat-icon>
          <span> Remover Núm.NF</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>