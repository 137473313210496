import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { BaseSelectModalComponent } from 'projects/ProjetoBaseAngular/app/base-select-modal.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { ProcedimentoModel } from '../../domain/models';
import { ProcedimentoService } from '../../domain/services';
import { ProcedimentoModalComponent } from '../procedimento-modal/procedimento-modal.component';

@Component({
  selector: 'app-procedimento-select-modal',
  templateUrl: './procedimento-select-modal.component.html'
})
export class ProcedimentoSelectModalComponent extends BaseSelectModalComponent<ProcedimentoModel> implements OnInit {
  @ViewChild('modal') modal: ProcedimentoModalComponent;

  searchTipoServico = new ModelSearch('tipoServico', OptionSearchType.Equals, SearchType.Int, 0);
  
  selectColumns = [ 
    { data: 'codigo'},
    { data: 'nome'},
    { data: 'grupo.nome'},
    { data: 'exame', orderable: false},
    { data: 'tipoServico', orderable: false}      
  ];

  constructor(
    injector: Injector,
    readonly service: ProcedimentoService
  ) {
    super(injector, service);
  }  

  ngOnInit() {    
    super.ngOnInit();    
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {

    dataTablesParameters.searches =  [];

    dataTablesParameters.searches.push(this.searchTipoServico);
    (this.searchRegistroAtivo.searchTerm) && dataTablesParameters.searches.push(this.searchRegistroAtivo);
    dataTablesParameters.selectColumns = this.selectColumns;
    return dataTablesParameters;
  }
}
