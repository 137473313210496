import { Injectable, Injector } from '@angular/core';
import { DataTablesResponse } from 'projects/ProjetoBaseAngular/domain/models';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AgendaDisponibilidadeModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AgendaDisponibilidadeService extends BaseCrudService<AgendaDisponibilidadeModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'AgendaDisponibilidade';
  }

  getByRecursoIds(recursoIds): Observable<AgendaDisponibilidadeModel[]> {
    return this.httpClient
    .get(`${this.getApiUrl()}${this.getController()}/GetByRecursoIds/${recursoIds}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }    
}
