import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ProcedimentoPerguntaModalComponent } from './procedimento-pergunta-modal/procedimento-pergunta-modal.component';
import { ProcedimentoPerguntaModel } from '../domain/models';
import { ProcedimentoPerguntaService } from '../domain/services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-procedimento-pergunta',
  templateUrl: './procedimento-pergunta.component.html'
})
export class ProcedimentoPerguntaComponent extends BaseCrudComponent<ProcedimentoPerguntaModel> implements OnInit {
  @ViewChild('modal') modal: ProcedimentoPerguntaModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  procedimentoId: string;
  procedimentoNome: string;

  selectColumns = [    
    { data: 'codigo' },
    { data: 'pergunta' },
    { data: 'resposta' }
  ];

  constructor(
    injector: Injector,
    readonly service: ProcedimentoPerguntaService
  ) {
    super(injector, service);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.procedimentoId) {
      dataTablesParameters.searches.push(new ModelSearch('procedimentoId', OptionSearchType.Equals, SearchType.Guid, this.procedimentoId));
    }
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  show(procedimentoId, procedimentoNome) {
    this.procedimentoId = procedimentoId;
    this.procedimentoNome = procedimentoNome;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();
    this.modalClose.emit();
  }

  create() {
    const model = new ProcedimentoPerguntaModel();
    model.dataInclusao = new Date();
    model.id = this.commonService.newGuid();
    model.procedimentoId = this.procedimentoId;
    super.create(model);
  }  
}
