import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PacienteTermoModel } from '../models';
import { WhatsChatModel } from '../../../atendimento-online/domain/models';

@Injectable({
  providedIn: 'root'
})
export class PacienteTermoService extends BaseCrudService<PacienteTermoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'PacienteTermo';
  }

  getByPacienteId(pacienteId: string): Observable<PacienteTermoModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByPacienteId/${pacienteId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  enviarTermo(idWhatsApp: string, base64: string, caption: string): Observable<any> {
    debugger
    const requestBody = {
      idWhatsApp: idWhatsApp,
      base64: base64,
      caption: caption
    };
    
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/EnviarTermo`, JSON.stringify(requestBody), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  existeChatAtivoByNumeroWhats(numeroWhatsApp: string): Observable<boolean> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/ExisteChatAtivoByNumeroWhats/${numeroWhatsApp}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getChatAtivoByNumeroWhats(numeroWhatsApp: string): Observable<WhatsChatModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetChatAtivoByNumeroWhats/${numeroWhatsApp}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
