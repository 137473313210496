import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { BaseEntidadePessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { EstadoCivilType, PacienteIndicadoPorType, PacienteProntuarioType, RacaType, SangueType, TermoControleType, TermoStatusType } from 'projects/admin/src/domain/types';
import { ReceitaType } from '../../atendimento/domain/types';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

export class GrupoPacienteModel extends BaseModel {
  public nome: string;
}

export class PacienteModel extends BaseEntidadePessoaModel {
  public grupoId: string;
  public grupoNome: string;
  public convenioId: string;
  public convenioNome: string;
  public convMatricula: string;
  public convMatriculaValidade?: Date;

  public codCns: string;
  public raca: RacaType;
  public estadoCivil: EstadoCivilType;
  public profissao: string;
  public nomePai: string;
  public nomeMae: string;
  public nomeResponsavel: string;
  public nomeConjuge: string;
  public indicadoPor: PacienteIndicadoPorType;
  public nomeIndicador: string;
  public codProntuario: string;

  public exibirAlertaAtendimento: boolean;
  public bloquearAgendamento: boolean;
  public dataExpiraBloqueio: Date;
  public mensagemBloqAlerta: string;
  public anotacaoProntuario: string;
  public tipoSanguineo: SangueType;
  public idWhatsApp: string;
}

export class PacienteArquivoModel extends BaseModel {
  public pacienteId: string;
  public nome: string;
  public arquivoUrl: string;
  public arquivoUrlBase: string;
  public arquivoPath: string;
  public arquivoBase64: string;
  public extensaoBase64: string;
}

export class PacienteExameIndicadorModel extends BaseModel {
  public pacienteId: string;
  public procedimentoId: string;
  public procedimentoNome: string;
}

export class PacienteExameIndicadorItemModel extends BaseModel {
  public pacienteExameIndicadorId: string;
  public dataReferencia: Date;
  public resultado: number;
  public observacao: string;
}

export class PacienteProntuarioModel extends BaseModel {
  public pacienteId: string;
  public profissionalSaudeId: string;
  public profissionalSaudeNome: string;
  public atendimentoId: string;
  public tipo: PacienteProntuarioType;
  public receitaTipo: ReceitaType;
  public sequencial: number;
  public descricao: string;
}

export class PacienteProntuarioDto extends SelectDto {
  public profissionalSaudeId: string;
  public profissionalSaudeNome: string;
  public dataInclusao: Date;
  public tipo: PacienteProntuarioType;
}

export class PacienteTermoModel extends BaseModel {
  public pacienteId: string;
  public nome: string;
  public textoTermo: string;
  public dataEnvio: Date;
  public dataResposta: Date;
  public status: TermoStatusType;
  public aceito: boolean;
  public tipoControle: TermoControleType;
}