import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch, PushModel } from 'projects/ProjetoBaseAngular/domain/models';
import { AssinanteService } from 'projects/ProjetoBaseAngular/domain/services/notificacao/assinante.service';
import { PushService } from 'projects/ProjetoBaseAngular/domain/services/notificacao/push.service';
import { OptionSearchType, PushStatusType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { PushModalComponent } from './push-modal/push-modal.component';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html'
})
export class PushComponent extends BaseCrudComponent<PushModel> implements OnInit {
  @ViewChild('pushModal') modal: PushModalComponent;

  pushs: PushModel[];

  PushStatusType: typeof PushStatusType = PushStatusType;


  statusPossiveis = [
    { text: 'Pendente', value: PushStatusType.Pendente },
    { text: 'Agendado', value: PushStatusType.Agendado },
    { text: 'Em Andamento', value: PushStatusType.EmAndamento },
    { text: 'Concluido', value: PushStatusType.Concluido },
    { text: 'Cancelado', value: PushStatusType.Cancelado }
  ];

  opcoesDatas = [
    { text: 'Data de Inclusão', value: 1 },
    { text: 'Data do Agendamento', value: 2 }
  ];

  filtroDataSelecionado: number;

  searchStatus = new ModelSearch('status', OptionSearchType.Equals, SearchType.Int);
  search = new ModelSearch('titulo', OptionSearchType.Contains, SearchType.String);

  searchDataInicialEnvio = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinalEnvio = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)));
  searchDataInicialAgendamento = new ModelSearch('dataAgendamento', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinalAgendamento = new ModelSearch('dataAgendamento', OptionSearchType.LessThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)));

  selectColumns = [
    { data: 'dataAgendamento' },
    { data: 'status' },
    { data: 'titulo' },
    { data: 'mensagem' },
    { data: 'url' },
    { data: 'totalAssinantes' }
  ];

  constructor(
    injector: Injector,
    readonly pushService: PushService,
    readonly assinanteService: AssinanteService
  ) {
    super(injector, pushService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (Number.isInteger(this.searchStatus.searchTerm)) && searches.push(this.searchStatus);
    (this.search.searchTerm) && searches.push(this.search);

    if (this.filtroDataSelecionado === 1) {
      (this.searchDataInicialEnvio.searchTerm) && searches.push(this.searchDataInicialEnvio);
      (this.searchDataFinalEnvio.searchTerm) && searches.push(this.searchDataFinalEnvio);

    } else if (this.filtroDataSelecionado === 2) {
      (this.searchDataInicialAgendamento.searchTerm) && searches.push(this.searchDataInicialAgendamento);
      (this.searchDataFinalAgendamento.searchTerm) && searches.push(this.searchDataFinalAgendamento);
    }

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[0, 'desc']];
  }

  getStatusPush(tipo: PushStatusType): string {
    switch (tipo) {
      case PushStatusType.Pendente:
        return 'Pendente';
      case PushStatusType.Agendado:
        return 'Agendado';
      case PushStatusType.EmAndamento:
        return 'Em Andamento';
      case PushStatusType.Cancelado:
        return 'Cancelado';
      case PushStatusType.Concluido:
        return 'Concluido';
    }
  }

  getSearchData(value: number): string {
    switch (value) {
      case 1:
        return 'Data de Inclusão';
      case 2:
        return 'Data de Agendamento';
      case 3:
        return 'Nao filtrar data';
    }
  }
}
