import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProfissionalSaudeTextoPadraoModel } from '../../../../equipe/domain/models';
import { ProfissionalSaudeTextoPadraoService } from '../../../../equipe/domain/services';

@Component({
  selector: 'app-laudo-montar-add-texto',
  templateUrl: './laudo-montar-add-texto.component.html'
})
export class LaudoMontarAddTextoComponent implements OnInit {
  @ViewChild('focus') focusElement: any;

  textos: ProfissionalSaudeTextoPadraoModel[];
  filteredTextos: ProfissionalSaudeTextoPadraoModel[] = [];
  textoId: string;

  public textoFilterCtrl: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    readonly dialogRef: MatDialogRef<LaudoMontarAddTextoComponent>,
    readonly profissionalSaudeTextoPadraoService: ProfissionalSaudeTextoPadraoService
  ) { }

  ngOnInit() {
    this.profissionalSaudeTextoPadraoService.getByProfissionalSaudeId(this.data.profissionalId).subscribe(x => {
      this.textos = x;
      this.filteredTextos = x;
    });

    this.textoFilterCtrl.valueChanges.subscribe(() => this.filter());
    
    if (this.focusElement) {
      if (this.focusElement.nativeElement) {
        this.focusElement.nativeElement.focus();
      } else {
        this.focusElement.focus();
      }
    }
  }

  adicionar() {
    const textoSelecionado = this.textos.find(x => x.id === this.textoId);
    this.dialogRef.close(textoSelecionado);
  }

  protected filter() {
    if (!this.textos) {
      return;
    }
    // get the search keyword
    let search = this.textoFilterCtrl.value;
    if (!search) {
      this.filteredTextos = this.textos.slice();
      return;
    } else {
      search = search.toLowerCase();
    }

    // filtrar textos
    this.filteredTextos = this.textos.filter(x => 
      x.texto?.toLowerCase().startsWith(search));

      
      // (bank.palavrasChave && bank.palavrasChave.toLowerCase().indexOf(search) > -1) ||
      // bank.texto.toLowerCase().indexOf(search) > -1
  }
}
