import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AtestadoComponent } from './atestado.component';
import { AtestadoModalComponent } from './atestado-modal/atestado-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AtestadoComponent,
    AtestadoModalComponent
  ]
})
export class AtestadoModule { }
