import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { CapturaModalComponent } from './captura-modal/captura-modal.component';
import { CapturaModel, EquipamentoModel } from '../domain/models';
import { CapturaService, EquipamentoService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { DataTablesRequest, ModelSearch, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { CapturaStatusType } from '../domain/types';
import { PacienteModel } from '../../paciente/domain/models';
import { PacienteService } from '../../paciente/domain/services';
import * as moment from 'moment';

@Component({
  selector: 'app-captura',
  templateUrl: './captura.component.html'
})
export class CapturaComponent extends BaseCrudComponent<CapturaModel> implements OnInit {
  @ViewChild('modal') modal: CapturaModalComponent;

  searchStatus = new ModelSearch('status', OptionSearchType.Equals, SearchType.Int, CapturaStatusType.Pendente);
  searchEquipamento = new ModelSearch('equipamentoId', OptionSearchType.Equals, SearchType.Guid);
  searchPaciente = new ModelSearch('pacienteId', OptionSearchType.Equals, SearchType.Guid);
  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime, this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime, this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)));
  equipamentos: EquipamentoModel[];
  paciente: PacienteModel;

  selectColumns = [
    { data: 'dataInclusao' },
    { data: 'atendimento.codigoDocMovto' },    
    { data: 'atendimento.paciente.pessoa.nomeFantasia as atendimentoPacienteNome' },
    { data: 'atendimento.convenio.nome as atendimentoConvenioNome' },    
    { data: 'status' },
  ];  

  readonly typesService: TypesService;
  private readonly equipamentoService: EquipamentoService;
  private readonly pacienteService: PacienteService;
  
  constructor(
    injector: Injector,
    service: CapturaService    
  ) {
    super(injector, service);
    this.typesService = injector.get<TypesService>(TypesService);
    this.equipamentoService = injector.get<EquipamentoService>(EquipamentoService);
    this.pacienteService = injector.get<PacienteService>(PacienteService);

    this.onPacienteResponse(null);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    //(Number.isInteger(this.searchStatus.searchTerm)) && searches.push(this.searchStatus);
    if ((Number.isInteger(this.searchStatus.searchTerm)) && this.searchStatus.searchTerm >= 0) {
      searches.push(this.searchStatus);
    }
    (this.searchEquipamento.searchTerm) && searches.push(this.searchEquipamento);
    (this.searchPaciente.searchTerm) && searches.push(this.searchPaciente);
    if (this.searchDataInicial.searchTerm) {
      const searchDataInicial = Object.assign({}, this.searchDataInicial);
      searchDataInicial.searchTerm = `${this.fromJsonDate(searchDataInicial.searchTerm)} 00:00`;
      searches.push(searchDataInicial);
    }
    if (this.searchDataFinal.searchTerm) {
      const searchDataFinal = Object.assign({}, this.searchDataFinal);
      searchDataFinal.searchTerm = `${this.fromJsonDate(searchDataFinal.searchTerm)} 23:59`;
      searches.push(searchDataFinal);
    }
    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.order = [[0, 'desc']];
    this.equipamentoService.getAll().subscribe(x => this.equipamentos = x.filter(x => x.capturaImagem));
  }

  onPacienteResponse(pacienteId: string) {    
    this.searchPaciente.searchTerm = pacienteId;
    if (!pacienteId) {      
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';      
    }
    else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;        
      });
    }        
  }
}
