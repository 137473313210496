import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { MedicamentoDto, MedicamentoModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MedicamentoService extends BaseCrudService<MedicamentoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Medicamento';
  }

  getByCodigo(codigo: number): Observable<MedicamentoModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByCodigo/${codigo}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelectList(): Observable<MedicamentoDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  setUsoFrequenteById(id: string, usoFrequente: boolean): Observable<any> {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/SetUsoFrequenteById/${id}/${usoFrequente}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
