<div bsModal #enqueteOpcaoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="enqueteOpcaoModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}"
	(onShown)="onShown()">
	<div class="modal-dialog modal-lg">
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2> Enquete </h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<div class="row">
						<div class="col-md-10">
							<mat-form-field>
								<mat-label>Descrição da Opção</mat-label>
								<input matInput formControlName="descricaoOpcao" type="text" #focus />
								<mat-hint align="end">Máx 200 caracteres</mat-hint>
								<mat-error>Informe uma Descrição válida.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Ordem</mat-label>
								<input matInput formControlName="ordem" type="text" />
								<mat-error>Informe uma Ordem válido.</mat-error>
							</mat-form-field>
						</div>
					</div>

				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
