import { BaseModel } from "../base/base-model";

export class ArquivoModel extends BaseModel {
    public nome: string;
    public descricao: string;
    public url: string;
    public urlBase: string;    
    public path: string;
    public file: any;
}
