import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { LayoutImpressaoModel } from '../../atendimento/domain/models';
import { LayoutImpressaoService } from '../../atendimento/domain/services';

@Component({
  selector: 'app-report-footer',
  templateUrl: './report-footer.component.html'
})
export class ReportFooterComponent implements OnInit {
  layoutImpressao: LayoutImpressaoModel;

  constructor(
    readonly layoutImpressaoService: LayoutImpressaoService,
    readonly sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.layoutImpressaoService.getAll().subscribe(x => this.layoutImpressao = x[0]);
  }

}
