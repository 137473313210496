<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card" *ngIf="listaApres && questionario && resposta && filtro">
        <div class="header">
          <h4 class="modal-title">Questionário - Detalhes de Respostas</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div #tableRespostas>
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th colspan="4" class="text-center">Questionário: {{questionario.titulo}}    -    Filtro: {{filtro.opcao}}</th>
                    </tr>
                    <tr>
                      <th colspan="2" class="text-center">Código: {{resposta.codigo}}  -  Data: {{resposta.dataInclusao | date:'dd/MM/yyyy HH:mm'}}</th>
                      <th colspan="2" class="text-center">Status: {{typesService.getPesquisaStatusCorType(resposta.statusCor)}}  -  Pontuação: {{resposta.pontuacao | currency:'BRL':''}}</th>
                    </tr>
                    <tr>
                      <th class="text-center">Grupo</th>
                      <th class="text-center">Questão</th>
                      <th class="text-center">Resposta</th>
                      <th class="text-center" [width]="50">Pontuação</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="listaApres?.length != 0">
                    <tr *ngFor="let item of listaApres">
                      <td>{{item.grupoTitulo}}</td>
                      <td>{{item.grupoItemQuestao}}</td>
                      <td>{{item.resposta}}</td>
                      <td class="text-right">{{item.pontuacao  | currency:'BRL':''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-flat-button color="primary" type="button" [routerLink]="" (click)="print()" class="button-row">
            <span>Imprimir</span>
          </button>
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>