<div class="panel-group" role="tablist" aria-multiselectable="true">
  <div class="panel" id="panelFilter">
    <div class="panel-heading" role="tab" id="headingFilter">
      <h4 class="panel-title">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionFilter" href="#collapseFilter"
          aria-expanded="false" aria-controls="collapseFilter">
          <span class="material-icons">filter_alt</span> Filtros
        </a>
      </h4>
    </div>
    <div id="collapseFilter" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFilter"
      aria-expanded="false">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="grupo">Grupo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um termo válido.</mat-error>
            </mat-form-field>
          </div>

        </div>
      </div>
      <div class="panel-footer" style="text-align: right">
        <mat-slide-toggle style="margin-right: 10px;" color="primary" [(ngModel)]="searchRegistroAtivo.searchTerm">
          Somente Reg.Ativos
        </mat-slide-toggle>
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="card main-content">
  <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
  <div class="header">
    <h2>Configurações</h2>
  </div>
  <div class="body table-responsive">
    <!--query end-->
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead class="thead-dark">
        <tr>
          <th class="text-center">Grupo</th>
          <th class="text-center">Descrição</th>
          <th class="text-center">Valor</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="models?.length != 0">
        <tr *ngFor="let config of models">
          <td>
            {{config.grupo}}
          </td>
          <td>
            {{config.descricao}}
          </td>
          <td>
            <span *ngIf="config.valor == null">Em branco</span>
            {{config.valor}}
          </td>
          <td class="text-center">
            <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(config)"
              matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="fixed-action-btn">
    <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
      matTooltipPosition="above">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<app-config-modal #configModal (modalSave)="onResponse($event)"></app-config-modal>
