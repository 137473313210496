import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';
import { UsuariosComponent } from './usuarios.component';
import { UsuarioModalComponent } from './usuario-modal/usuario-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    UsuariosComponent,
    UsuarioModalComponent
  ]
})
export class UsuariosModule { }
