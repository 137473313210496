<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="nome">Nome Contato</mat-option>
                <mat-option value="profissionalSaude.nome">Profissional da Saúde</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um titulo válido.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="searchStatus.searchTerm">
                <mat-option value="-1">Todos</mat-option>
                <mat-option *ngFor="let status of typesService.contatoPreAgendamentoStatusType" [value]="status.value">{{status.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando Data por:</mat-label>
              <mat-select [(ngModel)]="filtroDataSelecionado" (selectionChange)="getSearchData($event.value)">
                <mat-option value="Nao filtrar data">Não Filtrar Data</mat-option>
                <mat-option *ngFor="let opcao of opcoesDatas" [value]="opcao.value">{{opcao.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 1">
            <mat-form-field>
              <mat-label>Período do Contato</mat-label>
              <input matInput [(ngModel)]="searchDataInicialContato.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 1">
            <mat-form-field>
              <input matInput [(ngModel)]="searchDataFinalContato.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 2">
            <mat-form-field>
              <mat-label>Período de Status</mat-label>
              <input matInput [(ngModel)]="searchDataInicialStatus.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 2">
            <mat-form-field>
              <input matInput [(ngModel)]="searchDataFinalStatus.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Contato: Pré-Agendamento</h2>
      </div>
      <div class="body table-responsive">
        <!--query end-->
        <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center" width="70">Inclusão</th>
              <th class="text-center" width="100">Status</th>
              <th class="text-center" width="70">Dt.Status</th>
              <th class="text-center" width="200">Nome</th>
              <th class="text-center" width="200">Profissional</th>
              <th class="text-center">Consulta</th>
              <th class="text-center">Exame1</th>
              <th class="text-center">Turno</th>
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let model of models">
              <td>
                {{model.dataInclusao | date :'dd/MM/yyyy'}}
              </td>
              <td>
                <span class="label" [ngClass]="typesService.getContatoPreAgendamentoStatusColor(model.status)">
                  {{typesService.getContatoPreAgendamentoStatusType(model.status)}}
                </span>
              </td>
              <td>
                {{model.dataStatus | date :'dd/MM/yyyy'}}
              </td>
              <td>
                {{model.nome}}
              </td>
              <td>
                {{model.profissionalSaudePessoaNomeFantasia}}
              </td>
              <td>
                {{model.consultaNome}}
              </td>
              <td>
                {{model.exame1Nome}}
              </td>
              <td>
                {{typesService.getTurnoType(model.turno)}}
              </td>
              <td class="text-center">
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(model)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(model.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fixed-action-btn">
        <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <app-contato-pre-agendamento-modal #modal (modalSave)="onResponse($event)"></app-contato-pre-agendamento-modal>
