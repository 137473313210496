<div class="row clearfix">

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Status Publicação</mat-label>
              <mat-select [(ngModel)]="searchStatus.searchTerm">
                <mat-option value="-1">Todos</mat-option>
                <mat-option *ngFor="let opcao of typesService.artigoStatusType" [value]="opcao.value">{{opcao.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="titulo">Titulo</mat-option>
                <mat-option value="autor">Autor</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um titulo válido.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Local Publicação</mat-label>
              <mat-select [(ngModel)]="searchLocaisPublicacao.searchTerm" (selectionChange)="getTipoLocalPublicacao($event.value)">
                <mat-option value="">Todos os Locais de Publicação</mat-option>
                <mat-option *ngFor="let localPub of artigoLocaisPublicacao" [value]="localPub.value">{{localPub.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Artigo</h2>
      </div>
      <div class="body table-responsive">        
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center" width="150">Imagem Capa</th>
              <th class="text-center">Data</th>
              <th class="text-center">Autor</th>
              <th class="text-center">Titulo</th>
              <th class="text-center">Tags</th>
              <th class="text-center">Locais Publicação</th>
              <th class="text-center" width="150">Status</th>
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let artigo of models">
              <td class="text-center">
                <img src="{{artigo.imagemCapaUrl}}" style="height: 100px;">
              </td>
              <td>
                {{commonService.fromJsonDate(artigo.dataArtigo) | date: 'dd/MM/yyyy'}}
              </td>
              <td>
                {{artigo.autor}}
              </td>
              <td>
                {{artigo.titulo}}
              </td>
              <td>
                <p *ngFor="let tag of artigo.tagNomes" style="margin: 2px;">
                  <span class="label bg-blue">
                    {{tag}}
                  </span>                  
                </p>
              </td>
              <td>
								{{getLocaisPublicacaoNomes(artigo.locaisPublicacao)}}
              </td>
              <td class="text-center">
                <span class="label" [ngClass]="typesService.getArtigoStatusTypeColor(artigo.publicado)">
                  {{typesService.getArtigoStatusType(artigo.publicado)}}
                </span>
              </td>
              <td class="text-center">
                <button
                  *ngIf="!artigo.publicado"
                  mat-icon-button type="button" (click)="ativar(artigo)" matTooltip="Publicar Artigo">
                  <mat-icon>done</mat-icon>
                </button>
                <button
                  *ngIf="artigo.publicado"
                  mat-icon-button type="button" (click)="inativar(artigo)" matTooltip="Despublicar Artigo">
                  <mat-icon>highlight_off</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(artigo)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(artigo.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
      </table>
    </div>
    <div class="fixed-action-btn">
      <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<app-artigo-modal #artigoModal (modalSave)="onResponse($event)"></app-artigo-modal>
