import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { TenantService, CidadeService, EstadoService } from 'projects/ProjetoBaseAngular/domain/services';
import { TenantModel, EstadoModel, CidadeModel, ImagemModel, TenantLicencaModel, TenantComponenteModel } from 'projects/ProjetoBaseAngular/domain/models';
import { PessoaType } from 'projects/ProjetoBaseAngular/domain/types';
import { ImagemSelectModalComponent } from '../../galeria/imagens/imagem-select-modal/imagem-select-modal.component';

@Component({
  selector: 'app-tenant-modal',
  templateUrl: './tenant-modal.component.html'
})
export class TenantModalComponent extends BaseCrudModalComponent<TenantModel> implements OnInit {
  @ViewChild('tenantModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  estados: EstadoModel[];
  cidades: CidadeModel[];

  cnpjCpfMask = '';
  cnpjCpfPlaceholder = '';
  tipos = [
    { text: 'Jurídica', value: PessoaType.Juridica },
    { text: 'Física', value: PessoaType.Fisica }
  ];

  readonly pessoaJuridica = PessoaType.Juridica;
  readonly pessoaFisica = PessoaType.Fisica;

  constructor(
    injector: Injector,
    readonly tenantService: TenantService,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService
  ) {
    super(injector, tenantService);
  }

  protected initializeForm(tenant: TenantModel) {
    this.form = this.formBuilder.group({
      id: tenant.id,
      dataInclusao: [tenant.dataInclusao, Validators.required],
      dataAlteracao: tenant.dataAlteracao,
      tipoPessoa: [tenant.tipoPessoa, Validators.required],
      cnpjCpf: [tenant.cnpjCpf, [
        Validators.required,
        Validators.maxLength(18)]
      ],
      iestadualRg: [tenant.iestadualRg, [
        Validators.maxLength(18)]
      ],
      imunicipal: [tenant.imunicipal, [
        Validators.maxLength(18)]
      ],
      nomeFantasia: [tenant.nomeFantasia, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(60)]
      ],
      razaoSocial: [tenant.razaoSocial, [
        Validators.maxLength(60)]
      ],
      site: [tenant.site, [
        Validators.maxLength(50)]
      ],
      imagemUrl: [tenant.imagemUrl],
      imagemUrlBase: [tenant.imagemUrlBase],
      imagemPath: [tenant.imagemPath],
      celular: [tenant.celular, [
        Validators.required,
        Validators.maxLength(15)]
      ],
      telefone: [tenant.telefone, [
        Validators.maxLength(15)]
      ],
      email: [tenant.email, [
        Validators.maxLength(50)]
      ],
      subdominio: [tenant.subdominio, [
        Validators.required,
        Validators.maxLength(50)]
      ],
      tokenWhatsAppApi: [tenant.tokenWhatsAppApi, Validators.maxLength(100)],
      ruaAvenida: [tenant.ruaAvenida, [
        Validators.minLength(5),
        Validators.maxLength(50)]
      ],
      numero: [tenant.numero, Validators.maxLength(10)],
      complemento: [tenant.complemento, Validators.maxLength(50)],
      bairro: [tenant.bairro, Validators.maxLength(50)],
      cep: [tenant.cep, Validators.maxLength(10)],
      estadoId: tenant.estadoId,
      cidadeId: tenant.cidadeId,
      licenca: this.formBuilder.group({
        id: tenant.licenca?.id,
        dataInclusao: [tenant.licenca?.dataInclusao, Validators.required],
        dataAlteracao: tenant.licenca?.dataAlteracao,
        dataExpiracao: [
          this.fromJsonDate(tenant.licenca?.dataExpiracao), [
            Validators.required
          ]
        ],
        diasCarencia: [tenant.licenca?.diasCarencia, Validators.required],
        nomePlano: [
          tenant.licenca?.nomePlano, [
            Validators.maxLength(60),
            Validators.required,
          ]
        ]
      }),
      componentes: null
    });

    this.form.patchValue({ componentes: tenant.componentes });

    this.changeTipoPessoa(tenant.tipoPessoa);
  }

  ngOnInit() {
    super.ngOnInit();
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  changeTipoPessoa(pessoaType: PessoaType) {
    switch (Number(pessoaType)) {
      case PessoaType.Juridica:
        this.cnpjCpfMask = '00.000.000/0000-00';
        this.cnpjCpfPlaceholder = '00.000.000/0000-00';
        break;

      case PessoaType.Fisica:
        this.cnpjCpfMask = '000.000.000-00';
        this.cnpjCpfPlaceholder = '000.000.000-00';
        break;
    }
  }

  changeCep(cep: string) {
    if (cep) {
      this.commonService.queryPostalCode(cep).subscribe((endereco: any) => {
        this.form.patchValue({
          ruaAvenida: endereco.logradouro,
          bairro: endereco.bairro
        });

        const estadoId = this.estados.find(x => x.uf === endereco.uf).id;
        this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => {
          this.cidades = cidades;
          const cidadeId = this.cidades.find(x => x.codigoIbge === endereco.ibge).id;

          this.form.patchValue({ estadoId, cidadeId });
        });
      });
    }
  }

  changeEstado(estadoId: string) {
    this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
  }

  beforeShowCreate(model: TenantModel) {
    model.tipoPessoa = PessoaType.Juridica;
    model.licenca = new TenantLicencaModel();
    model.licenca.id = model.id;
    model.licenca.dataInclusao = new Date();
    model.licenca.diasCarencia = 2;
    return model;
  }

  beforeShowEdit(model: TenantModel) {
    model.estadoId && this.changeEstado(model.estadoId);
    if (!model.licenca) {
      model.licenca = new TenantLicencaModel();
      model.licenca.id = model.id;
      model.licenca.dataInclusao = new Date();
      model.licenca.diasCarencia = 2;
    }
    return model;
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }

  onComponentesResponse(componentes: TenantComponenteModel[]) {
    componentes.forEach(x => x.tenantId = this.form.value.id);
    this.form.patchValue({ componentes });
  }
}
