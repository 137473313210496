import { Component, OnInit, Injector, ViewChild, HostListener } from '@angular/core';
import Swal from 'sweetalert2';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { UsuarioModel, TenantModel, ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { UsuarioModalComponent } from './usuario-modal/usuario-modal.component';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-admin-usuario',
  templateUrl: './usuarios.component.html'
})
export class UsuariosComponent extends BaseComponent implements OnInit {
  @ViewChild('usuarioModal') usuarioModal: UsuarioModalComponent;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F9' && document.querySelectorAll('.modal-backdrop.fade.in').length === 0) {
      this.create();
    }
  }

  isKleinCode: boolean;
  tenants: TenantModel[];

  searchGrupo = new ModelSearch('grupo', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);
  searchAtivo = new ModelSearch('ativo', OptionSearchType.Equals, SearchType.Boolean, true);

  dtOptions: DataTables.Settings = {};
  usuarios: UsuarioModel[];


  filterOptions = [
    { text: 'Começando com', value: OptionSearchType.StartWith },
    { text: 'Contendo', value: OptionSearchType.Contains },
    { text: 'Terminando com', value: OptionSearchType.EndWith },
    { text: 'Igual', value: OptionSearchType.Equals }
  ];

  constructor(
    injector: Injector,
    private readonly usuarioService: UsuarioService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.isKleinCode = this.usuarioService.getTenant() === 'kleincode';
    this.dtOptions = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        const searches = [];

        (this.searchGrupo.searchTerm) && searches.push(this.searchGrupo);
        (this.search.searchTerm) && searches.push(this.search);
        searches.push(this.searchAtivo);

        dataTablesParameters.searches = searches;

        this.usuarioService.getResponse(dataTablesParameters).subscribe(resp => {
          this.usuarios = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { data: 'nome' },
        { data: 'sobrenome' },
        { data: 'email' },
        { data: 'userNameTenant' },
        { data: 'grupoUsuario.nome' },
        { data: 'ativo' },
        { data: 'superUsuarioTenant' }
      ]
    };

    if (this.isKleinCode) {
      this.dtOptions.columns.push({ data: 'tenant.nomeFantasia' })
    }
  }

  filter() {
    this.datatable.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  create() {
    this.usuarioModal.showCreate();
  }

  edit(usuario: UsuarioModel) {
    this.usuarioModal.showEdit(usuario.id);
  }

  editPassword(usuario: UsuarioModel) {
    Swal.fire({
      title: `Alterar Senha do usuário "${usuario.nome}"`,
      input: 'password',
      inputPlaceholder: 'Entre com a nova senha',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const password: string = result.value as string;
        usuario.password = password;
        usuario.confirmPassword = password;
        this.usuarioService.editPassword(usuario).subscribe(() => {
          this.snackBar.open('Senha alterada com sucesso!', 'Ok', {
            duration: 3000
          });
        });
      }
    });
  }

  delete(id: string) {
    Swal.fire({
      title: 'Tem certeza de que deseja excluir este registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        this.usuarioService.delete(id).subscribe(() => {
          const index = this.usuarios.findIndex(x => x.id === id);
          this.usuarios.splice(index, 1);
          this.snackBar.open('Excluído com sucesso!', 'Ok', {
            duration: 3000
          });
        });
      }
    });
  }

  definirSuperUsuario(usuario: UsuarioModel) {
    Swal.fire({
      title: `Tem certeza de que deseja definir ${usuario.nome} como "Super Usuário"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        this.usuarioService.editSuperUsuario(usuario).subscribe((usuario: UsuarioModel) => {
          this.onResponse(usuario);
        });
      }
    });
  }

  onResponse(usuario: UsuarioModel) {
    if (usuario === null)
      return;

    this.snackBar.open('Salvo com sucesso!', 'Ok', {
      duration: 3000
    });

    const index = this.usuarios.findIndex(x => x.id === usuario.id);
    if (index === -1) {
      this.usuarios.push(usuario);
    } else {
      this.usuarios[index] = usuario;
    }
  }

  isAllowed(value: string) {
    const type = this.usuarioService.getController();
    return super.isAllowed(value, type);
  }
}
