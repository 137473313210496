<div class="row">
  <div class="col-md-12">
    <button mat-flat-button color="primary" type="button" (click)="create()">Adicionar Item</button>
  </div>
  <hr/>
  <div class="col-md-12">
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
       style="width: 100%;">  
       <thead>
        <tr>
          <th class="text-center">Profissional</th>
          <th class="text-center" width="150">Pagto Laudo(%)</th>
          <th class="text-center" width="150">Clínica(%)</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="models?.length != 0">
        <tr *ngFor="let item of models">
          <td>
            {{item.profissionalSaudeNomeApresentacao}}
          </td>
          <td class="text-right">
            {{item.percLaudo | currency: 'BRL':''}}%
          </td>
          <td class="text-right">
            {{item.percClinica | currency: 'BRL':''}}%
          </td>
          <td class="text-center">
            <button mat-icon-button type="button" (click)="edit(item)" matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="delete(item.id)" matTooltip="Excluir">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-procedimento-convenio-profissional-modal #modal (modalSave)="onResponse($event)" 
    [procedimentoConvenioId]="procedimentoConvenioId">
  </app-procedimento-convenio-profissional-modal>
</div>