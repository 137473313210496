import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { ChatBotNodoModalComponent } from './chatbot-nodo-modal/chatbot-nodo-modal.component';
import { ChatbotConfigComponent } from './chatbot-config.component';
import { ChatBotRespAlternativaComponent } from './chatbot-opcao-alternativa/chatbot-resp-alternativa.component';
import { ChatBotRespAlternativaModalComponent } from './chatbot-opcao-alternativa/chatbot-opcao-alternativa-modal/chatbot-resp-alternativa-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatExpansionModule
  ],
  declarations: [
    ChatbotConfigComponent,
    ChatBotNodoModalComponent,
    ChatBotRespAlternativaComponent,
    ChatBotRespAlternativaModalComponent
  ]
})
export class ChatBotConfigModule { }
