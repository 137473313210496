<div bsModal #curriculoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="curriculoModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Curriculo</h2>
        </div>

        <div class="modal-body">

          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group align-center">
                  <div style="height: 150px; width: 100%; object-fit: contain;">
                    <img src="{{form.value.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                      *ngIf="form.value.imagemUrl">
                  </div>
                  <div class="align-center">
                    <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                      <mat-icon>add_photo_alternate</mat-icon>
                    </button>
                    <button mat-icon-button type="button" [disabled]="!form.value.imagemUrl"
                      (click)="deselectImage()" matTooltip="Desvincular Imagem">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                  <p class="font-15 col-deep-blue text-center">
                    O tamanho ideal para Imagens é <strong>800x600</strong>
                  </p>
                </div>
              </div>

              <div class="col-md-10">
                <h4 class="card-inside-title">Dados Principais</h4>
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Data Inclusão</mat-label>
                      <input matInput disabled type="datetime" value="{{form.value.dataInclusao | date :'dd/MM/yyyy HH:mm'}}" />
                    </mat-form-field>
                  </div>

                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Status Curriculo</mat-label>
                      <mat-select formControlName="status">
                        <mat-option *ngFor="let status of statusCurriculo" [value]="status.value">
                          {{status.text}}</mat-option>
                      </mat-select>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Data de Nascimento</mat-label>
                      <input matInput formControlName="dataNascimento" type="date" #focus/>
                      <mat-error>Informe uma Data de Nascimento válida.</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3">
										<mat-form-field>
                      <mat-label>Sexo</mat-label>
                      <mat-select formControlName="sexo">
												<mat-option *ngFor="let sexo of sexos" [value]="sexo.value">
													{{sexo.text}}</mat-option>
											</mat-select>
											<mat-error></mat-error>
										</mat-form-field>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>CPF</mat-label>
                      <input matInput formControlName="cpf" type="text" [dropSpecialCharacters]="false"
                        mask="{{cpfMask}}" placeholder="{{cpfPlaceholder}}" />
                        <mat-hint align="end">Máx 18 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-9">
                    <mat-form-field>
                      <mat-label> Nome Completo </mat-label>
                      <input matInput formControlName="nome" type="text" />
                        <mat-hint align="end">Máx 60 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <h4 class="card-inside-title">Dados para Contato</h4>
                <div class="row">
                  <div class="col-md-2">
                    <mat-form-field>
                      <mat-label>Telefone</mat-label>
                      <input matInput formControlName="telefone" type="tel" placeholder="Ex: (48) 3333-3333" />
                      <mat-hint align="end">Máx 15 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <mat-form-field>
                      <mat-label>Celular</mat-label>
                      <input matInput formControlName="celular" type="tel" [dropSpecialCharacters]="false"
                        mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" />
                        <mat-hint align="end">Máx 15 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>E-mail</mat-label>
                      <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com" />
                      <mat-hint align="end">Máx 50 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <mat-label>Site</mat-label>
                        <input matInput formControlName="site" type="url" placeholder="Ex: meusite.com" />
                        <mat-hint align="end">Máx 50 caracteres</mat-hint>
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h4 class="card-inside-title">Dados Adicionais</h4>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Vaga</mat-label>
                <mat-select formControlName="vagaId">
                  <mat-option *ngFor="let vaga of vagas" [value]="vaga.id">
                    {{vaga.titulo}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Área Atuação</mat-label>
                <input matInput #areaAtuacao formControlName="areaAtuacao" maxlength="60"/>
                <mat-hint align="end">Máx 60 caracteres</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observação Interna</mat-label>
                <textarea matInput #observacaoInterna formControlName="observacaoInterna" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{observacaoInterna.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <h4 class="card-inside-title">Currículo</h4>
          <div class="row" >
            <div class="col-md-12">
              <p class="text-left"> Selecione Seu Curriculo</p>
              <p class="font-15 col-deep-orange text-left">
                O tamanho máximo permitido do arquivo é de <strong>50MB</strong>
              </p>

              <div class="form-group">
                <input #fileUpload formControlName="arquivoCurriculoUrl" type="file" />
                <mat-progress-bar mode="indeterminate" *ngIf="file.inProgress"></mat-progress-bar>
                <div class="alert alert-success" role="alert" *ngIf="file.completed" i18n>Arquivo salvo com sucesso!
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="!newItem">
            <div class="col-md-10">
              <mat-form-field>
                <mat-label>Arquivo Do Curriculo</mat-label>
                <input matInput type="url" disabled value="{{form.value.arquivoCurriculoUrl}}"/>
              </mat-form-field>
            </div>
            <a mat-flat-button href="{{form.value.arquivoCurriculoUrl}}" [disabled]="!form.value.arquivoCurriculoUrl"
              color="primary" target="_blank" rel="noopener" class="button-row" matTooltip="Abrir Arquivo Curriculo">
              <span> Abrir PDF </span>
            </a>
          </div>

          <div>
            <h4 class="card-inside-title">Dados de endereço</h4>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>CEP</mat-label>
                  <input matInput formControlName="cep" type="text" [dropSpecialCharacters]="false" mask="00.000-000"
                    placeholder="Ex: 00.000-000" (blur)="changeCep($event.target.value)" />
                    <mat-hint align="end">Máx 10 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Rua / Avenida</mat-label>
                  <input matInput formControlName="ruaAvenida" type="text" />
                  <mat-hint align="end">Máx 50 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Número</mat-label>
                  <input matInput formControlName="numero" type="text" />
                  <mat-hint align="end">Máx 10 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Complemento</mat-label>
                  <input matInput formControlName="complemento" type="text" />
                  <mat-hint align="end">Máx 50 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Bairro</mat-label>
                  <input matInput formControlName="bairro" type="text" />
                  <mat-hint align="end">Máx 50 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Estado</mat-label>
                  <mat-select formControlName="estadoId" (selectionChange)="changeEstado($event.value)">
                    <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.uf}}
                    </mat-option>
                  </mat-select>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Cidade</mat-label>
                  <mat-select formControlName="cidadeId">
                    <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                    </mat-option>
                  </mat-select>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
