import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-previews',
  templateUrl: './previews.component.html',
  styleUrls: ['./previews.component.scss']
})
export class PreviewsComponent implements OnInit {
  @Input() layout: number;
  @Input() colorPrimary: string;
  @Input() colorSecondary: string;

  constructor() { }

  ngOnInit() {
  }

}
