import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { EstadoService } from 'projects/ProjetoBaseAngular/domain/services';
import { EstadoModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-estado-modal',
  templateUrl: './estado-modal.component.html'
})
export class EstadoModalComponent extends BaseCrudModalComponent<EstadoModel> implements OnInit {
  @ViewChild('estadoModal') modal: ModalDirective;

  constructor(
    injector: Injector,
    estadoService: EstadoService
  ) {
    super(injector, estadoService);
  }

  protected initializeForm(estado: EstadoModel) {
    this.form = this.formBuilder.group({
      id: estado.id,
      dataInclusao: [estado.dataInclusao, Validators.required],
      dataAlteracao: estado.dataAlteracao,
      codigoIbge: [estado.codigoIbge, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.minLength(2),
        Validators.maxLength(2)]
      ],
      uf: [estado.uf, [
        Validators.required,
        Validators.pattern(/^[A-Z]*$/),
        Validators.minLength(2),
        Validators.maxLength(2)]
      ],
      nome: [estado.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40)]
      ]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
