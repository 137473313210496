<div #relatorio class="visible-print">
  <table class="mb-1" border="1" cellspacing="5" cellpadding="5" style="width: 100%;" *ngIf="docSus">
    <tbody>
      <tr>
        <td valign="bottom" style="width: 50%;">
          <div class="row">
            <div class="col">
              <img src="assets/images/sus.jpg" height="80" />
            </div>
            <div class="col">
              <h5 class="font-weight-bold p-0 m-0">Estado de Santa Catarina</h5>
              <h5 class="font-weight-bold p-0 m-0">Ministério da Saúde</h5>
            </div>
          </div>
        </td>
        <td style="width: 50%;" *ngIf="docSus.tipo === typesService.DocSusType.BpaI">
          <h5 class="font-weight-bold p-0 m-0" align="center">Laudo Médico para Solicitação de Procedimentos Processados
            Através do BPA-I</h5>
        </td>
        <td style="width: 50%;" *ngIf="docSus.tipo === typesService.DocSusType.Apac">
          <h5 class="font-weight-bold p-0 m-0" align="center">APAC - Autorização de Procedimentos Ambulatoriais</h5>
          <h5 class="font-weight-bold p-0 m-0" align="center">Laudo de Solicitação / Autorização</h5>
        </td>
      </tr>
    </tbody>
  </table>
  <table border="1" cellspacing="5" cellpadding="5" style="width: 100%;" *ngIf="paciente">
    <tbody>
      <tr>
        <td colspan="13" style="background: silver;">
          <p class="font-weight-bold fs-14 p-0 m-0" align="center">IDENTIFICA&#199;&#195;O DO ESTABELECIMENTO DE SA&#218;DE
            (SOLICITANTE)</p>
        </td>
      </tr>
      <tr>
        <td colspan="11" valign="top">
          <p class="fs-12 p-0 m-0">1 - NOME DO ESTABELECIMENTO DE SA&#218;DE</p>
          <p class="p-0 m-0">{{tenant.nomeFantasia}}</p>
        </td>
        <td colspan="2">
          <p class="fs-12 p-0 m-0">2 - CNES</p>
          <p class="p-0 m-0">{{docSusConfig.codigoCnes}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" style="background: silver;">
          <p class="font-weight-bold fs-14 p-0 m-0" align="center">IDENTIFICA&#199;&#195;O DO PACIENTE</p>
        </td>
      </tr>
      <tr>
        <td colspan="11" valign="top">
          <p class="fs-12 p-0 m-0">3 - NOME DO PACIENTE</p>
          <p class="p-0 m-0">{{paciente.pessoa.nomeFantasia}}</p>
        </td>
        <td colspan="2" valign="top">
          <p class="fs-12 p-0 m-0">4 - N&#186; DO PRONTU&#193;RO</p>
          <p class="p-0 m-0">{{paciente.pessoa.codigo}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="3" valign="top">
          <p class="fs-12 p-0 m-0">5 - CART&#195;O NACIONAL DA SA&#218;DE</p>
        </td>
        <td colspan="4" valign="top">
          <p class="fs-12 p-0 m-0">6 - DATA DE NASCIMENTO</p>
          <p class="p-0 m-0">{{paciente.pessoa.dataNascimento | date: 'dd/MM/yyyy'}}</p>
        </td>
        <td colspan="4" valign="top">
          <p class="fs-12 p-0 m-0">7 - SEXO</p>
          <p class="p-0 m-0">{{typesService.getSexoTypeNome(paciente.pessoa.sexo)}}</p>
        </td>
        <td colspan="2" valign="top">
          <p class="fs-12 p-0 m-0">8 - RAÇA/COR</p>
          <p class="p-0 m-0">{{typesService.getRacaType(paciente.raca)}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7" rowspan="2" valign="top">
          <p class="fs-12 p-0 m-0">9 - NOME DA MÃE</p>
          <p class="p-0 m-0">{{paciente.nomeMae}}</p>
        </td>
        <td colspan="6">
          <p class="fs-12 p-0 m-0">10 - DDD TELEFONE DE CONTATO</p>
        </td>
      </tr>
      <tr>
        <td colspan="6" valign="top">
          <p class="p-0 m-0">&#160;</p>
        </td>
      </tr>
      <tr>
        <td colspan="7" rowspan="2" valign="top">
          <p class="fs-12 p-0 m-0">11 - NOME DO RESPONS&#193;VEL</p>
          <p class="p-0 m-0">{{paciente.nomeResponsavel}}</p>
        </td>
        <td colspan="6" valign="top">
          <p class="fs-12 p-0 m-0">12 - DDD TELEFONE DE CONTATO</p>
        </td>
      </tr>
      <tr>
        <td colspan="6">
          <p class="p-0 m-0">{{(paciente.pessoa.celular || '.') + ' ' + (paciente.pessoa.telefone || '')}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top">
          <p class="fs-12 p-0 m-0">13 - ENDERE&#199;O (RUA, N&#186;, BAIRRO)</p>
            <p class="p-0 m-0">{{(endereco || '.')}}</p> 
        </td>
      </tr>
      <tr>
        <td colspan="5" valign="top">
          <p class="fs-12 p-0 m-0">14 - MUN&#205;CIPIO DE RESID&#202;NCIA</p>
          <p class="p-0 m-0">{{(cidade?.nome || '.')}}</p>
        </td>
        <td colspan="4" valign="top">
          <p class="fs-12 p-0 m-0">15 - COD. IBGE MUNICIPIO</p>
          <p class="p-0 m-0">{{(cidade?.codigoIbge || '.')}}</p>
        </td>
        <td colspan="2" valign="top">
          <p class="fs-12 p-0 m-0">16 - UF</p>
          <p class="p-0 m-0">{{(cidade?.estadoUF || '.')}}</p>
        </td>
        <td colspan="2" valign="top">
          <p class="fs-12 p-0 m-0">17 - CEP</p>
          <p class="p-0 m-0">{{(paciente.pessoa.cep || '.')}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" style="background: silver;">
          <p class="font-weight-bold fs-14 p-0 m-0" align="center">PROCEDIMENTO SOLICITADO</p>
        </td>
      </tr>
      <tr *ngFor="let index of [0, 1, 2]">
        <td valign="top" height="50">
          <p class="fs-12 p-0 m-0">C&#211;DIGO DO PROCEDIMENTO</p>
          <p class="p-0 m-0">{{docSus.procedimentos[index]?.procedimentoCodigo}}</p>
        </td>
        <td colspan="11" valign="top">
          <p class="fs-12 p-0 m-0">NOME DO PROCEDIMENTO</p>
          <p class="p-0 m-0">{{docSus.procedimentos[index]?.procedimentoNome}}</p>
        </td>
        <td valign="top">
          <p class="fs-12 p-0 m-0">QTDE</p>
          <p class="p-0 m-0">{{docSus.procedimentos[index]?.quantidade}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" style="background: silver;">
          <p class="font-weight-bold fs-14 p-0 m-0" align="center">JUSTIFICATIVA DO(S) PROCEDIMENTO(S) SOLICITADO(S)</p>
        </td>
      </tr>
      <tr>
        <td colspan="4" valign="top" height="50">
          <p class="fs-12 p-0 m-0">DESCRI&#199;&#195;O DO DIAGN&#211;STICO</p>
          <p class="p-0 m-0">{{docSus.descricaoDiagnostico}}</p>
        </td>
        <td colspan="2" valign="top">
          <p class="fs-12 p-0 m-0">CID-10 PRINCIPAL</p>
          <p class="p-0 m-0">{{docSus.cid10Principal}}</p>
        </td>
        <td colspan="3" valign="top">
          <p class="fs-12 p-0 m-0">CID-10 SECUND&#193;RIO</p>
          <p class="p-0 m-0">{{docSus.cid10Secundario}}</p>
        </td>
        <td colspan="4" valign="top">
          <p class="fs-12 p-0 m-0">CID-10 OUTRAS CAUSAS ASSOCIADAS</p>
          <p class="p-0 m-0">{{docSus.cid10CausasAssociada}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="130">
          <div style="word-break: break-all; white-space: normal;">
            <p class="fs-12 p-0 m-0">RESUMO DA ANAMNESE E EXAME F&#205;SICO</p>
            <p class="p-0 m-0">{{docSus.resumoAnamnese}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="120">
          <div style="word-break: break-all; white-space: normal;">
            <p class="fs-12 p-0 m-0">JUSTIFICATIVA PROCEDIMENTO</p>
            <p class="p-0 m-0">{{docSus.justifProcedimento}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" style="background: silver;">
          <p class="font-weight-bold fs-14 p-0 m-0" align="center">SOLICITA&#199;&#195;O</p>
        </td>
      </tr>
      <tr>
        <td colspan="8" valign="top">
          <p class="fs-12 p-0 m-0">NOME DO PROFISSIONAL SOLICITANTE</p>
          <p class="p-0 m-0">{{profissionalSolicitante?.pessoa?.nomeFantasia}}</p>
        </td>
        <td colspan="2" valign="top">
          <p class="fs-12 p-0 m-0">DATA DA SOLICITAÇÃO</p>
          <p class="p-0 m-0">{{docSus.dataInclusao | date: 'dd/MM/yyyy'}}</p>
        </td>
        <td colspan="3" rowspan="2" valign="top" align="center">
          <p class="fs-12 p-0 m-0">ASSINATURA E CARIMBO</p>
        </td>
      </tr>
      <tr>
        <td colspan="2" valign="top" align="center">
          <p class="fs-12 p-0 m-0">DOCUMENTO</p>
          <p class="p-0 m-0">( &nbsp; ) CNS&nbsp;&nbsp;&nbsp;&nbsp;( X ) CPF</p>
        </td>
        <td colspan="8" valign="top" align="center">
          <p class="fs-12 p-0 m-0">NÚMERO DO CNS/CPF</p>
          <p class="p-0 m-0">{{profissionalSolicitante?.pessoa?.cnpjCpf}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" style="background: silver;">
          <p class="font-weight-bold fs-14 p-0 m-0" align="center">AUTORIZA&#199;&#195;O</p>
        </td>
      </tr>
      <tr>
        <td colspan="8" valign="top">
          <p class="fs-12 p-0 m-0">NOME DO PROFISSIONAL AUTORIZADOR</p>
          <p class="p-0 m-0">{{profissionalAutorizador?.pessoa?.nomeFantasia || "."}}</p>
        </td>
        <td colspan="2" valign="top">
          <p class="fs-12 p-0 m-0">COD ORGÃO EMISSOR</p>
        </td>
        <td colspan="3" rowspan="2" valign="top" align="center">
          <p class="fs-12 p-0 m-0">ASSINATURA E CARIMBO</p>
        </td>
      </tr>
      <tr>
        <td colspan="2" valign="top" align="center">
          <p class="fs-12 p-0 m-0">DOCUMENTO</p>
          <p class="p-0 m-0">( &nbsp; ) CNS&nbsp;&nbsp;&nbsp;&nbsp;( X ) CPF</p>
        </td>
        <td colspan="8" valign="top" align="center">
          <p class="fs-12 p-0 m-0">NÚMERO DO CNS/CPF</p>
        </td>
      </tr>
      <tr>
        <td colspan="10" valign="top" height="50">
          <p class="fs-12 p-0 m-0">NÚMERO DE AUTORIZAÇÃO</p>
        </td>
        <td colspan="3" valign="top">
          <p class="fs-12 p-0 m-0" align="center">DATA AUTORIZAÇÃO</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" style="background: silver;">
          <p class="font-weight-bold fs-14 p-0 m-0" align="center">IDENTIFICAÇÃO DO ESTABELECIMENTO DE SAÚDE (EXECUTANTE)</p>
        </td>
      </tr>
      <tr>
        <td colspan="10" valign="top">
          <p class="fs-12 p-0 m-0">NOME DO ESTABELECIMENTO DE SA&#218;DE EXECUTANTE</p>
          <p class="p-0 m-0">{{tenant.nomeFantasia}}</p>
        </td>
        <td colspan="3" valign="top">
          <p class="fs-12 p-0 m-0" align="center">2 - CNES</p>
          <p class="p-0 m-0" align="center">{{docSusConfig.codigoCnes}}</p>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="p-0 m-0">&#160;</p>
</div>