<div bsModal #menuModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="menuModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-lg">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Menu</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<div class="row">
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Título</mat-label>
								<input matInput formControlName="titulo" type="text"
									(blur)="slugify($event.target.value)" #focus />
								<mat-hint align="end">Máx 100 caracteres</mat-hint>
								<mat-error>Informe um Título válido.</mat-error>
							</mat-form-field>
						</div>
						
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Tipo</mat-label>
								<mat-select formControlName="tipo">
									<mat-option *ngFor="let tipo of tipos" [value]="tipo.value">{{tipo.text}}
									</mat-option>
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-3" *ngIf="form.value.tipo === MenuType.Principal">
							<mat-form-field>
								<mat-label>Menu Pai</mat-label>
								<mat-select formControlName="menuPaiId">
									<mat-option value="">Não Informado</mat-option>
									<mat-option *ngFor="let menuPai of menusPai" [value]="menuPai.id">{{menuPai.titulo}}
									</mat-option>
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Ordem Apresentação</mat-label>
								<input matInput formControlName="ordem" type="number" />
								<mat-error>Informe um Ordem válido.</mat-error>
							</mat-form-field>
						</div>

					</div>
					<div class="row" formGroupName="link">
						<div class="col-md-9">
							<mat-form-field>
								<mat-label>URL</mat-label>
								<input matInput formControlName="url" type="text" />
								<mat-error>Informe uma URL válida.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Abrir link em...</mat-label>
								<mat-select formControlName="target">
									<mat-option *ngFor="let target of targets" [value]="target.value">{{target.text}}
									</mat-option>
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" color="primary"
						class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>