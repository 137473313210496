import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { EnqueteComponent } from './enquete.component';
import { EnqueteModalComponent } from './enquete-modal/enquete-modal.component';
import { EnqueteOpcaoComponent } from '../enquete-opcao/enquete-opcao.component';
import { EnqueteOpcaoModalComponent } from '../enquete-opcao/enquete-opcao-modal/enquete-opcao-modal.component';
import { EnqueteRespostasModalComponent } from './enquete-respostas-modal/enquete-respostas-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChartsModule
  ],
  declarations: [
    EnqueteComponent,
    EnqueteModalComponent,
    EnqueteOpcaoComponent,
    EnqueteOpcaoModalComponent,
    EnqueteRespostasModalComponent
  ]
})
export class EnqueteModule { }
