import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ReportModule } from '../../report/report.module';
import { CapturaComponent } from './captura.component';
import { CapturaModalComponent } from './captura-modal/captura-modal.component';
import { CapturaListModalComponent } from './captura-list-modal/captura-list-modal.component';
import { CapturaPrintComponent } from './captura-print/captura-print.component';
import { PacienteModule } from '../../paciente/paciente/paciente.module';

@NgModule({
  imports: [    
    SharedModule,    
    ReportModule,
    PacienteModule
  ],
  declarations: [
    CapturaComponent,
    CapturaListModalComponent,
    CapturaModalComponent,
    CapturaPrintComponent   
  ],
  exports: [
    CapturaListModalComponent,
    CapturaModalComponent
  ]
})
export class CapturaModule { }
