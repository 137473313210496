import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { LaudoModalComponent } from './laudo-modal/laudo-modal.component';
import { LaudoModel } from '../domain/models';
import { LaudoService, ProcedimentoService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { DataTablesRequest, ModelSearch, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { LaudoStatusType } from '../domain/types';
import { PacienteModel } from '../../paciente/domain/models';
import { PacienteService } from '../../paciente/domain/services';
import * as moment from 'moment';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

@Component({
  selector: 'app-laudo',
  templateUrl: './laudo.component.html'
})
export class LaudoComponent extends BaseCrudComponent<LaudoModel> implements OnInit {
  @ViewChild('modal') modal: LaudoModalComponent;

  filtrarPeriodo = true;
  searchStatus = new ModelSearch('status', OptionSearchType.Equals, SearchType.Int, LaudoStatusType.Pendente);
  searchPaciente = new ModelSearch('pacienteId', OptionSearchType.Equals, SearchType.Guid);
  // searchProcedimento = new ModelSearch('procedimentoId', OptionSearchType.Contains, SearchType.Guid);
  searchProcedimento = new ModelSearch('procedimentoId', OptionSearchType.Equals, SearchType.Guid);
  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime, this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime, this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)));
  paciente: PacienteModel;
  listaProcedimento: SelectDto[];
  
  //procedimentoIds: string[] = [];

  selectColumns = [
    { data: 'dataInclusao' },
    { data: 'profissionalSolicitante.pessoa.nomeFantasia' },
    { data: 'profissionalLaudante.pessoa.nomeFantasia' },
    { data: 'paciente.pessoa.nomeFantasia' },
    { data: 'convenio.nome' },
    { data: 'procedimento.nome' },
    { data: 'status' },
  ];

  readonly typesService: TypesService;
  readonly pacienteService: PacienteService;
  readonly procedimentoService: ProcedimentoService;

  constructor(
    injector: Injector,
    readonly service: LaudoService,
    
  ) {
    super(injector, service);

    this.typesService = injector.get<TypesService>(TypesService);
    this.pacienteService = injector.get<PacienteService>(PacienteService);
    this.procedimentoService = injector.get<ProcedimentoService>(ProcedimentoService);

    this.onPacienteResponse(null);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[0, 'desc']];
    this.searchProcedimento.searchTerm = "-1";
    this.procedimentoService.getInternoSelectList().subscribe(x => this.listaProcedimento = x);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];

    if ((Number.isInteger(this.searchStatus.searchTerm)) && this.searchStatus.searchTerm >= 0) {
      searches.push(this.searchStatus);
    }

    (this.searchPaciente.searchTerm) && searches.push(this.searchPaciente);
    
    if (this.searchProcedimento.searchTerm !== "-1"){
      searches.push(this.searchProcedimento);
    }

    if (this.filtrarPeriodo) {
      if (this.searchDataInicial.searchTerm) {
        const searchDataInicial = Object.assign({}, this.searchDataInicial);
        searchDataInicial.searchTerm = `${this.fromJsonDate(searchDataInicial.searchTerm)} 00:00`;
        searches.push(searchDataInicial);
      }
      if (this.searchDataFinal.searchTerm) {
        const searchDataFinal = Object.assign({}, this.searchDataFinal);
        searchDataFinal.searchTerm = `${this.fromJsonDate(searchDataFinal.searchTerm)} 23:59`;
        searches.push(searchDataFinal);
      }
    }
    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  onPacienteResponse(pacienteId: string) {
    this.searchPaciente.searchTerm = pacienteId;
    if (!pacienteId) {
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
      if (this.searchStatus.searchTerm < 0) {
        this.searchStatus.searchTerm = LaudoStatusType.Pendente;
      }
    }
    else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;
        this.searchStatus.searchTerm = -1;
      });
    }
  }
}
