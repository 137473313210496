<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
<div class="card">
  <div class="header">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>Relatório - Repasses no Período</h2>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Inicial</mat-label>
          <input matInput [(ngModel)]="dataInicial" type="date" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Final</mat-label>
          <input matInput [(ngModel)]="dataFinal" type="date" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Profissionais</mat-label>
          <mat-select [(ngModel)]="profissionalIds" multiple>
            <mat-option *ngFor="let item of profissionais" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Convênios</mat-label>
          <mat-select [(ngModel)]="convenioIds" multiple>
            <mat-option *ngFor="let item of convenios" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Procedimentos</mat-label>
          <mat-select [(ngModel)]="procedimentoIds" multiple>
            <mat-option *ngFor="let item of procedimentos" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12 text-center">
        <button mat-flat-button color="primary" [disabled]="isBusy  || !isAllowed()" (click)="print()">
          <span>Gerar Relatório</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="text-center font-weight-bold">Relatório de Repasse Médico</h4>
          <div class="row">
            <div class="col-3">
              <strong>Data de Emissão:</strong> {{dataAtual}}
            </div>
            <div class="col">
              <strong>Período:</strong> {{dataInicial | date: 'dd/MM/yyyy'}} à {{dataFinal | date: 'dd/MM/yyyy'}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <strong>Profissionais:</strong> {{profissionalRecursoNomes}}
            </div>
            <div class="col">
              <strong>Convênios:</strong> {{convenioNomes}}
            </div>
            <div class="col">
              <strong>Procedimentos:</strong> {{procedimentoNomes}}
            </div>
          </div>

          <table border="1" cellpadding="5" cellspacing="5" style="width: 100%;" *ngIf="listaImpressao">
            <thead>
              <tr style="background-color: silver;">
                <!-- <th class="text-center">Profissional</th> -->
                <th class="text-center">Data Atend</th>
                <th class="text-center">Procedimento</th>
                <th class="text-center">Convênio</th>
                <th class="text-center">Paciente</th>
                <th class="text-center">Vlr Proc</th>
                <th class="text-center">Vlr Clínica</th>
                <th class="text-center">Vlr Médico</th>
                <th class="text-center">N-Fiscal</th>
              </tr>
            </thead>
            <tbody>
              <ng-template ngFor let-item [ngForOf]="listaImpressao" let-isLast="last">
                <tr>
                  <td colspan="4">
                    <strong>{{item.nome}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{item.vlrTotal | currency: 'BRL':''}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{item.vlrClinica | currency: 'BRL':''}}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{item.vlrMedico | currency: 'BRL':''}}</strong>
                  </td>
                  <td></td>
                </tr>
                <tr *ngFor="let subItem of item.itens">
                  <td>
                    {{subItem.dataAtendimento | date: 'dd/MM/yyyy'}}
                  </td>
                  <td>
                    {{subItem.procedimentoNome}}
                  </td>
                  <td>
                    {{subItem.convenioNome}}
                  </td>
                  <td>
                    {{subItem.pacienteNome}}
                  </td>
                  <td class="text-right">
                    {{subItem.vlrTotal | currency: 'BRL':''}}
                  </td>
                  <td class="text-right">
                    {{subItem.vlrClinica | currency: 'BRL':''}}
                  </td>
                  <td class="text-right">
                    {{subItem.vlrMedico | currency: 'BRL':''}}
                  </td>
                  <td>
                    {{subItem.notaFiscal}}
                  </td>
                </tr>
                <ng-container *ngIf="isLast && !contentPrinted">
                  {{ onContentPrinted() }}
               </ng-container>
              </ng-template>
              <tr style="background-color: silver;">
                <td class="text-center" colspan="4">
                  <strong>Total</strong>
                </td>
                <td class="text-right">
                  <strong>{{totalGeral | currency: 'BRL':''}}</strong>
                </td>
                <td class="text-right">
                  <strong>{{totalClinica | currency: 'BRL':''}}</strong>
                </td>
                <td class="text-right">
                  <strong>{{totalMedico | currency: 'BRL':''}}</strong>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>