import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { SetorModel } from '../../equipe/domain/models';
import { SetorService } from '../../equipe/domain/services';
import { CargoModel } from '../domain/models';
import { CargoService } from '../domain/services';
import { CargoModalComponent } from './cargo-modal/cargo-modal.component';

@Component({
  selector: 'app-cargo',
  templateUrl: './cargo.component.html',
})
export class CargoComponent extends BaseCrudComponent<CargoModel> implements OnInit {
  @ViewChild('cargoModal') modal: CargoModalComponent;

  setores: SetorModel[];
  cargos: CargoModel[];

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);
  searchSetores = new ModelSearch('setor.id', OptionSearchType.Equals, SearchType.Guid);
  searchCargos = new ModelSearch('cargoSuperior.id', OptionSearchType.Equals, SearchType.Guid);

  selectColumns = [ 
    {data: 'setor.nome'}, 
    {data: 'cargoSuperior.nome'}, 
    {data: 'nome'}
  ];

  constructor(
    injector: Injector,
    readonly cargoService: CargoService,
    readonly setorService: SetorService
  ) {
    super(injector, cargoService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);
    (this.searchSetores.searchTerm) && searches.push(this.searchSetores);
    (this.searchCargos.searchTerm) && searches.push(this.searchCargos);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();    
    this.setorService.get().subscribe(setores => this.setores = setores);
    this.cargoService.getAsync().subscribe(cargos => this.cargos = cargos);
  }
}
