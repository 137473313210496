import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { TypesService } from 'projects/admin/src/domain/services';
import { UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { ProtocoloDocModel } from '../../domain/models';
import { ProtocoloDocService } from '../../domain/services';

@Component({
  selector: 'app-protocolo-doc-print',
  templateUrl: './protocolo-doc-print.component.html'
})
export class ProtocoloDocPrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  protocoloDoc: ProtocoloDocModel;
  usuarioSolicitante: UsuarioModel;    
  paciente: PacienteModel;    

  constructor(        
    readonly usuarioService: UsuarioService,
    readonly protocoloDocService: ProtocoloDocService,
    readonly pacienteService: PacienteService,
    readonly printService: PrintService,
    readonly typesService: TypesService
  ) { }

  ngOnInit() {
  }

  async print(protocoloDocId: string) {
    this.protocoloDoc = await this.protocoloDocService.getById(protocoloDocId).toPromise();
    this.paciente = await this.pacienteService.getById(this.protocoloDoc.pacienteId).toPromise();
    this.usuarioSolicitante = await this.usuarioService.getById(this.protocoloDoc.usuarioIdAlteracao).toPromise();    

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Protocolo de Documento', content);
    }, 500);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }
}
