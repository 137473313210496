import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { AtendimentoSolicExameItemModel } from '../../../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';

@Component({
  selector: 'app-atendimento-solic-exame-item-modal',
  templateUrl: './atendimento-solic-exame-item-modal.component.html'
})
export class AtendimentoSolicExameItemModalComponent extends BaseComponent implements OnInit {
  @ViewChild('focus', { static: false }) focusElement: any;
  @ViewChild('modal', { static: false }) modal: ModalDirective;
  detailMode: boolean;  

  @Output() modalSave: EventEmitter<AtendimentoSolicExameItemModel> = new EventEmitter<AtendimentoSolicExameItemModel>();

  model: AtendimentoSolicExameItemModel;

  constructor(
    injector: Injector,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  protected initializeForm(model: AtendimentoSolicExameItemModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      atendimentoSolicExameItemId: [model.atendimentoSolicExameItemId, [
        Validators.required
      ]],      
      procedimentoId: [model.procedimentoId, [
        Validators.required
      ]],
      procedimentoNome: [model.procedimentoNome],
      procedimentoCodigo: [model.procedimentoCodigo],
      detalhes: [model.detalhes, [
        Validators.maxLength(500)
      ]],
      resultado: [model.resultado, [
        Validators.maxLength(500)
      ]],      
    });
  };

  ngOnInit() {
    super.ngOnInit();
  }

  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    const model: AtendimentoSolicExameItemModel = Object.assign({}, this.form.value);
    this.modalSave.emit(model);
    this.modal.hide();
    this.form = null;
  }

  showEdit(model: AtendimentoSolicExameItemModel) {
    this.detailMode = false;
    this.model = model;
    this.form = null;
    model.dataAlteracao = new Date();
    this.initializeForm(model);
    this.modal.show();
  }

  showDetail(model: AtendimentoSolicExameItemModel) {
    this.detailMode = true;
    this.model = model;
    this.form = null;    
    this.initializeForm(model);
    this.form.disable();
    this.modal.show();
  }

  close() {
    this.form = null;
    this.modal.hide();
  }

  onShown() {
    if (this.focusElement) {
      if (this.focusElement.nativeElement) {
        this.focusElement.nativeElement.focus();
      } else {
        this.focusElement.focus();
      }
    }
  }
}
