import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { SexoType } from 'projects/ProjetoBaseAngular/domain/types';
import { StatusCurriculoType } from 'projects/admin/src/domain/types';
import { CurriculoModel } from '../../domain/models';
import { CurriculoService } from '../../domain/services';
import { CurriculoModalComponent } from '../curriculo-modal/curriculo-modal.component';

@Component({
  selector: 'app-curriculo-cpf-modal',
  templateUrl: './curriculo-cpf-modal.component.html'
})
export class CurriculoCpfModalComponent extends BaseCrudComponent<CurriculoModel> implements OnInit {
  @ViewChild('curriculoCpfModal') modalCpf: ModalDirective;
  @ViewChild('curriculoModal') modal: CurriculoModalComponent;  

  StatusCurriculoType: typeof StatusCurriculoType = StatusCurriculoType; 

  statusCurriculo = [
    { text: 'Pendente', value: StatusCurriculoType.Pendente },
    { text: 'Visto', value: StatusCurriculoType.Visto },
    { text: 'Contratado', value: StatusCurriculoType.Contratado },
    { text: 'Não Atende', value: StatusCurriculoType.NaoAtende }
  ];  

  constructor(
    injector: Injector,
    private readonly curriculoService: CurriculoService,    
  ) {
    super(injector, curriculoService);    
  }  

  getStatusCurriculo(tipo: StatusCurriculoType): string {
    switch (tipo) {
      case StatusCurriculoType.Pendente:
        return 'Pendende';
      case StatusCurriculoType.Visto:
        return 'Visto';
      case StatusCurriculoType.Contratado:
        return 'Contratado';
      case StatusCurriculoType.NaoAtende:
        return 'Não Atende';
    }
  }

  getSexoCurriculo(tipo: SexoType): string {
    switch (tipo) {
      case SexoType.Masculino:
        return 'Masculino';
        case SexoType.Feminino:
        return 'Feminino';
      case SexoType.NaoDefinido:
        return 'Não Definido';      
    }
  }

  ngOnInit() {
    super.ngOnInit();    
  }  

  show(cpf: string) {
    this.curriculoService.getByCpf(cpf).subscribe(curriculos => {
      this.models = curriculos
    });
    this.modalCpf.show();
  }

  close() {    
    this.modalCpf.hide();
  }
}
