export enum ProcedimentoTabelaType {
    Brasindice = 1,
    Tuss = 2
}

export enum ProcedimentoServicoType {
    Interno = 0,
    Externo = 1
}

export enum AgendamentoStatusType {
    Disponivel = 0,
    Agendado = 1,
    Confirmado = 2,
    LancadoEntrada = 3,
    Atendido = 4,
    NaoCompareceu = 5,
    Encaixe = 6,
    Cancelado = 7,
    Bloqueado = 9
}

export enum AgendamentoStatusParcialType {
    Pendente = 0,
    Capturado = 1,
    Laudado = 2,
    Atendido = 3
}

export enum AgendamentoLogType {
    NovoRegistro = 0,
    Alteracao = 1
}

export enum AgendamentoType {
    Agendamento = 0,
    Retorno = 1,
    Encaixe = 2,
    Recorrente = 3
  }
    
  export enum RecorrenciaPeriodoType {
    Semanal = 0,
    Semana2 = 1,
    Semana4 = 2,
    Semana8 = 3,
    Semana12 = 4,
    Semana25 = 5,
    Semana52 = 6
  }

export enum ConfirmacaoLoteStatusType {
    NaoExecutado = 0,
    Sucesso = 1,
    SucessoFila = 2,
    Falha = 3
}

export enum AtendimentoStatusType {
    NaoAtendido = 0,
    EsperaAtendimento = 1,
    EmAtendimento = 2,
    Atendido = 3,
    Cancelado = 9
}

export enum AtendimentoRecebtoStatusType {
    Pendente = 0,
    Recebido = 1
}

export enum AtendimentoType {
    Atendimento = 0,
    NaoAgendado = 1
}

export enum ViaType {
    UsoOral = 0,
    UsoIntraMuscular = 1,
    UsoIntraVenoso = 2,
    UsoSubcutanea = 3,
    UsoSubvenal = 4,
    UsoTransvermica = 5,
    UsoTopico = 6,
    UsoNasal = 7,
    UsoOcular = 8,
    UsoCutaneo = 9,
    UsoRetal = 10,
    UsoInalatorio = 11,
    UsoExterno = 12,
    UsoInterno = 13
}

export enum ReceitaType {
    Normal = 0,
    Controlada = 1
}

export enum QuestAnamneseRespostaType {
    Descritiva = 0,
    SimNao = 1,
    MultiplaEscolha = 2,
    CheckBox = 3
}

export enum DocSusType {
    BpaI = 0,
    Apac = 1,
    LaudoTfd = 2
}

export enum CaracterAtendimentoType {
    Hospitalar = 0,
    Ambulatorio = 1
}

export enum AgendamentoViewType {
    ListaCompleta = 0,
    CalendarioMes = 1,
    CalendarioSemana = 2,
    CalendarioDia = 3
}

export enum LaudoStatusType {
    Pendente = 0,
    Laudado = 1,
    Finalizado = 2
}

export enum CapturaStatusType {
    Pendente = 0,
    Capturado = 1,
    Finalizado = 2
}


export enum LayoutMesangemType {
    AgendamentoConfirmacaoWhatsApp = 0,
    PesquisaEnvioLink = 1,
    PesquisaAgradecimento = 2
}