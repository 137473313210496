import { Component, ElementRef, Injector, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { EnqueteOpcaoModel } from '../../domain/models';

@Component({
  selector: 'app-enquete-opcao-modal',
  templateUrl: './enquete-opcao-modal.component.html',
})
export class EnqueteOpcaoModalComponent extends BaseComponent implements OnInit {
  @ViewChild('focus') focusElement: ElementRef;
  @ViewChild('enqueteOpcaoModal') modal: ModalDirective;

  @Output() modalSave: EventEmitter<EnqueteOpcaoModel> = new EventEmitter<EnqueteOpcaoModel>();

  constructor(
    injector: Injector    
  ) {
    super(injector);
  }

  protected initializeForm(enqueteOpcao: EnqueteOpcaoModel) {
    this.form = this.formBuilder.group({
      id: enqueteOpcao.id,
      dataInclusao: [enqueteOpcao.dataInclusao, Validators.required],
      dataAlteracao: enqueteOpcao.dataAlteracao,
      ordem: [enqueteOpcao.ordem, [
        Validators.required
      ]],
      descricaoOpcao: [enqueteOpcao.descricaoOpcao, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(200)
      ]]
    });
  }

  protected newModel(): EnqueteOpcaoModel {
    let enqueteOpcao = new EnqueteOpcaoModel();
    enqueteOpcao.id = this.commonService.newGuid();
    enqueteOpcao.dataInclusao = new Date();
    return enqueteOpcao;
  };

  ngOnInit() {
    super.ngOnInit();
  }

  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: EnqueteOpcaoModel = Object.assign({}, this.form.value);
    this.modalSave.emit(model);
    this.modal.hide();
    this.form = null;
  }

  showCreate() {
    this.form = null;
    const model = this.newModel();
    this.initializeForm(model);
    this.modal.show();
  }

  showEdit(enqueteOpcao: EnqueteOpcaoModel) {
    this.form = null;
    enqueteOpcao.dataAlteracao = new Date();
    this.initializeForm(enqueteOpcao);
    this.modal.show();
  }

  close() {
    this.form = null;
    this.modal.hide();
  }

  onShown() {
    document.getElementById('descricaoOpcao').focus();
  }
}
