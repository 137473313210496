<div bsModal #bannerModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="bannerModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-xl">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Banner</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<div class="row">
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Tipo</mat-label>
								<mat-select formControlName="tipo" (selectionChange)="changeTipo($event.value)" #focus>
									<mat-option *ngFor="let tipo of tipos" [value]="tipo.value">{{tipo.text}}
									</mat-option>
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Posição</mat-label>
								<mat-select formControlName="posicao">
									<mat-option *ngFor="let posicao of posicoes" [value]="posicao.value">
										{{posicao.text}}
									</mat-option>									
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>						
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Título</mat-label>
								<input matInput formControlName="titulo" type="text"
                  (blur)="slugify($event.target.value)" />
                  <mat-hint align="end">Máx 100 caracteres</mat-hint>
								<mat-error>Informe um Título válido.</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Ordem</mat-label>
								<input matInput formControlName="ordem" type="number" />
								<mat-error>Informe uma Ordem de Apresentação válida.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Registro Ativo</mat-label>
								<mat-select formControlName="registroAtivo">
									<mat-option [value]="true">Sim</mat-option>
									<mat-option [value]="false">Não</mat-option>
								</mat-select>															
							</mat-form-field>
						</div>
					</div>
					<div class="row" formGroupName="link" *ngIf="form.value.tipo===BlocoType.Imagem">
						<div class="col-md-9">
							<mat-form-field>
								<mat-label>URL</mat-label>
								<input matInput formControlName="url" type="text" />
								<mat-error>Informe uma URL válida.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Abrir link em...</mat-label>
								<mat-select formControlName="target">
									<mat-option *ngFor="let target of targets" [value]="target.value">{{target.text}}
									</mat-option>
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>
					</div>
					<div class="row" *ngIf="form.value.tipo===BlocoType.Imagem">
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>Legenda</mat-label>
								<textarea matInput formControlName="legenda"></textarea>
								<mat-hint align="end">Máx 200 caracteres</mat-hint>
								<mat-error>Informe uma Legenda válida.</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-12">
							<div class="form-group align-center">
								<div style="height: 150px; width: 100%; object-fit: contain;">
									<img src="{{form.value.imagemUrl}}"
										style="height: 150px; width: 100%; object-fit: contain;"
										*ngIf="form.value.imagemUrl">
								</div>
								<div class="align-center">
									<button mat-icon-button type="button" (click)="showSelectImage()"
										matTooltip="Selecionar Imagem">
										<mat-icon>add_photo_alternate</mat-icon>
									</button>
									<button mat-icon-button type="button" [disabled]="!form.value.imagemUrl"
										(click)="deselectImage()" matTooltip="Desvincular Imagem">
										<mat-icon>cancel</mat-icon>
									</button>
								</div>
							</div>
							<p class="font-15 col-deep-blue text-center">
								O tamanho ideal para Imagens é <strong>1920x400</strong>
							  </p>
						</div>
					</div>
					<div class="row" *ngIf="form.value.tipo===BlocoType.Html">
						<div class="col-md-12">
							<editor formControlName="html" [init]="tinymceConfig(700)">
							</editor>
						  </div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button  [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
