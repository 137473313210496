import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GaleriaModule } from '../galeria/galeria.module';
import { TenantsComponent } from './tenants.component';
import { TenantModalComponent } from './tenant-modal/tenant-modal.component';
import { TenantComponentesComponent } from './tenant-componentes/tenant-componentes.component';
import { TenantComponenteModalComponent } from './tenant-componentes/tenant-componente-modal/tenant-componente-modal.component';

@NgModule({
  imports: [    
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    TenantsComponent,
    TenantModalComponent,
    TenantComponentesComponent,
    TenantComponenteModalComponent
  ]
})
export class TenantsModule { }
