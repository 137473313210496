import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from '../../../galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { PaginaWebModel } from '../../domain/models';
import { PaginaWebService } from '../../domain/services';

@Component({
  selector: 'app-pagina-web-modal',
  templateUrl: './pagina-web-modal.component.html'
})
export class PaginaWebModalComponent extends BaseCrudModalComponent<PaginaWebModel> implements OnInit {
  @ViewChild('paginaWebModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  constructor(
    injector: Injector,
    paginaWebService: PaginaWebService
  ) {
    super(injector, paginaWebService);
  }

  protected initializeForm(paginaWeb: PaginaWebModel) {
    this.form = this.formBuilder.group({
      id: paginaWeb.id,
      dataInclusao: [paginaWeb.dataInclusao, Validators.required],
      dataAlteracao: paginaWeb.dataAlteracao,
      slug: [paginaWeb.slug, [
        Validators.required,
        Validators.maxLength(20)]
      ],
      titulo: [paginaWeb.titulo, [
        Validators.required,
        Validators.maxLength(100)]
      ],
      resumo: [paginaWeb.resumo, [
        Validators.required,
        Validators.maxLength(400)]
      ],
      palavrasChave: [paginaWeb.palavrasChave, [
        Validators.required,
        Validators.maxLength(400)]
      ],
      imagemUrl: [paginaWeb.imagemUrl],
      imagemUrlBase: [paginaWeb.imagemUrlBase],
      imagemPath: [paginaWeb.imagemPath],
      html: [paginaWeb.html],
      scriptHead: [paginaWeb.scriptHead],
      scriptBody: [paginaWeb.scriptBody]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }
}
