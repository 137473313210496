import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { ProfissionalSaudeTextoPadraoModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProfissionalSaudeTextoPadraoService extends BaseCrudService<ProfissionalSaudeTextoPadraoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ProfissionalSaudeTextoPadrao';
  }

  getByProfissionalSaudeId(profissionalSaudeId: string): Observable<ProfissionalSaudeTextoPadraoModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByProfissionalSaudeId/${profissionalSaudeId}`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
