import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from '../../galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { ConfigSiteTenantModel } from '../domain/models';
import { ConfigSiteTenantService } from '../domain/services/config-site-tenant.service';

@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.scss']
})
export class PwaComponent extends BaseComponent implements OnInit {  
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;
  url: string;
    
  constructor(
    injector: Injector,    
    private readonly configSiteTenantService: ConfigSiteTenantService
  ) {
    super(injector);
    this.url = location.host;
  }

  protected initializeForm(model: ConfigSiteTenantModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      pwaName: [model.pwaName],
      pwaShortName: [model.pwaShortName],
      pwaDescription: [model.pwaDescription],
      pwaIconUrl: [model.pwaIconUrl],
      pwaIconUrlBase: [model.pwaIconUrlBase],
      pwaIconPath: [model.pwaIconPath]
    });    
  }

  ngOnInit() {
    this.configSiteTenantService.get().subscribe(model => this.initializeForm(model));    
  }  
  
  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: ConfigSiteTenantModel = Object.assign({}, this.form.value);
    this.configSiteTenantService.editByTipo('Pwa', model).subscribe(
      () => {        
        this.snackBar.open('Salvo com sucesso!', 'Ok', {
          duration: 3000
        });
      },
      (errors) => this.verifyErrors(errors)
    );
  }

  showSelectImage() {    
    this.imagemSelectModal.showSelect(512);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      pwaIconUrl: imagem.url,
      pwaIconUrlBase: imagem.urlBase,
      pwaIconPath: imagem.path
    });     
  }

  deselectImage() {
    this.form.patchValue({
      pwaIconUrl: null,
      pwaIconUrlBase: null,
      pwaIconPath: null
    });
  }
}
