import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';
import { GaleriaModule } from '../galeria/galeria.module';
import { BannersComponent } from './banners/banners.component';
import { BannerModalComponent } from './banners/banner-modal/banner-modal.component';
import { ContatoComponent } from './contato/contato.component';
import { DominiosComponent } from './dominios/dominios.component';
import { EmailComponent } from './email/email.component';
import { LogotipoComponent } from './logotipo/logotipo.component';
import { MenusComponent } from './menus/menus.component';
import { MenuModalComponent } from './menus/menu-modal/menu-modal.component';
import { PaginasWebComponent } from './paginas-web/paginas-web.component';
import { PaginaWebModalComponent } from './paginas-web/pagina-web-modal/pagina-web-modal.component';
import { RodapeComponent } from './rodape/rodape.component';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { ScriptsComponent } from './scripts/scripts.component';
import { ScriptModalComponent } from './scripts/script-modal/script-modal.component';
import { SeoComponent } from './seo/seo.component';
import { PwaComponent } from './pwa/pwa.component';

@NgModule({
  imports: [
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    BannersComponent,
    BannerModalComponent,
    ContatoComponent,
    DominiosComponent,
    EmailComponent,
    LogotipoComponent,
    MenusComponent,
    MenuModalComponent,
    PaginasWebComponent,
    PaginaWebModalComponent,
    RodapeComponent,
    CabecalhoComponent,
    ScriptsComponent,
    ScriptModalComponent,
    SeoComponent,
    PwaComponent    
  ]
})
export class BaseMeuSiteModule { }
