import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ArquivoModel, ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { ArquivoService } from 'projects/ProjetoBaseAngular/domain/services';
import { ArquivoModalComponent } from './arquivo-modal/arquivo-modal.component';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-admin-arquivo',
  templateUrl: './arquivos.component.html'
})
export class ArquivosComponent extends BaseCrudComponent<ArquivoModel> implements OnInit {
  @ViewChild('arquivoModal') modal: ArquivoModalComponent;

  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime);
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'dataInclusao' },
    { data: 'nome' },
    { data: 'descricao' },
    { data: 'urlBase', hidden:true },
    { data: 'path', hidden:true }
  ];

  constructor(
    injector: Injector,
    readonly arquivoService: ArquivoService
  ) {
    super(injector, arquivoService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = [];

    (this.search.searchTerm) && searches.push(this.search);
    (this.searchDataInicial.searchTerm) && searches.push(this.searchDataInicial);
    (this.searchDataFinal.searchTerm) && searches.push(this.searchDataFinal);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[0, 'desc']];    
  }
}
