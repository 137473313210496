import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigSiteTenantModel, WebFonts } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ConfigSiteTenantService extends BaseCrudService<ConfigSiteTenantModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ConfigSiteTenant';
  }

  get(): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  getWebFonts(): Observable<WebFonts> {
    return this
      .httpClient
      .get<WebFonts>(`${this.getApiUrl()}${this.getController()}/GetWebFonts`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  editByTipo(tipo: string, model: ConfigSiteTenantModel): Observable<ConfigSiteTenantModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Edit${tipo}`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
