<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card">
        <div class="header">
          <h4 class="modal-title">Questionário - Respostas</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Questionário</mat-label>
                <input matInput [value]="questionarioTitulo" type="text" disabled>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Filtros</mat-label>
                <mat-select [(ngModel)]="searchFiltro.searchTerm" required #focus>
                  <mat-option *ngFor="let filtro of listaFiltro" [value]="filtro.id">
                    {{filtro.opcao}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Filtro.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Inicial</mat-label>
                <input matInput [(ngModel)]="searchData1.searchTerm" type="date" max="2999-12-31">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Final</mat-label>
                <input matInput [(ngModel)]="searchData2.searchTerm" type="date" max="2999-12-31">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <button mat-flat-button color="primary" (click)="filtrar()">
                <span>Filtrar</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
                class="table table-bordered table-striped table-hover" style="width: 100%;">
                <thead>
                  <tr>
                    <th class="text-center">Código</th>
                    <th class="text-center">Data</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Pontuação</th>
                    <th class="text-center">Filtro</th>
                    <th class="text-center" width="50"></th>
                  </tr>
                </thead>
                <tbody *ngIf="models?.length != 0">
                  <tr *ngFor="let model of models">
                    <td>
                      {{model.codigo}}
                    </td>
                    <td class="text-center">
                      {{model.dataInclusao | date:'dd/MM/yyyy HH:mm'}}
                    </td>
                    <td class="text-center">
                      <span [ngClass]="typesService.getPesquisaStatusCorTypeColor(model.statusCor)" class="label">
                        {{typesService.getPesquisaStatusCorType(model.statusCor)}}
                      </span>
                    </td>
                    <td class="text-right">
                      {{model.pontuacao | currency:'BRL':''}}
                    </td>
                    <td>
                      {{model.filtroOpcao}}
                    </td>
                    <td class="text-center">
                      <button mat-icon-button type="button" (click)="detail(model.id)"
                        matTooltip="Ver detalhes">
                        <mat-icon>preview</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="footer">
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span> Fechar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-questionario-resp-detalhe #detalheResp></app-questionario-resp-detalhe>