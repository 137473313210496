import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { AreaAtuacaoMedModel, EspecialidadeMedModel } from '../../domain/models';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AreaAtuacaoMedService, EspecialidadeMedService } from '../../domain/services';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-area-atuacao-med-modal',
  templateUrl: './area-atuacao-med-modal.component.html',
})
export class AreaAtuacaoMedModalComponent extends BaseCrudModalComponent<AreaAtuacaoMedModel> implements OnInit {
  @ViewChild('areaAtuacaoMedModal') modal: ModalDirective;

  especialidadesMed: EspecialidadeMedModel[];

  constructor(
    injector: Injector,
    areaAtuacaoMedService: AreaAtuacaoMedService,
    private especialidadeMedService: EspecialidadeMedService
  ) {
    super(injector, areaAtuacaoMedService);
  }

  protected initializeForm(areaAtuacaoMed: AreaAtuacaoMedModel) {
    this.form = this.formBuilder.group({
      id: areaAtuacaoMed.id,
      dataInclusao: [areaAtuacaoMed.dataInclusao, Validators.required],
      dataAlteracao: [areaAtuacaoMed.dataAlteracao],
      especialidadeMedId: [areaAtuacaoMed.especialidadeMedId, Validators.required],
      nome: [areaAtuacaoMed.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60)]
      ],
      saibaMaisUrl: [areaAtuacaoMed.saibaMaisUrl, [
        Validators.maxLength(200)
      ]]
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.especialidadeMedService.getAll().subscribe(especialidadesMed => this.especialidadesMed = especialidadesMed);
  }
}
