<div class="container-fluid">
  <br />
  <div class="row" *ngIf="models?.length > 0">
    <div [ngClass]="{'col-md-6': models.length > 2, 'col-md-5': models.length <= 2}" *ngFor="let model of models">
      <div class="card">
        <div class="header" [ngClass]="'bg-' + typesService.getReceitaTypeColor(model.tipo)">
          <h2>#{{model.seq}} {{typesService.getReceitaType(model.tipo)}}</h2>
        </div>
        <div class="body" style="height: 100px; overflow-y: auto; padding: 0px; padding-left: 5px;" [innerHTML]="sanitizer.bypassSecurityTrustHtml(model.descricaoHtml)">
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-4 text-left">
              <mat-slide-toggle #mostrarData color="primary" [checked]="true">
                Mostrar Data
              </mat-slide-toggle>
            </div>
            <div class="col-sm-8">
              <button mat-icon-button type="button"
                (click)="receitaPrint.print(model, mostrarData.checked)" matTooltip="Imprimir Receita">
                <mat-icon>print</mat-icon>
              </button>              
              <button [disabled]="detailMode" mat-icon-button type="button" (click)="edit(model)" matTooltip="Editar">
                <mat-icon>edit</mat-icon>
              </button>
              <button [disabled]="detailMode" mat-icon-button type="button" (click)="delete(model.id)"
                matTooltip="Excluir">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2" style="display: flex; justify-content: center; align-items: center; height: 50px;">
      <button [disabled]="detailMode" mat-fab type="button" color="primary" style="margin-right: 5px;"
        matTooltip="Nova Receita" (click)="create()">
        <mat-icon>add</mat-icon>
      </button>
      <button [disabled]="detailMode" mat-fab type="button" color="primary" style="margin-right: 5px;"
        matTooltip="Histórico de Receitas (Atendimentos Anteriores)" (click)="showHistorico()">
        <mat-icon>library_add_check</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="!(models?.length > 0)">
    <h4 class="text-center">Nenhuma receita adicionada ainda.</h4>
    <div style="display: flex; justify-content: center; align-items: center; height: 200px;">
      <button [disabled]="detailMode" mat-fab type="button" color="primary" style="margin-right: 5px;"
        matTooltip="Nova Receita" (click)="create()">
        <mat-icon>add</mat-icon>
      </button>
      <button [disabled]="detailMode" mat-fab type="button" color="primary" style="margin-right: 5px;"
        matTooltip="Histórico de Receitas (Atendimentos Anteriores)" (click)="showHistorico()">
        <mat-icon>library_add_check</mat-icon>
      </button>
    </div>
  </div>
  <br />
</div>

<app-atendimento-receita-modal #modal (modalSave)="onResponse($event)" 
  [atendimentoId]="atendimentoId" [profissionalId]="profissionalId">
</app-atendimento-receita-modal>
<app-atendimento-receita-historico-modal #modalHistorico (autoSave)="onResponse($event)"
  (cloneReceita)="onCloneResponse($event)" [atendimentoId]="atendimentoId" [profissionalId]="profissionalId"
  [pacienteId]="pacienteId">
</app-atendimento-receita-historico-modal>

<app-atendimento-receita-print #receitaPrint>
</app-atendimento-receita-print>