<div class="card">
  <div class="header">
    <h2>Filtro</h2>
  </div>
  <div class="body">
    <!-- <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Selecione os Procedimentos</mat-label>
          <mat-select [(ngModel)]="procedimentoIds" multiple>
            <mat-option *ngFor="let item of listaProcedimento" [value]="item.value">{{item.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Procedimento</mat-label>
          <mat-select [(ngModel)]="searchProcedimento.searchTerm">
            <mat-option value="-1">Todos</mat-option>
            <mat-option *ngFor="let item of listaProcedimento" [value]="item.value">{{item.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Período de Inclusão</mat-label>
          <input matInput [(ngModel)]="searchDataInicial.searchTerm" type="date" max="2999-12-31" [disabled]="!filtrarPeriodo">
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <input matInput [(ngModel)]="searchDataFinal.searchTerm" type="date" max="2999-12-31" [disabled]="!filtrarPeriodo">
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Cód./CPF Paciente</mat-label>
          <input id="cpfPaciente" #cpf matInput [(value)]="paciente.pessoa.cnpjCpf" type="text"
            (keyup.enter)="pacienteSelect.changeDocumento(cpf.value)"
            (blur)="pacienteSelect.changeDocumento($event.target.value)" />
          <button matSuffix mat-icon-button type="button" (click)="pacienteSelect.showSelect()"
            matTooltip="Selecionar Paciente">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Paciente</mat-label>
          <input matInput disabled
            [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia"
            type="text" />
          <button [disabled]="!paciente?.id" matSuffix mat-icon-button type="button"
            (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Status do Laudo</mat-label>
          <mat-select [(ngModel)]="searchStatus.searchTerm">
            <mat-option value="-1">Todos</mat-option>
            <mat-option *ngFor="let status of typesService.laudoStatusType" [value]="status.value">{{status.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="col-md-1 text-right">
      <section>
        <mat-checkbox [(ngModel)]="filtrarPeriodo">Filtrar por Período</mat-checkbox>
      </section>
    </div>
    <button mat-flat-button color="primary" (click)="filter()">
      <span>Filtrar</span>
    </button>
  </div>
</div>
<div class="card">
  <div class="header">
    <h2>Laudos</h2>
  </div>
  <div class="body table-responsive">
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center">Data</th>
          <th class="text-center">Profissional Solicitante</th>
          <th class="text-center">Profissional Laudante</th>
          <th class="text-center">Paciente</th>
          <th class="text-center">Convênio</th>
          <th class="text-center">Procedimento</th>
          <th class="text-center">Status</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="models?.length != 0">
        <tr *ngFor="let item of models">
          <td>{{item.dataInclusao | date: 'dd/MM/yyyy'}}</td>
          <td [innerHTML]="item.profissionalSolicitantePessoaNomeFantasia"></td>
          <td [innerHTML]="item.profissionalLaudantePessoaNomeFantasia"></td>
          <td [innerHTML]="item.pacientePessoaNomeFantasia"></td>
          <td [innerHTML]="item.convenioNome"></td>
          <td [innerHTML]="item.procedimentoNome"></td>
          <td>
            <span class="badge" [style.backgroundColor]="typesService.getLaudoStatusTypeColor(item.status)">
              {{typesService.getLaudoStatusType(item.status)}}
            </span>
          </td>
          <td class="text-center">
            <button *ngIf="item.status===typesService.LaudoStatusType.Laudado" mat-icon-button type="button"
              (click)="laudoPrint.print(item.id)" matTooltip="Imprimir Laudo">
              <mat-icon>print</mat-icon>
            </button>
            <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)"
              matTooltip="Laudar">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-laudo-modal #modal (modalSave)="onResponse($event)"></app-laudo-modal>
<app-laudo-print #laudoPrint></app-laudo-print>
<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>