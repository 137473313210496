import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { TenantModel, DataTablesRequest, ModelSearch, EstadoModel, CidadeModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TenantService, EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { TenantModalComponent } from './tenant-modal/tenant-modal.component';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-admin-tenant',
  templateUrl: './tenants.component.html'
})
export class TenantsComponent extends BaseCrudComponent<TenantModel> implements OnInit {
  @ViewChild('tenantModal') modal: TenantModalComponent;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;

  //tenants: TenantModel[];
  estados: EstadoModel[];
  cidades: CidadeModel[];

  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime);
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime);
  searchEstado = new ModelSearch('estadoId', OptionSearchType.Equals, SearchType.Guid);
  searchCidade = new ModelSearch('cidadeId', OptionSearchType.Equals, SearchType.Guid);

  selectColumns = [
    { data: 'dataInclusao' },
    { data: 'subdominio' },
    { data: 'tokenWhatsAppApi' },
    { data: 'nomeFantasia' },
    { data: 'cnpjCpf' },
    { data: '(cidade.nome + "/" + estado.uf) as cidadeUf', name: 'cidade.nome' }
  ];

  constructor(
    injector: Injector,
    private readonly tenantService: TenantService,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService
  ) {
    super(injector, tenantService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    (this.searchDataInicial.searchTerm) && searches.push(this.searchDataInicial);
    (this.searchDataFinal.searchTerm) && searches.push(this.searchDataFinal);
    (this.searchEstado.searchTerm) && searches.push(this.searchEstado);
    (this.searchCidade.searchTerm) && searches.push(this.searchCidade);

    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }

  changeEstado(estadoId: string) {
    if (estadoId) {
      this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
    } else {
      this.cidades = [];
    }
  }

  filter() {
    this.datatable.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  async createStartup(tenant: TenantModel) {
    let model = await this.tenantService.getById(tenant.id).toPromise();

    if (model.componentes.length == 0) {
      this.commonService.mensagem("Processo não pode ser executado.", "Tenant não possuí módulo ou componente informado.", "warning");
      return;
    }

    this.commonService.spinnerOpen("Startup Tenant", "Processando criação de ambiente do Tenant...", "info");
    let result = await this.tenantService.createStartup(model).toPromise();
    
    this.commonService.spinnerClose();

    if (result) {
      this.commonService.mensagem("Startup Tenant", "Ambiente criado com sucesso.", "info");
    }
  }
}
