import { NgModule } from '@angular/core';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ReportModule } from '../../report/report.module';
import { RelTabelaComponent } from './rel-tabela.component';

@NgModule({
  imports: [
    SharedModule,
    ReportModule
  ],
  declarations: [
    RelTabelaComponent,
  ]
})
export class RelTabelaModule { }
