<div bsModal #vagaModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="vagaModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Vaga</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>

          <div class="row">

            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Titulo</mat-label>
                <input matInput formControlName="titulo" type="text" #focus/>
                <mat-hint align="end">Máx 100 caracteres</mat-hint>
                <mat-error>Informe um Titulo válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field>
                <mat-label> Cargo </mat-label>
                <mat-select formControlName="cargoId" >
                  <mat-option [value]="">Nenhum Cargo</mat-option>
                  <mat-option *ngFor="let cargo of cargos" [value]="cargo.id">{{cargo.nome}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Cargo Superior.</mat-error>
              </mat-form-field>
            </div>

            <mat-slide-toggle formControlName="registroAtivo" color="primary">
              Vaga Ativa
            </mat-slide-toggle>

          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Descrição</mat-label>
                <textarea matInput #descricao formControlName="descricao" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{descricao.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <h4 class="card-inside-title">HTML</h4>
              <div class="row">
                <div class="col-md-12">
                  <editor formControlName="html" [init]="tinymceConfig(250)">
                  </editor>
                </div>
              </div>

        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
