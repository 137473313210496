import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ArtigoTagModel } from '../../domain/models';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ArtigoTagService } from '../../domain/services';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-artigo-tag-modal',
  templateUrl: './artigo-tag-modal.component.html'
})
export class ArtigoTagModalComponent extends BaseCrudModalComponent<ArtigoTagModel> implements OnInit {
  @ViewChild('artigoTagModal') modal: ModalDirective;

  constructor(
    injector: Injector,
    artigoTagService: ArtigoTagService
  ) {
    super(injector, artigoTagService);
  }

  protected initializeForm(artigoTag: ArtigoTagModel) {
    this.form = this.formBuilder.group({
      id: artigoTag.id,
      dataInclusao: [artigoTag.dataInclusao, Validators.required],
      dataAlteracao: artigoTag.dataAlteracao,
      nome: [artigoTag.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
      slug: [artigoTag.slug, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)
      ]]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
