import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ExameModalComponent } from './exame-modal/exame-modal.component';
import { ExameModeloModel } from '../domain/models';
import { ExameService } from '../domain/services/exame.service';

@Component({
  selector: 'app-exame',
  templateUrl: './exame.component.html'
})
export class ExameComponent extends BaseCrudComponent<ExameModeloModel> implements OnInit {
  @ViewChild('modal') modal: ExameModalComponent;

  constructor(
    injector: Injector,
    readonly service: ExameService
  ) {
    super(injector, service);
  }

  selectColumns = [    
    { data: 'nome' },
    { data: 'profissionalSaudeNomeApresentacao' },
  ];

  ngOnInit() {
    super.ngOnInit();
  }
}
