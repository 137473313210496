import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { TipoParceiroModule } from './tipo-parceiro/tiposParceiros.module';
import { ParceirosModule } from './parceiros/parceiros.module';


@NgModule({
  imports: [
    SharedModule,
    TipoParceiroModule,
    ParceirosModule
  ]
})
export class ParceiroModule { }
