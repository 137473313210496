import { Component, OnInit, Input, Injector, ViewChild, Output, EventEmitter } from '@angular/core';

import { TypesService } from 'projects/admin/src/domain/services';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { ChatBotRespAlternativaModel } from '../../domain/models';
import { ChatBotRespAlternativaModalComponent } from './chatbot-opcao-alternativa-modal/chatbot-resp-alternativa-modal.component';
import { ChatBotRespAlternativaService } from '../../domain/services/chatbot-opcao-alternativa.service';


@Component({
  selector: 'app-chatbot-resp-alternativa',
  templateUrl: './chatbot-resp-alternativa.component.html'
})
export class ChatBotRespAlternativaComponent extends BaseCrudComponent<ChatBotRespAlternativaModel> implements OnInit {
  @ViewChild('modal') modal: ChatBotRespAlternativaModalComponent;
  
  @Input('chatBotNodoId') chatBotNodoId: string;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  searchChatBotNodoId = new ModelSearch('chatBotNodoId', OptionSearchType.Equals, SearchType.Guid);  
  
  selectColumns = [ 
    { data: 'respostaAlternativa'},
    { data: 'valor'}
  ];

  constructor(
    injector: Injector,
    readonly service: ChatBotRespAlternativaService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();      
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    this.searchChatBotNodoId.searchTerm = this.chatBotNodoId;
    searches.push(this.searchChatBotNodoId);
    
    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }

  create() {
    this.autoSave.emit();
    super.create();
  }
}