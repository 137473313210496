<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl" *ngIf="pacienteId">
    <div class="card modal-content">
      <div class="header">
        <h2>Documentos do SUS - {{pacienteNome}}</h2>
      </div>
      <div class="modal-body table-responsive">
        <button mat-flat-button color="primary" type="button" [disabled]="!isAllowed('Create')"
          (click)="create()">Adicionar</button>
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
          class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Data</th>
              <th class="text-center">Tipo</th>
              <th class="text-center">Paciente</th>
              <th class="text-center">Profissional Solicitante</th>
              <th class="text-center">Profissional Autorizador</th>
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models">
              <td>{{item.dataSolicitacao | date: 'dd/MM/yyyy'}}</td>
              <td [innerHTML]="typesService.getDocSusType(item.tipo)"></td>
              <td [innerHTML]="item.pacienteNome"></td>
              <td [innerHTML]="item.solicitanteNome"></td>
              <td [innerHTML]="item.autorizadorNome"></td>
              <td class="text-center">
                <button mat-icon-button type="button" *ngIf="item.tipo !== docSusType.LaudoTfd" (click)="docSusPrint.print(item.id)"
                  matTooltip="Imprimir">
                  <mat-icon>print</mat-icon>
                </button>
                <button mat-icon-button type="button" *ngIf="item.tipo === docSusType.LaudoTfd" (click)="docSusTfdPrint.print(item.id)"
                  matTooltip="Imprimir">
                  <mat-icon>print</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)"
                  matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(item.id)"
                  matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-doc-sus-modal #modal (modalSave)="onResponse($event)"></app-doc-sus-modal>
<app-doc-sus-print #docSusPrint></app-doc-sus-print>
<app-doc-sus-tfd-print #docSusTfdPrint></app-doc-sus-tfd-print>