import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { GrupoQuestaoItemModel } from '../../domain/models';
import { GrupoQuestaoItemService } from '../../domain/services';

@Component({
  selector: 'app-grupo-questao-item-modal',
  templateUrl: './grupo-questao-item-modal.component.html'
})

export class GrupoQuestaoItemModalComponent extends BaseCrudModalComponent<GrupoQuestaoItemModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  @Input('grupoQuestaoId') grupoQuestaoId: string;

  constructor(
    injector: Injector,
    service: GrupoQuestaoItemService,
  ) {
    super(injector, service);
  }
  
  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: GrupoQuestaoItemModel) {
    this.form = this.formBuilder.group({
      id: [model.id],
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: [model.dataAlteracao],
      
      grupoQuestaoId: [model.grupoQuestaoId, Validators.required],
      ordem: [model.ordem],
      questao: [model.questao, [ Validators.required, Validators.maxLength(100)]]      
    });
  };


  protected newModel(): GrupoQuestaoItemModel {
    let model = new GrupoQuestaoItemModel();
    model.grupoQuestaoId = this.grupoQuestaoId;
    model.ordem = 1;
    return model;
  };

}
