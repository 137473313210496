import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ProtocoloDocService } from '../../domain/services';
import { ProtocoloDocModel } from '../../domain/models';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-protocolo-doc-modal',
  templateUrl: './protocolo-doc-modal.component.html'
})
export class ProtocoloDocModalComponent extends BaseCrudModalComponent<ProtocoloDocModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  paciente: PacienteModel;

  constructor(
    readonly pacienteService: PacienteService,
    injector: Injector,
    service: ProtocoloDocService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: ProtocoloDocModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      
      pacienteId: [model.pacienteId, [
        Validators.required
      ]],
      codigo: [model.codigo || 0],
      titulo: [model.titulo, [
        Validators.required, Validators.maxLength(100)
      ]],
      detalhes: [model.detalhes, [
        Validators.maxLength(500)
      ]],
      responsavel: [model.responsavel, [
        Validators.maxLength(100)
      ]],
      entregue: [model.entregue || false],
      dataEntrega: [this.fromJsonDate(model.dataEntrega)],
      entreguePara: [model.entreguePara, [
        Validators.maxLength(100)
      ]],
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onPacienteResponse(pacienteId: string) {
    this.form.patchValue({ pacienteId });
    if (!pacienteId) {
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
    } else {
      this.pacienteService.getById(pacienteId).subscribe(x => {        
        this.paciente = x;        
      });
    }    
  }
}
