import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { PesquisaQuestaoType } from 'projects/admin/src/domain/types';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { Subject } from 'rxjs';
import { GrupoQuestaoItemModel, GrupoQuestaoModel, QuestionarioFiltroModel, QuestionarioRespItemModel, QuestionarioRespModel } from '../domain/models';
import { GrupoQuestaoItemService, GrupoQuestaoService, QuestionarioFiltroService, QuestionarioRespService } from '../domain/services';

@Component({
  selector: 'app-questionario-dash-questao',
  templateUrl: './questionario-dash-questao.component.html'
})

export class QuestionarioDashQuestaoComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;
  @ViewChild('tableOpcoes') tableOpcoes: ElementRef;
  @ViewChild('tableRespostas') tableRespostas: ElementRef;
  @ViewChild('canvas') canvas: any;
  
  dtOptions: DataTables.Settings = {
    language: {
      url: '/assets-base/json/translation/datatable/pt-br.json'
    },
    order: [1, 'desc'],
    lengthChange: false,
    paging: false,
    searching: false,
    dom: ''
  };
  dtTrigger: Subject<any[]> = new Subject();

  questionarioRespostas: QuestionarioRespModel[];
  listaFiltros: QuestionarioFiltroModel[];
  gruposQuestao: GrupoQuestaoModel[];
  grupoQuestao: GrupoQuestaoModel;
  grupoQuestaoItens: GrupoQuestaoItemModel[];

  chartTypes = [
    { text: 'Pizza', value: 'pie' },
    { text: 'Linha', value: 'line' },
    { text: 'Barra', value: 'bar' },
    { text: 'Barra Horizontal', value: 'horizontalBar' },
    { text: 'Radar', value: 'radar' },
    { text: 'Rosquinha', value: 'doughnut' },
    { text: 'Área Polar', value: 'polarArea' }
  ];

  questionarioId: string;
  dataInicial: string;
  dataFinal: string;
  filtroId: string;
  grupoQuestaoId: string;
  grupoQuestaoItemId: string;
  isBusy: boolean;

  chartOptions: ChartOptions;
  chartLabels: Label[] = [];
  chartData: number[] = [];
  chartDatasets = [];
  chartType: ChartType = 'pie';
  chartLegend = true;
  chartPlugins = []; //[ChartDataLabels];
  chartColors = [];

  tableItens = [];
  tableRespItens: string[] = [];
  respSearchTerm: string;
  qtdTotal: number;

  constructor(
    injector: Injector,
    readonly questionarioRespService: QuestionarioRespService,
    readonly filtroService: QuestionarioFiltroService,
    readonly grupoQuestaoService: GrupoQuestaoService,
    readonly grupoQuestaoItemService: GrupoQuestaoItemService,
    readonly printService: PrintService,
    readonly typesService: TypesService
  ) {
    super(injector);
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
  }

  private updateDtTrigger() {
    if (this.datatable.dtInstance) {
      this.datatable.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  private popularDadosOpcoes(respostas: QuestionarioRespItemModel[]) {
    this.chartLabels = [];
    this.chartData = [];
    this.chartDatasets = [];
    this.chartOptions = {
      responsive: true
    }
    this.tableItens = [];

    this.qtdTotal = respostas.length;

    this.grupoQuestao.opcoes.forEach(questOpcao => {
      this.chartLabels.push(questOpcao.opcao);
      const data = respostas.filter(x => x.grupoQuestaoOpcaoId === questOpcao.id).length;
      this.chartData.push(data);
      this.tableItens.push({ opcao: questOpcao.opcao, total: data, percentual: this.calcPercentual(data, this.qtdTotal) });
    });

    this.updateDtTrigger();

    if (this.chartType === 'pie' || this.chartType === 'polarArea' || this.chartType === 'doughnut') {
      this.chartLegend = true;
      this.chartColors = [];
    } else {
      if (this.chartType !== 'radar') {
        this.chartOptions = {
          responsive: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        };
      }

      this.chartLegend = false;
      this.chartDatasets.push({ data: this.chartData, label: 'Total' });
      this.chartColors = [
        {
          backgroundColor: [
            'rgba(0,0,255,0.4)',
            'rgba(0,255,0,0.4)',
            'rgba(255,0,0,0.4)',

            'rgba(0,0,200,0.4)',
            'rgba(0,200,0,0.4)',
            'rgba(200,0,0,0.4)',

            'rgba(0,0,150,0.4)',
            'rgba(0,150,0,0.4)',
            'rgba(150,0,0,0.4)',

            'rgba(0,0,100,0.4)',
            'rgba(0,100,0,0.4)',
            'rgba(100,0,0,0.4)'
          ]
        }
      ];
    }
  }
  
  calcPercentual(qtd: number, qtdTotal: number): number {
    let prc = qtd * 100 / qtdTotal;
    return Math.round(prc * 100) / 100;
  }

  async show(questionarioId: string) {
    const now = new Date();
    this.dataInicial = this.fromJsonDate(new Date(now.getFullYear(), now.getMonth(), 1));
    this.dataFinal = this.fromJsonDate(new Date(now.getFullYear(), now.getMonth() + 1, 0));

    this.listaFiltros = await this.filtroService.getByQuestionarioId(questionarioId).toPromise();
    this.gruposQuestao = await this.grupoQuestaoService.getByFiltroId(this.listaFiltros[0].id).toPromise();

    this.questionarioId = questionarioId;

    this.filtrarByData();
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  changeFiltroId(filtroId) {
    this.grupoQuestaoService.getByFiltroId(filtroId).subscribe(x => this.gruposQuestao = x);
    this.grupoQuestaoId = "";
    this.grupoQuestaoItemId = "";
  }

  changeGrupoQuestaoId(grupoQuestaoId) {
    this.grupoQuestaoItemService.getByGrupoQuestaoId(grupoQuestaoId).subscribe(x => this.grupoQuestaoItens = x);
    this.grupoQuestaoItemId = "";
  }

  filtrarByData() {
    this.questionarioRespService.getByQuestionarioId(this.questionarioId, this.dataInicial, this.dataFinal).subscribe(x => {
      this.questionarioRespostas = x;
      this.filtrar();
    });
  }

  filtrar() {
    this.isBusy = true;
    this.grupoQuestao = this.gruposQuestao.find(x => x.id === this.grupoQuestaoId);

    const respostas = this.questionarioRespostas
      .map(x => x.itens)
      .reduce((a, b) => a.concat(b))
      .filter(x =>
        x.grupoQuestaoId === this.grupoQuestaoId &&
        x.grupoQuestaoItemId === this.grupoQuestaoItemId
      );

    if (this.grupoQuestao) {
      if (this.grupoQuestao.tipo === PesquisaQuestaoType.Descritivo) {
        this.tableRespItens = respostas
          .filter(x => x.respostaDescritiva)
          .map(x => x.respostaDescritiva);
        if (this.respSearchTerm) {
          this.tableRespItens = this.tableRespItens.filter(x => x.includes(this.respSearchTerm));
        }
      } else {
        this.popularDadosOpcoes(respostas);
      }

      setTimeout(() => {
        this.isBusy = false;
      }, 500);
    }
  }

  print() {
    const content =
      `
          <h4 class="text-center font-weight-bold">Respostas</h4>
          <div class="row">
            <div class="col-3">
              <strong>Data de Emissão:</strong> ${moment().format('DD/MM/YYYY')}
            </div>
            <div class="col">
              <strong>Período:</strong> ${moment(this.dataInicial).format('DD/MM/YYYY')} à ${moment(this.dataFinal).format('DD/MM/YYYY')}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <strong>Filtro:</strong> ${this.listaFiltros.find(x => x.id === this.filtroId)?.opcao}
            </div>
            <div class="col">
              <strong>Grupo de Questão:</strong> ${this.gruposQuestao.find(x => x.id === this.grupoQuestaoId)?.titulo}
            </div>
            <div class="col">
              <strong>Questão:</strong> ${this.grupoQuestaoItens.find(x => x.id === this.grupoQuestaoItemId)?.questao}
            </div>          
          </div>
          <div class="text-center"><img src="${this.canvas?.nativeElement.toDataURL()}"'/></div><br/>
          ${this.tableOpcoes?.nativeElement.innerHTML || ''}${this.tableRespostas?.nativeElement.innerHTML || ''}
        `;
    this.printService.print('Questionário - Dashboard por Questão', content);
  }

}
