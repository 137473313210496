import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';

import { EstadoModel, CidadeModel, ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { ConvenioModalComponent } from './convenio-modal/convenio-modal.component';
import { ConvenioModel } from '../domain/models';
import { ConvenioService } from '../domain/services/convenio.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { TypesService } from 'projects/admin/src/domain/services';


@Component({
  selector: 'app-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./convenio.component.scss']
})
export class ConvenioComponent extends BaseCrudComponent<ConvenioModel> implements OnInit {
  @ViewChild('convenioModal') modal: ConvenioModalComponent;

  convenios: SelectDto[];

  estados: EstadoModel[];
  cidades: CidadeModel[];
  
  searchEstado = new ModelSearch('estadoId', OptionSearchType.Equals, SearchType.Guid);
  searchCidade = new ModelSearch('cidadeId', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);
  
  selectColumns = [
    { data: 'ordem' },
    { data: 'codigo' },
    { data: 'nome' },
    { data: 'telefone' },      
    { data: '(cidade.nome + "/" + estado.uf) as cidadeUf' },
    { data: 'registroAtivo' }
  ];
  
  constructor(
    injector: Injector,
    readonly service: ConvenioService,
    readonly typesService: TypesService,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService
  ) {
    super(injector, service);
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchEstado.searchTerm) && searches.push(this.searchEstado);
    (this.searchCidade.searchTerm) && searches.push(this.searchCidade);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  changeEstado(estadoId) {
    if (estadoId) {
      this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
    } else {
      this.cidades = [];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    const ids = this.convenios.map(x => x.value);
    this.service.updateOrdens(ids).subscribe();
  }

  // async recarregarLista() {
  //   await this.atualizaOrdem();
  //   super.filter();
  // }

  async atualizaOrdem() {
    this.convenios = await this.service.getSelectList().toPromise();
  }
}

