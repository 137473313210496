import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { GrupoProcedimentoService } from '../../atendimento/domain/services';
import { ConvenioService } from '../../parceiro/domain/services/convenio.service';
import { RelatorioService } from '../domain/services';

@Component({
  selector: 'app-rel-recebimento-convenio',
  templateUrl: './rel-recebimento-convenio.component.html'
})
export class RelRecebimentoConvenioComponent extends BaseComponent implements OnInit {
  title = 'Relatório de Recebimentos de Convênio';
  @ViewChild('relatorio') relatorio: ElementRef;  
  convenios: SelectDto[];
  gruposProcedimento: SelectDto[];

  dataInicial: string;
  dataFinal: string;  
  convenioIds: string[] = [];
  grupoProcedimentoIds: string[] = [];

  isBusy: boolean;
  modelDto: any;

  constructor(
    injector: Injector,
    readonly service: RelatorioService,    
    readonly convenioService: ConvenioService,
    readonly grupoProcedimentoService: GrupoProcedimentoService,
    readonly printService: PrintService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.isAllowed()) {
      this.commonService.mensagem("", "Você não tem permissão de execução de relatórios!", "info");
    }

    this.dataInicial = this.fromJsonDate(new Date());
    this.dataFinal = this.fromJsonDate(new Date());
    
    this.convenioService.getSelectList().subscribe(x => this.convenios = x);
    this.grupoProcedimentoService.getSelectList().subscribe(x => this.gruposProcedimento = x);
  }

  isAllowed(): boolean {
    return super.isAllowed("Read", "RelRecConvenio");
  }

  async print() {
    //Limpar Html
    this.modelDto = [];
    
    if (!this.commonService.validarDataInput(this.dataInicial)){
      this.commonService.mensagem("Atenção", "Digite uma data inicial válida");
      return;
    }
    if (!this.commonService.validarDataInput(this.dataFinal)){
      this.commonService.mensagem("Atenção", "Digite uma data final válida");
      return;
    }

    this.commonService.spinnerOpen("Gerando Relatório...")
    this.isBusy = true;
    const filterDto = {
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,      
      convenioIds: this.convenioIds,
      grupoProcedimentoIds: this.grupoProcedimentoIds
    }

    this.modelDto = await this.service.getRecebimento(filterDto).toPromise();

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print(this.title, content);
      this.isBusy = false;
      this.commonService.spinnerClose();
    }, 500);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }
  
  get convenioNomes(): string {
    return this.convenios?.filter(x => this.convenioIds.includes(x.value)).map(x => x.text).join(', ') || 'Todos';
  }

  get grupoProcedimentoNomes(): string {
    return this.gruposProcedimento?.filter(x => this.grupoProcedimentoIds.includes(x.value)).map(x => x.text).join(', ') || 'Todos';
  }
}
