<div class="row clearfix">
  <div class="col-md-12">
      <div class="card">
          <div class="header">
              <h2>Banners</h2>              
          </div>
          <div class="body table-responsive">              
              <!--query end-->
              <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover" style="width: 100%;">
                <thead>
                  <tr>
                    <th class="text-center" width="80">Tipo</th>
                    <th class="text-center" width="100">Posição</th>
                    <th class="text-center">Título</th>
                    <th class="text-center">Link</th>                    
                    <th class="text-center">Imagem</th>
                    <th class="text-center" width="100">Registro Ativo</th>                    
                    <th class="text-center" width="150"></th>
                  </tr>
                </thead>
                <tbody *ngIf="models?.length != 0">
                  <tr *ngFor="let bloco of models">
                    <td>
                      {{getTipo(bloco.tipo)}}
                    </td>
                    <td>
                      {{getPosicao(bloco.posicao)}}
                    </td>
                    <td>
                      {{bloco.titulo}}
                    </td>                    
                    <td>
                      {{bloco.link?.url}}
                    </td>
                    <td class="text-center">
                      <img *ngIf="bloco.imagemUrl" src="{{bloco.imagemUrl}}" style="height: 100px;">
                    </td>
                    <td class="text-center">
                      <span [ngClass]="{
                        'bg-green': bloco.registroAtivo,
                        'bg-red': !bloco.registroAtivo                         
                      }" class="label">
                      {{bloco.registroAtivo ? 'Sim' : 'Não'}}
                      </span>
                    </td>
                    <td class="text-center">
                      <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(bloco)" matTooltip="Editar">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(bloco.id)" matTooltip="Excluir">
                        <mat-icon>delete</mat-icon>
                      </button>                      
                    </td>
                  </tr>
                </tbody>
              </table>              
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
                <mat-icon>add</mat-icon>
            </button>            
        </div>
      </div>
  </div>
</div>
<app-banner-modal #bannerModal (modalSave)="onResponse($event)"></app-banner-modal>
