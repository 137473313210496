<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="!termoPaciente" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="termoPaciente">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Layout de Laudo</h2>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" *ngIf="errors.length > 0">
          <h3>Oops! Algo deu errado:</h3>
          <ul>
            <li *ngFor="let error of errors">{{error}}</li>
          </ul>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput [ngModel]="termoPaciente.nome" type="text" disabled />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select disabled [value]="termoPaciente.status">
                <mat-option *ngFor="let tipo of typesService.termoStatusType" [value]="tipo.value">{{tipo.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Aceito</mat-label>
              <mat-select disabled [value]="termoPaciente.aceito">
                <mat-option *ngFor="let tipo of typesService.boolTypes" [value]="tipo.value">{{tipo.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-label>Texto Termo</mat-label>
            <editor [disabled]="true" [ngModel]="termoPaciente.textoTermo" [init]="tinymceConfig(500)">
            </editor>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>