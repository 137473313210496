import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ConfigModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ConfigService } from 'projects/ProjetoBaseAngular/domain/services/geral/config.service';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { Config } from 'protractor';
import { List } from 'lodash';

@Component({
  selector: 'app-config-modal',
  templateUrl: './config-modal.component.html'
})
export class ConfigModalComponent extends BaseCrudModalComponent<ConfigModel> implements OnInit {
  @ViewChild('configModal') modal: ModalDirective;

  opcoes: any[];

  constructor(
    injector: Injector,
    configService: ConfigService,
  ) {
    super(injector, configService);
  }

  protected initializeForm(config: ConfigModel) {
    this.form = this.formBuilder.group({
      id: config.id,
      dataInclusao: [config.dataInclusao, Validators.required],
      dataAlteracao: config.dataAlteracao,

      descricao: [config.descricao, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)]
      ],
      grupo: [config.grupo, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(30)]
      ],
      chave: [config.chave, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)]
      ],
      valor: [config.valor],
      opcoes: [config.opcoes],
      descricaoOpcoes: [config.descricaoOpcoes]
    });

    if (config.opcoes && config.descricaoOpcoes) {
      const opcoesArray = config.opcoes.split(',');
      const descicaoOpcoesArray = config.descricaoOpcoes.split(',');
      this.opcoes = [];
      for (let index = 0; index < opcoesArray.length; index++) {
        this.opcoes.push({ text: descicaoOpcoesArray[index], value: opcoesArray[index] });
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
