import { Component, OnInit, Input, Injector, ViewChild, Output, EventEmitter } from '@angular/core';

import { TypesService } from 'projects/admin/src/domain/services';
import { ProcedimentoConvenioProfissionalModel } from '../../domain/models';
import { ProcedimentoConvenioProfissionalModalComponent } from './procedimento-convenio-profissional-modal/procedimento-convenio-profissional-modal.component';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { ProcedimentoConvenioProfissionalService } from '../../domain/services/procedimento-convenio-profissional.service';

@Component({
  selector: 'app-procedimento-convenio-profissional',
  templateUrl: './procedimento-convenio-profissional.component.html'
})
export class ProcedimentoConvenioProfissionalComponent extends BaseCrudComponent<ProcedimentoConvenioProfissionalModel> implements OnInit {
  @ViewChild('modal') modal: ProcedimentoConvenioProfissionalModalComponent;
  
  @Input('procedimentoConvenioId') procedimentoConvenioId: string;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();
  
  searchProcConvId = new ModelSearch('procedimentoConvenioId', OptionSearchType.Equals, SearchType.Guid);  
  
  selectColumns = [ 
    { data: 'profissionalSaude.nomeApresentacao'},
    { data: 'percLaudo'},
    { data: 'percClinica'}
  ];

  constructor(
    injector: Injector,
    readonly service: ProcedimentoConvenioProfissionalService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();      
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    this.searchProcConvId.searchTerm = this.procedimentoConvenioId;
    searches.push(this.searchProcConvId);
    
    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }

  create() {
    this.autoSave.emit();
    super.create();
  }
}
