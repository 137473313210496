enum TokenComandoType {
  NaoComando = 0,
  ComandoIf = 1,
  ComandoElse = 2
}

enum TokenBlocoType {
  NaoToken = "",
  Inicio = "Begin",
  Fim = "End;"
}

/**
 * Objeto com token de palavras chaves para
 * substituir no texto da mensagem.
 */
export class ParseKeyPair {
  key: string;
  value: string;
}

export class ParseMensagem {

  private tabelaKey: ParseKeyPair[] = [];

  constructor(tabelaKey: ParseKeyPair[]) {
    this.tabelaKey = tabelaKey;
  }

  parse(mensagem: string): string {
    let resultado = "";
    let msgArr = this.getMsgArray(mensagem);

    let temIf: boolean = false;
    let executouIf: boolean = false;

    let saida = false;
    let idx = 0;

    while (idx < msgArr.length && !saida) {
      let tipoComando = this.getTokenComandoType(msgArr[idx]);
      let objBloco = null;
      let textoBloco = "";

      switch (tipoComando) {
        case TokenComandoType.ComandoIf:
          temIf = true;
          objBloco = this.getBloco(msgArr, idx + 1);
          textoBloco = objBloco.bloco;

          if (this.executarIf(msgArr[idx])) {
            executouIf = true;
            //recursividade no bloco
            let texto = this.parse(textoBloco);
            resultado += texto;
          }

          //idx = final do bloco
          idx = objBloco.index;

          break;
        case TokenComandoType.ComandoElse:
          objBloco = this.getBloco(msgArr, idx + 1);
          textoBloco = objBloco.bloco;

          if (temIf && !executouIf) {
            //recursividade no bloco
            let texto = this.parse(textoBloco);
            resultado += texto;
          }

          //idx = final do bloco
          idx = objBloco.index;
          //Liberar para possível outra estrutura if..else
          temIf = false;
          executouIf = false;
          break;
        default:
          let texto = msgArr[idx];
          this.tabelaKey.forEach(itemTabela => {
            texto = texto.replace(itemTabela.key, itemTabela.value);
          });

          resultado += texto;
          break;
      }

      idx++;
    }

    return resultado;
  }

  getMsgArray(mensagem: string): string[] {
    let arr = mensagem.split("\n");
    for (let i = 0; i < arr.length; i++) {
      if (i < (arr.length - 1)) {
        arr[i] = `${arr[i]}\n`;
      }
    }

    return arr;
  }

  private getTokenComandoType(text: string): TokenComandoType {
    if (text.includes("@If")) {
      return TokenComandoType.ComandoIf
    } else if (text.includes("@Else")) {
      return TokenComandoType.ComandoElse
    } else {
      return TokenComandoType.NaoComando;
    }
  }

  private getTokenBlocoType(text: string): TokenBlocoType {
    if (text.includes("Begin")) {
      return TokenBlocoType.Inicio;
    } else if (text.includes("End;")) {
      return TokenBlocoType.Fim;
    } else {
      return TokenBlocoType.NaoToken;
    }
  }

  private getBloco(msgArr: string[], i: number): any {
    let texto = "";
    let saida = false;

    while (i < msgArr.length && !saida) {
      let tipoToken = this.getTokenBlocoType(msgArr[i]);

      switch (tipoToken) {
        case TokenBlocoType.Inicio:
          i++;
          break;
        case TokenBlocoType.Fim:
          saida = true;
          break;
        default:
          texto += msgArr[i];
          i++;
          break;
      }
    }

    return { bloco: texto, index: i };
  }

  private executarIf(text: string): boolean {
    let obj = this.getKeyWordPair(text);
    if (!obj) {
      return false;
    }

    let posAbre = text.indexOf('(');
    let posFecha = text.indexOf(')');

    let valorArr: string[] = [];

    if (posAbre >= 0 && posFecha > posAbre) {
      let strValore = text.substring(posAbre + 1, posFecha);
      valorArr = strValore.split(',');

      if (valorArr.find(x => x.toUpperCase() === obj.value.toUpperCase())) {
        return true;
      }
    }

    return false;
  }

  private getKeyWordPair(texto: string): any {
    let result = null;
    this.tabelaKey.forEach(item => {
      if (texto.includes(item.key)) {
        result = item;
      }
    });

    return result;
  }
}