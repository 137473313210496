import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PacienteExameIndicadorModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PacienteExameIndicadorService extends BaseCrudService<PacienteExameIndicadorModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'PacienteExameIndicador';
  }

  getByPacienteId(pacienteId: string): Observable<PacienteExameIndicadorModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByPacienteId/${pacienteId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getExameDashboard(id, dataInicial, dataFinal: string): Observable<any[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetExameDashboard/${id}`, {
        headers: this.getAuthHeaderJson().headers,
        params: { dataInicial, dataFinal }
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
