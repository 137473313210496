import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { ContatoSiteModalComponent } from './contato-site-modal/contato-site-modal.component';
import { ContatoSiteComponent } from './contato-site.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    ContatoSiteComponent,
    ContatoSiteModalComponent
  ]
})
export class ContatoSiteModule { }
