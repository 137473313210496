<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">

				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>ChatBot - Resposta Alternativa</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>					
					<div class="row">
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Resp.Alternativa</mat-label>
								<input matInput formControlName="respostaAlternativa" type="text" maxlength="30" required />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Valor da Resposta</mat-label>
								<input matInput formControlName="valor" type="text" maxlength="30" required />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>