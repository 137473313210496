import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { PacienteModule } from '../../paciente/paciente/paciente.module';
import { ProcedimentoModule } from '../procedimento/procedimento.module';
import { AtendimentoComponent } from './atendimento.component';
import { AtendimentoModalComponent } from './atendimento-modal/atendimento-modal.component';
import { AtendimentoEntradaModalComponent } from './atendimento-entrada-modal/atendimento-entrada-modal.component';
import { AtendimentoProcedimentoComponent } from './atendimento-procedimento/atendimento-procedimento.component';
import { AtendimentoProcedimentoModalComponent } from './atendimento-procedimento/atendimento-procedimento-modal/atendimento-procedimento-modal.component';
import { AtendimentoReceitaComponent } from './atendimento-receita/atendimento-receita.component';
import { AtendimentoReceitaModalComponent } from './atendimento-receita/atendimento-receita-modal/atendimento-receita-modal.component';
import { AtendimentoSolicExameComponent } from './atendimento-solic-exame/atendimento-solic-exame.component';
import { AtendimentoSolicExameModalComponent } from './atendimento-solic-exame/atendimento-solic-exame-modal/atendimento-solic-exame-modal.component';
import { AtendimentoSolicExameItemComponent } from './atendimento-solic-exame/atendimento-solic-exame-item/atendimento-solic-exame-item.component';
import { AtendimentoSolicExameItemModalComponent } from './atendimento-solic-exame/atendimento-solic-exame-item/atendimento-solic-exame-item-modal/atendimento-solic-exame-item-modal.component';
import { AtendimentoSolicExameComparacaoComponent } from './atendimento-solic-exame/atendimento-solic-exame-comparacao/atendimento-solic-exame-comparacao.component';
import { AtendimentoReceitaPrintComponent } from './atendimento-print/atendimento-receita-print/atendimento-receita-print.component';
import { AtendimentoSolicExamePrintComponent } from './atendimento-print/atendimento-solic-exame-print/atendimento-solic-exame-print.component';
import { AtendimentoAtestadoPrintComponent } from './atendimento-print/atendimento-atestado-print/atendimento-atestado-print.component';
import { AtendimentoEsperaModalComponent } from './atendimento-espera-modal/atendimento-espera-modal.component';
import { AtendimentoHistoricoModalComponent } from './atendimento-historico-modal/atendimento-historico-modal.component';
import { AtendimentoProntuarioPrintComponent } from './atendimento-print/atendimento-prontuario-print/atendimento-prontuario-print.component';
import { ReportModule } from '../../report/report.module';
import { DocSusModule } from '../doc-sus/doc-sus.module';
import { LaudoModule } from '../laudo/laudo.module';
import { CapturaModule } from '../captura/captura.module';
import { AtendimentoReceitaHistoricoModalComponent } from './atendimento-receita/atendimento-receita-historico-modal/atendimento-receita-historico-modal.component';
import { AtendimentoSolicExameHistoricoModalComponent } from './atendimento-solic-exame/atendimento-solic-exame-historico-modal/atendimento-solic-exame-historico-modal.component';
import { AtendimentoAtestadoHistoricoModalComponent } from './atendimento-atestado-historico-modal/atendimento-atestado-historico-modal.component';

@NgModule({
  imports: [    
    SharedModule,
    NgxMatSelectSearchModule,
    PacienteModule,
    ProcedimentoModule,
    ReportModule,
    DocSusModule,
    LaudoModule,
    CapturaModule,
    ChartsModule
  ],
  declarations: [
    AtendimentoComponent,
    AtendimentoModalComponent,
    AtendimentoEntradaModalComponent,
    AtendimentoHistoricoModalComponent,
    AtendimentoEsperaModalComponent,

    AtendimentoAtestadoHistoricoModalComponent,
    
    AtendimentoProcedimentoComponent,
    AtendimentoProcedimentoModalComponent,
    
    AtendimentoReceitaComponent,
    AtendimentoReceitaModalComponent,
    AtendimentoReceitaHistoricoModalComponent,
    
    AtendimentoSolicExameComponent,
    AtendimentoSolicExameModalComponent,
    AtendimentoSolicExameComparacaoComponent,
    AtendimentoSolicExameHistoricoModalComponent,
        
    AtendimentoSolicExameItemComponent,
    AtendimentoSolicExameItemModalComponent,

    AtendimentoAtestadoPrintComponent,
    AtendimentoReceitaPrintComponent,
    AtendimentoSolicExamePrintComponent,
    AtendimentoProntuarioPrintComponent    
  ],
  exports: [
    AtendimentoEntradaModalComponent
  ]
})
export class AtendimentoModule { }
