import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AgendaEsperaModalComponent } from './agenda-espera-modal/agenda-espera-modal.component';
import { AgendaEsperaModel } from '../domain/models';
import { AgendaEsperaService } from '../domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-agenda-espera',
  templateUrl: './agenda-espera.component.html'
})
export class AgendaEsperaComponent extends BaseCrudComponent<AgendaEsperaModel> implements OnInit {
  @ViewChild('modal') modal: AgendaEsperaModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;
  @Output() modalSelect: EventEmitter<string> = new EventEmitter<string>();  

  recursoId: string;
  recursoNome: string;

  constructor(
    injector: Injector,
    readonly service: AgendaEsperaService
  ) {
    super(injector, service);
  }

  selectColumns = [     
    { data: 'dataInclusao'},    
    { data: 'paciente.pessoa.nomeFantasia'},
    { data: 'paciente.pessoa.dataNascimento'},
    { data: 'convenio.nome'},
    { data: 'observacao'},
  ];

  ngOnInit() {
    super.ngOnInit();    

    this.dtOptions.order = [[0, 'asc']];
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }
  
  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.recursoId) {
      dataTablesParameters.searches.push(new ModelSearch('profissionalRecursoId', OptionSearchType.Equals, SearchType.Guid, this.recursoId));
    }
    return super.beforeDtRequest(dataTablesParameters);
  }

  onResponse(id: string) {
    super.onResponse(id);
    this.filter();
  }

  show(recursoId, recursoNome) {
    this.recursoId = recursoId;
    this.recursoNome = recursoNome;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();    
  }

  create() {
    const model = new AgendaEsperaModel();
    model.dataInclusao = new Date();
    model.id = this.commonService.newGuid();
    model.profissionalRecursoId = this.recursoId;
    super.create(model);
  }

  edit(model: AgendaEsperaModel) {    
    this.modal.showEdit(model.id);
  }

  select(id) {        
    this.modalSelect.emit(id);
    this.close();
  }
}
