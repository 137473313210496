<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg">
    <div class="card modal-content" *ngIf="imagemUrl">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Imagem</h2>
      </div>
      <div class="card">
        <img class="img-responsive bg-grey" [src]="imagemUrl"
          style="width: 100%; height: 550px !important; object-fit: contain;" />
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
