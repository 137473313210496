import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AtendimentoService, EntregaExameService, ProcedimentoService } from '../../domain/services';
import { AtendimentoModel, EntregaExameModel, ProcedimentoModel } from '../../domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { PacienteModel } from '../../../paciente/domain/models';

@Component({
  selector: 'app-entrega-exame-modal',
  templateUrl: './entrega-exame-modal.component.html'
})
export class EntregaExameModalComponent extends BaseCrudModalComponent<EntregaExameModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  atendimento: AtendimentoModel;
  procedimento: ProcedimentoModel;
  paciente: PacienteModel;

  constructor(
    injector: Injector,
    service: EntregaExameService,
    readonly atendimentoService: AtendimentoService,
    readonly procedimentoService: ProcedimentoService,
    readonly pacienteService: PacienteService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: EntregaExameModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      atendimentoId: [model.atendimentoId],
      procedimentoId: [model.procedimentoId],
      entregue: [model.entregue || false],
      dataEntrega: [this.fromJsonDate(model.dataEntrega)],
      entreguePara: [model.entreguePara, [
        Validators.maxLength(100)
      ]],
      observacoes: [model.observacoes, [
        Validators.maxLength(500)
      ]],
    });

    this.atendimentoService.getById(model.atendimentoId).subscribe(atendimento => {
      this.atendimento = atendimento;
      this.pacienteService.getById(atendimento.pacienteId).subscribe(x => this.paciente = x);
    });
    this.procedimentoService.getById(model.procedimentoId).subscribe(x => this.procedimento = x);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
