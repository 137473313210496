import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { EspecialidadeMedService } from '../domain/services';
import { EspecialidadeMedModel } from '../domain/models';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { EspecialidadeMedModalComponent } from './especialidade-med-modal/especialidade-med-modal.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-especialidade-med',
  templateUrl: './especialidade-med.component.html',
  styleUrls: ['./especialidade-med.component.scss']
})
export class EspecialidadeMedComponent extends BaseCrudComponent<EspecialidadeMedModel> implements OnInit {
  @ViewChild('especialidadeMedModal') modal: EspecialidadeMedModalComponent;

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  constructor(
    injector: Injector,
    readonly especialidadeMedService: EspecialidadeMedService
  ) {
    super(injector, especialidadeMedService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.serverSide = false;
    this.especialidadeMedService.getAll().subscribe(especialidades => this.models = especialidades);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.models, event.previousIndex, event.currentIndex);
    const especialidadeIds = this.models.map(x => x.id);
    this.especialidadeMedService.updateOrdens(especialidadeIds).subscribe();
  }  
}
