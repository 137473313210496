<div bsModal #curriculoCpfModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="curriculoCpfModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">      
      <div class="header">
        <h2>Currículo</h2>
      </div>
      <div class="body table-responsive">
        <!--query end-->
        <table class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead class="thead-dark">
            <tr>
              <th class="text-center" width="80">Data</th>
              <th class="text-center" width="80">Status</th>
              <th class="text-center">Vaga</th>
              <th class="text-center">Nome</th>
              <th class="text-center" width="100">Sexo</th>
              <th class="text-center">Cidade</th>
              <th class="text-center" width="150">Celular</th>              
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let curriculo of models">
              <td>
                {{curriculo.dataInclusao | date :'dd/MM/yyyy'}}
              </td>
              <td class="text-center">
                <span [ngClass]="{
                        'bg-red': curriculo.status === StatusCurriculoType.Pendente,
                        'bg-orange': curriculo.status === StatusCurriculoType.Visto,
                        'bg-green': curriculo.status === StatusCurriculoType.Contratado,
                        'bg-grey': curriculo.status === StatusCurriculoType.NaoAtende
                      }" class="label">
                  {{getStatusCurriculo(curriculo.status)}}
                </span>
              </td>
              <td>
                {{curriculo.vagaTitulo}}
              </td>
              <td>
                {{curriculo.nome}}
              </td>
              <td>
                {{getSexoCurriculo(curriculo.sexo)}}
              </td>
              <td>
                {{curriculo.cidadeNome}}/{{curriculo.estadoUF}}
              </td>
              <td>
                {{curriculo.celular}}
              </td>              
              <td class="text-center">
                <a mat-icon-button href="{{curriculo.arquivoCurriculoUrl}}" target="_blank" rel="noopener"
                  matTooltip="Abrir Arquivo do Currículo">
                  <mat-icon>open_in_new</mat-icon>
                </a>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(curriculo)"
                  matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button"
                  (click)="delete(curriculo.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>          
    </div>
  </div>
</div>

<app-curriculo-modal #curriculoModal (modalSave)="onResponse($event)"></app-curriculo-modal>