import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { UsuarioCustomModel } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class UsuarioCustomService extends UsuarioService {
  constructor(injector: Injector) {
    super(injector);
  }

  create(model: UsuarioCustomModel): Observable<UsuarioCustomModel> {
    return this.httpClient
      .post(`${this.getApiUrl()}UsuarioCustom/Create`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  edit(model: UsuarioCustomModel): Observable<UsuarioCustomModel> {
    return this.httpClient.post(`${this.getApiUrl()}UsuarioCustom/Edit`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getById(id: string): Observable<UsuarioCustomModel> {
    return this
      .httpClient
      .get<UsuarioCustomModel>(`${this.getApiUrl()}UsuarioCustom/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
