import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { SolicitacaoOnlineFiltroDto, SolicitacaoOnlineModel } from '../models';


@Injectable({
  providedIn: 'root'
})
export class SolicitacaoOnlineService extends BaseCrudService<SolicitacaoOnlineModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'SolicitacaoOnline';
  }

  getByFiltro(dto: SolicitacaoOnlineFiltroDto): Observable<SolicitacaoOnlineModel[]> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/getByFiltro`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
