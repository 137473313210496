<div bsModal #pacienteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="pacienteModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Paciente</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <ul class="nav nav-tabs">
            <li class="active"><a class="nav-link active" id="nav-dados-tab" data-toggle="tab" href="#nav-dados"
                role="tab" aria-controls="nav-dados" aria-selected="true">Dados</a></li>
            <li *ngIf="prontuarioAtivo">
              <a class="nav-link" id="nav-anotacaoProntuario-tab" data-toggle="tab" href="#nav-anotacaoProntuario"
                role="tab" aria-controls="nav-anotacaoProntuario" aria-selected="false">Anotação de Prontuário</a>
            </li>
            <li *ngIf="prontuarioAtivo">
              <a class="nav-link" id="nav-prontuario-tab" data-toggle="tab" href="#nav-prontuario" role="tab"
                aria-controls="nav-prontuario" aria-selected="false">Histórico de Prontuário</a>
            </li>
            <li *ngIf="prontuarioAtivo">
              <a class="nav-link" id="nav-indicador-tab" data-toggle="tab" href="#nav-indicador" role="tab"
                aria-controls="nav-indicador" aria-selected="false">Resultados de Exames</a>
            </li>
          </ul>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade in active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
              <div class="row">
                <div class="col-md-2" formGroupName="pessoa">
                  <div class="form-group align-center">
                    <div style="height: 150px; width: 100%; object-fit: contain;">
                      <img src="{{form.value.pessoa.imagemUrl}}"
                        style="height: 150px; width: 100%; object-fit: contain;" *ngIf="form.value.pessoa.imagemUrl">
                    </div>
                    <div class="align-center">
                      <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                        <mat-icon>add_photo_alternate</mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="!form.value.pessoa.imagemUrl"
                        (click)="deselectImage()" matTooltip="Desvincular Imagem">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <p class="font-15 col-deep-blue text-center">
                      O tamanho ideal para Imagens é <strong>500x500</strong>
                    </p>
                  </div>
                </div>
                <div class="col-md-10">
                  <h4 class="card-inside-title">Dados Principais</h4>
                  <div class="row">
                    <div class="col-md-1">
                      <mat-form-field>
                        <mat-label>Código</mat-label>
                        <input matInput disabled [value]="form.value.pessoa.codigo" type="text" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" formGroupName="pessoa">
                      <mat-form-field>
                        <mat-label>CPF</mat-label>
                        <input matInput formControlName="cnpjCpf" type="text" placeholder="{{cnpjCpfPlaceholder}}" mask="{{cnpjCpfMask}}"
                          (blur)="changeCnpjCpf($event.target.value)" required maxlength="18" #focus />
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" formGroupName="pessoa">
                      <mat-form-field>
                        <mat-label>RG</mat-label>
                        <input matInput formControlName="iestadualRg" type="text" maxlength="18" />
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" formGroupName="pessoa">
                      <mat-form-field>
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="nomeFantasia" type="text" required minlength="5"
                          maxlength="60" />
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field>
                        <mat-label>Grupo de Paciente</mat-label>
                        <mat-select formControlName="grupoId">
                          <mat-option *ngFor="let grupo of grupos" [value]="grupo.value">
                            {{grupo.text}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2" formGroupName="pessoa">
                      <mat-form-field>
                        <mat-label>Sexo</mat-label>
                        <mat-select formControlName="sexo">
                          <mat-option *ngFor="let sexo of typesService.sexoTypes" [value]="sexo.value" required>
                            {{sexo.text}}</mat-option>
                        </mat-select>
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" formGroupName="pessoa">
                      <mat-form-field>
                        <mat-label>Data de Nascimento</mat-label>
                        <input matInput formControlName="dataNascimento" type="date" />
                        <mat-error>Informe uma Data de Nascimento válida.</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Idade</mat-label>
                        <input matInput disabled [value]="getIdadePessoa(form.value.pessoa.dataNascimento)" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Raça</mat-label>
                        <mat-select formControlName="raca">
                          <mat-option *ngFor="let raca of typesService.racaType" [value]="raca.value">
                            {{raca.text}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Estado Civil</mat-label>
                        <mat-select formControlName="estadoCivil">
                          <mat-option *ngFor="let estadoCivil of typesService.estadoCivilType"
                            [value]="estadoCivil.value">{{estadoCivil.text}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Tipo Sanguíneo</mat-label>
                        <mat-select formControlName="tipoSanguineo">
                          <mat-option *ngFor="let tipo of typesService.sangueType" [value]="tipo.value">
                            {{tipo.text}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Profissão</mat-label>
                        <input matInput formControlName="profissao" type="text" maxlength="60" />
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field>
                        <mat-label>Convênio</mat-label>
                        <mat-select formControlName="convenioId">
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Buscar (letras iniciais)..."
                              noEntriesFoundLabel="Nenhum resultado encontrado!" [formControl]="convenioFilterCtrl">
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let item of filteredConvenios" [value]="item.value">
                            {{item.text}}
                          </mat-option>
                        </mat-select>
                        <mat-error>Selecione um Convênio válido.</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Matrícula</mat-label>
                        <input matInput formControlName="convMatricula" type="text" maxlength="50" />
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field>
                        <mat-label>Validade Matrícula</mat-label>
                        <input matInput formControlName="convMatriculaValidade" type="date" />
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Cod. CNS (SUS)</mat-label>
                        <input matInput formControlName="codCns" type="text" maxlength="50" />
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="card-inside-title">Dados para Contato e Cobrança</h4>
              <div class="row">
                <div class="col-md-3" formGroupName="pessoa">
                  <mat-form-field>
                    <mat-label>Telefone</mat-label>
                    <input matInput formControlName="telefone" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 0000-0000" placeholder="Ex: (48) 3333-3333" [required]="telefoneObrigatorio === true"/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3" formGroupName="pessoa">
                  <mat-form-field>
                    <mat-label>Celular</mat-label>
                    <input matInput formControlName="celular" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" 
                      (blur)="onExitCelular()" [required]="celularObrigatorio === true" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>  
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>IdWhatsApp</mat-label>
                    <input matInput disabled [value]="form.value.idWhatsApp" type="text"/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>              
                <div class="col-md-3" formGroupName="pessoa">
                  <mat-form-field>
                    <mat-label>E-mail</mat-label>
                    <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com"
                      maxlength="50" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="pessoa-juridica" formGroupName="pessoa">
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Site</mat-label>
                      <input matInput formControlName="site" type="url" placeholder="Ex: meusite.com" maxlength="50" />
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <h4 class="card-inside-title">Dados Adicionais</h4>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Nome do Pai</mat-label>
                    <input matInput formControlName="nomePai" type="text" maxlength="60" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Nome da Mãe</mat-label>
                    <input matInput formControlName="nomeMae" type="text" maxlength="60" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Nome do Cônjuge</mat-label>
                    <input matInput formControlName="nomeConjuge" type="text" maxlength="60" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Nome do Responsável</mat-label>
                    <input matInput formControlName="nomeResponsavel" type="text" maxlength="60" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Observações</mat-label>
                    <textarea matInput #observacoes formControlName="observacoes" rows="4" maxlength="500"></textarea>
                    <mat-hint align="end">{{observacoes.value.length}} / 500</mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Indicado Por</mat-label>
                    <mat-select formControlName="indicadoPor" required>
                      <mat-option *ngFor="let indicadoPor of typesService.pacienteIndicadoPorType"
                        [value]="indicadoPor.value">{{indicadoPor.text}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Nome do Indicador</mat-label>
                    <input matInput formControlName="nomeIndicador" type="text" maxlength="60" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Código Prontuário/Ficha</mat-label>
                    <input matInput formControlName="codProntuario" type="text" maxlength="20" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
              </div>
              <h4 class="card-inside-title">Bloqueio/Alerta</h4>
              <div class="row">
                <div class="col-md-3">
                  <mat-slide-toggle formControlName="exibirAlertaAtendimento" color="primary">
                    Exibir Alerta Atendimento
                  </mat-slide-toggle>
                </div>
                <div class="col-md-3">
                  <mat-slide-toggle formControlName="bloquearAgendamento" color="primary">
                    Bloquear Agendamento
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3" *ngIf="form.value.bloquearAgendamento">
                  <mat-form-field>
                    <mat-label>Bloquear até</mat-label>
                    <input matInput formControlName="dataExpiraBloqueio" type="date" />
                    <mat-error>Informe uma Data válida.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <hr>
                  <mat-form-field>
                    <mat-label>Mensagem Bloqueio/Alerta</mat-label>
                    <textarea matInput #mensagemBloqAlerta formControlName="mensagemBloqAlerta" rows="4"
                      maxlength="500"></textarea>
                    <mat-hint align="end">{{mensagemBloqAlerta.value.length}} / 500</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <h4 class="card-inside-title">Dados de endereço</h4>
              <div class="row" formGroupName="pessoa">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>CEP</mat-label>
                    <input matInput formControlName="cep" type="text" [dropSpecialCharacters]="false" mask="00.000-000"
                      maxlength="10" placeholder="Ex: 00.000-000" (blur)="changeCep($event.target.value)" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Rua / Avenida</mat-label>
                    <input matInput formControlName="ruaAvenida" type="text" maxlength="50" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Número</mat-label>
                    <input matInput formControlName="numero" type="text" maxlength="10" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Complemento</mat-label>
                    <input matInput formControlName="complemento" type="text" maxlength="50" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Bairro</mat-label>
                    <input matInput formControlName="bairro" type="text" maxlength="50" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="estadoId" (selectionChange)="changeEstado($event.value)">
                      <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.uf}}
                      </mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Cidade</mat-label>
                    <mat-select formControlName="cidadeId">
                      <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                      </mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-anotacaoProntuario" role="tabpanel"
              aria-labelledby="nav-anotacaoProntuario-tab">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Anotação de Prontuário</mat-label>
                    <textarea matInput #notaProntuario formControlName="anotacaoProntuario" rows="10"
                      maxlength="1000"></textarea>
                    <mat-hint align="end">{{notaProntuario.value.length}} / 1000</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-prontuario" role="tabpanel" aria-labelledby="nav-prontuario-tab">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <button mat-flat-button color="primary" type="button"
                      (click)="createProntuario()">Adicionar</button>
                    <table class="table table-bordered table-striped table-hover dataTable"
                      style="width: 100%; max-height: 500px; overflow-y: scroll; display:inline-block;">
                      <thead>
                        <tr>
                          <th class="text-center">Data</th>
                          <th class="text-center">Tipo</th>
                          <th class="text-center">Profissional da Saúde</th>
                          <th class="text-center" width="120"></th>
                        </tr>
                      </thead>
                      <tbody *ngIf="listaProntuarioDto?.length != 0">
                        <tr *ngFor="let item of listaProntuarioDto">
                          <td>
                            {{item.dataInclusao | date: 'dd/MM/yyyy HH:mm'}}
                          </td>
                          <td>
                            <span [ngClass]="typesService.getPacienteProntuarioTypeColor(item.tipo)" class="label">
                              {{typesService.getPacienteProntuarioTypeNome(item.tipo)}}
                            </span>
                          </td>
                          <td>
                            {{item.profissionalSaudeNome}}
                          </td>
                          <td>
                            <button mat-icon-button type="button" (click)="detailProntuario(item)" matTooltip="Visualizar">
                              <mat-icon>preview</mat-icon>
                            </button>
                            <button mat-icon-button type="button"
                              [disabled]="item.profissionalSaudeId !== profissionalSaudeId"
                              (click)="editProntuario(item)" matTooltip="Editar">
                              <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button type="button"
                              [disabled]="item.profissionalSaudeId !== profissionalSaudeId"
                              (click)="deleteProntuario(item.value)" matTooltip="Excluir">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-indicador" role="tabpanel" aria-labelledby="nav-indicador-tab">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <button mat-flat-button color="primary" type="button" (click)="createExame()">Adicionar</button>
                    <table class="table table-bordered table-striped table-hover dataTable"
                      style="width: 100%; max-height: 500px; overflow-y: scroll; display:inline-block;">
                      <thead>
                        <tr>
                          <th class="text-center">Procedimento/Exame</th>
                          <th class="text-center" width="120"></th>
                        </tr>
                      </thead>
                      <tbody *ngIf="listaExameIndicador?.length != 0">
                        <tr *ngFor="let item of listaExameIndicador">
                          <td>
                            {{item.procedimentoNome}}
                          </td>
                          <td>
                            <button mat-icon-button type="button" (click)="exameDashboardModal.show(item.id)"
                              matTooltip="Dashboard de Resultado de Exames">
                              <mat-icon>donut_small</mat-icon>
                            </button>
                            <button mat-icon-button type="button" (click)="editExame(item)" matTooltip="Editar">
                              <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button type="button" (click)="deleteExame(item.id)" matTooltip="Excluir">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-3 text-left">
              <p style="margin: 0;">Incluído:{{form.value.dataInclusao | date:"dd/MM/yyyy"}}</p>
              <p style="margin: 0;">Alterado:{{form.value.dataAlteracao | date:"dd/MM/yyyy"}}</p>
            </div>
            <div class="col-sm-2 text-left">
            </div>
            <div class="col-sm-7">
              <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
                Continuar Incluindo
              </mat-slide-toggle>
              <button mat-flat-button [disabled]="isBusy" type="submit" (click)="save()" color="primary"
                class="button-row">
                <span> Salvar</span>
              </button>
              <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
                <span> Cancelar</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
<app-paciente-exame-indicador-modal #pacienteExameIndModal
  (modalSave)="onResponseExame($event)"></app-paciente-exame-indicador-modal>
<app-exame-dashboard-modal #exameDashboardModal></app-exame-dashboard-modal>
<app-paciente-prontuario-modal #pacienteProntuarioModal
  (modalSave)="onResponseProntuario($event)"></app-paciente-prontuario-modal>