import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { UsuariosComponent } from './usuarios.component';
import { UsuarioModalComponent } from './usuario-modal/usuario-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    UsuariosComponent,
    UsuarioModalComponent
  ]
})
export class UsuariosModule { }
