<div class="card">
  <div class="header">
    <h2>Tipos de Parceiros</h2>
  </div>
  <div class="body table-responsive">
    <!--query end-->
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center">Nome</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="models?.length != 0">
        <tr *ngFor="let tipoParceiro of models">
          <td>
            {{tipoParceiro.nome}}
          </td>
          <td class="text-center">
            <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(tipoParceiro)"
              matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
            <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(tipoParceiro.id)"
              matTooltip="Excluir">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>  
</div>
<div class="fixed-action-btn">
  <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
    matTooltipPosition="above">
    <mat-icon>add</mat-icon>
  </button>
</div>
<app-tipo-parceiro-modal #tipoParceiroModal (modalSave)="onResponse($event)"></app-tipo-parceiro-modal>