<div bsModal #imagemModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="imagemModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
	<div class="modal-dialog modal-lg">
		<div class="card modal-content">			
			<div class="header">				
				<button type="button" class="close" (click)="close()">
					<span aria-hidden="true">&times;</span>
				</button>
				<h2>Imagem</h2>
			</div>
			<div class="modal-body">				
				<div class="row" *ngIf="newItem">					
					<div class="col-md-6 col-md-offset-3">
						<div class="form-group">
							<div class="file-drop-area" (click)="uploadFiles()">
								<label for="files">Coloque sua imagem aqui.</label>
								<input #fileUploadCreate type="file" multiple style="display: none;">
							</div>
						</div>
					</div>
					<div class="col-md-6 col-md-offset-3" *ngFor="let slimOptions of slimOptionsList">
						<div class="form-group">
							<slim [options]="slimOptions" style="cursor: pointer">									
								<input type="file" accept="image/jpeg,image/png" />
							</slim>
						</div>
					</div>
				</div>
				
				<div class="row" *ngIf="!newItem && slimOptionsEdit">
					<div class="col-md-6 col-md-offset-3">
						<div class="form-group">
							<slim [options]="slimOptionsEdit" style="cursor: pointer">									
								<input type="file" accept="image/jpeg,image/png" />
							</slim>
						</div>
					</div>						
				</div>
			</div>
			<div class="footer">					
				<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
					<span>Fechar</span>
				</button>					
			</div>			
		</div>
	</div>
</div>
