import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { AtendimentoEntradaModalComponent } from '../atendimento/atendimento/atendimento-entrada-modal/atendimento-entrada-modal.component';
import { EquipamentoModel } from '../atendimento/domain/models';
import { AtendimentoProcedimentoService, EquipamentoService, GrupoProcedimentoService } from '../atendimento/domain/services';
import { AtendimentoRecebtoStatusType } from '../atendimento/domain/types';
import { ProfissionalSaudeService } from '../equipe/domain/services';
import { ConvenioService } from '../parceiro/domain/services/convenio.service';
import { FaturamentoService } from './domain/services';

@Component({
  selector: 'app-faturamento',
  templateUrl: './faturamento.component.html'
})
export class FaturamentoComponent extends BaseComponent implements OnInit {
  @ViewChild('atendimentoEntradaModal') atendimentoEntradaModal: AtendimentoEntradaModalComponent;
  @ViewChild('tabs') tabGroup: MatTabGroup;

  listaConvenio: SelectDto[] = [];
  listaConvenioIdSelecionado: string[] = [];
  convenioTotalSelect: boolean;
  listaMedico: SelectDto[] = [];
  listaMedicoIdSelecionado: string[] = [];
  medicoTotalSelect: boolean;
  listaGrupoProc: SelectDto[] = [];
  listaGrupoProcIdSelecionado: string[] = [];
  grupoProcTotalSelect: boolean;
  listaEquipamento: EquipamentoModel[] = [];
  listaEquipamentoIdSelecionado: string[] = [];
  equipamentoTotalSelect: boolean;

  dataInicial: string;
  dataFinal: string;
  statusRecebto: AtendimentoRecebtoStatusType;
  atendimentoRecebtoStatusType: typeof AtendimentoRecebtoStatusType = AtendimentoRecebtoStatusType;

  isBusy: boolean;
  listaDto: any;
  totalSelect: boolean;
  totalGeral: number;
  totalClinica: number;
  totalMedicos: number;

  atendProcId: string;

  constructor(
    injector: Injector,
    readonly service: FaturamentoService,
    readonly convenioService: ConvenioService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly equipamentoService: EquipamentoService,
    readonly grupoProcedimentoService: GrupoProcedimentoService,
    readonly atendimentoProcedimentoService: AtendimentoProcedimentoService,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (!this.isAllowed()) {
      this.commonService.mensagem("", "Você não tem permissão de execução das rotinas de faturamento!", "info");
    }
    this.convenioService.getSelectList().subscribe(x => this.listaConvenio = x);
    this.profissionalSaudeService.getSelectList().subscribe(x => this.listaMedico = x);
    this.equipamentoService.getAll().subscribe(x => this.listaEquipamento = x);
    this.grupoProcedimentoService.getSelectList().subscribe(x => this.listaGrupoProc = x);
    this.statusRecebto = AtendimentoRecebtoStatusType.Pendente;
    this.dataInicial = this.fromJsonDate(new Date());
    this.dataFinal = this.fromJsonDate(new Date());    
  }

  isAllowed(): boolean {
    return super.isAllowed("Read", "Faturamento");
  }

  async filtrar() {
    this.commonService.spinnerOpen("Gerando Relatório...");
    this.isBusy = true;

    const filterDto = {
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,

      //Se item estiver com TotalSelect = true então não enviar lista de filtro (limpar)
      convenioIds: this.convenioTotalSelect ? [] : this.listaConvenioIdSelecionado,
      grupoProcedimentoIds: this.grupoProcTotalSelect ? [] : this.listaGrupoProcIdSelecionado,
      medicoIds: this.medicoTotalSelect ? [] : this.listaMedicoIdSelecionado,
      equipamentoIds: this.equipamentoTotalSelect ? [] : this.listaEquipamentoIdSelecionado,
      statusRecebto: this.statusRecebto
    }

    this.listaDto = await this.service.getAtendimento(filterDto).toPromise();
    this.totalSelect = false;
    this.listaDto.forEach(obj => {
      obj.selecionado = false;
    });

    this.totalGeral = this.listaDto.map(x => x.vlrTotal).reduce((acumulador, atual) => {
      return acumulador + atual
    }, 0);
    this.totalClinica = this.listaDto.map(x => x.vlrClinica).reduce((acumulador, atual) => {
      return acumulador + atual
    }, 0);
    this.totalMedicos = this.listaDto.map(x => x.vlrMedico).reduce((acumulador, atual) => {
      return acumulador + atual
    }, 0);

    this.commonService.spinnerClose();

    this.tabGroup.selectedIndex = 1;
  }

  async aplicarRecebto() {
    let result = await this.commonService.mensagemConfirmacao("Aplicar Recebimento", "Confirmar?", "question");
    if (result) {
      this.commonService.spinnerOpen("Aplicando Recebimento...");
      this.isBusy = true;

      const listaSelecionadoId = this.listaDto.filter(x => x.selecionado).map(x => x.id);

      await this.service.applyStatusRecebido(listaSelecionadoId).toPromise();
           
      this.commonService.spinnerClose();

      this.filtrar();
    }
  }

  async desfazerRecebto() {
    let result = await this.commonService.mensagemConfirmacao("Desfazer Recebimento", "Confirmar?", "question");
    if (result) {

      this.commonService.spinnerOpen("Desfazer Recebimento...");
      this.isBusy = true;

      const listaSelecionadoId = this.listaDto.filter(x => x.selecionado).map(x => x.id);

      await this.service.undoStatusRecebido(listaSelecionadoId).toPromise();
      this.commonService.spinnerClose();

      this.filtrar();
    }
  }

  selectAll(tipoObj: number) {
    switch (tipoObj) {
      case 1:
        this.listaConvenioIdSelecionado = [...this.listaConvenio.map(x => x.value)];
        this.convenioTotalSelect = true;
        break;
      case 2:
        this.listaMedicoIdSelecionado = [...this.listaMedico.map(x => x.value)];
        this.medicoTotalSelect = true;
        break;
      case 3:
        this.listaEquipamentoIdSelecionado = [...this.listaEquipamento.map(x => x.id)];
        this.equipamentoTotalSelect = true;
        break;
      case 4:
        this.listaGrupoProcIdSelecionado = [...this.listaGrupoProc.map(x => x.value)];
        this.grupoProcTotalSelect = true;
        break;
    }
  }

  deSelectAll(tipoObj: number) {
    switch (tipoObj) {
      case 1:
        this.listaConvenioIdSelecionado = [];
        this.convenioTotalSelect = false;
        break;
      case 2:
        this.listaMedicoIdSelecionado = [];
        this.medicoTotalSelect = false;
        break;
      case 3:
        this.listaEquipamentoIdSelecionado = [];
        this.equipamentoTotalSelect = false;
        break;
      case 4:
        this.listaGrupoProcIdSelecionado = [];
        this.grupoProcTotalSelect = false;
        break;
    }
  }

  onSelectionChange(tipoObj: number) {
    switch (tipoObj) {
      case 1:
        this.convenioTotalSelect = (this.listaConvenioIdSelecionado.length == this.listaConvenio.length);
        break;
      case 2:
        this.medicoTotalSelect = (this.listaMedicoIdSelecionado.length == this.listaMedico.length);
        break;
      case 3:
        this.equipamentoTotalSelect = (this.listaEquipamentoIdSelecionado.length == this.listaEquipamento.length);
        break;
      case 4:
        this.grupoProcTotalSelect = (this.listaGrupoProcIdSelecionado.length == this.listaGrupoProc.length);
        break;
    }
  }
  tbSelect(item: any) {
    item.selecionado = true;
  }
  tbDeSelect(item: any) {
    item.selecionado = false;
    this.totalSelect = false;
  }

  tbSelectAll() {
    this.listaDto.forEach(obj => {
      obj.selecionado = true;
    });
    this.totalSelect = true;
  }

  tbDeSelectAll() {
    this.listaDto.forEach(obj => {
      obj.selecionado = false;
    });
    this.totalSelect = false;
  }


  editAtendimento(item: any) {
    this.atendProcId = item.atendProcId;
    this.atendimentoEntradaModal.showEdit(item.id);
  }

  async onAtendimentoResponse(atendimentoId) {

    const modelAtendProc = await this.atendimentoProcedimentoService.getById(this.atendProcId).toPromise();
    const registro = this.listaDto.find(x => x.atendProcId === this.atendProcId);

    this.subtrairVlrAnterior(registro);

    //Calcular novos valores
    registro.vlrTotal = modelAtendProc.vlrTotal;
    registro.vlrClinica = Math.round((modelAtendProc.vlrTotal * modelAtendProc.percClinica / 100) * 100) / 100;
    registro.vlrMedico = registro.vlrTotal - registro.vlrClinica;

    this.adicionarVlrNovo(registro);
  }

  adicionarVlrNovo(registro: any) {
    this.totalGeral = this.totalGeral + registro.vlrTotal;
    this.totalClinica = this.totalClinica + registro.vlrClinica;
    this.totalMedicos = this.totalMedicos + registro.vlrMedico
  }

  subtrairVlrAnterior(registro: any) {
    this.totalGeral = this.totalGeral - registro.vlrTotal;
    this.totalClinica = this.totalClinica - registro.vlrClinica;
    this.totalMedicos = this.totalMedicos - registro.vlrMedico
  }

}
