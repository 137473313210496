import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AtestadoModalComponent } from './atestado-modal/atestado-modal.component';
import { AtestadoModeloModel } from '../domain/models';
import { AtestadoService } from '../domain/services/atestado.service';

@Component({
  selector: 'app-atestado',
  templateUrl: './atestado.component.html'
})
export class AtestadoComponent extends BaseCrudComponent<AtestadoModeloModel> implements OnInit {
  @ViewChild('modal') modal: AtestadoModalComponent;

  constructor(
    injector: Injector,
    readonly service: AtestadoService
  ) {
    super(injector, service);
  }

  selectColumns = [
    { data: 'nome' },
    { data: 'texto' },
    { data: 'cids' },
  ];

  ngOnInit() {
    super.ngOnInit();
  }
}
