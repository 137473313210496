<div class="col-md-12">
  <div class="card">
    <div class="header">
      <h2>Filtro</h2>
    </div>
    <div class="body">
      <div class="row">
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="searchStatus.searchTerm">
              <mat-option [value]=-1>Todos</mat-option>
              <mat-option *ngFor="let status of typesService.statusPublicacaoType" [value]="status.value"
                [disabled]="status.value === statusPublicacaoType.Agendada">
                {{status.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Filtrando por:</mat-label>
            <mat-select [(ngModel)]="search.field">
              <mat-option value="titulo">Titulo</mat-option>
              <mat-option value="descricaoInterna">Descrição Interna</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Tipo Filtro</mat-label>
            <mat-select [(ngModel)]="search.option">
              <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Pesquisar por</mat-label>
            <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="footer">
      <button mat-flat-button color="primary" (click)="filter()">
        <span>Filtrar</span>
      </button>
    </div>
  </div>
</div>

<div class="col-md-12">
  <div class="card">
    <div class="header">
      <h2>Questionários</h2>
    </div>
    <div class="body table-responsive">
      <!--query end-->
      <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
        style="width: 100%;">
        <thead>
          <tr>
            <th class="text-center">Status</th>
            <th class="text-center">Titulo</th>
            <th class="text-center">Descrição Interna</th>
            <th class="text-center" width="220"></th>
          </tr>
        </thead>
        <tbody *ngIf="models?.length != 0">
          <tr *ngFor="let model of models">
            <td>
              <span [ngClass]="typesService.getStatusPublicacaoTypeColor(model.statusPublicacao)" class="label">
                {{typesService.getStatusPublicacaoType(model.statusPublicacao)}}
              </span>
            </td>
            <td>
              {{model.titulo}}
            </td>
            <td>
              {{model.descricaoInterna}}
            </td>
            <td class="text-center">
              <button
                *ngIf="model.statusPublicacao === statusPublicacaoType.Publicada"
                mat-icon-button type="button" (click)="encerrar(model)" matTooltip="Encerrar Pesquisa">
                <mat-icon>highlight_off</mat-icon>
              </button>
              <button
                *ngIf="model.statusPublicacao === statusPublicacaoType.Rascunho"
                mat-icon-button type="button" (click)="publicar(model)" matTooltip="Publicar Pesquisa">
                <mat-icon>playlist_add_check</mat-icon>
              </button>
              <button *ngIf="model.statusPublicacao !== statusPublicacaoType.Rascunho" mat-icon-button type="button"
                (click)="questionarioDashQuestaoModal.show(model.id)" matTooltip="Ver Dashboard p/Grupo e Questão">
                <mat-icon>pie_chart</mat-icon>
              </button>
              <button *ngIf="model.statusPublicacao !== statusPublicacaoType.Rascunho" mat-icon-button type="button"
                (click)="respostaListaModal.show(model.id,model.titulo)" matTooltip="Ver Listagem Respostas">
                <mat-icon>list_alt</mat-icon>
              </button>
              <button *ngIf="model.statusPublicacao !== statusPublicacaoType.Rascunho" mat-icon-button type="button"
                (click)="questionarioDashGeralModal.show(model.id,model.titulo)" matTooltip="Ver Dashboard Geral">
                <mat-icon>donut_small</mat-icon>
              </button>
              <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(model)"
                matTooltip="Editar">
                <mat-icon>edit</mat-icon>
              </button>
              <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(model.id)"
                matTooltip="Excluir">
                <mat-icon>delete</mat-icon>
              </button>
              <!-- <button mat-icon-button type="button" (click)="duplicar(model)" matTooltip="Duplicar Pesquisa">
                <mat-icon>content_copy</mat-icon>
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="fixed-action-btn">
      <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
        matTooltipPosition="above">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <app-questionario-modal #questionarioModal (modalSave)="onResponse($event)"></app-questionario-modal>
  <app-questionario-dash-questao #questionarioDashQuestaoModal></app-questionario-dash-questao>
  <app-questionario-dash-geral #questionarioDashGeralModal></app-questionario-dash-geral>
  <app-questionario-resp #respostaListaModal></app-questionario-resp>