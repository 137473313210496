<div class="card">
  <div class="body" *ngIf="form">
    <form method="post" (ngSubmit)="save()" [formGroup]="form">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="header">
                  <h2>Sugestão de Cores</h2>
                </div>
                <div class="body">
                  <div class="row">
                    <div class="col-md-1 col-xs-1" style="padding: 0 20px;"
                      *ngFor="let suggestionColor of suggestionColors">
                      <div class="circle" (click)="changeColor(suggestionColor);"
                        [style.border-top-color]="suggestionColor.colorPrimary"
                        [style.border-left-color]="suggestionColor.colorPrimary"
                        [style.border-right-color]="suggestionColor.colorSecondary"
                        [style.border-bottom-color]="suggestionColor.colorSecondary">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Cor Primária</mat-label>
                <input matInput #corHexCodePrimary formControlName="themeColorPrimary"
                  [(colorPicker)]="corHexCodePrimary.value"
                  (colorPickerChange)="setColorPrimary(corHexCodePrimary.value)" [cpOutputFormat]="'hex'"
                  maxlength="7" />
                <mat-icon [style.color]="form.value.themeColorPrimary" matSuffix>brightness_1</mat-icon>
                <mat-error>Informe um HexCode válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Cor Secundária</mat-label>
                <input matInput #corHexCodeSecondary formControlName="themeColorSecondary"
                  [(colorPicker)]="corHexCodeSecondary.value"
                  (colorPickerChange)="setColorSecondary(corHexCodeSecondary.value)" [cpOutputFormat]="'hex'"
                  maxlength="7" />
                <mat-icon [style.color]="form.value.themeColorSecondary" matSuffix>brightness_1</mat-icon>
                <mat-error>Informe um HexCode válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="webFonts">
              <mat-form-field>
                <mat-label>Fonte</mat-label>
                <mat-select formControlName="themeFontFamily" (selectionChange)="changeFont($event.value)">
                  <mat-option *ngFor="let item of webFonts.items" [value]="item.family">{{item.family}}</mat-option>
                </mat-select>
                <mat-error>Selecione uma fonte.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <button mat-flat-button type="submit" color="primary" class="button-row">
                <span>Salvar Alterações</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="control-label">Pré-visualização do layout</label>
          <div class="card">
            <div>
              <img src="assets-base/images/previews/browser-top.png" class="img-responsive" style="width: 100%;">
            </div>
            <app-previews [layout]="temaAtual" [colorPrimary]="form.value.themeColorPrimary"
              [colorSecondary]="form.value.themeColorSecondary"></app-previews>
          </div>
        </div>
        <div class="col-md-5">
          <label class="control-label">Pré-visualização da fonte</label>
          <label id="preview_font" style="font-weight: unset; font-size: 14pt;"
            [style.font-family]="form.value.themeFontFamily">
            a b c d e f g h i j k l m n o p q r s t u v w x y z
            <br />
            A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
            <br />
            0 1 2 3 4 5 6 7 8 9 ! $ # % & @ . ,
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet efficitur risus at viverra.
            Curabitur finibus quam sit amet tortor elementum, eget dictum quam vulputate. Donec consequat erat
            tortor, a suscipit erat bibendum pellentesque. Cras lectus purus, fringilla sed rutrum a, tristique
            vel lacus. Sed eu tempus dui, sed cursus elit. Integer orci tortor, pellentesque vitae eros nec,
            cursus cursus turpis.
          </label>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Fontes -->
<link id="google-font" rel="stylesheet">
