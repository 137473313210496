<div bsModal #requisicaoLgpdModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="requisicaoLgpdModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}"
  (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Requisição LGPD</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data da Requisição</mat-label>
                <input matInput formControlName="data" type="datetime-local" readonly />
                <mat-error>Informe uma Data válida.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-7">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>CPF</mat-label>
                <input matInput formControlName="cpf" type="text" [dropSpecialCharacters]="false" mask="{{cpfMask}}"
                  placeholder="{{cpfPlaceholder}}" />
                  <mat-hint align="end">Máx 20 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Solicitação LGPD</mat-label>
                <mat-select formControlName="tipoSolicitacao">
                  <mat-option *ngFor="let tipoSolicitacao of tiposSolicitacoes" [value]="tipoSolicitacao.value">
                    {{tipoSolicitacao.text}}</mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" (selectionChange)="onChangeStatus()">
                  <mat-option *ngFor="let status of statusPossiveis" [value]="status.value">
                    {{status.text}}</mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Data Status</mat-label>
                <input matInput formControlName="dataStatus" type="datetime-local" readonly />
                <mat-error>Informe uma Data válida.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <h4 class="card-inside-title">Dados para Contato</h4>
          <div class="row">
            <div class="col-md-9">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Telefone</mat-label>
                <input matInput formControlName="telefone" type="tel" [dropSpecialCharacters]="false"
                mask="(00) 00000-0000" placeholder="Ex: (48) 93333-3333" />
                <mat-hint align="end">Máx 16 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Mensagem</mat-label>
                <textarea matInput #mensagem formControlName="mensagem" rows="3" maxlength="200"></textarea>
                <mat-hint align="end">{{mensagem.value.length}} / 200</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observação Interna</mat-label>
                <textarea matInput #observacaoInterna formControlName="observacaoInterna" rows="3"
                  maxlength="200"></textarea>
                <mat-hint align="end">{{observacaoInterna.value.length}} / 200</mat-hint>
              </mat-form-field>
            </div>

          </div>
        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
