import { Component, Injector, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ConfigSiteTenantModel, TemaModel } from 'projects/ProjetoBaseAngular/app/admin/meu-site/domain/models';
import { TemaService } from 'projects/ProjetoBaseAngular/app/admin/meu-site/domain/services/tema.service';
import { ConfigSiteTenantService } from 'projects/ProjetoBaseAngular/app/admin/meu-site/domain/services/config-site-tenant.service';

@Component({
  selector: 'app-temas',
  templateUrl: './temas.component.html'
})
export class TemasComponent extends BaseComponent implements OnInit {
  temas: TemaModel[];
  temaAtual: number;
  configSiteTenant: ConfigSiteTenantModel;

  constructor(
    injector: Injector,
    private readonly temaService: TemaService,
    private readonly configSiteService: ConfigSiteTenantService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.temaService.get().subscribe(response => this.temas = response);
    this.temaService.getCurrent().subscribe(tema => this.temaAtual = tema.codigo);
    this.configSiteService.get().subscribe(configSiteTenant => this.configSiteTenant = configSiteTenant);
  }

  selecionarTema(codigo) {
    this.temaService.updateCodigo(codigo).subscribe(() => {
      this.temaAtual = codigo;
      Swal.fire(`Tema ${codigo} selecionado!`);
    },
      (errors) => {
        Swal.fire(errors.join('/n'));
      });
  }

  isAllowed(value: string) {
    const type = this.temaService.getController();
    return super.isAllowed(value, type);
  }
}
