import { Injectable, Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from '../base-crud.service';
import { PessoaModel } from '../../models/entidade-pessoa/pessoa-model';
import { BaseEntidadePessoaModel, PessoaDto } from '../../models/entidade-pessoa/base-entidade-pessoa-model';

@Injectable({
  providedIn: 'root'
})
export class BaseEntidadePessoaService<TEntidadePessoaModel extends BaseEntidadePessoaModel> extends BaseCrudService<TEntidadePessoaModel> {

  tipoEntidadePessoa: string = "Base";

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return '';
  }

  isTipoEntidade(tipo: string): boolean {
    let lista = tipo.split(",", 30);
    let item = lista.find(x => x === this.tipoEntidadePessoa);
    if (item) {
      return true;
    } else {
      return false;
    }
  };

  getByCnpjCpf(cnpjCpf: string): Observable<PessoaModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByCnpjCpf/${cnpjCpf}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByCodigo(codigo: number): Observable<PessoaModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByCodigo/${codigo}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getPessoaSelectByCodigo(codigo: number): Observable<PessoaDto> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetPessoaSelectByCodigo/${codigo}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getPessoaSelect(id: string): Observable<PessoaDto> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetPessoaSelect/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
