import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ReceitaModeloModel } from '../../domain/models';
import { ReceitaService } from '../../domain/services/receita.service';
import { ProfissionalSaudeService } from '../../../equipe/domain/services';
import { ProfissionalSaudeDto } from '../../../equipe/domain/models';

@Component({
  selector: 'app-receita-modal',
  templateUrl: './receita-modal.component.html'
})
export class ReceitaModalComponent extends BaseCrudModalComponent<ReceitaModeloModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;  

  listaProfissional: ProfissionalSaudeDto[] = [];

  constructor(
    injector: Injector,
    service: ReceitaService,
    readonly profissionalService: ProfissionalSaudeService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected init(): void {
    this.profissionalService.getSelectList().subscribe(x => this.listaProfissional = x);
    super.init();
  }

  protected initializeForm(model: ReceitaModeloModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: this.commonService.fromJsonDate(model.dataInclusao),
      dataAlteracao: this.commonService.fromJsonDate(model.dataAlteracao),

      nome: [model.nome, [
        Validators.maxLength(50),
        Validators.required
      ]],
      texto: [model.texto, Validators.required],
      profissionalSaudeId: [model.profissionalSaudeId, Validators.required],
    });
  }

  protected newModel(): ReceitaModeloModel {
    const model = new ReceitaModeloModel();
    model.id = this.commonService.newGuid(); 
    model.dataInclusao = new Date();
    model.profissionalSaudeId = "";

    return model;
  }
}
