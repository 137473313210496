<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Captura - {{procedimentoApresentacao}}</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Cód./CPF Paciente</mat-label>
                <input matInput disabled [value]="atendimento?.pacienteCnpjCpf" type="text" />
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <mat-form-field>
                <mat-label>Paciente</mat-label>
                <input matInput disabled
                  [value]="(atendimento?.pacienteCodigo ? atendimento?.pacienteCodigo + ' - ' : '') + atendimento?.pacienteNome"
                  type="text" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Convênio</mat-label>
                <input matInput disabled [value]="atendimento?.convenioNome" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" disabled>
                  <mat-option *ngFor="let status of typesService.capturaStatusType" [value]="status.value">
                    {{status.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Status.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 text-center">
              <video id="video" style="width: 100%;">Video stream não está disponível.</video>
              <div class="row" *ngIf="!detailMode">
                <div class="col-sm-6">
                  <button mat-flat-button color="primary" class="button-row" type="button" (click)="takePicture()"
                    *ngIf="streaming">
                    Capturar Foto
                  </button>
                  <button *ngIf="!streaming" mat-flat-button color="primary" class="button-row" type="button"
                    (click)="startupCamera()">
                    Iniciar Câmera
                  </button>
                </div>
                <div class="col-sm-6">
                  <button mat-flat-button color="primary" class="button-row" type="button" (click)="fileUpload.click()">
                    Upload Imagem
                  </button>
                  <input id="fileUpload" #fileUpload multiple class="form-control" type="file" accept=".jpg,.png"
                    (change)="upload(fileUpload.files)" style="display: none;" />
                </div>
              </div>
              <canvas id="canvas" style="display: none;">
              </canvas>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-3 text-center" style="margin-bottom: 10px;"
                  *ngFor="let imagem of imagens; let i = index;">
                  <div class="output">
                    <img [src]="imagem.imagemUrl" style="width: 100%;">
                  </div>
                  <!-- <strong class="text-center">{{imagem.procedimentoNome}}</strong> -->
                  <div class="align-center" *ngIf="!detailMode">
                    <button mat-icon-button type="button" *ngIf="i > 0" (click)="moverEsquerda(imagem.id, i)"
                      matTooltip="Move Imagem Para Esquerda">
                      <mat-icon>skip_previous</mat-icon>
                    </button>
                    <button mat-icon-button type="button" [disabled]="!imagem.imagemUrl" (click)="excluirImagem(imagem)"
                      matTooltip="Excluir Imagem">
                      <mat-icon>cancel</mat-icon>
                    </button>
                    <button mat-icon-button type="button" *ngIf="imagens.length !== i + 1"
                      (click)="moverDireita(imagem.id, i)" matTooltip="Move Imagem Para Direita">
                      <mat-icon>skip_next</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="footer" *ngIf="!detailMode">
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="finalizar()" color="primary"
          class="button-row">
          <span> Finalizar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
      <div class="footer" *ngIf="detailMode">
        <button mat-flat-button [disabled]="isBusy" (click)="capturaPrint.print(form.value.id)" color="primary"
          class="button-row">
          <span> Imprimir</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-captura-print #capturaPrint></app-captura-print>