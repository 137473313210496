import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('projects/ProjetoBaseAngular/app/account/account.module').then(x => x.AccountModule)
  },

  {
    path: 'confirma-agendamento',
    loadChildren: () => import('./confirma-agendamento/confirma-agendamento.module').then(x => x.ConfirmaAgendamentoModule)
  },

  {
    path: 'pesquisa',
    loadChildren: () => import('./pesquisa/pesquisa.module').then(x => x.PesquisaModule)
  },

  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(x => x.AdminModule),
  },

  {
    path: "**",
    redirectTo: ""
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
