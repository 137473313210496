import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ArquivoService } from 'projects/ProjetoBaseAngular/domain/services';
import { ArquivoModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-arquivo-modal',
  templateUrl: './arquivo-modal.component.html'
})
export class ArquivoModalComponent extends BaseCrudModalComponent<ArquivoModel> implements OnInit {
  @ViewChild('arquivoModal') modal: ModalDirective;
  @ViewChild("fileUpload") fileUpload: ElementRef;

  file: any = {};

  constructor(
    injector: Injector,
    private arquivoService: ArquivoService
  ) {
    super(injector, arquivoService);
  }

  protected initializeForm(arquivo: ArquivoModel) {
    this.form = this.formBuilder.group({
      id: arquivo.id,
      dataInclusao: [arquivo.dataInclusao, Validators.required],
      dataAlteracao: arquivo.dataAlteracao,
      nome: [arquivo.nome, [
        Validators.required,
        Validators.maxLength(50)]
      ],
      descricao: [arquivo.descricao, [
        Validators.maxLength(50)]
      ],
      url: [arquivo.url, Validators.maxLength(200)],
      file: [arquivo.file, Validators.required]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    const arquivo: ArquivoModel = Object.assign({}, new ArquivoModel(), this.form.value);

    const fileUpload = this.fileUpload.nativeElement;
    this.file = { data: fileUpload.files[0], inProgress: true, completed: false };
    const formData = new FormData();
    formData.append('file', this.file.data);

    this.arquivoService.upload(arquivo.id, arquivo.nome, arquivo.descricao, formData).subscribe(
      (url) => {
        this.file.inProgress = false;
        this.file.completed = true;

        arquivo.url = url;

        if (this.multiCreate) {
          this.form = null;
          let newModel = this.newModel();
          newModel.id = newModel.id || this.commonService.newGuid();
          newModel.dataInclusao = new Date();
          newModel = this.beforeShowCreate(newModel);
          this.initializeForm(newModel);
          this.onShown();
        } else {
          this.close();
        }
        this.modalSave.emit(arquivo.id);

        this.isBusy = false;
      },
      (errors) => {
        this.file.inProgress = false;
        this.errors = errors;
      });
  }

  async showCreate() {
    this.file = [];
    super.showCreate();
  }

  async showEdit(id: string) {
    this.file = [];
    super.showEdit(id);
  }
}
