import { Component, OnInit, ViewChild, Output, ElementRef, EventEmitter, Injector, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';

import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ConvenioService } from '../../../../parceiro/domain/services/convenio.service';
import { ProcedimentoConvenioModel } from '../../../domain/models';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ProcedimentoConvenioService } from '../../../domain/services/procedimento-convenio.service';

@Component({
  selector: 'app-procedimento-convenio-modal',
  templateUrl: './procedimento-convenio-modal.component.html'
})
export class ProcedimentoConvenioModalComponent extends BaseCrudModalComponent<ProcedimentoConvenioModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Input('procedimentoId') procedimentoId: string;

  listaConvenio: SelectDto[];

  constructor(
    injector: Injector,
    readonly service: ProcedimentoConvenioService,
    readonly convenioService: ConvenioService,
    readonly typesService: TypesService
  ) {
    super(injector,service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.convenioService.getSelectList().subscribe(x => this.listaConvenio = x);
  }

  protected initializeForm(model: ProcedimentoConvenioModel) {
    this.form = this.formBuilder.group({      
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      id: [model.id],
      procedimentoId: [model.procedimentoId],
      convenioId: [model.convenioId, Validators.required],
      codigoTabela: [model.codigoTabela, [Validators.required, Validators.maxLength(20)]],
      procedimentoTabela: [model.procedimentoTabela, [Validators.required, Validators.maxLength(20)]],
      valor: [model.valor, Validators.required],
      percLaudo: [model.percLaudo, Validators.required],
      percClinica: [model.percClinica, Validators.required]
    });
  };

  protected newModel(): ProcedimentoConvenioModel {
    let model = new ProcedimentoConvenioModel();
    model.id = this.commonService.newGuid();
    model.dataInclusao = new Date();    
    model.procedimentoId = this.procedimentoId;
    model.valor = 0;
    model.percLaudo = 0;
    model.percClinica = 0;

    return model;
  };

  autoSave() {
    this.save(true);
  }
}
