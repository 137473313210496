import { NgModule } from '@angular/core';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';

import { BaseMeuSiteModule } from 'projects/ProjetoBaseAngular/app/admin/meu-site/base-meu-site.module';
import { TemasComponent } from './temas/temas.component';
import { PreviewsModule } from './previews/previews.module';
import { AparenciaComponent } from './aparencia/aparencia.component';

@NgModule({
  imports: [
    SharedModule,
    BaseMeuSiteModule,
    PreviewsModule
  ],
  declarations: [
    TemasComponent,
    AparenciaComponent    
  ]
})
export class MeuSiteModule { }
