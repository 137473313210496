<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
<div class="card">
  <div class="header">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>{{titulo}}</h2>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Inicial</mat-label>
          <input matInput [(ngModel)]="dataInicial" type="date" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Final</mat-label>
          <input matInput [(ngModel)]="dataFinal" type="date" />
        </mat-form-field>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12 text-center">
        <button mat-flat-button color="primary" [disabled]="isBusy || !isAllowed()" (click)="print()">
          <span>Gerar Relatório</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="text-center font-weight-bold">{{titulo}}</h4>
          <div class="row">
            <div class="col-md-9">
              <strong>Período:</strong> {{dataInicial | date: 'dd/MM/yyyy'}} à {{dataFinal | date: 'dd/MM/yyyy'}}
            </div>
            <div class="col-md-3">
              <strong>Data de Emissão:</strong> {{dataAtual}}
            </div>
          </div>
          <br>
          <div>
            <table border="1" cellpadding="5" cellspacing="5" style="width: 100%;" *ngIf="listaDto.length > 0">
              <thead>
                <tr style="background-color: silver;">
                  <th class="text-center" [width]="100">Cadastro</th>
                  <th class="text-center" [width]="80">Código</th>
                  <th class="text-center">Nome Paciente</th>
                  <th class="text-center" [width]="120">CPF</th>
                  <th class="text-center" [width]="100">Nascto</th>
                  <th class="text-center" [width]="150">Celular</th>
                  <th class="text-center" [width]="150">Telefone</th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-item [ngForOf]="listaDto" let-isLast="last">
                  <tr>
                    <td>
                      {{item.dataInclusao | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                      {{item.codigo}}
                    </td>
                    <td>
                      {{item.pacienteNome}}
                    </td>
                    <td>
                      {{item.cpf | mask: '000.000.000-00'}}
                    </td>
                    <td>
                      {{item.dataNascimento | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                      {{item.celular}}
                    </td>
                    <td>
                      {{item.telefone}}
                    </td>
                  </tr>
                  <ng-container *ngIf="isLast && !contentPrinted">
                    {{ onContentPrinted() }}
                  </ng-container>
                </ng-template>
                <tr style="background-color: silver;">
                  <td colspan="6" class="text-center">
                    <strong>Total</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{qtdRegistros}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>