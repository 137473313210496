import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AgendaBloqueioComponent } from './agenda-bloqueio.component';
import { AgendaBloqueioModalComponent } from './agenda-bloqueio-modal/agenda-bloqueio-modal.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    AgendaBloqueioComponent,
    AgendaBloqueioModalComponent
  ],
  exports: [
    AgendaBloqueioComponent,
    AgendaBloqueioModalComponent
  ]
})
export class AgendaBloqueioModule { }
