<div bsModal #enqueteRespostasModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="enqueteRespostasModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">      
      <div class="card" *ngIf="opcoesRespostas">
        <div class="header">
          <h4 class="modal-title">Respostas</h4>
        </div>
        <div class="body">
          <div style="display: block;">
            <canvas baseChart 
              [data]="pieChartData" 
              [labels]="pieChartLabels" 
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [plugins]="pieChartPlugins"
              [legend]="pieChartLegend">
            </canvas>
          </div>          
        </div>
        <div class="footer">
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
