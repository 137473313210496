import { Component, OnInit, ViewChild, Injector, Input, Output, EventEmitter } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { PacienteArquivoModalComponent } from './paciente-arquivo-modal/paciente-arquivo-modal.component';
import { PacienteArquivoModel } from '../../domain/models';
import { PacienteArquivoService } from '../../domain/services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-paciente-arquivo',
  templateUrl: './paciente-arquivo.component.html'
})
export class PacienteArquivoComponent extends BaseCrudComponent<PacienteArquivoModel> implements OnInit {
  @ViewChild('modal') modal: PacienteArquivoModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  pacienteId: string;
  pacienteNome: string;

  selectColumns = [
    { data: 'nome' },
    { data: 'arquivoUrl' }
  ];

  constructor(
    injector: Injector,
    readonly service: PacienteArquivoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.pacienteId) {
      dataTablesParameters.searches.push(new ModelSearch('pacienteId', OptionSearchType.Equals, SearchType.Guid, this.pacienteId));
    }
    return super.beforeDtRequest(dataTablesParameters);
  }

  show(pacienteId, pacienteNome) {
    this.pacienteId = pacienteId;
    this.pacienteNome = pacienteNome;
    this.listModal.show();
    this.filter();
  }

  showLinq(item: PacienteArquivoModel) {
    window.open(item.arquivoUrl, "_blank");
  }

  create() {
    const model = new PacienteArquivoModel();
    model.dataInclusao = new Date();
    model.id = this.commonService.newGuid();
    model.pacienteId = this.pacienteId;
    super.create(model);
  }

  close() {
    this.listModal.hide();
    this.modalClose.emit();
  }
}
