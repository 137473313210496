<div bsModal #configModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" 
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Configuração de Formulários SUS</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2 text-center">
              <h5>Laudo TFD - Logo/Imagem</h5>
              <div class="form-group align-center">
                <div style="height: 150px; width: 100%; object-fit: contain;">
                  <img src="{{form.value.tfdImagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                    *ngIf="form.value.tfdImagemUrl">
                </div>
                <div class="align-center">
                  <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                    <mat-icon>add_photo_alternate</mat-icon>
                  </button>
                  <button mat-icon-button type="button" [disabled]="!form.value.tfdImagemUrl" (click)="deselectImage()"
                    matTooltip="Desvincular Imagem">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
                <p class="font-15 col-deep-blue text-center">
                  O tamanho ideal para imagem é no máximo: <strong>800x600</strong>
                </p>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Código CNES</mat-label>
                    <input matInput formControlName="codigoCnes" type="text" maxlength="15" #focus />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
            <span> Salvar</span>
          </button>
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Cancelar</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>