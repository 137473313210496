import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import * as moment from 'moment';
moment.locale('pt-BR');

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { AtendimentoFilterDto, AtendimentoModel } from '../../domain/models';
import { AgendamentoService, AtendimentoService } from '../../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ProfissionalRecursoService } from '../../domain/services/profissional-recurso.service';
import { AtendimentoStatusType } from '../../domain/types';
import { DocStatusType, OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-atendimento-espera-modal',
  templateUrl: './atendimento-espera-modal.component.html'
})
export class AtendimentoEsperaModalComponent extends BaseCrudComponent<AtendimentoModel> implements OnInit {  
  modal = null;
  @ViewChild('listModal') listModal: ModalDirective;  

  atendimentoFilterDto = new AtendimentoFilterDto();
  recursos: SelectDto[];  
  anotacoes: string;
  anotacoesOld: string;

  selectColumns = [
    { data: 'agendamento.data' },
    { data: 'agendamento.horario' },    
    { data: 'dataHoraEntrada' },    
    { data: 'profissionalRecurso.nome'},
    { data: 'agendamento.classificacao.nome' },
    { data: 'status' },
    { data: 'paciente.pessoa.nomeFantasia' },    
    { data: 'convenio.nome' },
    { data: 'agendamentoId', hidden: true } 
  ];

  constructor(
    injector: Injector,
    readonly service: AtendimentoService,
    readonly agendamentoService: AgendamentoService,
    readonly typesService: TypesService,    
    readonly profissionalRecursoService: ProfissionalRecursoService
  ) {
    super(injector, service);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {    
    dataTablesParameters = super.beforeDtRequest(dataTablesParameters);
    dataTablesParameters.searches.push(new ModelSearch('statusDocumento', OptionSearchType.GreaterThan, SearchType.Int, DocStatusType.Draft));        
    
    const data = this.atendimentoFilterDto.data;
    this.atendimentoFilterDto.data = this.fromJsonDate(new Date(data));
    dataTablesParameters.customParam = JSON.stringify(this.atendimentoFilterDto);
    this.atendimentoFilterDto.data = data;
    return dataTablesParameters;
  }  

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.order = [[2, 'asc']];
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  async undoAppliedDoc(nota: AtendimentoModel) {
    let desfazer = await this.commonService.mensagemConfirmacao("Atenção", "Defazer aplicação de nota de compra?", "question");
    if (desfazer) {
      this.service.undoAppliedDoc(nota.id)
        .subscribe(
          result => {
            if (result) {
              this.service.getById(nota.id).subscribe(model => {
                this.onResponse(model.id);
              });
            }
          },
          errors => this.commonService.mensagem("Erro!", errors.join('<br/>'), "error")
        );
    }
  }
  
  prevDayClick() {
    this.atendimentoFilterDto.data = moment(this.atendimentoFilterDto.data).add(-1, 'days').toDate().toISOString();
    this.filter();
  }

  nextDayClick() {
    this.atendimentoFilterDto.data = moment(this.atendimentoFilterDto.data).add(1, 'days').toDate().toISOString();
    this.filter();
  }

  todayClick() {
    this.atendimentoFilterDto.data = new Date().toISOString();
    this.filter();
  }

  get isToday(): boolean {
    return this.fromJsonDate(new Date(this.atendimentoFilterDto.data)) === this.fromJsonDate(new Date());
  }  

  changeRecurso(recursoIds) {    
    localStorage.setItem('app.recursoIds', recursoIds.join(','));        
    this.filter();    
  }

  saveAnotacoes() {
    this.agendamentoService.saveAnotacoes(this.anotacoes).subscribe(() => {
      this.anotacoesOld = this.anotacoes;
    });
  }  

  refreshAnotacoes() {
    this.agendamentoService.getAnotacoes().subscribe(x => {
      this.anotacoes = x;
      this.anotacoesOld = x;
    });
  }  

  show() {    
    this.atendimentoFilterDto.data = new Date().toISOString();
    this.atendimentoFilterDto.status = [AtendimentoStatusType.EsperaAtendimento, AtendimentoStatusType.NaoAtendido];
    this.atendimentoFilterDto.recursoIds = localStorage.getItem('app.recursoIds')?.split(',');
    if (this.atendimentoFilterDto.recursoIds) {
      this.changeRecurso(this.atendimentoFilterDto.recursoIds);
    }

    this.profissionalRecursoService.getSelectListUser().subscribe(x => {
      this.recursos = x;            
    });    
    this.refreshAnotacoes();    

    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();    
  }
}
