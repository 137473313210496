import { Injectable, Injector } from '@angular/core';
import * as moment from 'moment';
import { of } from 'rxjs';
import Swal, { SweetAlertIcon } from 'sweetalert2';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends BaseService {

  formatterNumberTwoDigits = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  constructor(
    injector: Injector
  ) {

    super(injector);
  }

  queryPostalCode(postalCode: string) {
    // Only number
    postalCode = postalCode === null ? '' : postalCode.replace(/\D/g, '');
    if (postalCode === '') {
      return of({});
    }

    // Expression to validate postal code.
    const validacep = /^[0-9]{8}$/;

    // Validate format
    if (!validacep.test(postalCode)) {
      return of({});
    }
    return this.httpClient.get(`//viacep.com.br/ws/${postalCode}/json`);
  }

  public newGuid(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0; // tslint:disable-line
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16); // tslint:disable-line
    });
    return uuid;
  }

  public getGuidSequencial(id: string) {
    let novoId = "00000000-0000-0000-0000-" + id.padStart(12, "0");
    return novoId;
  }

  public copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }

  public formatCpf(cpf: string) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
  }

  public formatCnpj(cnpj: string) {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
  }

  public fromJsonDate(date: Date): string {
    if (!date) {
      return null;
    }
    return new Date(date).toISOString().substring(0, 10);
  }

  public fromJsonDateTime(date: Date): string {
    if (!date) {
      return null;
    }
    return new Date(date).toISOString().substring(0, 16);
  }

  public formatDate(data: Date): string {    
    const date = new Date(data);
    const ano = date.getFullYear();
    let mes = date.getMonth() + 1; // Months start at 0!
    let dia = date.getDate();
    let diaStr = dia.toString();
    let mesStr = mes.toString();

    if (dia < 10) {
      diaStr = '0' + dia;
    }
    if (mes < 10){
      mesStr = '0' + mes;
    } 

    const dataFormatada = diaStr + '/' + mesStr + '/' + ano;
    return dataFormatada;
  }

  /**
   * Formatar string/texto e apresentação HTML
   * substituindo as quebras de linhas "\n" por <br>
   */
  formatPlaintextToHtml(texto: string): string {
    if (texto) {
      let linhas = texto.split('\n');
      let result = "<span>";
      linhas.forEach(item => {
        result += `${item}<br>`;
      });

      result += "</span>";

      return result;
    }

    return "";
  }

  /**
   * Resolver formatação de texto de whats para Html
   */
  public formatWhatsToHtml(mensagem: string): string {
    //let strTeste = 'hello I am *bold* text, and I am _italic_, I am ~line-through~ I am *bold again!*';

    if (!mensagem) {
      return "";
    }

    mensagem = mensagem.replace(/\n/g, "<br>");

    const htmlFormat = [
      { symbol: '*', tag: 'b' },
      { symbol: '_', tag: 'em' },
      { symbol: '~', tag: 'del' },
      { symbol: '`', tag: 'code' }
    ];

    htmlFormat.forEach(({ symbol, tag }) => {
      if (!mensagem) return;

      const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
      const matchArr = mensagem.match(regex);
      if (!matchArr) return;

      matchArr.forEach(char => {
        let formatted = char;
        for (let i = 0; i < 2; i++) {
          formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
        }
        mensagem = mensagem.replace(char, formatted);
      });
    });

    return mensagem;
  }

  /**
   * Converte número de celular em IdWhatsApp
   */
  celularToIdWhats(fone: string): string {
    let result: string = "";
    if (fone) {
      //(48) 99933-9721 ou 48999339721
      fone = fone.replace(/\s/g, "")
        .replace(/[(]/g, "")
        .replace(/[)]/g, "")
        .replace(/[-]/g, "");

      let strDdd = fone.substring(0, 2);
      fone = fone.substring(2, fone.length);

      let strNum1 = "";
      let strNum2 = "";

      if (fone.length < 8) {
        return result;
      }


      if (fone.length === 8) {
        //Caso 99339721
        strNum1 = fone.substring(0, 4);
        strNum2 = fone.substring(4, fone.length);
      } else {
        //Caso 999339721
        strNum1 = fone.substring(1, 5);
        strNum2 = fone.substring(5, fone.length);
      }

      result = `55${strDdd}${strNum1}${strNum2}`;
    }

    return result;
  }

  /**
   * Converte IdWhatsApp em número de celular.
   */
  idWhatsToCelular(idWhats: string): string {
    let result: string = "";
    if (idWhats) {
      //554899339721 -> remover 55
      idWhats = idWhats.substring(2, idWhats.length);

      //4899339721
      let strDdd = "";
      let strNum1 = "";
      let strNum2 = "";
      for (let i = 0; i < idWhats.length; i++) {
        if (i <= 1) {
          strDdd += idWhats[i];
        } else if (i <= 5) {
          strNum1 += idWhats[i];
        } else {
          strNum2 += idWhats[i];
        }
      }
      result = `(${strDdd}) 9${strNum1}-${strNum2}`;
    }

    return result;
  }

  public async mensagemConfirmacao(titulo: string, mensagem?: string, icone?: SweetAlertIcon): Promise<boolean> {
    let result = await Swal.fire({
      title: titulo,
      html: mensagem,
      icon: icone,
      focusCancel: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    });

    return result.value ? true : false;
  }

  public async mensagem(titulo: string, mensagem?: string, icone?: SweetAlertIcon): Promise<boolean> {
    await Swal.fire({
      title: titulo,
      html: mensagem,
      icon: icone,
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#3085d6',
    });
    return true;
  }

  /**
   * Valida a data no formato recebido do html type="date".
   * @param data Em string, formato padrão proveniente de Html (YYYY-MM-DD)
   * @returns Boolean (Válido = true, Inválido = false)
   */
  validarDataInput(data: string): boolean {
    let result = moment(data, 'YYYY-MM-DD', true).isValid();
    return result;
  }

  /**
   * Retorna a diferença em dias entre 2 datas
   * @param data1 
   * @param data2 
   * @returns dias
   */
  getDiasDiferenca(data1: Date, data2: Date): number {

    //Definir as datas com tempo = 00:00:00
    let dataAux1 = moment(data1).toDate();
    let dataAux2 = moment(data2).toDate();

    data1 = moment(`${dataAux1.getFullYear()}-${dataAux1.getMonth() + 1}-${dataAux1.getDate()}`, "YYYY-MM-DD").toDate();
    data2 = moment(`${dataAux2.getFullYear()}-${dataAux2.getMonth() + 1}-${dataAux2.getDate()}`, "YYYY-MM-DD").toDate();
    //

    //Pegar o momento (tempo)
    let momento1 = moment(data1.toDateString());
    let momento2 = moment(data2.toDateString());

    //Diferença em tempo
    let difTempo = moment.duration(momento2.diff(momento1));
    let dias = Math.trunc(difTempo.asDays());

    return dias;
  }

  /**
   * Retorna a diferença em horas entre 2 datas
   * @param data1 
   * @param data2 
   * @returns horas
   */
  getHorasDiferenca(data1: Date, data2: Date): number {
    //Garantir que sejam datas válidas
    data1 = moment(data1).toDate();
    data2 = moment(data2).toDate();

    var ms = moment(data2, "DD/MM/YYYY HH:mm:ss").diff(moment(data1, "DD/MM/YYYY HH:mm:ss"));
    var dias = moment.duration(ms);

    return dias.asHours();
  }

  getIdade(dataNascto: Date): string {
    dataNascto = moment(dataNascto).toDate();

    var now = moment(new Date(), 'YYYY-MM-DD');
    var birthday = moment(dataNascto, 'YYYY-MM-DD');
    var age = moment.duration(now.diff(birthday));

    const years = age.years();
    const months = age.months();
    const days = age.days() + 1;

    return `${years} anos, ${months} ${months > 1 ? 'meses' : 'mês'}, ${days} ${days > 1 ? 'dias' : 'dia'}`;
  }

  getIdadeAnos(dataNascto: Date): string {
    dataNascto = moment(dataNascto).toDate();

    var now = moment(new Date(), 'YYYY-MM-DD');
    var birthday = moment(dataNascto, 'YYYY-MM-DD');
    var age = moment.duration(now.diff(birthday));

    const years = age.years();
    return `${years}`;
  }

  hexToRGB(hex: string, alpha: string): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }

  // Utilizado para retornar o elemento e poder utilizar operações no mesmo ex: .focus()
  getHTMLElement(controlName: string): HTMLElement {
    let elemento: HTMLElement = document.querySelector(`input[formControlName="${controlName}"]`);
    return elemento
  }

  isNumeric(str) {
    var er = /^[0-9]+$/;
    return (er.test(str));
  }

  removeSpecial(text) {
    if (text) {
      var lower = text.toLowerCase();
      var upper = text.toUpperCase();
      var result = '';
      for (var i = 0; i < lower.length; ++i) {
        if (this.isNumeric(text[i]) || (lower[i] != upper[i]) || (lower[i].trim() === '')) {
          result += text[i];
        }
      }
      return result;
    }
    return '';
  }

  spinnerOpen(titulo: string, mensagem?: string, icone?: SweetAlertIcon) {
    Swal.fire({
      title: titulo,
      html: mensagem,
      icon: icone,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  spinnerClose() {
    Swal.close();
  }

  getDayOfWeek(date: Date) {
    let day: string = moment(date).format('dddd').replace('-feira', '');
    return day[0].toUpperCase() + day.substr(1);
  }

  getDayOfWeekFull(date: Date) {
    let day: string = moment(date).format('dddd');
    return day[0].toUpperCase() + day.substr(1);
  }

  /**
   * Retorna um objeto com uma lista agrupada pela propriedade fornecida (Key)
   * @param data é uma lista de objetos (array)
   * @param key é a propriedade para o group by
   * @returns 
   */
  groupBy = (data, key) => {

    return data.reduce((storage, item) => {
      // pegar a 1° instancia da chave (key) para agrupar
      let group = item[key];

      // setar `storage` para esta instancia do group ou inicializar ele
      storage[group] = storage[group] || [];

      // adicionar este item para o group usando `storage`
      storage[group].push(item);

      // retorna o storage atualizado para a função reduce, através de cada loop
      return storage;
    }, {}); // {} inicialização do valorde storage
  };

  /**
   * Ordenar uma lista de objetos através de uma propriedade específica.
   * @param data lista de objetos inicial
   * @param key propriedade utilizada para comparação / ordenação
   * @param ordemCrescente define a forma de ordenação crescente (padrão) ou decrescente
   * @returns lista ordenada
   */
  orderBy = (data, key, ordemCrescente = true) => {
    data.sort((a, b) => {
      if (ordemCrescente) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        // a === b
        return 0;
      } else {
        if (a[key] < b[key]) {
          return 1;
        }
        if (a[key] > b[key]) {
          return -1;
        }
        // a === b
        return 0;
      }
    });
    return data;
  }

  /**
   * Retorna uma string capitalizada
   */
  capitalize(str: string): string {
    if (str.length > 0) {
      return str[0].toUpperCase() + str.slice(1);
    }

    return str;
  }

  /**
   * Capitaliza nomes
   */
  capitalizeNome(str: string): string {
    let arr = str.split(' ');
    let result = "";

    for (let i = 0; i < arr.length; i++) {
      if (result === "") {
        result = this.capitalize(arr[i]);
      } else {
        result = `${result} ${this.capitalize(arr[i])}`;
      }
    }

    return result;
  }

  /**
   * Salvar arquivos
   * @param fileName (ex. Downloads/nomearquivo.txt)
   * @param fileType (ex. txt)
   * @param data (conteúdo do arquivo)
   */
  saveFile(fileName: string, fileType: string, data: string) {
    const file = new Blob([data], { type: fileType });

    // if (window.navigator.msSaveOrOpenBlob) {
    //     window.navigator.msSaveOrOpenBlob(file, filename);
    //     return
    // }

    const a = document.createElement("a");
    const url = URL.createObjectURL(file);

    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);

    a.click();

    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
