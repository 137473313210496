<div bsModal #enqueteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="enqueteModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2> Enquete </h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Status Publicação</mat-label>
                <mat-select formControlName="statusPublicacao">
                  <mat-option *ngFor="let status of statusPublicacoes" [value]="status.value">
                    {{status.text}}</mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data inicial</mat-label>
                <input matInput formControlName="dataInicial" type="date" #focus/>
                <mat-error>Informe uma Data Inicial válida.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data Final</mat-label>
                <input matInput formControlName="dataFinal" type="date" />
                <mat-error>Informe uma Data Final válida.</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row">

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Titulo</mat-label>
                <input matInput formControlName="titulo" type="text"/>
                <mat-hint align="end">Máx 100 caracteres</mat-hint>
                <mat-error>Informe um Titulo válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Descrição Interna</mat-label>
                <input matInput formControlName="descricaoInterna" type="text" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe um Descrição Interna válida.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Descrição Site</mat-label>
                <input matInput formControlName="descricaoSite" type="text" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe um Descrição Site válida.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <h4 class="card-inside-title">Opções da Enquete</h4>
            <app-enquete-opcao #enqueteOpcao (itemUpdate)="onItemResponse($event)" [opcoes]="form.value.opcoes">
          </app-enquete-opcao>

        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
