import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { PacienteModel, PacienteProntuarioModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { AtendimentoFilterDto } from '../../domain/models';
import { AtendimentoService } from '../../domain/services';
import { PacienteProntuarioService } from '../../../paciente/domain/services/paciente-prontuario.service';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-atendimento-historico-modal',
  templateUrl: './atendimento-historico-modal.component.html'
})
export class AtendimentoHistoricoModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('relatorio') relatorio: ElementRef;

  listaAtendimento: any[] = [];
  listaProntuario: PacienteProntuarioModel[] = [];
  listaApresentacao: any[] = [];

  paciente: PacienteModel;
  dtOptionsAtendimento: DataTables.Settings = null;

  constructor(
    readonly atendimentoService: AtendimentoService,
    readonly pacienteService: PacienteService,
    readonly pacienteProntuarioService: PacienteProntuarioService,
    readonly typesService: TypesService,
    readonly printService: PrintService,
    injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async show(pacienteId: string) {
    
    this.listaApresentacao = [];
    
    const selectColumns = [
      { data: 'dataHoraEntrada' },
      { data: 'profissionalRecurso.nome' },
      { data: 'anamnese' },
      { data: 'diagnostico' },
      { data: 'status' },
      { data: 'agendamentoId' },
      { data: 'codigoDocMovto' }
    ];

    const atendimentoFilterDto = new AtendimentoFilterDto();
    atendimentoFilterDto.naoFiltrarData = true;
    atendimentoFilterDto.naoFiltrarRecurso = true;
    atendimentoFilterDto.pacienteId = pacienteId;
    const queryDto = {
      selectColumns,
      customParam: JSON.stringify(atendimentoFilterDto)
    };
    
    const dataResponse = await this.atendimentoService.getResponse(queryDto).toPromise();
    debugger
    this.listaAtendimento = dataResponse.data;

    this.paciente = await this.pacienteService.getById(pacienteId).toPromise();

    this.listaProntuario = await this.pacienteProntuarioService.getByPacienteId(pacienteId).toPromise();

    this.listaAtendimento.forEach(item => {
      const model = new Object();
      model["dataHora"] = item.dataHoraEntrada;
      model["profissionalSaudeNome"] = item.profissionalRecursoNome;
      model["txtProntuario"] = item.anamnese ? `<strong>Anamnese:</strong> <div>${item.anamnese}</div>` : "";
      model["txtProntuario"] += item.diagnostico ? `<strong>Diagnóstico:</strong> <div> ${item.diagnostico}</div>` : "";
      
      if (model["txtProntuario"].length > 0){
        this.listaApresentacao.push(model);
      }
    });

    this.listaProntuario.forEach(item => {
      const model = new Object();
      model["dataHora"] = item.dataInclusao;
      model["profissionalSaudeNome"] = item.profissionalSaudeNome;
      model["txtProntuario"] = item.descricao;
      
      this.listaApresentacao.push(model);
    });
    
    this.listaApresentacao = this.commonService.orderBy(this.listaApresentacao, "dataHora", false);

    this.modal.show();
  }

  getIdadePessoa(data: Date): string {
    if (!data) {
      return '';
    }
    return this.commonService.getIdade(data);
  }

  close() {
    this.modal.hide();
  }

  async print() {
    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Relatório de Atendimento', content);
    }, 500);
  }
}
