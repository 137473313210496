export enum ContatoStatusType {
  Pendente = 0,
  Encaminhado = 1,
  Resolvido = 2
}

export enum ContatoPreAgendamentoStatusType {
  Pendente = 0,
  EmAtendimento = 1,
  Cacelado = 2,
  Concluido = 3
}

export enum TurnoType {
  Matutino = 0,
  Vespertino = 1,
  Noturno = 2,
}


