<div #relatorio class="visible-print">
  <table class="report-container" *ngIf="laudo">
    <thead class="report-header">
      <tr>
        <th>
          <div class="mb-2" *ngIf="imprimirCabRod">
            <app-report-header></app-report-header>
          </div>
        </th>
      </tr>
    </thead>
    <tfoot style="display:table-footer-group;">
      <tr>
        <td>
          <div class="container">
            <div style="white-space: pre-line; font-family: arial; font-size:20px" class="mt-5 pt-5 mb-5 text-center">
              <p class="p-0 m-0">{{laudo.rodape}}</p>
            </div>
            <!-- <div *ngIf="imprimirCabRod">
              <app-report-footer></app-report-footer>
            </div> -->
          </div>
        </td>
      </tr>
    </tfoot>
    <tbody class="report-content">
      <tr>
        <td>
          <div class="mt-5" style="white-space: pre-line; font-family: arial; font-size:20px">
            {{laudo.cabecalho}}
          </div>
          <!-- <p>Data: {{dataAtual}}</p> -->
          <div class="mt-5" [innerHTML]="sanitizer.bypassSecurityTrustHtml(laudo.texto)"></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>