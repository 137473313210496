import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared.module';

import { GeralModule } from './geral/geral.module';
import { TenantsModule } from './tenants/tenants.module';
import { GaleriaModule } from './galeria/galeria.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { ControleAcessoModule } from './controle-acesso/controleAcesso.module';
import { NotificacaoModule } from './notificacao/notificacao.module';
import { BaseFinanceiroModule } from './financeiro/base-financeiro.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    GeralModule,    
    TenantsModule,
    GaleriaModule,
    UsuariosModule,
    ControleAcessoModule,
    BaseFinanceiroModule,
    NotificacaoModule    
  ]
})
export class BaseAdminModule { }
