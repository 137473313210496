<div class="card" *ngIf="form">
    <form method="post" (ngSubmit)="save()" [formGroup]="form">
        <div class="body">
            <h4 class="card-inside-title">Logotipo do site</h4>
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <p class="font-15 col-deep-orange text-center">
                        O tamanho ideal da imagem é <strong>240 x 120 pixels</strong>
                    </p>
                    <div class="form-group align-center">
                        <div style="height: 150px; width: 100%; object-fit: contain;">
                            <img src="{{form.value.imgLogotipoUrl}}"
                                style="height: 150px; width: 100%; object-fit: contain;"
                                *ngIf="form.value.imgLogotipoUrl">
                        </div>
                        <div class="align-center">
                            <button mat-icon-button type="button" (click)="showSelectImage('Logotipo')"
                                matTooltip="Selecionar Imagem">
                                <mat-icon>add_photo_alternate</mat-icon>
                            </button>
                            <button mat-icon-button type="button" [disabled]="!form.value.imgLogotipoUrl"
                                (click)="deselectImage('Logotipo')" matTooltip="Desvincular Imagem">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <h4 class="card-inside-title">Ícone do site para os navegadores (Favicon)</h4>
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <p class="font-15 col-deep-orange text-center">
                        O tamanho ideal da imagem é <strong>144 x 144 pixels</strong>
                    </p>
                    <div class="form-group align-center">
                        <div style="height: 150px; width: 100%; object-fit: contain;">
                            <img src="{{form.value.imgFavIconUrl}}"
                                style="height: 150px; width: 100%; object-fit: contain;"
                                *ngIf="form.value.imgFavIconUrl">
                        </div>
                        <div class="align-center">
                            <button mat-icon-button type="button" (click)="showSelectImage('FavIcon')"
                                matTooltip="Selecionar Imagem">
                                <mat-icon>add_photo_alternate</mat-icon>
                            </button>
                            <button mat-icon-button type="button" [disabled]="!form.value.imgFavIconUrl"
                                (click)="deselectImage('FavIcon')" matTooltip="Desvincular Imagem">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>            

            <h4 class="card-inside-title">Imagem para compartilhamento do site</h4>
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <p class="font-15 col-deep-orange text-center">
                        O tamanho ideal da imagem é <strong>512 x 512 pixels</strong>
                    </p>
                    <div class="form-group align-center">
                        <div style="height: 150px; width: 100%; object-fit: contain;">
                            <img src="{{form.value.imgSharingUrl}}"
                                style="height: 150px; width: 100%; object-fit: contain;"
                                *ngIf="form.value.imgSharingUrl">
                        </div>
                        <div class="align-center">
                            <button mat-icon-button type="button" (click)="showSelectImage('Sharing')"
                                matTooltip="Selecionar Imagem">
                                <mat-icon>add_photo_alternate</mat-icon>
                            </button>
                            <button mat-icon-button type="button" [disabled]="!form.value.imgSharingUrl"
                                (click)="deselectImage('Sharing')" matTooltip="Desvincular Imagem">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <button mat-flat-button type="submit" color="primary" class="button-row">
                <span>Salvar Alterações</span>
            </button>
        </div>
    </form>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>