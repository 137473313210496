import { Injectable, Injector } from '@angular/core';
import { ResponsePage } from 'projects/ProjetoBaseAngular/domain/models/response-page';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RevistaModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RevistaService extends BaseCrudService<RevistaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'revista';
  }

  get(searchTerm = '', page = '1', resultsPerPage = '10'): Observable<ResponsePage<RevistaModel>> {
    return this
      .httpClient
      .get<ResponsePage<RevistaModel>>(`${this.getApiUrl()}${this.getController()}`, {
        headers: super.getHeaderJson().headers,
        params: { searchTerm, page, resultsPerPage }
      }).pipe(
        map(this.extractData)
      );
  }

  getUltima(): Observable<RevistaModel> {
    return this
      .httpClient
      .get<RevistaModel>(`${this.getApiUrl()}${this.getController()}/GetUltima`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }
}
