<div mat-dialog-title>
  <button type="button" class="close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
  <h4>Comparativo de Resultados</h4>
</div>
<mat-dialog-content>
  <mat-tab-group *ngIf="resultado">
    <mat-tab label="Resultados">
      <ng-template matTabContent>
        <br>
        <table class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Exames</th>
              <th class="text-center" *ngFor="let data of resultado.datas">{{data}}</th>
            </tr>
          </thead>
          <tbody *ngIf="resultado?.itens.length != 0">
            <tr *ngFor="let procedimentoId of resultado.procedimentoIds">
              <td>{{getProcedimentoNome(procedimentoId)}}</td>
              <td *ngFor="let data of resultado.datas">{{getResultado(procedimentoId, data)}}</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="Gráfico">
      <ng-template matTabContent>
        <br>
        <mat-form-field>
          <mat-label>Tipo Gráfico</mat-label>
          <mat-select [(ngModel)]="chartType" required (selectionChange)="popularChart()">
            <mat-option *ngFor="let chartType of chartTypes" [value]="chartType.value">
              {{chartType.text}}
            </mat-option>
          </mat-select>
          <mat-error>Selecione um Item do Grupo de Questão.</mat-error>
        </mat-form-field>
        <canvas #canvas height="200" baseChart [data]="chartData" [datasets]="chartDatasets" [labels]="chartLabels"
          [colors]="chartColors" [chartType]="chartType" [options]="chartOptions" [plugins]="chartPlugins"
          [legend]="chartLegend">
        </canvas>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button type="button" mat-dialog-close class="button-row">
    <span> Fechar</span>
  </button>
</mat-dialog-actions>