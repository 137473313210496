import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { CargoModel, VagaModel } from '../../domain/models';
import { CargoService, VagaService } from '../../domain/services';

@Component({
  selector: 'app-vaga-modal',
  templateUrl: './vaga-modal.component.html'
})
export class VagaModalComponent extends BaseCrudModalComponent<VagaModel> implements OnInit {
  @ViewChild('vagaModal') modal: ModalDirective;

  cargos: CargoModel[];

  constructor(
    injector: Injector,
    vagaService: VagaService,
    private cargoService: CargoService
  ) {
    super(injector, vagaService);
  }

  protected initializeForm(vaga: VagaModel) {
    this.form = this.formBuilder.group({
      id: vaga.id,
      dataInclusao: [vaga.dataInclusao, Validators.required],
      dataAlteracao: vaga.dataAlteracao,
      titulo: [vaga.titulo, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)]
      ],
      descricao: [vaga.descricao, [
        Validators.minLength(2),
        Validators.maxLength(500)
      ]],
      cargoId: [vaga.cargoId],
      registroAtivo: [vaga.registroAtivo],
      html: [vaga.html]
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.cargoService.getAsync().subscribe(cargos => this.cargos = cargos);
  }

  beforeShowCreate(model: VagaModel): VagaModel {
    model.registroAtivo = false;
    return super.beforeShowCreate(model);
  }
}
