<div bsModal #imagemSelectModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="imagemSelectModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content" *ngIf="imagemSelectModal.isShown">
      <div class="card">
        <div class="header">
          <h4 class="modal-title">Selecione uma imagem</h4>
          <ul class="header-dropdown m-r--5">
            <li>
              <button mat-flat-button color="primary" type="button" (click)="create()">Adicionar</button>
            </li>
          </ul>
        </div>
        <div class="modal-body">
          <div class="row clearfix">
            <div class="col-md-12">
              <app-status-armazenamento></app-status-armazenamento>
            </div>
            <div class="col-lg-3 col-md-4 col-xs-6" *ngFor="let imagem of imagens">
              <div class="card">
                <a class="image-galery" (click)="select(imagem)">
                  <img class="img-responsive bg-grey" src="{{imagem.url}}"
                    style="width: 100%; height: 200px !important; object-fit: contain;" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-imagem-modal #imagemModal (modalClose)="onResponse($event)"></app-imagem-modal>
