import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ProcedimentoPerguntaService } from '../../domain/services';
import { ProcedimentoPerguntaModel } from '../../domain/models';

@Component({
  selector: 'app-procedimento-pergunta-modal',
  templateUrl: './procedimento-pergunta-modal.component.html'
})
export class ProcedimentoPerguntaModalComponent extends BaseCrudModalComponent<ProcedimentoPerguntaModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: ProcedimentoPerguntaService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: ProcedimentoPerguntaModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      
      procedimentoId: 
      [model.procedimentoId, [        
                
      ]],
      codigo: 
      [model.codigo, [        
                
      ]],
      pergunta: 
      [model.pergunta, [        
        Validators.required, Validators.maxLength(500)        
      ]],
      resposta: 
      [model.resposta, [        
        Validators.maxLength(500)        
      ]],
      unidadePergunta: 
      [model.unidadePergunta, [        
        Validators.maxLength(50)        
      ]],
      unidadeResposta: 
      [model.unidadeResposta, [        
        Validators.maxLength(50)        
      ]],
      formula: 
      [model.formula, [        
        Validators.maxLength(100)        
      ]],
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
