<!-- <div class="overlay"></div> -->

<top-bar></top-bar>

<section (window:resize)="onResize()">
    <aside id="leftsidebar" class="sidebar">
        <sidebar-user-area></sidebar-user-area>
        <sidebar-nav></sidebar-nav>
        <!-- <sidebar-footer></sidebar-footer> -->
    </aside>

    <right-sidebar></right-sidebar>
</section>

<section class="content">    
    <router-outlet></router-outlet>    
</section>
