<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
<div class="card">
  <div class="header">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>{{titulo}}</h2>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="statusChat">
            <mat-option [value]="-1">Todos</mat-option>
            <mat-option *ngFor="let item of typesService.whatsChatStatusType" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Inicial</mat-label>
          <input matInput [(ngModel)]="dataInicial" type="date" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Final</mat-label>
          <input matInput [(ngModel)]="dataFinal" type="date" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <button mat-flat-button color="primary" [disabled]="isBusy || !isAllowed()" (click)="print()">
          <span>Gerar Relatório</span>
        </button>
      </div>
    </div>
    <hr>
  </div>
  <br>
  <div *ngIf="listaDto.length > 0">
    <div class="row">
      <br />
      <div class="col-md-6" *ngIf="showGraficoChatsByAtendente">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12">
                <h4 style="text-align: center;">Chats por Atendente | Total {{qtdRegistros}}</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <canvas height="120" baseChart [datasets]="chartChatsByAtendenteData"
                    [labels]="chartChatsByAtendenteLabels" [chartType]="chartChatsByAtendenteType"
                    [options]="chartChatsByAtendenteOptions" [plugins]="chartChatsByAtendentePlugins"
                    [legend]="chartChatsByAtendenteLegend">
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <table border="1" cellpadding="3" cellspacing="3" style="width: 100%;"
                *ngIf="listaApresentacao.length > 0">
                <thead>
                  <tr style="background-color: silver;">
                    <th class="text-center">Nome Atendente</th>
                    <th class="text-center" [width]="150">Qtd. Atendimentos</th>
                    <th class="text-center" [width]="120">%</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template ngFor let-item [ngForOf]="listaApresentacao" let-isLast="last">
                    <tr>
                      <td class="text-center">
                        {{item.nome}}
                      </td>
                      <td class="text-right">
                        {{item.qtd}}
                      </td>
                      <td class="text-right">
                        {{item.percentual}}%
                      </td>
                    </tr>
                  </ng-template>
                  <tr style="background-color: silver;">
                    <td colspan="1" class="text-center">
                      <strong>Total</strong>
                    </td>
                    <td class="text-right">
                      <strong>{{qtdRegistros}}</strong>
                    </td>
                    <td class="text-right">
                      <strong>100%</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <br />
      <div class="col-md-12" *ngIf="showGraficoChatByFluxo">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12">
                <h4 style="text-align: center;">Chats por Tipo de Fluxo</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <canvas height="60" baseChart [datasets]="chartChatByFluxoData"
                  [labels]="chartChatByFluxoLabels" [chartType]="chartChatByFluxoType"
                  [options]="chartChatByFluxoOptions" [plugins]="chartChatByFluxoPlugins"
                  [legend]="chartChatByFluxoLegend">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <br />
      <div class="col-md-12" *ngIf="showGraficoChatByTipoAtendimento">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12">
                <h4 style="text-align: center;">Chats por Tipo de Atendimento</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <canvas height="60" baseChart [datasets]="chartChatByTipoAtendimentoData"
                  [labels]="chartChatByTipoAtendimentoLabels" [chartType]="chartChatByTipoAtendimentoType"
                  [options]="chartChatByTipoAtendimentoOptions" [plugins]="chartChatByTipoAtendimentoPlugins"
                  [legend]="chartChatByTipoAtendimentoLegend">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</div>