import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AtendimentoProcedimentoService, ProcedimentoService } from '../../../domain/services';
import { AtendimentoProcedimentoModel, ProcedimentoModel } from '../../../domain/models';
import { ConvenioService } from '../../../../parceiro/domain/services/convenio.service';
import { ConvenioModel } from '../../../../parceiro/domain/models';
import { ProcedimentoConvenioService } from '../../../domain/services/procedimento-convenio.service';
import { ProcedimentoConvenioProfissionalService } from '../../../domain/services/procedimento-convenio-profissional.service';

@Component({
  selector: 'app-atendimento-procedimento-modal',
  templateUrl: './atendimento-procedimento-modal.component.html'
})
export class AtendimentoProcedimentoModalComponent extends BaseCrudModalComponent<AtendimentoProcedimentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  @Input('atendimentoId') atendimentoId: string;
  @Input('convenioId') convenioId: string;
  
  profissionalId: string;

  procedimento: ProcedimentoModel;
  convenio: ConvenioModel;

  constructor(
    injector: Injector,
    service: AtendimentoProcedimentoService,
    readonly procedimentoService: ProcedimentoService,    
    readonly convenioService: ConvenioService,
    readonly procConvenioService: ProcedimentoConvenioService,
    readonly procConvProfissionalService: ProcedimentoConvenioProfissionalService,
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: AtendimentoProcedimentoModel) {
    this.convenioService.getById(this.convenioId).subscribe(x => this.convenio = x);
    
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      atendimentoId: [this.atendimentoId],
      procedimentoId: [model.procedimentoId, [

      ]],
      procedimento: [model.procedimento, [

      ]],
      vlrUnitario: [model.vlrUnitario, [
        Validators.required
      ]],
      vlrAcrescimo: [model.vlrAcrescimo, [
        Validators.required
      ]],
      vlrDesconto: [model.vlrDesconto, [
        Validators.required
      ]],
      vlrTotal: [model.vlrTotal, [
        Validators.required
      ]],
      percClinica: [model.percClinica],
      numGuia: [model.numGuia, [Validators.maxLength(20)]],
      senhaGuia: [model.senhaGuia, [Validators.maxLength(15)]]
    });

    this.procedimento = null;
    if (model.procedimentoId) {
      this.procedimentoService.getById(model.procedimentoId).subscribe(x => {
        this.procedimento = x;
      });
    }    
  }

  protected newModel(): AtendimentoProcedimentoModel {
    const model = super.newModel();
    model.vlrUnitario = 0;
    model.vlrAcrescimo = 0;
    model.vlrDesconto = 0;
    model.vlrTotal = 0;
    model.percClinica = 0;

    return model;
  }

  onProcedimentoResponse(procedimentoId: string) {    
    if (procedimentoId === this.form.value.procedimentoId) {
      return;
    }

    this.form.patchValue({ procedimentoId });
    if (!procedimentoId) {
      this.procedimento = new ProcedimentoModel();
      this.procedimento.nome = '';
    } else {
      this.procedimentoService.getById(procedimentoId).subscribe(x => {
        this.procedimento = x;
        
        this.popularProcedimento(procedimentoId);
      });      
    }
  }

  async changeProcedimentoCodigo(codigo: number) {
    if (codigo === this.procedimento?.codigo) {
      return;
    }
    
    this.procedimento = new ProcedimentoModel();
    this.procedimento.codigo = null;
    this.procedimento.nome = '';

    if (codigo) {
      let proc = await this.procedimentoService.getByCodigo(codigo).toPromise();
      if (!proc?.codigo) {
          this.commonService.mensagem('Procedimento não encontrado!', '', 'warning');
          return;
      }
      this.procedimento = proc;
      this.form.patchValue({
        procedimentoId: this.procedimento.id,
      });
      this.popularProcedimento(this.procedimento.id);
    }
  }

  async popularProcedimento(procedimentoId: string) {    
    let vlrUnitario = 0;
    let percClinica = 0;

    const tabelaPadrao = await this.procConvenioService
                              .getByProcedimentoIdAndConvenioId(procedimentoId,      
                                this.convenioId).toPromise();
    if (tabelaPadrao) {
      vlrUnitario = tabelaPadrao.valor;
      percClinica = tabelaPadrao.percClinica;

      const tabelaEspecial = await this.procConvProfissionalService
                                    .getByProcConvIdAndProfissionalId(tabelaPadrao.id, 
                                      this.profissionalId).toPromise();
      if (tabelaEspecial?.percClinica) {
        percClinica = tabelaEspecial.percClinica;
      }
    }
    
    this.form.patchValue({
      vlrUnitario: vlrUnitario,
      percClinica: percClinica    
    });
    
    this.atualizarTotal();
  }

  atualizarTotal() {
    let model: AtendimentoProcedimentoModel = Object.assign({}, this.form.value);
    this.form.patchValue({
      vlrTotal: model.vlrUnitario + model.vlrAcrescimo - model.vlrDesconto
    });
  }
}
