<div #relatorio class="visible-print">
  <table class="report-container" *ngIf="atendimento">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
          <h4 class="text-center font-weight-bold">PRONTUÁRIO ELETRÔNICO </h4>
          <!-- <p style="margin: 0; padding: 0;" *ngIf="!atendimento.agendamentoId">Complemento</p> -->
          <p style="margin: 0; padding: 0;">
            <strong>Nome Paciente:</strong><span class="mr-5"> {{paciente?.pessoa?.nomeFantasia}}</span>
            <strong>Código Prontuário:</strong> {{atendimento.codigoDocMovto}}
          </p>
          <p style="margin: 0; padding: 0;">
            <strong>Data Nascto:</strong><span class="mr-5"> {{paciente?.pessoa?.dataNascimento | date:
              'dd/MM/yyyy'}}</span>
            <strong>CPF:</strong> {{paciente?.pessoa?.cnpjCpf}}
          </p>
          <p style="margin: 0; padding: 0;">
            <strong>Data Atendimento:</strong> {{atendimento.dataHoraEntrada | date: 'dd/MM/yyyy HH:mm'}}
          </p>
        </th>
      </tr>
    </thead>
    <tfoot class="report-footer">
      <tr>
        <td>
          <div class="container">
            <footer>
              <app-report-footer></app-report-footer>
            </footer>
          </div>
        </td>
      </tr>
    </tfoot>
    <tbody class="report-content">
      <tr>
        <td>
          <div>
            <div style="margin-top: 2px;" *ngIf="atendimento.anamnese">
              <strong>Anamnese:</strong>
              <div [innerHTML]="atendimento.anamnese"></div>
            </div>
            <div style="margin-top: 2px;" *ngIf="atendimento.diagnostico">
              <strong>Diagnóstico:</strong>
              <div [innerHTML]="atendimento.diagnostico"></div>
            </div>
            <hr style="border-top: 1px solid;" />
          </div>
          <div class="text-center mt-5 pt-5">
            <p class="p-0 m-0">_______________________________________________________</p>
            <p class="p-0 m-0">Carimbo e assinatura do médico</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>