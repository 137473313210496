import { Component, OnInit, ViewChild, Injector, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AgendaBloqueioModalComponent } from './agenda-bloqueio-modal/agenda-bloqueio-modal.component';
import { AgendaBloqueioModel } from '../domain/models';
import { AgendaBloqueioService } from '../domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-agenda-bloqueio',
  templateUrl: './agenda-bloqueio.component.html'
})
export class AgendaBloqueioComponent extends BaseCrudComponent<AgendaBloqueioModel> implements OnInit {
  @ViewChild('modal') modal: AgendaBloqueioModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  recursoId: string;
  recursoNome: string;

  listarBloqAnteriores: boolean;

  selectColumns = [    
    { data: 'dataInicial' },
    { data: 'dataFinal' },
    { data: 'horarioInicial' },
    { data: 'horarioFinal' },
    { data: 'observacao' },
  ];

  constructor(
    injector: Injector,
    readonly service: AgendaBloqueioService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.recursoId) {
      dataTablesParameters.searches.push(new ModelSearch('profissionalRecursoId', OptionSearchType.Equals, SearchType.Guid, this.recursoId));
    }

    if (!this.listarBloqAnteriores) {      
      dataTablesParameters.searches.push(new ModelSearch('dataInicial', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime, new Date().toDateString()));
    }

    return super.beforeDtRequest(dataTablesParameters);
  }

  onResponse(id: string) {
    super.onResponse(id);
    this.filter();
  }

  show(recursoId, recursoNome) {
    this.recursoId = recursoId;
    this.recursoNome = recursoNome;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();
    this.modalClose.emit();
  }

  create() {
    const model = new AgendaBloqueioModel();
    model.dataInclusao = new Date();
    model.id = this.commonService.newGuid();
    model.profissionalRecursoId = this.recursoId;
    super.create(model);
  }

  edit(model: AgendaBloqueioModel) {    
    this.modal.showEditByRecursoId(model.id, this.recursoId);
  }  
}
