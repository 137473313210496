import { Injectable, Injector } from '@angular/core';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EnqueteModel, EnqueteOpcaoModel } from '../models';


@Injectable({
  providedIn: 'root'
})
export class EnqueteService extends BaseCrudService<EnqueteModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Enquete';
  }

  getEnqueteAtiva(): Observable<EnqueteModel> {
    return this
      .httpClient
      .get<EnqueteModel>(`${this.getApiUrl()}${this.getController()}/GetEnqueteAtiva`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  getOpcoesRespostas(enqueteId): Observable<EnqueteOpcaoModel[]> {
    return this
      .httpClient
      .get<EnqueteOpcaoModel[]>(`${this.getApiUrl()}${this.getController()}/GetOpcoesRespostas/${enqueteId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  responder(enqueteId, opcaoId): Observable<EnqueteModel> {
    return this
      .httpClient
      .post<EnqueteModel>(`${this.getApiUrl()}${this.getController()}/Responder/${enqueteId}/${opcaoId}`, null, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  publicarEnquete(enquete: EnqueteModel): Observable<EnqueteModel> {
    return this.httpClient
    .post(`${this.getApiUrl()}${this.getController()}/PublicarEnquete`, JSON.stringify(enquete), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  agendarEnquete(enquete: EnqueteModel): Observable<EnqueteModel> {
    return this.httpClient
    .post(`${this.getApiUrl()}${this.getController()}/AgendarEnquete`, JSON.stringify(enquete), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  encerrarEnquete(enquete: EnqueteModel): Observable<EnqueteModel> {
    return this.httpClient
    .post(`${this.getApiUrl()}${this.getController()}/EncerrarEnquete`, JSON.stringify(enquete), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
