<div bsModal #selectModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="selectModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Selecione um Paciente</h2>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="pessoa.nomeFantasia">Nome</mat-option>
                <mat-option value="pessoa.codigo">Codigo</mat-option>
                <mat-option value="pessoa.cnpjCpf">CNPJ/CPF</mat-option>
                <mat-option value="pessoa.cidade.nome">Cidade</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="search" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Data de Nascimento</mat-label>
              <input matInput [(ngModel)]="searchDataNascimento.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-1">
            <mat-label></mat-label>
            <button mat-flat-button color="primary" (click)="filter()">
              <span>Filtrar</span>
            </button>
          </div>
        </div>
        <div class="table-responsive" *ngIf="dtOptions.serverSide">
          <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped table-hover"
            style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center" width="100">Código</th>
                <th class="text-center">Nome</th>
                <th class="text-center" width="150">CNPJ/CPF</th>
                <th class="text-center" width="100">Nascimento</th>
                <th class="text-center" width="150">Cidade/UF</th>                
              </tr>
            </thead>
            <tbody *ngIf="models?.length != 0">
              <tr *ngFor="let paciente of models" (click)="select(paciente.id)" style="cursor: pointer;">
                <td>
                  {{paciente.pessoaCodigo}}
                </td>
                <td>
                  {{paciente.pessoaNomeFantasia}}
                </td>
                <td>
                  {{paciente.pessoaCnpjCpf}}
                </td>
                <td>
                  {{paciente.pessoaDataNascimento | date: 'dd/MM/yyyy'}}
                </td>
                <td>
                  {{paciente.pessoaCidadeUf}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-paciente-modal #pacienteModal (modalSave)="onResponse($event)"></app-paciente-modal>