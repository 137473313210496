import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { ComponenteSistemaType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
    templateUrl: './topbar.component.html',
    selector: 'top-bar',
    encapsulation: ViewEncapsulation.None
})
export class TopBarComponent extends BaseComponent implements OnInit {
    hasSite: boolean;

    constructor(
        injector: Injector,
        readonly typesService: TypesService,
        readonly tenantService: TenantService
    ) {
        super(injector);
    }

    openSite() {
        const subdomain = `${localStorage.getItem('app.tenant')}.`;
        window.open(`https://${location.host.replace('admin.', subdomain)}`, '_blank');
    }

    ngOnInit() {
        super.ngOnInit();

        this.tenantService.getCurrentTenant().subscribe(tenant => {
            tenant;        
            const modulos = tenant.componentes.filter(x => x.tipoItem === ComponenteSistemaType.Modulo);    
            if (modulos.find(x => x.codigoItem === this.typesService.MODULO_PORTAL)) {
                this.hasSite = true;
            }
        });
    }
}
