import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest, CidadeModel, EstadoModel } from 'projects/ProjetoBaseAngular/domain/models';
import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { OptionSearchType, SearchType, SexoType } from 'projects/ProjetoBaseAngular/domain/types';
import { StatusCurriculoType } from 'projects/admin/src/domain/types';
import { CurriculoModel, VagaModel } from '../domain/models';
import { CurriculoService, VagaService } from '../domain/services';
import { CurriculoCpfModalComponent } from './curriculo-cpf-modal/curriculo-cpf-modal.component';
import { CurriculoModalComponent } from './curriculo-modal/curriculo-modal.component';

@Component({
  selector: 'app-curriculo',
  templateUrl: './curriculo.component.html'
})
export class CurriculoComponent extends BaseCrudComponent<CurriculoModel> implements OnInit {
  @ViewChild('curriculoModal') modal: CurriculoModalComponent;
  @ViewChild('curriculoCpfModal') cpfModal: CurriculoCpfModalComponent;

  StatusCurriculoType: typeof StatusCurriculoType = StatusCurriculoType;

  statusCurriculo = [
    { text: 'Pendente', value: StatusCurriculoType.Pendente },
    { text: 'Visto', value: StatusCurriculoType.Visto },
    { text: 'Contratado', value: StatusCurriculoType.Contratado },
    { text: 'Não Atende', value: StatusCurriculoType.NaoAtende }
  ];

  sexoCurriculo = [
    { text: 'Masculino', value: SexoType.Masculino },
    { text: 'Feminino', value: SexoType.Feminino },
    { text: 'Não Definido', value: SexoType.NaoDefinido }
  ];

  vagas: VagaModel[];
  estados: EstadoModel[];
  cidades: CidadeModel[];

  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime);
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime);
  searchEstado = new ModelSearch('estadoId', OptionSearchType.Equals, SearchType.Guid);
  searchCidade = new ModelSearch('cidadeId', OptionSearchType.Equals, SearchType.Guid);
  searchVaga = new ModelSearch('vagaId', OptionSearchType.Equals, SearchType.Guid);
  searchStatus = new ModelSearch('status', OptionSearchType.Equals, SearchType.Int);
  searchSexo = new ModelSearch('sexo', OptionSearchType.Equals, SearchType.Int);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  private readonly estadoService: EstadoService;
  private readonly cidadeService: CidadeService;

  selectColumns = [
    { data: 'dataInclusao' },
    { data: 'status' },
    { data: 'vagaTitulo' },
    { data: 'nome' },
    { data: 'areaAtuacao' },
    { data: 'sexo' },
    { data: '(cidadeNome + "/" + estadoUf) as cidadeUf' },
    { data: 'celular' },
    { data: 'total' },
    { data: 'cpf', visible: false }
  ];

  constructor(
    injector: Injector,
    curriculoService: CurriculoService,
    readonly vagaService: VagaService,
  ) {
    super(injector, curriculoService);
    this.estadoService = injector.get<EstadoService>(EstadoService);
    this.cidadeService = injector.get<CidadeService>(CidadeService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    if (this.searchDataInicial.searchTerm) {
      this.searchDataInicial.searchTerm = `${this.searchDataInicial.searchTerm} 00:00`;
      searches.push(this.searchDataInicial);
    }
    if (this.searchDataFinal.searchTerm) {
      this.searchDataFinal.searchTerm = `${this.searchDataFinal.searchTerm} 23:59`;
      searches.push(this.searchDataFinal);
    }
    (this.searchEstado.searchTerm) && searches.push(this.searchEstado);
    (this.searchCidade.searchTerm) && searches.push(this.searchCidade);
    (this.searchVaga.searchTerm) && searches.push(this.searchVaga);
    (Number.isInteger(this.searchStatus.searchTerm)) && searches.push(this.searchStatus);
    (Number.isInteger(this.searchSexo.searchTerm)) && searches.push(this.searchSexo);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  getStatusCurriculo(tipo: StatusCurriculoType): string {
    switch (tipo) {
      case StatusCurriculoType.Pendente:
        return 'Pendende';
      case StatusCurriculoType.Visto:
        return 'Visto';
      case StatusCurriculoType.Contratado:
        return 'Contratado';
      case StatusCurriculoType.NaoAtende:
        return 'Não Atende';
    }
  }

  getSexoCurriculo(tipo: SexoType): string {
    switch (tipo) {
      case SexoType.Masculino:
        return 'Masculino';
      case SexoType.Feminino:
        return 'Feminino';
      case SexoType.NaoDefinido:
        return 'Não Definido';
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.vagaService.getAll().subscribe(vagas => this.vagas = vagas);
    this.estadoService.getAll().subscribe(estados => this.estados = estados);

    this.dtOptions.order = [[0, 'desc']];
  }

  changeEstado(estadoId) {
    if (estadoId) {
      this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
    } else {
      this.cidades = [];
    }
  }

  showCurriculos(cpf: string) {
    this.cpfModal.show(cpf);
  }
}
