import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ProcedimentoModalComponent } from './procedimento-modal/procedimento-modal.component';
import { ProcedimentoModel } from '../domain/models';
import { ProcedimentoService } from '../domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-procedimento',
  templateUrl: './procedimento.component.html'
})
export class ProcedimentoComponent extends BaseCrudComponent<ProcedimentoModel> implements OnInit {
  @ViewChild('modal') modal: ProcedimentoModalComponent;

  searchNome = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);  
  searchUsoFrequente = new ModelSearch('usoFrequente', OptionSearchType.Equals, SearchType.Boolean);
  searchTipoServico = new ModelSearch('tipoServico', OptionSearchType.Equals, SearchType.Int);
  OptionSearchType: typeof OptionSearchType = OptionSearchType;
  
  selectColumns = [ 
    { data: 'codigo'},
    { data: 'nome'},
    { data: 'grupo.nome'},
    { data: 'exame'},
    { data: 'usoFrequente'},
    { data: 'tipoServico'},
    { data: 'registroAtivo'}
  ];

  constructor(
    injector: Injector,
    readonly service: ProcedimentoService,
    readonly typesService: TypesService

  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();  
    this.searchUsoFrequente.searchTerm = "";
    this.searchTipoServico.searchTerm = 1000;
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    (this.searchNome.searchTerm) && searches.push(this.searchNome);
    if (this.searchTipoServico.searchTerm < 1000){
      searches.push(this.searchTipoServico);
    }
    if (this.searchUsoFrequente.searchTerm &&
      this.searchUsoFrequente.searchTerm === true ||
      this.searchUsoFrequente.searchTerm === false) {
      searches.push(this.searchUsoFrequente);
    }

    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }
}
