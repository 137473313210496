import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { CentroCustoComponent } from './centro-custo.component';
import { CentroCustoModalComponent } from './centro-custo-modal/centro-custo-modal.component';


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    CentroCustoComponent,
    CentroCustoModalComponent
  ]
})
export class CentroCustoModule { }
