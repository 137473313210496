import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { PushModel } from 'projects/ProjetoBaseAngular/domain/models';
import { PushService } from 'projects/ProjetoBaseAngular/domain/services/notificacao/push.service';
import { PushStatusType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-push-modal',
  templateUrl: './push-modal.component.html'
})
export class PushModalComponent extends BaseCrudModalComponent<PushModel> implements OnInit {
  @ViewChild('pushModal') modal: ModalDirective;

  PushStatusType: typeof PushStatusType = PushStatusType;

  status = [
    { text: 'Pendente de Envio', value: PushStatusType.Pendente },
    { text: 'Agendado', value: PushStatusType.Agendado }
  ];

  constructor(
    injector: Injector,
    pushService: PushService,
  ) {
    super(injector, pushService);
  }

  protected initializeForm(push: PushModel) {
    this.form = this.formBuilder.group({
      id: push.id,
      dataInclusao: [push.dataInclusao, Validators.required],
      dataAlteracao: push.dataAlteracao,
      titulo: [push.titulo, [
        Validators.required,
        Validators.maxLength(100)]
      ],
      mensagem: [push.mensagem, [
        Validators.required,
        Validators.maxLength(400)]
      ],
      url: [push.url, [
        Validators.required,
        Validators.maxLength(200)]
      ],
      status: [push.status],
      dataAgendamento: [this.fromJsonDateTime(push.dataAgendamento)]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  beforeShowCreate(model: PushModel): PushModel {
    model.status = PushStatusType.Pendente;
    return model;
  }
}
