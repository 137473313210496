import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { ParceirosComponent } from './parceiros.component';
import { ParceiroModalComponent } from './parceiro-modal/parceiro-modal.component';

@NgModule({
  imports: [
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    ParceirosComponent,
    ParceiroModalComponent
  ]
})
export class ParceirosModule { }
