<nav class="navbar">
    <div class="container-fluid">
        <div class="navbar-header">
            <a href="javascript:void(0);" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false"></a>
            <a href="javascript:void(0);" class="bars"></a>
            <a class="animated bounceIn navbar-brand" style="display: inline-block; padding-top: .25rem; padding-bottom: .25rem; margin-right: 1rem; font-size: 1.25rem; line-height: inherit;" routerLink="/admin">
                <img style="height: 40px" class="img-responsive" src="assets/images/logotipo.svg" title="Mr.Doctor - Sistema para Gerenciamento de Clínicas" alt="Mr.Doctor - Sistema para Gerenciamento de Clínicas">
            </a>            
        </div>
        <div class="collapse navbar-collapse" id="navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="hasSite">
                    <button mat-raised-button type="button" class="bg-indigo" (click)="openSite()" style="margin-top: 18px;">
                        Ver site
                    </button>
                </li>

                <!-- Notifications -->
                <li class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown" role="button">
                        <i class="material-icons">notifications</i>
                        <span id="label_count" class="label-count" style="visibility: hidden">0</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="header">NOTIFICAÇÕES</li>
                        <li class="body">
                            <ul class="menu" id="noti_content"></ul>
                        </li>
                        <li class="footer" id="noti_footer"></li>                                
                    </ul>
                </li>
                <!-- #END# Notifications -->

                <li class="pull-right">
                    <a href="javascript:void(0);">
                        <img src="assets/images/user.jpg" width="24" height="24" alt="User" class="img-circle js-right-sidebar" data-close="true" style="margin-top: 0px" />
                    </a>
                </li>                
            </ul>
        </div>
    </div>
</nav>
