import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PacienteExameIndicadorItemModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PacienteExameIndicadorItemService extends BaseCrudService<PacienteExameIndicadorItemModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'PacienteExameIndicadorItem';
  }

  getByExameIndicadorId(id: string): Observable<PacienteExameIndicadorItemModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByExameIndicadorId/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
