import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { EquipamentoModalComponent } from './equipamento-modal/equipamento-modal.component';
import { EquipamentoModel } from '../domain/models';
import { EquipamentoService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-equipamento',
  templateUrl: './equipamento.component.html'
})
export class EquipamentoComponent extends BaseCrudComponent<EquipamentoModel> implements OnInit {
  @ViewChild('modal') modal: EquipamentoModalComponent;

  search = new ModelSearch('nomeApresentacao', OptionSearchType.Contains, SearchType.String);
  
  constructor(
    injector: Injector,
    readonly service: EquipamentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  selectColumns = [
    { data: 'nomeApresentacao' },
    { data: 'usaConfirmacaoLote'},
    { data: 'preRequisitos' },
    { data: 'capturaImagem' },
    { data: 'registroAtivo' },
    { data: 'profissionalRecursoId', hidden: true },
  ];

  ngOnInit() {
    super.ngOnInit();
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

}
