import { NgModule } from '@angular/core';
import { FaturamentoComponent } from './faturamento.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AtendimentoModule } from '../atendimento/atendimento/atendimento.module';

@NgModule({
  imports: [
    SharedModule,
    AtendimentoModule
  ],
  declarations: [
    FaturamentoComponent
  ]
})
export class FaturamentoModule { }
