import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AtendimentoSolicExameItemModel, AtendimentoSolicExameModel, ProcedimentoModel } from '../../../domain/models';
import { DataTableDirective } from 'angular-datatables';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { AtendimentoSolicExameService, ProcedimentoService } from '../../../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { ExameService } from '../../../../modelos/domain/services/exame.service';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

@Component({
  selector: 'app-atendimento-solic-exame-modal',
  templateUrl: './atendimento-solic-exame-modal.component.html'
})
export class AtendimentoSolicExameModalComponent extends BaseCrudModalComponent<AtendimentoSolicExameModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild(DataTableDirective) datatableExame: DataTableDirective;
  @ViewChild(DataTableDirective) datatableModelos: DataTableDirective;
  @Input('atendimentoId') atendimentoId: string;
  @Input('profissionalId') profissionalId: string;

  dtOptionsExame: DataTables.Settings = null;
  dtOptionsModelos: DataTables.Settings = null;

  listaExame: any[];
  listaModelo: SelectDto[];

  searchNome = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);
  searchUsoFrequente = new ModelSearch('usoFrequente', OptionSearchType.Equals, SearchType.Boolean);
  searchSoExame = new ModelSearch('exame', OptionSearchType.Equals, SearchType.Boolean, true);

  searchAtivo = new ModelSearch('registroAtivo', OptionSearchType.Equals, SearchType.Boolean, true);

  searchProfissionalId = new ModelSearch('profissionalSaudeId', OptionSearchType.Equals, SearchType.Guid);

  constructor(
    injector: Injector,
    service: AtendimentoSolicExameService,
    readonly procedimentoService: ProcedimentoService,
    readonly exameModeloService: ExameService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected init() {
    super.init();
    this.searchUsoFrequente.searchTerm = true;
    this.searchSoExame.searchTerm = true;
    this.searchAtivo.searchTerm = true;

    this.searchProfissionalId.searchTerm = this.profissionalId;

    this.initExames();
    this.initModelos();
  }

  protected initializeForm(model: AtendimentoSolicExameModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      atendimentoId: [this.atendimentoId],
      seq: [model.seq, Validators.required],
      descricao: [model.descricao, Validators.required]
    });
  }

  initExames() {
    this.dtOptionsExame = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      dom: '<r<t>ip>',
      pageLength: 10,
      searching: false,
      serverSide: true,
      processing: true,
      scrollY: "280px",
      scrollCollapse: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters = this.beforeDtRequestExame(dataTablesParameters);
        this.procedimentoService.getResponse(dataTablesParameters).subscribe(resp => {
          this.listaExame = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { data: 'nome' },
        { data: 'id', orderable: false }
      ]
    };
  }

  initModelos() {
    this.dtOptionsModelos = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      dom: '<rt<t>ip>',
      pageLength: 10,
      searching: false,
      serverSide: true,
      processing: true,
      scrollY: "280px",
      scrollCollapse: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters = this.beforeDtRequestModelos(dataTablesParameters);
        this.exameModeloService.getResponse(dataTablesParameters).subscribe(resp => {
          this.listaModelo = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { data: 'nome' },
        { data: 'id', orderable: false }
      ]
    };
  }

  protected beforeDtRequestExame(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];

    dataTablesParameters.searches.push(this.searchSoExame);
    dataTablesParameters.searches.push(this.searchAtivo);
    (this.searchUsoFrequente.searchTerm) && dataTablesParameters.searches.push(this.searchUsoFrequente);
    (this.searchNome.searchTerm) && dataTablesParameters.searches.push(this.searchNome);

    dataTablesParameters.selectColumns = [{ data: 'nome' }];

    return dataTablesParameters;
  }

  protected beforeDtRequestModelos(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];

    dataTablesParameters.searches.push(this.searchAtivo);
    dataTablesParameters.searches.push(this.searchProfissionalId);
    
    dataTablesParameters.selectColumns = [{ data: 'nome' }];

    return dataTablesParameters;
  }  

  filterExame() {
    this.datatableExame?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  filterModelos() {
    this.datatableModelos?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  async addExame(id: string) {
    const model = await this.procedimentoService.getById(id).toPromise();
    let descricao: string = "";

    if (this.form.value.descricao) {
      descricao = this.form.value.descricao;
    }
    descricao += descricao.length > 0 ? `\n${model.nome}` : model.nome;

    this.form.patchValue({ descricao });
  }

  async addModelo(id: string) {
    const model = await this.exameModeloService.getById(id).toPromise();
    let descricao: string = "";

    if (this.form.value.descricao) {
      descricao = this.form.value.descricao;
    }
    descricao += descricao.length > 0 ? `\n${model.texto}` : model.texto;

    this.form.patchValue({ descricao });
  }

  isAllowedExame(value: string) {
    const type = this.procedimentoService.getController();
    return super.isAllowed(value, type);
  }

  async addUsoFreq(model: ProcedimentoModel) {
    let result = await this.commonService.mensagemConfirmacao("Adicionar exame em lista de uso frequente?", "", "question");
    if (result) {
      await this.procedimentoService.setUsoFrequenteById(model.id, true).toPromise();
    }
  }

  async remUsoFreq(model: ProcedimentoModel) {
    let result = await this.commonService.mensagemConfirmacao("Remover exame da lista de uso frequente?", "", "question");
    if (result) {
      await this.procedimentoService.setUsoFrequenteById(model.id, false).toPromise();
      this.filterExame();
    }
  }

  onChangeOpcao() {
    this.filterExame();
  }
}
