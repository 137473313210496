import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { TenantModel, EstadoModel, CidadeModel, TenantLicencaModel, ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { PessoaType } from 'projects/ProjetoBaseAngular/domain/types';
import Swal from 'sweetalert2';
import { EmpresaService } from '../domain/servides';


@Component({
  selector: 'app-empresa-modal',
  templateUrl: './empresa-modal.component.html'
})
export class EmpresaModalComponent extends BaseCrudModalComponent<TenantModel> implements OnInit {
  @ViewChild('empresaModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  estados: EstadoModel[];
  cidades: CidadeModel[];

  tenant: TenantModel;

  cnpjCpfMask = '';
  cnpjCpfPlaceholder = '';
  tipos = [
    { text: 'Jurídica', value: PessoaType.Juridica },
    { text: 'Física', value: PessoaType.Fisica }
  ];

  readonly pessoaJuridica = PessoaType.Juridica;
  readonly pessoaFisica = PessoaType.Fisica;

  constructor(
    injector: Injector,
    readonly empresaService: EmpresaService,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService
  ) {
    super(injector,empresaService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
    this.verifyPermissionUpdate = false;
  }  

  protected initializeForm(model: TenantModel) {
    this.tenant = model;
    this.form = this.formBuilder.group({
      id: model.id,
      dataAlteracao: model.dataAlteracao,
      site: [model.site, [
        Validators.maxLength(50)]
      ],
      imagemUrl: [model.imagemUrl, [
        Validators.maxLength(200)]
      ],
      imagemUrlBase: [model.imagemUrlBase],
      imagemPath: [model.imagemPath],
      celular: [model.celular, [
        Validators.required,
        Validators.maxLength(15)]
      ],
      telefone: [model.telefone, [
        Validators.maxLength(15)]
      ],
      email: [model.email, [
        Validators.maxLength(50)]
      ],
      subdominio: [model.subdominio, [
        Validators.required,
        Validators.maxLength(50)]
      ],
      ruaAvenida: [model.ruaAvenida, [
        Validators.minLength(5),
        Validators.maxLength(50)]
      ],
      numero: [model.numero, Validators.maxLength(10)],
      complemento: [model.complemento, Validators.maxLength(50)],
      bairro: [model.bairro, Validators.maxLength(50)],
      cep: [model.cep, Validators.maxLength(10)],
      estadoId: model.estadoId,
      cidadeId: model.cidadeId,
    });
  }

  changeCep(cep: string) {
    if (cep) {
      this.commonService.queryPostalCode(cep).subscribe((endereco: any) => {
        this.form.patchValue({
          ruaAvenida: endereco.logradouro,
          bairro: endereco.bairro
        });

        const estadoId = this.estados.find(x => x.uf === endereco.uf).id;
        this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => {
          this.cidades = cidades;
          const cidadeId = this.cidades.find(x => x.codigoIbge === endereco.ibge).id;

          this.form.patchValue({ estadoId, cidadeId });
        });
      });
    }
  }

  changeEstado(estadoId: string) {
    this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
  }

  beforeShowEdit(model: TenantModel) {
    model.estadoId && this.changeEstado(model.estadoId);
    if (!model.licenca) {
      model.licenca = new TenantLicencaModel();
      model.licenca.id = model.id;
      model.licenca.dataInclusao = new Date();
      model.licenca.diasCarencia = 2;
    }
    return model;
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({ 
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
     });
  }

  deselectImage() {
    this.form.patchValue({ 
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
     });  
    }

  showEditarDados() {
    this.commonService.spinnerOpen('Carregando...');
    this.form = null;
    this.newItem = false;
    this.detailMode = false;
    this.isBusy = false;
    this.empresaService.getEmpresa().subscribe(
      (model) => {
        model.dataAlteracao = new Date();
        model = this.beforeShowEdit(model);
        this.initializeForm(model);
        this.modal.show();
        this.commonService.spinnerClose();
      },
      () => {
        this.commonService.spinnerClose();
      });
  }

  async save() {
    let model: TenantModel = Object.assign({}, this.form.value);

    this.empresaService.edit(model).subscribe(
      (model) => {
        this.afterSave(model);
        this.close();
        this.modalSave.emit(model.id);
        this.isBusy = false;
      },
      (errors) => {
        this.verifyErrors(errors);
        this.isBusy = false;
        Swal.fire(
          'Oops! Algo deu errado',
          errors.join('/n'),
          'error');
      }
    );
    super.save();
  }
}
