import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProcedimentoConvenioProfissionalModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProcedimentoConvenioProfissionalService extends BaseCrudService<ProcedimentoConvenioProfissionalModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ProcedimentoConvenioProfissional';
  }

  getByProcConvId(procConvId: string): Observable<ProcedimentoConvenioProfissionalModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByProcConvId/${procConvId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByProcConvIdAndProfissionalId(procConvId: string, profId: string): Observable<ProcedimentoConvenioProfissionalModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByProcConvIdAndProfissionalId/${procConvId}/${profId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
