<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="nome">Nome</mat-option>
                <mat-option value="tipoParceiro">Tipo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-8">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um texto válido.</mat-error>
            </mat-form-field>
          </div>

        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-12">
      <div class="card">
          <div class="header">
              <h2>Tipo Servico</h2>
          </div>
          <div class="body table-responsive">
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
              <div class="example-box" *ngFor="let tipoServico of models" cdkDrag>
                {{tipoServico.nome}}
                <div>
                  <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(tipoServico)" matTooltip="Editar">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(tipoServico.id)" matTooltip="Excluir">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
                <mat-icon>add</mat-icon>
            </button>
        </div>
      </div>
  </div>
</div>
<app-tipo-servico-modal #tipoServicoModal (modalSave)="onResponse($event)"></app-tipo-servico-modal>
