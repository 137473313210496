import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from '../../galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { ConfigSiteTenantModel } from '../domain/models';
import { ConfigSiteTenantService } from '../domain/services/config-site-tenant.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent extends BaseComponent implements OnInit {  
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;
  url: string;
    
  constructor(
    injector: Injector,    
    private readonly configSiteTenantService: ConfigSiteTenantService
  ) {
    super(injector);
    this.url = location.host;
  }

  protected initializeForm(model: ConfigSiteTenantModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      contactEmail: [model.contactEmail],
      contactTelefone: [model.contactTelefone],
      contactFacebook: [model.contactFacebook],
      contactInstagram: [model.contactInstagram],
      contactTwitter: [model.contactTwitter],
      contactWhatsApp: [model.contactWhatsApp],
      contactYouTube: [model.contactYouTube],
      imgWhatsAppUrl: [model.imgWhatsAppUrl],
      imgWhatsAppUrlBase: [model.imgWhatsAppUrlBase],
      imgWhatsAppPath: [model.imgWhatsAppPath]
    });    
  }

  ngOnInit() {
    this.configSiteTenantService.get().subscribe(model => this.initializeForm(model));    
  }  
  
  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: ConfigSiteTenantModel = Object.assign({}, this.form.value);
    this.configSiteTenantService.editByTipo('Contact', model).subscribe(
      () => {        
        this.snackBar.open('Salvo com sucesso!', 'Ok', {
          duration: 3000
        });
      },
      (errors) => this.verifyErrors(errors)
    );
  }

  showSelectImage() {    
    this.imagemSelectModal.showSelect(0);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({      
      imgWhatsAppUrl: imagem.url,
      imgWhatsAppUrlBase: imagem.urlBase,
      imgWhatsAppPath: imagem.path
    });     
  }

  deselectImage() {
    this.form.patchValue({
      imgWhatsAppUrl: null,
      imgWhatsAppUrlBase: null,
      imgWhatsAppPath: null
    });
  }
}
