import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { CommonService } from 'projects/ProjetoBaseAngular/domain/services';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import Swal from 'sweetalert2';
import { StatusPublicacaoType } from 'projects/admin/src/domain/types';
import { EnqueteModel } from '../domain/models';
import { EnqueteService } from '../domain/services';
import { EnqueteModalComponent } from './enquete-modal/enquete-modal.component';
import { EnqueteRespostasModalComponent } from './enquete-respostas-modal/enquete-respostas-modal.component';

@Component({
  selector: 'app-enquete',
  templateUrl: './enquete.component.html',
})
export class EnqueteComponent extends BaseCrudComponent<EnqueteModel> implements OnInit {
  @ViewChild('enqueteModal') modal: EnqueteModalComponent;
  @ViewChild('enqueteRespostasModal') respostasModal: EnqueteRespostasModalComponent;

  search = new ModelSearch('titulo', OptionSearchType.Contains, SearchType.String);
  searchDataInicial = new ModelSearch('dataFinal', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinal = new ModelSearch('dataFinal', OptionSearchType.LessThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)));


  StatusPublicacaoType: typeof StatusPublicacaoType = StatusPublicacaoType;

  selectColumns = [
    { data: 'statusPublicacao' },
    { data: 'titulo' }, 
    { data: 'dataInicial' }, 
    { data: 'dataFinal' }, 
    { data: 'descricaoInterna' }
  ];

  constructor(
    injector: Injector,
    readonly enqueteService: EnqueteService,
    readonly commonService: CommonService
  ) {
    super(injector, enqueteService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);
    (this.searchDataInicial.searchTerm) && searches.push(this.searchDataInicial);
    (this.searchDataFinal.searchTerm) && searches.push(this.searchDataFinal);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  getTipoStatusPublicacao(tipo: StatusPublicacaoType): string {
    switch (tipo) {
      case StatusPublicacaoType.Rascunho:
        return 'Rascunho';
      case StatusPublicacaoType.Agendada:
        return 'Agendada';
      case StatusPublicacaoType.Publicada:
        return 'Publicada';
      case StatusPublicacaoType.Encerrada:
        return 'Encerrada';
    }
  }

  publicar(model: EnqueteModel) {
    if (model.statusPublicacao !== StatusPublicacaoType.Publicada && model.statusPublicacao !== StatusPublicacaoType.Encerrada) {
      Swal.fire({
        title: 'Confirmar Publicação?',
        html:
          '<h4 class="text-center">Atenção! Esta enquete vai ser publicada.</h4>',
        icon: 'warning',
        focusCancel: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.value) {
          this.enqueteService.publicarEnquete(model).subscribe(enquete => this.onResponse(enquete.id),
           erros => Swal.fire({
             title: 'Erro!',
             icon: 'warning',
             html: erros.join('\n')
            }));
        }
      });
    }
  }

  agendar(model: EnqueteModel) {
    const newModel: EnqueteModel = { ...model };
    newModel.statusPublicacao = StatusPublicacaoType.Agendada;
    this.modal.showEditarAgendamento(newModel);
  }

  duplicar(model: EnqueteModel) {
    const newModel: EnqueteModel = { ...model };
    newModel.statusPublicacao = StatusPublicacaoType.Rascunho;
    newModel.titulo = '';
    newModel.id = this.commonService.newGuid();

    model.opcoes.forEach(opcao => {
      opcao.enqueteId = model.id;
      opcao.id = this.commonService.newGuid();
    });

    this.modal.showCriarDuplicada(newModel);
  }

  encerrar(model: EnqueteModel) {
    if (model.statusPublicacao !== StatusPublicacaoType.Encerrada && model.statusPublicacao !== StatusPublicacaoType.Rascunho) {
      Swal.fire({
        title: 'Encerrar Enquete?',
        html:
          '<h4 class="text-center">Atenção! Esta enquete vai ser encerrada.</h4>',
        icon: 'warning',
        focusCancel: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.value) {
          this.enqueteService.encerrarEnquete(model).subscribe(enquete => this.onResponse(enquete.id));
        }
      });
    }
  }

  dataFinalMenorQueInicial(model: EnqueteModel) {
    if (model.dataFinal <= new Date()) {
      return true;
    }
    return false;
  }

  showRespotas(model: EnqueteModel) {
    this.respostasModal.show(model);
  }
}

