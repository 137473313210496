import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { AgendaDisponibilidadeEventualModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AgendaDisponibilidadeEventualService extends BaseCrudService<AgendaDisponibilidadeEventualModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'AgendaDisponibilidadeEventual';
  }

  getByRecursoIdAndPeriodo(recursoId: string, data1: string, data2: string): Observable<AgendaDisponibilidadeEventualModel[]> {
    return this.httpClient
    .get(`${this.getApiUrl()}${this.getController()}/GetByRecursoIdAndPeriodo/${recursoId}/${data1}/${data2}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }    

  getByRecursoIdAndData(recursoId: string, data: string): Observable<AgendaDisponibilidadeEventualModel[]> {
    return this.httpClient
    .get(`${this.getApiUrl()}${this.getController()}/GetByRecursoIdAndData/${recursoId}/${data}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }    
}
