export class MenuItem {
    name = '';
    permissionName = '';
    icon = '';
    faIcon = '';
    route = '';
    items: MenuItem[];

    constructor(name: string, permissionName: string, icon: string, route: string, childItems: MenuItem[] = null, faIcon: string = "") {
        this.name = name;
        this.permissionName = permissionName;
        this.icon = icon;
        this.route = route;
        this.faIcon = faIcon;

        if (childItems) {
            this.items = childItems;
        } else {
            this.items = [];
        }
    }
}
