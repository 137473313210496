<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg">
    <div class="card modal-content">
      <div class="header">
        <h2>Documento - ChatBot</h2>
      </div>
      <div class="modal-body" *ngIf="model">
        <ul class="nav nav-tabs">
          <li class="active"><a class="nav-link active" id="nav-dados-tab" data-toggle="tab" href="#nav-dados"
              role="tab" aria-controls="nav-dados" aria-selected="true">Solicitação</a></li>
          <li><a class="nav-link" id="nav-chat-tab" data-toggle="tab" href="#nav-chat" role="tab"
              aria-controls="nav-chat" aria-selected="false">Detalhes - Chat</a>
          </li>
        </ul>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade in active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
            <div class="row">
              <div class="col-md-5">
                <p style="margin-top: 25px;">
                  <span [ngClass]="model.statusColor">
                    {{model.statusNome}}</span><span>&nbsp;-&nbsp;</span>
                  <span [ngClass]="model.tipoColor">
                    {{model.tipoNome}}</span>
                </p>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Código</mat-label>
                  <input matInput [value]="model.codigo" type="text" readonly />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Número Paciente</mat-label>
                  <input matInput [value]="model.numeroPaciente" type="tel" mask="00(00) 00000-0000" readonly />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Nome Paciente</mat-label>
                  <input matInput [value]="model.nomePaciente" type="text" readonly />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Nome Médico</mat-label>
                  <input matInput [value]="model.nomeMedico" type="text" readonly />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Convênio</mat-label>
                  <input matInput [value]="model.convenio" type="text" readonly />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Mensagem Solicitação</mat-label>
                  <textarea matInput [value]="model.mensagemSolicitacao" rows="5" readonly></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-chat" role="tabpanel"
              aria-labelledby="nav-chat-tab">
              <div class="main-container">
                <div class="midle-container">
                  <div class="chat-container">
                    <div *ngIf="listaMsg.length > 0">
                      <div class="message-box" *ngFor="let item of listaMsg"
                        [ngClass]="item.tipo === whatsChatMsgType.Recebida ? 'friend-message' : 'my-message'">
                        <p [innerHTML]="item.mensagem"></p>
                        <img *ngIf="item.tipoMidia === whatsChatMsgMidiaType.Imagem"
                          class="img" src={{item.arquivoUrl}} alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>