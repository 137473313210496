import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MicroRegiaoModel } from '../../models/geral/micro-regiao-model';

import { BaseCrudService } from '../base-crud.service';


@Injectable({
  providedIn: 'root'
})
export class MicroRegiaoService extends BaseCrudService<MicroRegiaoModel> {
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'MicroRegiao';
  }

  getAsync(): Observable<MicroRegiaoModel[]> {
    return this
      .httpClient
      .get<MicroRegiaoModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
