<div bsModal #especialidadeMedModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="especialidadeMedModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}"
  (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2> Especialidade Medica </h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>

          <div class="col-md-3">
            <div class="form-group align-center">
              <div style="height: 150px; width: 100%; object-fit: contain;">
                <img src="{{form.value.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                  *ngIf="form.value.imagemUrl">
              </div>
              <div class="align-center">
                <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                  <mat-icon>add_photo_alternate</mat-icon>
                </button>
                <button mat-icon-button type="button" [disabled]="!form.value.imagemUrl" (click)="deselectImage()"
                  matTooltip="Desvincular Imagem">
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="nome" type="text" #focus maxlength="60" />
                  <mat-hint align="end">Máx 60 caracteres</mat-hint>
                  <mat-error>Informe um Nome válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Descrição</mat-label>
                  <textarea matInput #descricao formControlName="descricao" rows="3" maxlength="500"></textarea>
                  <mat-hint align="end">{{descricao.value.length}} / 500</mat-hint>
                  <mat-error>Informe um Descrição válida.</mat-error>
                </mat-form-field>
              </div>
            </div>

            <h4 class="card-inside-title">Configurações do Site</h4>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Titulo do Icone</mat-label>
                  <input matInput formControlName="tituloIcone" type="text" />
                  <mat-hint align="end">Máx 60 caracteres</mat-hint>
                  <mat-error>Informe um Titulo válido.</mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Icone Html</mat-label>
                  <input matInput formControlName="iconeHtml" type="text" />
                  <mat-hint align="end">Máx 200 caracteres</mat-hint>
                  <mat-error>Informe um Html válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2 font-size-h1" [innerHTML]="form.value.iconeHtml">
              </div>

              <button mat-icon-button type="button" (click)="showSelectIcone()" matTooltip="Selecionar Icone">
                <mat-icon>library_add</mat-icon>
              </button>              
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
<app-icone-select-modal #iconeSelectModal (modalClose)="onResponseIcone($event)"></app-icone-select-modal>
