<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg" *ngIf="agendamentoId">
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header" [ngClass]="{'bg-red' : !form.value.registroAtivo}">
          <div class="row">
            <div class="col-md-6">
              <h2 *ngIf="form.value.registroAtivo">Configuração de Recorrência - {{modoCrud}}</h2>
              <h2 *ngIf="!form.value.registroAtivo">Configuração de Recorrência (Cancelada)</h2>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Profissional / Equipamento</mat-label>
                <input matInput [value]="recursoDto.text" readonly class="readonly-input" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Paciente</mat-label>
                <input matInput [value]="paciente.pessoa.nomeFantasia" readonly class="readonly-input"/>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Convênio</mat-label>
                <mat-select formControlName="convenioId">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Buscar (letras iniciais)..."
                      noEntriesFoundLabel="Nenhum resultado encontrado!" [formControl]="convenioFilterCtrl">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let item of filteredConvenios" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Convênio válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Classificação</mat-label>
                <mat-select formControlName="classificacaoId">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Buscar (letras iniciais)..."
                      noEntriesFoundLabel="Nenhum resultado encontrado!" [formControl]="classificacaoFilterCtrl">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let item of filteredClassificacoes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione uma Classificação válida.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data Inicial</mat-label>
                <input matInput formControlName="dataInicial" type="date" readonly class="readonly-input"/>
                <mat-error>Informe um Data válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Horário</mat-label>
                <input matInput formControlName="horario" type="time" readonly class="readonly-input"/>
                <mat-error>Informe um Horario válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Dia da Semana</mat-label>
                <input matInput [value]="diaSemana" readonly class="readonly-input"/>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field *ngIf="newItem">
                <mat-label>Período</mat-label>
                <mat-select formControlName="periodo">
                  <mat-option *ngFor="let item of typesService.recorrenciaPeriodoTypes"
                    [value]="item.value">{{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="!newItem">
                <mat-label>Período</mat-label>
                <input matInput [value]="periodo" readonly class="readonly-input"/>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field *ngIf="newItem">
                <mat-label>Itens Agendamento</mat-label>
                <input matInput formControlName="totalItens" type="text" />
              </mat-form-field>
              <mat-form-field *ngIf="!newItem">
                <mat-label>Itens Agendamento</mat-label>
                <input matInput formControlName="totalItens" type="text" readonly class="readonly-input"/>
              </mat-form-field>
            </div>            
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observações</mat-label>
                <textarea matInput formControlName="observacoes" rows="3" #obs></textarea>
                <mat-hint align="end">{{obs.value.length}} / 1000</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <div class="row">
          <div class="col-sm-4 text-left">
            <button mat-flat-button [disabled]="isBusy || newItem || !registroAtivo || detailMode" type="button" (click)="cancelar()"
              color="warn" class="button-row">
              <mat-icon>cancel</mat-icon>
              <span> Cancelar</span>
            </button>
          </div>
          <div class="col-sm-8">
            <button mat-flat-button [disabled]="!form.valid || isBusy || !registroAtivo" type="submit" (click)="save()" color="primary"
              class="button-row">
              <span>Salvar</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span>Cancelar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>