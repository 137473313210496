import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';

import { PoliticaPrivacidadeModel } from '../../domain/models';
import { PoliticaPrivacidadeService } from '../../domain/services';

@Component({
  selector: 'app-politica-privacidade-modal',
  templateUrl: './politica-privacidade-modal.component.html'
})
export class PoliticaPrivacidadeModalComponent extends BaseCrudModalComponent<PoliticaPrivacidadeModel> implements OnInit {
  @ViewChild('politicaPrivacidadeModal') modal: ModalDirective;

  constructor(
    injector: Injector,
    politicaPrivacidadeService: PoliticaPrivacidadeService,
  ) {
    super(injector, politicaPrivacidadeService);
  }

  protected initializeForm(politicaPrivacidade: PoliticaPrivacidadeModel) {
    this.form = this.formBuilder.group({
      id: politicaPrivacidade.id,
      dataInclusao: [this.fromJsonDate(politicaPrivacidade.dataInclusao), Validators.required],
      dataAlteracao: politicaPrivacidade.dataAlteracao,
      versao: [politicaPrivacidade.versao, [
        Validators.required]
      ],
      subVersao: [politicaPrivacidade.subVersao],
      html: [politicaPrivacidade.html, Validators.required],
      nomeDpo: [politicaPrivacidade.nomeDpo, Validators.required],
      emailDpo: [politicaPrivacidade.emailDpo, Validators.required]
    });
  }

  beforeShowCreate(model: PoliticaPrivacidadeModel): PoliticaPrivacidadeModel {
    model.dataInclusao = new Date();
    return model;
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
