import { OnInit, ViewChild, Output, EventEmitter, Injector, Directive } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { BaseModel } from '../domain/models/base/base-model';
import { BaseCrudService } from '../domain/services/base-crud.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Directive()
export abstract class BaseCrudModalComponent<TModel extends BaseModel> extends BaseComponent implements OnInit {
  @ViewChild('focus') focusElement: any;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  isBusy: boolean;
  newItem: boolean;
  multiCreate: boolean;
  detailMode: boolean;
  protected verifyPermissionCreate: boolean = true;
  protected verifyPermissionRead: boolean = true;
  protected verifyPermissionUpdate: boolean = true;
  protected verifyPermissionDelete: boolean = true;

  protected abstract modal: ModalDirective;
  protected readonly service: BaseCrudService<TModel>;

  /**
   * Model que armazena os dados antes da edição (Old Values).
   * capturado em BeforeShowEdit
   */
  protected oldModel: TModel;

  /**
  * Model que armazena os dados apos o salvamento para ser utilizado
  * em por exemplo casos de multicreate
  */
  protected lastModel: TModel;

  constructor(
    injector: Injector,
    service: BaseCrudService<TModel>
  ) {
    super(injector);
    this.service = service;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected abstract initializeForm(model: TModel);

  protected init() {
  }

  protected newModel(): TModel {
    return new BaseModel() as TModel;
  }

  /**
   * Rotina Asyncrona de criação de model em backend.
   * @param model 
   * @param autoSave 
   */
  protected async create(model: TModel, autoSave: boolean = false) {
		try {
			model = await this.service.create(model).toPromise();
			this.form.patchValue({ id: model.id });
			await this.afterSave(model);
			if (autoSave) {
				this.newItem = false;
				this.oldModel = null;
			} else {
				if (this.multiCreate) {
					this.lastModel = model;
					this.form = null;
					let newModel = this.newModel();
					newModel.id = newModel.id || this.commonService.newGuid();
					newModel.dataInclusao = new Date();
					newModel = await this.beforeShowCreate(newModel);
					this.initializeForm(newModel);
					this.onShown();
				} else {
					this.close();
				}
				this.modalSave.emit(model.id);
			}
			this.isBusy = false;
		} catch (errors) {
			this.verifyErrors(errors);
			this.isBusy = false;
			Swal.fire(
				'Oops! Algo deu errado',
				errors.join('/n'),
				'error');
		}
	}

  /**
   * Rotina Asyncrona de edição em backend.
   * @param model 
   * @param autoSave 
   */
  protected async edit(model: TModel, autoSave: boolean = false) {
		try {
			model = await this.service.edit(model).toPromise();
			this.oldModel = null;
			await this.afterSave(model);
			if (!autoSave) {
				this.close();
				this.modalSave.emit(model.id);
			}
			this.isBusy = false;
		} catch (errors) {
			this.verifyErrors(errors);
			this.isBusy = false;
			Swal.fire(
				'Oops! Algo deu errado',
				errors.join('/n'),
				'error');
		}
	}

  getSlug(title: string): string {
    if (!title) {
      return null;
    }
    return title.toLowerCase()
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')       // Special Characters #1
      .replace(/[èÈéÉêÊëË]+/g, 'e')       	// Special Characters #2
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')       	// Special Characters #3
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')       	// Special Characters #4
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')       	// Special Characters #5
      .replace(/[ýÝÿŸ]+/g, 'y')       		// Special Characters #6
      .replace(/[ñÑ]+/g, 'n')       			// Special Characters #7
      .replace(/[çÇ]+/g, 'c')       			// Special Characters #8
      .replace(/[ß]+/g, 'ss')       			// Special Characters #9
      .replace(/[Ææ]+/g, 'ae')       			// Special Characters #10
      .replace(/[Øøœ]+/g, 'oe')       		// Special Characters #11
      .replace(/[%]+/g, 'pct')       			// Special Characters #12
      .replace(/\s+/g, '-')           		// Replace spaces with -
      .replace(/[^\w\-]+/g, '')       		// Remove all non-word chars
      .replace(/\-\-+/g, '-')         		// Replace multiple - with single -
      .replace(/^-+/, '')             		// Trim - from start of text
      .replace(/-+$/, '')            		    // Trim - from end of text
      .substr(0, 50);            		        // Max length 50 characters
  }

  slugify(value: string) {
    if (!this.form.value.slug) {
      this.form.patchValue({ slug: this.getSlug(value) });
    }
  }

  beforeSave(model: TModel): TModel {
    return model;
  }

  afterSave(model: TModel): TModel {
    return model;
  }

  /**
   * Método recursivo que mapea os campos inválidos, percorrendo inclusive subclasses.
   * E adiciona os campos com invalidações na lista de erros.
   * @param subClass Tipo: FormGroup
   * @param classeInterna Tipo: string (default = "")
   */
  findInvalidControls(formGroup: FormGroup, classeInterna: string = ""): void {
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (controls[name] instanceof FormGroup) {
          let strClass = (classeInterna === "") ? name : `${classeInterna}.${name}`;
          this.findInvalidControls((controls[name] as FormGroup), strClass)
        } else {
          this.setControlError((controls[name] as FormControl), name, classeInterna);
        }
      }
    }
  }

  /**
   * Adiciona definição de validação do FormControl inválida na lista de erros.
   * @param control FormControl
   * @param nome Nome do controle
   * @param classe classe a que pertence o controle (campo)
   */
  setControlError(control: FormControl, nome: string, classe: string) {
    let strError = classe === "" ? nome : `${classe}.${nome}`;
    this.errors.push(`Campo: ${strError}`);

    const errors = control.errors;
    for (const name in errors) {
      this.errors.push(`=> Tipo Erro: ${name}`);
      let errorProperts = errors[name];
      for (const prop in errorProperts) {
        this.errors.push(` => Definição: ${prop} = ${errorProperts[prop]}`);
      }
    }
  }

  async validateToSave(autoSave: boolean = false): Promise<boolean> {
		this.errors = [];
		
		this.form.markAllAsTouched();
		if (!autoSave) {
      if (this.detailMode) {
        this.errors.push('Você está em modo de visualização de detalhes. Registro não pode ser alterado/salvo!');
        return false;
      }
      
      if (!this.form.valid) {
				this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
				this.findInvalidControls(this.form);
        return false;
			}
		}

		return true;
	}

  async save(autoSave: boolean = false) {
		if (!await this.validateToSave(autoSave)) {
			return;
		}

		this.isBusy = true;
		let model: TModel = Object.assign({}, this.form.value);
		model = await this.beforeSave(model);
		if (this.newItem) {
			await this.create(model, autoSave);
		} else {
			await this.edit(model, autoSave);
		}
	}

  beforeShowCreate(model: TModel = null): TModel {
    return model;
  }

  async showCreate(model: TModel = null) {
    if (this.verifyPermissionCreate) {
      if (!this.isAllowed("Create")) {
        this.commonService.mensagem("", `Você não tem permissão de criação neste módulo! Módulo: ${this.service.getController()}`, "info");
        return;
      }
    }

    this.commonService.spinnerOpen('Carregando...');
    this.init();

    this.newItem = true;
    this.detailMode = false;
    this.isBusy = false;
    model = model || this.newModel();
    model.id = model.id || this.commonService.newGuid();
    model.dataInclusao = new Date();
    model.registroAtivo = true;
    model = this.beforeShowCreate(model);
    if (this.form?.disabled) {
      this.form.enable();
      this.form.clearValidators();
    }
    this.initializeForm(model);
    this.modal.show();
    this.afterShowCreate();
    this.commonService.spinnerClose();
  }

  afterShowCreate() {
  }

  beforeShowEdit(model: TModel): TModel {
    this.oldModel = { ...model };
    return model;
  }

  async showEdit(id: string): Promise<void> {
    if (this.verifyPermissionUpdate) {
      if (!this.isAllowed("Update")) {
        this.commonService.mensagem("", `Você não tem permissão de edição neste módulo! Módulo: ${this.service.getController()}`, "info");
        return;
      }
    }

    this.commonService.spinnerOpen('Carregando...');
    this.init();
    this.form = null;
    this.newItem = false;
    this.detailMode = false;
    this.isBusy = false;
    try {
      let model = await this.service.getById(id).toPromise();
      model.dataAlteracao = new Date();
      model = this.beforeShowEdit(model);
      this.initializeForm(model);
      this.modal.show();
      this.commonService.spinnerClose();
    }
    catch (errors) {
      this.verifyErrors(errors);
      this.isBusy = false;
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('/n'),
        'error');
      this.commonService.spinnerClose();
    }
  }

  async showDetail(id: string) {
    if (this.verifyPermissionRead) {
      if (!this.isAllowed("Read")) {
        this.commonService.mensagem("", `Você não tem permissão de leitura neste módulo! Módulo: ${this.service.getController()}`, "info");
        return;
      }
    }

    this.commonService.spinnerOpen('Carregando...');
    this.init();
    this.detailMode = true;
    this.form = null;
    this.newItem = false;
    this.isBusy = false;
    try {
      let model = await this.service.getById(id).toPromise();
      model.dataAlteracao = new Date();
      model = this.beforeShowEdit(model);
      this.initializeForm(model);
      this.form.disable();
      this.modal.show();
      this.commonService.spinnerClose();
    } catch (errors) {
      this.verifyErrors(errors);
      this.isBusy = false;
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('/n'),
        'error');
      this.commonService.spinnerClose();
    }
  }

  async showEditWithModel(model: TModel) {
    if (this.verifyPermissionUpdate) {
      if (!this.isAllowed("Update")) {
        this.commonService.mensagem("", `Você não tem permissão de edição neste módulo! Módulo: ${this.service.getController()}`, "info");
        return;
      }
    }

    this.init();
    this.form = null;
    this.newItem = false;
    this.isBusy = false;
    model.dataAlteracao = new Date();
    model = this.beforeShowEdit(model);
    this.initializeForm(model);
    this.modal.show();
  }

  close() {
    this.errors = [];
    this.form = null;
    this.modal.hide();
  }

  onShown() {
    if (this.focusElement) {
      if (this.focusElement.nativeElement) {
        this.focusElement.nativeElement.focus();
      } else {
        this.focusElement.focus();
      }
    }
  }

  /**
   * Returna validação da operação (true/false).
   * De acordo com as permissões definidas nas CLaims do usuário.
   * @param value É o tipo de operação que será testada a permissão (exemplo: "Update").
   * @param type É o tipo de Claim (PermissionName = Nome do controller).
   * Se parâmetro não for passado utilizará Claim/PermissionName do service padrão do componente.
   */
  isAllowed(value: string, type?: string) {
    if (!type) {
      type = this.service.getController();
    }
    return super.isAllowed(value, type);
  }
}
