import { Injectable } from '@angular/core';
import { CategoriaFinanceiraType, ComissaoVendedorType, ComponenteSistemaType, ContaItemType, DocFinanRecOperationType, DocFinanStatusType, DocFinanType, EntidadePessoaConsts, PeriodicidadeRecorrenciaType, ProdutoType, PushStatusType, RecorrenciaType, VendedorType } from '../types';
import { ContaFinanType, DocMovtoEstoqueType, DocStatusType, GrupoDreType } from '../types';
import { PagamentoType, PeriodicidadeCobrancaType, PessoaType, RegistroSistemaType, SexoType } from '../types';

@Injectable({
  providedIn: 'root'
})
export class BaseTypesService {

  constructor() { }

  boolTypes = [
    { text: 'Sim', value: true, color: 'bg-green' },
    { text: 'Não', value: false, color: 'bg-red'},
  ];
  
  graphTypes = [
    { text: 'Pizza', value: 'pie' },
    { text: 'Doughnut', value: 'doughnut' },
    { text: 'Linha', value: 'line' },
    { text: 'Barras', value: 'bar' },
    { text: 'Barras Horinzontal', value: 'horizontalBar' },
    { text: 'Radar', value: 'radar' },
    { text: 'Área Polar', value: 'polarArea' },
    { text: 'Bolhas', value: 'bubble' },
    { text: 'Scatter', value: 'scatter' },
    { text: 'Velas', value: 'candlestick' }
  ];

  registroSistemaTypes = [
    { text: 'Inativo', value: RegistroSistemaType.Inativo },
    { text: 'Ativo', value: RegistroSistemaType.Ativo }
  ];

  pessoaTypes = [
    { text: 'Jurídica', value: PessoaType.Juridica },
    { text: 'Física', value: PessoaType.Fisica }
  ];

  sexoTypes = [
    { text: 'Não Definido', value: SexoType.NaoDefinido },
    { text: 'Masculino', value: SexoType.Masculino },
    { text: 'Feminino', value: SexoType.Feminino }
  ];

  entidadePessoaTypes = [    
    { text: 'Cliente', value: EntidadePessoaConsts.Cliente, color: 'bg-teal' },
    { text: 'Fornecedor', value: EntidadePessoaConsts.Fornecedor, color: 'bg-pink' },
    { text: 'Vendedor', value: EntidadePessoaConsts.Vendedor, color: 'bg-orange' },
    { text: 'Banco', value: EntidadePessoaConsts.Banco, color: 'bg-grey' },
    { text: 'Transportadora', value: EntidadePessoaConsts.Transportadora, color: 'bg-brown' }
  ];  

  produtoTypes = [
    { text: 'Produto', value: ProdutoType.Produto },
    { text: 'Serviço', value: ProdutoType.Servico },
    { text: 'Matéria Prima', value: ProdutoType.MateriaPrima },
    { text: 'Insumo', value: ProdutoType.Insumo },
  ];

  componentesSistema = [
    { text: 'Descritivo', value: ComponenteSistemaType.Descritivo },
    { text: 'Funcionalidade', value: ComponenteSistemaType.Funcionalidade },
    { text: 'Módulo', value: ComponenteSistemaType.Modulo }
  ];

  docStatusTypes = [
    { text: 'Rascunho', value: DocStatusType.Draft, color: 'bg-grey', icon: 'line_style' },
    { text: 'Salvo', value: DocStatusType.Saved, color: 'bg-orange', icon: 'segment' },
    { text: 'Aplicado', value: DocStatusType.Applied, color: 'bg-green', icon: 'done' }
  ];

  docMovtoEstoqueTypes = [
    { text: 'Sem Movimento', value: DocMovtoEstoqueType.SemMovimento },
    { text: 'Entrada', value: DocMovtoEstoqueType.Entrada },
    { text: 'Saída', value: DocMovtoEstoqueType.Saida },
    { text: 'Saída para Transferência', value: DocMovtoEstoqueType.SaidaTransferencia }
  ];

  comissaoVendedorTypes = [
    { text: 'Sem Comissão', value: ComissaoVendedorType.SemComissao },
    { text: 'Sobre a Venda', value: ComissaoVendedorType.Venda },
    { text: 'Sobre Liquidação', value: ComissaoVendedorType.Liquidacao },
    { text: 'Sobre Recorrência', value: ComissaoVendedorType.Recorrencia }
  ];

  periodicidadeCobrancaTypes = [
    { text: 'Mensal', value: PeriodicidadeCobrancaType.Mensal },
    { text: 'Trimestral', value: PeriodicidadeCobrancaType.Trimestral },
    { text: 'Semestral', value: PeriodicidadeCobrancaType.Semestral },
    { text: 'Anual', value: PeriodicidadeCobrancaType.Anual },
    { text: 'Customizado', value: PeriodicidadeCobrancaType.Customizado },
  ];

  categoriaFinanceiraTypes = [
    { text: 'Receita', value: CategoriaFinanceiraType.Receita, color: 'bg-teal', icon: 'playlist_add_check' },
    { text: 'Despesa', value: CategoriaFinanceiraType.Despesa, color: 'bg-pink', icon: 'ballot' }
  ];

  grupoDreTypes = [
    { text: 'Receita', value: GrupoDreType.Receita },
    { text: 'Despesa', value: GrupoDreType.Despesa },
    { text: 'Ambos', value: GrupoDreType.Ambos },
    { text: 'Totalizador', value: GrupoDreType.Totalizador }
  ];

  contaFinanTypes = [
    { text: 'Conta Corrente', value: ContaFinanType.ContaCorrente, icon:'account_balance' },
    { text: 'Carteira', value: ContaFinanType.Carteira, icon:'monetization_on' },
    { text: 'Poupança', value: ContaFinanType.Poupanca, icon:'archive' },
    { text: 'Investimentos', value: ContaFinanType.Investimentos, icon:'trending_up' },
    { text: 'Outros', value: ContaFinanType.Outros, icon:'adjust' }
  ];

  contaItemTypes = [
    { text: 'Crédito', value: ContaItemType.Credito, color: 'bg-blue' },
    { text: 'Débito', value: ContaItemType.Debito, color: 'bg-red' }
  ];
  
  pagamentoTypes = [
    { text: 'Dinheiro', value: PagamentoType.Dinheiro },
    { text: 'Cartão', value: PagamentoType.Cartao },
    { text: 'Boleto', value: PagamentoType.Boleto },
    { text: 'Cheque', value: PagamentoType.Cheque }
  ];

  docFinanTypes = [
    { text: 'Receber', value: DocFinanType.Receber },
    { text: 'Pagar', value: DocFinanType.Pagar }
  ];

  docFinanStatusTypes = [
    { text: 'Pendente', value: DocFinanStatusType.Pendente, color: 'bg-grey', icon:'schedule_send', iconAction:'done' },
    { text: 'Pago', value: DocFinanStatusType.Pago, color: 'bg-green', icon:'thumb_up', iconAction:'undo' }
  ];

  recorrenciaTypes = [
    { text: 'Sem Recorrência', value: RecorrenciaType.SemRecorrencia },
    { text: 'Fixa', value: RecorrenciaType.Fixa },
    { text: 'Parcelas', value: RecorrenciaType.Parcelas }
  ];

  periodicidadeRecorrenciaTypes = [
    { text: 'Dias', value: PeriodicidadeRecorrenciaType.Dias },
    { text: 'Semanas', value: PeriodicidadeRecorrenciaType.Semanas },
    { text: 'Meses', value: PeriodicidadeRecorrenciaType.Meses },
    { text: 'Anos', value: PeriodicidadeRecorrenciaType.Anos }
  ];

  docFinanRecOperationTypes = [
    { text: 'Somente Este', value: DocFinanRecOperationType.SomenteEste },
    { text: 'Todos Pendentes', value: DocFinanRecOperationType.TodosPendentes },
    { text: 'Todos', value: DocFinanRecOperationType.Todos }
  ];
  
  pushStatusTypes = [
    { text: 'Pendente', value: PushStatusType.Pendente },
    { text: 'Agendado', value: PushStatusType.Agendado },
    { text: 'Em Andamento', value: PushStatusType.EmAndamento },
    { text: 'Concluído', value: PushStatusType.Concluido },
    { text: 'Cancelado', value: PushStatusType.Cancelado }
  ];

  vendedorTypes = [
    { text: 'Vendedor', value: VendedorType.Vendedor },
    { text: 'Representante', value: VendedorType.Representante },
    { text: 'Agente Comercial', value: VendedorType.AgenteComercial },
    { text: 'Consultor', value: VendedorType.Consultor },
    { text: 'Parceiro', value: VendedorType.Parceiro }
  ];

  getBoolTypeNome(value: boolean) {
    return this.boolTypes.find(x => x.value === value)?.text;
  }

  getBoolTypeColor(value: boolean) {
    return this.boolTypes.find(x => x.value === value)?.color;
  }

  getGraphTypeNome(value: string) {
    return this.graphTypes.find(x => x.value === value)?.text;
  }

  getProdutoTypeNome(value: ProdutoType) {
    return this.produtoTypes.find(x => x.value === value)?.text;
  }

  getSexoTypeNome(value: SexoType) {
    return this.sexoTypes.find(x => x.value === value)?.text;
  }

  getPessoaTypeNome(value: PessoaType) {
    return this.pessoaTypes.find(x => x.value === value)?.text;
  }

  getEntidadePessoaTypeNome(tipo: string) {
    return this.entidadePessoaTypes.find(x => x.value === tipo)?.text;
  }

  getEntidadePessoaTypeColor(tipo: string) {
    return this.entidadePessoaTypes.find(x => x.value === tipo)?.color;
  }

  getRegistroSistemaTypeNome(value: RegistroSistemaType) {
    return this.registroSistemaTypes.find(x => x.value === value)?.text;
  }
  
  getComponenteSistemaNome(value: ComponenteSistemaType) {
    return this.componentesSistema.find(x => x.value === value)?.text;
  }

  getDocStatusTypeNome(value: DocStatusType) {
    return this.docStatusTypes.find(x => x.value === value)?.text;
  }

  getDocStatusTypeColor(value: DocStatusType) {
    return this.docStatusTypes.find(x => x.value === value)?.color;
  }

  getDocStatusTypeIcon(value: DocStatusType) {
    return this.docStatusTypes.find(x => x.value === value)?.icon;
  }

  getDocMovtoEstoqueTypeNome(value: DocMovtoEstoqueType) {
    return this.docMovtoEstoqueTypes.find(x => x.value === value)?.text;
  }

  getComissaoVendedorTypeNome(value: ComissaoVendedorType) {
    return this.comissaoVendedorTypes.find(x => x.value === value)?.text;
  }

  getPeriodicidadeCobrancaTypeNome(value: PeriodicidadeCobrancaType) {
    return this.periodicidadeCobrancaTypes.find(x => x.value === value)?.text;
  }

  getCategoriaFinanceiraTypeNome(value: CategoriaFinanceiraType) {
    return this.categoriaFinanceiraTypes.find(x => x.value === value)?.text;
  }

  getCategoriaFinanceiraTypeColor(value: CategoriaFinanceiraType) {
    return this.categoriaFinanceiraTypes.find(x => x.value === value)?.color;
  }

  getCategoriaFinanceiraTypeIcon(value: CategoriaFinanceiraType) {
    return this.categoriaFinanceiraTypes.find(x => x.value === value)?.icon;
  }

  getGrupoDreTypeNome(value: GrupoDreType) {
    return this.grupoDreTypes.find(x => x.value === value)?.text;
  }
  
  getContaFinanTypeNome(value: ContaFinanType) {
    return this.contaFinanTypes.find(x => x.value === value)?.text;
  }

  getContaFinanTypeIcon(value: ContaFinanType) {
    return this.contaFinanTypes.find(x => x.value === value)?.icon;
  }

  getContaItemTypeNome(value: ContaItemType) {
    return this.contaItemTypes.find(x => x.value === value)?.text;
  }

  getContaItemTypeColor(value: ContaItemType) {
    return this.contaItemTypes.find(x => x.value === value)?.color;
  }

  getPagamentoTypeNome(value: PagamentoType) {
    return this.pagamentoTypes.find(x => x.value === value)?.text;
  }

  getDocFinanTypeNome(value: DocFinanType) {
    return this.docFinanTypes.find(x => x.value === value)?.text;
  }

  getDocFinanStatusTypeNome(value: DocFinanStatusType) {
    return this.docFinanStatusTypes.find(x => x.value === value)?.text;
  }

  getDocFinanStatusTypeColor(value: DocFinanStatusType) {
    return this.docFinanStatusTypes.find(x => x.value === value)?.color;
  }

  getDocFinanStatusTypeIcon(value: DocFinanStatusType) {
    return this.docFinanStatusTypes.find(x => x.value === value)?.icon;
  }

  getDocFinanStatusTypeIconAction(value: DocFinanStatusType) {
    return this.docFinanStatusTypes.find(x => x.value === value)?.iconAction;
  }

  getRecorrenciaTypeNome(value: RecorrenciaType) {
    return this.recorrenciaTypes.find(x => x.value === value)?.text;
  }

  getPeriodicidadeRecorrenciaTypeNome(value: PeriodicidadeRecorrenciaType) {
    return this.periodicidadeRecorrenciaTypes.find(x => x.value === value)?.text;
  }

  getDocFinanRecOperationTypeNome(value: DocFinanRecOperationType) {
    return this.docFinanRecOperationTypes.find(x => x.value === value)?.text;
  }

  getPushStatusTypeNome(value: PushStatusType) {
    return this.pushStatusTypes.find(x => x.value === value)?.text;
  }

  getVendedorTypeNome(value: VendedorType) {
    return this.vendedorTypes.find(x => x.value === value)?.text;
  }
}
