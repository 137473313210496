import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { SalaAtendimentoService } from '../../domain/services';
import { SalaAtendimentoModel } from '../../domain/models';

@Component({
  selector: 'app-sala-atendimento-modal',
  templateUrl: './sala-atendimento-modal.component.html'
})
export class SalaAtendimentoModalComponent extends BaseCrudModalComponent<SalaAtendimentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: SalaAtendimentoService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: SalaAtendimentoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      
      nome: [model.nome, [        
        Validators.required, 
        Validators.maxLength(50)        
      ]],
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
