<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card" *ngIf="gruposQuestao">
        <div class="header">
          <h4 class="modal-title">Questionário - Dashboard</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Inicial</mat-label>
                <input matInput [(ngModel)]="dataInicial" type="date" max="2999-12-31" (blur)="filtrarByData()">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Final</mat-label>
                <input matInput [(ngModel)]="dataFinal" type="date" max="2999-12-31" (blur)="filtrarByData()">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Filtros</mat-label>
                <mat-select [(ngModel)]="filtroId" required #focus (selectionChange)="changeFiltroId($event.value)">
                  <mat-option *ngFor="let filtro of listaFiltros" [value]="filtro.id">
                    {{filtro.opcao}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Filtro.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Grupo de Questão</mat-label>
                <mat-select [(ngModel)]="grupoQuestaoId" required
                  (selectionChange)="changeGrupoQuestaoId($event.value)">
                  <mat-option *ngFor="let grupoQuestao of gruposQuestao" [value]="grupoQuestao.id">
                    {{grupoQuestao.titulo}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Grupo de Questão.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Questão</mat-label>
                <mat-select [(ngModel)]="grupoQuestaoItemId" required (selectionChange)="filtrar()">
                  <mat-option *ngFor="let grupoQuestaoItem of grupoQuestaoItens" [value]="grupoQuestaoItem.id">
                    {{grupoQuestaoItem.questao}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Item do Grupo de Questão.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2" *ngIf="grupoQuestao?.tipo!==typesService.PesquisaQuestaoType.Descritivo">
              <mat-form-field>
                <mat-label>Tipo Gráfico</mat-label>
                <mat-select [(ngModel)]="chartType" required (selectionChange)="filtrar()">
                  <mat-option *ngFor="let chartType of chartTypes" [value]="chartType.value">
                    {{chartType.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Item do Grupo de Questão.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2" *ngIf="grupoQuestao?.tipo===typesService.PesquisaQuestaoType.Descritivo">
              <mat-form-field>
                <mat-label>Pesquisa por</mat-label>
                <input matInput [(ngModel)]="respSearchTerm" (blur)="filtrar()" (keyup.enter)="filtrar()" />
                <button matSuffix mat-icon-button type="button" (click)="filtrar()" matTooltip="Filtrar">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <div class="row"
            [style.display]="grupoQuestao?.tipo!==typesService.PesquisaQuestaoType.Descritivo ? 'block' : 'none'">
            <div class="col-md-6">
              <div style="display: block;" *ngIf="grupoQuestaoItemId && !isBusy">
                <canvas #canvas height="200" baseChart [data]="chartData" [datasets]="chartDatasets"
                  [labels]="chartLabels" [colors]="chartColors" [chartType]="chartType" [options]="chartOptions"
                  [plugins]="chartPlugins" [legend]="chartLegend">
                </canvas>
              </div>
            </div>
            <div class="col-md-6">
              <div #tableOpcoes>
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th class="text-center">Opção</th>
                      <th class="text-center">Total</th>
                      <th class="text-center">Perc.</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="tableItens?.length != 0">
                    <tr *ngFor="let item of tableItens">
                      <td>{{item.opcao}}</td>
                      <td class="text-right">{{item.total}}</td>
                      <td class="text-right">{{item.percentual | currency:'BRL':''}} %</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan="3" class="text-center">Total Reg.: {{qtdTotal}}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="grupoQuestao?.tipo===typesService.PesquisaQuestaoType.Descritivo">
            <div class="col-md-12">
              <div #tableRespostas>
                <table class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th class="text-center">Respostas</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="tableRespItens?.length != 0">
                    <tr *ngFor="let resposta of tableRespItens">
                      <td>{{resposta}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-flat-button color="primary" type="button" [routerLink]="" (click)="print()" class="button-row">
            <span>Imprimir</span>
          </button>
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>