import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';

import { SolicitacaoLgpdType, StatusLgpdType } from 'projects/admin/src/domain/types';
import { RequisicaoLgpdModel } from '../../domain/models';
import { RequisicaoLgpdService } from '../../domain/services';

@Component({
  selector: 'app-requisicao-lgpd-modal',
  templateUrl: './requisicao-lgpd-modal.component.html',
})
export class RequisicaoLgpdModalComponent extends BaseCrudModalComponent<RequisicaoLgpdModel> implements OnInit {
  @ViewChild('requisicaoLgpdModal', { static: false }) modal: ModalDirective;

  tiposSolicitacoes = [
    { text: 'Verificar Dados', value: SolicitacaoLgpdType.VerificarDados },
    { text: 'Alterar/Consultar Dados', value: SolicitacaoLgpdType.AlterarConsultarDados },
    { text: 'Exclusão de Dados', value: SolicitacaoLgpdType.ExclusaoDados },
    { text: 'Outros', value: SolicitacaoLgpdType.Outros }
  ];

  statusPossiveis = [
    { text: 'Pendente', value: StatusLgpdType.Pendente },
    { text: 'Em Processo', value: StatusLgpdType.EmProcesso },
    { text: 'Finalizado', value: StatusLgpdType.Finalizado }
  ];

  cpfMask = '000.000.000-00';
  cpfPlaceholder = '000.000.000-00';

  constructor(
    injector: Injector,
    requisicaoLgpdService: RequisicaoLgpdService

  ) {
    super(injector, requisicaoLgpdService);
  }

  protected initializeForm(requisicao: RequisicaoLgpdModel) {
    this.form = this.formBuilder.group({
      id: requisicao.id,
      dataInclusao: [requisicao.dataInclusao, Validators.required],
      dataAlteracao: [requisicao.dataAlteracao],
      nome: [requisicao.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60)]
      ],
      email: [requisicao.email, [
        Validators.required,
        Validators.maxLength(200)]
      ],
      cpf: [requisicao.cpf, [
        Validators.maxLength(20)]
      ],
      tipoSolicitacao: [requisicao.tipoSolicitacao],
      mensagem: [requisicao.mensagem, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(200)]
      ],
      status: [requisicao.status],
      observacaoInterna: [requisicao.observacaoInterna, [
        Validators.maxLength(200)]
      ],
      data: [this.fromJsonDateTime(requisicao.data)],
      dataStatus: [this.fromJsonDateTime(requisicao.dataStatus)],
      telefone: [requisicao.telefone, [
        Validators.maxLength(16)]
      ],
    });
  }

  beforeShowCreate(model: RequisicaoLgpdModel): RequisicaoLgpdModel {
    model.status = StatusLgpdType.Pendente;
    model.data = new Date();
    model.dataStatus = new Date();
    return super.beforeShowCreate(model);
  }

  onChangeStatus() {
    this.form.patchValue({
      dataStatus: this.fromJsonDate(new Date())
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
