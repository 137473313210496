import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { CommonService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import Swal from 'sweetalert2';
import { EquipamentoModel } from '../../atendimento/domain/models';
import { EquipamentoService, ProcedimentoService } from '../../atendimento/domain/services';
import { ProfissionalSaudeService } from '../../equipe/domain/services';
import { ConvenioService } from '../../parceiro/domain/services/convenio.service';
import { DemonstrativoDetalheModel, DemonstrativoPrincipalModel } from '../domain/models';
import { RelatorioService } from '../domain/services';

@Component({
  selector: 'app-rel-demonstrativo-periodo',
  templateUrl: './rel-demonstrativo-periodo.component.html'
})
export class RelDemonstrativoPeriodoComponent extends BaseComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  isBusy: boolean;
  contentPrinted: boolean = false;

  titulo = 'Relatório Demonstrativo do Período';
  tituloModelo: string;
  tituloTipo: string;
  colunaApresentacao: string;
  dataAtual: string;
  dataInicial: string;
  dataFinal: string;
  modeloRelatorio: number;
  tipoRelatorio: number;
  listaDto: any[] = [];
  listaImpressao: any[] = [];

  totalGeral: number;
  qtdRegistros: number;

  listaModeloRelatorio = [
    { text: 'Convênio', value: 1 },
    { text: 'Equipamento', value: 2 },
    { text: 'Prof. Executante', value: 3 }
  ];

  listaTipoRelatorio = [
    { text: 'Sintético', value: 1 },
    { text: 'Analítico', value: 2 }
  ];

  listaConvenio: SelectDto[] = [];
  listaConvenioIdSelecionado: string[] = [];
  convenioTotalSelect: boolean;
  listaProfissional: SelectDto[] = [];
  listaProfissionalIdSelecionado: string[] = [];
  medicoTotalSelect: boolean;
  listaProcedimento: SelectDto[] = [];
  listaProcedimentoIdSelecionado: string[] = [];
  procedimentoTotalSelect: boolean;
  listaEquipamento: EquipamentoModel[] = [];
  listaEquipamentoIdSelecionado: string[] = [];
  equipamentoTotalSelect: boolean;

  constructor(
    injector: Injector,
    readonly service: RelatorioService,
    readonly printService: PrintService,
    readonly commonService: CommonService,
    readonly convenioService: ConvenioService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly equipamentoService: EquipamentoService,
    readonly procedimentoService: ProcedimentoService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.isAllowed()) {
      this.commonService.mensagem("", "Você não tem permissão de execução de relatórios!", "info");
    }

    this.dataAtual = moment().format('DD/MM/YYYY');
    this.dataInicial = this.fromJsonDate(new Date());
    this.dataFinal = this.fromJsonDate(new Date());
    this.modeloRelatorio = 1;
    this.tipoRelatorio = 1;

    this.convenioService.getSelectList().subscribe(x => this.listaConvenio = x);
    this.profissionalSaudeService.getSelectList().subscribe(x => this.listaProfissional = x);
    this.equipamentoService.getAll().subscribe(x => this.listaEquipamento = x);
    this.procedimentoService.getInternoSelectList().subscribe(x => this.listaProcedimento = x);
  }

  isAllowed(): boolean {
    return super.isAllowed("Read", "RelDemonstrativoPer");
  }

  getFilterDto(): any {
    const filterDto = {
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
      sintetico: this.tipoRelatorio == 1 ? true : false,
      modeloRelatorio: this.modeloRelatorio,
      convenioIds: [],
      equipamentoIds: [],
      profissionalIds: [],
      procedimentoIds: this.procedimentoTotalSelect ? [] : this.listaProcedimentoIdSelecionado
    }

    //Se item estiver com TotalSelect = true então não enviar lista de filtro (limpar)
    switch (this.modeloRelatorio) {
      case 1:
        filterDto.convenioIds = this.convenioTotalSelect ? [] : this.listaConvenioIdSelecionado;
        break;
      case 2:
        filterDto.equipamentoIds = this.equipamentoTotalSelect ? [] : this.listaEquipamentoIdSelecionado;
        break;
      case 3:
        filterDto.profissionalIds = this.medicoTotalSelect ? [] : this.listaProfissionalIdSelecionado;
        break;
    }

    return filterDto;
  }

  async print() {
    //Limpar o HTML
    this.listaImpressao = [];

    if (!this.commonService.validarDataInput(this.dataInicial)) {
      this.commonService.mensagem("Atenção", "Digite uma data inicial válida");
      return;
    }
    if (!this.commonService.validarDataInput(this.dataFinal)) {
      this.commonService.mensagem("Atenção", "Digite uma data final válida");
      return;
    }

    this.commonService.spinnerOpen("Filtrando Relatório...");
    this.isBusy = true;

    this.listaDto = await this.service.getDemonstrativo(this.getFilterDto()).toPromise();

    if (this.listaDto.length > 0){
      //Totalizadores
      this.qtdRegistros = this.listaDto.length;
      this.totalGeral = this.listaDto.map(x => x.vlrTotal).reduce((acumulador, atual) => {
        return acumulador + atual
      }, 0);
  
      this.commonService.spinnerClose();
      this.commonService.spinnerOpen("Preparando Impressão...");
  
      this.contentPrinted = false;
  
      this.tituloTipo = this.tipoRelatorio == 1 ? "Sintético" : "Analítico";
      switch (this.modeloRelatorio) {
        case 1:
        default:
          this.tituloModelo = "Convênios";
          this.colunaApresentacao = "Convênio";
          this.preparaRelatorio("convenioNome");
          break;
        case 2:
          this.tituloModelo = "Equipamentos";
          this.colunaApresentacao = "Equipamento";
          this.preparaRelatorio("equipamentoNome");
          break;
        case 3:
          this.tituloModelo = "Profissional Executante";
          this.colunaApresentacao = "Profissional";
          this.preparaRelatorio("executanteNome");
          break;
      }
    } else {
      this.commonService.spinnerClose();
      this.isBusy = false;
      this.commonService.mensagem("Não foram encontrados dados para esta seleção", "", "info");
    }

    // setTimeout(() => {
    //   const content = this.relatorio.nativeElement.innerHTML;
    //   this.printService.print(this.titulo, content);
    //   this.isBusy = false;
    //   this.commonService.spinnerClose();
    // }, 500);
  }

  onContentPrinted() {
    this.contentPrinted = true;
    const content = this.relatorio.nativeElement.innerHTML;
    this.printService.print(this.titulo, content);
    this.isBusy = false;
    this.commonService.spinnerClose();
  }

  preparaRelatorio(agrupador: string) {
    this.listaImpressao = [];
    let lista = this.commonService.groupBy(this.listaDto, agrupador);

    for (let prop in lista) {
      let obj = new DemonstrativoPrincipalModel();
      obj.nome = prop;
      obj.quantidade = lista[prop].length;
      obj.valorTotal = lista[prop].map(x => x.vlrTotal).reduce((acumulador, atual) => {
        return acumulador + atual
      }, 0);
      obj.percentualValor = Math.round((obj.valorTotal * 100 / this.totalGeral) * 100) / 100;
      obj.percentualQtd = Math.round((obj.quantidade * 100 / this.qtdRegistros) * 100) / 100;
      obj.itens = [];

      if (this.tipoRelatorio == 2) {
        //preparar grouby de procedimentos
        let listaItem = this.commonService.groupBy(lista[prop], "procedimentoNome");
        //preparar lista de procedimentos
        for (let propItem in listaItem) {
          let objItem = new DemonstrativoDetalheModel();
          objItem.procedimento = propItem;
          objItem.quantidade = listaItem[propItem].length;
          objItem.valorTotal = listaItem[propItem].map(x => x.vlrTotal).reduce((acumulador, atual) => {
            return acumulador + atual
          }, 0);
          objItem.percentualValor = Math.round((objItem.valorTotal * 100 / obj.valorTotal) * 100) / 100;
          objItem.percentualQtd = Math.round((objItem.quantidade * 100 / obj.quantidade) * 100) / 100;

          //add na lista principal
          obj.itens.push(objItem);
        }
      }

      this.listaImpressao.push(obj);
    }
  }

  selectAll(tipoObj: number) {
    switch (tipoObj) {
      case 1:
        this.listaConvenioIdSelecionado = [...this.listaConvenio.map(x => x.value)];
        this.convenioTotalSelect = true;
        break;
      case 2:
        this.listaProfissionalIdSelecionado = [...this.listaProfissional.map(x => x.value)];
        this.medicoTotalSelect = true;
        break;
      case 3:
        this.listaEquipamentoIdSelecionado = [...this.listaEquipamento.map(x => x.id)];
        this.equipamentoTotalSelect = true;
        break;
      case 4:
        this.listaProcedimentoIdSelecionado = [...this.listaProcedimento.map(x => x.value)];
        this.procedimentoTotalSelect = true;
        break;
    }
  }

  deSelectAll(tipoObj: number) {
    switch (tipoObj) {
      case 1:
        this.listaConvenioIdSelecionado = [];
        this.convenioTotalSelect = false;
        break;
      case 2:
        this.listaProfissionalIdSelecionado = [];
        this.medicoTotalSelect = false;
        break;
      case 3:
        this.listaEquipamentoIdSelecionado = [];
        this.equipamentoTotalSelect = false;
        break;
      case 4:
        this.listaProcedimentoIdSelecionado = [];
        this.procedimentoTotalSelect = false;
        break;
    }
  }

  onSelectionChange(tipoObj: number) {
    switch (tipoObj) {
      case 1:
        this.convenioTotalSelect = (this.listaConvenioIdSelecionado.length == this.listaConvenio.length);
        break;
      case 2:
        this.medicoTotalSelect = (this.listaProfissionalIdSelecionado.length == this.listaProfissional.length);
        break;
      case 3:
        this.equipamentoTotalSelect = (this.listaEquipamentoIdSelecionado.length == this.listaEquipamento.length);
        break;
      case 4:
        this.procedimentoTotalSelect = (this.listaProcedimentoIdSelecionado.length == this.listaProcedimento.length);
        break;
    }
  }
}

