import { OnInit, Injector, Directive } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch, EstadoModel, CidadeModel, BaseEntidadePessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';

@Directive()
export abstract class BaseEntidadePessoasComponent<TEntidadePessoaModel extends BaseEntidadePessoaModel> 
  extends BaseCrudComponent<TEntidadePessoaModel> implements OnInit {
  
  estados: EstadoModel[];
  cidades: CidadeModel[];

  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime);
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime);
  searchDataNascimento = new ModelSearch('pessoa.dataNascimento', OptionSearchType.Equals, SearchType.DateTime);
  searchEstado = new ModelSearch('pessoa.estadoId', OptionSearchType.Equals, SearchType.Guid);
  searchCidade = new ModelSearch('pessoa.cidadeId', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('pessoa.nomeFantasia', OptionSearchType.Contains, SearchType.String);

  protected readonly estadoService: EstadoService;
  private readonly cidadeService: CidadeService;

  selectColumns = [
    { data: 'pessoa.codigo' },
    { data: 'pessoa.nomeFantasia' },
    { data: 'pessoa.cnpjCpf' },
    { data: 'pessoa.dataNascimento' },
    { data: '(pessoa.cidade.nome + "/" + pessoa.estado.uf) as pessoaCidadeUf' }
  ];

  constructor(
    injector: Injector,
    readonly entidadePessoaService: BaseEntidadePessoaService<TEntidadePessoaModel>
  ) {
    super(injector, entidadePessoaService);
    this.estadoService = injector.get<EstadoService>(EstadoService);
    this.cidadeService = injector.get<CidadeService>(CidadeService);
  }

  ngOnInit() {
    super.ngOnInit();   
    this.dtOptions.order = [[0, 'desc']]; 
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchDataInicial.searchTerm) && searches.push(this.searchDataInicial);
    (this.searchDataFinal.searchTerm) && searches.push(this.searchDataFinal);
    (this.searchDataNascimento.searchTerm) && searches.push(this.searchDataNascimento);
    (this.searchEstado.searchTerm) && searches.push(this.searchEstado);
    (this.searchCidade.searchTerm) && searches.push(this.searchCidade);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  changeEstado(estadoId) {
    if (estadoId) {
      this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
    } else {
      this.cidades = [];
    }
  }
}
