import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from '../base-crud.service';

import { PaginaModel } from '../../models/controle-acesso/pagina-model';

@Injectable({
  providedIn: 'root'
})
export class PaginaService extends BaseCrudService<PaginaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Pagina';
  }

  get(): Observable<PaginaModel[]> {
    return this
      .httpClient
      .get<PaginaModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
