import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout1',
  templateUrl: './layout1.component.html',
  styleUrls: ['./layout1.component.scss']
})
export class Layout1Component implements OnInit {
  @Input() colorPrimary: string;
  @Input() colorSecondary: string;

  constructor() { }

  ngOnInit() {
  }

}
