<div bsModal #modal="bs-modal" class="modal fade" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <h2>Termos do Paciente - {{paciente?.pessoa?.nomeFantasia}}</h2>
      </div>

      <div class="card">
        <div class="header">
          <ul class="nav nav-tabs">
            <li class="active"><a class="nav-link active" id="nav-tab-enviados-tab" data-toggle="tab"
                href="#nav-tab-enviados" role="tab" aria-controls="nav-tab-enviados" aria-selected="true">
                <span style="display: flex; align-items: center;">
                  Termos Enviados
                </span>
              </a>
            </li>
            <li>
              <a class="nav-link" id="nav-tab-modelos-tab" data-toggle="tab" href="#nav-tab-modelos" role="tab"
                aria-controls="nav-tab-modelos" aria-selected="false">
                <span style="display: flex; align-items: center;">
                  Modelos
                </span>
              </a>
            </li>
          </ul>
        </div>


        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade in active" id="nav-tab-enviados" role="tabpanel"
            aria-labelledby="nav-tab-enviados-tab">

            <div class="body table-responsive">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-striped table-hover" style="width: 100%;">
                    <thead>
                      <tr>
                        <!-- todo: ajustar as datas na tabela -->
                        <th class="text-center" [width]="80">Data Envio</th>
                        <th class="text-center" [width]="80">Data Resposta</th>
                        <th class="text-center" [width]="150">Status Aceite</th>
                        <th class="text-center" [width]="100">Aceito</th>
                        <th class="text-center" [width]="100">Nome</th>
                        <th class="text-center" width="50"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="listaTermoPaciente?.length != 0">
                      <tr *ngFor="let item of listaTermoPaciente">
                        <td class="text-center">
                          {{item.dataEnvio | date: 'dd/MM/yyyy HH:mm'}}
                        </td>
                        <td class="text-center" *ngIf="item.dataResposta">
                          {{item.dataResposta | date: 'dd/MM/yyyy HH:mm'}}
                        </td>
                        <td class="text-center" *ngIf="!item.dataResposta">
                          Não respondido
                        </td>
                        <td class="text-center">
                          <span class="label" [ngClass]="typesService.getTermoStatusTypeColor(item.status)">
                            {{typesService.getTermoStatusTypeNome(item.status)}}
                          </span>
                        </td>
                        <td class="text-center">
                          <span class="label" [ngClass]="typesService.getBoolTypeColor(item.aceito)">
                            {{typesService.getBoolTypeNome(item.aceito)}}
                          </span>
                        </td>
                        <td>{{item.nome}}</td>
                        <td class="text-center">
                          <button mat-icon-button type="button" (click)="detalhesPacienteTermo.show(item.id)"
                            matTooltip="Gerenciar Termos">
                            <mat-icon>description</mat-icon>
                          </button>
                          <button mat-icon-button type="button" (click)="excluirPacienteTermo(item.id)"
                            matTooltip="Excluir Termo do Paciente">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade in" id="nav-tab-modelos" role="tabpanel" aria-labelledby="nav-tab-modelos-tab">


            <div class="body table-responsive">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-striped table-hover" style="width: 100%;">
                    <thead>
                      <tr>
                        <th class="text-center">Modelos de Termos</th>
                        <th class="text-center" width="120"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="listaTermos?.length != 0">
                      <tr *ngFor="let item of listaTermos">
                        <td>{{item.text}}</td>
                        <td class="text-center">
                          <button mat-icon-button type="button" (click)="enviarTermo(item.value)"
                            matTooltip="Enviar Termo por WhatsApp">
                            <mat-icon>send</mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-paciente-termo-detalhes-modal #detalhesPacienteTermo></app-paciente-termo-detalhes-modal>