import { NgModule } from '@angular/core';


import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AcoesComponent } from './acoes.component';
import { AcaoModalComponent } from './acoes-modal/acao-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AcoesComponent,
    AcaoModalComponent
  ]
})
export class AcoesModule { }
