import { Component, OnInit, Injector, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';

@Component({
    templateUrl: './sidebar-user-area.component.html',
    selector: 'sidebar-user-area',
    encapsulation: ViewEncapsulation.None
})
export class SideBarUserAreaComponent extends BaseComponent implements OnInit {

    shownLoginName = '';

    constructor(
        injector: Injector,
        // TODO: KleinCode
        //private _authService: AppAuthService
    ) {
        super(injector);
    }

    ngOnInit() {
        // TODO: KleinCode
        //this.shownLoginName = this.appSession.getShownLoginName();
    }

    logout(): void {
        // TODO: KleinCode
        //this._authService.logout();
    }
}
