import { Component, OnInit, ViewChild, Injector, Input, Output, EventEmitter } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AtendimentoReceitaModalComponent } from './atendimento-receita-modal/atendimento-receita-modal.component';
import { AtendimentoReceitaModel } from '../../domain/models';
import { AtendimentoReceitaService } from '../../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { AtendimentoReceitaHistoricoModalComponent } from './atendimento-receita-historico-modal/atendimento-receita-historico-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AtendimentoReceitaPrintComponent } from '../atendimento-print/atendimento-receita-print/atendimento-receita-print.component';
import { ReceitaType } from '../../domain/types';

@Component({
  selector: 'app-atendimento-receita',
  templateUrl: './atendimento-receita.component.html'
})
export class AtendimentoReceitaComponent extends BaseCrudComponent<AtendimentoReceitaModel> implements OnInit {
  @ViewChild('modal') modal: AtendimentoReceitaModalComponent;
  @ViewChild('modalHistorico') modalHistorico: AtendimentoReceitaHistoricoModalComponent;
  @ViewChild('receitaPrint') receitaPrint: AtendimentoReceitaPrintComponent;

  @Input('atendimentoId') atendimentoId: string;
  @Input('profissionalId') profissionalId: string;
  @Input('pacienteId') pacienteId: string;
  @Input('detailMode') detailMode: boolean;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  models: AtendimentoReceitaModel[] = [];

  constructor(
    injector: Injector,
    readonly service: AtendimentoReceitaService,
    readonly typesService: TypesService,
    readonly sanitizer: DomSanitizer
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.useDatatables = false;
    this.filter();
  }

  create(model: AtendimentoReceitaModel = null) {
    this.autoSave.emit();
    const newModel = new AtendimentoReceitaModel();
    newModel.seq = this.models.length > 0 ? Math.max(...this.models.map(x => x.seq)) + 1 : 1;
    if (model) {
      newModel.tipo = model.tipo;
      newModel.descricao = model.descricao;
    } else {
      newModel.tipo = ReceitaType.Normal;
    }
    super.create(newModel);
  }

  filter() {
    this.service.getByAtendimentoId(this.atendimentoId).subscribe(x => {
      this.models = x;
      this.models.forEach(item => {
        //injetando campo dinâmico para apresentação
        item['descricaoHtml'] = this.commonService.formatPlaintextToHtml(item.descricao);
      });
    });
  }

  showHistorico() {
    this.modalHistorico.show();
  }

  onCloneResponse(receita: AtendimentoReceitaModel) {
    if (receita) {
      this.create(receita);
    }
  }
}
