import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DocSusConfigModel } from '../domain/models';
import { DocSusConfigService } from '../domain/services/doc-sus.-config.service';
import { DocSusConfigModalComponent } from './doc-sus-config-modal/doc-sus-config-modal.component';

@Component({
  selector: 'app-doc-sus-config',
  templateUrl: './doc-sus-config.component.html'
})
export class DocSusConfigComponent extends BaseCrudComponent<DocSusConfigModel> implements OnInit {
  @ViewChild('configModal') modal: DocSusConfigModalComponent;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;

  docSusConfig: DocSusConfigModel;
  verifyPermissionRead = false;

  constructor(
    injector: Injector,
    readonly service: DocSusConfigService,
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.useDatatables = false;
    let id = this.commonService.getGuidSequencial("1");
    this.service.getById(id).subscribe(x => this.docSusConfig = x);
  }

  create(model: DocSusConfigModel = null) {
    let id = this.commonService.getGuidSequencial("1");
    this.service.getById(id).subscribe(x => {
      if (x.id) {
        this.commonService.mensagem("Já existe registro de configuração.", "Utilize a edição do registro atual para alterar configurações de SUS.", "info");
        return;
      } else {
        model = new DocSusConfigModel();
        model.id = id;
        super.create(model);
      }
    });
  }
}