<div bsModal #selectModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Selecione um Procedimento</h2>
      </div>
      <div class="modal-body">        
        <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Código</th>
              <th class="text-center">Nome</th>
              <th class="text-center">Grupo</th>
              <th class="text-center" width="100">Tipo</th>   
              <th class="text-center" width="100">Serviço</th>   
              <th class="text-center" width="50"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models"(click)="select(item.id)" style="cursor: pointer;">
              <td [innerHTML]="item.codigo"></td>
              <td [innerHTML]="item.nome"></td>
              <td [innerHTML]="item.grupoNome"></td>
              <td [innerHTML]="item.exame ? 'Exame' : 'Proced.'"></td>
              <td [innerHTML]="item.tipoServico ? 'Externo' : 'Interno'"></td>
              <td class="text-center">
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(item.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-procedimento-modal #modal (modalSave)="onResponse($event)"></app-procedimento-modal>