import { BaseEntidadePessoaModel } from "projects/ProjetoBaseAngular/domain/models";
import { BaseModel } from "projects/ProjetoBaseAngular/domain/models/base/base-model";
import { SelectDto } from "projects/ProjetoBaseAngular/domain/models/select-dto";

export class TipoParceiroModel extends BaseModel {
    public nome: string;
}

export class ParceiroTipoModel extends BaseModel {
  public parceiroId: string;
  public tipoParceiroId: string;
}

export class ParceiroModel extends BaseEntidadePessoaModel {
  public ordem: number;
  public nome: string;
  public tipos: ParceiroTipoModel[];
  public tipoIds: string[];
  public tipoNomes: string[];

  public telefone: string;
  public urlSite: string;

  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;

  public ruaAvenida: string;
  public numero: string;
  public complemento: string;
  public bairro: string;
  public cep: string;
  public estadoId: string;
  public estadoNome: string;
  public estadoUF: string;
  public cidadeId: string;
  public cidadeNome: string;
}

export class ConvenioModel extends BaseModel {
  public ordem: number;
  public nome: string;
  public telefone: string;
  public urlSite: string;

  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;

  public ruaAvenida: string;
  public numero: string;
  public complemento: string;
  public bairro: string;
  public cep: string;
  public estadoId: string;
  public estadoNome: string;
  public estadoUF: string;
  public cidadeId: string;
  public cidadeNome: string;
  public digitosMatricula: string;
  public matriculaObrigatoria: boolean;
  public permiteAlterarValor: boolean;  
  public codigo: number;  
}

export class ConvenioDto extends SelectDto {
  public ordem: number;
  public matriculaObrigatoria: boolean;
}

