import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { AtendimentoReceitaModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoReceitaService extends BaseCrudService<AtendimentoReceitaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'AtendimentoReceita';
  }  

  getByAtendimentoId(atendimentoId): Observable<AtendimentoReceitaModel[]> {
    return this.httpClient.get(`${this.getApiUrl()}${this.getController()}/GetByAtendimentoId/${atendimentoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByPacienteProfissionalId(pacienteId: string, profissionalId: string): Observable<AtendimentoReceitaModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByPacienteProfissionalId/${pacienteId}/${profissionalId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByPacienteId(pacienteId: string): Observable<any[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByPacienteId/${pacienteId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
