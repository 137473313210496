import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { SetorModel } from '../domain/models';
import { SetorModalComponent } from './setor-modal/setor-modal.component';
import { SetorService } from '../domain/services/setor.service';
import { DataTablesRequest, ModelSearch, UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-setor',
  templateUrl: './setor.component.html'
})
export class SetorComponent extends BaseCrudComponent<SetorModel> implements OnInit {
  @ViewChild('setorModal') modal: SetorModalComponent;

  usuarios: UsuarioModel[];

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'nome' },
    { data: 'usuarioResponsavel.nome' },
    { data: 'liberadoContatoExterno' },      
  ];

  constructor(
    injector: Injector,
    readonly setorService: SetorService,
    private usuarioService: UsuarioService
  ) {
    super(injector, setorService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();    
    this.usuarioService.getAll().subscribe(usuarios => this.usuarios = usuarios);
  }
}
