import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { GrupoPacienteModalComponent } from './grupo-paciente-modal/grupo-paciente-modal.component';
import { GrupoPacienteModel } from '../domain/models';
import { GrupoPacienteService } from '../domain/services';

@Component({
  selector: 'app-grupo-paciente',
  templateUrl: './grupo-paciente.component.html'
})
export class GrupoPacienteComponent extends BaseCrudComponent<GrupoPacienteModel> implements OnInit {
  @ViewChild('modal') modal: GrupoPacienteModalComponent;

  selectColumns = [ 
    { data: 'nome'},
  ];

  constructor(
    injector: Injector,
    readonly service: GrupoPacienteService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
