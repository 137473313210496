import { Injectable, Injector } from '@angular/core';
import { CargoModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CargoService extends BaseCrudService<CargoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'cargo';
  }

  getAsync(): Observable<CargoModel[]> {
    return this
      .httpClient
      .get<CargoModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getCargoDiferente(cargoId: string): Observable<CargoModel[]> {
    return this
      .httpClient
      .get<CargoModel>(`${this.getApiUrl()}${this.getController()}/GetCargoDiferente/${cargoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getBySetorId(setorId: string): Observable<CargoModel[]> {
    return this
      .httpClient
      .get<CargoModel>(`${this.getApiUrl()}${this.getController()}/GetBySetorId/${setorId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
