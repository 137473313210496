<div class="row clearfix">
  <div class="col-md-12" *ngIf="form">
    <form (ngSubmit)="saveConfig()" [formGroup]="form">

      <div class="card">
        <div class="header">
          <h2>ChatBot - Configuração</h2>
        </div>
        <div class="body">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Whats Conectado</mat-label>
                <mat-select [value]="usuarioWhatsConectado" disabled>
                  <mat-option *ngFor="let item of typesService.boolTypes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Whats Logado</mat-label>
                <mat-select [value]="usuarioWhatsLogado" disabled>
                  <mat-option *ngFor="let item of typesService.boolTypes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Whats Jid</mat-label>
                <input matInput [value]="usuarioWhatsJid" type="text" disabled />
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <button mat-flat-button type="button" (click)="sessionGetStatus()" class="button-row"
                style="margin-right: 10px;" color="primary">
                <span> Status da Sessão</span>
              </button>
              <button mat-flat-button type="button" (click)="sessionConnect()" class="button-row"
                style="margin-right: 10px;" color="primary" [disabled]="usuarioWhatsConectado">
                <span> Conectar Sessão </span>
              </button>
              <button mat-flat-button type="button" (click)="sessionGetQr()" class="button-row"
                style="margin-right: 10px;" color="primary" [disabled]="usuarioWhatsLogado">
                <span> Habilitar com QRcode </span>
              </button>
              <button mat-flat-button type="button" (click)="sessionPair()" class="button-row"
                style="margin-right: 10px;" color="primary" [disabled]="usuarioWhatsLogado">
                <span> Habilitar com Paircode </span>
              </button>
              <button mat-flat-button type="button" (click)="sessionDisconnect()" class="button-row"
                style="margin-right: 10px;" color="primary" [disabled]="!usuarioWhatsConectado">
                <span> Desconectar Sessão</span>
              </button>
              <button mat-flat-button type="button" (click)="sessionLogout()" class="button-row"
                style="margin-right: 10px;" color="primary" [disabled]="!usuarioWhatsConectado || !usuarioWhatsLogado!">
                <span> Deslogar Sessão</span>
              </button>
              <button mat-flat-button type="button" (click)="webHookGet()" class="button-row"
                style="margin-right: 10px;" color="primary" [disabled]="!usuarioWhatsConectado">
                <span> Conferir Webhook</span>
              </button>
              <button mat-flat-button type="button" (click)="webHookSet()" class="button-row"
                style="margin-right: 10px;" color="primary" [disabled]="!usuarioWhatsConectado">
                <span> Setar Webhook</span>
              </button>
              
              <!-- <button mat-flat-button type="button" (click)="enviarMensagemTeste()" class="button-row" color="primary">
                <span>Testar Mensagem</span>
              </button> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Erros de Cliente => Transf.Atendente</mat-label>
                <input matInput formControlName="qtdErros" type="number" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Mensagem Cancelamento pelo Cliente</mat-label>
                <textarea matInput formControlName="msgCancCliente" rows="3"></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Mensagem Transferência para Atendente</mat-label>
                <textarea matInput formControlName="msgTransfAtendente" rows="3"></textarea>
              </mat-form-field>
            </div>
          </div>
          <hr>
          <div class="row text-right">
            <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="saveConfig()"
              color="primary" class="button-row">
              <span> Salvar</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>ChatBot - Fluxo Etapas de Atendimento</h2>
      </div>
      <div class="body table-responsive">
        <!--query end-->
        <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center" width="70">Tipo Fluxo</th>
              <th class="text-center" width="100">Máscara</th>
              <th class="text-center">Nome</th>
              <th class="text-center" width="70">Tipo</th>
              <th class="text-center" width="200">Tipo Atendimento</th>
              <th class="text-center" width="200">Tipo Campo</th>
              <th class="text-center" width="100">Próxima Etapa</th>
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let model of models">
              <td>
                <span class="label" [ngClass]="typesService.getBotFluxoTypeColor(model.tipoFluxo)">
                  {{typesService.getBotFluxoTypeNome(model.tipoFluxo)}}
                </span>
              </td>
              <td>
                {{model.mascara}}
              </td>
              <td>
                {{model.nome}}
              </td>
              <td>
                <span class="label" [ngClass]="typesService.getBotNodoTypeColor(model.tipo)">
                  {{typesService.getBotNodoTypeNome(model.tipo)}}
                </span>
              </td>
              <td>
                <span class="label" [ngClass]="typesService.getBotAtendimentoTypeColor(model.tipoAtendimento)">
                  {{typesService.getBotAtendimentoTypeNome(model.tipoAtendimento)}}
                </span>
              </td>
              <td>
                {{typesService.getWhatsChatMsgCampoTypeNome(model.tipoCampo)}}
              </td>
              <td>
                {{model.proxNodo || "Não possuí"}}
              </td>
              <td class="text-center">
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(model)"
                  matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Read')" mat-icon-button type="button" (click)="detail(model)"
                  matTooltip="Detalhes">
                  <mat-icon>description</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(model.id)"
                  matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fixed-action-btn">
        <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
          matTooltipPosition="above">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<app-chatbot-nodo-modal #modal (modalSave)="onResponse($event)"></app-chatbot-nodo-modal>