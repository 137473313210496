import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ProfissionalSaudeTextoPadraoModalComponent } from './profissional-saude-texto-padrao-modal/profissional-saude-texto-padrao-modal.component';
import { ProfissionalSaudeTextoPadraoModel } from '../domain/models';
import { ProfissionalSaudeTextoPadraoService } from '../domain/services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-profissional-saude-texto-padrao',
  templateUrl: './profissional-saude-texto-padrao.component.html'
})
export class ProfissionalSaudeTextoPadraoComponent extends BaseCrudComponent<ProfissionalSaudeTextoPadraoModel> implements OnInit {
  @ViewChild('modal') modal: ProfissionalSaudeTextoPadraoModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  profissionalId: string;
  profissionalNome: string;
  selectColumns = [     
    { data: 'texto'},
  ];

  constructor(
    injector: Injector,
    readonly service: ProfissionalSaudeTextoPadraoService
  ) {
    super(injector, service);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.profissionalId) {
      dataTablesParameters.searches.push(new ModelSearch('profissionalSaudeId', OptionSearchType.Equals, SearchType.Guid, this.profissionalId));
    }
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();  
    
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = ''; 
  }

  show(recursoId, recursoNome) {
    this.profissionalId = recursoId;
    this.profissionalNome = recursoNome;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();
    this.modalClose.emit();
  }

  create() {
    const model = new ProfissionalSaudeTextoPadraoModel();
    model.dataInclusao = new Date();
    model.id = this.commonService.newGuid();
    model.profissionalSaudeId = this.profissionalId;
    super.create(model);
  }
}
