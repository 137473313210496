<div bsModal #empresaModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dadosEmpresaModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form && tenant">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Minha Empresa</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2">
              <div class="form-group align-center">
                <div style="height: 150px; width: 100%; object-fit: contain;">
                  <img src="{{form.value.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                    *ngIf="form.value.imagemUrl">
                </div>
                <div class="align-center">
                  <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                    <mat-icon>add_photo_alternate</mat-icon>
                  </button>
                  <button mat-icon-button type="button" [disabled]="!form.value.imagemUrl" (click)="deselectImage()"
                    matTooltip="Desvincular Imagem">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <h4 class="card-inside-title">Dados Principais</h4>
              <div class="row">
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>Tipo Pessoa</mat-label>
                    <mat-select [value]="tenant.tipoPessoa" disabled>
                      <mat-option *ngFor="let tipo of tipos" [value]="tipo.value">
                        {{tipo.text}}
                      </mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label *ngIf="tenant.tipoPessoa == pessoaJuridica">CNPJ
                    </mat-label>
                    <mat-label *ngIf="tenant.tipoPessoa == pessoaFisica">CPF</mat-label>
                    <input matInput [value]="tenant.cnpjCpf" type="text"  disabled/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label *ngIf="tenant.tipoPessoa == pessoaJuridica">IE
                    </mat-label>
                    <mat-label *ngIf="tenant.tipoPessoa == pessoaFisica">RG</mat-label>
                    <input matInput [value]="tenant.iestadualRg" type="text" disabled/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="tenant.tipoPessoa == pessoaJuridica">
                  <mat-form-field>
                    <mat-label>Insc. Municipal</mat-label>
                    <input matInput [value]="tenant.imunicipal" type="text" disabled/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3" *ngIf="tenant.tipoPessoa == pessoaJuridica">
                  <mat-form-field>
                    <mat-label>Razão Social</mat-label>
                    <input matInput value="{{tenant.razaoSocial}}" type="text" disabled/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Nome</mat-label>
                    <input matInput value="{{tenant.nomeFantasia}}" type="text" disabled/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>Subdomínio</mat-label>
                    <input matInput value="{{tenant.subdominio}}" type="text" disabled/>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
              </div>
              <h4 class="card-inside-title">Dados para Contato e Cobrança</h4>
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Telefone</mat-label>
                    <input matInput formControlName="telefone" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 0000-0000" placeholder="Ex: (48) 3333-3333" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Celular</mat-label>
                    <input matInput formControlName="celular" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>E-mail</mat-label>
                    <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="pessoa-juridica">
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Site</mat-label>
                      <input matInput formControlName="site" type="url" />
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 class="card-inside-title">Dados de endereço</h4>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>CEP</mat-label>
                <input matInput formControlName="cep" type="text" [dropSpecialCharacters]="false" mask="00.000-000"
                  placeholder="Ex: 00.000-000" (blur)="changeCep($event.target.value)" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Rua / Avenida</mat-label>
                <input matInput formControlName="ruaAvenida" type="text" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Número</mat-label>
                <input matInput formControlName="numero" type="text" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Complemento</mat-label>
                <input matInput formControlName="complemento" type="text" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Bairro</mat-label>
                <input matInput formControlName="bairro" type="text" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Estado</mat-label>
                <mat-select formControlName="estadoId" (selectionChange)="changeEstado($event.value)">
                  <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.uf}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Cidade</mat-label>
                <mat-select formControlName="cidadeId">
                  <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">
                    {{cidade.nome}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>

        </div>
        <div class="footer">
          <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
            <span> Salvar</span>
          </button>
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Cancelar</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>