<div class="row clearfix">
  <div class="col-md-12">
      <div class="card">
          <div class="header">
              <h2>Estados</h2>              
          </div>
          <div class="body table-responsive">              
              <!--query end-->
              <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover" style="width: 100%;">
                <thead>
                  <tr>
                    <th class="text-center">IBGE</th>
                    <th class="text-center">UF</th>
                    <th class="text-center">Nome</th>
                    <th class="text-center" width="150"></th>
                  </tr>
                </thead>
                <tbody *ngIf="models?.length != 0">
                  <tr *ngFor="let estado of models">
                    <td>
                      {{estado.codigoIbge}}
                    </td>
                    <td>
                      {{estado.uf}}
                    </td>
                    <td>
                      {{estado.nome}}
                    </td>
                    <td class="text-center">
                      <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(estado)" matTooltip="Editar">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(estado.id)" matTooltip="Excluir">
                        <mat-icon>delete</mat-icon>
                      </button>                      
                    </td>
                  </tr>
                </tbody>
              </table>              
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
                <mat-icon>add</mat-icon>
            </button>            
        </div>
      </div>
  </div>
</div>
<app-estado-modal #estadoModal (modalSave)="onResponse($event)"></app-estado-modal>
