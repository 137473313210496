import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ReportComponent } from './report.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { ReportFooterComponent } from './report-footer/report-footer.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ReportComponent,
    ReportHeaderComponent,
    ReportFooterComponent
  ],
  exports: [
    ReportHeaderComponent,
    ReportFooterComponent
  ]
})
export class ReportModule { }
