import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AgendaBloqueioModule } from './agenda-bloqueio/agenda-bloqueio.module';
import { AgendaDisponibilidadeModule } from './agenda-disponibilidade/agenda-disponibilidade.module';
import { SalaAtendimentoModule } from './sala-atendimento/sala-atendimento.module';
import { AgendamentoModule } from './agendamento/agendamento.module';
import { AtendimentoModule } from './atendimento/atendimento.module';
import { ClassificacaoAgendamentoModule } from './classificacao-agendamento/classificacao-agendamento.module';
import { GrupoProcedimentoModule } from './grupo-procedimento/grupo-procedimento.module';
import { MedicamentoModule } from './medicamento/medicamento.module';
import { ProcedimentoModule } from './procedimento/procedimento.module';
import { GrupoAnamneseModule } from './grupo-anamnese/grupo-anamnese.module';
import { QuestAnamneseModule } from './quest-anamnese/quest-anamnese.module';
import { PerfilAnamneseModule } from './perfil-anamnese/perfil-anamnese.module';
import { DocSusModule } from './doc-sus/doc-sus.module';
import { AgendaEsperaModule } from './agenda-espera/agenda-espera.module';
import { LayoutLaudoModule } from './layout-laudo/layout-laudo.module';
import { LayoutImpressaoModule } from './layout-impressao/layout-impressao.module';
import { LaudoModule } from './laudo/laudo.module';
import { EquipamentoModule } from './equipamento/equipamento.module';
import { LayoutMensagemModule } from './layout-mensagem/layout-mensagem.module';
import { ProtocoloDocModule } from './protocolo-doc/protocolo-doc.module';
import { EntregaExameModule } from './entrega-exame/entrega-exame.module';
import { DocSusConfigModule } from './doc-sus-config/doc-sus-config.module';
import { TermoModule } from './termo/termo.module';

@NgModule({
  imports: [
    SharedModule,
    AgendaBloqueioModule,
    AgendaDisponibilidadeModule,
    SalaAtendimentoModule,
    AgendamentoModule,
    AtendimentoModule,
    ClassificacaoAgendamentoModule,
    GrupoProcedimentoModule,
    MedicamentoModule,
    ProcedimentoModule,
    GrupoAnamneseModule,
    QuestAnamneseModule,
    PerfilAnamneseModule,
    DocSusModule,
    DocSusConfigModule,
    AgendaEsperaModule,
    LayoutLaudoModule,
    LayoutImpressaoModule,
    LaudoModule,
    EquipamentoModule,
    LayoutMensagemModule,
    ProtocoloDocModule,
    EntregaExameModule,
    TermoModule  
  ]
})
export class AtendimentoGroupModule { }
