import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ContatoPreAgendamentoModel } from '../../domain/models';
import { ContatoPreAgendamentoService } from '../../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { ContatoPreAgendamentoStatusType, TurnoType } from '../../domain/types';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ProfissionalSaudeDto } from '../../../equipe/domain/models';

@Component({
  selector: 'app-contato-pre-agendamento-modal',
  templateUrl: './contato-pre-agendamento-modal.component.html'
})
export class ContatoPreAgendamentoModalComponent extends BaseCrudModalComponent<ContatoPreAgendamentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  listaEspecialidadeDto: SelectDto[] = [];
  listaServicoDto: SelectDto[] = [];
  listaConvenioDto: SelectDto[] = [];
  listaProfissionalDto: ProfissionalSaudeDto[] = [];
  listaFiltroProfissionalDto: ProfissionalSaudeDto[] = [];

  constructor(
    injector: Injector,
    readonly service: ContatoPreAgendamentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.service.getEspecialidadeSelectList().subscribe(lista => this.listaEspecialidadeDto = lista);
    this.service.getServicoSelectList().subscribe(lista => this.listaServicoDto = lista);
    this.service.getProfissionalSelectList().subscribe(lista => this.listaProfissionalDto = lista);
    this.service.getConvenioSelectList().subscribe(lista => this.listaConvenioDto = lista);
  }

  protected initializeForm(model: ContatoPreAgendamentoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: [this.fromJsonDate(model.dataAlteracao)],

      nome: [model.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60)]
      ],
      email: [model.email, Validators.maxLength(100)],
      celular: [model.celular, [
        Validators.required,
        Validators.maxLength(16)]
      ],
      status: [model.status],
      dataStatus: [this.fromJsonDate(model.dataStatus)],
      observacao: [model.observacao, Validators.maxLength(200)],
      observacaoInterna: [model.observacaoInterna, Validators.maxLength(200)],
      convenioId: [model.convenioId],
      consultaId: [model.consultaId],
      exameId1: [model.exameId1],
      exameId2: [model.exameId2],
      profissionalSaudeId: [model.profissionalSaudeId],
      turno: [model.turno, Validators.required]
    });
  }


  newModel(): ContatoPreAgendamentoModel {
    const model = new ContatoPreAgendamentoModel();
    model.status = ContatoPreAgendamentoStatusType.Pendente;
    model.dataStatus = new Date();
    model.consultaId = "";
    model.exameId1 = "";
    model.exameId2 = "";
    model.convenioId = "";
    model.profissionalSaudeId = "";
    model.turno = TurnoType.Matutino;
    return model;
  }
  
  onChangeStatus() {
    this.form.patchValue({
      dataStatus: this.fromJsonDate(new Date())
    });
  }

  beforeShowCreate(model: ContatoPreAgendamentoModel = null): ContatoPreAgendamentoModel {
    this.aplicarFiltro("");
    return super.beforeShowCreate(model);
  }

  beforeShowEdit(model: ContatoPreAgendamentoModel): ContatoPreAgendamentoModel {
    this.aplicarFiltro(model.consultaId);
    return super.beforeShowEdit(model);
  }

  onChangeEspecialidade(especialidadeId: string) {
    this.aplicarFiltro(especialidadeId);
    this.form.patchValue({
      profissionalSaudeId: ""
    });
  }

  aplicarFiltro(especialidadeId: string) {
    if (especialidadeId) {
      this.listaFiltroProfissionalDto = this.listaProfissionalDto.filter(x => x.especialidadeId == especialidadeId);
    } else {
      this.listaFiltroProfissionalDto = [...this.listaProfissionalDto];
    }
  }
}
