<div bsModal #arquivoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="arquivoModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-lg">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Arquivo</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>Nome do Arquivo</mat-label>
								<input matInput formControlName="nome" type="text" #focus
									(blur)="slugify($event.target.value)" />
								<mat-error>Informe um Nome válido.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>Descrição</mat-label>
								<input matInput formControlName="descricao" type="text" />
								<mat-error>Informe uma Descrição válida.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-12">
							<p class="font-15 col-deep-orange text-center">
								O tamanho máximo permitido do arquivo é de <strong>50MB</strong>
							</p>
							<div class="form-group">
								<input #fileUpload formControlName="file" type="file" />

								<mat-progress-bar mode="indeterminate" *ngIf="file.inProgress"></mat-progress-bar>
								<div class="alert alert-success" role="alert" *ngIf="file.completed" i18n>Arquivo salvo
									com sucesso!</div>
							</div>
						</div>
						<div class="col-md-12" *ngIf="!newItem">
							<mat-form-field>
								<mat-label>Url Arquivo</mat-label>
								<input matInput type="url" disabled value="{{form.value.url}}" />
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button  [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
