import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from '../base-crud.service';
import { ArquivoModel } from '../../models/galeria/arquivo-model';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService extends BaseCrudService<ArquivoModel> {
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Arquivo';
  }

  upload(id: string, nome: string, descricao: string, formData: FormData): Observable<any> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Upload`, formData, {
        headers: this.getAuthHeader().headers,
        params: { id : id || '', nome, descricao }
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
