import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';
import { CurriculoModel } from '../models';


@Injectable({
  providedIn: 'root'
})
export class CurriculoService extends BaseEntidadePessoaService<CurriculoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'curriculo';
  }

  get(): Observable<CurriculoModel[]> {
    return this
      .httpClient
      .get<CurriculoModel[]>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByCpf(cpf): Observable<CurriculoModel[]> {
    return this
      .httpClient
      .get<CurriculoModel[]>(`${this.getApiUrl()}${this.getController()}/GetByCpf/${cpf}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByVagaId(vagaId: string): Observable<CurriculoModel[]> {
    return this
      .httpClient
      .get<CurriculoModel>(`${this.getApiUrl()}${this.getController()}/GetByVagaId/${vagaId}`,
        this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  upload(id: string, slug: string, formData: FormData): Observable<any> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Upload`, formData, {
      headers: this.getAuthHeader().headers,
      params: { id: id || '', slug }
    })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getMsgAceiteCurriculo(): Observable<string> {
    return this
      .httpClient
      .get<string>(`${this.getApiUrl()}${this.getController()}/GetMsgAceiteCurriculo`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  send(model: CurriculoModel): Observable<CurriculoModel> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Send`, model, this.getHeader())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
