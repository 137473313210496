import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { BaseEntidadePessoaSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoa-select-modal.component';
import { PacienteModel } from '../../domain/models';
import { PacienteService } from '../../domain/services';
import { PacienteModalComponent } from '../paciente-modal/paciente-modal.component';

@Component({
  selector: 'app-paciente-select-modal',
  templateUrl: './paciente-select-modal.component.html'
})
export class PacienteSelectModalComponent extends BaseEntidadePessoaSelectModalComponent<PacienteModel> implements OnInit {
  @ViewChild('pacienteModal', { static: false }) modal: PacienteModalComponent;  

  constructor(
    injector: Injector,
    readonly pacienteService: PacienteService
  ) {
    super(injector, pacienteService);
  }  

  ngOnInit() {    
    super.ngOnInit();    
    this.dtOptions.serverSide = false;
  }

  filter() {    
    this.dtOptions.serverSide = true;
    super.filter();
  }
}
