<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <h2>Resultado de Exame</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Procedimento/Exame</mat-label>
                <mat-select formControlName="procedimentoId">
                  <mat-option *ngFor="let item of listaProcedimento" [value]="item.value" required>
                    {{item.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <button mat-flat-button color="primary" type="button" (click)="createItem()">Adicionar</button>
                <table class="table table-bordered table-striped table-hover dataTable"
                  style="width: 100%; max-height: 500px; overflow-y: scroll; display:inline-block;">
                  <thead>
                    <tr>
                      <th class="text-center">Data Ref.</th>
                      <th class="text-center">Resultado</th>
                      <th class="text-center" width="120"></th>
                    </tr>
                  </thead>
                  <tbody *ngIf="listaItem?.length != 0">
                    <tr *ngFor="let item of listaItem">
                      <td>
                        {{item.dataReferencia | date: 'dd/MM/yyyy'}}
                      </td>
                      <td class="text-right">
                        {{item.resultado | currency:'':''}}
                      </td>
                      <td>
                        <button mat-icon-button type="button" (click)="editItem(item)" matTooltip="Editar">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button type="button" (click)="deleteItem(item.id)" matTooltip="Excluir">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-paciente-exame-indicador-item-modal #itemModal (modalSave)="onResponseItem($event)"></app-paciente-exame-indicador-item-modal>