<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
	[config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-xl">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Grupo de Questões</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<div class="row">
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Ordem</mat-label>
								<input matInput formControlName="ordem" type="text" required #focus />
								<mat-error>Informe uma Ordem válida</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Filtro</mat-label>
								<mat-select formControlName="filtroId">
									<mat-option *ngFor="let filtro of listaFiltros" [value]="filtro.id">
										{{filtro.opcao}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Título</mat-label>
								<input matInput formControlName="titulo" maxlength="100" required />
								<mat-error>Informe um Título válido.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Tipo</mat-label>
								<mat-select formControlName="tipo">
									<mat-option *ngFor="let tipo of typesService.pesquisaQuestaoType"
										[value]="tipo.value">
										{{tipo.text}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Usa Pontuação</mat-label>
								<mat-select formControlName="usaPontuacao">
									<mat-option *ngFor="let item of typesService.boolTypes" [value]="item.value">
										{{item.text}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<mat-tab-group animationDuration="0ms">
						<mat-tab label="Questões">
							<ng-template matTabContent>
								<app-grupo-questao-item #grupoQuestaoItem [grupoQuestaoId]="form.value.id"
									(autoSave)="save(true)">
								</app-grupo-questao-item>
							</ng-template>
						</mat-tab>
						<mat-tab label="Opções" *ngIf="form.value.tipo !== pesquisaQuestaoType.Descritivo">
							<ng-template matTabContent>
								<app-grupo-questao-opcao #grupoQuestaoOpcao [grupoQuestaoId]="form.value.id"
									[usaPontuacao]="form.value.usaPontuacao" (autoSave)="save(true)">
								</app-grupo-questao-opcao>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
				</div>
			</form>
			<div class="footer">
				<mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
					Continuar Incluindo
				</mat-slide-toggle>
				<button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()"
					color="primary" class="button-row">
					<span> Salvar</span>
				</button>
				<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
					<span> Cancelar</span>
				</button>
			</div>
		</div>
	</div>
</div>