import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TypesService } from 'projects/admin/src/domain/services';

import { CidadeModel, TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { CidadeService, CommonService, TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProfissionalSaudeModel } from '../../../equipe/domain/models';
import { ProfissionalSaudeService } from '../../../equipe/domain/services';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { DocSusConfigModel, DocSusModel } from '../../domain/models';
import { DocSusService } from '../../domain/services';
import { DocSusConfigService } from '../../domain/services/doc-sus.-config.service';
import { CaracterAtendimentoType } from '../../domain/types';

@Component({
  selector: 'app-doc-sus-tfd-print',
  templateUrl: './doc-sus-tfd-print.component.html'
})
export class DocSusTfdPrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  tenant: TenantModel;
  docSus: DocSusModel;
  docSusConfig: DocSusConfigModel;
  paciente: PacienteModel;
  profissionalSolicitante: ProfissionalSaudeModel;
  cidade: CidadeModel;
  endereco: string;

  caracterAtendimentoType: typeof CaracterAtendimentoType = CaracterAtendimentoType;

  constructor(
    readonly tenantService: TenantService,
    readonly docSusService: DocSusService,
    readonly docSusConfigService: DocSusConfigService,
    readonly pacienteService: PacienteService,
    readonly cidadeService: CidadeService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly printService: PrintService,
    readonly typesService: TypesService,
    readonly commonService: CommonService
  ) { }

  ngOnInit() {
    
  }

  async print(docSusId: string) {
    //this.tenant = await this.tenantService.getCurrentTenant().toPromise();
    let docSusConfigId = this.commonService.getGuidSequencial("1");
    this.docSusConfig = await this.docSusConfigService.getById(docSusConfigId).toPromise();
    this.docSus = await this.docSusService.getById(docSusId).toPromise();
    
    this.profissionalSolicitante = await this.profissionalSaudeService.getById(this.docSus.profissionalSolicitanteId).toPromise();
    this.paciente = await this.pacienteService.getById(this.docSus.pacienteId).toPromise();
    
    //this.cidade = await this.cidadeService.getById(this.tenant.cidadeId).toPromise();

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Documento dos SUS', content);
    }, 500);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }

  get idadePaciente() {
    if (!this.paciente?.pessoa.dataNascimento) {
      return '';
    }

    return this.commonService.getIdadeAnos(this.paciente.pessoa.dataNascimento);
  }
}

