<div bsModal #paginaModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="paginaModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Paginas</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">

            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Nome de Permissão</mat-label>
                <input matInput formControlName="permissionName" type="text" #focus />
                <mat-error>Informe um Nome de Permissão válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" />
                <mat-hint align="end">Máx 50 caracteres</mat-hint>
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Descrição</mat-label>
                <textarea matInput #descricao formControlName="descricao" rows="1" maxlength="500"></textarea>
                <mat-hint align="end">{{descricao.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>HelpUrl</mat-label>
                <input matInput formControlName="helpUrl" type="text" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe uma Url válida.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <div class="card" *ngIf="paginaAcoes?.length != 0">

                <table class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead class="thead-dark">
                    <tr>
                      <th class="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pagina of paginaAcoes">
                      <td>
                        {{pagina.acaoNome}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
