import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { CapturaImagemModel, CapturaModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { CapturaService } from '../../domain/services/captura.service';
import { AtendimentoService, CapturaImagemService, ProcedimentoService } from '../../domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { CapturaStatusType } from '../../domain/types';

@Component({
  selector: 'app-captura-modal',
  templateUrl: './captura-modal.component.html'
})
export class CapturaModalComponent extends BaseCrudModalComponent<CapturaModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  atendimento: any;
  procedimentos: SelectDto[];

  width = 0;    // We will scale the photo width to this
  height = 0;     // This will be computed based on the input stream
  streaming = false;
  video = null;
  canvas = null;
  imagens: CapturaImagemModel[] = [];
  localStream = null;
  arquivosBase64: string[];

  procedimentoApresentacao: string = "";


  constructor(
    injector: Injector,
    service: CapturaService,
    readonly atendimentoService: AtendimentoService,
    readonly procedimentoService: ProcedimentoService,
    readonly capturaImagemService: CapturaImagemService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.procedimentoService.getSelectList().subscribe(x => this.procedimentos = x);
  }

  protected initializeForm(model: CapturaModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      status: [model.status],
      atendimentoId: [model.atendimentoId, Validators.required],
      pacienteId: [model.pacienteId, Validators.required],
      equipamentoId: [model.equipamentoId, Validators.required]
    });

    this.popularAtendimento(model.atendimentoId);

    this.capturaImagemService.getByCapturaId(model.id).subscribe(x => this.imagens = x);
    this.streaming = false;
  }

  popularAtendimento(atendimentoId: string) {
    const selectColumns = [
      { data: 'codigoDocMovto' },
      { data: 'paciente.pessoa.CnpjCpf as pacienteCnpjCpf' },
      { data: 'paciente.pessoa.nomeFantasia as pacienteNome' },
      { data: 'paciente.pessoa.codigo as pacienteCodigo' },
      { data: 'convenio.nome as convenioNome' },
      { data: 'procedimentos' }
    ];
    this.atendimentoService.getSelectById(atendimentoId, selectColumns).subscribe(x => {
      this.atendimento = x;

      let primeiro = true;
      this.atendimento.procedimentos.forEach(atendProcs => {
        if (primeiro) {
          this.procedimentoApresentacao = this.procedimentos.find(x => x.value === atendProcs.procedimentoId).text;
          primeiro = false;
        } else {
          this.procedimentoApresentacao += " / " + this.procedimentos.find(x => x.value === atendProcs.procedimentoId).text;
        }
      });
    });
  }

  startupCamera() {
    this.video = document.getElementById('video');
    this.canvas = document.getElementById('canvas');

    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then((stream) => {
        this.localStream = stream;
        this.video.srcObject = stream;
        this.video.play();
      })
      .catch((err) => {
        this.commonService.mensagem('Nenhum dispositivo encontrado!', '', 'warning');
        console.log("An error occurred: " + err);
      });

    this.video.addEventListener('canplay', () => {
      if (!this.streaming) {
        this.width = this.video.videoWidth;
        this.height = this.video.videoHeight;

        this.streaming = true;
      }
    }, false);
  }

  // Capture a photo by fetching the current contents of the video
  // and drawing it into a canvas, then converting that to a PNG
  // format data URL. By drawing it on an offscreen canvas and then
  // drawing that to the screen, we can change its size and/or apply
  // other changes before drawing it.

  takePicture() {
    var context = this.canvas.getContext('2d');
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    context.drawImage(this.video, 0, 0, this.width, this.height);

    const data = this.canvas.toDataURL('image/png');
    this.savePicture([data]);
  }

  savePicture(imagens: string[]) {
    let buttons: string = '';
    this.atendimento.procedimentos.forEach(atendimentoProcedimento => {
      const procedimento = this.procedimentos.find(x => x.value === atendimentoProcedimento.procedimentoId);
      buttons += `<button id="procedimento${atendimentoProcedimento.procedimentoId}" type="button" class="swal2-confirm swal2-styled">${procedimento.text}</button>`;
    });

    let strHtml = "";
    imagens.forEach(imagem => {
      strHtml += `<div class="col-md-4" style="margin-bottom: 5px"><img src="${imagem}" style="width: 100%;"/></div>`;
    });

    Swal.fire({
      title: 'Confirmar Imagem?',
      html:
        `<div class="row">${strHtml}</div><br/><h5 class="text-center">Selecione um Procedimento abaixo: *</h5><br/>${buttons}`,
      focusCancel: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Fechar',
      didOpen: () => {
        this.atendimento.procedimentos.forEach(atendimentoProcedimento => {
          const procedimentoId = atendimentoProcedimento.procedimentoId;
          document.getElementById(`procedimento${procedimentoId}`)
            .addEventListener('click', () =>
              Swal.close({ isConfirmed: true, isDismissed: false, isDenied: false, value: procedimentoId })
            );
        });
      }
    }).then(result => {
      if (result.value) {
        this.commonService.spinnerOpen("Gravando imagens...");
        let contadorIndex = 0;
        let contadorSequencia = 0;
        imagens.forEach(imagemBase64 => {
          const imagem = new CapturaImagemModel();
          imagem.id = this.commonService.newGuid();
          imagem.dataInclusao = new Date();
          imagem.capturaId = this.form.value.id;
          imagem.procedimentoId = result.value;
          imagem.sequencia = contadorSequencia++;

          imagem.imagemBase64 = imagemBase64.split(';base64,')[1];

          this.capturaImagemService.create(imagem).subscribe(imagem => {
            this.imagens.push(imagem);
            contadorIndex++;
            if (contadorIndex >= imagens.length){
              this.commonService.spinnerClose();
            }
          },
          erros => {
            console.log(erros);
            this.commonService.spinnerClose();
          });
        });
      }
    });
  }

  async excluirImagem(imagem: CapturaImagemModel) {
    await this.capturaImagemService.delete(imagem.id).toPromise();
    //Remover da lista
    let index = this.imagens.indexOf(imagem);
    this.imagens.splice(index, 1);
  }

  moverDireita(imagemId: string, index: number) {
    let imagemMovida = this.imagens.find(x => x.id === imagemId);
    let indexImagemMovida = this.imagens.findIndex(x => x.id === imagemId);
    let imagemDireita = this.imagens[indexImagemMovida + 1];

    this.imagens[index] = imagemDireita;
    this.imagens[index + 1] = imagemMovida;

    for (let i = 0; i < this.imagens.length; i++) {
      this.imagens[i].sequencia = i;
    }
  }

  moverEsquerda(imagemId: string, index: number) {
    let imagemMovida = this.imagens.find(x => x.id === imagemId);
    let indexImagemMovida = this.imagens.findIndex(x => x.id === imagemId);
    let imagemEsquerda = this.imagens[indexImagemMovida - 1];

    this.imagens[index] = imagemEsquerda;
    this.imagens[index - 1] = imagemMovida;

    for (let i = 0; i < this.imagens.length; i++) {
      this.imagens[i].sequencia = i;
    }
  }

  upload(files: any) {
    this.arquivosBase64 = [];
    const reader = new FileReader();
    
    this.commonService.spinnerOpen("Carregando imagens...");

    let readerFile = (index) => {
      if (index >= files.length){
        this.commonService.spinnerClose();
        this.savePicture(this.arquivosBase64);
        return;
      }
      let file = files[index];
      reader.onload = (readerEvt: any) => {
        const binaryString = readerEvt.target.result;
        const arquivoBase64 = btoa(binaryString);
        this.arquivosBase64.push('data:image/jpeg;base64,' + arquivoBase64);

        readerFile(index+1);
      };

      reader.readAsBinaryString(file)
    };

    readerFile(0);
  }

  close() {
    this.localStream?.getTracks().forEach(function (track) {
      track.stop();
    });

    super.close();
  }

  finalizar() {
    if (this.form.valid) {
      this.form.patchValue({ status: CapturaStatusType.Capturado });
      
      //Atualizar as ordem de imagens
      this.capturaImagemService.updateOrdens(this.imagens.map(x => x.id)).subscribe();

      this.save();
    }
  }
}
