<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Documento do SUS</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Profissional Solicitante</mat-label>
                <mat-select formControlName="profissionalSolicitanteId" required>
                  <mat-option *ngFor="let profissional of profissionais" [value]="profissional.value">
                    {{profissional.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Profissional Autorizador</mat-label>
                <mat-select formControlName="profissionalAutorizadorId">
                  <mat-option value="">Não Informado</mat-option>
                  <mat-option *ngFor="let profissional of profissionais" [value]="profissional.value">
                    {{profissional.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipo" required #focus>
                  <mat-option *ngFor="let tipo of typesService.docSusType" [value]="tipo.value">
                    {{tipo.text}}</mat-option>
                </mat-select>
                <mat-error>Selecione um Tipo válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Solicitação</mat-label>
                <input matInput formControlName="dataSolicitacao" type="date" required />
                <mat-error>Informe um Data válida.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <h4 class="card-inside-title">Procedimentos Solicidados</h4>
          <div class="row" *ngFor="let docSusProcedimento of procedimentos; let i = index">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input matInput [disabled]="detailMode" [value]="docSusProcedimento.procedimentoCodigo" type="number"
                  (blur)="changeProcedimentoCodigo($event.target.value, i)" required #focus autocomplete="off" />
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="procedimentoIndex = i; procedimentoSelect.showSelect()" matTooltip="Selecionar Procedimento">
                  <mat-icon>search</mat-icon>
                </button>
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="procedimentoIndex = i; procedimentoSelect.create()" matTooltip="Adicionar Procedimento">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Nome do Procedimento</mat-label>
                <input matInput disabled [value]="docSusProcedimento.procedimentoNome" type="text" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="form.value.tipo !== typesService.DocSusType.LaudoTfd">
            <h4 class="card-inside-title">Justificativa dos procedimentos solicitados</h4>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Descrição do Diagnóstico</mat-label>
                  <input matInput formControlName="descricaoDiagnostico" type="text" />
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field>
                  <mat-label>CID-10 Principal</mat-label>
                  <input matInput formControlName="cid10Principal" type="text" />
                  <mat-error>Informe um CID-10 válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field>
                  <mat-label>CID-10 Secundário</mat-label>
                  <input matInput formControlName="cid10Secundario" type="text" />
                  <mat-error>Informe um CID-10 válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field>
                  <mat-label>CID-10 Causas Associada</mat-label>
                  <input matInput formControlName="cid10CausasAssociada" type="text" />
                  <mat-error>Informe um CID-10 válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Resumo da Anamnese e Exames Físicos</mat-label>
                  <textarea matInput formControlName="resumoAnamnese" rows="10"></textarea>
                  <mat-error>Informe um Resumo válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Justificativa dos Procedimento</mat-label>
                  <textarea matInput formControlName="justifProcedimento" rows="5"></textarea>
                  <mat-error>Informe uma Justificativa válida.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="form.value.tipo === typesService.DocSusType.LaudoTfd">
            <div class="row">
              <div class="col-md-2">
                <mat-form-field>
                  <mat-label>CID-10 Principal</mat-label>
                  <input matInput formControlName="cid10Principal" type="text" />
                  <mat-error>Informe um CID-10 válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Caracter Atendimento</mat-label>
                  <mat-select formControlName="caracterAtendimento" required>
                    <mat-option *ngFor="let caracter of typesService.caracterAtendimentoType" [value]="caracter.value">
                      {{caracter.text}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Selecione um Tipo de Atendimento.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-7">
                <mat-form-field>
                  <mat-label>Diagnóstico Inicial</mat-label>
                  <input matInput formControlName="diagnosticoInicial" type="text" />
                  <mat-error>Informe um Diagnóstico Inicial válido.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Histórico Doença</mat-label>
                  <input matInput formControlName="historicoDoenca" type="text" />
                  <mat-error>Informe um Histórico Doença válido.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Exame Físico</mat-label>
                  <textarea matInput formControlName="exameFisico" rows="2"></textarea>
                  <mat-error>Informe um Exame Físico válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Diagnóstico Provavel</mat-label>
                  <textarea matInput formControlName="diagnosticoProvavel" rows="2"></textarea>
                  <mat-error>Informe um Diagnóstico Provavel válido.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Exame Complementares realizados (anexar cópias)</mat-label>
                  <input matInput formControlName="examesComplementares" type="text" />
                  <mat-error>Informe um Exames Complementares válido.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Tratamento Realizado</mat-label>
                  <textarea matInput formControlName="tratamentoRealizado" rows="2"></textarea>
                  <mat-error>Informe um Tratamento Realizado válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Procedimento / Tratamento Solicitado</mat-label>
                  <textarea matInput formControlName="tratamentoSolicitado" rows="2"></textarea>
                  <mat-error>Informe um Tratamento Solicitado válido.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Justificar impossibilidades de tratamento na localidade</mat-label>
                  <textarea matInput formControlName="justifTratamento" rows="2"></textarea>
                  <mat-error>Informe um Justif. Tratamento válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Justificar a necessidade de acompanante</mat-label>
                  <textarea matInput formControlName="justifAcompanante" rows="2"></textarea>
                  <mat-error>Informe um Justif. Acompanante válido.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <section>
                  <mat-checkbox formControlName="transpRodoviario" color="primary">
                    Transp. Rodoviário</mat-checkbox>
                </section>
              </div>
              <div class="col-md-4">
                <section>
                  <mat-checkbox formControlName="transpAereo" color="primary">
                    Transp. Aéreo</mat-checkbox>
                </section>
              </div>
              <div class="col-md-4">
                <section>
                  <mat-checkbox formControlName="transpAmbulancia" color="primary">
                    Transp. Ambulância</mat-checkbox>
                </section>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Justificar Transporte</mat-label>
                  <input matInput formControlName="justifTransp" type="text" />
                  <mat-error>Informe um Justif. Transp. válido.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-procedimento-select-modal #procedimentoSelect (modalClose)="changeProcedimentoId($event, procedimentoIndex)">
</app-procedimento-select-modal>