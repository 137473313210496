<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Histórico de Atendimentos</h2>
      </div>
      <div class="modal-body">
        <p style="margin: 0; padding: 0;">
          <strong>Paciente:</strong>
          <span style="margin-right: 20px;"> {{paciente?.pessoa?.codigo}} - {{paciente?.pessoa?.nomeFantasia}}</span>

          <strong>Nascimento:</strong>
          <span style="margin-right: 20px;"> {{paciente?.pessoa?.dataNascimento | date: 'dd/MM/yyyy'}}</span>

          <strong>CPF:</strong> {{paciente?.pessoa?.cnpjCpf}}
        </p>
        <hr style="border-top: 1px solid;" />
        <!-- <div *ngif="listaApresentacao.length > 0"> -->
        <div *ngFor="let item of listaApresentacao">
          <p style="margin: 0; padding: 0;">
            <strong>Atendimento:</strong>
            <span style="margin-right: 20px;"> {{item.dataHora | date: 'dd/MM/yyyy HH:mm'}}</span>
            <strong>Profissional Saúde:</strong> {{item.profissionalSaudeNome}}
          </p>
          <div style="margin-top: 2px;">
            <div [innerHTML]="item.txtProntuario"></div>
          </div>
          <hr style="border-top: 1px solid;" />
        </div>
        <!-- </div> -->
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" type="button" [routerLink]="" (click)="print()" class="button-row">
          <span>Imprimir</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>    
    <tbody class="report-content">
      <tr>
        <td>
          <br>
          <br>
          <h4 class="text-center font-weight-bold">Histórico de Atendimentos</h4>
          <hr>
          <div class="row">
            <div class="col-6">
              <strong>Paciente:</strong>
              <span class="mr-5"> {{paciente?.pessoa?.nomeFantasia}}</span>
            </div>
            <div class="col-3">
              <strong>CPF:</strong> {{paciente?.pessoa?.cnpjCpf}}
            </div>
            <div class="col-3">
              <strong>RG:</strong> {{paciente?.pessoa?.iestadualRg}}
            </div>            
          </div>
          <div class="row">
            <div class="col-4">
              <strong>Telefone:</strong> {{paciente?.pessoa?.telefone}}
            </div>
            <div class="col-4">
              <strong>Celular:</strong> {{paciente?.pessoa?.celular}}
            </div>
            <div class="col-4">
              <strong>Profissão:</strong> {{paciente?.profissao}}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <strong>Convênio:</strong> {{paciente?.convenioNome}}
            </div>
            <div class="col-4">
              <strong>Matricula:</strong> {{paciente?.convMatricula}}
            </div>
            <div class="col-4">
              <strong>Validade:</strong> {{paciente?.convMatriculaValidade | date: 'dd/MM/yyyy'}}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <strong>Nascimento:</strong>
              <span class="mr-5"> {{paciente?.pessoa?.dataNascimento | date: 'dd/MM/yyyy'}}</span>
            </div>            
            <div class="col-8">
              <strong>Idade:</strong> {{getIdadePessoa(paciente?.pessoa?.dataNascimento)}}
            </div>            
          </div>
          <div class="row">
            <div class="col-3">
              <strong>Estado Civil:</strong> {{typesService.getEstadoCivilType(paciente?.estadoCivil)}}
            </div>
            <div class="col-3">
              <strong>Sexo:</strong> {{typesService.getSexoTypeNome(paciente?.pessoa?.sexo)}}
            </div>
            <div class="col-3">
              <strong>Raça:</strong> {{typesService.getRacaType(paciente?.raca)}}
            </div>
            <div class="col-3">
              <strong>Tipo Sanguíneo:</strong> {{typesService.getSangueType(paciente?.tipoSanguineo)}}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong>Nome Pai:</strong> {{paciente?.nomePai}}
            </div>
            <div class="col-6">
              <strong>Nome Mãe:</strong> {{paciente?.nomeMae}}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong>Endereço:</strong> {{paciente?.pessoa?.ruaAvenida}}
            </div>
            <div class="col-2">
              <strong>N°:</strong> {{paciente?.pessoa?.numero}}
            </div>
            <div class="col-4">
              <strong>Complemento:</strong> {{paciente?.pessoa?.complemento}}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <strong>Bairro:</strong> {{paciente?.pessoa?.bairro}}
            </div>
            <div class="col-4">
              <strong>Cidade:</strong> {{paciente?.pessoa?.cidadeNome}}
            </div>
            <div class="col-2">
              <strong>Estado:</strong> {{paciente?.pessoa?.estadoUf}}
            </div>
            <div class="col-2">
              <strong>CEP:</strong> {{paciente?.pessoa?.cep}}
            </div>
          </div>
          <hr style="border-top: 1px solid;" />
          <div *ngFor="let item of listaApresentacao">
            <p style="margin: 0; padding: 0;">
              <strong>Atendimento:</strong>
              <span style="margin-right: 20px;"> {{item.dataHora | date: 'dd/MM/yyyy HH:mm'}}</span>
              <strong>Profissional Saúde:</strong> {{item.profissionalSaudeNome}}
            </p>
            <div style="margin-top: 2px;">
              <div [innerHTML]="item.txtProntuario"></div>
            </div>
            <hr style="border-top: 1px solid;" />
          </div>
        </td>
      </tr>
    </tbody>
    <!-- <tfoot class="report-footer">
      <tr>
        <td>
          <footer>
            <app-report-footer></app-report-footer>
          </footer>
        </td>
      </tr>
    </tfoot> -->
  </table>
</div>