import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from 'projects/ProjetoBaseAngular/domain/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class FaturamentoService extends BaseService {
  
  constructor(
    injector: Injector
    ) {
    super(injector);
  }

  public getController(): string {
    return 'Faturamento';
  }

  applyStatusRecebido(listaId: string[]): Observable<any> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/ApplyStatusRecebido`, JSON.stringify(listaId), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  undoStatusRecebido(listaId: string[]): Observable<any> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UndoStatusRecebido`, JSON.stringify(listaId), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAtendimento(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetAtendimento`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}