import { OnInit, Injector, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

import { AccountService, ApplicationService, CommonService } from 'projects/ProjetoBaseAngular/domain/services';
import { ClaimModel } from 'projects/ProjetoBaseAngular/domain/models';

@Directive()
export abstract class BaseComponent implements OnInit {
  errors: any[] = [];

  form: FormGroup;
  protected readonly formBuilder: FormBuilder;
  readonly router: Router;
  readonly route: ActivatedRoute;
  readonly snackBar: MatSnackBar;

  readonly accountService: AccountService;
  readonly applicationService: ApplicationService;
  readonly commonService: CommonService;

  constructor(
    injector: Injector,
  ) {
    this.formBuilder = injector.get<FormBuilder>(FormBuilder);
    this.router = injector.get<Router>(Router);
    this.route = injector.get<ActivatedRoute>(ActivatedRoute);
    this.snackBar = injector.get<MatSnackBar>(MatSnackBar);
    this.accountService = injector.get<AccountService>(AccountService);
    this.applicationService = injector.get<ApplicationService>(ApplicationService);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit() {
  }
  
  protected verifyErrors(errors: any) {
    this.errors = [];
    errors.forEach((error: any) => {
      if (error instanceof Object) {
        const control = this.form.controls[`${error.key[0].toLowerCase()}${error.key.slice(1)}`];
        control && control.setErrors({ valid: false });

        this.errors.push(`${error.key} - ${error.value}`);
      } else {
        this.errors.push(error);
      }
    });
  }

  split(text: string, character: string) {
    const work = text.split(character);
    const result = new Array<string>();
    work.forEach(element => {
      if (element.trim().length > 0) {
        result.push(element);
      }
    });
    return result;
  }

  fromJsonDate(date: Date): string {
    if (!date) {
      return null;
    }
    return moment(date).format('YYYY-MM-DD');
  }

  fromJsonDateTime(date: Date): string {
    if (!date) {
      return null;
    }
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  /**
   * Returna validação da operação (true/false).
   * De acordo com as permissões definidas nas CLaims do usuário.   
   * @param value É o tipo de operação que será testada a permissão (exemplo: "Update").
   * @param type É o tipo de Claim (PermissionName = Nome do controller).
   */
  isAllowed(value: string, type: string): boolean {
    const claims: ClaimModel[] = this.accountService.getClaims();
    if (claims === null) {
      return false;
    }

    const claim = claims.filter(src => src.type.toUpperCase() === type.toUpperCase());
    if (claim.length === 0) {
      return false;
    }

    return this.split(claim[0].value, ',').filter(val => val.toUpperCase() === value.toUpperCase()).length === 0 ? false : true;
  }

  tinymceConfig(height?: number) {
    return {
      height,
      base_url: '/tinymce',
      suffix: '.min',
      language: 'pt_BR',
      language_url: '/assets-base/langs/pt_BR.js',
      entity_encoding: 'numeric',
      extended_valid_elements: 'i[class],script[src|async|defer|type|charset]',
      plugins: 'code print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern emoticons help',
      toolbar: 'undo, redo | bold italic | fontselect fontsizeselect formatselect | link image media emoticons | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | fullscreen',
      setup: null
    }
  }
}
