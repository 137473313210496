import { Component, OnInit, ViewChild, Injector, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { PacienteExameIndicadorItemModel } from '../../../domain/models';
import { PacienteExameIndicadorItemService } from '../../../domain/services';

@Component({
  selector: 'app-paciente-exame-indicador-item-modal',
  templateUrl: './paciente-exame-indicador-item-modal.component.html'
})
export class PacienteExameIndicadorItemModalComponent extends BaseCrudModalComponent<PacienteExameIndicadorItemModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Output() itemUpdate: EventEmitter<any> = new EventEmitter<any>();  

  constructor(
    injector: Injector,
    service: PacienteExameIndicadorItemService    
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: PacienteExameIndicadorItemModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: model.dataAlteracao,

      pacienteExameIndicadorId: [model.pacienteExameIndicadorId, Validators.required],
      dataReferencia: [this.fromJsonDate(model.dataReferencia), Validators.required],
      resultado: [model.resultado, Validators.required],
      observacao: [model.observacao, Validators.maxLength(500)]
    });
  }
}
