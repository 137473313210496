<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-md">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Agenda de Espera</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-5">
              <mat-form-field>
                <mat-label>Cód./CPF Paciente</mat-label>
                <input matInput [disabled]="detailMode"
                  [value]="paciente?.pessoa?.cnpjCpf" type="text"
                  (blur)="pacienteSelect.changeDocumento($event.target.value)" maxlength="18" required />
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.showSelect()" matTooltip="Selecionar Paciente">
                  <mat-icon>search</mat-icon>
                </button>
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.create()" matTooltip="Adicionar Paciente">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-md-7">
              <mat-form-field>
                <mat-label>Paciente</mat-label>
                <input matInput disabled [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia" type="text" />
                <button [disabled]="isBusy || !paciente?.id" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Convênio</mat-label>
                <mat-select formControlName="convenioId" required>
                  <mat-option *ngFor="let convenio of convenios" [value]="convenio.value">
                    {{convenio.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione uma Convênio.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observação</mat-label>
                <textarea matInput formControlName="observacao" type="text"></textarea>
                <mat-error>Informe um Observação válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>