import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { EquipamentoComponent } from './equipamento.component';
import { EquipamentoModalComponent } from './equipamento-modal/equipamento-modal.component';
import { AgendaDisponibilidadeModule } from '../agenda-disponibilidade/agenda-disponibilidade.module';
import { AgendaBloqueioModule } from '../agenda-bloqueio/agenda-bloqueio.module';

@NgModule({
  imports: [    
    SharedModule,
    AgendaDisponibilidadeModule,
    AgendaBloqueioModule
  ],
  declarations: [
    EquipamentoComponent,
    EquipamentoModalComponent
  ]
})
export class EquipamentoModule { }
