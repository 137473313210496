import { Injectable, Injector } from '@angular/core';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PoliticaPrivacidadeModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PoliticaPrivacidadeService extends BaseCrudService<PoliticaPrivacidadeModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'PoliticaPrivacidade';
  }

  getUltimaVersao(): Observable<PoliticaPrivacidadeModel> {
    return this
      .httpClient
      .get<PoliticaPrivacidadeModel>(`${this.getApiUrl()}${this.getController()}/GetUltimaVersao`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
