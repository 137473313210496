import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';

import { ContatoPreAgendamentoModel } from '../models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ProfissionalSaudeDto } from '../../../equipe/domain/models';

@Injectable({
  providedIn: 'root'
})
export class ContatoPreAgendamentoService extends BaseCrudService<ContatoPreAgendamentoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ContatoPreAgendamento';
  }

  getMsgAceiteContatoSite(): Observable<string> {
    return this
      .httpClient
      .get<string>(`${this.getApiUrl()}${this.getController()}/GetMsgAceiteContatoSite`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  send(model: ContatoPreAgendamentoModel): Observable<ContatoPreAgendamentoModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Send`, JSON.stringify(model), this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getProfissionalSelectList(): Observable<ProfissionalSaudeDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetProfissionalSelectList`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getEspecialidadeSelectList(): Observable<SelectDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetEspecialidadeSelectList`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getServicoSelectList(): Observable<SelectDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetServicoSelectList`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getConvenioSelectList(): Observable<SelectDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetConvenioSelectList`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
