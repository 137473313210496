export class UsuarioModel {
    public id: string;
    public userNameTenant: string;
    public email: string;
    public imagemUrl: string;
    public imagemUrlBase: string;
    public imagemPath: string;
    public nome: string;
    public sobrenome: string;
    public temaAtual: string;
    public password: string;
    public confirmPassword: string;
    public grupoUsuarioId: string;
    public grupoUsuarioNome: string;
    public tenantId: string;
    public tenantNomeFantasia: string;
    public superUsuarioTenant: boolean;
    public ativo: boolean;
    public profissionalRecursoIds: string[];
}
