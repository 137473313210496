import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BaseService } from "projects/ProjetoBaseAngular/domain/services/base.service";
import { WaConnectRequestDto, WaConnectResultDto, WaDisconnectResultDto, WaLogoutResultDto, WaPairResultDto, WaQrResultDto, WaServerResultDto, WaStatusResultDto, WaWebhookResultDto } from "projects/ProjetoBaseAngular/domain/models/whatsAppApi/models";

@Injectable({
  providedIn: 'root'
})
export class WhatsAppConfigService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'WAConfig';
  }

  //#region Session
  sessionInit(adminToken: string, userName: string, userToken: string): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/SessionInit/${adminToken}/${userName}/${userToken}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  sessionGetAll(adminToken: string): Observable<string[]> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/SessionGetAll/${adminToken}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  sessionConnect(requestDto: WaConnectRequestDto): Observable<WaConnectResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/SessionConnect`, JSON.stringify(requestDto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  sessionPair(phone: string): Observable<WaPairResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/SessionPair/${phone}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  sessionGetQr(): Observable<WaQrResultDto> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/SessionGetQr`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  sessionGetStatus(): Observable<WaStatusResultDto> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/SessionGetStatus`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  sessionDisconnect(): Observable<WaDisconnectResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/SessionDisconnect`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  sessionLogout(): Observable<WaLogoutResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/SessionLogout`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  //#endregion

  //#region Webhook
  webHookGet(): Observable<WaWebhookResultDto> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/WebHookGet`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  webHookSet(): Observable<WaWebhookResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/WebHookSet`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  //#endregion

  //#region Server
  serverOk(): Observable<WaServerResultDto> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/ServerOk`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  serverLicense(adminToken: string): Observable<WaServerResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/ServerLicense/${adminToken}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  serverUpdate(adminToken: string): Observable<WaServerResultDto> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/ServerUpdate/${adminToken}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  //#endregion

}