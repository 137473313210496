export enum LinkTargetType {
    Self = 0,
    Blank = 1
}

export enum MenuType {
    Principal = 0,
    TopoDireito = 1,
    TopoEsquerdo = 2,
    Destaque = 3
}

export enum BlocoType {
    Imagem = 0,
    Html = 1,
    Script = 2
}

export enum BlocoPosicaoType {
    Logotipo = 0,
    Rodape = 1,    
    DestaqueAbertura = 3,
    DestaqueCarrossel = 4,
    DestaqueRodape = 5,
    Cabecalho = 6,    
    TagHead = 7,
    TagBody = 8
}    