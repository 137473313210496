import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseEntidadePessoaModalComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoa-modal.component';
import { PacienteExameIndicadorModel, PacienteModel, PacienteProntuarioDto, PacienteProntuarioModel } from '../../domain/models';
import { GrupoPacienteService, PacienteService } from '../../domain/services';
import { PessoaType } from 'projects/ProjetoBaseAngular/domain/types';
import { TypesService } from 'projects/admin/src/domain/services';
import { ConvenioService } from '../../../parceiro/domain/services/convenio.service';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PacienteIndicadoPorType, PacienteProntuarioType, SangueType } from 'projects/admin/src/domain/types';
import * as moment from 'moment';
import { PacienteExameIndicadorService } from '../../domain/services/paciente-exame-indicador.service';
import { PacienteExameIndicadorModalComponent } from '../paciente-exame-indicador-modal/paciente-exame-indicador-modal.component';
import { ExameDashboardModalComponent } from '../exame-dashboard-modal/exame-dashboard-modal.component';
import { ConvenioDto } from '../../../parceiro/domain/models';
import { PacienteProntuarioService } from '../../domain/services/paciente-prontuario.service';
import { PacienteProntuarioModalComponent } from '../paciente-prontuario-modal/paciente-prontuario-modal.component';
import { ConfigService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-paciente-modal',
  templateUrl: './paciente-modal.component.html'
})
export class PacienteModalComponent extends BaseEntidadePessoaModalComponent<PacienteModel> implements OnInit {
  @ViewChild('pacienteModal') modal: ModalDirective;
  @ViewChild('pacienteExameIndModal') pacienteExameIndModal: PacienteExameIndicadorModalComponent;
  @ViewChild('exameDashboardModal') exameDashboardModal: ExameDashboardModalComponent;
  @ViewChild('pacienteProntuarioModal') pacienteProntuarioModal: PacienteProntuarioModalComponent;

  grupos: SelectDto[];

  convenios: ConvenioDto[];
  filteredConvenios: ConvenioDto[] = [];
  public convenioFilterCtrl: FormControl = new FormControl();

  prontuarioAtivo: boolean = false;
  profissionalSaudeId: string;

  listaExameIndicador: PacienteExameIndicadorModel[] = [];
  listaProntuarioDto: PacienteProntuarioDto[] = [];

  celularObrigatorio: boolean = true;
  telefoneObrigatorio: boolean = false;

  constructor(
    injector: Injector,
    readonly pacienteService: PacienteService,
    readonly grupoPacienteService: GrupoPacienteService,
    readonly convenioService: ConvenioService,
    readonly pacienteExameIndService: PacienteExameIndicadorService,
    readonly pacienteProntuarioService: PacienteProntuarioService,
    readonly typesService: TypesService,
    readonly configService: ConfigService,
  ) {
    super(injector, pacienteService);
  }

  async ngOnInit() {    
    super.ngOnInit();
  }  

  showEditProntuario(id: string, profissionalSaudeId?: string) {
    super.showEdit(id);
    this.prontuarioAtivo = true;
    this.profissionalSaudeId = profissionalSaudeId;
  }

  protected init() {    
    super.init();

    this.grupoPacienteService.getSelectList().subscribe(x => this.grupos = x);

    this.convenioService.getSelectList().subscribe(x => {
      this.convenios = x;
      this.convenios.forEach(item => {
        //normalizando texto (tirando acentos) para filtro
        item["normalizado"] = item.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      });

      this.filteredConvenios = x;
    });
    this.convenioFilterCtrl.valueChanges.subscribe(() => this.filterConvenio());
  }

  protected filterConvenio() {
    if (!this.convenios) {
      return;
    }
    // pega a palavra chave da consulta
    let search = this.convenioFilterCtrl.value;

    if (!search) {
      this.filteredConvenios = this.convenios.slice();
      return;
    } else {
      search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    }

    // filtra para a nova lista
    this.filteredConvenios = this.convenios.filter(x =>
      x["normalizado"].toLowerCase().startsWith(search)
    );
  }

  protected async initializeForm(paciente: PacienteModel) {    
    super.initializeForm(paciente);

    this.form.addControl('grupoId', new FormControl(paciente.grupoId));
    this.form.addControl('convenioId', new FormControl(paciente.convenioId));
    this.form.addControl('convMatricula',
      new FormControl(paciente.convMatricula, [
        Validators.maxLength(50)
      ])
    );
    this.form.addControl('convMatriculaValidade', new FormControl(paciente.convMatriculaValidade));
    this.form.addControl('codCns',
      new FormControl(paciente.codCns, [
        Validators.maxLength(50)
      ])
    );
    this.form.addControl('raca', new FormControl(paciente.raca));
    this.form.addControl('estadoCivil', new FormControl(paciente.estadoCivil));
    this.form.addControl('profissao',
      new FormControl(paciente.profissao, [
        Validators.maxLength(60)
      ])
    );
    this.form.addControl('nomePai',
      new FormControl(paciente.nomePai, [
        Validators.maxLength(60)
      ])
    );
    this.form.addControl('nomeMae',
      new FormControl(paciente.nomeMae, [
        Validators.maxLength(60)
      ])
    );
    this.form.addControl('nomeConjuge',
      new FormControl(paciente.nomeConjuge, [
        Validators.maxLength(60)
      ])
    );
    this.form.addControl('nomeResponsavel',
      new FormControl(paciente.nomeResponsavel, [
        Validators.maxLength(60)
      ])
    );
    this.form.addControl('indicadoPor', new FormControl(paciente.indicadoPor));
    this.form.addControl('nomeIndicador',
      new FormControl(paciente.nomeIndicador, [
        Validators.maxLength(60)
      ])
    );
    this.form.addControl('codProntuario',
      new FormControl(paciente.codProntuario, [
        Validators.maxLength(20)
      ])
    );
    this.form.addControl('exibirAlertaAtendimento', new FormControl(paciente.exibirAlertaAtendimento));
    this.form.addControl('bloquearAgendamento', new FormControl(paciente.bloquearAgendamento));
    this.form.addControl('dataExpiraBloqueio', new FormControl(this.fromJsonDate(paciente.dataExpiraBloqueio)));
    this.form.addControl('mensagemBloqAlerta',
      new FormControl(paciente.mensagemBloqAlerta, [
        Validators.maxLength(500)
      ])
    );
    this.form.addControl('anotacaoProntuario',
      new FormControl(paciente.anotacaoProntuario, [
        Validators.maxLength(1000)
      ])
    );
    this.form.addControl('tipoSanguineo',
      new FormControl(paciente.tipoSanguineo, Validators.required)
    );
    this.form.addControl('idWhatsApp',
      new FormControl(paciente.idWhatsApp)
    );

    //Preparar listas
    if (this.prontuarioAtivo) {
      this.carregarListaExame(paciente.id);
      this.carregarlistaProntuarioDto(paciente.id);
    }

    await this.updateRequiredFones();
  }

  async updateRequiredFones() {
    let configCel = await this.configService.getConfigByGrupoChave("Paciente", "CelularObrigatorio").toPromise();
    if (configCel.valor === "N") {
      this.celularObrigatorio = false;
    }

    let configFone = await this.configService.getConfigByGrupoChave("Paciente", "TelefoneObrigatorio").toPromise();
    if (configFone.valor === "S") {
      this.telefoneObrigatorio = true;
    }
  }

  beforeShowCreate(model: PacienteModel) {
    model = super.beforeShowCreate(model);
    model.pessoa.tipoPessoa = PessoaType.Fisica;
    model.exibirAlertaAtendimento = false;
    model.bloquearAgendamento = false;
    model.indicadoPor = PacienteIndicadoPorType.SemIndicacao;
    model.tipoSanguineo = SangueType.NaoInformado;

    return model;
  }

  async save(autoSave: boolean = false) {
    const model: PacienteModel = Object.assign({}, this.form.value);

    if (model.bloquearAgendamento) {
      if (moment(this.fromJsonDate(model.dataExpiraBloqueio)).toDate() < moment(this.fromJsonDate(new Date())).toDate()) {
        this.commonService.mensagem('Data inválida', 'Informe uma data maior ou igual a data de hoje para expiração do bloqueio.', 'warning');
        return;
      }
    } else {
      this.form.patchValue({ dataExpiraBloqueio: null });
    }

    await super.save(autoSave);
  }

  carregarListaExame(id: string) {
    this.pacienteExameIndService.getByPacienteId(id).subscribe(x => this.listaExameIndicador = x);
  }

  editExame(model: PacienteExameIndicadorModel) {
    this.pacienteExameIndModal.showEdit(model.id);
  }

  async deleteExame(id: string) {
    let result = await this.commonService.mensagemConfirmacao("Excluir resultado exame?", "Os valores vinculados a este lançamento serão perdidos.", "question");
    if (result) {
      await this.pacienteExameIndService.delete(id).toPromise();
      this.carregarListaExame(this.form.value.id);
    }
  }

  createExame() {
    const model = new PacienteExameIndicadorModel();
    model.pacienteId = this.form.value.id;
    this.pacienteExameIndModal.showCreate(model);
  }

  onResponseExame(event) {
    this.carregarListaExame(this.form.value.id);
  }

  carregarlistaProntuarioDto(id: string) {
    this.pacienteProntuarioService.getSelectListByPacienteId(id).subscribe(x => this.listaProntuarioDto = x);
  }

  async editProntuario(dto: PacienteProntuarioDto) {
    if (!await this.validarEditDelete(dto)) {
      return
    }

    this.pacienteProntuarioModal.showEdit(dto.value);
  }

  detailProntuario(dto: PacienteProntuarioDto) {
    this.pacienteProntuarioModal.showDetail(dto.value);
  }

  async deleteProntuario(value: string) {
    const dto = this.listaProntuarioDto.find(x => x.value === value);

    if (!await this.validarEditDelete(dto)) {
      return
    }

    let result = await this.commonService.mensagemConfirmacao("Excluir registro de Prontuario", "Confirmar?", "question");
    if (result) {
      await this.pacienteProntuarioService.delete(value).toPromise();
      this.carregarlistaProntuarioDto(this.form.value.id);
    }
  }

  async validarEditDelete(dto: PacienteProntuarioDto): Promise<boolean> {
    if (dto.profissionalSaudeId !== this.profissionalSaudeId) {
      await this.commonService.mensagem("Atenção", "Somente o profissional de origem poderá excluir o registro.", "info");
      return false;
    }
    let diferenca = Math.round(this.commonService.getHorasDiferenca(dto.dataInclusao, new Date()));
    if (diferenca > 24) {
      await this.commonService.mensagem("Atenção", "Após 24 horas o registro não poderá mais ser editado ou excluído.", "info");
      return false;
    }

    return true;
  }

  createProntuario() {
    const model = new PacienteProntuarioModel();
    model.pacienteId = this.form.value.id;
    model.dataInclusao = new Date();
    model.tipo = PacienteProntuarioType.Evolucao;
    model.receitaTipo = 0;
    model.sequencial = 0;
    model.profissionalSaudeId = this.profissionalSaudeId ? this.profissionalSaudeId : "";

    this.pacienteProntuarioModal.showCreate(model);
  }

  onResponseProntuario(event) {
    this.carregarlistaProntuarioDto(this.form.value.id);
  }

  onExitCelular() {
    const model: PacienteModel = Object.assign({}, this.form.value);
    if (model.pessoa.celular) {
      model.idWhatsApp = this.commonService.celularToIdWhats(model.pessoa.celular);
      this.form.patchValue({ idWhatsApp:  model.idWhatsApp});
    } else {
      model.idWhatsApp = "";
    }
  }

}
