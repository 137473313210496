import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { LinkTargetType, MenuType } from '../../domain/enums';
import { LinkModel, MenuModel } from '../../domain/models';
import { MenuService } from '../../domain/services';

@Component({
  selector: 'app-menu-modal',
  templateUrl: './menu-modal.component.html'
})
export class MenuModalComponent extends BaseCrudModalComponent<MenuModel> implements OnInit {
  @ViewChild('menuModal') modal: ModalDirective;

  MenuType: typeof MenuType = MenuType;

  menusPai: MenuModel[];

  tipos = [
    { text: 'Principal', value: MenuType.Principal },
    { text: 'Topo Direito', value: MenuType.TopoDireito },
    { text: 'Topo Esquerdo', value: MenuType.TopoEsquerdo },
    { text: 'Destaque', value: MenuType.Destaque },
  ];

  targets = [
    { text: 'Janela Atual', value: LinkTargetType.Self },
    { text: 'Nova Janela', value: LinkTargetType.Blank },
  ];

  constructor(
    injector: Injector,
    readonly menuService: MenuService
  ) {
    super(injector, menuService);
  }

  protected initializeForm(menu: MenuModel) {
    this.form = this.formBuilder.group({
      id: menu.id,
      dataInclusao: [menu.dataInclusao, Validators.required],
      dataAlteracao: menu.dataAlteracao,
      tipo: [menu.tipo, Validators.required],
      titulo: [menu.titulo, [
        Validators.required,
        Validators.maxLength(100)]
      ],
      iconeHtml: [menu.iconeHtml, Validators.maxLength(100)],
      menuPaiId: [menu.menuPaiId],
      ordem: [menu.ordem, Validators.required],
      linkId: [menu.linkId],
      link: this.formBuilder.group({
        id: menu.link.id,
        dataInclusao: [menu.link.dataInclusao, Validators.required],
        dataAlteracao: menu.link.dataAlteracao,
        url: [menu.link.url, Validators.required],
        target: [menu.link.target, Validators.required]
      })
    });

    this.menuService.getAll().subscribe(menus => {      
      this.menusPai = menus.filter(x => x.id !== menu.id && !x.menuPaiId);
    })
  }

  ngOnInit() {
    super.ngOnInit();
  }

  beforeShowCreate(model: MenuModel): MenuModel {
    model.link = new LinkModel();
    model.link.id = this.commonService.newGuid();
    model.link.dataInclusao = new Date();
    model.link.target = LinkTargetType.Self;
    model.linkId = model.link.id;
    return super.beforeShowCreate(model);
  }
}
