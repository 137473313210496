import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProcedimentoPerguntaModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProcedimentoPerguntaService extends BaseCrudService<ProcedimentoPerguntaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ProcedimentoPergunta';
  }

  getByProcedimentoId(procedimentoId): Observable<ProcedimentoPerguntaModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByProcedimentoId/${procedimentoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  montarResultado(procedimentoId, respostas): Observable<string> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/montarResultado/${procedimentoId}`, JSON.stringify(respostas), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
