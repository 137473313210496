import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { MicroRegiaoModel } from 'projects/ProjetoBaseAngular/domain/models';
import { MicroRegiaoService } from 'projects/ProjetoBaseAngular/domain/services';
import { MicroRegiaoModalComponent } from './micro-regiao-modal/micro-regiao-modal.component';


@Component({
  selector: 'app-admin-micro-regiao',
  templateUrl: './micro-regiao.component.html'
})
export class MicroRegiaoComponent extends BaseCrudComponent<MicroRegiaoModel> implements OnInit {
  @ViewChild('microRegiaoModal') modal: MicroRegiaoModalComponent;

  selectColumns = [
    { data: 'codigoIbge' },
    { data: 'nome' }
  ];

  constructor(
    injector: Injector,
    readonly microRegiaoService: MicroRegiaoService
  ) {
    super(injector, microRegiaoService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
