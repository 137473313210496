import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import Swal from 'sweetalert2';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { ArtigoModel, LocaisPublicacao } from '../domain/models';
import { ArtigoService } from '../domain/services';
import { ArtigoModalComponent } from './artigo-modal/artigo-modal.component';
import { LocalPublicacaoType } from 'projects/admin/src/domain/types';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-artigo',
  templateUrl: './artigo.component.html',
})
export class ArtigoComponent extends BaseCrudComponent<ArtigoModel> implements OnInit {
  @ViewChild('artigoModal') modal: ArtigoModalComponent;

  artigoLocaisPublicacao: LocaisPublicacao[] = [
    { text: 'Notícia', value: LocalPublicacaoType.Noticia.toString() },
    { text: 'Responsabilidade Social', value: LocalPublicacaoType.ResponsabilidadeSocial.toString() },
    { text: 'Blog', value: LocalPublicacaoType.Blog.toString() }
  ];

  searchLocaisPublicacao = new ModelSearch('locaisPublicacao.localPublicacao', OptionSearchType.Equals, SearchType.String);
  search = new ModelSearch('titulo', OptionSearchType.Contains, SearchType.String);
  searchStatus = new ModelSearch('publicado', OptionSearchType.Equals, SearchType.Boolean);

  selectColumns = [
    { data: 'imagens.OrderBy(x => x.Ordem).Select(x => x.ImagemUrl).FirstOrDefault() as imagemCapaUrl', orderable: false },
    { data: 'dataArtigo' },
    { data: 'autor' },
    { data: 'titulo' },
    { data: 'tags.Select(x => x.artigoTag.nome) as tagNomes', orderable: false },
    { data: 'locaisPublicacao.Select(x => new { x.ProfissionalSaude.Pessoa.NomeFantasia, x.LocalPublicacao }) as locaisPublicacao', orderable: false },
    { data: 'publicado' }
  ];

  constructor(
    injector: Injector,
    readonly artigoService: ArtigoService,
    readonly typesService: TypesService
  ) {
    super(injector, artigoService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.order = [[1, 'desc']];
    this.searchStatus.searchTerm = -1;
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = [];

    (this.searchLocaisPublicacao.searchTerm) && searches.push(this.searchLocaisPublicacao);
    (this.search.searchTerm) && searches.push(this.search);
    if (this.searchStatus.searchTerm != -1){
      searches.push(this.searchStatus);
    }

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  getTipoLocalPublicacao(tipo: LocalPublicacaoType): string {
    switch (tipo) {
      case LocalPublicacaoType.Noticia:
        return 'Notícia';
      case LocalPublicacaoType.ResponsabilidadeSocial:
        return 'Responsabilidade Social';
      case LocalPublicacaoType.Blog:
        return 'Blog';
      case LocalPublicacaoType.HotSite:
        return 'HotSite';
    }
  }

  async ativar(model: ArtigoModel) {
    if (model.publicado !== true) {
      let result = await this.commonService.mensagemConfirmacao("Confirmar Publicação?","Atenção! Este Artigo vai ser publicado.","question");
      if (result){
       model = await this.artigoService.ativarArtigo(model.id).toPromise();
       this.applyToList(model);
      }
    }
  }

  async inativar(model: ArtigoModel) {
    if (model.publicado !== false) {
      let result = await this.commonService.mensagemConfirmacao("Despublicar Artigo?","Atenção! Este Artigo não será mais apresentado no site até que você publique novamente.","question");
      if (result){
       model = await this.artigoService.inativarArtigo(model.id).toPromise();
       this.applyToList(model);
      }
    }
  }

  getLocaisPublicacaoNomes(locaisPublicacao): string {
    return locaisPublicacao.map(x =>
      x.LocalPublicacao === LocalPublicacaoType.HotSite ?
        `Hotsite: ${x.NomeFantasia}` : this.typesService.getLocalPublicacaoType(x.LocalPublicacao)
    ).join(', ');
  }
}
