import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { PesquisaStatusCorType } from 'projects/admin/src/domain/types';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { Subject } from 'rxjs';
import { QuestionarioFiltroModel, QuestionarioModel, QuestionarioRespModel } from '../domain/models';
import { QuestionarioFiltroService, QuestionarioRespService, QuestionarioService } from '../domain/services';

@Component({
  selector: 'app-questionario-dash-geral',
  templateUrl: './questionario-dash-geral.component.html'
})

export class QuestionarioDashGeralComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;
  @ViewChild('tableRespostas') tableRespostas: ElementRef;
  @ViewChild('canvas') canvas: any;

  dtOptions: DataTables.Settings = {
    language: {
      url: '/assets-base/json/translation/datatable/pt-br.json'
    },
    order: [2, 'desc'],
    lengthChange: false,
    paging: false,
    searching: false,
    dom: ''
  };
  dtTrigger: Subject<any[]> = new Subject();

  listaFiltro: QuestionarioFiltroModel[];
  questionarioTitulo: string;
  questionarioId: string;
  dataInicial: string;
  dataFinal: string;
  filtroId: string;
  questionario: QuestionarioModel;
  
  chartOptions: ChartOptions = {
    responsive: true
  };
  chartLabels: Label[] = [];
  chartData: number[] = [];
  chartDatasets = [];
  chartType: ChartType = 'pie';
  chartLegend = true;
  chartPlugins = []; //[ChartDataLabels];
  chartColors = [];

  tableItens: any[] = [];
  qtdTotal: number;
  
  constructor(
    injector: Injector,
    readonly service: QuestionarioRespService,
    readonly questionarioService: QuestionarioService,
    readonly filtroService: QuestionarioFiltroService,
    readonly printService: PrintService,
    readonly typesService: TypesService
  ) {
    super(injector);
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
  }

  async show(questionarioId: string, titulo: string) {
    this.questionarioId = questionarioId;
    this.questionarioTitulo = titulo;

    const now = new Date();
    this.dataInicial = this.fromJsonDate(new Date(now.getFullYear(), now.getMonth(), 1));
    this.dataFinal = this.fromJsonDate(new Date(now.getFullYear(), now.getMonth() + 1, 0));

    this.listaFiltro = await this.filtroService.getByQuestionarioId(questionarioId).toPromise();
    this.filtroId = this.listaFiltro[0].id;

    this.filtrar();
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  async filtrar() {
    let lista = await this.service.getByQuestionarioFiltro(this.questionarioId, this.filtroId, this.dataInicial, this.dataFinal).toPromise();
    this.questionario = await this.questionarioService.getById(this.questionarioId).toPromise();
    this.popularDados(lista);
  }

  private popularDados(listaResposta: QuestionarioRespModel[]) {

    //Totalizar por Status
    this.qtdTotal = listaResposta.length;
    let qtdVerde = listaResposta.filter(x => x.statusCor === PesquisaStatusCorType.Verde).length;
    let qtdLaranja = listaResposta.filter(x => x.statusCor === PesquisaStatusCorType.Laranja).length;
    let qtdVermelho = listaResposta.filter(x => x.statusCor === PesquisaStatusCorType.Vermelho).length;
    let qtdPurpura = listaResposta.filter(x => x.statusCor === PesquisaStatusCorType.Purpura).length;

    //Preparar dados Chart
    this.chartLabels = [];
    this.chartData = [];
    this.chartDatasets = [];

    // this.chartLabels.push(this.typesService.getPesquisaStatusCorType(PesquisaStatusCorType.Verde));
    // this.chartLabels.push(this.typesService.getPesquisaStatusCorType(PesquisaStatusCorType.Laranja));
    // this.chartLabels.push(this.typesService.getPesquisaStatusCorType(PesquisaStatusCorType.Vermelho));
    // this.chartLabels.push(this.typesService.getPesquisaStatusCorType(PesquisaStatusCorType.Purpura));

    this.chartLabels.push(this.questionario.nomeVerde);
    this.chartLabels.push(this.questionario.nomeLaranja);
    this.chartLabels.push(this.questionario.nomeVermelha);
    this.chartLabels.push("Abaixo Limite");

    this.chartData.push(qtdVerde);
    this.chartData.push(qtdLaranja);
    this.chartData.push(qtdVermelho);
    this.chartData.push(qtdPurpura);

    let backColors: any[] = [];
    let colors: any[] = [];

    backColors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Verde), "0.3"));
    backColors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Laranja), "0.3"));
    backColors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Vermelho), "0.3"));
    backColors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Purpura), "0.3"));
    colors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Verde), "1"));
    colors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Laranja), "1"));
    colors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Vermelho), "1"));
    colors.push(this.commonService.hexToRGB(this.typesService.getPesquisaStatusCorTypeColorHex(PesquisaStatusCorType.Purpura), "1"));

    this.chartDatasets = [
      { data: this.chartData, label: 'Questionário - Respostas', backgroundColor: backColors, borderColor: colors, borderWidth: 1 }
    ];

    //Preparar dados Lista
    this.tableItens = [];
    this.tableItens.push({ nomeStatusCor: this.questionario.nomeVerde, statusCor: PesquisaStatusCorType.Verde, total: qtdVerde, percentual: this.calcPercentual(qtdVerde, this.qtdTotal) });
    this.tableItens.push({ nomeStatusCor: this.questionario.nomeLaranja, statusCor: PesquisaStatusCorType.Laranja, total: qtdLaranja, percentual: this.calcPercentual(qtdLaranja, this.qtdTotal) });
    this.tableItens.push({ nomeStatusCor: this.questionario.nomeVermelha, statusCor: PesquisaStatusCorType.Vermelho, total: qtdVermelho, percentual: this.calcPercentual(qtdVermelho, this.qtdTotal) });
    this.tableItens.push({ nomeStatusCor: "Abaixo Limite", statusCor: PesquisaStatusCorType.Purpura, total: qtdPurpura, percentual: this.calcPercentual(qtdPurpura, this.qtdTotal) });

    this.updateDtTrigger();
  }

  private calcPercentual(qtd: number, total: number): number {
    let prc = qtd * 100 / total; 
    return Math.round(prc * 100) / 100;
  }

  private updateDtTrigger() {
    if (this.datatable.dtInstance) {
      this.datatable.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  print() {
    const content =
      `
          <h4 class="text-center font-weight-bold">Questionário - Dashboard Geral</h4>
          </br>
          </br>
          <div class="row">
            <div class="col-6">
              <strong>Questionário:</strong> ${this.questionarioTitulo}
            </div>
            <div class="col-6">
              <strong>Filtro:</strong> ${this.listaFiltro.find(x => x.id === this.filtroId).opcao}
            </div>            
          </div>
          <div class="row">
            <div class="col-8">
              <strong>Período:</strong> ${moment(this.dataInicial).format('DD/MM/YYYY')} à ${moment(this.dataFinal).format('DD/MM/YYYY')}
            </div>
          </div>
          <div class="text-center"><img src="${this.canvas?.nativeElement.toDataURL()}"'/></div><br/>
          ${this.tableRespostas?.nativeElement.innerHTML || ''}
        `;
    this.printService.print('Questionário - Dashboard Geral', content);
  }

}
