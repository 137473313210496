import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AgendamentoRecorrenteModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { CommonService } from 'projects/ProjetoBaseAngular/domain/services';
import { DayOfWeekType } from 'projects/admin/src/domain/types';


@Injectable({
  providedIn: 'root'
})
export class AgendamentoRecorrenteService extends BaseCrudService<AgendamentoRecorrenteModel> {

  constructor(
    injector: Injector,
    readonly commonService: CommonService
  ) {
    super(injector);
  }

  public getController(): string {
    return 'AgendamentoRecorrente';
  }

  getByProfissionalDiaSemana(profissionalRecursoId: string, diaSemana: DayOfWeekType): Observable<AgendamentoRecorrenteModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByProfissionalDiaSemana/${profissionalRecursoId}/${diaSemana}`,  this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByPacienteId(pacienteId: string): Observable<AgendamentoRecorrenteModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByPacienteId/${pacienteId}`,  this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }  

  getByProfissionalPacienteId(profissionalRecursoId: string, pacienteId: string): Observable<AgendamentoRecorrenteModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByProfissionalPacienteId/${profissionalRecursoId}/${pacienteId}`,  this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  updateAgendamentos(modelRecorr: AgendamentoRecorrenteModel): Observable<boolean> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/updateAgendamentos`, JSON.stringify(modelRecorr) ,this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  cancelar(modelRecorr: AgendamentoRecorrenteModel): Observable<boolean> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Cancelar`, JSON.stringify(modelRecorr) ,this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }  
}