import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { ImagemModalComponent } from '../imagem-modal/imagem-modal.component';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-imagem-select-modal',
  templateUrl: './imagem-select-modal.component.html'
})
export class ImagemSelectModalComponent extends BaseComponent implements OnInit {
  @ViewChild('imagemSelectModal') modal: ModalDirective;
  @ViewChild('imagemModal') imagemModal: ImagemModalComponent;
  @Output() modalClose: EventEmitter<ImagemModel> = new EventEmitter<ImagemModel>();
  cropperWidth: number = 0;  

  imagens: ImagemModel[];

  constructor(
    injector: Injector,
    private readonly imagemService: ImagemService,
  ) {
    super(injector);
  }

  private init() {
    this.imagemService.getAll().subscribe(imagens => {
      this.imagens = imagens;
    });
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  showSelect(cropperWidth: number = 0) {
    this.init();
    this.cropperWidth = cropperWidth;
    this.create();
  }  

  select(imagem: ImagemModel) {
    this.modal.hide();
    this.modalClose.emit(imagem);
  }

  close() {
    this.modal.hide();
  }

  create() {
    this.imagemModal.showCreateCropper(this.cropperWidth);
  }

  onResponse(imagem: ImagemModel = null) {
    if (imagem && !this.modal.isShown) {
      this.modalClose.emit(imagem);      
    } else {      
      this.modal.show();
      this.init();      
    }    
  }
}
