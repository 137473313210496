<div bsModal #grupoUsuarioModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="grupoUsuarioModal" aria-hidden="true" [config]="{ignoreBackdropClick: true}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Grupo de Usuarios</h2>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="save()" [formGroup]="form">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" #focus />
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div *ngIf="paginas?.length != 0">
          <div class="row" *ngFor="let pagina of paginas">
            <div class="col-md-12">
              <div class="form-group">
                <h3 class="card-inside-title bg-theme">
                  <span style="cursor: help" data-toggle="tooltip" data-placement="top" title="{{pagina.descricao}}">
                    {{pagina.nome}} <i class="zmdi zmdi-help-outline"></i>
                  </span>
                </h3>
                <mat-slide-toggle style="margin-right: 10px; margin-left: 5px;" color="primary"
                  *ngFor="let permissao of getPermissoesByPagina(pagina)" [(ngModel)]="permissao.temPermissao">
                  <span style="cursor: help" data-toggle="tooltip" data-placement="top"
                    title="{{permissao.acaoDescricao}}">
                    {{permissao.acaoNome}} <i class="zmdi zmdi-help-outline"></i>
                  </span>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <button mat-flat-button  [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>

    </div>
  </div>