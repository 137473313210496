import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ConfigSiteTenantModel } from '../domain/models';
import { ConfigSiteTenantService } from '../domain/services/config-site-tenant.service';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})
export class SeoComponent extends BaseComponent implements OnInit {  
  url: string;
    
  constructor(
    injector: Injector,    
    private readonly configSiteTenantService: ConfigSiteTenantService
  ) {
    super(injector);
    this.url = location.host;
  }

  protected initializeForm(model: ConfigSiteTenantModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      seoTitle: [model.seoTitle],
      seoShortTitle: [model.seoShortTitle],
      seoDescription: [model.seoDescription],
      seoKeywords: [model.seoKeywords],
    });    
  }

  ngOnInit() {
    this.configSiteTenantService.get().subscribe(model => this.initializeForm(model));    
  }  
  
  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: ConfigSiteTenantModel = Object.assign({}, this.form.value);
    this.configSiteTenantService.editByTipo('Seo', model).subscribe(
      () => {        
        this.snackBar.open('Salvo com sucesso!', 'Ok', {
          duration: 3000
        });
      },
      (errors) => this.verifyErrors(errors)
    );
  }
}
