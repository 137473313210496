import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { LayoutMensagemModalComponent } from './layout-mensagem-modal/layout-mensagem-modal.component';
import { LayoutMensagemModel } from '../domain/models';
import { LayoutMensagemService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-layout-mensagem',
  templateUrl: './layout-mensagem.component.html'
})
export class LayoutMensagemComponent extends BaseCrudComponent<LayoutMensagemModel> implements OnInit {
  @ViewChild('modal') modal: LayoutMensagemModalComponent;

  constructor(
    injector: Injector,
    readonly service: LayoutMensagemService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  selectColumns = [ 
    { data: 'tipo'},
    { data: 'nome'}
  ];

  ngOnInit() {
    super.ngOnInit();    
  }
}
