import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ArquivoService } from 'projects/ProjetoBaseAngular/domain/services';
import { RevistaType } from 'projects/admin/src/domain/types';
import { RevistaModel } from '../../domain/models';
import { RevistaService } from '../../domain/services';


@Component({
  selector: 'app-revista-modal',
  templateUrl: './revista-modal.component.html'
})
export class RevistaModalComponent extends BaseCrudModalComponent<RevistaModel> implements OnInit {
  @ViewChild('revistaModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;
  @ViewChild('fileUpload') fileUpload: ElementRef;

  file: any = {};

  tiposRevista = [
    { text: 'Link Externo', value: RevistaType.LinkExterno },
    { text: 'Arquivo Pdf', value: RevistaType.ArquivoPdf }
  ];

  readonly linkExterno = RevistaType.LinkExterno;
  readonly arquivoPdf = RevistaType.ArquivoPdf;

  constructor(
    injector: Injector,
    revistaService: RevistaService,
    private arquivoService: ArquivoService

  ) {
    super(injector, revistaService);
  }

  protected initializeForm(revista: RevistaModel) {
    this.form = this.formBuilder.group({
      id: revista.id,
      dataInclusao: [revista.dataInclusao, Validators.required],
      dataAlteracao: revista.dataAlteracao,
      tipo: [revista.tipo],
      titulo: [revista.titulo, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(200)]
      ],
      data: [this.fromJsonDate(revista.data)],
      edicao: [revista.edicao, [
        Validators.minLength(2),
        Validators.maxLength(100)
      ]],
      imagemUrl: [revista.imagemUrl],
      imagemUrlBase: [revista.imagemUrlBase],
      imagemPath: [revista.imagemPath],

      arquivoUrl: [revista.arquivoUrl],
      arquivoUrlBase: [revista.arquivoUrlBase],
      arquivoPath: [revista.arquivoPath],
      linkExterno: [revista.linkExterno, [
        Validators.minLength(2),
        Validators.maxLength(200)
      ]],
      slug: [revista.slug, [
        Validators.maxLength(50)
      ]],
      publicado: [revista.publicado],
      file: [revista.file]
    });
  }

  beforeShowCreate(model: RevistaModel): RevistaModel {
    model.publicado = false;
    model.tipo = RevistaType.ArquivoPdf;
    return model;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect();
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({ 
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }

  async save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    const revista: RevistaModel = Object.assign({}, new RevistaModel(), this.form.value);

    if (revista.file) {

      const fileUpload = this.fileUpload.nativeElement;
      this.file = { data: fileUpload.files[0], inProgress: true, completed: false };
      const formData = new FormData();
      formData.append('file', this.file.data);

      this.arquivoService.upload(revista.id, revista.slug, revista.titulo, formData).subscribe(
        (fileInfo) => {
          this.file.inProgress = false;
          this.file.completed = true;
          this.form.patchValue({             
            arquivoUrlBase: fileInfo.urlBase,
            arquivoPath: fileInfo.path,
          });
          super.save();
        },
        (errors) => {
          console.log('errors');
          this.file.inProgress = false;
          this.errors = errors;

        });
    } else {
      super.save();
    }

  }
}
