<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Especialidade Médica</h2>
      </div>
      <div class="body table-responsive">

        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let especialidadeMed of models" cdkDrag>
            {{especialidadeMed.nome}}
            <div>
              <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(especialidadeMed)" matTooltip="Editar">
                <mat-icon>edit</mat-icon>
              </button>
              <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(especialidadeMed.id)" matTooltip="Excluir">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    <div class="fixed-action-btn">
      <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<app-especialidade-med-modal #especialidadeMedModal (modalSave)="onResponse($event)"></app-especialidade-med-modal>
