import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ProcedimentoPerguntaComponent } from './procedimento-pergunta.component';
import { ProcedimentoPerguntaModalComponent } from './procedimento-pergunta-modal/procedimento-pergunta-modal.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    ProcedimentoPerguntaComponent,
    ProcedimentoPerguntaModalComponent
  ],
  exports: [
    ProcedimentoPerguntaComponent
  ]
})
export class ProcedimentoPerguntaModule { }
