import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { BaseEntidadePessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { LayoutArtigoType, ProfissionalRecursoType } from 'projects/admin/src/domain/types';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

export class SetorModel extends BaseModel {
  public nome: string;

  public usuarioResponsavelId: string;
  public usuarioResponsavelNome: string;

  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;

  public liberadoContatoExterno: boolean;
}

export class EspecialidadeMedModel extends BaseModel {
  public ordem: number;
  public iconeHtml: string;
  public tituloIcone: string;
  public nome: string;
  public descricao: string;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;
  public areasAtuacao: AreaAtuacaoMedModel[];
}

export class AreaAtuacaoMedModel extends BaseModel {
  public nome: string;
  public ordem: number;
  public especialidadeMedId: string;
  public especialidadeMedNome: string;
  public saibaMaisUrl: string;
}

export class EquipeModel extends BaseEntidadePessoaModel {
  public ordem: number;
  public setorId: string;
  public setorNome: string;
  public cargoId: string;
  public cargoNome: string;
}

export class ProfissionalSaudeAreaAtuacaoMedModel extends BaseModel {
  public profissionalSaudeId: string;
  public areaAtuacaoMedId: string;
}

export class ProfissionalSaudeModel extends BaseEntidadePessoaModel {
  public ordem: number;

  public especialidadeMedId: string;
  public especialidadeMedNome: string;
  public especialidadeMedIconeHtml: string;

  public areasAtuacaoMed: ProfissionalSaudeAreaAtuacaoMedModel[];
  public areaAtuacaoMedIds: string[];
  public areaAtuacaoMedNomes: string[];

  public iconeCrmCrnHtml: string;
  public crmMedico: string;
  public crnMedico: string;
  public rqeMedico: string;

  public nomeApresentacao: string;
  public usuarioId: string;
  public usaConfirmacaoLote: boolean;

  public bannerHotSite: string;
  public bannerHotSiteUrlBase: string;
  public bannerHotSitePath: string;
  public slug: string;
  public temHotsite: boolean;
  public curriculoHtml: string;
  public redesSociais: string;
  public linkFacebook: string;
  public linkYoutube: string;
  public linkInstagram: string;
  public linkTwitter: string;
  public numeroWhatsApp: string;
  public numeroTelegram: string;
  public emailHotSite: string;

  public corPrimaria: string;
  public corSecundaria: string;

  public layoutArtigoHotSite: LayoutArtigoType;

  getNumeroWhatsApp() {
    return this.numeroWhatsApp.replace(/\D/g, '');
  }

  getNumeroTelegram() {
    return this.numeroTelegram.replace(/\D/g, '');
  }
}

export class ProfissionalSaudeDto extends SelectDto {
  public especialidadeId: string;
  public nomeApresentacao: string;  
}

export class ProfissionalRecursoModel extends BaseModel {
  public tipo: ProfissionalRecursoType;
  public nome: string;
  public crmMedico: string;
  public crnMedico: string;
  public rqeMedico: string;
  public salaAtendimentoPadraoId: string;
  public usaConfirmacaoLote: boolean;
}

export class ProfissionalSaudeTextoPadraoModel extends BaseModel {
  public profissionalSaudeId: string;
  public texto: string;
  public palavrasChave: string;
}
