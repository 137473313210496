<div class="row">
  <div class="col-md-12">
    <button mat-flat-button color="primary" type="button" (click)="create()">
      <mat-icon>add</mat-icon> Novo Filtro
    </button>
  </div>
  <hr />
  <div class="col-md-12">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let model of models" cdkDrag>
        {{model.opcao}}
        <div>
          <button mat-icon-button type="button" (click)="edit(model)" matTooltip="Editar">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button type="button" (click)="delete(model.id)" matTooltip="Excluir">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-questionario-filtro-modal #modal [questionarioId]="questionarioId" (modalSave)="onResponse($event)">
</app-questionario-filtro-modal>