import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from '../base-crud.service';
import { CidadeModel } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CidadeService extends BaseCrudService<CidadeModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Cidade';
  }  

  getByEstadoId(estadoId: string): Observable<CidadeModel[]> {
    return this
      .httpClient
      .get<CidadeModel>(`${this.getApiUrl()}${this.getController()}/GetByEstadoId/${estadoId}`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
