import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { AtendimentoSolicExameModel } from '../../../domain/models';
import { AtendimentoSolicExameService } from '../../../domain/services';

@Component({
  selector: 'app-atendimento-solic-exame-historico-modal',
  templateUrl: './atendimento-solic-exame-historico-modal.component.html'
})

export class AtendimentoSolicExameHistoricoModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  @Input('atendimentoId') atendimentoId: string;
  @Input('profissionalId') profissionalId: string;
  @Input('pacienteId') pacienteId: string;
  @Input('detailMode') detailMode: boolean;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() cloneSolicExame: EventEmitter<AtendimentoSolicExameModel> = new EventEmitter<AtendimentoSolicExameModel>();

  //models: AtendimentoSolicExameModel[] = [];
  listaExameDto: any[] = [];

  constructor(
    injector: Injector,
    readonly service: AtendimentoSolicExameService,
    readonly typesService: TypesService,
    readonly sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  async show() {
    this.autoSave.emit();
    
    this.modal.show();
    this.commonService.spinnerOpen("Pesquisando Histórico de Exames...");

    const lista = await this.service.getByPacienteId(this.pacienteId).toPromise();
    if (this.atendimentoId){
      this.listaExameDto = lista.filter(x => x.atendimentoId !== this.atendimentoId);
    } else {
      this.listaExameDto = lista;
    }

    this.listaExameDto.forEach(item => {
      //injetando campo dinâmico para apresentação
      item['descricaoHtml'] = this.service.getDescricaoHtml(item.descricao);
    });
    this.commonService.spinnerClose();

    // this.service.getByPacienteProfissionalId(this.pacienteId, this.profissionalId)
    //   .subscribe(lista => {
    //   this.models = lista.filter(x => x.atendimentoId !== this.atendimentoId);
    //   this.models.forEach(item => {
    //     //injetando campo dinâmico para apresentação
    //     item['descricaoHtml'] = this.service.getDescricaoHtml(item.descricao);
    //   });

    //   this.commonService.spinnerClose();
    // });
  }

  async clonar(model:AtendimentoSolicExameModel){
    let result = await this.commonService.mensagemConfirmacao("Clonar solicitação de exame selecionada?","","question");
    if (result){
      this.close();
      this.cloneSolicExame.emit(model);
    }
  }

  close(){
    this.modal.hide();
  }
}
