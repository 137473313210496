import { Injectable, Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { BaseService } from './base.service';
import { LoginModel, ClaimModel, UsuarioModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  constructor(
    injector: Injector
  ) {

    super(injector);
  }

  login(tenant, user: LoginModel): Observable<LoginModel> {
    return this.httpClient
      .post(`${super.getApiUrl()}Account/Login`, user, {
        headers: new HttpHeaders({ tenant })
      })
      .pipe(
        map(super.extractData),
        catchError(super.serviceError)
      );
  }

  changeUiTheme(userId: string, theme: string): Observable<boolean> {
    return this.httpClient
      .get(`${super.getApiUrl()}Account/ChangeUiTheme`, {
        headers: super.getAuthHeaderJson().headers,
        params: { userId, theme }
      }).pipe(
        map(super.extractData),
        catchError(super.serviceError)
      );
  }

  logout() {
    return this.httpClient
      .post(`${super.getApiUrl()}Account/Logout`, null, this.getAuthHeaderJson())
      .subscribe(() => {
        localStorage.removeItem('app.tenant');
        localStorage.removeItem('app.token');
        localStorage.removeItem('app.expiresAt');
        localStorage.removeItem('app.user');
        localStorage.removeItem('app.claims');
        localStorage.removeItem('app.modulos');
      })
  }

  cookie(response: any) {
    let expiresAt = moment().add(response.expiresInSeconds, 'seconds');

    localStorage.setItem('app.tenant', response.tenant);
    localStorage.setItem('app.token', response.token);
    localStorage.setItem('app.expiresAt', expiresAt.toString());
    localStorage.setItem('app.user', JSON.stringify(response.user));
    localStorage.setItem('app.claims', JSON.stringify(response.claims));
    localStorage.setItem('app.modulos', JSON.stringify(response.modulos));
  }

  getClaims(): ClaimModel[] {
    const claims: ClaimModel[] = JSON.parse(localStorage.getItem('app.claims'));
    return claims;
  }

  getModulos(): string[] {
    const modulos: string[] = JSON.parse(localStorage.getItem('app.modulos'));
    return modulos;
  }

  hasModulo(modulo: string): boolean {
    const modulos = this.getModulos();
    let result = modulos.findIndex(x => x === modulo);
    
    return (result !== -1);
  }

  getUser(): UsuarioModel {
    const user: UsuarioModel = JSON.parse(localStorage.getItem('app.user'));
    return user;
  }

  isAdminKleinCode(): boolean {
    return this.getTenant() == 'kleincode';
  }
}
