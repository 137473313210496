<div class="card">
  <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
  <div class="header" *ngIf="!isBusy">
    <h2>Atendimentos</h2>
  </div>
  <div class="body" *ngIf="!isBusy">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Profissional da Saúde</mat-label>
          <mat-select [(ngModel)]="atendimentoFilterDto.recursoIds" (selectionChange)="changeRecurso($event.value)"
            *ngIf="!atendimentoFilterDto.naoFiltrarRecurso" multiple>
            <mat-option *ngFor="let recurso of recursos" [value]="recurso.value">
              {{recurso.text}}
            </mat-option>
          </mat-select>
          <input matInput disabled value="Todos Profissionais da Saúde"
            *ngIf="atendimentoFilterDto.naoFiltrarRecurso" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Status do Atendimento</mat-label>
          <mat-select [(ngModel)]="atendimentoFilterDto.status" (selectionChange)="filter()" multiple>
            <mat-option *ngFor="let status of typesService.atendimentoStatusType" [value]="status.value">
              {{status.text}}
            </mat-option>
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Cód./CPF Paciente</mat-label>
          <input id="cpfPaciente" #cpf matInput [(value)]="paciente.pessoa.cnpjCpf" type="text"
            (keyup.enter)="pacienteSelect.changeDocumento(cpf.value)"
            (blur)="pacienteSelect.changeDocumento($event.target.value)" />
          <button matSuffix mat-icon-button type="button" (click)="pacienteSelect.showSelect()"
            matTooltip="Selecionar Paciente">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Paciente</mat-label>
          <input matInput disabled [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia" type="text" />
          <button [disabled]="!paciente?.id" matSuffix mat-icon-button type="button"
            (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12 text-center text-md-left" style="margin-bottom: 0px;">
        <div style="display: inline-flex; align-items: center;">
          <mat-form-field>
            <mat-label>Data</mat-label>
            <input #data matInput type="date" placeholder="Data"
              [(ngModel)]="atendimentoFilterDto.data" (change)="filter()"
              *ngIf="!atendimentoFilterDto.naoFiltrarData">            
            <input matInput disabled value="Todas Datas" *ngIf="atendimentoFilterDto.naoFiltrarData">            
            <button matPrefix mat-icon-button color="primary" type="button" matTooltip="Dia anterior"
              (click)="prevDayClick()" [disabled]="atendimentoFilterDto.naoFiltrarData">
              <mat-icon>arrow_back_ios</mat-icon>
            </button>
            <button matSuffix mat-icon-button color="primary" type="button" matTooltip="Próximo dia"
              (click)="nextDayClick()" [disabled]="atendimentoFilterDto.naoFiltrarData">
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div style="display: inline-flex; align-items: center; margin-left: 10px; margin-right: 10px;">
          <button mat-flat-button type="button" [disabled]="isToday || atendimentoFilterDto.naoFiltrarData"
            color="primary" (click)="todayClick()">
            Hoje
          </button>
          <button style="margin-left: 10px; margin-right: 10px;" mat-flat-button type="button" (click)="filter()"
            color="primary" matTooltip="Atualizar Agenda">
            <span class="material-icons">
              refresh
            </span>
          </button>
        </div>
        <div class="row" style="display: inline-flex; align-items: center; margin: 0px;">
          <div class="col-md-12">
            <p style="margin-top: 10px;">
              <mat-slide-toggle color="primary" [disabled]="!atendimentoFilterDto.pacienteId"
                [(ngModel)]="atendimentoFilterDto.naoFiltrarData" (toggleChange)="filter()">
                Não filtrar data
              </mat-slide-toggle>
            </p>
            <p>
              <mat-slide-toggle color="primary" [(ngModel)]="atendimentoFilterDto.naoFiltrarRecurso"
                (toggleChange)="filter()">
                Não filtrar profissional/equipamento
              </mat-slide-toggle>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
        style="width: 100%;">
        <thead>
          <tr>
            <th class="text-center" colspan="1">Entrada</th>
            <th class="text-center" colspan="1">Agend.</th>
            <th class="text-center" colspan="6">Dados do Atendimento</th>
          </tr>
          <tr>
            <th class="text-center" width="120">Data/Hora</th>            
            <th class="text-center" width="30">Horário</th>
            <th class="text-center">Profissional</th>
            <th class="text-center">Classificação</th>
            <th class="text-center">Status</th>
            <th class="text-center">Paciente</th>
            <th class="text-center">Convênio</th>
          </tr>
        </thead>
        <tbody *ngIf="models?.length != 0">
          <tr *ngFor="let item of models" style="cursor: pointer;" (click)="detail(item)">
            <td>{{item.dataHoraEntrada | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td *ngIf="item.agendamentoId">
              <span [ngClass]="typesService.getAtendimentoTypeColor(atendimentoType.Atendimento)" 
                class="label" matTooltip="Atendimento agendado">
                {{item.agendamentoHorario | mask: 'hh:MM'}}
              </span>
            </td>
            <td *ngIf="!item.agendamentoId">
              <span [ngClass]="typesService.getAtendimentoTypeColor(atendimentoType.NaoAgendado)" 
                class="label" matTooltip="Atendimento não agendado">
                Não Ag.
              </span>
            </td>
            <td>{{item.profissionalRecursoNome}}</td>
            <td>{{item.agendamentoClassificacaoNome}}</td>
            <td>
              <span class="badge" [style.backgroundColor]="typesService.getAtendimentoStatusColorType(item.status)"
                [matTooltip]="item.status === typesService.AtendimentoStatusType.Atendido ? 'Ver Detalhes' : 'Iniciar/Continuar Atendimento'">
                {{typesService.getAtendimentoStatusType(item.status)}}
              </span>
            </td>
            <td [innerHTML]="item.pacientePessoaNomeFantasia"></td>
            <td [innerHTML]="item.convenioNome"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-4" style="margin-bottom: 0;">
        
        <mat-form-field>
          <mat-label>Anotações</mat-label>
          <textarea matInput [(ngModel)]="anotacoes" rows="2"></textarea>
          <button matSuffix mat-icon-button type="button" [disabled]="anotacoes===anotacoesOld"
            (click)="saveAnotacoes()" color="primary" matTooltip="Salvar Anotações">
            <mat-icon>done</mat-icon>
          </button>
          <button matSuffix mat-icon-button type="button" (click)="refreshAnotacoes()" color="primary"
            matTooltip="Atualizar Anotações">
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<app-atendimento-modal #modal (modalSave)="onResponse($event)" (atendimentoModal)="atendimentoModal.showDetail($event)">
</app-atendimento-modal>
<app-atendimento-modal #atendimentoModal [readOnly]="true"></app-atendimento-modal>
<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>