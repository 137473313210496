import { NgModule } from '@angular/core';

import { EstadosComponent } from './estados.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { EstadoModalComponent } from './estado-modal/estado-modal.component';

@NgModule({
  imports: [    
    SharedModule    
  ],
  declarations: [
    EstadosComponent,
    EstadoModalComponent
  ]
})
export class EstadosModule { }
