//#region Base
export class BaseWaData
{
    public details: string;
}

export class BaseWaResult<TData extends BaseWaData>
{
    public code: number;
    public data: TData;
    public error: string;
    public success: boolean;
}
//#endregion

//#region Session
export class WaConnectData extends BaseWaData
{
    public events: string;
    public jid: string;
    public webhook: string;
}

export class WaConnectResultDto extends BaseWaResult<WaConnectData>
{
}

export class WaConnectRequestDto
{
    public subscribe: string[];
    public immediate: boolean;
}

export class WaStatusData extends BaseWaData
{
    public connected: boolean;
    public jid: string;
    public loggedIn: boolean;
}

export class WaStatusResultDto extends BaseWaResult<WaStatusData>
{
}

export class WaDisconnectResultDto extends BaseWaResult<BaseWaData>
{
}

export class WaLogoutResultDto extends BaseWaResult<BaseWaData>
{
}

export class WaPairData extends BaseWaData
{
    public events: string;
    public jid: string;
    public pairCode: string;
    public webhook: string;
}

export class WaPairResultDto extends BaseWaResult<WaPairData>
{
}

export class WaQrData extends BaseWaData
{
    public qrCode: string;
}

export class WaQrResultDto extends BaseWaResult<WaQrData>
{
}
//#endregion

//#region Webhook
export class WaWebhookData extends BaseWaData
{
    public subscribe:  string[];
    public webhook: string;
}

export class WaWebhookResultDto extends BaseWaResult<WaWebhookData>
{
}
//#endregion

//#region Server

export class WaServerData extends BaseWaData
{
    public email: string;
    public end: string;
    public type: string;
    public version: string;
}

export class WaServerResultDto extends BaseWaResult<WaServerData>
{
}
//#endregion

//#region User
export class WaUserInfoUser extends BaseWaData
{
    public jid: string;
    public devices: string[];
    public pictureID: string;
    public status: string;
    public verifiedName: string;
}

export class WaUserInfoData extends BaseWaData
{
    public users: WaUserInfoUser[];
}

export class WaUserInfoResultDto extends BaseWaResult<WaUserInfoData>
{
}

export class WaUserCheckUser extends BaseWaData
{
    public isInWhatsapp: boolean;
    public jid: string;
    public query: string;
    public verifiedName: string;
}

export class WaUserCheckData extends BaseWaData
{
    public users: WaUserCheckUser[];
}

export class WaUserCheckResultDto extends BaseWaResult<WaUserCheckData>
{
}

export class WaUserAvatarData extends BaseWaData
{
    public url: string;
    public id: string;
    public type: string;
    public directPath: string;
}

export class WaUserAvatarResultDto extends BaseWaResult<WaUserAvatarData>
{
}
//#endregion

//#region Message
export class WaMessageDownloadData extends BaseWaData
{
    public data: string;
    public mimetype: string;
}
export class WaMessageDownloadResultDto extends BaseWaResult<WaMessageDownloadData>
{
}
//#endregion