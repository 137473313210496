import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { IconeModel, CategoriaIconeModel } from '../../domain/models';
import { IconeService, CategoriaIconeService } from '../../domain/services';

@Component({
  selector: 'app-icone-modal',
  templateUrl: './icone-modal.component.html',
})
export class IconeModalComponent extends BaseCrudModalComponent<IconeModel> implements OnInit {
  @ViewChild('iconeModal') modal: ModalDirective;

  categoriasIcone: CategoriaIconeModel[];

  constructor(
    injector: Injector,
    iconeService: IconeService,
    private categoriaIconeService: CategoriaIconeService
  ) {
    super(injector, iconeService);
  }

  protected initializeForm(icone: IconeModel) {
    this.form = this.formBuilder.group({
      id: icone.id,
      dataInclusao: [icone.dataInclusao, Validators.required],
      dataAlteracao: [icone.dataAlteracao],
      nome: [icone.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
      categoriaIconeId: [icone.categoriaIconeId, Validators.required],
      iconeHtml: [icone.iconeHtml, [
        Validators.maxLength(200)]
     ],
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.categoriaIconeService.getAll().subscribe(categoriasIcone => this.categoriasIcone = categoriasIcone);
  }
}
