import { BaseCrudService } from "projects/ProjetoBaseAngular/domain/services/base-crud.service";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ChatBotRespAlternativaModel } from "../models";


@Injectable({
  providedIn: 'root'
})
export class ChatBotRespAlternativaService extends BaseCrudService<ChatBotRespAlternativaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ChatBotRespAlternativa';
  }

  getByNodoId(id: string): Observable<ChatBotRespAlternativaModel> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByNodoId/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}