import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GrupoPacienteComponent } from './grupo-paciente.component';
import { GrupoPacienteModalComponent } from './grupo-paciente-modal/grupo-paciente-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    GrupoPacienteComponent,
    GrupoPacienteModalComponent
  ]
})
export class GrupoPacienteModule { }
