<div bsModal #modal="bs-modal" class="modal fade" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <h2>Prontuário</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data</mat-label>
                <input matInput formControlName="dataInclusao" type="date" [readonly]/>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Profissional da Saúde</mat-label>
                <mat-select formControlName="profissionalSaudeId">
                  <mat-option value="">Selecione uma opção</mat-option>
                  <mat-option *ngFor="let item of listaProfissionalDto" [value]="item.value">{{item.nomeApresentacao}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Tipo Registro</mat-label>
                <mat-select formControlName="tipo">
                  <mat-option *ngFor="let item of typesService.pacienteProntuarioType" 
                    [disabled]="item.value !== pacienteProntuarioType.Evolucao" 
                    [value]="item.value">{{item.text}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="form.value.tipo === pacienteProntuarioType.Receita">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Tipo Receita</mat-label>
                <mat-select formControlName="receitaTipo">
                  <mat-option *ngFor="let item of typesService.receitaType" [value]="item.value">{{item.text}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>N°. Sequencial</mat-label>
                <input matInput formControlName="sequencial" type="number"/>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
              <editor [disabled]="detailMode" formControlName="descricao" [init]="tinymceConfig(400)"></editor>
          </div>
        </div>
      </form>
      <div class="footer">
        <button mat-flat-button [disabled]="detailMode || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>