import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from '../base-crud.service';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models/galeria/imagem-model';
import { ResponsePage } from '../../models/response-page';
import { PaginaModel } from '../../models/controle-acesso/pagina-model';

@Injectable({
  providedIn: 'root'
})
export class ImagemService extends BaseCrudService<ImagemModel> {  
  constructor(injector: Injector) {
    super(injector);
  }  

  public getController(): string {
    return 'Imagem';
  }

  getAll(): Observable<ImagemModel[]> {
    return this
      .httpClient
      .get<ImagemModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }  

  getByPage(vinculoPaginaIds, page = 1, resultsPerPage = 24): Observable<ResponsePage<ImagemModel>> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByPage`, {
          headers: super.getAuthHeaderJson().headers,
          params: { vinculoPaginaIds, page: page.toString(), resultsPerPage: resultsPerPage.toString() }
      }).pipe(
        map(this.extractData)
      );
  }

  getVinculoPaginas(): Observable<PaginaModel[]> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetVinculoPaginas`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
