import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';

import { ChartOptions, ChartType } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProcedimentoService } from '../../../atendimento/domain/services';
import { PacienteExameIndicadorService, PacienteService } from '../../domain/services';
import { ProcedimentoModel } from '../../../atendimento/domain/models';
import { PacienteModel } from '../../domain/models';

@Component({
  selector: 'app-exame-dashboard-modal',
  templateUrl: './exame-dashboard-modal.component.html'
})

export class ExameDashboardModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('canvas') canvas: any;

  isBusy: boolean;

  exameIndicadorId: string;
  procedimentoModel: ProcedimentoModel;
  pacienteModel: PacienteModel;
  dataInicial: string;
  dataFinal: string;

  listaResultado: any[];

  chartTypes = [
    { text: 'Linha', value: 'line' },
    { text: 'Barra', value: 'bar' }
  ];

  chartOptions: ChartOptions;
  chartLabels: Label[] = [];
  chartData: number[] = [];
  chartDatasets = [];
  chartType: ChartType = 'line';
  chartLegend = true;
  chartPlugins = []; //[ChartDataLabels];
  chartColors = [];

  constructor(
    injector: Injector,
    readonly exameIndService: PacienteExameIndicadorService,
    readonly procedimentoService: ProcedimentoService,
    readonly pacienteService: PacienteService,
    readonly printService: PrintService,
    readonly typesService: TypesService
  ) {
    super(injector);
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
  }

  async show(id: string) {
    const model = await this.exameIndService.getById(id).toPromise();
    this.exameIndicadorId = model.id;

    this.procedimentoModel = await this.procedimentoService.getById(model.procedimentoId).toPromise();
    this.pacienteModel = await this.pacienteService.getById(model.pacienteId).toPromise();

    const now = new Date();
    this.dataInicial = this.fromJsonDate(new Date(now.getFullYear() - 1, now.getMonth(), now.getDay()));
    this.dataFinal = this.fromJsonDate(now);

    this.executarFiltro();
    this.modal.show();
  }

  async executarFiltro() {
    this.commonService.spinnerOpen("Filtrando dados...");
    this.listaResultado = await this.exameIndService.getExameDashboard(this.exameIndicadorId, this.dataInicial, this.dataFinal).toPromise();
    this.commonService.spinnerClose();
    this.aplicarGrafico();

  }

  private aplicarGrafico() {
    this.chartLabels = [];
    this.chartData = [];
    this.chartDatasets = [];
    this.chartOptions = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }],
        xAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };

    this.listaResultado.forEach(item => {
      let data = moment(item.dataReferencia).format('DD/MM/YYYY');
      this.chartLabels.push(data);
      this.chartData.push(item.resultado);
    });

    this.chartDatasets.push({ data: this.chartData, label: this.procedimentoModel.nome });
  }

  close() {
    this.modal.hide();
  }

  print() {
    const content =
      `
          <h4 class="text-center font-weight-bold">Histórico de Resultados de Exames</h4>
          <div class="row">
            <div class="col">
              <strong>Paciente:</strong> ${this.pacienteModel.pessoa.nomeFantasia}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <strong>Tipo de Exame:</strong> ${this.procedimentoModel.nome}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <strong>Período:</strong> ${moment(this.dataInicial).format('DD/MM/YYYY')} à ${moment(this.dataFinal).format('DD/MM/YYYY')}
            </div>
            <div class="col-3">
              <strong>Data:</strong> ${moment().format('DD/MM/YYYY')}
            </div>
          </div>
          <div class="text-center"><img src="${this.canvas?.nativeElement.toDataURL()}"'/></div><br/>
      `;
    this.printService.print('Resultado de Exames', content);
  }

}
