import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { WhatsAppChatComponent } from './whatsapp-chat.component';
import { DocFiltroModalComponent } from './docfiltro-modal/docfiltro-modal.component';
import { ChatFiltroModalComponent } from './chatfiltro-modal/chatfiltro-modal.component';
import { PacienteModule } from '../../paciente/paciente/paciente.module';
import { BotDocModalComponent } from './bot-doc-modal/bot-doc-modal.component';
import { ImgViewModalComponent } from './imgview-modal/imgview-modal.component';
import { WhatsFileUploadModalComponent } from './whats-file-upload-modal/whats-file-upload-modal.component';
import { ImagemApresentacaoModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-apresentacao-modal/imagem-apresentacao-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PacienteModule,
    GaleriaModule
  ],
  declarations: [
    WhatsAppChatComponent,
    DocFiltroModalComponent,
    ChatFiltroModalComponent,
    BotDocModalComponent,
    ImgViewModalComponent,
    WhatsFileUploadModalComponent
  ]
})
export class WhatsAppChatModule { }
