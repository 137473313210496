import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GrupoQuestaoItemModalComponent } from './grupo-questao-item/grupo-questao-item-modal/grupo-questao-item-modal.component';
import { GrupoQuestaoItemComponent } from './grupo-questao-item/grupo-questao-item.component';
import { GrupoQuestaoOpcaoModalComponent } from './grupo-questao-opcao/grupo-questao-opcao-modal/grupo-questao-opcao-modal.component';
import { GrupoQuestaoOpcaoComponent } from './grupo-questao-opcao/grupo-questao-opcao.component';
import { GrupoQuestaoModalComponent } from './grupo-questao/grupo-questao-modal/grupo-questao-modal.component';
import { GrupoQuestaoComponent } from './grupo-questao/grupo-questao.component';
import { QuestionarioModalComponent } from './questionario/questionario-modal/questionario-modal.component';
import { QuestionarioComponent } from './questionario/questionario.component';
import { ChartsModule } from 'ng2-charts';
import { QuestionarioFiltroModalComponent } from './questionario-filtro/questionario-filtro-modal/questionario-filtro-modal.component';
import { QuestionarioFiltroComponent } from './questionario-filtro/questionario-filtro.component';
import { QuestionarioDashQuestaoComponent } from './questionario-dashboard/questionario-dash-questao.component';
import { QuestionarioRespComponent } from './questionario-dashboard/questionario-resp/questionario-resp.component';
import { QuestionarioRespDetalheComponent } from './questionario-dashboard/questionario-resp/questionario-resp-detalhe/questionario-resp-detalhe.component';
import { QuestionarioDashGeralComponent } from './questionario-dashboard/questionario-dash-geral.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [
    SharedModule,
    ChartsModule,
    GaleriaModule
  ],
  declarations: [
    QuestionarioComponent,
    QuestionarioModalComponent,
    QuestionarioFiltroComponent,
    QuestionarioFiltroModalComponent,
    GrupoQuestaoModalComponent,
    GrupoQuestaoComponent,
    GrupoQuestaoItemModalComponent,
    GrupoQuestaoItemComponent,
    GrupoQuestaoOpcaoModalComponent,
    GrupoQuestaoOpcaoComponent,
    QuestionarioDashQuestaoComponent,
    QuestionarioDashGeralComponent,
    QuestionarioRespComponent,
    QuestionarioRespDetalheComponent,
  ]
})
export class PesquisaAdmModule { }
