import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { SetorModel } from '../../../equipe/domain/models';
import { SetorService } from '../../../equipe/domain/services';
import { CargoModel } from '../../domain/models';
import { CargoService } from '../../domain/services';

@Component({
  selector: 'app-cargo-modal',
  templateUrl: './cargo-modal.component.html'
})
export class CargoModalComponent extends BaseCrudModalComponent<CargoModel> implements OnInit {
  @ViewChild('cargoModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  cargos: CargoModel[];
  setores: SetorModel[];

  constructor(
    injector: Injector,
    private cargoService: CargoService,
    private setorService: SetorService
  ) {
    super(injector, cargoService);
  }

  protected initializeForm(cargo: CargoModel) {
    this.form = this.formBuilder.group({
      id: cargo.id,
      dataInclusao: [cargo.dataInclusao, Validators.required],
      dataAlteracao: cargo.dataAlteracao,
      nome: [cargo.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)]
      ],
      setorId: [cargo.setorId, Validators.required],
      cargoSuperiorId: [cargo.cargoSuperiorId],
      missaoObjetivos: [cargo.missaoObjetivos, [
        Validators.minLength(2),
        Validators.maxLength(500)
      ]],
      atividades: [cargo.atividades, [
        Validators.minLength(2),
        Validators.maxLength(500)
      ]],
      experiencia: [cargo.experiencia, [
        Validators.minLength(2),
        Validators.maxLength(500)
      ]],
      formacaoAcademica: [cargo.formacaoAcademica, [
        Validators.minLength(2),
        Validators.maxLength(500)
      ]],
      competencias: [cargo.competencias, [
        Validators.minLength(2),
        Validators.maxLength(500)
      ]],
      treinamentos: [cargo.treinamentos, [
        Validators.minLength(2),
        Validators.maxLength(500)
      ]],
      imagemUrl: [cargo.imagemUrl],
      imagemUrlBase: [cargo.imagemUrlBase],
      imagemPath: [cargo.imagemPath]
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.setorService.get().subscribe(setores => this.setores = setores);
  }

  beforeShowCreate(model: CargoModel) {
    model = super.beforeShowCreate(model);
    this.cargoService.getAsync().subscribe(cargos => this.cargos = cargos);
    return model;
  }

  beforeShowEdit(model: CargoModel) {
    model = super.beforeShowEdit(model);
    this.cargoService.getCargoDiferente(model.id).subscribe(cargos => this.cargos = cargos);
    return model;
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect();
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({ 
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({ 
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }
}
