import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';

@Component({
  selector: 'app-imgview-modal',
  templateUrl: './imgview-modal.component.html'
})
export class ImgViewModalComponent extends BaseComponent implements OnInit{
  @ViewChild('modal') modal: ModalDirective;
  
  //@Input('imgMedia') imgMedia: string;

  imgBase64: string;
  imgHeight: number;
  imgWidth: number;
  
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();  
  }

  show(imgBase64: string, height: number, width: number) {
    this.imgBase64 = imgBase64;
    this.imgHeight = height;
    this.imgWidth = width;

    this.modal.show();
  }

  close() {
    this.modal.hide();
  }
}
