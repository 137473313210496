import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetorComponent } from './setor.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { SetorModalComponent } from './setor-modal/setor-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    SetorComponent,
    SetorModalComponent
  ]
})
export class SetorModule { }
