import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ConfigModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { ChatBotNodoModel } from '../domain/models';
import { ChatBotNodoModalComponent } from './chatbot-nodo-modal/chatbot-nodo-modal.component';
import { ChatBotNodoService } from '../domain/services/chatbot-nodo.service';
import Swal from 'sweetalert2';
import { WhatsChatService } from '../domain/services/whatschat.service';
import { ConfigChatbotConsts, WhatsAppApiEvents } from 'projects/admin/src/domain/consts';
import { ConfigService } from 'projects/ProjetoBaseAngular/domain/services';
import { WhatsAppConfigService } from '../domain/services/whatsAppConfig.service';
import { WaConnectRequestDto } from 'projects/ProjetoBaseAngular/domain/models/whatsAppApi/models';

@Component({
  selector: 'app-chatbot-config',
  templateUrl: './chatbot-config.component.html'
})
export class ChatbotConfigComponent extends BaseCrudComponent<ChatBotNodoModel> implements OnInit {
  @ViewChild('modal') modal: ChatBotNodoModalComponent;

  usuarioWhatsConectado = false;
  usuarioWhatsLogado = false;
  usuarioWhatsJid = "";

  qtdErros: ConfigModel;
  MsgCancCliente: ConfigModel;
  MsgTransfAtendente: ConfigModel;

  selectColumns = [
    { data: 'tipoFluxo' },
    { data: 'mascara' },
    { data: 'nome' },
    { data: 'tipo' },
    { data: 'tipoAtendimento' },
    { data: 'tipoCampo' },
    { data: 'proxNodo' }
  ];

  constructor(
    injector: Injector,
    readonly service: ChatBotNodoService,
    readonly whatsAppConfigService: WhatsAppConfigService,
    readonly whatsChatService: WhatsChatService,
    readonly configService: ConfigService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[1, 'asc'], [2, 'asc']];

    this.sessionGetStatus();
    this.initializeForm();
  }

  async initializeForm() {
    this.qtdErros = await this.configService.getModelByChave(ConfigChatbotConsts.QtdErrosTransfAtend).toPromise();
    this.MsgCancCliente = await this.configService.getModelByChave(ConfigChatbotConsts.MsgCanceladoCliente).toPromise();
    this.MsgTransfAtendente = await this.configService.getModelByChave(ConfigChatbotConsts.MsgTransferidoAtendente).toPromise();

    this.form = this.formBuilder.group({
      qtdErros: [this.qtdErros.valor],
      msgCancCliente: [this.MsgCancCliente.valor],
      msgTransfAtendente: [this.MsgTransfAtendente.valor]
    });
  }

  async saveConfig() {
    this.qtdErros.valor = this.form.value.qtdErros;
    this.MsgCancCliente.valor = this.form.value.msgCancCliente;
    this.MsgTransfAtendente.valor = this.form.value.msgTransfAtendente;

    await this.configService.edit(this.qtdErros).toPromise();
    await this.configService.edit(this.MsgCancCliente).toPromise();
    await this.configService.edit(this.MsgTransfAtendente).toPromise();

    this.commonService.mensagem("Dados salvos com sucesso!", "", "success");
  }

  async sessionGetStatus() {
    try {
      //limpar controles
      this.usuarioWhatsConectado = false;
      this.usuarioWhatsLogado = false;
      this.usuarioWhatsJid = "";
      //

      this.commonService.spinnerOpen('Verificando Status da sessão.');

      const result = await this.whatsAppConfigService.sessionGetStatus().toPromise();
      this.commonService.spinnerClose();

      if (result.code === 200 && result.success) {
        this.usuarioWhatsConectado = result.data.connected;
        this.usuarioWhatsLogado = result.data.loggedIn;
        this.usuarioWhatsJid = result.data.jid;

        let mensagem = `Conectado: ${result.data.connected}`;
        mensagem += `<br>Logado: ${result.data.loggedIn}`;
        mensagem += `<br>Jid: ${result.data.jid}`;

        await this.commonService.mensagem("Status", mensagem, "info");
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("Status", mensagem, "warning");
      }

    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }    
  }

  async sessionConnect() {
    try {
      this.commonService.spinnerOpen('Conectando sessão aos Servidores de WhatsApp.');

      const request = new WaConnectRequestDto();
      request.subscribe = [WhatsAppApiEvents.EventMessage];
      request.immediate = true;

      const result = await this.whatsAppConfigService.sessionConnect(request).toPromise();
      this.commonService.spinnerClose();

      if (result.code === 200 && result.success) {
        let mensagem = `Detalhes: ${result.data.details}`;
        mensagem += `<br>Eventos: ${result.data.events}`;
        mensagem += `<br>Jid: ${result.data.jid}`;
        mensagem += `<br>Webhook: ${result.data.webhook}`;

        await this.commonService.mensagem("Conectado com sucesso!", mensagem, "info");
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("Erro na tentativa de conexão!", mensagem, "warning");
      }

    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }

    this.sessionGetStatus();
  }

  async sessionGetQr() {
    try {
      this.commonService.spinnerOpen('Habilitar fone com QRcode.');

      const result = await this.whatsAppConfigService.sessionGetQr().toPromise();
      this.commonService.spinnerClose();

      debugger

      if (result.code === 200 && result.success) {
        Swal.fire({
          title: '<strong> Habilitar fone com QRcode.</strong>',
          icon: 'success',
          html:
            'Escaneie o QR Code abaixo: <br>' +
            `<img src="${result.data.qrCode}" />`,
          showConfirmButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Fechar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.sessionGetStatus();
          }
        });

        return;
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("QRcode retornou um erro!", mensagem, "warning");
      }

    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }

    this.sessionGetStatus();
  }

  async sessionPair() {
    try {
      this.commonService.spinnerOpen('Habilitar fone com Pair code.');

      let fone = "554899339721";

      const result = await this.whatsAppConfigService.sessionPair(fone).toPromise();
      this.commonService.spinnerClose();

      if (result.code === 200 && result.success) {
        let mensagem = `Detalhes: ${result.data.details}`;
        mensagem += `<br>Eventos: ${result.data.events}`;
        mensagem += `<br>Jid: ${result.data.jid}`;
        mensagem += `<br>PairCode: ${result.data.pairCode}`;
        mensagem += `<br>Webhook: ${result.data.webhook}`;

        await this.commonService.mensagem("Conectado com sucesso!", mensagem, "info");
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("Erro com PairCode!", mensagem, "warning");
      }

    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }

    this.sessionGetStatus();
  }

  async sessionDisconnect() {
    try {
      this.commonService.spinnerOpen('Desconectando sessão aos Servidores de WhatsApp.');

      const result = await this.whatsAppConfigService.sessionDisconnect().toPromise();
      this.commonService.spinnerClose();

      if (result.code === 200 && result.success) {
        let mensagem = `Detalhes: ${result.data.details}`;

        await this.commonService.mensagem("Desconectado com sucesso!", mensagem, "info");
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("Erro ao Desconectar!", mensagem, "warning");
      }

    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }

    this.sessionGetStatus();
  }

  async sessionLogout() {
    try {
      this.commonService.spinnerOpen('Efetuando logout da sessão.');

      const result = await this.whatsAppConfigService.sessionLogout().toPromise();
      this.commonService.spinnerClose();

      if (result.code === 200 && result.success) {
        let mensagem = `Detalhes: ${result.data.details}`;

        await this.commonService.mensagem("Logout efetuado com sucesso!", mensagem, "info");
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("Erro de logout!", mensagem, "warning");
      }
    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }

    this.sessionGetStatus();
  }

  async webHookSet() {
    try {
      this.commonService.spinnerOpen('Configurar o Webhook da sessão.');

      const result = await this.whatsAppConfigService.webHookSet().toPromise();
      this.commonService.spinnerClose();

      if (result.code === 200 && result.success) {
        let mensagem = `Webhook: ${result.data.webhook}`;

        await this.commonService.mensagem("Webhook configurado com sucesso!", mensagem, "info");
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("Erro de Webhook!", mensagem, "warning");
      }
    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }
  }

  async webHookGet() {
    try {
      this.commonService.spinnerOpen('Verificando o Webhook da sessão.');

      const result = await this.whatsAppConfigService.webHookGet().toPromise();
      this.commonService.spinnerClose();

      if (result.code === 200 && result.success) {
        let mensagem = `Subscribe: ${result.data.subscribe}`;
        mensagem += `<br>Webhook: ${result.data.webhook}`;

        await this.commonService.mensagem("Webhook configurado com sucesso!", mensagem, "info");
      } else {
        let mensagem = `Erro: ${result.error}`;

        await this.commonService.mensagem("Erro de Webhook!", mensagem, "warning");
      }
    } catch (errors) {
      this.verifyErrors(errors);
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('<br>'),
        'error');
    }
  }
  
}
