import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaAtuacaoMedComponent } from './area-atuacao-med.component';
import { AreaAtuacaoMedModalComponent } from './area-atuacao-med-modal/area-atuacao-med-modal.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    AreaAtuacaoMedComponent,
    AreaAtuacaoMedModalComponent
  ]
})
export class AreaAtuacaoMedModule { }
