import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ConvenioDto, ConvenioModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { ConvenioResponsePage } from 'projects/site/src/app/view-models/convenios-view-model';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';


@Injectable({
  providedIn: 'root'
})
export class ConvenioService extends BaseCrudService<ConvenioModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'convenio';
  }

  get(searchTerm, cidadeIds, ordenacao, page, resultsPerPage = '10'): Observable<ConvenioResponsePage> {
    return this
      .httpClient
      .get<ConvenioModel>(`${this.getApiUrl()}${this.getController()}`, {
        headers: super.getAuthHeaderJson().headers,
        params: { searchTerm, cidadeIds, ordenacao, page, resultsPerPage }
      }).pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  updateOrdens(convenioIds) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, convenioIds, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelectList(): Observable<ConvenioDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByMatricula(matricula: string): Observable<ConvenioModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByMatricula/${matricula}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
