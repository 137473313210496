import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { TypesService } from 'projects/admin/src/domain/services';
import { StatusPublicacaoType } from 'projects/admin/src/domain/types';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { QuestionarioModel } from '../domain/models';
import { QuestionarioService } from '../domain/services';
import { QuestionarioModalComponent } from './questionario-modal/questionario-modal.component';

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html'
})

export class QuestionarioComponent extends BaseCrudComponent<QuestionarioModel> implements OnInit {
  @ViewChild('questionarioModal') modal: QuestionarioModalComponent;

  search = new ModelSearch('titulo', OptionSearchType.Contains, SearchType.String);
  searchStatus = new ModelSearch('statusPublicacao', OptionSearchType.Equals, SearchType.Int);

  statusPublicacaoType: typeof StatusPublicacaoType = StatusPublicacaoType;

  selectColumns = [
    { data: 'statusPublicacao' },
    { data: 'titulo' },
    { data: 'descricaoInterna' }
  ];

  constructor(
    injector: Injector,
    readonly service: QuestionarioService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchStatus.searchTerm = -1;
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];

    (this.search.searchTerm) && searches.push(this.search);
    (this.searchStatus.searchTerm >= 0) && searches.push(this.searchStatus);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  async publicar(model: QuestionarioModel) {
    let result = await this.commonService.mensagemConfirmacao("Publicar Questionário de Pesquisa?", "O questionário será habilitado para pesquisa de satisfação", "info");
    if (result) {
      let modelPublicado = await this.service.getQuestionarioAtivo().toPromise();
      if (!modelPublicado.id) {
        await this.service.publicar(model.id).toPromise();
        this.filter();
      } else {
        this.commonService.mensagem("Publicação não pôde ser efetuada.", "Já existe uma pesquisa com publicação ativa.", "warning");
      }
    }
  }

  async encerrar(model: QuestionarioModel) {
    let result = await this.commonService.mensagemConfirmacao("Encerrar Publicação de Pesquisa?", "O questionário será desabilitado para pesquisas de satisfação.", "info");
    if (result) {
      await this.service.encerrar(model.id).toPromise();
      this.filter();
    }
  }

  duplicar(model: QuestionarioModel) {
    // const newModel: QuestionarioModel = { ...model };
    // newModel.statusPublicacao = StatusPublicacaoType.Rascunho;
    // newModel.titulo = '';
    // newModel.id = this.commonService.newGuid();

    // model.opcoes.forEach(opcao => {
    //   opcao.enqueteId = model.id;
    //   opcao.id = this.commonService.newGuid();
    // });

    // this.modal.showCriarDuplicada(newModel);
  }  
}

