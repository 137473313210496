import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TypesService } from 'projects/admin/src/domain/services';

import { CidadeModel, EstadoModel, TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { CidadeService, CommonService, EstadoService, TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProfissionalSaudeModel } from '../../../equipe/domain/models';
import { ProfissionalSaudeService } from '../../../equipe/domain/services';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { DocSusConfigModel, DocSusModel } from '../../domain/models';
import { DocSusService } from '../../domain/services';
import { DocSusConfigService } from '../../domain/services/doc-sus.-config.service';

@Component({
  selector: 'app-doc-sus-print',
  templateUrl: './doc-sus-print.component.html'
})
export class DocSusPrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  tenant: TenantModel;
  docSus: DocSusModel;
  docSusConfig: DocSusConfigModel;
  paciente: PacienteModel;
  profissionalAutorizador: ProfissionalSaudeModel;
  profissionalSolicitante: ProfissionalSaudeModel;
  cidade: CidadeModel;
  endereco: string;

  constructor(
    readonly tenantService: TenantService,
    readonly docSusService: DocSusService,
    readonly docSusConfigService: DocSusConfigService,
    readonly pacienteService: PacienteService,
    readonly cidadeService: CidadeService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly printService: PrintService,
    readonly typesService: TypesService,
    readonly commonService: CommonService
  ) { }

  ngOnInit() {
    this.tenantService.getCurrentTenant().subscribe(x => this.tenant = x);
  }

  async print(docSusId: string) {
    let docSusConfigId = this.commonService.getGuidSequencial("1");
    this.docSusConfig = await this.docSusConfigService.getById(docSusConfigId).toPromise();
    this.docSus = await this.docSusService.getById(docSusId).toPromise();
    this.profissionalAutorizador = null;
    if (this.docSus.profissionalAutorizadorId) {
      this.profissionalAutorizador = await this.profissionalSaudeService.getById(this.docSus.profissionalAutorizadorId).toPromise();
    }

    this.profissionalSolicitante = await this.profissionalSaudeService.getById(this.docSus.profissionalSolicitanteId).toPromise();
    this.paciente = await this.pacienteService.getById(this.docSus.pacienteId).toPromise();
    this.endereco = this.paciente.pessoa.ruaAvenida;
    if (this.paciente.pessoa.numero){
      this.endereco += `, N°. ${this.paciente.pessoa.numero}`;
    }
    if (this.paciente.pessoa.complemento){
      this.endereco += ` ${this.paciente.pessoa.complemento}`;
    }
    if (this.paciente.pessoa.bairro){
      this.endereco += `, ${this.paciente.pessoa.bairro}`;
    }
    
    if (this.paciente.pessoa.cidadeId){
      this.cidade = await this.cidadeService.getById(this.paciente.pessoa.cidadeId).toPromise();
    }
    

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Documento dos SUS', content);
    }, 500);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }
}

