import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { EquipeModel, SetorModel } from '../domain/models';
import { EspecialidadeMedService, EquipeService, AreaAtuacaoMedService, SetorService } from '../domain/services';
import { EquipeModalComponent } from './equipe-modal/equipe-modal.component';
import { BaseEntidadePessoasComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoas.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { CargoModel } from '../../rh/domain/models';
import { CargoService } from '../../rh/domain/services';

@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.scss']
})
export class EquipeComponent extends BaseEntidadePessoasComponent<EquipeModel> implements OnInit {
  @ViewChild('equipeModal') modal: EquipeModalComponent;

  cargos: CargoModel[];
  setores: SetorModel[];
  equipes: EquipeModel[];

  searchSetor = new ModelSearch('setor.id', OptionSearchType.Equals, SearchType.Guid);
  searchCargo = new ModelSearch('cargo.id', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('pessoa.nomeFantasia', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'setor.nome' }, 
    { data: 'cargo.nome' },
    { data: 'pessoa.nomeFantasia' }
  ];

  constructor(
    injector: Injector,
    readonly equipeService: EquipeService,
    readonly especialidadeMedService: EspecialidadeMedService,
    readonly areaAtuacaoService: AreaAtuacaoMedService,
    readonly setorService: SetorService,
    readonly cargoService: CargoService,
  ) {
    super(injector, equipeService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchSetor.searchTerm) && searches.push(this.searchSetor);
    (this.searchCargo.searchTerm) && searches.push(this.searchCargo);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();    
    this.setorService.get().subscribe(setores => this.setores = setores);
  }

  changeSetor(setorId) {
    if (setorId) {
      this.cargoService.getBySetorId(setorId)
      .subscribe(cargos => this.cargos = cargos);
    } else {
      this.cargos = [];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.equipes, event.previousIndex, event.currentIndex);
    const ids = this.equipes.map(x => x.id);
    this.equipeService.updateOrdens(ids).subscribe();
  }

  atualizaOrdem() {
    this.equipeService.getAll().subscribe(x => this.equipes = x);
  }
}
