import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';

import { RequisicaoLgpdModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RequisicaoLgpdService extends BaseCrudService<RequisicaoLgpdModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'RequisicaoLgpd';
  }

  getAsync(): Observable<RequisicaoLgpdModel[]> {
    return this
      .httpClient
      .get<RequisicaoLgpdModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  send(model: RequisicaoLgpdModel): Observable<RequisicaoLgpdModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Send`, JSON.stringify(model), this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
