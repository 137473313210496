<div bsModal #politicaPrivacidadeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="setorModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}"
  (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2> Politica de Privacidade </h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">

            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data Inclusão</mat-label>
                <input matInput formControlName="dataInclusao" type="date" readonly />
                <mat-error>Informe uma Data válida.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Versão</mat-label>
                <input matInput formControlName="versao" type="number" />
                <mat-error>Informe uma Versão válida.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Sub-Versão</mat-label>
                <input matInput formControlName="subVersao" type="number" />
                <mat-error>Informe uma SubVersão válida.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <h4 class="card-inside-title">Encarregado de Dados (DPO)</h4>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nomeDpo" type="text" />
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput formControlName="emailDpo" type="email" />
                <mat-error>Informe um E-mail válido.</mat-error>
              </mat-form-field>
            </div>
          </div>

          <h4 class="card-inside-title">HTML</h4>
          <div class="row">
            <div class="col-md-12">
              <editor formControlName="html" [init]="tinymceConfig(350)">
              </editor>
            </div>
          </div>


        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
