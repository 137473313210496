import { Injectable, Injector } from '@angular/core';
import { ArtigoModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ResponsePage } from 'projects/ProjetoBaseAngular/domain/models/response-page';
import { LocalPublicacaoType, LayoutArtigoType } from 'projects/admin/src/domain/types';

@Injectable({
  providedIn: 'root'
})
export class ArtigoService extends BaseCrudService<ArtigoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'artigo';
  }

  get(localPublicacao, corpoClinicoSlug = '', tagSlug = '', searchTerm = '', page = '1', resultsPerPage = '10'): Observable<ResponsePage<ArtigoModel>> {
    localPublicacao = localPublicacao || '';
    return this.httpClient
      .get<ArtigoModel>(`${this.getApiUrl()}${this.getController()}`, {
        headers: super.getHeaderJson().headers,
        params: { localPublicacao, corpoClinicoSlug, tagSlug, searchTerm, page, resultsPerPage }
      }).pipe(
        map(this.extractData)
      );
  }

  getByTag(tagSlug = '', searchTerm = '', page = '1', resultsPerPage = '10'): Observable<ResponsePage<ArtigoModel>> {
    return this.httpClient
      .get<ArtigoModel>(`${this.getApiUrl()}${this.getController()}`, {
        headers: super.getHeaderJson().headers,
        params: { tagSlug, searchTerm, page, resultsPerPage }
      }).pipe(
        map(this.extractData)
      );
  }

  getRelacionados(id, page = '1', resultsPerPage = '2'): Observable<ResponsePage<ArtigoModel>> {
    return this.httpClient
      .get<ArtigoModel>(`${this.getApiUrl()}${this.getController()}/GetRelacionados/${id}`, {
        headers: super.getHeaderJson().headers,
        params: { page, resultsPerPage }
      }).pipe(
        map(this.extractData)
      );
  }

  getBySlug(slug: string): Observable<ArtigoModel> {
    return this
      .httpClient
      .get<ArtigoModel>(`${this.getApiUrl()}${this.getController()}/GetBySlug/${slug}`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  ativarArtigo(id: string): Observable<ArtigoModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/AtivarArtigo/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  inativarArtigo(id: string): Observable<ArtigoModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/InativarArtigo/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getLayoutArtigo(localPublicacao: LocalPublicacaoType): Observable<LayoutArtigoType> {
    return this
      .httpClient
      .get<ArtigoModel>(`${this.getApiUrl()}${this.getController()}/GetLayoutArtigo/${localPublicacao}`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }
}
