import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';

import { TypesService } from 'projects/admin/src/domain/services';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { GrupoQuestaoModel, QuestionarioFiltroModel } from '../domain/models';
import { GrupoQuestaoService, QuestionarioFiltroService } from '../domain/services';
import { GrupoQuestaoModalComponent } from './grupo-questao-modal/grupo-questao-modal.component';

@Component({
  selector: 'app-grupo-questao',
  templateUrl: './grupo-questao.component.html'
})

export class GrupoQuestaoComponent extends BaseCrudComponent<GrupoQuestaoModel> implements OnInit {
  @ViewChild('modal') modal: GrupoQuestaoModalComponent;

  @Input('questionarioId') questionarioId: string;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  listaFiltros: QuestionarioFiltroModel[];
    
  constructor(
    injector: Injector,
    readonly service: GrupoQuestaoService,
    readonly filtroService: QuestionarioFiltroService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
    this.useDatatables = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.service.getByQuestionarioId(this.questionarioId).subscribe(x => this.models = x);    
    this.filtroService.getByQuestionarioId(this.questionarioId).subscribe(x => this.listaFiltros = x);    
  }  

  getFiltroDescricao(id): string{
    let result = this.listaFiltros.find(x => x.id === id);
    if (result){
      return result.opcao;
    }
    return "";
  }

  create(model: GrupoQuestaoModel = null) {
    super.create(model);
    this.autoSave.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.models, event.previousIndex, event.currentIndex);
    const ids = this.models.map(x => x.id);
    this.service.updateOrdens(ids).subscribe();
  }
}

