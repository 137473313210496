<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <h2>Visualização de Imagem</h2>
      </div>
      <div class="modal-body text-center" *ngIf="imgBase64">
        <img src={{imgBase64}} alt="" height={{imgHeight}} width={{imgWidth}}>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>