import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { CategoriaIconeModel } from '../../domain/models';
import { CategoriaIconeService } from '../../domain/services';

@Component({
  selector: 'app-categoria-icone-modal',
  templateUrl: './categoria-icone-modal.component.html',
})
export class CategoriaIconeModalComponent extends BaseCrudModalComponent<CategoriaIconeModel> implements OnInit {
  @ViewChild('categoriaIconeModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  constructor(
    injector: Injector,
    categoriaIconeService: CategoriaIconeService
  ) {
    super(injector, categoriaIconeService);
  }

  protected initializeForm(categoriaIcone: CategoriaIconeModel) {
    this.form = this.formBuilder.group({
      id: categoriaIcone.id,
      dataInclusao: [categoriaIcone.dataInclusao, Validators.required],
      dataAlteracao: categoriaIcone.dataAlteracao,
      nome: [categoriaIcone.nome, [
        Validators.required,
        Validators.maxLength(60)
      ]]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
