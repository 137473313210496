<div class="card main-content">
  <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
  <div class="header">
    <h2>Dados Empresa</h2>
  </div>
  <div class="body table-responsive">
    <!--query end-->
    <table class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center" width="150">Data Alteração</th>
          <th class="text-center" width="100">Subdomínio</th>
          <th class="text-center">Nome</th>
          <th class="text-center" width="150">CNPJ/CPF</th>          
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="empresa">
        <tr>
          <td>
            {{empresa.dataAlteracao ? (empresa.dataAlteracao | date :'dd/MM/yyyy HH:mm'): 'Sem alterações'}}            
          </td>          
          <td>
            {{empresa.subdominio}}
          </td>
          <td>
            {{empresa.nomeFantasia}}
          </td>
          <td>
            {{empresa.cnpjCpf}}
          </td>          
          <td class="text-center">
            <button mat-icon-button type="button" (click)="editarDados()"
              matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>            
          </td>
        </tr>
      </tbody>
    </table>
  </div>  
</div>

<app-empresa-modal #empresaModal (modalSave)="onResponse($event)"></app-empresa-modal>
