import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { ContatoPreAgendamentoStatusType, ContatoStatusType, TurnoType } from './types';

export class ContatoSiteModel extends BaseModel {
  public nome: string;
  public email: string;
  public telefone: string;
  public celular: string;
  public mensagem: string;
  public setorId: string;
  public setorNome: string;
  public status: ContatoStatusType;
  public data: Date;
  public dataStatus: Date;
  public usuarioResponsavelId: string;
  public usuarioResponsavelNome: string;
  public observacaoInterna: string;
}

export class ContatoPreAgendamentoModel extends BaseModel {
  public nome: string;
  public email: string;
  public celular: string;
  public status: ContatoPreAgendamentoStatusType;
  public dataStatus: Date;
  public observacao: string;
  public observacaoInterna: string;
  public convenioId: string;
  public consultaId: string;
  public exameId1: string;
  public exameId2: string;
  public profissionalSaudeId: string;
  public turno: TurnoType;
}
