import { Component, OnInit, ViewChild, Output, EventEmitter, Injector, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';

import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ProcedimentoConvenioProfissionalModel } from '../../../domain/models';
import { ProcedimentoConvenioProfissionalService } from '../../../domain/services/procedimento-convenio-profissional.service';
import { ProfissionalSaudeService } from '../../../../equipe/domain/services';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';

@Component({
  selector: 'app-procedimento-convenio-profissional-modal',
  templateUrl: './procedimento-convenio-profissional-modal.component.html'
})
export class ProcedimentoConvenioProfissionalModalComponent extends BaseCrudModalComponent<ProcedimentoConvenioProfissionalModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  @Input('procedimentoConvenioId') procedimentoConvenioId: string;
  @Output() modalSave: EventEmitter<ProcedimentoConvenioProfissionalModel> = new EventEmitter<ProcedimentoConvenioProfissionalModel>();

  listaProfissional: SelectDto[];

  constructor(
    injector: Injector,
    readonly service: ProcedimentoConvenioProfissionalService,
    readonly profissionalService: ProfissionalSaudeService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.profissionalService.getSelectList().subscribe(x => this.listaProfissional = x);
  }

  protected initializeForm(model: ProcedimentoConvenioProfissionalModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      procedimentoConvenioId: [model.procedimentoConvenioId, Validators.required],
      profissionalSaudeId: [model.profissionalSaudeId, Validators.required],
      percLaudo: [model.percLaudo, Validators.required],
      percClinica: [model.percClinica, Validators.required]
    });
  };

  protected newModel(): ProcedimentoConvenioProfissionalModel {
    let model = new ProcedimentoConvenioProfissionalModel();
    model.id = this.commonService.newGuid();
    model.dataInclusao = new Date();   
    model.procedimentoConvenioId = this.procedimentoConvenioId;
    model.profissionalSaudeId = "";

    return model;
  };  
}
