import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProcedimentoService } from '../../atendimento/domain/services';
import { ProfissionalSaudeService } from '../../equipe/domain/services';
import { ConvenioService } from '../../parceiro/domain/services/convenio.service';
import { RelatorioService } from '../domain/services';

@Component({
  selector: 'app-rel-tabela',
  templateUrl: './rel-tabela.component.html',
})
export class RelTabelaComponent extends BaseComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;
  convenios: SelectDto[];
  procedimentos: SelectDto[];

  convenioIds: string[] = [];
  procedimentoIds: string[] = [];

  listaDto: any[] = [];
  listaImpressao: any[] = [];

  isBusy: boolean;
  contentPrinted: boolean = false;

  constructor(
    injector: Injector,
    readonly service: RelatorioService,
    readonly convenioService: ConvenioService,
    readonly procedimentoService: ProcedimentoService,
    readonly printService: PrintService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.isAllowed()) {
      this.commonService.mensagem("", "Você não tem permissão de execução de relatórios!", "info");
    }

    this.convenioService.getSelectList().subscribe(x => this.convenios = x);
    this.procedimentoService.getInternoSelectList().subscribe(x => this.procedimentos = x);
  }

  isAllowed(): boolean {
    return super.isAllowed("Read", "RelTabelasPreco");
  }

  async print() {
    //Para limar o Html
    this.listaImpressao = [];

    this.commonService.spinnerOpen("Filtrando Relatório...")
    this.isBusy = true;
    const filterDto = {
      convenioIds: this.convenioIds,
      procedimentoIds: this.procedimentoIds
    }

    this.listaDto = await this.service.getTabela(filterDto).toPromise();

    if (this.listaDto.length > 0) {
      this.commonService.spinnerOpen("Preparando Impressão...");
      
      this.contentPrinted = false;
      this.preparaRelatorio();
    } else {
      this.commonService.spinnerClose();
      this.isBusy = false;
      this.commonService.mensagem("Não foram encontrados dados para esta seleção", "", "info");
    }
  }

  onContentPrinted() {
    this.contentPrinted = true;
    const content = this.relatorio.nativeElement.innerHTML;
    this.printService.print('Relatório de Repasse Médico', content);
    this.isBusy = false;
    this.commonService.spinnerClose();
  }

  preparaRelatorio() {
    this.listaImpressao = [];
    let lista = this.commonService.groupBy(this.listaDto, "procedimentoNome");

    for (let prop in lista) {
      let obj = new Object();
      obj["nome"] = prop;
      
      obj["itens"] = [...lista[prop]];

      this.listaImpressao.push(obj);
    }
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }

  get convenioNomes(): string {
    return this.convenios?.filter(x => this.convenioIds.includes(x.value)).map(x => x.text).join(', ') || 'Todos';
  }

  get procedimentoNomes(): string {
    return this.procedimentos?.filter(x => this.procedimentoIds.includes(x.value)).map(x => x.text).join(', ') || 'Todos';
  }
}
