import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';

import { TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProfissionalSaudeModel } from '../../../../equipe/domain/models';
import { ProfissionalSaudeService } from '../../../../equipe/domain/services';
import { PacienteModel } from '../../../../paciente/domain/models';
import { PacienteService } from '../../../../paciente/domain/services';
import { AtendimentoReceitaModel } from '../../../domain/models';
import { AtendimentoService } from '../../../domain/services';

@Component({
  selector: 'app-atendimento-receita-print',
  templateUrl: './atendimento-receita-print.component.html'
})
export class AtendimentoReceitaPrintComponent extends BaseComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  tenant: TenantModel;
  receita: AtendimentoReceitaModel;
  paciente: PacienteModel;
  profissionalSaude: ProfissionalSaudeModel;
  mostrarData: boolean = true;
  fsValue = 'fs-36';

  constructor(
    injector: Injector,
    readonly tenantService: TenantService,
    readonly atendimentoService: AtendimentoService,
    readonly pacienteService: PacienteService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly printService: PrintService,
    readonly typesService: TypesService
    //readonly sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async print(receita: AtendimentoReceitaModel, mostrarData: boolean = true) {
    this.tenant = await this.tenantService.getCurrentTenant().toPromise();

    this.receita = receita;
    this.mostrarData = mostrarData;
    const atendimento = await this.atendimentoService.getById(receita.atendimentoId).toPromise();
    //const atendimento = await this.atendimentoService.getById(atendimentoId).toPromise();
    this.profissionalSaude = await this.profissionalSaudeService.getByProfissionalRecursoId(atendimento.profissionalRecursoId).toPromise();
    
    this.paciente = await this.pacienteService.getById(atendimento.pacienteId).toPromise();

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Receitas', content);
    }, 500);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }
}
