import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { TenantModel, EstadoModel, CidadeModel, DataTablesRequest, } from 'projects/ProjetoBaseAngular/domain/models';
import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { EmpresaService } from './domain/servides';
import { EmpresaModalComponent } from './empresa-modal/empresa-modal.component';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html'
})
export class EmpresaComponent extends BaseCrudComponent<TenantModel> implements OnInit {
  @ViewChild('empresaModal') modal: EmpresaModalComponent;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;

  empresa: TenantModel;

  verifyPermissionRead = false;  

  constructor(
    injector: Injector,
    private readonly empresaService: EmpresaService,    
  ) {
    super(injector, empresaService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.useDatatables = false;
    this.empresaService.getEmpresa().subscribe(x => this.empresa = x);    
  }

  editarDados() {
    this.modal.showEditarDados();
  }
}