import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { QuestionarioFiltroModel} from '../../domain/models';
import { QuestionarioFiltroService, QuestionarioRespService } from '../../domain/services';
import { QuestionarioRespDetalheComponent } from './questionario-resp-detalhe/questionario-resp-detalhe.component';

@Component({
  selector: 'app-questionario-resp',
  templateUrl: './questionario-resp.component.html'
})

export class QuestionarioRespComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;
  @ViewChild("detalheResp") detalheResp: QuestionarioRespDetalheComponent;

  searchQuestionario = new ModelSearch('questionarioId', OptionSearchType.Equals, SearchType.Guid);
  searchFiltro = new ModelSearch('filtroId', OptionSearchType.Equals, SearchType.Guid);
  searchData1 = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime);
  searchData2 = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime);

  selectColumns = [
    { data: 'codigo' },
    { data: 'dataInclusao' },
    { data: 'statusCor' },
    { data: 'pontuacao' },
    { data: 'filtro.opcao' }
  ];

  dtOptions: DataTables.Settings;
  models: any[];

  listaFiltro: QuestionarioFiltroModel[];
  questionarioTitulo: string;
  questionarioId: string;
  
  constructor(
    injector: Injector,
    readonly service: QuestionarioRespService,
    readonly filtroService: QuestionarioFiltroService,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  private configDatatables() {
    this.dtOptions = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      pageLength: 10,
      searching: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters = this.beforeDtRequest(dataTablesParameters);
        this.service.getResponse(dataTablesParameters).subscribe(
          (resp) => {
            this.models = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          },
          () => {
          });
      },
      columns: this.selectColumns
    };
  }

  async show(questionarioId: string, titulo: string) {
    this.questionarioId = questionarioId;
    this.questionarioTitulo = titulo;
    
    this.listaFiltro = await this.filtroService.getByQuestionarioId(questionarioId).toPromise();
    this.searchFiltro.searchTerm = this.listaFiltro[0].id;

    const now = new Date();
    this.searchData1.searchTerm = this.fromJsonDate(new Date(now.getFullYear(), now.getMonth(), 1));
    this.searchData2.searchTerm = this.fromJsonDate(new Date(now.getFullYear(), now.getMonth() + 1, 0));

    this.configDatatables();

    this.filtrar();

    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {

    dataTablesParameters.searches = dataTablesParameters.searches || [];

    this.searchQuestionario.searchTerm = this.questionarioId;
    dataTablesParameters.searches.push(this.searchQuestionario);
    (this.searchFiltro.searchTerm) && dataTablesParameters.searches.push(this.searchFiltro);
    (this.searchData1.searchTerm) && dataTablesParameters.searches.push(this.searchData1);
    (this.searchData2.searchTerm) && dataTablesParameters.searches.push(this.searchData2);

    dataTablesParameters.selectColumns = this.selectColumns;
    return dataTablesParameters;
  }

  filtrar() {
    this.datatable?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  detail(id: string){
    this.detalheResp.show(id);
  }
}
