import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TypesService } from 'projects/admin/src/domain/services';

import { TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProfissionalRecursoModel } from '../../../../equipe/domain/models';
import { PacienteModel } from '../../../../paciente/domain/models';
import { PacienteService } from '../../../../paciente/domain/services';
import { AtendimentoModel } from '../../../domain/models';
import { AtendimentoService } from '../../../domain/services';
import { ProfissionalRecursoService } from '../../../domain/services/profissional-recurso.service';

@Component({
  selector: 'app-atendimento-prontuario-print',
  templateUrl: './atendimento-prontuario-print.component.html'
})
export class AtendimentoProntuarioPrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  tenant: TenantModel;  
  paciente: PacienteModel;
  profissionalRecurso: ProfissionalRecursoModel;
  atendimento: AtendimentoModel;  

  constructor(
    readonly tenantService: TenantService,
    readonly atendimentoService: AtendimentoService,
    readonly pacienteService: PacienteService,    
    readonly profissionalRecursoService: ProfissionalRecursoService,
    readonly printService: PrintService,
    readonly typesService: TypesService
  ) { }

  ngOnInit() {
  }

  async print(atendimentoId: string) {    
    this.tenant = await this.tenantService.getCurrentTenant().toPromise();
    this.atendimento = await this.atendimentoService.getById(atendimentoId).toPromise();
    this.profissionalRecurso = await this.profissionalRecursoService.getById(this.atendimento.profissionalRecursoId).toPromise();
    this.paciente = await this.pacienteService.getById(this.atendimento.pacienteId).toPromise();
    
    setTimeout(() => {      
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Prontuário Eletrônico', content);    
    }, 500);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }
}

