import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { LocalPublicacaoType } from 'projects/admin/src/domain/types';

export class ArtigoTagModel extends BaseModel {
  public nome: string;
  public slug: string;
}

export class ArtigoImagemModel extends BaseModel {
  public ordem: number;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;
}

export class ArtigoLocalPublicacaoModel extends BaseModel {
  public localPublicacao: LocalPublicacaoType;

  // para hotSite medico
  public profissionalSaudeNome: string;
  public profissionalSaudeId: string;
}

export class LocaisPublicacao {
  public text: string;
  public value: string;
}

export class ArtigoTagArtigoModel {
  public artigoId: string;
  public artigoTag: ArtigoTagModel;
  public artigoTagId: string;
}

export class ArtigoModel extends BaseModel {
  public slug: string;
  public titulo: string;
  public autor: string;
  public resumo: string;

  public tagNomes: any[];
  public tagsApresentacao: string;
  public tags: ArtigoTagArtigoModel[];

  public imagens: ArtigoImagemModel[];
  public legendaImagens: string;

  public html: string;
  public imagemCapaUrl: string;
  public locaisPublicacao: ArtigoLocalPublicacaoModel[];
  public locaisPublicacaoNomes: string;

  public dataArtigo: Date;
  public publicado: boolean;  
}
