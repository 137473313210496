import { NgModule } from '@angular/core';

import { ArtigoComponent } from './artigo.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ArtigoModalComponent } from './artigo-modal/artigo-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [    
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    ArtigoComponent,
    ArtigoModalComponent
  ]
})
export class ArtigoModule { }
