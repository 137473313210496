<div bsModal #revistaModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="revistaModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Revista</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group align-center">
                <div style="height: 150px; width: 100%; object-fit: contain;">
                  <img src="{{form.value.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                    *ngIf="form.value.imagemUrl">
                </div>
                <div class="align-center">
                  <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                    <mat-icon>add_photo_alternate</mat-icon>
                  </button>
                  <button mat-icon-button type="button" [disabled]="!form.value.imagemUrl" (click)="deselectImage()"
                    matTooltip="Desvincular Imagem">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-9">

              <div class="row">
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Data</mat-label>
                    <input matInput formControlName="data" type="date" #focus />
                    <mat-error>Informe uma Data válida.</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-8">
                  <mat-form-field>
                    <mat-label>Titulo</mat-label>
                    <input matInput formControlName="titulo" type="text" (blur)="slugify($event.target.value)" />
                    <mat-hint align="end">Máx 200 caracteres</mat-hint>
                    <mat-error>Informe um Titulo válido.</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Edição</mat-label>
                    <input matInput formControlName="edicao" type="text" />
                    <mat-hint align="end">Máx 100 caracteres</mat-hint>
                    <mat-error>Informe uma Edição válida.</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Tipo de Revista</mat-label>
                    <mat-select formControlName="tipo">
                      <mat-option *ngFor="let tipoRevista of tiposRevista" [value]="tipoRevista.value">
                        {{tipoRevista.text}}</mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>


                <mat-slide-toggle formControlName="publicado" color="primary">
                  Revista Publicada
                </mat-slide-toggle>
              </div>

              <!-- Revista url ou link -->

              <div class="row">

                <div class="col-md-12" *ngIf="form.value.tipo == linkExterno">
                  <mat-form-field>
                    <mat-label>Link Externo</mat-label>
                    <input matInput formControlName="linkExterno" type="text" />
                    <mat-hint align="end">Máx 200 caracteres</mat-hint>
                    <mat-error>Informe um Link Externo válido.</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-12" *ngIf="form.value.tipo == arquivoPdf">
                  <p class="text-center"> Selecione O Pdf da Revista</p>
                  <p class="font-15 col-deep-orange text-center">
                    O tamanho máximo permitido do arquivo é de <strong>50MB</strong>
                  </p>
                  <div class="form-group">
                    <input #fileUpload formControlName="file" type="file" />
                    <mat-progress-bar mode="indeterminate" *ngIf="file.inProgress"></mat-progress-bar>
                    <div class="alert alert-success" role="alert" *ngIf="file.completed" i18n>Arquivo salvo com sucesso!
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="!newItem">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Url Arquivo</mat-label>
                    <input matInput type="url" disabled value="{{form.value.arquivoUrl}}" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <!-- Slug Hidden para poder preencher com o slugify -->
          <input formControlName="slug" type="hidden" />

        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
