import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicoComponent } from './servico.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ServicoModalComponent } from './servico-modal/servico-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    ServicoComponent,
    ServicoModalComponent
  ]
})
export class ServicoModule { }
