import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { PesquisaQuestaoType } from 'projects/admin/src/domain/types';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { GrupoQuestaoModel, QuestionarioFiltroModel } from '../../domain/models';
import { GrupoQuestaoService, QuestionarioFiltroService } from '../../domain/services';
import { GrupoQuestaoItemComponent } from '../../grupo-questao-item/grupo-questao-item.component';
import { GrupoQuestaoOpcaoComponent } from '../../grupo-questao-opcao/grupo-questao-opcao.component';

@Component({
  selector: 'app-grupo-questao-modal',
  templateUrl: './grupo-questao-modal.component.html'
})

export class GrupoQuestaoModalComponent extends BaseCrudModalComponent<GrupoQuestaoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('grupoQuestaoItem') grupoQuestaoItem: GrupoQuestaoItemComponent;
  @ViewChild('grupoQuestaoOpcao') grupoQuestaoOpcao: GrupoQuestaoOpcaoComponent;

  @Input('questionarioId') questionarioId: string;

  pesquisaQuestaoType: typeof PesquisaQuestaoType = PesquisaQuestaoType;
  listaFiltros: QuestionarioFiltroModel[];

  constructor(
    injector: Injector,
    service: GrupoQuestaoService,
    readonly filtroService: QuestionarioFiltroService,
    readonly typesService: TypesService

  ) {
    super(injector, service);
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.filtroService.getByQuestionarioId(this.questionarioId).subscribe(lista => this.listaFiltros = lista);
  }

  protected initializeForm(model: GrupoQuestaoModel) {
    this.form = this.formBuilder.group({
      id: [model.id],
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: [model.dataAlteracao],
      
      questionarioId: [model.questionarioId, Validators.required],
      filtroId:  [model.filtroId, Validators.required],
      ordem: [model.ordem],
      titulo: [model.titulo, [ Validators.required, Validators.maxLength(100)]],
      tipo: [model.tipo, Validators.required],
      usaPontuacao: [model.usaPontuacao, Validators.required]
    });
  };

  protected newModel(): GrupoQuestaoModel {
    let model = super.newModel();
    model.questionarioId = this.questionarioId;
    model.ordem = 1;
    model.tipo = PesquisaQuestaoType.Selecao;
    model.usaPontuacao = false;
    return model;
  };

}
