import { Component, OnInit, ViewChild, Injector, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AtendimentoSolicExameModalComponent } from './atendimento-solic-exame-modal/atendimento-solic-exame-modal.component';
import { AtendimentoSolicExameModel } from '../../domain/models';
import { AtendimentoSolicExameService } from '../../domain/services';
import { AtendimentoSolicExameComparacaoComponent } from './atendimento-solic-exame-comparacao/atendimento-solic-exame-comparacao.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AtendimentoSolicExameHistoricoModalComponent } from './atendimento-solic-exame-historico-modal/atendimento-solic-exame-historico-modal.component';

@Component({
  selector: 'app-atendimento-solic-exame',
  templateUrl: './atendimento-solic-exame.component.html'
})
export class AtendimentoSolicExameComponent extends BaseCrudComponent<AtendimentoSolicExameModel> implements OnInit {
  @ViewChild('modal') modal: AtendimentoSolicExameModalComponent;
  @ViewChild('modalHistorico') modalHistorico: AtendimentoSolicExameHistoricoModalComponent;
 
  @Input('atendimentoId') atendimentoId: string;
  @Input('profissionalId') profissionalId: string;  
  @Input('pacienteId') pacienteId: string;
  @Input('detailMode') detailMode: boolean;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  models: AtendimentoSolicExameModel[] = [];

  constructor(
    injector: Injector,
    readonly dialog: MatDialog,
    readonly service: AtendimentoSolicExameService,
    readonly sanitizer: DomSanitizer
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.useDatatables = false;

    this.filter();
  }

  create(model: AtendimentoSolicExameModel = null) {
    this.autoSave.emit();

    const newModel = new AtendimentoSolicExameModel();
    newModel.seq = this.models.length > 0 ? Math.max(...this.models.map(x => x.seq)) + 1 : 1;
    if (model){
      newModel.descricao = model.descricao;  
    }
  
    super.create(newModel);
  }

  filter() {
    this.service.getByAtendimentoId(this.atendimentoId).subscribe(x =>{
      this.models = x;
      this.models.forEach(item => {
        //injetando campo dinâmico para apresentação
        item['descricaoHtml'] = this.service.getDescricaoHtml(item.descricao);
      });
    });
  }

  compararResultados(solicExame) {
    this.dialog.open(AtendimentoSolicExameComparacaoComponent, {
      width: '800px',
      data: { pacienteId: this.pacienteId, solicExame }
    });
  }

  showHistorico(){
    this.modalHistorico.show();
  }

  onCloneResponse(model: AtendimentoSolicExameModel) {
    if (model) {
      this.create(model);
    }
  }  
}
