import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { PesquisaQuestaoType } from 'projects/admin/src/domain/types';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { QuestionarioFiltroModel } from '../../domain/models';
import { QuestionarioFiltroService } from '../../domain/services';

@Component({
  selector: 'app-questionario-filtro-modal',
  templateUrl: './questionario-filtro-modal.component.html'
})

export class QuestionarioFiltroModalComponent extends BaseCrudModalComponent<QuestionarioFiltroModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  
  @Input('questionarioId') questionarioId: string;

  pesquisaQuestaoType: typeof PesquisaQuestaoType = PesquisaQuestaoType;

  constructor(
    injector: Injector,
    service: QuestionarioFiltroService,
    readonly typesService: TypesService

  ) {
    super(injector, service);
  }
  
  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: QuestionarioFiltroModel) {
    this.form = this.formBuilder.group({
      id: [model.id],
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: [model.dataAlteracao],
      
      questionarioId: [model.questionarioId, Validators.required],
      ordem: [model.ordem],
      opcao: [model.opcao, [ Validators.required, Validators.maxLength(100)]]
    });
  };

  protected newModel(): QuestionarioFiltroModel {
    let model = super.newModel();
    model.questionarioId = this.questionarioId;
    model.ordem = 1;
    return model;
  };

}
