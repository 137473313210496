<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Faturamento</h2>
      </div>
      <div class="body">
        <mat-tab-group #tabs>
          <mat-tab label="Filtro">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Data Inicial</mat-label>
                    <input matInput [(ngModel)]="dataInicial" type="date" />
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Data Final</mat-label>
                    <input matInput [(ngModel)]="dataFinal" type="date" />
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Status de Recebimento</mat-label>
                    <mat-select [(ngModel)]="statusRecebto">
                      <mat-option *ngFor="let item of typesService.atendimentoRecebtoStatusType" [value]="item.value">
                        {{item.text}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="card">
                    <div class="header">
                      <h2>
                        <button mat-icon-button type="button" (click)="selectAll(1)" *ngIf="!convenioTotalSelect">
                          <mat-icon>check_box_outline_blank</mat-icon>
                        </button>
                        <button mat-icon-button type="button" (click)="deSelectAll(1)" *ngIf="convenioTotalSelect">
                          <mat-icon>check_box</mat-icon>
                        </button>
                        Convênios
                      </h2>
                    </div>
                    <div class="body">
                      <mat-selection-list #matListConvenio [(ngModel)]="listaConvenioIdSelecionado"
                        (selectionChange)="onSelectionChange(1)" style="height: 200px; overflow: auto;">
                        <mat-list-option *ngFor="let item of listaConvenio" checkboxPosition="before" color="primary"
                          [value]="item.value">
                          {{item.text}}
                        </mat-list-option>
                      </mat-selection-list>
                      <hr>
                      Total: {{ listaConvenioIdSelecionado.length }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="header">
                      <h2>
                        <button mat-icon-button type="button" (click)="selectAll(2)" *ngIf="!medicoTotalSelect">
                          <mat-icon>check_box_outline_blank</mat-icon>
                        </button>
                        <button mat-icon-button type="button" (click)="deSelectAll(2)" *ngIf="medicoTotalSelect">
                          <mat-icon>check_box</mat-icon>
                        </button>
                        Médicos
                      </h2>
                    </div>
                    <div class="body">
                      <mat-selection-list #matListMedico [(ngModel)]="listaMedicoIdSelecionado"
                        (selectionChange)="onSelectionChange(2)" style="height: 200px; overflow: auto;">
                        <mat-list-option *ngFor="let item of listaMedico" checkboxPosition="before" color="primary"
                          [value]="item.value">
                          {{item.text}}
                        </mat-list-option>
                      </mat-selection-list>
                      <hr>
                      Total: {{ listaMedicoIdSelecionado.length }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="header">
                      <h2>
                        <button mat-icon-button type="button" (click)="selectAll(3)" *ngIf="!equipamentoTotalSelect">
                          <mat-icon>check_box_outline_blank</mat-icon>
                        </button>
                        <button mat-icon-button type="button" (click)="deSelectAll(3)" *ngIf="equipamentoTotalSelect">
                          <mat-icon>check_box</mat-icon>
                        </button>
                        Equipamentos
                      </h2>
                    </div>
                    <div class="body">
                      <mat-selection-list #matListEquipamento [(ngModel)]="listaEquipamentoIdSelecionado"
                        (selectionChange)="onSelectionChange(3)" style="height: 200px; overflow: auto;">
                        <mat-list-option *ngFor="let item of listaEquipamento" checkboxPosition="before" color="primary"
                          [value]="item.id">
                          {{item.nomeApresentacao}}
                        </mat-list-option>
                      </mat-selection-list>
                      <hr>
                      Total: {{ listaEquipamentoIdSelecionado.length }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="header">
                      <h2>
                        <button mat-icon-button type="button" (click)="selectAll(4)" *ngIf="!grupoProcTotalSelect">
                          <mat-icon>check_box_outline_blank</mat-icon>
                        </button>
                        <button mat-icon-button type="button" (click)="deSelectAll(4)" *ngIf="grupoProcTotalSelect">
                          <mat-icon>check_box</mat-icon>
                        </button>
                        Grupos de Procedimentos
                      </h2>
                    </div>
                    <div class="body">
                      <mat-selection-list #matListGrupoProc [(ngModel)]="listaGrupoProcIdSelecionado"
                        (selectionChange)="onSelectionChange(4)" style="height: 200px; overflow: auto;">
                        <mat-list-option *ngFor="let item of listaGrupoProc" checkboxPosition="before" color="primary"
                          [value]="item.value">
                          {{item.text}}
                        </mat-list-option>
                      </mat-selection-list>
                      <hr>
                      Total: {{ listaGrupoProcIdSelecionado.length }}
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button mat-flat-button color="primary" [disabled]="isBusy  || !isAllowed()" (click)="filtrar()">
                    <span>Filtrar Dados</span>
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Dados">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover dataTable"
                    style="width: 100%; max-height: 500px; overflow-y: scroll; display:inline-block;">
                    <thead>
                      <tr>
                        <th class="text-center">
                          <button mat-icon-button type="button" (click)="tbSelectAll()" class="button-row"
                            *ngIf="!totalSelect">
                            <mat-icon>check_box_outline_blank</mat-icon>
                          </button>
                          <button mat-icon-button type="button" (click)="tbDeSelectAll()" class="button-row"
                            *ngIf="totalSelect">
                            <mat-icon>check_box</mat-icon>
                          </button>
                        </th>
                        <th class="text-center">Status Recebto</th>
                        <th class="text-center">Cód.Atend.</th>
                        <th class="text-center">Data</th>
                        <th class="text-center">Procedimento</th>
                        <th class="text-center">Convênio</th>
                        <th class="text-center">Paciente</th>
                        <th class="text-center">Vlr.Total</th>
                        <th class="text-center">Vlr.Clínica</th>
                        <th class="text-center">Vlr.Médico</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="listaDto?.length != 0">
                      <tr *ngFor="let item of listaDto">
                        <td>
                          <button mat-icon-button type="button" (click)="tbSelect(item)" class="button-row"
                            *ngIf="!item.selecionado">
                            <mat-icon>check_box_outline_blank</mat-icon>
                          </button>
                          <button mat-icon-button type="button" (click)="tbDeSelect(item)" class="button-row"
                            *ngIf="item.selecionado">
                            <mat-icon>check_box</mat-icon>
                          </button>
                        </td>
                        <td (click)="editAtendimento(item)">
                          <span class="label"
                            [ngClass]="typesService.getAtendimentoRecebtoStatusColor(item.statusRecebto)">
                            {{typesService.getAtendimentoRecebtoStatusType(item.statusRecebto)}}
                          </span>
                        </td>
                        <td (click)="editAtendimento(item)">
                          {{item.codigoDocMovto}}
                        </td>
                        <td (click)="editAtendimento(item)">
                          {{item.dataHoraEntrada | date :'dd/MM/yyyy'}}
                        </td>
                        <td (click)="editAtendimento(item)">
                          {{item.procedimentoNome}}
                        </td>
                        <td (click)="editAtendimento(item)">
                          {{item.convenioNome}}
                        </td>
                        <td (click)="editAtendimento(item)">
                          {{item.pacienteNome}}
                        </td>
                        <td class="text-right" (click)="editAtendimento(item)">
                          {{item.vlrTotal | currency:'':''}}
                        </td>
                        <td class="text-right" (click)="editAtendimento(item)">
                          {{item.vlrClinica | currency:'':''}}
                        </td>
                        <td class="text-right" (click)="editAtendimento(item)">
                          {{item.vlrMedico | currency:'':''}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <button mat-flat-button type="button" (click)="aplicarRecebto()" color="primary" class="button-row"
                  *ngIf="statusRecebto === atendimentoRecebtoStatusType.Pendente">
                  <mat-icon>done_all</mat-icon>
                  <span> Marcar Como Recebido</span>
                </button>
                <button mat-flat-button type="button" (click)="desfazerRecebto()" color="primary" class="button-row"
                  *ngIf="statusRecebto === atendimentoRecebtoStatusType.Recebido">
                  <mat-icon>undo</mat-icon>
                  <span> Desfazer Recebimento</span>
                </button>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Total Geral</mat-label>
                  <input matInput class="font-weight-bold" disabled value="{{totalGeral | currency:'BRL':''}}"
                    currencyMask />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Total Clínica</mat-label>
                  <input matInput class="font-weight-bold" disabled value="{{totalClinica | currency:'BRL':''}}"
                    currencyMask />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Total Médicos</mat-label>
                  <input matInput class="font-weight-bold" disabled value="{{totalMedicos | currency:'BRL':''}}"
                    currencyMask />
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<app-atendimento-entrada-modal #atendimentoEntradaModal (modalSave)="onAtendimentoResponse($event)">
</app-atendimento-entrada-modal>