import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GrupoCategoriaFinanComponent } from './grupo-categoria-finan.component';
import { GrupoCategoriaFinanModalComponent } from './grupo-categoria-finan-modal/grupo-categoria-finan-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    GrupoCategoriaFinanComponent,
    GrupoCategoriaFinanModalComponent
  ]
})
export class GrupoCategoriaFinanModule { }
