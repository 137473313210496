import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from '../../../galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BlocoType, LinkTargetType, BlocoPosicaoType } from '../../domain/enums';
import { BlocoModel, LinkModel } from '../../domain/models';
import { BlocoService } from '../../domain/services/bloco.service';

@Component({
  selector: 'app-script-modal',
  templateUrl: './script-modal.component.html'
})
export class ScriptModalComponent extends BaseCrudModalComponent<BlocoModel> implements OnInit {
  @ViewChild('scriptModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  BlocoType: typeof BlocoType = BlocoType;

  targets = [
    { text: 'Janela Atual', value: LinkTargetType.Self },
    { text: 'Nova Janela', value: LinkTargetType.Blank },
  ];

  tipos = [
    { text: 'Script', value: BlocoType.Script },
  ];

  posicoes: any[];

  constructor(
    injector: Injector,
    blocoService: BlocoService
  ) {
    super(injector, blocoService);
  }

  protected initializeForm(bloco: BlocoModel) {
    this.changeTipo(bloco.tipo);

    this.form = this.formBuilder.group({
      id: bloco.id,
      dataInclusao: [bloco.dataInclusao, Validators.required],
      dataAlteracao: bloco.dataAlteracao,
      registroAtivo: bloco.registroAtivo,
      tipo: [bloco.tipo, Validators.required],
      posicao: [bloco.posicao],
      imagemUrl: [bloco.imagemUrl],
      imagemUrlBase: [bloco.imagemUrlBase],
      imagemPath: [bloco.imagemPath],
      titulo: [bloco.titulo, [
        Validators.required,
        Validators.maxLength(100)]
      ],
      legenda: [bloco.legenda, [
        Validators.maxLength(200)]
      ],
      ordem: [bloco.ordem, Validators.required],
      html: [bloco.html],
      linkId: [bloco.linkId],
      link: this.formBuilder.group({
        id: bloco.link.id,
        dataInclusao: [bloco.link.dataInclusao, Validators.required],
        dataAlteracao: bloco.link.dataAlteracao,
        url: [bloco.link.url],
        target: [bloco.link.target, Validators.required]
      })
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  changeTipo(blocoType: BlocoType) {
    switch (Number(blocoType)) {
      case BlocoType.Script:
        this.posicoes = [
          { text: 'Cabeçado do HTML <head>', value: BlocoPosicaoType.TagHead },
          { text: 'Corpo do HTML <body>', value: BlocoPosicaoType.TagBody }
        ];
        break;
    }
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(1920);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }

  beforeShowCreate(model: BlocoModel): BlocoModel {
    model.registroAtivo = true;
    model.ordem = 0;
    model.tipo = BlocoType.Script;
    model.link = new LinkModel();
    model.link.id = this.commonService.newGuid();
    model.link.dataInclusao = new Date();
    model.link.target = LinkTargetType.Self;
    model.linkId = model.link.id;
    return model;
  }
}
