import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AssinanteModel } from '../../models/notificacao/assinante-model';
import { BaseCrudService } from '../base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AssinanteService extends BaseCrudService<AssinanteModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Assinante';
  }

  register(model: AssinanteModel): Observable<AssinanteModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/register`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
