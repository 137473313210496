<div class="card main-content">
  <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
  <div class="header">
    <h2>Configuração de Formulários SUS</h2>
  </div>
  <div class="body table-responsive">
    <!--query end-->
    <table class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center">Data Inclusão</th>
          <th class="text-center">Data Alteração</th>
          <th class="text-center">Código - CNES</th>
          <th class="text-center" width="100"></th>
        </tr>
      </thead>
      <tbody *ngIf="docSusConfig">
        <tr>
          <td>
            {{docSusConfig.dataInclusao | date :'dd/MM/yyyy HH:mm'}}            
          </td>          
          <td>
            {{docSusConfig.dataAlteracao | date :'dd/MM/yyyy HH:mm'}}            
          </td>          
          <td>
            {{docSusConfig.codigoCnes}}
          </td>
          <td class="text-center">
            <button mat-icon-button type="button" (click)="edit(docSusConfig)"
              matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>            
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="fixed-action-btn">
    <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
      matTooltipPosition="above">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<app-doc-sus-config-modal #configModal (modalSave)="onResponse($event)"></app-doc-sus-config-modal>
