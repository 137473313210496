import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { LayoutMensagemService } from '../../domain/services';
import { LayoutMensagemModel } from '../../domain/models';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-layout-mensagem-modal',
  templateUrl: './layout-mensagem-modal.component.html'
})
export class LayoutMensagemModalComponent extends BaseCrudModalComponent<LayoutMensagemModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  constructor(
    injector: Injector,
    service: LayoutMensagemService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: LayoutMensagemModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      nome: [model.nome, [
        Validators.maxLength(50),
        Validators.required
      ]],
      texto: [model.texto, [
        Validators.required
      ]],

      tipo: [model.tipo]
    });
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(500);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      pessoa: {
        imagemUrl: null,
        imagemUrlBase: null,
        imagemPath: null
      }
    });
  }
}
