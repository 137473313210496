import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { PaginaWebModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PaginaWebService extends BaseCrudService<PaginaWebModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'PaginaWeb';
  }

  getBySlug(slug: string): Observable<PaginaWebModel> {
    return this
      .httpClient
      .get<PaginaWebModel>(`${this.getApiUrl()}${this.getController()}/GetBySlug/${slug}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
