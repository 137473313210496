import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ProcedimentoComponent } from './procedimento.component';
import { ProcedimentoModalComponent } from './procedimento-modal/procedimento-modal.component';
import { ProcedimentoSelectModalComponent } from './procedimento-select-modal/procedimento-select-modal.component';
import { ProcedimentoConvenioComponent } from './procedimento-convenio/procedimento-convenio.component';
import { ProcedimentoConvenioModalComponent } from './procedimento-convenio/procedimento-convenio-modal/procedimento-convenio-modal.component';
import { ProcedimentoPerguntaModule } from '../procedimento-pergunta/procedimento-pergunta.module';
import { ProcedimentoConvenioProfissionalComponent } from './procedimento-convenio-profissional/procedimento-convenio-profissional.component';
import { ProcedimentoConvenioProfissionalModalComponent } from './procedimento-convenio-profissional/procedimento-convenio-profissional-modal/procedimento-convenio-profissional-modal.component';

@NgModule({
  imports: [    
    SharedModule,
    ProcedimentoPerguntaModule
  ],
  declarations: [
    ProcedimentoComponent,
    ProcedimentoModalComponent,
    ProcedimentoSelectModalComponent,

    ProcedimentoConvenioComponent,
    ProcedimentoConvenioModalComponent,
    ProcedimentoConvenioProfissionalComponent,
    ProcedimentoConvenioProfissionalModalComponent
  ],
  exports: [
    ProcedimentoSelectModalComponent
  ]
})
export class ProcedimentoModule { }
