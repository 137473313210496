import { BaseModel } from "projects/ProjetoBaseAngular/domain/models/base/base-model";

export class AtestadoModeloModel extends BaseModel {
  public nome: string;
  public texto: string;
  public cids: string;
}

export class ReceitaModeloModel extends BaseModel {
  public nome: string;
  public texto: string;
  public profissionalSaudeId: string;
  public profissionalSaudeNomeApresentacao: string;
}

export class ExameModeloModel extends BaseModel {
  public nome: string;
  public texto: string;
  public profissionalSaudeId: string;
  public profissionalSaudeNomeApresentacao: string;
}