
export class DemonstrativoDetalheModel {
  public procedimento: string;
  public quantidade: number;
  public valorTotal: number;
  public percentualValor: number;
  public percentualQtd: number;
}

export class DemonstrativoPrincipalModel {
  public nome: string;
  public quantidade: number;
  public valorTotal: number;
  public percentualValor: number;
  public percentualQtd: number;
  public itens: DemonstrativoDetalheModel[];
}

