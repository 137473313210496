import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators, FormControl } from '@angular/forms';

import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { EstadoModel, CidadeModel, ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';

import { TipoParceiroModel } from '../../domain/models';
import { ParceiroModel } from '../../domain/models';
import { TipoParceiroService } from '../../domain/services';
import { ParceiroService } from '../../domain/services';


@Component({
  selector: 'app-parceiro-modal',
  templateUrl: './parceiro-modal.component.html'
})
export class ParceiroModalComponent extends BaseCrudModalComponent<ParceiroModel> implements OnInit {
  @ViewChild('parceiroModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  estados: EstadoModel[];
  cidades: CidadeModel[];
  tiposParceiros: TipoParceiroModel[];

  constructor(
    injector: Injector,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService,
    private readonly tipoParceiroService: TipoParceiroService,
    parceiroService: ParceiroService,
  ) {
    super(injector, parceiroService);
    this.tipoParceiroService = injector.get<TipoParceiroService>(TipoParceiroService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
    this.tipoParceiroService.getAll().subscribe(tiposParceiros => this.tiposParceiros = tiposParceiros);
  }

  protected initializeForm(parceiro: ParceiroModel) {
    this.form = this.formBuilder.group({
      id: parceiro.id,
      dataInclusao: [parceiro.dataInclusao, Validators.required],
      dataAlteracao: parceiro.dataAlteracao,
      ordem: [parceiro.ordem],
      nome: [parceiro.nome, [
        Validators.required,
        Validators.maxLength(100)]
      ],
      telefone: [parceiro.telefone,
      Validators.maxLength(15)
      ],
      urlSite: [parceiro.urlSite,
      Validators.maxLength(200)
      ],
      tipoIds: [parceiro.tipoIds, [
        Validators.required
      ]],
      imagemUrl: [parceiro.imagemUrl],
      imagemUrlBase: [parceiro.imagemUrlBase],
      imagemPath: [parceiro.imagemPath],

      ruaAvenida: [parceiro.ruaAvenida, [
        Validators.minLength(5),
        Validators.maxLength(50)]
      ],
      numero: [parceiro.numero, Validators.maxLength(10)],
      complemento: [parceiro.complemento, Validators.maxLength(50)],
      bairro: [parceiro.bairro, Validators.maxLength(50)],
      cep: [parceiro.cep, Validators.maxLength(10)],
      estadoId: parceiro.estadoId,
      cidadeId: parceiro.cidadeId
    });
  }

  protected newModel(): ParceiroModel {
    const model = new ParceiroModel();
    model.ordem = 0;

    return model;
  }
  
  changeCep(cep: string) {
    if (cep) {
      this.commonService.queryPostalCode(cep).subscribe((endereco: any) => {
        this.form.patchValue({
          ruaAvenida: endereco.logradouro,
          bairro: endereco.bairro
        });

        const estadoId = this.estados.find(x => x.uf === endereco.uf).id;
        this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => {
          this.cidades = cidades;
          const cidadeId = this.cidades.find(x => x.codigoIbge === endereco.ibge).id;

          this.form.patchValue({ estadoId, cidadeId });
        });
      });
    }
  }

  changeEstado(estadoId: string) {
    this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
  }

  beforeShowEdit(model: ParceiroModel) {
    model.estadoId && this.changeEstado(model.estadoId);
    return model;
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({ 
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });    
  }

  deselectImage() {
    this.form.patchValue({ 
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }
}
