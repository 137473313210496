<div class="card">
  <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
  <div class="body" *ngIf="!isBusy">
    <div class="row">
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Profissional da Saúde/Equipamento</mat-label>
          <mat-select [(ngModel)]="agendamentoFilterDto.recursoIds" (selectionChange)="changeRecurso($event.value)"
            *ngIf="!agendamentoFilterDto.naoFiltrarRecurso" multiple>
            <mat-option *ngFor="let recurso of recursos" [value]="recurso.value">
              {{recurso.text}}
            </mat-option>
          </mat-select>
          <input matInput disabled value="Todos Profissionais da Saúde/Equipamentos"
            *ngIf="agendamentoFilterDto.naoFiltrarRecurso" />
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Status da Agenda</mat-label>
          <mat-select [(ngModel)]="agendamentoFilterDto.status" (selectionChange)="filter()">
            <mat-option value="">Todos (Exceto Cancelados)</mat-option>
            <mat-option *ngFor="let status of typesService.agendamentoStatusFiltroType" [value]="status.value"
              [style.backgroundColor]="status.color" [style.color]="'white'">
              {{status.text}}
            </mat-option>
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Cód./CPF Paciente</mat-label>
          <input id="cpfPaciente" #cpf matInput [(value)]="paciente.pessoa.cnpjCpf"
            [disabled]="agendamentoFilterDto.pacienteAnotacao" type="text"
            (keyup.enter)="pacienteSelect.changeDocumento(cpf.value)"
            (blur)="pacienteSelect.changeDocumento($event.target.value)" />
          <button *ngIf="!agendamentoFilterDto.pacienteAnotacao" matSuffix mat-icon-button type="button"
            (click)="pacienteSelect.showSelect()" matTooltip="Selecionar Paciente">
            <mat-icon>search</mat-icon>
          </button>
          <mat-slide-toggle color="primary" (change)="changeFiltroPaciente()" matSuffix
            [(ngModel)]="agendamentoFilterDto.pacienteAnotacao" matTooltip="Filtrar por paciente anotação">
          </mat-slide-toggle>
        </mat-form-field>
      </div>
      <div class="col-md-3" *ngIf="!agendamentoFilterDto.pacienteAnotacao">
        <mat-form-field>
          <mat-label>Paciente</mat-label>
          <input matInput disabled
            [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia"
            type="text" />
          <button [disabled]="!paciente?.id" matSuffix mat-icon-button type="button"
            (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3" *ngIf="agendamentoFilterDto.pacienteAnotacao">
        <mat-form-field>
          <mat-label>Entre com o nome do paciente anotação</mat-label>
          <input id="nomePaciente" matInput [(ngModel)]="agendamentoFilterDto.nomePaciente" type="text" />
        </mat-form-field>
      </div>
      <div class="col-md-3 text-center">
        <mat-button-toggle-group [(ngModel)]="agendamentoView" (click)="changeView()">
          <mat-button-toggle [value]="AgendamentoViewType.ListaCompleta" matTooltip="Mostrar Lista Completa">
            <mat-icon>list</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle [value]="AgendamentoViewType.CalendarioMes" matTooltip="Mostrar Calendário Mês">
            <mat-icon>calendar_view_month</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle [value]="AgendamentoViewType.CalendarioSemana" matTooltip="Mostrar Calendário Semana">
            <mat-icon>calendar_view_week</mat-icon>
          </mat-button-toggle>
          <!-- <mat-button-toggle [value]="AgendamentoViewType.CalendarioDia" matTooltip="Mostrar Calendário em Lista">
            <mat-icon>calendar_view_day</mat-icon>
          </mat-button-toggle> -->
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center text-md-left" style="margin-bottom: 0px;"
        *ngIf="agendamentoView === AgendamentoViewType.ListaCompleta">
        <div style="display: inline-flex; align-items: center;">
          <mat-form-field>
            <mat-label>Data</mat-label>
            <input #data matInput type="date" placeholder="Data" [(ngModel)]="agendamentoFilterDto.dateList"
              *ngIf="!agendamentoFilterDto.naoFiltrarData">
            <!-- <input #data matInput type="date" placeholder="Data" [(ngModel)]="agendamentoFilterDto.dateList"
              (change)="filter()" *ngIf="!agendamentoFilterDto.naoFiltrarData"> -->
            <input matInput disabled value="Todas Datas" *ngIf="agendamentoFilterDto.naoFiltrarData">
            <button matPrefix mat-icon-button color="primary" type="button" matTooltip="Dia anterior"
              (click)="prevDayClick()" [disabled]="agendamentoFilterDto.naoFiltrarData">
              <mat-icon>arrow_back_ios</mat-icon>
            </button>
            <button matSuffix mat-icon-button color="primary" type="button" matTooltip="Próximo dia"
              (click)="nextDayClick()" [disabled]="agendamentoFilterDto.naoFiltrarData">
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div style="display: inline-flex; align-items: center; margin-left: 5px;">
          <button mat-flat-button type="button" [disabled]="isToday || agendamentoFilterDto.naoFiltrarData"
            color="primary" (click)="todayClick()">
            Hoje
          </button>
          <button mat-flat-button type="button" (click)="showDispAgenda()" color="primary" style="margin-left: 10px;"
            matTooltip="Disponibilidade Agenda">
            <mat-icon>date_range</mat-icon>
          </button>
          <button mat-flat-button type="button" (click)="showBloqAgenda()" color="primary" style="margin-left: 10px;"
            matTooltip="Bloqueios Agenda">
            <mat-icon>block</mat-icon>
          </button>
          <button mat-flat-button type="button" (click)="print()" color="primary" style="margin-left: 10px;"
            matTooltip="Imprimir">
            <span class="material-icons">
              print
            </span>
          </button>
        </div>
        <div class="row" style="display: inline-flex; align-items: center; margin: 0px;">
          <div class="col-md-12">
            <section>
              <p class="p-0 m-0">
                <mat-checkbox [(ngModel)]="agendamentoFilterDto.naoFiltrarData"
                  [disabled]="!agendamentoFilterDto.pacienteId || agendamentoFilterDto.pacienteAnotacao"
                  color="primary">
                  {{FiltroDataTexto}}</mat-checkbox>
              </p>
              <p class="p-0 m-0">
                <mat-checkbox [(ngModel)]="agendamentoFilterDto.naoFiltrarRecurso" color="primary">Todos
                  Profissionais / Equipamentos</mat-checkbox>
              </p>
            </section>
          </div>
        </div>
        <button mat-flat-button type="button" (click)="filter()" color="primary" style="margin-left: 5px;"
          matTooltip="Atualizar Agenda">
          <span class="material-icons">
            refresh
          </span>
          Filtrar
        </button>
        <button mat-flat-button type="button" (click)="showListaPaciente()" color="primary" style="margin-left: 5px;"
          matTooltip="Ir para Cadastro de Pacientes">
          <span class="material-icons">
            people
          </span>
          Pacientes
        </button>
        <button mat-flat-button type="button" (click)="confirmacaoLote()" color="primary" style="margin-left: 5px;"
          matTooltip="WhatsApp e ChatBot - Enviar confirmações de presença para todos agendamentos do dia selecionado em apenas 1 operação.">
          <span class="material-icons">
            send
          </span>
          C.Lote
        </button>
      </div>
    </div>
    <full-calendar #fullCalendar [options]="calendarOptions"
      *ngIf="agendamentoView !== AgendamentoViewType.ListaCompleta"></full-calendar>
    <div #tabelaAgendamentos class="table-responsive"
      [style.display]="agendamentoView === AgendamentoViewType.ListaCompleta ? 'block' : 'none'">
      <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
        style="width: 100%;">
        <thead>
          <tr>
            <th class="text-center" width="50">Data</th>
            <th class="text-center" width="30">Hora</th>
            <th class="text-center" width="30">Dia</th>
            <th class="text-center" width="150">Profissional/Equip.</th>
            <th class="text-center" width="100">Classificação</th>
            <th class="text-center" width="100">Status</th>
            <th class="text-center" width="200">Paciente</th>
            <th class="text-center" width="100">Telefone</th>
            <th class="text-center" width="120">Convênio</th>
            <th class="text-center">Observações</th>
            <th class="text-center" width="100"></th>
          </tr>
        </thead>
        <tbody *ngIf="models?.length != 0">
          <tr *ngFor="let item of models" style="cursor: pointer;">
            <td (click)="showAgendamento(item)">{{item.dataHoraInicial | date:'dd/MM/yyyy'}}</td>
            <td (click)="showAgendamento(item)">{{item.horario | mask:'hh:MM'}}</td>
            <td (click)="showAgendamento(item)">{{commonService.getDayOfWeek(item.dataHoraInicial)}}</td>
            <td (click)="showAgendamento(item)">{{item.profissionalRecursoNome}}</td>
            <td (click)="showAgendamento(item)">{{item.classificacaoNome}}</td>
            <td (click)="showAgendamento(item)">
              <span class="badge" [style.backgroundColor]="typesService.getAgendamentoStatusColorType(item.status)"
                [matTooltip]="item.disponivel ? 'Agendar' : 'Ver Detalhes'">
                {{typesService.getAgendamentoStatusType(item.status)}}
              </span>
              <span
                *ngIf="item.statusParcial === agendamentoStatusParcialType.Capturado || item.statusParcial === agendamentoStatusParcialType.Laudado"
                class="badge"
                [style.backgroundColor]="typesService.getAgendamentoStatusParcialColorType(item.statusParcial)">
                {{typesService.getAgendamentoStatusParcialType(item.statusParcial)}}
              </span>
            </td>
            <td (click)="showAgendamento(item)">
              <span *ngIf="item.codigoPaciente">{{item.codigoPaciente}}-{{item.nomePaciente}}</span>
              <span *ngIf="!item.codigoPaciente">{{item.nomePaciente}}</span>
            </td>
            <td (click)="showAgendamento(item)">{{(item.celularPaciente || '') + ' ' + (item.telefonePaciente || '')}}
            </td>
            <td (click)="showAgendamento(item)" [innerHTML]="item.convenioNome"></td>
            <td (click)="showAgendamento(item)"
              style="max-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              <span>{{item.observacoes}}</span>
              <span>{{item.motivoCancelamento}}</span>
            </td>
            <td class="text-center">
              <button mat-icon-button type="button" (click)="modal.lancar(item.id)" class="button-row"
                matTooltip="Lançar Entrada do Paciente"
                *ngIf="item.status === typesService.AgendamentoStatusType.Confirmado">
                <i class="far fa-check-circle fa-lg"></i>
              </button>
              <button mat-icon-button type="button" (click)="modal.solicitarConfirmacao(item.id)"
                [matTooltip]="item.dataSolicConfirmacao ? 'Solicitar Confirmação Novamente' : 'Solicitar Confirmação'"
                class="button-row" *ngIf="item.status === typesService.AgendamentoStatusType.Agendado ||
                  item.status === typesService.AgendamentoStatusType.Encaixe
                ">
                <i *ngIf="!item.dataSolicConfirmacao" class="far fa-paper-plane fa-lg"></i>
                <i *ngIf="item.dataSolicConfirmacao" class="fas fa-paper-plane fa-lg"></i>
              </button>
              <button mat-icon-button type="button" (click)="modal.confirmarPresenca(item.id)"
                matTooltip="Confirmar Presença" class="button-row" *ngIf="item.status === typesService.AgendamentoStatusType.Agendado ||
                  item.status === typesService.AgendamentoStatusType.Encaixe
                ">
                <i class="far fa-thumbs-up fa-lg"></i>
              </button>
              <button mat-icon-button type="button" (click)="modal.naoCompareceu(item.id)" matTooltip="Não Compareceu"
                class="button-row" *ngIf="item.status === typesService.AgendamentoStatusType.Confirmado">
                <i class="far fa-thumbs-down fa-lg"></i>
              </button>
              <button mat-icon-button type="button" (click)="modal.desconfirmarPresenca(item.id)"
                matTooltip="Desfazer Confirmação - Retorna status p/ Agendado" class="button-row"
                *ngIf="item.status === typesService.AgendamentoStatusType.NaoCompareceu">
                <mat-icon>undo</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-4" style="margin-bottom: 0;">
        <mat-form-field>
          <mat-label>Anotações</mat-label>
          <textarea matInput [(ngModel)]="anotacoes" rows="2"></textarea>
          <button matSuffix mat-icon-button type="button" [disabled]="anotacoes===anotacoesOld"
            (click)="saveAnotacoes()" color="primary" matTooltip="Salvar Anotações">
            <mat-icon>done</mat-icon>
          </button>
          <button matSuffix mat-icon-button type="button" (click)="refreshAnotacoes()" color="primary"
            matTooltip="Atualizar Anotações">
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <strong>Legenda: </strong>
      <span (click)="changeStatus(status.value)" style="margin-right: 5px; cursor: pointer;"
        *ngFor="let status of typesService.agendamentoStatusFiltroType" class="badge"
        [style.backgroundColor]="status.color">
        {{status.text}}
      </span>
      <span (click)="changeStatus('')" class="badge" style="margin-right: 5px; cursor: pointer;">
        Todos
      </span>
    </div>
    <div class="fixed-action-btn">
      <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
        matTooltipPosition="above">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<app-agendamento-modal #modal (modalSave)="filter()"></app-agendamento-modal>
<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>
<app-agenda-disponibilidade #agendaDispModal (modalClose)="changeRecurso(agendamentoFilterDto.recursoIds)">
</app-agenda-disponibilidade>
<app-agenda-bloqueio #agendaBloqModal (modalClose)="changeRecurso(agendamentoFilterDto.recursoIds)">
</app-agenda-bloqueio>
<app-agenda-espera #agendaEsperaModal></app-agenda-espera>