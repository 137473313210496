import { Component, OnInit, ViewChild, Injector, ElementRef } from '@angular/core';
import { CalendarOptions, EventClickArg } from '@fullcalendar/common';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { environment } from 'environments/environment';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import Swal from 'sweetalert2';

import * as moment from 'moment';
moment.locale('pt-BR');

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { AgendamentoModalComponent } from './agendamento-modal/agendamento-modal.component';
import { AgendaDisponibilidadeEventualModel, AgendaDisponibilidadeModel, AgendamentoFilterDto, AgendamentoModel } from '../domain/models';
import { AgendaDisponibilidadeService, AgendamentoService } from '../domain/services';
import { ProfissionalRecursoService } from '../domain/services/profissional-recurso.service';
import { TypesService } from 'projects/admin/src/domain/services';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { PacienteModel } from '../../paciente/domain/models';
import { DataTablesRequest, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { PacienteService } from '../../paciente/domain/services';
import { AgendaDisponibilidadeComponent } from '../agenda-disponibilidade/agenda-disponibilidade.component';
import { AgendamentoStatusParcialType, AgendamentoStatusType, AgendamentoType, AgendamentoViewType } from '../domain/types';
import { AgendaBloqueioComponent } from '../agenda-bloqueio/agenda-bloqueio.component';
import { AgendaEsperaComponent } from '../agenda-espera/agenda-espera.component';
import { DateAdapter } from '@angular/material/core';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DayOfWeekType } from 'projects/admin/src/domain/types';
import { AgendaDisponibilidadeEventualService } from '../domain/services/agenda-disponibilidade-eventual.service';

@Component({
  selector: 'app-agendamento',
  templateUrl: './agendamento.component.html',
  styleUrls: ['./agendamento.component.scss']
})
export class AgendamentoComponent extends BaseCrudComponent<AgendamentoModel> implements OnInit {
  @ViewChild('modal') modal: AgendamentoModalComponent;
  @ViewChild('agendaDispModal') agendaDispModal: AgendaDisponibilidadeComponent;
  @ViewChild('agendaBloqModal') agendaBloqModal: AgendaBloqueioComponent;
  @ViewChild('agendaEsperaModal') agendaEsperaModal: AgendaEsperaComponent;
  @ViewChild('fullCalendar') fullCalendar: FullCalendarComponent;
  @ViewChild('tabelaAgendamentos') tabelaAgendamentos: ElementRef;

  private hubConnection: HubConnection;

  private getCalendarViewByType() {
    switch (this.agendamentoView) {
      case AgendamentoViewType.CalendarioDia:
        return 'listDay';

      case AgendamentoViewType.CalendarioSemana:
        return 'dayGridWeek';

      case AgendamentoViewType.CalendarioMes:
        return 'dayGridMonth';
    }

    return 'dayGridMonth';
  }

  agendamentoStatusParcialType: typeof AgendamentoStatusParcialType = AgendamentoStatusParcialType;

  AgendamentoViewType: typeof AgendamentoViewType = AgendamentoViewType;

  isBusy: boolean = true;
  agendamentoView: AgendamentoViewType = AgendamentoViewType.ListaCompleta;

  recursos: SelectDto[];
  paciente: PacienteModel;
  listaDisponibilidade: AgendaDisponibilidadeModel[];
  listaDispEventual: AgendaDisponibilidadeEventualModel[];
  anotacoes: string;
  anotacoesOld: string;

  agendamentoFilterDto = new AgendamentoFilterDto();

  calendarEvents: any[];
  calendarOptions: CalendarOptions = {
    height: 580,
    dayMaxEventRows: true,
    slotLabelFormat: [{
      hour: '2-digit',
      minute: '2-digit'
    }],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dispAgendaButton bloqAgendaButton updateButton'
    },
    customButtons: {
      dispAgendaButton: {
        text: 'Disp. Agenda',
        click: () => {
          this.showDispAgenda();
        }
      },
      bloqAgendaButton: {
        text: 'Bloq. Agenda',
        click: () => {
          this.showBloqAgenda();
        }
      },
      updateButton: {
        text: 'Atualizar Agenda',
        click: () => {
          this.filter();
        }
      },
    },
    initialView: 'dayGridMonth',
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit'
    },
    events: (info, successCallback, failureCallback) => {
      this.filterCalendario(info, successCallback, failureCallback);
    },
    selectable: false,
    eventClick: this.fullCalendarEventClick.bind(this),
    locale: ptBrLocale
  };

  selectColumns = [
    { data: 'dataHoraInicial' },
    { data: 'horario' },
    { data: 'dia' },
    { data: 'profissionalRecursoNome' },
    { data: 'classificacaoNome' },
    { data: 'status' },
    { data: 'statusParcial' },
    { data: 'nomePaciente' },
    { data: 'celularPaciente' },
    { data: 'convenioNome' },
    { data: 'observacoes' },
    { data: 'motivoCancelamento', hidden: true },
    { data: 'dataSolicConfirmacao', hidden: true },
    { data: 'telefonePaciente', hidden: true },
    { data: 'profissionalRecursoId', hidden: true },
    { data: 'codigoPaciente', hidden: true }
  ];

  constructor(
    injector: Injector,
    private dateAdapter: DateAdapter<Date>,
    readonly typesService: TypesService,
    readonly profissionalRecursoService: ProfissionalRecursoService,
    readonly pacienteService: PacienteService,
    readonly agendaDispService: AgendaDisponibilidadeService,
    readonly agendaDispEventualService: AgendaDisponibilidadeEventualService,
    readonly service: AgendamentoService,
    readonly printService: PrintService    
  ) {
    super(injector, service);
    this.dateAdapter.setLocale('pt-BR');
    this.agendamentoFilterDto.start = this.fromJsonDate(new Date());
    this.agendamentoFilterDto.end = this.fromJsonDate(new Date());
    this.agendamentoFilterDto.dateList = this.fromJsonDate(new Date());

    const status: any = '';
    this.agendamentoFilterDto.status = status;
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[0, 'asc'], [1, 'asc']];
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';

    this.refreshAnotacoes();

    this.profissionalRecursoService.getSelectListUser().subscribe(x => {
      this.recursos = x;

      this.agendamentoView = parseInt(localStorage.getItem('app.agendamentoView')) || AgendamentoViewType.ListaCompleta;
      this.calendarOptions.initialView = this.getCalendarViewByType();

      this.agendamentoFilterDto.recursoIds = [];
      const recursoIds = localStorage.getItem('app.recursoIds');
      if (recursoIds) {
        this.agendamentoFilterDto.recursoIds = localStorage.getItem('app.recursoIds').split(',');
        this.changeRecurso(this.agendamentoFilterDto.recursoIds);
      }

      this.isBusy = false;
    });

    this.paciente = new PacienteModel();
    this.paciente.pessoa = new PessoaModel();
    this.paciente.pessoa.cnpjCpf = '';
    this.paciente.pessoa.nomeFantasia = '';

    // let builder = new HubConnectionBuilder();
    // this.hubConnection = builder.withUrl(`${environment.baseUrl}/hub?tenant=${localStorage.getItem('app.tenant')}`).build();
    // this.hubConnection.start();
    // this.hubConnection.on('Send', (type: string) => {
    //   if (type === 'update-agenda') {
    //     this.filter();
    //   }

    //   if (type === 'update-anotacoes') {
    //     this.refreshAnotacoes();
    //   }
    // });
    // this.hubConnection.onclose(() => {
    //   this.hubConnection.start();
    // });
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    this.agendamentoFilterDto.start = this.fromJsonDate(moment(this.agendamentoFilterDto.dateList).toDate());
    this.agendamentoFilterDto.end = this.fromJsonDate(moment(this.agendamentoFilterDto.dateList).toDate());
    dataTablesParameters.customParam = JSON.stringify(this.agendamentoFilterDto);

    return super.beforeDtRequest(dataTablesParameters);
  }

  async selectRecursoAsync(): Promise<SelectDto> {
    if (this.agendamentoFilterDto?.recursoIds) {
      if (this.agendamentoFilterDto.recursoIds.length === 1) {
        return this.recursos.find(x => x.value === this.agendamentoFilterDto.recursoIds[0]);
      }

      let buttons: string = '';
      this.agendamentoFilterDto.recursoIds.forEach(recursoId => {
        const recurso = this.recursos.find(x => x.value === recursoId);
        buttons += `<button id="recurso${recursoId}" type="button" class="swal2-confirm swal2-styled">${recurso.text}</button>`;
      });
      const result = await Swal.fire({
        title: 'Disponibilidade de Agenda',
        html:
          `<h5 class="text-center">Selecione um Profissional/Equipamento abaixo: *</h5>${buttons}`,
        icon: 'question',
        focusCancel: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Fechar',
        didOpen: () => {
          this.agendamentoFilterDto.recursoIds.forEach(recursoId => {
            document.getElementById(`recurso${recursoId}`).addEventListener('click', () => Swal.close({ isConfirmed: true, isDismissed: false, isDenied: false, value: recursoId }));
          });
        }
      });

      if (result.value) {
        return this.recursos.find(x => x.value === result.value);
      }
    }

    return null;
  }

  showDispAgenda() {
    if (!this.agendamentoFilterDto?.recursoIds || this.agendamentoFilterDto.recursoIds.length > 1) {
      this.commonService.mensagem("Selecione um Profissional ou Equipamento", "", "info");
    } else {
      let recurso = this.recursos.find(x => x.value === this.agendamentoFilterDto.recursoIds[0]);
      let data = new Date(this.agendamentoFilterDto.dateList);
      this.agendaDispModal.show(recurso.value, recurso.text, data);
    }
  }

  showBloqAgenda() {
    if (!this.agendamentoFilterDto?.recursoIds || this.agendamentoFilterDto.recursoIds.length > 1) {
      this.commonService.mensagem("Selecione um Profissional ou Equipamento", "", "info");
    } else {
      let recurso = this.recursos.find(x => x.value === this.agendamentoFilterDto.recursoIds[0]);
      this.agendaBloqModal.show(recurso.value, recurso.text);
    }
  }

  showAgendaEspera() {
    this.selectRecursoAsync().then(recurso => {
      (recurso) && this.agendaEsperaModal.show(recurso.value, recurso.text);
    });
  }

  async changeRecurso(recursoIds) {
    localStorage.setItem('app.recursoIds', recursoIds.join(','));

    let hiddenDays: DayOfWeekType[] = [];
    let diasSemana: DayOfWeekType[] = [];

    await this.atualizarDisponibilidade(recursoIds);

    const businessHours = [];
    if (this.listaDisponibilidade?.length > 0) {
      this.calendarOptions.slotDuration = this.listaDisponibilidade.map(x => x.duracao).reduce((prev, current) => (prev < current) ? prev : current);
      this.calendarOptions.slotMinTime = this.listaDisponibilidade.map(x => x.horarioInicial).reduce((prev, current) => (prev < current) ? prev : current);
      this.calendarOptions.slotMaxTime = this.listaDisponibilidade.map(x => x.horarioFinal).reduce((prev, current) => (prev > current) ? prev : current);

      this.listaDisponibilidade.forEach(x => {
        businessHours.push({
          // days of week. an array of zero-based day of week integers (0=Sunday)
          daysOfWeek: [x.diaSemana], // Monday - Thursday    
          startTime: x.horarioInicial, // a start time (10am in this example)
          endTime: x.horarioFinal, // an end time (6pm in this example)
        })
      });

      diasSemana = this.listaDisponibilidade.map(x => x.diaSemana);
    }

    if (this.listaDispEventual.length > 0) {
      this.listaDispEventual.forEach(item => {
        let diaSemana = moment(item.data).weekday();
        if (diasSemana.findIndex(x => x === diaSemana) === -1) {
          diasSemana.push(diaSemana);
        }
      });
    }

    if (diasSemana.findIndex(x => x === DayOfWeekType.Domingo) === -1) {
      hiddenDays.push(DayOfWeekType.Domingo);
    }
    if (diasSemana.findIndex(x => x === DayOfWeekType.Sabado) === -1) {
      hiddenDays.push(DayOfWeekType.Sabado);
    }

    this.calendarOptions.hiddenDays = hiddenDays;
    this.filter();
  }

  async atualizarDisponibilidade(recursoIds: any) {
    this.listaDisponibilidade = await this.agendaDispService.getByRecursoIds(recursoIds).toPromise();
    this.listaDispEventual = await this.agendaDispEventualService.getByRecursoIdAndPeriodo(recursoIds, this.agendamentoFilterDto.start, this.agendamentoFilterDto.end).toPromise();
  }

  async changeView() {
    localStorage.setItem('app.agendamentoView', this.agendamentoView.toString());

    if (this.agendamentoView !== AgendamentoViewType.ListaCompleta) {
      const calendarApi = this.fullCalendar?.getApi();
      calendarApi?.changeView(this.getCalendarViewByType());
    } else {
      this.filter();
    }
  }

  changeStatus(status) {
    this.agendamentoFilterDto.status = status;
    this.filter();
  }

  changeFiltroPaciente() {
    if (this.agendamentoFilterDto.pacienteAnotacao) {
      this.agendamentoFilterDto.pacienteId = '';
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
      this.agendamentoFilterDto.naoFiltrarData = true;
      this.agendamentoFilterDto.naoFiltrarRecurso = true;
      document.getElementById('nomePaciente').focus();
    } else {
      this.agendamentoFilterDto.nomePaciente = '';
      this.agendamentoFilterDto.naoFiltrarData = false;
      this.agendamentoFilterDto.naoFiltrarRecurso = false;
      document.getElementById('cpfPaciente').focus();
    }
  }

  fullCalendarEventClick(clickInfo: EventClickArg) {
    console.log(this.calendarEvents);

    console.log(clickInfo);

    const agendamento = this.calendarEvents.find(x => x.id === clickInfo.event.id);
    console.log(agendamento);

    if (agendamento?.disponivel) {
      this.create(agendamento);
    } else {
      this.service.getById(clickInfo.event.id).subscribe(agendamento => this.detail(agendamento));
    }
  }

  filter() {
    if (this.agendamentoFilterDto.pacienteAnotacao && !this.agendamentoFilterDto.nomePaciente) {
      this.commonService.mensagem("Informe um nome de paciente para executar o filtro.", "", "info");
      return;
    }

    if (!this.agendamentoFilterDto.nomePaciente && !this.agendamentoFilterDto.pacienteId) {
      this.agendamentoFilterDto.naoFiltrarData = false;
    }

    if (this.agendamentoView === AgendamentoViewType.ListaCompleta) {
      this.commonService.spinnerOpen('Carregando...');
      super.filter();
    } else {
      const calendarApi = this.fullCalendar.getApi();
      calendarApi.refetchEvents();
    }
  }


  async filterCalendario(info, successCallback, failureCallback) {

    // //Contornar erro de período maluco do objeto "info" do FullCalendar na primeira vez
    // //ou quando é modificado a view da datatables para fullCalendar.
    // let dias = this.commonService.getDiasDiferenca(moment(info.start).toDate(),moment(info.end).add(-1, 'days').toDate());
    // if (dias > 32) return;
    // //


    if (!this.agendamentoFilterDto.recursoIds) {
      return;
    }

    this.agendamentoFilterDto.start = this.fromJsonDate(info.start);
    this.agendamentoFilterDto.end = this.fromJsonDate(moment(info.end).add(-1, 'days').toDate());
    this.agendamentoFilterDto.pacienteId = this.paciente?.id;

    //Forçar atualização de disponibilidade dentro do período (semana/mês)
    const recursoIds = localStorage.getItem('app.recursoIds');
    if (recursoIds) {
      this.agendamentoFilterDto.recursoIds = localStorage.getItem('app.recursoIds').split(',');
      await this.atualizarDisponibilidade(this.agendamentoFilterDto.recursoIds);
    }
    //

    this.commonService.spinnerOpen('Carregando...');

    try {
      let listaAgendamento = await this.service.getFilter(this.agendamentoFilterDto).toPromise();
      this.calendarEvents = listaAgendamento;

      //Criar lista de objetos de eventos do FullCalendar
      const events = listaAgendamento.map(x => {
        return {
          id: x.id,
          backgroundColor: this.typesService.getAgendamentoStatusColorType(x.status),
          title: `${this.typesService.getAgendamentoStatusType(x.status)} - ${x.nomePaciente || ''} (${x.classificacaoNome} - ${x.profissionalRecursoNome})`,
          start: x.dataHoraInicial
        }
      });

      successCallback(events);
    } catch (errors) {
      failureCallback(errors);
    }

    this.commonService.spinnerClose();
  }

  create(model: AgendamentoModel = null) {
    const newModel = new AgendamentoModel();
    if (model) {
      const dayOfWeek = moment(model.data).weekday();
      const time = `${model.horario}:00`;

      let naoDisponivel = !this.listaDisponibilidade?.find(x => x.profissionalRecursoId === model.profissionalRecursoId
        && x.diaSemana === dayOfWeek
        && time >= x.horarioInicial
        && time < x.horarioFinal);

      let naoDisponivelEventual = !this.listaDispEventual?.find(x => x.profissionalRecursoId === model.profissionalRecursoId
        && x.data === model.data
        && time >= x.horarioInicial
        && time < x.horarioFinal);

      if (naoDisponivel && naoDisponivelEventual) {
        this.commonService.mensagem('Data/Hora selecionada não está disponível para agendamento!', '', 'warning');
        return;
      }

      newModel.data = model.data;
      newModel.horario = model.horario;
      newModel.classificacaoId = model.classificacaoId;
      newModel.profissionalRecursoId = model.profissionalRecursoId;
    }
    newModel.id = this.commonService.newGuid();
    newModel.tipo = AgendamentoType.Agendamento;
    newModel.agendamentoRecorrenteId = "";
    newModel.itemRecorrencia = 0;
    newModel.totalItensRecorrencia = 0;
    
    if (this.agendamentoFilterDto.recursoIds?.length === 1) {
      newModel.profissionalRecursoId = this.agendamentoFilterDto.recursoIds[0];
    }
    super.create(newModel);
  }

  detail(model: AgendamentoModel) {
    if (model?.status === AgendamentoStatusType.Bloqueado) {
      this.commonService.mensagem('Agendamento Bloqueado!', `Motivo: ${model.observacoes || '-'}`, 'info');
      return;
    }

    super.detail(model);
  }

  prevDayClick() {
    this.agendamentoFilterDto.dateList = this.fromJsonDate(moment(this.agendamentoFilterDto.dateList).add(-1, 'days').toDate());
    //this.filter();
  }

  nextDayClick() {
    this.agendamentoFilterDto.dateList = this.fromJsonDate(moment(this.agendamentoFilterDto.dateList).add(1, 'days').toDate());
    //this.filter();
  }

  todayClick() {
    this.agendamentoFilterDto.dateList = this.fromJsonDate(new Date());
    this.filter();
  }

  get isToday(): boolean {
    return this.fromJsonDate(moment(this.agendamentoFilterDto.dateList).toDate()) === this.fromJsonDate(new Date());
  }

  onPacienteResponse(pacienteId: string) {
    this.agendamentoFilterDto.pacienteId = pacienteId;
    if (!pacienteId) {
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
      this.filter();
    }
    else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;
        this.filter();
      });
    }
  }

  saveAnotacoes() {
    this.service.saveAnotacoes(this.anotacoes).subscribe(() => {
      this.anotacoesOld = this.anotacoes;
    });
  }

  refreshAnotacoes() {
    this.service.getAnotacoes().subscribe(x => {
      this.anotacoes = x;
      this.anotacoesOld = x;
    });
  }

  showAgendamento(item) {    
    item.disponivel ? this.create(item) : this.detail(item);
  };

  print() {
    const content = this.tabelaAgendamentos.nativeElement.innerHTML;
    this.printService.print('Agendamentos', content);
  }

  get FiltroDataTexto() {
    if (this.agendamentoFilterDto.pacienteAnotacao) {
      return "Data incial = (Hoje - 30 dias) e  Data Final = (Hoje + 180 dias)";
    } else {
      return "Não Filtrar Data";
    }
  }

  showListaPaciente() {
    this.router.navigate(['/paciente'], { });
  }
  
  async confirmacaoLote() {
    if (!this.accountService.hasModulo(this.typesService.MODULO_ATENDIMENTOONLINE)) {
      await this.commonService.mensagem("Módulo não está disponível","Fale com o administrador do seu sistema.","warning");
      return;
    }
    
    this.router.navigate(['agendamento-confirmacaolote'], { relativeTo: this.route.parent });
  }
}
