import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import * as moment from 'moment';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ProtocoloDocModalComponent } from './protocolo-doc-modal/protocolo-doc-modal.component';
import { ProtocoloDocModel } from '../domain/models';
import { ProtocoloDocService } from '../domain/services';
import { DataTablesRequest, ModelSearch, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { PacienteModel } from '../../paciente/domain/models';
import { PacienteService } from '../../paciente/domain/services';

@Component({
  selector: 'app-protocolo-doc',
  templateUrl: './protocolo-doc.component.html'
})
export class ProtocoloDocComponent extends BaseCrudComponent<ProtocoloDocModel> implements OnInit {
  @ViewChild('modal') modal: ProtocoloDocModalComponent;
  
  searchStatus = new ModelSearch('entregue', OptionSearchType.Equals, SearchType.Boolean, false);    
  searchPaciente = new ModelSearch('pacienteId', OptionSearchType.Equals, SearchType.Guid);  
  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime, this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime, this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)));

  paciente: PacienteModel;

  constructor(
    injector: Injector,
    readonly service: ProtocoloDocService,
    readonly pacienteService: PacienteService
  ) {
    super(injector, service);
  }

  selectColumns = [ 
    { data: 'codigo'},
    { data: 'titulo'},
    { data: 'detalhes'},
    { data: 'paciente.pessoa.nomeFantasia as pacienteNome'},
    { data: 'responsavel'},
    { data: 'entregue'},
    { data: 'dataEntrega'},
    { data: 'entreguePara'},
  ];

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];    
    (this.searchStatus.searchTerm !== null) && searches.push(this.searchStatus);
    (this.searchPaciente.searchTerm) && searches.push(this.searchPaciente);
    if (this.searchDataInicial.searchTerm) {
      const searchDataInicial = Object.assign({}, this.searchDataInicial);
      searchDataInicial.searchTerm = `${this.fromJsonDate(searchDataInicial.searchTerm)} 00:00`;
      searches.push(searchDataInicial);
    }
    if (this.searchDataFinal.searchTerm) {
      const searchDataFinal = Object.assign({}, this.searchDataFinal);
      searchDataFinal.searchTerm = `${this.fromJsonDate(searchDataFinal.searchTerm)} 23:59`;
      searches.push(searchDataFinal);
    }

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }  

  ngOnInit() {
    super.ngOnInit();  
    this.onPacienteResponse(null);  
  }

  onPacienteResponse(pacienteId: string) {    
    this.searchPaciente.searchTerm = pacienteId;
    if (!pacienteId) {      
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';      
    }
    else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;        
      });
    }        
  }
}
