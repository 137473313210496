import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ProfissionalSaudeModalComponent } from './profissional-saude-modal/profissional-saude-modal.component';
import { EspecialidadeMedModel, ProfissionalSaudeModel, AreaAtuacaoMedModel } from '../domain/models';
import { EspecialidadeMedService, AreaAtuacaoMedService, ProfissionalSaudeService } from '../domain/services';
import { BaseEntidadePessoasComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoas.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-profissional-saude',
  templateUrl: './profissional-saude.component.html',
  styleUrls: ['./profissional-saude.component.scss']
})
export class ProfissionalSaudeComponent extends BaseEntidadePessoasComponent<ProfissionalSaudeModel> implements OnInit {
  @ViewChild('profissionalSaudeModal') modal: ProfissionalSaudeModalComponent;

  especialidadesMed: EspecialidadeMedModel[];
  areasAtuacaoMed: AreaAtuacaoMedModel[];

  corposClinicos: ProfissionalSaudeModel[];

  searchEspecialidadesMed = new ModelSearch('especialidadeMed.id', OptionSearchType.Equals, SearchType.Guid);
  searchAreaAtuacaoMed = new ModelSearch('areaAtuacaoMedIds', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('pessoa.nomeFantasia', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'ordem' },
    { data: 'pessoa.nomeFantasia' },
    { data: 'especialidadeMed.nome' },
    { data: 'areasAtuacaoMed.Select(y => y.areaAtuacaoMed.nome) as areaAtuacaoMedNomes', orderable: false },
    { data: 'nomeApresentacao' },
    { data: 'registroAtivo' },
    { data: 'usuario.Nome'},
    { data: 'usaConfirmacaoLote'},
    { data: 'temHotsite', orderable: false },
    { data: 'profissionalRecursoId', hidden: true },
  ];

  constructor(
    injector: Injector,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly especialidadeMedService: EspecialidadeMedService,
    readonly areaAtuacaoService: AreaAtuacaoMedService,
    readonly typesService: TypesService
  ) {
    super(injector, profissionalSaudeService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.especialidadeMedService.getAll().subscribe(especialidadesMed => this.especialidadesMed = especialidadesMed);
    this.atualizaOrdem();
  }
  
  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchEspecialidadesMed.searchTerm) && searches.push(this.searchEspecialidadesMed);
    (this.searchAreaAtuacaoMed.searchTerm) && searches.push(this.searchAreaAtuacaoMed);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  changeEspecialidadeMed(especialidadeMedId) {
    if (especialidadeMedId) {
      this.areaAtuacaoService.getByEspecialidadeMedId(especialidadeMedId)
        .subscribe(areasAtuacaoMed => this.areasAtuacaoMed = areasAtuacaoMed);
    } else {
      this.areasAtuacaoMed = [];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.corposClinicos, event.previousIndex, event.currentIndex);
    const ids = this.corposClinicos.map(x => x.id);
    this.profissionalSaudeService.updateOrdens(ids).subscribe();
  }

  atualizaOrdem() {
    this.profissionalSaudeService.getAll().subscribe(corposClinicos => this.corposClinicos = corposClinicos);
  }
}
