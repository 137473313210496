import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { PacienteService } from '../../paciente/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { RelatorioService } from '../domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { CommonService } from 'projects/ProjetoBaseAngular/domain/services';
import * as moment from 'moment';

@Component({
  selector: 'app-rel-pacientes-periodo',
  templateUrl: './rel-pacientes-periodo.component.html'
})
export class RelPacientesPeriodoComponent extends BaseComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  pacientes: SelectDto[];

  titulo: string = "Relatório de Pacientes Novos no Período";
  dataAtual: string;
  dataInicial: string;
  dataFinal: string;

  listaDto: any[] = [];

  totalGeral: number;
  totalClinica: number;
  totalMedico: number;
  qtdRegistros: number;

  isBusy: boolean;
  contentPrinted: boolean = false;

  constructor(
    injector: Injector,
    readonly service: RelatorioService,
    readonly printService: PrintService,
    readonly commonService: CommonService,
    readonly pacienteService: PacienteService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.isAllowed()) {
      this.commonService.mensagem("", "Você não tem permissão de execução de relatórios!", "info");
    }

    this.dataAtual = moment().format('DD/MM/YYYY');
    this.dataInicial = this.fromJsonDate(new Date());
    this.dataFinal = this.fromJsonDate(new Date());
  }

  isAllowed(): boolean {
    return super.isAllowed("Read", "RelPacientePeriodo");
  }

  getFilterDto(): any {
    const filterDto = {
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
    }

    return filterDto;
  }

  async print() {
    this.listaDto = [];

    if (!this.commonService.validarDataInput(this.dataInicial)) {
      this.commonService.mensagem("Atenção", "Digite uma data inicial válida");
      return;
    }
    if (!this.commonService.validarDataInput(this.dataFinal)) {
      this.commonService.mensagem("Atenção", "Digite uma data final válida");
      return;
    }
    
    this.commonService.spinnerOpen("Filtrando Relatório...");
    this.isBusy = true;
    this.listaDto = await this.service.getPacientesPeriodo(this.getFilterDto()).toPromise();

    if (this.listaDto.length > 0){
      this.qtdRegistros = this.listaDto.length;
      this.commonService.spinnerClose();
  
      this.contentPrinted = false;

    } else {
      this.commonService.spinnerClose();
      this.isBusy = false;
      this.commonService.mensagem("Não foram encontrados dados para esta seleção", "", "info");
    }
  }

  onContentPrinted() {
    this.contentPrinted = true;
    const content = this.relatorio.nativeElement.innerHTML;
    this.printService.print(this.titulo, content);
    this.isBusy = false;
    this.commonService.spinnerClose();
  }
}
