import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { LayoutLaudoModalComponent } from './layout-laudo-modal/layout-laudo-modal.component';
import { LayoutLaudoModel } from '../domain/models';
import { LayoutLaudoService } from '../domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-layout-laudo',
  templateUrl: './layout-laudo.component.html'
})
export class LayoutLaudoComponent extends BaseCrudComponent<LayoutLaudoModel> implements OnInit {
  @ViewChild('modal') modal: LayoutLaudoModalComponent;

  searchNome = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);  
  OptionSearchType: typeof OptionSearchType = OptionSearchType;
  
  selectColumns = [ 
    { data: 'nome'}
  ];

  constructor(
    injector: Injector,
    readonly service: LayoutLaudoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    (this.searchNome.searchTerm) && searches.push(this.searchNome);

    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }
}
