<div class="panel-group" role="tablist" aria-multiselectable="true">
  <div class="panel" id="panelFilter">
    <div class="panel-heading" role="tab" id="headingFilter">
      <h4 class="panel-title">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionFilter" href="#collapseFilter"
          aria-expanded="false" aria-controls="collapseFilter">
          <span class="material-icons">filter_alt</span> Filtros
        </a>
      </h4>
    </div>
    <div id="collapseFilter" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFilter"
      aria-expanded="false">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <mat-form-field>
              <mat-label>Período de Inclusão</mat-label>
              <input matInput [(ngModel)]="searchDataInicial.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-3 col-sm-6">
            <mat-form-field>
              <input matInput [(ngModel)]="searchDataFinal.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select [(ngModel)]="searchEstado.searchTerm" (selectionChange)="changeEstado($event.value)">
                <mat-option value="">Todos Estados</mat-option>
                <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <mat-select [(ngModel)]="searchCidade.searchTerm">
                <mat-option value="">Todos Cidades</mat-option>
                <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="panel-footer" style="text-align: right">
        <mat-slide-toggle style="margin-right: 10px;" color="primary" [(ngModel)]="searchRegistroAtivo.searchTerm">
          Somente Reg.Ativos
        </mat-slide-toggle>
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="card main-content">
  <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
  <div class="header">
    <h2>Tenants</h2>
  </div>
  <div class="body table-responsive">
    <!--query end-->
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center" width="150">Data Inclusão</th>
          <th class="text-center" width="100">Subdomínio</th>
          <th class="text-center" width="200">Token API WhatsApp</th>
          <th class="text-center">Nome</th>
          <th class="text-center" width="150">CNPJ/CPF</th>
          <th class="text-center">Cidade/UF</th>          
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="models?.length != 0">
        <tr *ngFor="let tenant of models">
          <td>
            {{tenant.dataInclusao | date :'dd/MM/yyyy HH:mm'}}
          </td>
          <td>
            {{tenant.subdominio}}
          </td>
          <td>
            {{tenant.tokenWhatsAppApi}}
          </td>
          <td>
            {{tenant.nomeFantasia}}
          </td>
          <td>
            {{tenant.cnpjCpf}}
          </td>
          <td>
            {{tenant.cidadeUf}}
          </td>
          <td class="text-center">
            <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="createStartup(tenant)"
              matTooltip="Criar Ambiente (Startup)">
              <mat-icon>fact_check</mat-icon>
            </button>
            <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(tenant)"
              matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
            <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(tenant.id)"
              matTooltip="Excluir">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="fixed-action-btn">
    <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
      matTooltipPosition="above">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<app-tenant-modal #tenantModal (modalSave)="onResponse($event)"></app-tenant-modal>
