<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg" [ngClass]="{'modal-xl': hasPaciente}">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Agendamento</h2>
        </div>
        <div class="modal-body">
          <div class="row">
            <div [ngClass]="{'col-md-6': hasPaciente, 'col-md-12': !hasPaciente}">
              <div class="alert alert-danger" *ngIf="errors.length > 0">
                <h3>Oops! Algo deu errado:</h3>
                <ul>
                  <li *ngFor="let error of errors">{{error}}</li>
                </ul>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <mat-form-field>
                    <mat-label>Profissional da Saúde/Equipamento</mat-label>
                    <mat-select formControlName="profissionalRecursoId" required>
                      <mat-option *ngFor="let recurso of recursos" [value]="recurso.value">
                        {{recurso.text}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <span class="badge" *ngIf="form.value.tipo !== agendamentoType.Recorrente"
                    [ngClass]="typesService.agendamentoTypeColor(form.value.tipo)"
                    style="margin-top: 25px;">
                    {{typesService.agendamentoTypeNome(form.value.tipo)}}
                  </span>
                  <span class="badge" *ngIf="form.value.tipo === agendamentoType.Recorrente"
                    [ngClass]="typesService.agendamentoTypeColor(form.value.tipo)"
                    style="margin-top: 25px;">
                    {{typesService.agendamentoTypeNome(form.value.tipo)}} - {{form.value.itemRecorrencia}}/{{form.value.totalItensRecorrencia}}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select [value]="form.value.status" disabled>
                      <mat-option *ngFor="let status of typesService.agendamentoStatusType" [value]="status.value">
                        {{status.text}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione um Status.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Data</mat-label>
                    <input matInput formControlName="data" type="date" #focus required />
                    <mat-error>Informe um Data válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>Horário</mat-label>
                    <input matInput formControlName="horario" type="time" required />
                    <mat-error>Informe um Horario válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Sala</mat-label>
                    <mat-select formControlName="salaId">
                      <mat-option *ngFor="let sala of salas" [value]="sala.value">
                        {{sala.text}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione uma Sala.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row" *ngIf="!form.value.pacienteAnotacao">
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Cód./CPF Paciente</mat-label>
                    <input matInput [disabled]="form.value.pacienteAnotacao || detailMode"
                      [value]="paciente?.pessoa?.cnpjCpf" type="text"
                      (blur)="pacienteSelect.changeDocumento($event.target.value)" maxlength="18" required />
                    <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                      (click)="pacienteSelect.showSelect()" matTooltip="Selecionar Paciente">
                      <mat-icon>search</mat-icon>
                    </button>
                    <button [disabled]="detailMode" matSuffix mat-icon-button type="button"
                      (click)="pacienteSelect.create()" matTooltip="Adicionar Paciente">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="col-md-8">
                  <mat-form-field>
                    <mat-label>Paciente</mat-label>
                    <input matInput disabled
                      [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia"
                      type="text" />
                    <button [disabled]="isBusy || !paciente?.id" matSuffix mat-icon-button type="button"
                      (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
                      <mat-icon>mode_edit</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <mat-slide-toggle formControlName="pacienteAnotacao" color="primary" (change)="onChangeAnotacao()">
                    Paciente Anotação
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="row" *ngIf="form.value.pacienteAnotacao">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Nome Paciente</mat-label>
                    <input matInput formControlName="nomePaciente" type="text" required />
                    <mat-error>Informe um Nome Paciente válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Celular</mat-label>
                    <input matInput formControlName="celularPaciente" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" />
                    <mat-error>Informe um Celular Paciente válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Telefone</mat-label>
                    <input matInput formControlName="telefonePaciente" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 0000-0000" placeholder="Ex: (48) 3333-3333" />
                    <mat-error>Informe um Telefone Paciente válido.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Convênio</mat-label>
                    <mat-select formControlName="convenioId">
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Buscar (letras iniciais)..."
                          noEntriesFoundLabel="Nenhum resultado encontrado!" [formControl]="convenioFilterCtrl">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of filteredConvenios" [value]="item.value">
                        {{item.text}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione um Convênio válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Classificação</mat-label>
                    <mat-select formControlName="classificacaoId">
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Buscar (letras iniciais)..."
                          noEntriesFoundLabel="Nenhum resultado encontrado!" [formControl]="classificacaoFilterCtrl">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of filteredClassificacoes" [value]="item.value">
                        {{item.text}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione uma Classificação válida.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-tab-group animationDuration="0ms">
                    <mat-tab label="Observações">
                      <mat-form-field>
                        <textarea matInput formControlName="observacoes" rows="6" #obs></textarea>
                        <mat-hint align="end">{{obs.value.length}} / 1000</mat-hint>
                      </mat-form-field>
                    </mat-tab>
                    <mat-tab label="Pré-Atendimento">
                      <mat-form-field>
                        <textarea matInput formControlName="preAtendimento" rows="6" #pre></textarea>
                        <mat-hint align="end">{{pre.value.length}} / 1000</mat-hint>
                      </mat-form-field>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="hasPaciente">
              <div class="table-responsive">
                <h4 class="text-center" style="margin-bottom: 0;">Histórico de Atendimentos</h4>
                <table datatable [dtOptions]="dtOptionsAtendimento" *ngIf="dtOptionsAtendimento"
                  class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th class="text-center" width="100">Data/Hora</th>
                      <th class="text-center" width="30">Ret.</th>
                      <th class="text-center" width="150">Profissional/Equip.</th>
                      <th class="text-center">Convênio</th>
                      <th class="text-center">Observações</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="atendimentosPaciente?.length != 0">
                    <tr *ngFor="let item of atendimentosPaciente" (click)="atendimentoEntradaModal.showDetail(item.id);"
                      style="cursor: pointer;">
                      <td *ngIf="item.agendamentoId">
                        <span [ngClass]="typesService.getAtendimentoTypeColor(atendimentoType.Atendimento)"
                          class="label" matTooltip="Atendimento agendado">
                          {{item.dataHoraEntrada | date:'dd/MM/yyyy HH:mm'}}
                        </span>
                      </td>
                      <td *ngIf="!item.agendamentoId">
                        <span [ngClass]="typesService.getAtendimentoTypeColor(atendimentoType.NaoAgendado)"
                          class="label" matTooltip="Atendimento não agendado">
                          {{item.dataHoraEntrada | date:'dd/MM/yyyy HH:mm'}}
                        </span>
                      </td>
                      <td>
                        <span *ngIf="item.agendamentoClassificacaoRetorno !== null; else elseBlock"
                          [ngClass]="{'bg-purple': item.agendamentoClassificacaoRetorno, 'bg-green': !item.agendamentoClassificacaoRetorno}"
                          class="label">
                          {{typesService.getBoolTypeNome(item.agendamentoClassificacaoRetorno)}}
                        </span>
                        <ng-template #elseBlock>
                          <span [ngClass]="{'bg-orange': true}" class="label">Não</span>
                        </ng-template>
                      </td>
                      <td>{{item.profissionalRecursoNome}}</td>
                      <td>{{item.convenioNome}}</td>
                      <td>{{item.observacoes}}</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <h4 class="text-center" style="margin-bottom: 0;">Agendamentos</h4>
                <table datatable [dtOptions]="dtOptionsAgendamento" *ngIf="dtOptionsAgendamento"
                  class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th class="text-center" width="60">Data</th>
                      <th class="text-center" width="30">Hora</th>
                      <th class="text-center" width="150">Profissional/Equip.</th>
                      <th class="text-center">Convênio</th>
                      <th class="text-center">Observações</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="agendamentosPaciente?.length != 0">
                    <tr *ngFor="let item of agendamentosPaciente">
                      <td>{{item.dataHoraInicial | date:'dd/MM/yyyy'}}</td>
                      <td>{{item.horario | mask:'hh:MM'}}</td>
                      <td>{{item.profissionalRecursoNome}}</td>
                      <td>{{item.convenioNome}}</td>
                      <td>{{item.observacoes}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="footer" *ngIf="!detailMode">
        <div class="row">
          <div class="col-sm-8 text-left">
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="bloquearAgenda()" color="warn"
              matTooltip="Bloquear na Agenda" class="button-row" *ngIf="newItem">
              <mat-icon>block</mat-icon>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="showAgendaEspera()" color="primary"
              matTooltip="Abrir Agenda de Espera" class="button-row" *ngIf="statusAgendadoEncaixe">
              <mat-icon>hourglass_top</mat-icon>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="copiarBotDocDeTransf()" color="primary"
              matTooltip="Bot - Copiar Documento da área de transferência" class="button-row">
              <mat-icon>file_copy</mat-icon>
            </button>
            <button *ngIf="isRecorrenciaAtiva" [disabled]="isBusy" mat-flat-button
              type="button" (click)="telaRecorrencia()"
              [ngClass]="typesService.agendamentoTypeColor(agendamentoType.Recorrente)"
              matTooltip="Configurar agendamento recorrente.">
              <i class="material-icons">event_repeat</i>
              <span>Recorrência</span>
            </button>
          </div>
          <div class="col-sm-4">
            <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
              class="button-row">
              <span>Salvar</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span>Cancelar</span>
            </button>
          </div>
        </div>
      </div>
      <div class="footer" *ngIf="detailMode">
        <div class="row">
          <div class="col-sm-3 text-left">
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="showAgendamentoLog(this.form.value.id)"
              color="primary" class="button-row" matTooltip="Logs do Agendamento">
              <mat-icon>history</mat-icon>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="encaixar(this.form.value.id)"
              color="primary" matTooltip="Lançar Encaixe de Horário para outro Paciente" class="button-row">
              <mat-icon>extension</mat-icon>
            </button>
            <button *ngIf="isRecorrenciaAtiva" [disabled]="isBusy" mat-flat-button
              type="button" (click)="telaRecorrencia()"
              [ngClass]="typesService.agendamentoTypeColor(agendamentoType.Recorrente)"
              matTooltip="Configurar agendamento recorrente.">
              <i class="material-icons">event_repeat</i>
              <span>Recorrência</span>
            </button>
          </div>
          <div class="col-sm-9">
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="marcarAtendido(this.form.value.id)"
              color="primary" class="button-row" matTooltip="Marcar como Atendido"
              *ngIf="form.value.status === typesService.AgendamentoStatusType.LancadoEntrada">
              <i class="far fa-check-circle fa-lg"></i>
              <span> Marcar Atendido</span>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="desfazerAtendido(this.form.value.id)"
              color="primary" class="button-row" matTooltip="Volta Status para Lançado Entrada"
              *ngIf="form.value.status === typesService.AgendamentoStatusType.Atendido">
              <mat-icon>undo</mat-icon>
              <span> Desfazer Atendido</span>
            </button>

            <button mat-flat-button [disabled]="isBusy" type="button" (click)="lancar(this.form.value.id)"
              color="primary" class="button-row" matTooltip="Lançar Entrada do Paciente"
              *ngIf="statusAgendadoEncaixe || form.value.status === typesService.AgendamentoStatusType.Confirmado">
              <i class="far fa-check-circle fa-lg"></i>
              <span> Lançar</span>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="solicitarConfirmacao(this.form.value.id)"
              color="primary"
              [matTooltip]="this.form.value.dataSolicConfirmacao ? 'Solicitar Confirmação Novamente' : 'Solicitar Confirmação de Presença'"
              class="button-row" *ngIf="statusAgendadoEncaixe">
              <i *ngIf="!this.form.value.dataSolicConfirmacao" class="far fa-paper-plane fa-lg"></i>
              <i *ngIf="this.form.value.dataSolicConfirmacao" class="fas fa-paper-plane fa-lg"></i>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="confirmarPresenca(this.form.value.id)"
              color="primary" matTooltip="Confirmar Presença" class="button-row" *ngIf="statusAgendadoEncaixe">
              <i class="far fa-thumbs-up fa-lg"></i>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="desconfirmarPresenca(this.form.value.id)"
              color="primary" matTooltip="Desfazer Confirmação - Retorna status p/ Agendado" class="button-row"
              *ngIf="form.value.status === typesService.AgendamentoStatusType.Confirmado">
              <mat-icon>undo</mat-icon>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="naoCompareceu(this.form.value.id)"
              color="primary" matTooltip="Não Compareceu" class="button-row"
              *ngIf="statusAgendadoEncaixe || form.value.status === typesService.AgendamentoStatusType.Confirmado">
              <i class="far fa-thumbs-down fa-lg"></i>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="cancelar(this.form.value.id)"
              color="warn" class="button-row"
              *ngIf="form.value.status !== typesService.AgendamentoStatusType.Cancelado 
              && form.value.status !== typesService.AgendamentoStatusType.LancadoEntrada">
              <mat-icon>cancel</mat-icon>
              <span> Cancelar</span>
            </button>
            <button mat-flat-button [disabled]="isBusy" type="button" (click)="desfazerLancamento(this.form.value.id)"
              color="warn" class="button-row"
              *ngIf="form.value.status === typesService.AgendamentoStatusType.LancadoEntrada">
              <mat-icon>undo</mat-icon>
              <span> Desfazer Lanc.</span>
            </button>
            <button mat-flat-button *ngIf="statusEntradaAtendidoCancelado" type="button"
              (click)="editarObservacoes(form.value.id)" color="primary" class="button-row">
              <mat-icon>mode_edit</mat-icon>
              <span> Editar Observação</span>
            </button>
            <button mat-flat-button [disabled]="isBusy || statusEntradaAtendidoCancelado" type="button"
              (click)="showEdit(form.value.id)" color="primary" class="button-row">
              <mat-icon>mode_edit</mat-icon>
              <span> Editar</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span>Fechar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>
<app-paciente-modal #pacienteAtendimentoModal (modalSave)="onPacienteAtendimentoResponse($event)"></app-paciente-modal>
<app-agendamento-log #agendamentoLogModal></app-agendamento-log>
<app-atendimento-entrada-modal #atendimentoEntradaModal (modalSave)="onAtendimentoResponse($event)">
</app-atendimento-entrada-modal>
<app-agenda-espera #agendaEsperaModal (modalSelect)="onAgendaEsperaResponse($event)"></app-agenda-espera>
<app-agendamento-recorrente #agendaRecorrenteModal
  (modalClose)="onRecorrenciaResponse($event)"></app-agendamento-recorrente>