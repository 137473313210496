import { Component, OnInit, ViewChild, Injector, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as moment from 'moment';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AtendimentoService } from '../../domain/services';
import { AtendimentoFilterDto, AtendimentoModel, AtestadoDto } from '../../domain/models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ProfissionalRecursoService } from '../../domain/services/profissional-recurso.service';
import { ConvenioService } from '../../../parceiro/domain/services/convenio.service';
import { PacienteService } from '../../../paciente/domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { AtendimentoStatusType } from '../../domain/types';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { DocMovtoEstoqueType, DocStatusType, OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { ProfissionalRecursoType } from 'projects/admin/src/domain/types';
import { PacienteModalComponent } from '../../../paciente/paciente/paciente-modal/paciente-modal.component';
import { PacienteProntuarioService } from '../../../paciente/domain/services/paciente-prontuario.service';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { ProfissionalSaudeModel } from '../../../equipe/domain/models';
import { ProfissionalSaudeService } from '../../../equipe/domain/services';
import { ConvenioModel } from '../../../parceiro/domain/models';
import { AtendimentoHistoricoModalComponent } from '../atendimento-historico-modal/atendimento-historico-modal.component';
import { AtendimentoReceitaHistoricoModalComponent } from '../atendimento-receita/atendimento-receita-historico-modal/atendimento-receita-historico-modal.component';
import { AtendimentoSolicExameHistoricoModalComponent } from '../atendimento-solic-exame/atendimento-solic-exame-historico-modal/atendimento-solic-exame-historico-modal.component';
import { AtestadoModeloModel } from '../../../modelos/domain/models';
import { AtestadoService } from '../../../modelos/domain/services/atestado.service';
import { AtendimentoAtestadoHistoricoModalComponent } from '../atendimento-atestado-historico-modal/atendimento-atestado-historico-modal.component';

@Component({
  selector: 'app-atendimento-modal',
  templateUrl: './atendimento-modal.component.html'
})
export class AtendimentoModalComponent extends BaseCrudModalComponent<AtendimentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('pacienteModal') pacienteModal: PacienteModalComponent;
  @ViewChild('historicoModal') historicoModal: AtendimentoHistoricoModalComponent;
  @ViewChild('modalReceitaHistorico') modalReceitaHistorico: AtendimentoReceitaHistoricoModalComponent;
  @ViewChild('modalExameHistorico') modalExameHistorico: AtendimentoSolicExameHistoricoModalComponent;
  @ViewChild('modalAtestadoHistorico') modalAtestadoHistorico: AtendimentoAtestadoHistoricoModalComponent;

  @Output() atendimentoModal: EventEmitter<string> = new EventEmitter<string>();
  @Input('readOnly') readOnly: boolean;

  usuarioId: string;
  paciente: any;
  profissionalAtendimento: ProfissionalSaudeModel;
  profissionalUsuario: ProfissionalSaudeModel;
  convenio: ConvenioModel;

  dtOptionsAtendimento: DataTables.Settings = null;
  dtOptionsAtestado: DataTables.Settings = null;

  atendimentosPaciente: any[] = [];
  atestados: AtestadoModeloModel[];

  currentTabIndex: number = -1;

  duracaoAtendimento: string = "";
  horaFinal: string = "";
  tempo = 60000;
  temporizador = null;

  avisoAnotacaoAtivo: boolean = false;
  txtPacienteTooltip: string = "";
  primeiroAviso = true;


  constructor(
    injector: Injector,
    readonly service: AtendimentoService,
    readonly profissionalRecursoService: ProfissionalRecursoService,
    readonly convenioService: ConvenioService,
    readonly pacienteService: PacienteService,
    readonly profissionalService: ProfissionalSaudeService,
    readonly pacienteProntuarioService: PacienteProntuarioService,
    readonly atestadoService: AtestadoService,
    readonly usuarioService: UsuarioService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.primeiroAviso = true;
  }

  protected init() {
    this.usuarioId = this.accountService.getUser().id;
    this.profissionalService.getByUsuarioId(this.usuarioId).subscribe(x => this.profissionalUsuario = x);
    this.initAtestados();
    this.atualizarHora();
    this.temporizador = setInterval(() => this.atualizarHora(), this.tempo);
  }

  protected initializeForm(model: AtendimentoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      status: [model.status, Validators.required],
      profissionalRecursoId: [model.profissionalRecursoId, [Validators.required]],
      profissionalSolicitanteId: [model.profissionalSolicitanteId],
      profissionalExecutanteId: [model.profissionalExecutanteId],
      agendamentoId: [model.agendamentoId],
      pacienteId: [model.pacienteId, [Validators.required]],
      convenioId: [model.convenioId, [Validators.required]],
      dataHoraEntrada: [this.fromJsonDateTime(model.dataHoraEntrada), [Validators.required]],
      dataHoraInicial: [this.fromJsonDateTime(model.dataHoraInicial)],
      dataHoraFinal: [this.fromJsonDateTime(model.dataHoraFinal)],
      statusDocumento: [model.statusDocumento],
      numeroNotaFiscal: [model.numeroNotaFiscal],
      gerouNotaFiscal: [model.gerouNotaFiscal],
      anamnese: model.anamnese,
      diagnostico: model.diagnostico,
      atestado: model.atestado,
      examesFisicos: model.examesFisicos,

      codigoDocMovto: model.codigoDocMovto,
      docMovto: this.formBuilder.group({
        id: model.docMovto.id,
        dataInclusao: model.docMovto.dataInclusao,
        dataAlteracao: model.docMovto.dataAlteracao,
        dataEmissao: model.dataEmissao,
        dataAplicacao: model.dataAplicacao,
        statusDocumento: model.docMovto.statusDocumento,
        tipoMovtoEstoque: model.docMovto.tipoMovtoEstoque,
        tipoDocMovto: model.docMovto.tipoDocMovto
      }),

      subTotal: [model.subTotal],
      total: [model.total],
      observacoes: [model.observacoes]
    });

    this.form.patchValue({
      procedimentos: model.procedimentos,
      medicamentos: model.medicamentos
    });

    if (model.pacienteId) {
      this.loadPaciente();
      this.initAtendimentos();
    }

    this.loadConvenio(model.convenioId);
    this.loadProfissionalSaude(model.profissionalRecursoId);

    this.currentTabIndex = -1;
    setTimeout(() => {
      this.currentTabIndex = 0;
    }, 500);
  }

  async loadConvenio(convenioId: string) {
    this.convenio = await this.convenioService.getById(convenioId).toPromise();
  }

  async loadProfissionalSaude(id: string) {
    this.profissionalAtendimento = await this.profissionalService.getById(id).toPromise();
  }

  async loadPaciente() {
    let pacienteId = this.form.value.pacienteId;

    if (pacienteId) {
      const selectColumns = [
        { data: 'pessoa.cnpjCpf' },
        { data: 'pessoa.nomeFantasia' },
        { data: 'pessoa.codigo' },
        { data: 'pessoa.sexo' },
        { data: 'pessoa.dataNascimento' },
        { data: 'pessoa.email' },
        { data: 'estadoCivil' },
        { data: 'profissao' },
        { data: 'exibirAlertaAtendimento' },
        { data: 'mensagemBloqAlerta' },
        { data: '(pessoa.cidade.nome + "/" + pessoa.estado.uf) as pessoaCidadeUf' },
        { data: 'anotacaoProntuario' }
      ];

      this.paciente = await this.pacienteService.getSelectById(pacienteId, selectColumns).toPromise();
      this.paciente.idade = this.commonService.getIdade(this.paciente.pessoaDataNascimento);

      this.txtPacienteTooltip = "Dados do paciente";
      if (this.primeiroAviso) {
        let qtdProntuario = await this.pacienteProntuarioService.getCountByPacienteId(pacienteId).toPromise();
        this.avisoAnotacaoAtivo = (this.paciente.anotacaoProntuario || qtdProntuario) ? true : false;

        this.txtPacienteTooltip += this.paciente.anotacaoProntuario ? " : (1 Anotação)" : "";
        this.txtPacienteTooltip += qtdProntuario > 0 ? ` : (${qtdProntuario} Histórico(s))` : "";
      }

      this.primeiroAviso = false;

      if (this.paciente.exibirAlertaAtendimento) {
        this.commonService.mensagem('Alerta do Paciente', this.paciente.mensagemBloqAlerta, 'warning');
      }
    }
  }

  async save(autoSave: boolean = false) {
    this.pararTemporizador();

    let model: AtendimentoModel = Object.assign({}, this.form.value);
    model = this.beforeSave(model);

    try {
      if (this.newItem) {
        model = await this.service.create(model).toPromise();
      } else {
        model = await this.service.edit(model).toPromise();
      }

      this.newItem = false;
      this.afterSave(model);

      if (!autoSave) {
        if (this.form.value.status !== AtendimentoStatusType.Atendido) {
          let titulo = "Confirmar finalização do atendimento?";
          let mensagem = "Atenção: Após a finalização, se necessário, será possivel editar Atendimento dentro do período de 24hs.";

          let result = await this.commonService.mensagemConfirmacao(titulo, mensagem, "question")
          if (result) {
            try {
              let aplicado = await this.service.applyDoc(this.form.value.id).toPromise();
              if (aplicado) {
                this.form.patchValue({ status: AtendimentoStatusType.Atendido });
                this.close(true);
                this.modalSave.emit(model.id);
              }
            } catch (errors) {
              this.commonService.mensagem("Erro!", errors.join('<br/>'), "error");
              throw (errors);
            }
          }

          this.isBusy = false;
          return;
        }

        this.close(true);
      }
      this.modalSave.emit(model.id);
      this.isBusy = false;

    } catch (errors) {
      this.verifyErrors(errors);
      this.isBusy = false;
      Swal.fire(
        'Oops! Algo deu errado',
        errors.join('/n'),
        'error');
    }
  }

  async iniciarAtendimento() {
    if (!this.profissionalUsuario.id) {
      await this.mensagemUsuarioNaoProf();
      return;
    }

    if (this.profissionalUsuario.id !== this.profissionalAtendimento.id) {
      await this.mensagemProfDiferente();
      return;
    }

    let model: AtendimentoModel = Object.assign({}, this.form.value);
    model.status = AtendimentoStatusType.EmAtendimento;
    model.dataHoraInicial = new Date();

    this.service.edit(model).subscribe(
      (model) => {
        this.afterSave(model);
        this.isBusy = false;
        this.showEdit(this.form.value.id);
        this.modalSave.emit(model.id);
      },
      (errors) => {
        this.verifyErrors(errors);
        this.isBusy = false;
        Swal.fire(
          'Oops! Algo deu errado',
          errors.join('/n'),
          'error');
      }
    );
  }

  async continuarAtendimento() {
    if (!this.profissionalUsuario.id) {
      await this.mensagemUsuarioNaoProf();
      return;
    }

    if (this.profissionalUsuario.id !== this.profissionalAtendimento.id) {
      await this.mensagemProfDiferente();
      return;
    }

    this.showEdit(this.form.value.id);
  }

  async editarAtendimento() {
    if (!this.profissionalUsuario.id) {
      await this.mensagemUsuarioNaoProf();
      return;
    }

    if (this.profissionalUsuario.id !== this.profissionalAtendimento.id) {
      await this.mensagemProfDiferente();
      return;
    }

    const model: AtendimentoModel = Object.assign({}, this.form.value);

    let diferenca = Math.round(this.commonService.getHorasDiferenca(model.dataHoraFinal, new Date()));
    if (diferenca > 24) {
      await this.commonService.mensagem("Atenção", "Após 24 horas o registro não poderá mais ser editado.", "info");
      return false;
    }

    this.showEdit(model.id);
  }


  /**
   * Caso de atendimento sem agendamento.
   */
  async novoAtendimento() {
    if (!this.profissionalUsuario.id) {
      await this.mensagemUsuarioNaoProf();
      return;
    }

    var atendimento = new AtendimentoModel();
    atendimento.id = this.commonService.newGuid();
    atendimento.dataInclusao = new Date();
    atendimento.pacienteId = this.form.value.pacienteId;
    atendimento.convenioId = this.form.value.convenioId;

    if (this.profissionalUsuario.id === this.profissionalAtendimento.id) {
      atendimento.profissionalRecursoId = this.form.value.profissionalRecursoId;
    } else {
      atendimento.profissionalRecursoId = this.profissionalUsuario.id;
      this.loadProfissionalSaude(atendimento.profissionalRecursoId);
    }

    atendimento.dataHoraEntrada = new Date();
    atendimento.dataHoraInicial = new Date();
    atendimento.status = AtendimentoStatusType.EmAtendimento;

    atendimento.dataHoraEntrada = new Date();
    atendimento.dataEmissao = new Date();
    atendimento.subTotal = 0;
    atendimento.percentualAcrescimo = 0;
    atendimento.valorAcrescimo = 0;
    atendimento.percentualDesconto = 0;
    atendimento.valorDesconto = 0;
    atendimento.total = 0;
    atendimento.statusDocumento = DocStatusType.Saved;
    atendimento.gerouNotaFiscal = false;

    //#region DocMovto
    atendimento.docMovto.id = atendimento.id;
    atendimento.docMovto.dataInclusao = atendimento.dataInclusao;
    atendimento.docMovto.dataEmissao = atendimento.dataEmissao;
    atendimento.docMovto.statusDocumento = atendimento.statusDocumento;
    atendimento.docMovto.tipoMovtoEstoque = DocMovtoEstoqueType.SemMovimento;
    //#endregion

    this.showCreate(atendimento);
  }

  async mensagemUsuarioNaoProf() {
    let mensagem = "Verifique o cadastro 'Profissional da Saúde' para linkar um usuário à um profissional."
    await this.commonService.mensagem("Usuário não é um Profissional da Saúde", mensagem, "warning");
  }

  async mensagemProfDiferente() {
    let titulo = `Profissional responsável deste Atendimento é: ${this.profissionalAtendimento.nomeApresentacao}`;
    let mensagem = "Somente o resposável poderá iniciar, dar continuidade ou editar o Atendimento."
    await this.commonService.mensagem(titulo, mensagem, "warning");
  }

  initAtestados() {
    this.dtOptionsAtestado = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      paging: false,
      info: false,
      searching: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters.selectColumns = dataTablesParameters.columns;
        dataTablesParameters.selectColumns.push({ data: 'texto' });
        this.atestadoService.getResponse(dataTablesParameters).subscribe(resp => {
          this.atestados = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { data: 'nome' },
        { data: 'cids' }
      ]
    };
  }

  initAtendimentos() {
    this.dtOptionsAtendimento = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      paging: false,
      info: false,
      scrollY: "200px",
      scrollCollapse: true,
      searching: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters.selectColumns = dataTablesParameters.columns;
        dataTablesParameters.selectColumns.push({ data: 'status' });
        dataTablesParameters.selectColumns.push({ data: 'agendamentoId' });
        const atendimentoFilterDto = new AtendimentoFilterDto();
        atendimentoFilterDto.naoFiltrarData = true;
        atendimentoFilterDto.naoFiltrarRecurso = true;
        atendimentoFilterDto.pacienteId = this.form.value.pacienteId;
        dataTablesParameters.customParam = JSON.stringify(atendimentoFilterDto);

        dataTablesParameters.searches = dataTablesParameters.searches || [];
        dataTablesParameters.searches.push(new ModelSearch('profissionalRecurso.tipo', OptionSearchType.Equals, SearchType.Int, ProfissionalRecursoType.ProfissionalSaude));

        if (!atendimentoFilterDto.pacienteId) {
          this.atendimentosPaciente = [];
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        } else {
          this.service.getResponse(dataTablesParameters).subscribe(resp => {
            this.atendimentosPaciente = resp.data.filter(x => x.id !== this.form.value.id);
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }
      },
      order: [[0, 'desc']],
      columns: [
        { data: 'dataHoraEntrada' }
      ]
    };
  }

  addAtestado(model: AtestadoModeloModel) {
    if (this.detailMode)
      return;

    let atestado = this.form.value.atestado;
    atestado = atestado ? atestado += '\n' : '';
    atestado += model.texto;
    this.form.patchValue({ atestado });
  }

  close(jaSalvo: boolean = false) {
    if (this.detailMode || jaSalvo) {
      this.pararTemporizador();
      super.close();
      return;
    }

    Swal.fire({
      title: 'Salvar Atendimento ao Sair?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      denyButtonText: 'Não Salvar',
      cancelButtonText: 'Retornar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.save(true);
      }

      if (!result.isDismissed) {
        this.pararTemporizador();
        super.close();
      }
    });
  }

  atualizarHora() {
    let dataHoraFinal = this.form?.value.status === AtendimentoStatusType.EmAtendimento ?
      new Date() :
      this.form?.value.dataHoraFinal ? moment(this.form?.value.dataHoraFinal).toDate() : null;

    if (!dataHoraFinal) {
      this.horaFinal = "";
      this.duracaoAtendimento = "";
    } else {
      this.horaFinal = moment(dataHoraFinal).format('HH:mm');
      const diff = moment.utc(moment(this.form?.value.dataHoraInicial).diff(dataHoraFinal));
      this.duracaoAtendimento = diff.format('HH:mm');
    }
  }

  pararTemporizador() {
    clearInterval(this.temporizador);
  }

  tinymceConfigLocal(height?: number) {
    return {
      height,
      base_url: '/tinymce',
      suffix: '.min',
      language: 'pt_BR',
      language_url: '/assets-base/langs/pt_BR.js',
      entity_encoding: 'numeric',
      extended_valid_elements: 'i[class],script[src|async|defer|type|charset]',
      plugins: 'hr nonbreaking anchor toc wordcount',
      //selector: 'textarea',
      menubar: '',
      toolbar: '',
      setup: null
    }
  }

  async showHistorico() {
    const model: AtendimentoModel = Object.assign({}, this.form.value);
    let emEdicao = !this.readOnly && !this.detailMode;

    if (emEdicao) {
      this.commonService.spinnerOpen("Salvando atendimento automaticamente ...");
      await this.save(true);
      this.commonService.spinnerClose();
    }

    this.historicoModal.show(model.pacienteId);
  }

  showEditPacienteProntuario(id: string) {
    this.avisoAnotacaoAtivo = false;
    this.pacienteModal.showEditProntuario(id, this.form.value.profissionalRecursoId);
  }

  showReceitaHistorico() {
    this.modalReceitaHistorico.show();
  }

  showExameHistorico() {
    this.modalExameHistorico.show();
  }

  showAtestadoHistorico() {
    this.modalAtestadoHistorico.show();
  }

  onCloneAtestadoResponse(atestadoDto: AtestadoDto) {
    if (atestadoDto) {
      this.form.patchValue({ atestado: atestadoDto.atestado });
    }
  }

}

