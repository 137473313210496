import { Component, OnInit, ViewChild, ElementRef, Output, Injector, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { CidadeService, EstadoService } from 'projects/ProjetoBaseAngular/domain/services';
import { CidadeModel, EstadoModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-cidade-modal',
  templateUrl: './cidade-modal.component.html'
})
export class CidadeModalComponent extends BaseCrudModalComponent<CidadeModel> implements OnInit {
  @ViewChild('cidadeModal') modal: ModalDirective;

  estados: EstadoModel[];

  constructor(
    injector: Injector,
    cidadeService: CidadeService,
    private estadoService: EstadoService
  ) {
    super(injector, cidadeService);
  }

  protected initializeForm(cidade: CidadeModel) {
    this.form = this.formBuilder.group({
      id: cidade.id,
      dataInclusao: [cidade.dataInclusao, Validators.required],
      dataAlteracao: cidade.dataAlteracao,
      estadoId: [cidade.estadoId, Validators.required],
      codigoIbge: [cidade.codigoIbge, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.minLength(7),
        Validators.maxLength(7)]
      ],
      nome: [cidade.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40)]
      ]
    });
  }

  protected init() {
    super.init();
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  ngOnInit() {
    super.ngOnInit();        
  }
}
