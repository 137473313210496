<div bsModal #pushModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="pushModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Notificação Push</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Status Publicação</mat-label>
                <mat-select formControlName="status" #focus>
                  <mat-option *ngFor="let status of status" [value]="status.value" >
                    {{status.text}}</mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="form.value.status === PushStatusType.Agendado">
              <mat-form-field>
                <mat-label>Data Agendamento</mat-label>
                <input matInput formControlName="dataAgendamento" type="datetime-local" />
                <mat-error>Informe uma Data válida.</mat-error>
              </mat-form-field>
            </div>
            <div [ngClass]="{'col-md-6': form.value.status === PushStatusType.Agendado, 'col-md-9': form.value.status === PushStatusType.Pendente}" >
              <mat-form-field>
                <mat-label>Título</mat-label>
                <input matInput formControlName="titulo" type="text"  />
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Mensagem</mat-label>
                <textarea matInput #mensagem formControlName="mensagem" rows="3" maxlength="400"></textarea>
                <mat-hint align="end">{{mensagem.value.length}} / 400</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>URL</mat-label>
                <input matInput formControlName="url" type="url" />
                <mat-error>Informe um URL válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
