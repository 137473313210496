import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { LaudoService, LayoutLaudoService, ProcedimentoService } from '../../domain/services';
import { LaudoModel, LayoutLaudoModel, ProcedimentoModel } from '../../domain/models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ProfissionalSaudeService, ProfissionalSaudeTextoPadraoService } from '../../../equipe/domain/services';
import { PacienteModel } from '../../../paciente/domain/models';
import { PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { ConvenioService } from '../../../parceiro/domain/services/convenio.service';
import { TypesService } from 'projects/admin/src/domain/services';
import { LaudoStatusType } from '../../domain/types';
import { LaudoMontarModalComponent } from '../laudo-montar-modal/laudo-montar-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ProfissionalSaudeTextoPadraoModel } from '../../../equipe/domain/models';

@Component({
  selector: 'app-laudo-modal',
  templateUrl: './laudo-modal.component.html'
})
export class LaudoModalComponent extends BaseCrudModalComponent<LaudoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  profissionais: SelectDto[];
  paciente: PacienteModel;
  procedimento: ProcedimentoModel;
  layoutLaudoIdOld: string;

  convenios: SelectDto[];
  layoutsLaudo: SelectDto[];
  filteredLayoutLaudos: SelectDto[] = [];
  
  public layoutFilterCtrl: FormControl = new FormControl();

  textosPadroes: ProfissionalSaudeTextoPadraoModel[] = [];
  
  constructor(
    injector: Injector,
    service: LaudoService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly profissionalSaudeTextoPadraoService: ProfissionalSaudeTextoPadraoService,
    readonly pacienteService: PacienteService,
    readonly convenioService: ConvenioService,
    readonly procedimentoService: ProcedimentoService,
    readonly layoutLaudoService: LayoutLaudoService,
    readonly typesService: TypesService,
    readonly dialog: MatDialog
  ) {
    super(injector, service);
  }


  ngOnInit() {
    super.ngOnInit();

    this.profissionalSaudeService.getSelectList().subscribe(x => this.profissionais = x);
    this.convenioService.getSelectList().subscribe(x => this.convenios = x);
    
    this.layoutLaudoService.getSelectList().subscribe(x => {
      this.layoutsLaudo = x;
      this.filteredLayoutLaudos = x;
    });

    this.layoutFilterCtrl.valueChanges.subscribe(() => this.filterLayoutLaudo());
  }

  protected filterLayoutLaudo() {
    if (!this.layoutsLaudo) {
      return;
    }
    // pega a palavra chave da consulta
    let search = this.layoutFilterCtrl.value;
    
    if (!search) {
      this.filteredLayoutLaudos = this.layoutsLaudo.slice();
      return;
    } else {
      search = search.toLowerCase();
    }

    // filtra para a nova lista
    this.filteredLayoutLaudos = this.layoutsLaudo.filter(x => 
      x.text?.toLowerCase().startsWith(search)
    );    
  }

  protected initializeForm(model: LaudoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      atendimentoId: [model.atendimentoId,Validators.required],
      status: [model.status,Validators.required],
      profissionalSolicitanteId: [model.profissionalSolicitanteId,Validators.required],
      profissionalLaudanteId: [model.profissionalLaudanteId,Validators.required],
      profissionalTecnicoId: [model.profissionalTecnicoId || ''],
      pacienteId: [model.pacienteId, Validators.required],
      convenioId: [model.convenioId, Validators.required],
      procedimentoId: [model.procedimentoId, Validators.required],
      layoutLaudoId: [model.layoutLaudoId],
      cabecalho: [model.cabecalho, Validators.maxLength(500)],
      texto: [model.texto, Validators.required],
      rodape: [model.rodape, Validators.maxLength(500)],
      tokenVersion: [model.tokenVersion]
    });

    if (model.pacienteId) {
      this.onPacienteResponse(model.pacienteId);
    }

    if (model.profissionalLaudanteId) {
      this.changeProfissional(model.profissionalLaudanteId);
    }

    if (model.procedimentoId) {
      this.procedimentoService.getById(model.procedimentoId).subscribe(x => {
        this.procedimento = x;
      });
    }

    this.layoutLaudoIdOld = model.layoutLaudoId;
  }

  async changeLayoutLaudoAsync(layoutLaudoId: string) {
    const ok =
      this.layoutLaudoIdOld !== layoutLaudoId &&
      (
        !this.form.value.texto ||
        await this.commonService.mensagemConfirmacao(
          'Deseja Alterar o Layout do Laudo?',
          'Ao confirmar o texto digitado será substituido.',
          'info'
        )
      );

    if (!ok) {
      this.form.patchValue({ layoutLaudoId: this.layoutLaudoIdOld });
      return;
    }

    this.layoutLaudoService.getById(layoutLaudoId).subscribe(x => {
      this.form.patchValue({ texto: x.texto });
    });
  }

  onPacienteResponse(pacienteId: string) {
    this.form.patchValue({ pacienteId });
    if (!pacienteId) {
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
    } else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;
      });
    }
  }

  laudar() {
    if (this.form.valid) {
      this.form.patchValue({ status: LaudoStatusType.Laudado });
      this.save();
    }
  }

  showMontarLaudo() {
    const dialogRef = this.dialog.open(LaudoMontarModalComponent, {
      width: '800px',
      data: { profissionalId: this.form.value.profissionalLaudanteId, procedimentoId: this.form.value.procedimentoId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.form.patchValue({ texto: result });
      }
    });
  }


  tinymceConfig(height?: number) {
    const config = super.tinymceConfig(height);

    config.setup = (editor) => {
      var onAction = (autocompleteApi, rng, value) => {
        editor.selection.setRng(rng);
        //Aqui testar como abaixo comentado
        editor.insertContent(`${value}`);
        //editor.insertContent(`<p>${value}</p>`);
        autocompleteApi.hide();
      };

      var getMatchedChars = (pattern) => {
        return this.textosPadroes.filter((char) => {
          return (char.palavrasChave && char.palavrasChave.toUpperCase().indexOf(pattern.toUpperCase()) !== -1) ||
            char.texto.toUpperCase().indexOf(pattern.toUpperCase()) !== -1;

            // return char.palavrasChave?.toUpperCase().indexOf(pattern.toUpperCase()) !== -1 || 
            // char.texto.toUpperCase().indexOf(pattern.toUpperCase()) !== -1;
        });
      };

      /**
       * An autocompleter that allows you to insert special characters.
       * Items are built using the CardMenuItem.
       */
      editor.ui.registry.addAutocompleter('specialchars_cardmenuitems', {
        ch: '#',
        minChars: 1,
        columns: 1,
        highlightOn: ['obs_name'],
        onAction: onAction,
        fetch: (pattern) => {
          return new Promise((resolve) => {
            const results = getMatchedChars(pattern).map((char) => {
              return {
                type: 'cardmenuitem',
                value: char.texto,                
                items: [
                  {
                    type: 'cardcontainer',
                    direction: 'vertical',
                    items: [
                      {
                        type: 'cardtext',
                        text: char.texto,
                        name: 'obs_name'
                      },
                      {
                        type: 'cardtext',
                        text: char.palavrasChave || ''
                      }
                    ]
                  }
                ]
              }
            });
            resolve(results);
          });
        }
      });
    }
    return config;
  }

  changeProfissional(profissionalId) {
    this.profissionalSaudeTextoPadraoService.getByProfissionalSaudeId(profissionalId).subscribe(textos => {
      this.textosPadroes = textos;
    });
  }
}
