<div bsModal #profissionalSaudeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}"
  (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header" [ngClass]="{'bg-red' : !form.value.registroAtivo}">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="row">
            <div class="col-md-6">
              <h2>Profissional da Saúde</h2>
            </div>
            <div class="col-md-5 text-right">
              <mat-slide-toggle formControlName="registroAtivo" color="primary">
                Reg.Ativo
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs">
            <li class="active">
              <a class="nav-link active" id="nav-profissionalSaude-tab" data-toggle="tab" href="#nav-profissionalSaude"
                role="tab" aria-controls="nav-profissionalSaude" aria-selected="true">Principal</a>
            </li>
            <li *ngIf="moduloPortal">
              <a class="nav-link" id="nav-hotSite-tab" data-toggle="tab" href="#nav-hotSite" role="tab"
                aria-controls="nav-hotSite" aria-selected="false">Dados Hotsite</a>
            </li>
          </ul>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade in active" id="nav-profissionalSaude" role="tabpanel"
              aria-labelledby="nav-profissionalSaude-tab">

              <div class="alert alert-danger" *ngIf="errors.length > 0">
                <h3>Oops! Algo deu errado:</h3>
                <ul>
                  <li *ngFor="let error of errors">{{ error }}</li>
                </ul>
              </div>
              <div formGroupName="pessoa">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group align-center">
                      <div style="height: 150px; width: 100%; object-fit: contain;">
                        <img src="{{form.value.pessoa.imagemUrl}}"
                          style="height: 150px; width: 100%; object-fit: contain;" *ngIf="form.value.pessoa.imagemUrl">
                      </div>
                      <div class="align-center">
                        <button mat-icon-button type="button" (click)="showSelectImage()"
                          matTooltip="Selecionar Imagem">
                          <mat-icon>add_photo_alternate</mat-icon>
                        </button>
                        <button mat-icon-button type="button" [disabled]="!form.value.pessoa.imagemUrl"
                          (click)="deselectImage()" matTooltip="Desvincular Imagem">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                      <p class="font-15 col-deep-blue text-center">
                        O tamanho ideal para Imagens é <strong>500x500</strong>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-10">
                    <h4 class="card-inside-title">Dados Principais</h4>
                    <div class="row">
                      <div class="col-md-2">
                        <mat-form-field>
                          <mat-label>Tipo Pessoa</mat-label>
                          <mat-select formControlName="tipoPessoa" (selectionChange)="changeTipoPessoa($event.value)"
                            #focus>
                            <mat-option *ngFor="let tipo of tipos" [value]="tipo.value">
                              {{tipo.text}}</mat-option>
                          </mat-select>
                          <mat-error></mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2">
                        <mat-form-field>
                          <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaJuridica">CNPJ
                          </mat-label>
                          <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaFisica">CPF
                          </mat-label>
                          <input matInput formControlName="cnpjCpf" type="text" [dropSpecialCharacters]="false"
                            mask="{{cnpjCpfMask}}" placeholder="{{cnpjCpfPlaceholder}}"
                            (blur)="changeCnpjCpf($event.target.value)" />
                          <mat-hint align="end">Máx 18 caracteres</mat-hint>
                          <mat-error></mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-7">
                        <mat-form-field>
                          <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaFisica">
                            Nome Completo
                          </mat-label>
                          <input matInput formControlName="nomeFantasia" type="text"
                            (blur)="prepararNome($event.target.value)" />
                          <mat-hint align="end">Máx 60 caracteres</mat-hint>
                          <mat-error></mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <h4 class="card-inside-title">Dados para Contato</h4>
                    <div class="row">
                      <div class="col-md-2">
                        <mat-form-field>
                          <mat-label>Telefone</mat-label>
                          <input matInput formControlName="telefone" type="tel" placeholder="Ex: (48) 3333-3333" />
                          <mat-hint align="end">Máx 15 caracteres</mat-hint>
                          <mat-error></mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2">
                        <mat-form-field>
                          <mat-label>Celular</mat-label>
                          <input matInput formControlName="celular" type="tel" [dropSpecialCharacters]="false"
                            mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" />
                          <mat-hint align="end">Máx 15 caracteres</mat-hint>
                          <mat-error></mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field>
                          <mat-label>E-mail</mat-label>
                          <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com" />
                          <mat-hint align="end">Máx 50 caracteres</mat-hint>
                          <mat-error></mat-error>
                        </mat-form-field>
                      </div>
                      <div class="pessoa-juridica">
                        <div class="col-md-4">
                          <mat-form-field>
                            <mat-label>Site</mat-label>
                            <input matInput formControlName="site" type="url" placeholder="Ex: meusite.com" />
                            <mat-hint align="end">Máx 50 caracteres</mat-hint>
                            <mat-error></mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h4 class="card-inside-title">Dados Adicionais</h4>
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Especialidade Médica</mat-label>
                    <mat-select formControlName="especialidadeMedId"
                      (selectionChange)="changeEspecialidadeMed($event.value)" required>
                      <mat-option *ngFor="let especialidadesMed of especialidadesMed" [value]="especialidadesMed.id">
                        {{especialidadesMed.nome}}
                      </mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Areas de Atuação Médica</mat-label>
                    <mat-select formControlName="areaAtuacaoMedIds" multiple>
                      <mat-option *ngFor="let areaAtuacaoMed of areasAtuacaoMed" [value]="areaAtuacaoMed.id">
                        {{areaAtuacaoMed.nome}}
                      </mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>CRM Médico</mat-label>
                    <input matInput formControlName="crmMedico" type="text" placeholder="Ex: 54875" />
                    <mat-hint align="end">Máx 7 caracteres</mat-hint>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>CRN Médico</mat-label>
                    <input matInput formControlName="crnMedico" type="text" placeholder="Ex: 54875" />
                    <mat-hint align="end">Máx 7 caracteres</mat-hint>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>RQE Médico</mat-label>
                    <input matInput formControlName="rqeMedico" type="text" placeholder="Ex: 54938" />
                    <mat-hint align="end">Máx 20 caracteres</mat-hint>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>

                <mat-slide-toggle formControlName="temHotsite" color="primary" *ngIf="moduloPortal">
                  HotSite Ativo
                </mat-slide-toggle>
              </div>

              <!-- Slug Hidden para poder preencher com o slugify -->
              <input formControlName="slug" type="hidden" />

              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Nome Apresentação</mat-label>
                    <input matInput formControlName="nomeApresentacao" type="text" maxlength="60" />
                    <mat-hint align="end">Máx 60 caracteres</mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Usuário Correspondente</mat-label>
                    <mat-select formControlName="usuarioId">
                      <mat-option [value]="null">Sem usuário vinculado</mat-option>
                      <mat-option *ngFor="let item of listaDtoUsuario" [value]="item.value">
                        {{item.text}}
                      </mat-option>
                    </mat-select>
                    <mat-hint>Vincule um usuário (sistema efetua controles no atendimento).</mat-hint>                    
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Usa Confirmação Lote</mat-label>
                    <mat-select formControlName="usaConfirmacaoLote">
                      <mat-option *ngFor="let item of typesService.boolTypes" [value]="item.value">
                        {{item.text}}
                      </mat-option>
                    </mat-select>
                    <mat-hint>Define se Profissional usa confirmação de agendamento em lote.</mat-hint>                    
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Observações</mat-label>
                    <textarea matInput #observacoes formControlName="observacoes" rows="1" maxlength="500"></textarea>
                    <mat-hint align="end">{{observacoes.value.length}} / 500</mat-hint>
                  </mat-form-field>
                </div>
              </div>

              <div formGroupName="pessoa">

                <h4 class="card-inside-title">Dados de endereço</h4>
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>CEP</mat-label>
                      <input matInput formControlName="cep" type="text" [dropSpecialCharacters]="false"
                        mask="00.000-000" placeholder="Ex: 00.000-000" (blur)="changeCep($event.target.value)" />
                      <mat-hint align="end">Máx 10 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field>
                      <mat-label>Rua / Avenida</mat-label>
                      <input matInput formControlName="ruaAvenida" type="text" />
                      <mat-hint align="end">Máx 50 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Número</mat-label>
                      <input matInput formControlName="numero" type="text" />
                      <mat-hint align="end">Máx 10 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Complemento</mat-label>
                      <input matInput formControlName="complemento" type="text" />
                      <mat-hint align="end">Máx 50 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Bairro</mat-label>
                      <input matInput formControlName="bairro" type="text" />
                      <mat-hint align="end">Máx 50 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Estado</mat-label>
                      <mat-select formControlName="estadoId" (selectionChange)="changeEstado($event.value)">
                        <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.uf}}
                        </mat-option>
                      </mat-select>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Cidade</mat-label>
                      <mat-select formControlName="cidadeId">
                        <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                        </mat-option>
                      </mat-select>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- fim tab pessoa -->

            <div class="tab-pane fade" id="nav-hotSite" role="tabpanel" aria-labelledby="nav-hotSite-tab">
              <h4 class="card-inside-title">Banner HotSite</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group align-center">
                    <div style="height: 150px; width: 100%; object-fit: contain;">
                      <img src="{{form.value.bannerHotSite}}" style="height: 150px; width: 100%; object-fit: contain;"
                        *ngIf="form.value.bannerHotSite">
                    </div>
                    <div class="align-center">
                      <button mat-icon-button type="button" (click)="showSelectImageBanner()"
                        matTooltip="Selecionar Imagem">
                        <mat-icon>add_photo_alternate</mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="!form.value.bannerHotSite"
                        (click)="deselectImageBanner()" matTooltip="Desvincular Imagem">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <p class="font-15 col-deep-blue text-center">
                      O tamanho ideal para o banner é <strong>1920x300</strong>
                    </p>
                  </div>
                </div>
              </div>
              <h4 class="card-inside-title">Layout HotSite</h4>
              <div class="row">
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>Cor Primaria HexCode</mat-label>
                    <input matInput #corPrimaria formControlName="corPrimaria" [(colorPicker)]="corPrimaria.value"
                      (colorPickerChange)="setCorPrimaria(corPrimaria.value)" [cpOutputFormat]="'hex'" maxlength="7" />
                    <mat-icon [style.color]="form.value.corPrimaria" matSuffix>brightness_1</mat-icon>
                    <mat-hint align="end">{{corPrimaria.value.length}} / 7</mat-hint>
                    <mat-error>Informe um HexCode válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field>
                    <mat-label>Cor Secundária HexCode</mat-label>
                    <input matInput #corSecundaria formControlName="corSecundaria" [(colorPicker)]="corSecundaria.value"
                      (colorPickerChange)="setCorSecundaria(corSecundaria.value)" [cpOutputFormat]="'hex'"
                      maxlength="7" />
                    <mat-icon [style.color]="form.value.corSecundaria" matSuffix>brightness_1</mat-icon>
                    <mat-hint align="end">{{corSecundaria.value.length}} / 7</mat-hint>
                    <mat-error>Informe um HexCode válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Layout Artigos</mat-label>
                    <mat-select formControlName="layoutArtigoHotSite">
                      <mat-option *ngFor="let layout of layoutsArtigos" [value]="layout.value">
                        {{layout.text}}</mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Icone do CRM ou CRN em HTML</mat-label>
                    <input matInput formControlName="iconeCrmCrnHtml" type="text" />
                    <mat-hint align="end">Máx 200 caracteres</mat-hint>
                    <button matSuffix mat-icon-button type="button" (click)="showSelectIcone()"
                      matTooltip="Selecionar Icone">
                      <mat-icon>library_add</mat-icon>
                    </button>
                    <mat-error>Informe um Html válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-1 font-size-h1" [innerHTML]="form.value.iconeCrmCrnHtml">
                </div>
              </div>
              <h4 class="card-inside-title">Redes Sociais</h4>
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Link Facebook</mat-label>
                    <input matInput formControlName="linkFacebook" type="text" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Link Instagram</mat-label>
                    <input matInput formControlName="linkInstagram" type="text" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Link Youtube</mat-label>
                    <input matInput formControlName="linkYoutube" type="text" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Link Twitter</mat-label>
                    <input matInput formControlName="linkTwitter" type="text" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Email HotSite</mat-label>
                    <input matInput formControlName="emailHotSite" type="text" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Numero WhatsApp</mat-label>
                    <input matInput formControlName="numeroWhatsApp" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Numero Telegram</mat-label>
                    <input matInput formControlName="numeroTelegram" type="tel" [dropSpecialCharacters]="false"
                      mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" />
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
              </div>
              <h4 class="card-inside-title">Currículo</h4>
              <div class="row">
                <div class="col-md-12">
                  <editor formControlName="curriculoHtml" [init]="tinymceConfig()">
                  </editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <div class="row">
          <div class="col-sm-3 text-left">
            <p style="margin: 0;">Incluído:{{form.value.dataInclusao | date:"dd/MM/yyyy"}}</p>
            <p style="margin: 0;">Alterado:{{form.value.dataAlteracao | date:"dd/MM/yyyy"}}</p>
          </div>
          <div class="col-sm-2 text-left">
          </div>
          <div class="col-sm-7">
            <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
              Continuar Incluindo
            </mat-slide-toggle>
            <button mat-flat-button [disabled]="isBusy" type="submit" (click)="save()" color="primary"
              class="button-row">
              <span> Salvar</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span> Cancelar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
<app-imagem-select-modal #imagemBannerSelectModal (modalClose)="onResponseBanner($event)"></app-imagem-select-modal>
<app-icone-select-modal #iconeSelectModal (modalClose)="onResponseIcone($event)"></app-icone-select-modal>