import { Component, OnInit, ViewChild, Injector, ElementRef } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { TermoService } from '../../domain/services';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { TermoComponent } from '../termo.component';
import { TypesService } from 'projects/admin/src/domain/services';
import * as moment from 'moment';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-termo-list-modal',
  templateUrl: './termo-list-modal.component.html'
})
export class TermoListModalComponent extends TermoComponent implements OnInit {
  @ViewChild('listModal') listModal: ModalDirective;
  @ViewChild('relatorio') relatorio: ElementRef;
  
  paciente: PacienteModel;
  conteudo: string;

  selectColumns = [
    { data: 'nome' }
  ];

  constructor(
    injector: Injector,
    readonly service: TermoService,
    readonly pacienteService: PacienteService,
    readonly tenantService: TenantService,
    readonly printService: PrintService,
    readonly sanitizer: DomSanitizer,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  async show(pacienteId: string) {
    this.paciente = await this.pacienteService.getById(pacienteId).toPromise();
    
    this.listModal.show();
    this.filter();
  }

  async print(id: string) { 
    const termo = await this.service.getById(id).toPromise();
    const tenant = await this.tenantService.getCurrentTenant().toPromise();

    let data = new Date();

    this.conteudo = termo.texto
    .replace(/{{pacienteNome}}/g, this.paciente.pessoa.nomeFantasia)
    .replace(/{{pacienteCpf}}/g, this.paciente.pessoa.cnpjCpf)
    .replace(/{{pacienteEndereco}}/g, `${this.paciente.pessoa.ruaAvenida}${this.paciente.pessoa.numero ? ', ' 
      + this.paciente.pessoa.numero : ''}, ${this.paciente.pessoa.bairro}, 
      ${this.paciente.pessoa.cidadeNome}/${this.paciente.pessoa.estadoUf}`)
    .replace(/{{pacienteCelular}}/g, this.paciente.pessoa.celular)
    .replace(/{{data}}/g, moment(data).format('DD/MM/YYYY'))
    .replace(/{{empresaNome}}/g, tenant.nomeFantasia)
    .replace(/{{empresaEndereco}}/g, `${tenant.ruaAvenida}${tenant.numero ? ', ' + tenant.numero : ''}, 
      ${tenant.bairro}, ${tenant.cidadeNome}/${tenant.estadoUf}`)
    .replace(/{{empresaCelular}}/g, tenant.celular);

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Termo', content);
    }, 1000);
  }

  close() {
    this.listModal.hide();
  }
}
