import { Component, OnInit, ViewChild, Output, ElementRef, EventEmitter, Injector, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { TenantComponenteModel } from 'projects/ProjetoBaseAngular/domain/models';
import { BaseTypesService } from 'projects/ProjetoBaseAngular/domain/services/base-types.service';

@Component({
  selector: 'app-tenant-componente-modal',
  templateUrl: './tenant-componente-modal.component.html'
})
export class TenantComponenteModalComponent extends BaseComponent implements OnInit {
  @ViewChild('focus', { static: false }) focusElement: ElementRef;
  @ViewChild('modal', { static: false }) modal: ModalDirective;  

  @Output() modalSave: EventEmitter<TenantComponenteModel> = new EventEmitter<TenantComponenteModel>();

  constructor(
    injector: Injector,
    readonly typeService: BaseTypesService
  ) {
    super(injector);
  }

  protected initializeForm(model: TenantComponenteModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      codigoItem: [
        model.codigoItem, [
          Validators.required,
          Validators.maxLength(20)
        ]
      ],
      nome: [
        model.nome, [
          Validators.required,
          Validators.maxLength(60)
        ]
      ],
      tipoItem: model.tipoItem,      
      quantidade: [model.quantidade, Validators.required]
    });
  };

  protected newModel(): TenantComponenteModel {
    let planoContratoItem = new TenantComponenteModel();
    planoContratoItem.id = this.commonService.newGuid();
    planoContratoItem.dataInclusao = new Date();
    planoContratoItem.quantidade = 1;
    return planoContratoItem;
  };

  ngOnInit() {
    super.ngOnInit();
  }

  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: TenantComponenteModel = Object.assign({}, this.form.value);
    this.modalSave.emit(model);
    this.modal.hide();
    this.form = null;
  }

  showCreate() {
    this.form = null;
    const model = this.newModel();
    this.initializeForm(model);
    this.modal.show();
  }

  showEdit(planoContratoItem: TenantComponenteModel) {
    this.form = null;
    planoContratoItem.dataAlteracao = new Date();
    this.initializeForm(planoContratoItem);
    this.modal.show();
  }

  close() {
    this.form = null;
    this.modal.hide();
  }

  onShown() {
    document.getElementById('codigoItem').focus();
  }  
}
