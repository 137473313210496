import { Injectable, Injector } from '@angular/core';
import { PushModel } from '../../models/notificacao/push-model';

import { BaseCrudService } from '../base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class PushService extends BaseCrudService<PushModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Push';
  }
}
