import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { TiposParceirosComponent } from './tipos-parceiros.component';
import { TipoParceiroModalComponent } from './tipo-parceiro-modal/tipo-parceiro-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TiposParceirosComponent,
    TipoParceiroModalComponent
  ]
})
export class TipoParceiroModule { }
