<div class="panel-group" role="tablist" aria-multiselectable="true">
  <div class="panel" id="panelFilter">
    <div class="panel-heading" role="tab" id="headingFilter">
      <h4 class="panel-title">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionFilter" href="#collapseFilter"
          aria-expanded="false" aria-controls="collapseFilter">
          <span class="material-icons">filter_alt</span> Filtros
        </a>
      </h4>
    </div>
    <div id="collapseFilter" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFilter"
      aria-expanded="false">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="pessoa.nomeFantasia">Nome</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um Nome válido.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Especialidade Médica</mat-label>
              <mat-select [(ngModel)]="searchEspecialidadesMed.searchTerm"
                (selectionChange)="changeEspecialidadeMed($event.value)">
                <mat-option value="">Todas as Especialidades</mat-option>
                <mat-option *ngFor="let especialidade of especialidadesMed" [value]="especialidade.id">
                  {{especialidade.nome}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Área de Atuação Médica</mat-label>
              <mat-select [(ngModel)]="searchAreaAtuacaoMed.searchTerm">
                <mat-option value="">Todas as Areas</mat-option>
                <mat-option *ngFor="let areaAtuacao of areasAtuacaoMed" [value]="areaAtuacao.id">{{areaAtuacao.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="panel-footer" style="text-align: right">
        <mat-slide-toggle style="margin-right: 10px;" color="primary" [(ngModel)]="searchRegistroAtivo.searchTerm">
          Somente Reg.Ativos
        </mat-slide-toggle>
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row clearfix">
  <div class="col-md-12">
    <div class="card main-content">
      <div class="header">

        <ul class="nav nav-tabs">
          <li class="active"><a class="nav-link active" id="nav-profissional-saude-tab" data-toggle="tab"
              href="#nav-profissional-saude" role="tab" aria-controls="nav-profissional-saude" aria-selected="true" (click)="filter()">Profissionais da Saúde</a></li>
          <li><a class="nav-link" id="nav-ordenacao-tab" data-toggle="tab" href="#nav-ordenacao" role="tab"
              aria-controls="nav-ordenacao" aria-selected="false" (click)="atualizaOrdem()">Ordenação</a></li>
        </ul>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade in active" id="nav-profissional-saude" role="tabpanel"
          aria-labelledby="nav-profissional-saude-tab">


          <div class="body table-responsive">
            <!--query end-->
            <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
              class="table table-bordered table-striped table-hover" style="width: 100%;">
              <thead class="thead-dark">
                <tr>
                  <th class="text-center" width="30">Ordem</th>
                  <th class="text-center">Nome</th>
                  <th class="text-center" width="150">Apresentação</th>                  
                  <th class="text-center" width="150">Usuário</th>
                  <th class="text-center" width="50">Conf.Lote</th>
                  <th class="text-center" width="150">Especialidade</th>
                  <th class="text-center" width="150">Atuação Médica</th>                  
                  <th class="text-center" width="50">Hotsite</th>
                  <th class="text-center" width="50">Ativo</th>
                  <th class="text-center" width="220"></th>
                </tr>
              </thead>
              <tbody *ngIf="models?.length != 0">
                <tr *ngFor="let profissionalSaude of models">
                  <td>
                    {{profissionalSaude.ordem}}
                  </td>
                  <td>
                    {{profissionalSaude.pessoaNomeFantasia}}
                  </td>
                  <td>
                    {{profissionalSaude.nomeApresentacao}}
                  </td>
                  <td>
                    {{profissionalSaude.usuarioNome}}
                  </td>
                  <td>
                    {{typesService.getBoolTypeNome(profissionalSaude.usaConfirmacaoLote)}}
                  </td>
                  <td>
                    {{profissionalSaude.especialidadeMedNome}}
                  </td>
                  <td>{{profissionalSaude.areaAtuacaoMedNomes?.join(', ')}}
                  </td>
                  <td>
                    {{typesService.getBoolTypeNome(profissionalSaude.temHotsite)}}
                  </td>
                  <td>
                    {{typesService.getBoolTypeNome(profissionalSaude.registroAtivo)}}
                  </td>
                  <td class="text-center">
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="textoPadraoModal.show(profissionalSaude.id, profissionalSaude.pessoaNomeFantasia)" matTooltip="Textos Padrões">
                      <mat-icon>sticky_note_2</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="agendaDispModal.show(profissionalSaude.profissionalRecursoId, profissionalSaude.pessoaNomeFantasia)" matTooltip="Disponibilidade da Agenda">
                      <mat-icon>alarm</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="agendaBloqueioModal.show(profissionalSaude.profissionalRecursoId, profissionalSaude.pessoaNomeFantasia)" matTooltip="Bloqueios da Agenda">
                      <mat-icon>block</mat-icon>
                    </button>
                    <!-- <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="perfilAnamneseModal.show(profissionalSaude.profissionalRecursoId, profissionalSaude.pessoaNomeFantasia)" matTooltip="Perfil de Anamnese">
                      <mat-icon>assignment</mat-icon>
                    </button>                     -->
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(profissionalSaude)"
                      matTooltip="Editar">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button"
                      (click)="delete(profissionalSaude.id)" matTooltip="Excluir">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()"
              matTooltip="Adicionar (F9)" matTooltipPosition="above">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <div class="tab-pane fade" id="nav-ordenacao" role="tabpanel" aria-labelledby="nav-ordenacao-tab" >
          <div class="body">
            <div mat-dialog-content>
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let profissionalSaude of corposClinicos" cdkDrag>
                  {{profissionalSaude.pessoa.nomeFantasia}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-profissional-saude-modal #profissionalSaudeModal (modalSave)="onResponse($event)"></app-profissional-saude-modal>
<app-agenda-disponibilidade #agendaDispModal></app-agenda-disponibilidade>
<app-agenda-bloqueio #agendaBloqueioModal></app-agenda-bloqueio>
<!-- <app-perfil-anamnese #perfilAnamneseModal></app-perfil-anamnese> -->
<app-profissional-saude-texto-padrao #textoPadraoModal></app-profissional-saude-texto-padrao>
