import { Component, OnInit, ViewChild, Injector, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PacienteExameIndicadorItemModel, PacienteExameIndicadorModel } from '../../domain/models';
import { ProcedimentoService } from '../../../atendimento/domain/services';
import { PacienteExameIndicadorItemService, PacienteExameIndicadorService } from '../../domain/services';
import { PacienteExameIndicadorItemModalComponent } from './paciente-exame-indicador-item-modal/paciente-exame-indicador-item-modal.component';

@Component({
  selector: 'app-paciente-exame-indicador-modal',
  templateUrl: './paciente-exame-indicador-modal.component.html'
})
export class PacienteExameIndicadorModalComponent extends BaseCrudModalComponent<PacienteExameIndicadorModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('itemModal') itemModal: PacienteExameIndicadorItemModalComponent;

  listaProcedimento: SelectDto[];
  listaItem: PacienteExameIndicadorItemModel[] = [];

  constructor(
    injector: Injector,
    service: PacienteExameIndicadorService,
    readonly itemService: PacienteExameIndicadorItemService,
    readonly procedimentoService: ProcedimentoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.procedimentoService.getSelectList().subscribe(x => this.listaProcedimento = x);    
  }

  protected initializeForm(model: PacienteExameIndicadorModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      pacienteId: [model.pacienteId, Validators.required],
      procedimentoId: [model.procedimentoId, Validators.required],
    });

    this.carregarListaItem(model.id);
  }

  carregarListaItem(id: string) {
    this.itemService.getByExameIndicadorId(id).subscribe(x => this.listaItem = x);
  }

  createItem() {
    this.save(true);
    
    const model = new PacienteExameIndicadorItemModel();
    model.pacienteExameIndicadorId = this.form.value.id;
    model.dataReferencia = new Date();
    this.itemModal.showCreate(model);
  }

  editItem(model: PacienteExameIndicadorItemModel) {
    this.itemModal.showEdit(model.id);
  }

  async deleteItem(id: string) {
    let result = await this.commonService.mensagemConfirmacao("Atenção","Excluir resultado?", "question");
    if (result) {
      await this.itemService.delete(id).toPromise();
      this.carregarListaItem(this.form.value.id);  
    }
  }

  onResponseItem(event) {
    this.carregarListaItem(this.form.value.id);
  }

}
