import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { ContatoStatusType } from '../domain/types';
import { ContatoSiteModel } from '../domain/models';
import { ContatoSiteService } from '../domain/services/contato-site.service';
import { ContatoSiteModalComponent } from './contato-site-modal/contato-site-modal.component';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-contato-site',
  templateUrl: './contato-site.component.html'
})
export class ContatoSiteComponent extends BaseCrudComponent<ContatoSiteModel> implements OnInit {
  @ViewChild('contatoSiteModal') modal: ContatoSiteModalComponent;

  ContatoStatusType: typeof ContatoStatusType = ContatoStatusType;

  opcoesDatas = [
    { text: 'Data do Contato', value: 1 },
    { text: 'Data do Status', value: 2 }
  ];

  filtroDataSelecionado: number;

  searchStatus = new ModelSearch('status', OptionSearchType.Equals, SearchType.Int);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  searchDataInicialContato = new ModelSearch('data', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinalContato = new ModelSearch('data', OptionSearchType.LessThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)));
  searchDataInicialStatus = new ModelSearch('dataStatus', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinalStatus = new ModelSearch('dataStatus', OptionSearchType.LessThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)));

  selectColumns = [
    { data: 'data' },
    { data: 'status' },
    { data: 'dataStatus' },
    { data: 'nome' },
    { data: 'usuarioResponsavel.nome' },
    { data: 'observacaoInterna' }
  ];

  constructor(
    injector: Injector,
    readonly contatoSiteService: ContatoSiteService,
    readonly typesService: TypesService,

  ) {
    super(injector, contatoSiteService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];

    (this.searchStatus.searchTerm) && searches.push(this.searchStatus);
    (this.search.searchTerm) && searches.push(this.search);

    if (this.filtroDataSelecionado === 1) {
      (this.searchDataInicialContato.searchTerm) && searches.push(this.searchDataInicialContato);
      (this.searchDataFinalContato.searchTerm) && searches.push(this.searchDataFinalContato);

    } else if (this.filtroDataSelecionado === 2) {
      (this.searchDataInicialStatus.searchTerm) && searches.push(this.searchDataInicialStatus);
      (this.searchDataFinalStatus.searchTerm) && searches.push(this.searchDataFinalStatus);
    }

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[0, 'desc']];
  }

  getSearchData(value: number): string {
    switch (value) {
      case 1:
        return 'Data do Contato';
      case 2:
        return 'Data do Status';
      case 3:
        return 'Nao filtrar data';
    }
  }
}
