import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'projects/ProjetoBaseAngular/app/auth.guard';

import { AdminComponent } from './admin.component';

import { TelaInicialComponent } from './tela-inicial/tela-inicial.component';
import { ParceirosComponent } from './parceiro/parceiros/parceiros.component';
import { TiposParceirosComponent } from './parceiro/tipo-parceiro/tipos-parceiros.component';
import { EstadosComponent } from 'projects/ProjetoBaseAngular/app/admin/geral/estados/estados.component';
import { CidadesComponent } from 'projects/ProjetoBaseAngular/app/admin/geral/cidades/cidades.component';
import { TenantsComponent } from 'projects/ProjetoBaseAngular/app/admin/tenants/tenants.component';
import { ImagensComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagens.component';
import { ArquivosComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/arquivos/arquivos.component';
import { ConfigsComponent } from 'projects/ProjetoBaseAngular/app/admin/geral/configs/configs.component';
import { AcoesComponent } from 'projects/ProjetoBaseAngular/app/admin/controle-acesso/acoes/acoes.component';
import { PaginasComponent } from 'projects/ProjetoBaseAngular/app/admin/controle-acesso/pagina/paginas.component';
import { GrupoUsuariosComponent } from 'projects/ProjetoBaseAngular/app/admin/controle-acesso/grupo-usuario/grupo-usuarios.component';
import { TipoServicoComponent } from './servico/tipo-servico/tipo-servico.component';
import { ServicoComponent } from './servico/servico/servico.component';
import { ConvenioComponent } from './parceiro/convenio/convenio.component';
import { SetorComponent } from './equipe/setor/setor.component';
import { EspecialidadeMedComponent } from './equipe/especialidade-med/especialidade-med.component';
import { AreaAtuacaoMedComponent } from './equipe/area-atuacao-med/area-atuacao-med.component';
import { EquipeComponent } from './equipe/equipe/equipe.component';
import { ProfissionalSaudeComponent } from './equipe/profissional-saude/profissional-saude.component';
import { ArtigoTagComponent } from './artigo/artigo-tag/artigo-tag.component';
import { ArtigoComponent } from './artigo/artigo/artigo.component';
import { MicroRegiaoComponent } from 'projects/ProjetoBaseAngular/app/admin/geral/micro-regioes/micro-regiao.component';
import { EnqueteComponent } from './enquete/enquete/enquete.component';
import { CargoComponent } from './rh/cargo/cargo.component';
import { VagaComponent } from './rh/vaga/vaga.component';
import { CurriculoComponent } from './rh/curriculo/curriculo.component';
import { PaginasWebComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/paginas-web/paginas-web.component';
import { RevistaComponent } from './revista/revista/revista.component';
import { MenusComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/menus/menus.component';
import { BannersComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/banners/banners.component';
import { TemasComponent } from './meu-site/temas/temas.component';
import { ContatoSiteComponent } from './contato/contato-site/contato-site.component';
import { SeoComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/seo/seo.component';
import { RequisicaoLgpdComponent } from './lgpd/requisicao-lgpd/requisicao-lgpd.component';
import { ContatoComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/contato/contato.component';
import { PoliticaPrivacidadeComponent } from './lgpd/politica-privacidade/politica-privacidade.component';
import { LogotipoComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/logotipo/logotipo.component';
import { PwaComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/pwa/pwa.component';
import { PushComponent } from 'projects/ProjetoBaseAngular/app/admin/notificacao/push/push.component';
import { RodapeComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/rodape/rodape.component';
import { AparenciaComponent } from './meu-site/aparencia/aparencia.component';
import { IconeComponent } from './Icone/icone/icone.component';
import { CategoriaIconeComponent } from './Icone/categoria-icone/categoria-icone.component';
import { CabecalhoComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/cabecalho/cabecalho.component';
import { ScriptsComponent } from 'projects/ProjetoBaseAngular/app/admin/meu-site/scripts/scripts.component';
import { GrupoPacienteComponent } from './paciente/grupo-paciente/grupo-paciente.component';
import { AgendaBloqueioComponent } from './atendimento/agenda-bloqueio/agenda-bloqueio.component';
import { AgendaDisponibilidadeComponent } from './atendimento/agenda-disponibilidade/agenda-disponibilidade.component';
import { SalaAtendimentoComponent } from './atendimento/sala-atendimento/sala-atendimento.component';
import { AgendamentoComponent } from './atendimento/agendamento/agendamento.component';
import { AtendimentoComponent } from './atendimento/atendimento/atendimento.component';
import { ClassificacaoAgendamentoComponent } from './atendimento/classificacao-agendamento/classificacao-agendamento.component';
import { GrupoProcedimentoComponent } from './atendimento/grupo-procedimento/grupo-procedimento.component';
import { PacienteComponent } from './paciente/paciente/paciente.component';
import { ProcedimentoComponent } from './atendimento/procedimento/procedimento.component';
import { MedicamentoComponent } from './atendimento/medicamento/medicamento.component';
import { GrupoAnamneseComponent } from './atendimento/grupo-anamnese/grupo-anamnese.component';
import { QuestAnamneseComponent } from './atendimento/quest-anamnese/quest-anamnese.component';

import { LayoutLaudoComponent } from './atendimento/layout-laudo/layout-laudo.component';
import { LaudoComponent } from './atendimento/laudo/laudo.component';
import { EquipamentoComponent } from './atendimento/equipamento/equipamento.component';
import { RelRepasseComponent } from './relatorios/rel-repasse/rel-repasse.component';
import { CapturaComponent } from './atendimento/captura/captura.component';
import { LayoutImpressaoComponent } from './atendimento/layout-impressao/layout-impressao.component';
import { LayoutMensagemComponent } from './atendimento/layout-mensagem/layout-mensagem.component';
import { ProtocoloDocComponent } from './atendimento/protocolo-doc/protocolo-doc.component';
import { EntregaExameComponent } from './atendimento/entrega-exame/entrega-exame.component';
import { RelRecebimentoConvenioComponent } from './relatorios/rel-recebimento-convenio/rel-recebimento-convenio.component';
import { UsuariosComponent } from './usuario/usuarios.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { QuestionarioComponent } from './pesquisa-adm/questionario/questionario.component';
import { DocSusConfigComponent } from './atendimento/doc-sus-config/doc-sus-config.component';
import { RelDemonstrativoPeriodoComponent } from './relatorios/rel-demonstrativo-periodo/rel-demonstrativo-periodo.component';
import { ContatoPreAgendamentoComponent } from './contato/contato-pre-agendamento/contato-pre-agendamento.component';
import { FaturamentoComponent } from './faturamento/faturamento.component';
import { TermoComponent } from './atendimento/termo/termo.component';
import { AtestadoComponent } from './modelos/atestado/atestado.component';
import { ReceitaComponent } from './modelos/receita/receita.component';
import { ExameComponent } from './modelos/exame/exame.component';
import { RelTabelaComponent } from './relatorios/rel-tabela/rel-tabela.component';
import { ConfirmacaoLoteComponent } from './atendimento/agendamento/confirmacao-lote/confirmacao-lote.component';
import { WhatsAppChatComponent } from './atendimento-online/whasapp-chat/whatsapp-chat.component';
import { ChatbotConfigComponent } from './atendimento-online/chatbot-config/chatbot-config.component';
import { RelRepasseSimplificadoComponent } from './relatorios/rel-repasse-simplificado/rel-repasse-simplificado.component';
import { RelPacientesPeriodoComponent } from './relatorios/rel-pacientes-periodo/rel-pacientes-periodo.component';
import { RelAtendimentosChatbotComponent } from './relatorios/rel-atendimentos-chatbot/rel-atendimentos-chatbot.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      // Biblioteca
      { path: '', component: TelaInicialComponent, canActivate: [AuthGuardService] },
      { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuardService] },
      { path: 'estados', component: EstadosComponent, canActivate: [AuthGuardService] },
      { path: 'cidades', component: CidadesComponent, canActivate: [AuthGuardService] },
      { path: 'microRegiao', component: MicroRegiaoComponent, canActivate: [AuthGuardService] },
      { path: 'tenants', component: TenantsComponent, canActivate: [AuthGuardService] },
      { path: 'imagens', component: ImagensComponent, canActivate: [AuthGuardService] },
      { path: 'arquivos', component: ArquivosComponent, canActivate: [AuthGuardService] },
      { path: 'configs', component: ConfigsComponent, canActivate: [AuthGuardService] },
      { path: 'acoes', component: AcoesComponent, canActivate: [AuthGuardService] },
      { path: 'paginas', component: PaginasComponent, canActivate: [AuthGuardService] },
      { path: 'grupoUsuarios', component: GrupoUsuariosComponent, canActivate: [AuthGuardService] },

      // Parceiros
      { path: 'parceiros', component: ParceirosComponent, canActivate: [AuthGuardService] },
      { path: 'tiposParceiros', component: TiposParceirosComponent, canActivate: [AuthGuardService] },
      { path: 'convenio', component: ConvenioComponent, canActivate: [AuthGuardService] },

      // Servico
      { path: 'tipo-servico', component: TipoServicoComponent, canActivate: [AuthGuardService] },
      { path: 'servico', component: ServicoComponent, canActivate: [AuthGuardService] },

      // Equipe
      { path: 'equipe', component: EquipeComponent, canActivate: [AuthGuardService] },
      { path: 'profissional-saude', component: ProfissionalSaudeComponent, canActivate: [AuthGuardService] },
      { path: 'setor', component: SetorComponent, canActivate: [AuthGuardService] },
      { path: 'especialidade-med', component: EspecialidadeMedComponent, canActivate: [AuthGuardService] },
      { path: 'area-atuacao-med', component: AreaAtuacaoMedComponent, canActivate: [AuthGuardService] },

      // Artigo
      { path: 'artigo', component: ArtigoComponent, canActivate: [AuthGuardService] },
      { path: 'artigo-tag', component: ArtigoTagComponent, canActivate: [AuthGuardService] },

      // Enquete
      { path: 'enquete', component: EnqueteComponent, canActivate: [AuthGuardService] },

      // RH
      { path: 'cargo', component: CargoComponent, canActivate: [AuthGuardService] },
      { path: 'vaga', component: VagaComponent, canActivate: [AuthGuardService] },
      { path: 'curriculo', component: CurriculoComponent, canActivate: [AuthGuardService] },

      // Revista
      { path: 'revista', component: RevistaComponent, canActivate: [AuthGuardService] },

      // Site
      { path: 'meu-site/paginas-web', component: PaginasWebComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/menus', component: MenusComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/banners', component: BannersComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/temas', component: TemasComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/aparencia', component: AparenciaComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/seo', component: SeoComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/contato', component: ContatoComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/logotipo', component: LogotipoComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/pwa', component: PwaComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/rodape', component: RodapeComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/cabecalho', component: CabecalhoComponent, canActivate: [AuthGuardService] },
      { path: 'meu-site/scripts', component: ScriptsComponent, canActivate: [AuthGuardService] },

      // Contato
      { path: 'contato-site', component: ContatoSiteComponent, canActivate: [AuthGuardService] },
      { path: 'contato-pre-agendamento', component: ContatoPreAgendamentoComponent, canActivate: [AuthGuardService] },

      // Lgpd
      { path: 'requisicao-lgpd', component: RequisicaoLgpdComponent, canActivate: [AuthGuardService] },

      // Politica de Privacidade
      { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent, canActivate: [AuthGuardService] },

      // Notificação Push
      { path: 'push', component: PushComponent, canActivate: [AuthGuardService] },

      // Icone
      { path: 'icone', component: IconeComponent, canActivate: [AuthGuardService] },
      { path: 'categoria-icone', component: CategoriaIconeComponent, canActivate: [AuthGuardService] },

      // Pesquisa
      { path: 'questionario', component: QuestionarioComponent, canActivate: [AuthGuardService] },

      // Paciente
      { path: 'grupo-paciente', component: GrupoPacienteComponent, canActivate: [AuthGuardService] },
      { path: 'paciente', component: PacienteComponent, canActivate: [AuthGuardService] },

      // Atendimento
      { path: 'agenda-bloqueio', component: AgendaBloqueioComponent, canActivate: [AuthGuardService] },
      { path: 'agenda-disponibilidade', component: AgendaDisponibilidadeComponent, canActivate: [AuthGuardService] },
      { path: 'sala-atendimento', component: SalaAtendimentoComponent, canActivate: [AuthGuardService] },
      { path: 'agendamento', component: AgendamentoComponent, canActivate: [AuthGuardService] },
      { path: 'agendamento-confirmacaolote', component: ConfirmacaoLoteComponent, canActivate: [AuthGuardService] },
      { path: 'atendimento', component: AtendimentoComponent, canActivate: [AuthGuardService] },
      { path: 'classificacao-agendamento', component: ClassificacaoAgendamentoComponent, canActivate: [AuthGuardService] },
      { path: 'grupo-procedimento', component: GrupoProcedimentoComponent, canActivate: [AuthGuardService] },
      { path: 'procedimento', component: ProcedimentoComponent, canActivate: [AuthGuardService] },
      { path: 'medicamento', component: MedicamentoComponent, canActivate: [AuthGuardService] },
      { path: 'grupo-anamnese', component: GrupoAnamneseComponent, canActivate: [AuthGuardService] },
      { path: 'quest-anamnese', component: QuestAnamneseComponent, canActivate: [AuthGuardService] },
      { path: 'layout-laudo', component: LayoutLaudoComponent, canActivate: [AuthGuardService] },
      { path: 'layout-impressao', component: LayoutImpressaoComponent, canActivate: [AuthGuardService] },
      { path: 'laudo', component: LaudoComponent, canActivate: [AuthGuardService] },
      { path: 'captura', component: CapturaComponent, canActivate: [AuthGuardService] },
      { path: 'equipamento', component: EquipamentoComponent, canActivate: [AuthGuardService] },
      { path: 'layout-mensagem', component: LayoutMensagemComponent, canActivate: [AuthGuardService] },      
      { path: 'protocolo-doc', component: ProtocoloDocComponent, canActivate: [AuthGuardService] },
      { path: 'entrega-exame', component: EntregaExameComponent, canActivate: [AuthGuardService] },
      { path: 'doc-sus-config', component: DocSusConfigComponent, canActivate: [AuthGuardService] },
      { path: 'termo', component: TermoComponent, canActivate: [AuthGuardService] },

      //Modelos
      { path: 'atestado', component: AtestadoComponent, canActivate: [AuthGuardService] },
      { path: 'receita', component: ReceitaComponent, canActivate: [AuthGuardService] },
      { path: 'exame', component: ExameComponent, canActivate: [AuthGuardService] },

      // AtendimentoOnLine
      { path: 'chatbot-config', component: ChatbotConfigComponent, canActivate: [AuthGuardService] },
      { path: 'whatsapp-chat', component: WhatsAppChatComponent, canActivate: [AuthGuardService] },
      { path: 'whatsapp-dashboard', component: RelAtendimentosChatbotComponent, canActivate: [AuthGuardService] },

      //Relatórios
      { path: 'rel-repasse-med', component: RelRepasseComponent, canActivate: [AuthGuardService] },
      { path: 'rel-repasse-med-simplificado', component: RelRepasseSimplificadoComponent, canActivate: [AuthGuardService] },
      { path: 'rel-rec-convenio', component: RelRecebimentoConvenioComponent, canActivate: [AuthGuardService] },
      { path: 'rel-demonstrativo-per', component: RelDemonstrativoPeriodoComponent, canActivate: [AuthGuardService] },
      { path: 'rel-tabelas-preco', component: RelTabelaComponent, canActivate: [AuthGuardService] },
      { path: 'rel-paciente-periodo', component: RelPacientesPeriodoComponent, canActivate: [AuthGuardService] },

      { path: 'faturamento', component: FaturamentoComponent, canActivate: [AuthGuardService] },

      //Empresa
      { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuardService] },
    ]
  },
];

export const AdminRoutes = RouterModule.forChild(routes);
