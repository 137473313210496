import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncateStrPipe } from './truncate-str-pipe';
import { CpfPipe } from './cpf-pipe';



@NgModule({
  imports: [CommonModule],
  declarations: [
    TruncateStrPipe,
    CpfPipe
  ],
  exports: [
    TruncateStrPipe,
    CpfPipe
  ]
})
export class KleincodePipesModule { }