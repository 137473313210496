<div class="row clearfix">
  <div class="col-md-12">
      <div class="card">
          <div class="header">
              <h2>Micro Regiões</h2>
          </div>
          <div class="body table-responsive">
              <!--query end-->
              <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover" style="width: 100%;">
                <thead>
                  <tr>
                    <th class="text-center" width="250">Código IBGE</th>
                    <th class="text-center">Nome</th>
                    <th class="text-center" width="150"></th>
                  </tr>
                </thead>
                <tbody *ngIf="models?.length != 0">
                  <tr *ngFor="let microRegiao of models">
                    <td>
                      {{microRegiao.codigoIbge}}
                    </td>
                    <td>
                      {{microRegiao.nome}}
                    </td>
                    <td class="text-center">
                      <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(microRegiao)" matTooltip="Editar">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(microRegiao.id)" matTooltip="Excluir">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
                <mat-icon>add</mat-icon>
            </button>
        </div>
      </div>
  </div>
</div>
<app-micro-regiao-modal #microRegiaoModal (modalSave)="onResponse($event)"></app-micro-regiao-modal>
