import { Component, OnInit, ViewChild, Injector, ElementRef } from '@angular/core';
import { ArtigoModel, ArtigoLocalPublicacaoModel, LocaisPublicacao, ArtigoImagemModel } from '../../domain/models';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ArtigoService, ArtigoTagService } from '../../domain/services';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { LocalPublicacaoType } from 'projects/admin/src/domain/types';
import { ProfissionalSaudeService } from 'projects/admin/src/app/admin/equipe/domain/services';
import { ProfissionalSaudeModel } from 'projects/admin/src/app/admin/equipe/domain/models';

@Component({
  selector: 'app-artigo-modal',
  templateUrl: './artigo-modal.component.html'
})
export class ArtigoModalComponent extends BaseCrudModalComponent<ArtigoModel> implements OnInit {
  @ViewChild('artigoModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;
  @ViewChild('chipList') chipList: ElementRef<HTMLInputElement>;

  allTags: string[];
  artigoLocaisPublicacao: ArtigoLocalPublicacaoModel[];

  locaisDisponiveis: LocaisPublicacao[] = [
    { text: 'Notícia', value: LocalPublicacaoType.Noticia.toString() },
    { text: 'Responsabilidade Social', value: LocalPublicacaoType.ResponsabilidadeSocial.toString() },
    { text: 'Blog', value: LocalPublicacaoType.Blog.toString() }
  ];

  medicos: ProfissionalSaudeModel[];

  private imageSelectedId;

  constructor(
    injector: Injector,
    artigoService: ArtigoService,
    private artigoTagService: ArtigoTagService,

    // pegar a lista de medicos que tem hot site
    private profissionalSaudeService: ProfissionalSaudeService
  ) {
    super(injector, artigoService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.popularHotSites();
    this.artigoTagService.get().subscribe(artigoTags => this.allTags = artigoTags.map(x => x.nome));
  }

  protected initializeForm(artigo: ArtigoModel) {

    const locaisSelecionados = this.getLocaisSelecionados(artigo);
    const imagens: FormGroup[] = [];
    artigo.imagens = artigo.imagens.sort(x => x.ordem);
    artigo.imagens.forEach(imagem => {
      imagens.push(this.createImagem(imagem));
    });

    const tagsSelecionadas: { value: string }[] = [];
    if (artigo.tagNomes) {
      artigo.tagNomes.forEach(tag => tagsSelecionadas.push({ value: tag }))
    }

    this.form = this.formBuilder.group({
      id: artigo.id,
      dataInclusao: [artigo.dataInclusao, Validators.required],
      dataAlteracao: artigo.dataAlteracao,
      slug: [artigo.slug, [
        Validators.maxLength(50)]
      ],
      titulo: [artigo.titulo, [
        Validators.required,
        Validators.maxLength(100)
      ]],
      autor: [artigo.autor, [
        Validators.required,
        Validators.maxLength(50)
      ]],
      resumo: [artigo.resumo, [
        Validators.required,
        Validators.maxLength(200)
      ]],
      dataArtigo: [this.fromJsonDate(artigo.dataArtigo), [
        Validators.required
      ]],
      publicado: [artigo.publicado],

      tagNomes: [tagsSelecionadas],
      html: [artigo.html],
      imagens: this.formBuilder.array(imagens),
      legendaImagens: [artigo.legendaImagens],
      locaisSelecionados: [locaisSelecionados]
    });
  }

  beforeShowCreate(model: ArtigoModel): ArtigoModel {
    model.publicado = false;
    model.imagens = [];
    return super.beforeShowCreate(model);
  }

  createImagem(imagem: ArtigoImagemModel): FormGroup {
    return this.formBuilder.group({
      id: imagem.id,
      dataInclusao: [imagem.dataInclusao, Validators.required],
      dataAlteracao: imagem.dataAlteracao,
      imagemUrl: [imagem.imagemUrl],
      imagemUrlBase: [imagem.imagemUrlBase],
      imagemPath: [imagem.imagemPath],
      ordem: imagem.ordem
    });
  }

  // popular a lista de locaisDisponiveis com a lista de hotSites que possuem medicos
  private popularHotSites() {
    this.profissionalSaudeService.getAll().subscribe(medicos => {
      this.medicos = medicos;
      this.medicos.forEach(medico => {
        if (medico.temHotsite === true) {
          const localDisponivel = {
            text: 'HotSite: ' + medico.pessoa.nomeFantasia,
            value: medico.id
          };
          this.locaisDisponiveis.push(localDisponivel);
        }
      });
    });
  }

  beforeSave(model: ArtigoModel): ArtigoModel {
    model.locaisPublicacao = [];
    model.tagNomes = model.tagNomes.map(x => x.value);
    this.form.value.locaisSelecionados.forEach(localSelecionado => {
      const artigoLocalPublicacao = new ArtigoLocalPublicacaoModel();
      switch (localSelecionado) {
        case LocalPublicacaoType.Noticia.toString():
          artigoLocalPublicacao.localPublicacao = LocalPublicacaoType.Noticia;
          break;
        case LocalPublicacaoType.ResponsabilidadeSocial.toString():
          artigoLocalPublicacao.localPublicacao = LocalPublicacaoType.ResponsabilidadeSocial;
          break;
        case LocalPublicacaoType.Blog.toString():
          artigoLocalPublicacao.localPublicacao = LocalPublicacaoType.Blog;
          break;
        default:
          artigoLocalPublicacao.localPublicacao = LocalPublicacaoType.HotSite;
          artigoLocalPublicacao.profissionalSaudeId = localSelecionado;
          break;
      }
      model.locaisPublicacao.push(artigoLocalPublicacao);
    });
    return model;
  }

  getLocaisSelecionados(model: ArtigoModel): string[] {
    const locaisSelecionados: string[] = [];
    if (model.locaisPublicacao) {
      model.locaisPublicacao.forEach(localSelecionado => {
        switch (localSelecionado.localPublicacao) {
          case LocalPublicacaoType.Noticia:
            locaisSelecionados.push(LocalPublicacaoType.Noticia.toString());
            break;
          case LocalPublicacaoType.ResponsabilidadeSocial:
            locaisSelecionados.push(LocalPublicacaoType.ResponsabilidadeSocial.toString());
            break;
          case LocalPublicacaoType.Blog:
            locaisSelecionados.push(LocalPublicacaoType.Blog.toString());
            break;
          default:
            locaisSelecionados.push(localSelecionado.profissionalSaudeId);
            break;
        }
      });
    }
    return locaisSelecionados;
  }

  showSelectImage(imagemId: string) {
    this.imageSelectedId = imagemId;
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {

    let artigoImagem = new ArtigoImagemModel();

    if (this.imageSelectedId) {

      artigoImagem = this.form.value.imagens.find(x => x.id === this.imageSelectedId);
      artigoImagem.imagemUrl = imagem.url;
      artigoImagem.imagemUrlBase = imagem.urlBase;
      artigoImagem.imagemPath = imagem.path;
      
      artigoImagem.dataAlteracao = new Date();
    } else {
      artigoImagem.imagemUrl = imagem.url;
      artigoImagem.imagemUrlBase = imagem.urlBase;
      artigoImagem.imagemPath = imagem.path;
      
      artigoImagem.id = this.commonService.newGuid();
      artigoImagem.dataInclusao = new Date();

      if (this.imagens.length > 0) {
        artigoImagem.ordem = Math.max(...this.imagens.map(x => x.ordem)) + 1;
      } else {
        artigoImagem.ordem = 0;
      }

      (this.form.get('imagens') as FormArray).push(this.createImagem(artigoImagem));
    }
  }

  deselectImage(imagemId: string) {
    const index = this.form.value.imagens.findIndex(x => x.id === imagemId);    
    (this.form.get('imagens') as FormArray).removeAt(index);
  }

  moveImagemDireita(imagemId: string, index: number) {
    const artigoImagemMovida = this.imagens.find(x => x.id === imagemId);
    const indexImagemMovida = this.imagens.findIndex(x => x.id === imagemId);
    const artigoImagemDireita = this.imagens[indexImagemMovida + 1];    

    this.imagens[index] = artigoImagemDireita;
    this.imagens[index + 1] = artigoImagemMovida;

    for (let i = 0; i < this.imagens.length; i++) {
      this.imagens[i].ordem = i;
    }
  }

  moveImagemEsquerda(imagemId: string, index: number) {
    const artigoImagemMovida = this.imagens.find(x => x.id === imagemId);
    const indexImagemMovida = this.imagens.findIndex(x => x.id === imagemId);
    const artigoImagemEsquerda = this.imagens[indexImagemMovida - 1];

    this.imagens[index] = artigoImagemEsquerda;
    this.imagens[index - 1] = artigoImagemMovida;

    for (let i = 0; i < this.imagens.length; i++) {
      this.imagens[i].ordem = i;
    }
  }

  get imagemCapa(): ArtigoImagemModel {
    return this.imagens[0];
  }

  get imagens(): ArtigoImagemModel[] {
    return this.form.value.imagens;
  }
}
