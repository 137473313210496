import { OnInit, Injector, ViewChild, Output, EventEmitter, Directive } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

import { BaseEntidadePessoasComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoas.component';
import { PessoaType } from 'projects/ProjetoBaseAngular/domain/types';
import { BaseEntidadePessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';

@Directive()
export abstract class BaseEntidadePessoaSelectModalComponent<TEntidadePessoaModel extends BaseEntidadePessoaModel> extends BaseEntidadePessoasComponent<TEntidadePessoaModel> implements OnInit {
  @ViewChild('selectModal', { static: false }) selectModal: ModalDirective;
  @Output() modalClose: EventEmitter<string> = new EventEmitter<string>();

  readonly pessoaJuridica = PessoaType.Juridica;
  readonly pessoaFisica = PessoaType.Fisica;

  constructor(
    injector: Injector,
    entidadePessoaService: BaseEntidadePessoaService<TEntidadePessoaModel>
  ) {
    super(injector, entidadePessoaService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  changeDocumento(cnpjCpf: string) {
    if (cnpjCpf) {
      if (!this.commonService.isNumeric(this.commonService.removeSpecial(cnpjCpf))) {
        this.search.searchTerm = cnpjCpf;
        this.selectModal.show();
        this.filter();
        return;
      }

      cnpjCpf = cnpjCpf.replace(/[^0-9]/g, '');
      if (cnpjCpf.length >= 11) {
        this.entidadePessoaService
          .getByCnpjCpf(cnpjCpf)
          .subscribe(entidadePessoa => {
            if (entidadePessoa.id) {
              this.modalClose.emit(entidadePessoa.id);
            } else {
              this.modalClose.emit(null);              
              const entidadePessoa = new BaseEntidadePessoaModel() as TEntidadePessoaModel;              
              if (cnpjCpf.length === 14) {
                entidadePessoa.pessoa.tipoPessoa = PessoaType.Juridica;
                entidadePessoa.pessoa.cnpjCpf = this.commonService.formatCnpj(cnpjCpf);
              } else {
                entidadePessoa.pessoa.tipoPessoa = PessoaType.Fisica;
                entidadePessoa.pessoa.cnpjCpf = this.commonService.formatCpf(cnpjCpf);
              }
              Swal.fire({
                title: 'Cadastro não encontrado!',
                html: `Documento ${entidadePessoa.pessoa.cnpjCpf} não encontrado na base de dados! Deseja cadastrá-lo agora?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
              }).then((result) => {
                if (result.value) {
                  this.modal.showCreate(entidadePessoa);
                }
              });
            }
          });
      } else if (cnpjCpf) {
        this.entidadePessoaService
          .getByCodigo(parseInt(cnpjCpf))
          .subscribe(entidadePessoa => {
            if (entidadePessoa.id) {
              this.modalClose.emit(entidadePessoa.id);
            } else {
              this.modalClose.emit(null);
              this.commonService.mensagem('Cadastro não encontrado!', '', 'warning');                
            }
          });
      }
    } else {
      this.modalClose.emit(null);
    }
  }

  showSelect() {    
    this.searchDataInicial.searchTerm = '';
    this.searchDataFinal.searchTerm = '';
    this.searchDataNascimento.searchTerm = '';
    this.searchEstado.searchTerm = '';
    this.searchCidade.searchTerm = '';
    this.search.searchTerm = '';
    this.models = [];
    this.selectModal.show();
  }

  select(id: string) {
    this.modalClose.emit(id);
    this.selectModal.hide();
  }

  close() {
    this.selectModal.hide();
  }

  onResponse(id: string) {
    if (id) {
      this.select(id);
      return;
    }
    super.onResponse(id);
  }
}
