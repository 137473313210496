import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services/types.service';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { AgendamentoLogModel } from '../../domain/models';
import { AgendamentoLogService } from '../../domain/services';

@Component({
  selector: 'app-agendamento-log',
  templateUrl: './agendamento-log.component.html'
})
export class AgendamentoLogComponent extends BaseCrudComponent<AgendamentoLogModel> implements OnInit {
  @ViewChild('modal') modal = null;
  @ViewChild('listModal') listModal: ModalDirective;

  agendamentoId: string;

  constructor(
    injector: Injector,
    readonly service: AgendamentoLogService,
    readonly typesService: TypesService,
  ) {
    super(injector, service);
  }

  selectColumns = [
    { data: 'dataInclusao' },
    { data: 'tipo' },
    { data: 'terminal' },
    { data: 'usuario.nome' },
    { data: 'log' },
  ];

  ngOnInit() {
    super.ngOnInit();  
    this.dtOptions.order = [[0, 'desc']];   
    this.dtOptions.pageLength = 5;
    this.dtOptions.lengthMenu = [[5],[5]];
    //this.dtOptions.scrollY = "200px"; resolve o scroll mas buga o cabeçalho
  }

  show(agendamentoId) {
    this.agendamentoId = agendamentoId;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();    
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.agendamentoId) {
      dataTablesParameters.searches.push(new ModelSearch('agendamentoId', OptionSearchType.Equals, SearchType.Guid, this.agendamentoId));
    }
    return super.beforeDtRequest(dataTablesParameters);
  }
}
