import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { TypesService } from 'projects/admin/src/domain/services';
import { PesquisaStatusCorType, QuestionarioPosicaoCardType, StatusPublicacaoType } from 'projects/admin/src/domain/types';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { QuestionarioModel } from '../../domain/models';
import { QuestionarioService } from '../../domain/services';
import { GrupoQuestaoComponent } from '../../grupo-questao/grupo-questao.component';
import { QuestionarioFiltroComponent } from '../../questionario-filtro/questionario-filtro.component';

@Component({
  selector: 'app-questionario-modal',
  templateUrl: './questionario-modal.component.html'
})

export class QuestionarioModalComponent extends BaseCrudModalComponent<QuestionarioModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('grupoQuestao') grupoQuestao: GrupoQuestaoComponent;
  @ViewChild('questionarioFiltro') questionarioFiltro: QuestionarioFiltroComponent;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  statusCorType: typeof PesquisaStatusCorType = PesquisaStatusCorType;

  private imagemSelecionada: number;

  constructor(
    injector: Injector,
    readonly service: QuestionarioService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }
  
  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: QuestionarioModel) {
    this.form = this.formBuilder.group({
      id: [model.id],
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: [model.dataAlteracao],
      
      titulo: [model.titulo, [ Validators.required, Validators.maxLength(100)]],
      statusPublicacao: [model.statusPublicacao],
      descricaoExterna: [model.descricaoExterna, Validators.maxLength(100)],
      descricaoInterna: [model.descricaoInterna, Validators.maxLength(100)],
      questaoFiltro: [model.questaoFiltro, Validators.maxLength(100)],
      pontuacaoVerde: [model.pontuacaoVerde, Validators.required],
      nomeVerde: [model.nomeVerde, Validators.maxLength(10)],
      pontuacaoLaranja: [model.pontuacaoLaranja, Validators.required],
      nomeLaranja: [model.nomeLaranja, Validators.maxLength(10)],
      pontuacaoVermelha: [model.pontuacaoVermelha, Validators.required],
      nomeVermelha: [model.nomeVermelha, Validators.maxLength(10)],
      codigoResposta: [model.codigoResposta],
      imagem1Url: [model.imagem1Url],
      imagem1UrlBase: [model.imagem1UrlBase],
      imagem1Path: [model.imagem1Path],
      imagem2Url: [model.imagem2Url],
      imagem2UrlBase: [model.imagem2UrlBase],
      imagem2Path: [model.imagem2Path],
      posicaoCard: [model.posicaoCard]
    });
  };

  protected newModel(): QuestionarioModel {
    let model = new QuestionarioModel();
    model.statusPublicacao = StatusPublicacaoType.Rascunho;
    model.pontuacaoVerde = 0;
    model.pontuacaoLaranja= 0;
    model.pontuacaoVermelha = 0;
    model.nomeVerde = "Ótimo";
    model.nomeLaranja = "Bom";
    model.nomeVermelha = "Ruim";
    model.codigoResposta = 0;
    model.posicaoCard = QuestionarioPosicaoCardType.Centro;

    return model;
  };  

  showSelectImage(imagemNum: number) {
    this.imagemSelecionada = imagemNum;
    this.imagemSelectModal.showSelect(800);
  }

  deselectImage(imagemNum: number) {
    if (imagemNum === 1) {
      this.form.patchValue({
        imagem1Url: "",
        imagem1UrlBase: "",
        imagem1Path: ""
      });
    } else {
      this.form.patchValue({
        imagem2Url: "",
        imagem2UrlBase: "",
        imagem2Path: ""
      });
    }
  }

  onResponseImagem(imagem: ImagemModel) {
    if (this.imagemSelecionada === 1) {
      this.form.patchValue({
        imagem1Url: imagem.url,
        imagem1UrlBase: imagem.urlBase,
        imagem1Path: imagem.path
      });
    } else {
      this.form.patchValue({
        imagem2Url: imagem.url,
        imagem2UrlBase: imagem.urlBase,
        imagem2Path: imagem.path
      });
    }
  }

}

