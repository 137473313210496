import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { EstadoModel } from 'projects/ProjetoBaseAngular/domain/models';
import { EstadoService } from 'projects/ProjetoBaseAngular/domain/services';
import { EstadoModalComponent } from './estado-modal/estado-modal.component';

@Component({
  selector: 'app-admin-estado',
  templateUrl: './estados.component.html'
})
export class EstadosComponent extends BaseCrudComponent<EstadoModel> implements OnInit {
  @ViewChild('estadoModal') modal: EstadoModalComponent;

  selectColumns = [
    { data: 'codigoIbge' }, 
    { data: 'uf' }, 
    { data: 'nome' }
  ];

  constructor(
    injector: Injector,
    readonly estadoService: EstadoService
  ) {
    super(injector, estadoService);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
