<div bsModal #usuarioModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="usuarioModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Usuário</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-5">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" #focus />
                <mat-hint align="end">Máx 50 caracteres</mat-hint>
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-5">
              <mat-form-field>
                <mat-label>Sobrenome</mat-label>
                <input matInput formControlName="sobrenome" type="text" />
                <mat-hint align="end">Máx 50 caracteres</mat-hint>
                <mat-error>Informe um Sobrenome válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="ativo">
                  <mat-option [value]="true">Ativo</mat-option>
                  <mat-option [value]="false">Inativo</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="!isKleinCode">
              <mat-form-field>
                <mat-label>Grupo Usuário</mat-label>
                <mat-select formControlName="grupoUsuarioId">
                  <mat-option value="">-- Selecione uma opção --</mat-option>
                  <mat-option *ngFor="let grupoUsuario of gruposUsuario" [value]="grupoUsuario.id">{{grupoUsuario.nome}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Grupo.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Profissional da Saúde/Equipamento</mat-label>
                <mat-select formControlName="profissionalRecursoIds" multiple>
                  <mat-option *ngFor="let recurso of recursos" [value]="recurso.value">
                    {{recurso.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional da Saúde/Equipamento.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Usuário</mat-label>
                <input matInput formControlName="userNameTenant" type="text" />
                <mat-hint align="end">Máx 256 caracteres</mat-hint>
                <mat-error>Informe um Nome de Usuário válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com" />
                <mat-hint align="end">Máx 256 caracteres</mat-hint>
                <mat-error>Informe um E-mail válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="newItem">
              <mat-form-field>
                <mat-label>Senha</mat-label>
                <input matInput formControlName="password" type="password" required />
                <mat-error>Informe uma Senha válida.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="newItem">
              <mat-form-field>
                <mat-label>Confirmar Senha</mat-label>
                <input matInput formControlName="confirmPassword" type="password" required />
                <mat-error>Informe uma Senha válida.</mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="footer">
          <button mat-flat-button  [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
            <span> Salvar</span>
          </button>
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Cancelar</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
