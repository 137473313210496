import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { CapturaModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CapturaService extends BaseCrudService<CapturaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Captura';
  }
}
