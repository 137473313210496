<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Histórico de Receitas</h2>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="listaReceitaDto?.length > 0">
          <div [ngClass]="{'col-md-6': listaReceitaDto.length > 2, 'col-md-5': listaReceitaDto.length <= 2}"
            *ngFor="let receita of listaReceitaDto">
            <div class="card">
              <div class="header" [ngClass]="'bg-' + typesService.getReceitaTypeColor(receita.tipo)">
                <h2>{{receita.dataInclusao | date: 'dd/MM/yyyy'}} - {{typesService.getReceitaType(receita.tipo)}}
                  ({{receita.profissionalSaudeNome}})
                </h2>
              </div>
              <div class="body" style="height: 100px; overflow-y: auto; padding: 0px; padding-left: 5px;" [innerHTML]="sanitizer.bypassSecurityTrustHtml(receita.descricaoHtml)">
              </div>
              <div class="footer">
                <div class="row">
                  <div class="col-sm-4 text-left">
                    <mat-slide-toggle #mostrarData color="primary" [checked]="true">
                      Mostrar Data
                    </mat-slide-toggle>
                  </div>
                  <div class="col-sm-8">
                    <button mat-icon-button type="button"                      
                      (click)="receitaPrint.print(receita, mostrarData.checked)"
                      matTooltip="Imprimir Receita">
                      <mat-icon>print</mat-icon>
                    </button>
                    <button [disabled]="!profissionalId" mat-icon-button type="button" 
                      (click)="clonar(receita)"
                      matTooltip="Clonar Receita">
                      <mat-icon>note_add</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!(listaReceitaDto?.length > 0)">
          <h4 class="text-center">Não foram encontrados registros históricos de receita.</h4>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-atendimento-receita-print #receitaPrint>
</app-atendimento-receita-print>