import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ContaComponent } from './conta.component';
import { ContaModalComponent } from './conta-modal/conta-modal.component';
import { ContaItemModalComponent } from './conta-item-modal/conta-item-modal.component';
import { ContaTransfModalComponent } from './conta-transf-modal/conta-transf-modal.component';
import { ContaExtratoModalComponent } from './conta-extrato-modal/conta-extrato-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ContaComponent,
    ContaModalComponent, 
    ContaItemModalComponent,
    ContaTransfModalComponent,
    ContaExtratoModalComponent
  ]
})
export class ContaModule { }
