import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { AgendamentoFilterDto, AgendamentoModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService extends BaseCrudService<AgendamentoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Agendamento';
  }

  public getFilter(agendamentoFilterDto: AgendamentoFilterDto): Observable<any[]> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Filter`, JSON.stringify(agendamentoFilterDto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public getDtoById(id: string): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetDtoById/${id}`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public confirmarPresenca(id: string): Observable<any[]> {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/ConfirmarPresenca/${id}`, null, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public cancelarPresenca(id: string, motivo: string): Observable<any[]> {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/CancelarPresenca/${id}`, JSON.stringify(motivo), this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public getAnotacoes(): Observable<string> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/Anotacoes`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public saveAnotacoes(anotacoes: string): Observable<any[]> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Anotacoes`, JSON.stringify(anotacoes), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public cancel(agendamentoId, motivo: string): Observable<any[]> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Cancel/${agendamentoId}`, JSON.stringify(motivo), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public atenderManual(model:AgendamentoModel): Observable<AgendamentoModel> {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/AtenderManual`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  public desfazAtendidoManual(model:AgendamentoModel): Observable<AgendamentoModel> {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/DesfazAtendidoManual`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}