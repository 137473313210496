import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ListaConfPresencaFilaDto, WhatsAppUserInfoDto, WhatsChatFiltroDto, WhatsChatModel } from "../models";
import { BaseService } from "projects/ProjetoBaseAngular/domain/services/base.service";
import { environment } from "environments/environment";
import { WaUserAvatarResultDto, WaUserCheckResultDto, WaUserInfoResultDto } from "projects/ProjetoBaseAngular/domain/models/whatsAppApi/models";

@Injectable({
  providedIn: 'root'
})
export class WhatsChatService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'WhatsChat';
  }

  getHubUrl(): string {
    return `${environment.baseUrl}/hubs/whatsChatHub?tenant=${localStorage.getItem('app.tenant')}`;
  }

  finalizarByAgendamentoId(agendamentoId: string): Observable<any> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/FinalizarByAgendamentoId/${agendamentoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  criarChatConfPresenca(agendamentoId: string, telefone: string, texto: string): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/CriarChatConfPresenca/${agendamentoId}/${telefone}`, JSON.stringify(texto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  criarFilaConfPresenca(listaDto: ListaConfPresencaFilaDto): Observable<boolean> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/CriarFilaConfPresenca`, JSON.stringify(listaDto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  enviarConfirmacaoFila(numeroWhats: string): Observable<any> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/EnviarConfirmacaoFila/${numeroWhats}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  validarFilaConfByNumeroWhats(numeroWhats: string): Observable<any> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/ValidarFilaConfByNumeroWhats/${numeroWhats}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  criarChatAceiteTermo(termoPacienteId: string, telefone: string, base64: string, texto: string, caption: string): Observable<boolean> {
    const requestBody = {
      pacienteTermoId: termoPacienteId,
      telefone: telefone,
      base64: base64,
      texto: texto,
      caption: caption
    };

    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/CriarChatAceiteTermo`, JSON.stringify(requestBody), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getUsersCheck(arrFones: string[]): Observable<WaUserCheckResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/GetUsersCheck`, JSON.stringify(arrFones), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getUsersInfo(arrFones: string[]): Observable<WaUserInfoResultDto> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/GetUsersInfo`, JSON.stringify(arrFones), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  getUserAvatar(idWhats: string): Observable<WaUserAvatarResultDto> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetUserAvatar/${idWhats}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByFiltro(dto: WhatsChatFiltroDto): Observable<WhatsChatModel[]> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/getByFiltro`, JSON.stringify(dto), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}