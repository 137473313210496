import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { DocSusService, ProcedimentoService } from '../../domain/services';
import { DocSusModel, DocSusProcedimentoModel, ProcedimentoModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { ProfissionalSaudeService } from '../../../equipe/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { CaracterAtendimentoType, DocSusType } from '../../domain/types';

@Component({
  selector: 'app-doc-sus-modal',
  templateUrl: './doc-sus-modal.component.html'
})
export class DocSusModalComponent extends BaseCrudModalComponent<DocSusModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  profissionais: SelectDto[];
  procedimentos: DocSusProcedimentoModel[] = [];
  procedimentoIndex: number;

  constructor(
    injector: Injector,
    service: DocSusService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly procedimentoService: ProcedimentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.profissionalSaudeService.getSelectList().subscribe(x => this.profissionais = x);
  }

  protected initializeForm(model: DocSusModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      dataSolicitacao: [this.fromJsonDate(model.dataSolicitacao), [
        Validators.required
      ]],
      tipo: [model.tipo, [
        Validators.required
      ]],
      pacienteId: [model.pacienteId, [
        Validators.required
      ]],
      profissionalSolicitanteId: [model.profissionalSolicitanteId, [
        Validators.required
      ]],
      profissionalAutorizadorId: [model.profissionalAutorizadorId],
      descricaoDiagnostico: [model.descricaoDiagnostico, [
        Validators.maxLength(100)
      ]],
      cid10Principal: [model.cid10Principal, [
        Validators.maxLength(20)
      ]],
      cid10Secundario: [model.cid10Secundario, [
        Validators.maxLength(20)
      ]],
      cid10CausasAssociada: [model.cid10CausasAssociada, [
        Validators.maxLength(20)
      ]],
      resumoAnamnese: [model.resumoAnamnese],
      justifProcedimento: [model.justifProcedimento, [
        Validators.maxLength(500)
      ]],
      caracterAtendimento: [model.caracterAtendimento, [

      ]],
      diagnosticoInicial: [model.diagnosticoInicial, [
        Validators.maxLength(500)
      ]],
      historicoDoenca: [model.historicoDoenca, [
        Validators.maxLength(500)
      ]],
      exameFisico: [model.exameFisico, [
        Validators.maxLength(200)
      ]],
      diagnosticoProvavel: [model.diagnosticoProvavel, [
        Validators.maxLength(200)
      ]],
      examesComplementares: [model.examesComplementares, [
        Validators.maxLength(200)
      ]],
      tratamentoRealizado: [model.tratamentoRealizado, [
        Validators.maxLength(100)
      ]],
      tratamentoSolicitado: [model.tratamentoSolicitado, [
        Validators.maxLength(100)
      ]],
      justifTratamento: [model.justifTratamento, [
        Validators.maxLength(400)
      ]],
      justifAcompanante: [model.justifAcompanante, [
        Validators.maxLength(400)
      ]],
      transpRodoviario: [model.transpRodoviario, [

      ]],
      transpAereo: [model.transpAereo, [

      ]],
      transpAmbulancia: [model.transpAmbulancia, [

      ]],
      justifTransp: [model.justifTransp, [
        Validators.maxLength(400)
      ]]
    });

    this.procedimentos = model.procedimentos || [];
    this.procedimentos.forEach(docSusProcedimento => {
      if (docSusProcedimento.procedimentoId) {
        this.procedimentoService.getById(docSusProcedimento.procedimentoId).subscribe(procedimento => {
          docSusProcedimento.procedimentoCodigo = procedimento.codigo;
          docSusProcedimento.procedimentoNome = procedimento.nome;
        });
      }
    });

    let totalProcedimentos: number;

    if (model.tipo === DocSusType.LaudoTfd) {
      totalProcedimentos = 1 - this.procedimentos.length;
    } else {
      totalProcedimentos = 3 - this.procedimentos.length;
    }

    for (let i = 0; i < totalProcedimentos; i++) {
      const docSusProcedimento = new DocSusProcedimentoModel();
      docSusProcedimento.docSusId = model.id;
      docSusProcedimento.dataInclusao = new Date();
      docSusProcedimento.id = this.commonService.newGuid();
      docSusProcedimento.procedimentoNome = '';
      docSusProcedimento.quantidade = 1;
      this.procedimentos.push(docSusProcedimento);
    }
  }


  beforeSave(model: DocSusModel) {
    model.procedimentos = this.procedimentos.filter(x => x.procedimentoId);

    return super.beforeSave(model);
  }

  beforeShowCreate(model: DocSusModel = null): DocSusModel {
    model.dataSolicitacao = new Date();
    model.transpAereo = false;
    model.transpAmbulancia = false;
    model.transpRodoviario = false;
    model.caracterAtendimento = CaracterAtendimentoType.Hospitalar;
    return model;
  }

  changeProcedimentoCodigo(codigo, index: number) {
    if (!codigo || codigo === this.procedimentos[index]?.procedimentoCodigo) {
      return;
    }
    this.getProcedimento(codigo, index, 2);
  }

  changeProcedimentoId(id: string, index: number) {
    if (!id || id === this.procedimentos[index]?.procedimentoId) {
      return;
    }
    this.getProcedimento(id, index, 1);
  }

  async getProcedimento(identificador, index, tipoIdentificador: number = 1) {
    let procedimento: ProcedimentoModel;
    switch (tipoIdentificador) {
      case 1:
        procedimento = await this.procedimentoService.getById(identificador).toPromise();
        break;
      case 2:
        procedimento = await this.procedimentoService.getByCodigo(identificador).toPromise();
        break;
    }

    if (!procedimento.id) {
      this.commonService.mensagem('Procedimento não encontrado!', '', 'warning');
      return;
    }

    this.procedimentos[index] = new DocSusProcedimentoModel();
    this.procedimentos[index].procedimentoId = procedimento.id;
    this.procedimentos[index].procedimentoCodigo = procedimento.codigo;
    this.procedimentos[index].procedimentoNome = procedimento.nome;
  }
}
