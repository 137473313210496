<div class="row clearfix">

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="titulo">Titulo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um titulo válido.</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Status do Contato</mat-label>
              <mat-select [(ngModel)]="searchStatus.searchTerm" (selectionChange)="getStatusPush($event.value)">
                <mat-option value="">Todos os Status</mat-option>
                <mat-option *ngFor="let status of statusPossiveis" [value]="status.value">{{status.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando Data por:</mat-label>
              <mat-select [(ngModel)]="filtroDataSelecionado" (selectionChange)="getSearchData($event.value)">
                <mat-option value="Nao filtrar data">Não Filtrar Data</mat-option>
                <mat-option *ngFor="let opcao of opcoesDatas" [value]="opcao.value">{{opcao.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 1">
            <mat-form-field>
              <mat-label>Período do Contato</mat-label>
              <input matInput [(ngModel)]="searchDataInicialEnvio.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 1">
            <mat-form-field>
              <input matInput [(ngModel)]="searchDataFinalEnvio.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>

          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 2">
            <mat-form-field>
              <mat-label>Período de Status</mat-label>
              <input matInput [(ngModel)]="searchDataInicialAgendamento.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-5 col-sm-5" *ngIf="filtroDataSelecionado === 2">
            <mat-form-field>
              <input matInput [(ngModel)]="searchDataFinalAgendamento.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>

        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card main-content">
          <div class="header">
              <h2>Notificações Push</h2>
          </div>
          <div class="body table-responsive">
              <!--query end-->
              <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover" style="width: 100%;">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-center">Data de Envio</th>
                    <th class="text-center">Status</th>
                    <th class="text-center" width="300">Título</th>
                    <th class="text-center">Mensagem</th>
                    <th class="text-center">URL</th>
                    <th class="text-center"width="100">Assinantes Notificados</th>
                    <th class="text-center" width="120"></th>
                  </tr>
                </thead>
                <tbody *ngIf="models?.length != 0">
                  <tr *ngFor="let push of models">
                    <td>
                      {{push.dataInclusao | date:'dd/MM/yyyy HH:mm'}}
                    </td>
                    <td *ngIf="push.status !== PushStatusType.Agendado">
                      {{getStatusPush(push.status)}}
                    </td>
                    <td *ngIf="push.status === PushStatusType.Agendado">
                      {{getStatusPush(push.status)}} {{push.dataAgendamento | date: 'dd/MM/yyyy HH:mm'}}
                    </td>
                    <td>
                      {{push.titulo}}
                    </td>
                    <td>
                      {{push.mensagem}}
                    </td>
                    <td>
                      {{push.url}}
                    </td>
                    <td>
                      <div *ngIf="push.status !== PushStatusType.Agendado && push.status !== PushStatusType.Pendente ">
                        {{push.totalAssinantes}}
                      </div>
                    </td>
                    <td class="text-center">
                      <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(push.id)" matTooltip="Excluir">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
                <mat-icon>add</mat-icon>
            </button>
        </div>
      </div>
  </div>
</div>
<app-push-modal #pushModal (modalSave)="onResponse($event)"></app-push-modal>
