import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AtendimentoService } from '../../domain/services';
import { AtendimentoModel } from '../../domain/models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PacienteModel } from '../../../paciente/domain/models';
import { PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ProfissionalRecursoService } from '../../domain/services/profissional-recurso.service';
import { ConvenioService } from '../../../parceiro/domain/services/convenio.service';
import { PacienteService } from '../../../paciente/domain/services';
import { DocMovtoEstoqueType, DocStatusType } from 'projects/ProjetoBaseAngular/domain/types';
import { ProfissionalSaudeService } from '../../../equipe/domain/services';
import { ProfissionalRecursoModel } from '../../../equipe/domain/models';
import { ProfissionalRecursoType } from 'projects/admin/src/domain/types';
import { TypesService } from 'projects/admin/src/domain/services';
import { AtendimentoType } from '../../domain/types';
import { ConvenioDto } from '../../../parceiro/domain/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-atendimento-entrada-modal',
  templateUrl: './atendimento-entrada-modal.component.html'
})
export class AtendimentoEntradaModalComponent extends BaseCrudModalComponent<AtendimentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  recursos: SelectDto[];
  profissionais: SelectDto[];

  convenios: ConvenioDto[];
  filteredConvenios: ConvenioDto[] = [];
  public convenioFilterCtrl: FormControl = new FormControl();

  paciente: PacienteModel;
  profissionalRecurso: ProfissionalRecursoModel;
  tipoAtendimento: AtendimentoType;
  lancadoRascunho: boolean;

  //Obj utilizado para controles internos
  totalProcedimentos = { qtdProcedimentos: 0, subTotal: 0, total: 0 };

  constructor(
    injector: Injector,
    readonly service: AtendimentoService,
    readonly profissionalRecursoService: ProfissionalRecursoService,
    readonly convenioService: ConvenioService,
    readonly pacienteService: PacienteService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
    this.verifyPermissionCreate = false;
    this.verifyPermissionRead = false;
    this.verifyPermissionUpdate = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.lancadoRascunho = false;
  }

  protected init() {
    super.init();
    this.profissionalRecursoService.getSelectListUser().subscribe(x => this.recursos = x);
    this.profissionalSaudeService.getSelectList().subscribe(x => this.profissionais = x);
    this.convenioService.getSelectList().subscribe(x => {
      this.convenios = x;
      this.convenios.forEach(item => {
        //normalizando texto (tirando acentos) para filtro
        item["normalizado"] = item.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      });

      this.filteredConvenios = x;
    });
    this.convenioFilterCtrl.valueChanges.subscribe(() => this.filterConvenio());
  }

  protected filterConvenio() {
    if (!this.convenios) {
      return;
    }
    // pega a palavra chave da consulta
    let search = this.convenioFilterCtrl.value;

    if (!search) {
      this.filteredConvenios = this.convenios.slice();
      return;
    } else {
      search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    }

    // filtra para a nova lista
    this.filteredConvenios = this.convenios.filter(x =>
      x["normalizado"].toLowerCase().startsWith(search)
    );
  }

  protected initializeForm(model: AtendimentoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      status: [model.status, Validators.required],
      profissionalRecursoId: [model.profissionalRecursoId, [Validators.required]],
      profissionalSolicitanteId: [model.profissionalSolicitanteId],
      profissionalExecutanteId: [model.profissionalExecutanteId],
      agendamentoId: [model.agendamentoId, [Validators.required]],
      pacienteId: [model.pacienteId, [Validators.required]],
      convenioId: [model.convenioId, [Validators.required]],
      matriculaConvenio: [model.matriculaConvenio, [Validators.maxLength(20)]],
      dataHoraEntrada: [this.fromJsonDateTime(model.dataHoraEntrada), [Validators.required]],
      dataHoraInicial: [this.fromJsonDateTime(model.dataHoraInicial)],
      dataHoraFinal: [this.fromJsonDateTime(model.dataHoraFinal)],
      statusDocumento: [model.statusDocumento],
      numeroNotaFiscal: [model.numeroNotaFiscal],
      gerouNotaFiscal: [model.gerouNotaFiscal],
      anamnese: [model.anamnese],

      codigoDocMovto: model.codigoDocMovto,
      docMovto: this.formBuilder.group({
        id: model.docMovto.id,
        dataInclusao: model.docMovto.dataInclusao,
        dataAlteracao: model.docMovto.dataAlteracao,
        dataEmissao: model.dataEmissao,
        dataAplicacao: model.dataAplicacao,
        statusDocumento: model.docMovto.statusDocumento,
        tipoMovtoEstoque: model.docMovto.tipoMovtoEstoque,
        tipoDocMovto: model.docMovto.tipoDocMovto
      }),

      subTotal: [model.subTotal],
      total: [model.total]
    });

    this.totalProcedimentos.qtdProcedimentos = 0;
    this.totalProcedimentos.subTotal = model.subTotal;
    this.totalProcedimentos.total = model.total;
    if (model.procedimentos?.length > 0) {
      this.totalProcedimentos.qtdProcedimentos = model.procedimentos?.length;
    }

    this.form.patchValue({
      procedimentos: model.procedimentos,
      medicamentos: model.medicamentos
    });

    if (model.pacienteId) {
      this.pacienteService.getById(model.pacienteId).subscribe(x => this.paciente = x);
    }

    if (model.profissionalRecursoId) {
      this.profissionalRecursoService.getById(model.profissionalRecursoId).subscribe(x => this.profissionalRecurso = x);
    }
    if (model.agendamentoId) {
      this.tipoAtendimento = AtendimentoType.Atendimento;
    } else {
      this.tipoAtendimento = AtendimentoType.NaoAgendado;
    }

  }

  beforeShowCreate(model: AtendimentoModel = null): AtendimentoModel {
    model.dataHoraEntrada = new Date();
    model.dataEmissao = new Date();
    model.subTotal = 0;
    model.percentualAcrescimo = 0;
    model.valorAcrescimo = 0;
    model.percentualDesconto = 0;
    model.valorDesconto = 0;
    model.total = 0;
    model.statusDocumento = DocStatusType.Draft;
    model.gerouNotaFiscal = false;

    //#region DocMovto
    model.docMovto.id = model.id;
    model.docMovto.dataInclusao = model.dataInclusao;
    model.docMovto.dataEmissao = model.dataEmissao;
    model.docMovto.statusDocumento = model.statusDocumento;
    model.docMovto.tipoMovtoEstoque = DocMovtoEstoqueType.SemMovimento;
    //#endregion

    return super.beforeShowCreate(model);
  }

  private isAllowedLancarEntrada(): boolean {
    let result = super.isAllowed("LancarEntrada");
    if (!result) {
      this.commonService.mensagem("Você não tem permissão de lançar atendimento!");
    }
    return result;
  }

  showLancar(atendimento: AtendimentoModel) {
    this.lancadoRascunho = false;
    if (this.isAllowedLancarEntrada()) {
      this.showCreate(atendimento);
    }
  }

  async showDetail(id: string) {
    if (this.isAllowedLancarEntrada()) {
      super.showDetail(id);
    }
  }

  async showEdit(id: string) {
    if (this.isAllowedLancarEntrada()) {
      super.showEdit(id);
    }
  }

  onPacienteResponse(pacienteId: string) {
    if (pacienteId === this.form.value.pacienteId) {
      return;
    }

    this.form.patchValue({ pacienteId });
    if (!pacienteId) {
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
    } else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;
      });
    }
  }

  async getTotais(id: string): Promise<any> {
    let proc = await this.service.calcularTotais(id).toPromise();
    this.totalProcedimentos.qtdProcedimentos = proc.qtdProcedimentos;
    this.totalProcedimentos.subTotal = proc.subTotal;
    this.totalProcedimentos.total = proc.total;
    return this.totalProcedimentos;
  }

  onProcedimentoResponse() {
    this.getTotais(this.form.value.id).then(totais => {
      this.form.patchValue({
        subTotal: totais.subTotal,
        total: totais.total
      });
    });
  }

  autoSave() {
    this.save(true);
  }

  /**
   * Fechar sem lançar.
   * Caso já tenha sido lançado algum item de procedimento isso faz com que seja lançado
   * Atendimento em "modo rascunho" então é preciso excluir este lançamento.
   * @returns 
   */
  async closeNoSave() {
    if (this.newItem && this.lancadoRascunho) {
      let resposta = await this.commonService.mensagemConfirmacao("Atenção", "Atendimento já lançado em modo rascunho. Deseja cancelar lançamento?", "question");
      if (!resposta) {
        return false;
      } else {
        let model: AtendimentoModel = Object.assign({}, this.form.value);
        await this.service.excluirRascunho(model).toPromise();
      }
    }
    this.close();
  }


  async save(autoSave: boolean = false) {
    let model: AtendimentoModel = Object.assign({}, this.form.value);

    if (!autoSave) {
      const convenio = this.convenios.find(x => x.value === model.convenioId);
      if (convenio.matriculaObrigatoria && !model.matriculaConvenio) {
        this.commonService.mensagem(`O número da matrícula é obrigatório para o convênio ${convenio.text}`);
        return;
      }

      if (this.profissionalRecurso.tipo === ProfissionalRecursoType.Equipamento) {
        if (!model.profissionalSolicitanteId) {
          this.commonService.mensagem(`Informe o profissional solicitante.`);
          return;
        }
        if (!model.profissionalExecutanteId) {
          this.commonService.mensagem(`Informe o profissional executante.`);
          return;
        }
      }

      if (this.totalProcedimentos.qtdProcedimentos === 0) {
        //Pode ser uma edição em que não foi chamado Add Procedimento.
        //Então fazer validação na API
        let totais = await this.getTotais(model.id);
        if (totais.qtdProcedimentos === 0) {
          this.commonService.mensagem(`Informe um procedimento a ser lançado.`);
          return;
        }
      }
    }

    if (!autoSave && Number(model.docMovto.statusDocumento) === DocStatusType.Draft) {
      this.form.patchValue({
        statusDocumento: DocStatusType.Saved,
        docMovto: {
          statusDocumento: DocStatusType.Saved
        }
      });
    }

    this.form.patchValue({
      docMovto: {
        dataEmissao: model.dataEmissao
      }
    });

    if (!model.gerouNotaFiscal) {
      this.form.patchValue({
        numeroNotaFiscal: null
      });
    }

    this.lancar(autoSave);
  }

  lancar(autoSave: boolean) {
    let model: AtendimentoModel = Object.assign({}, this.form.value);
    this.errors = [];
    if (this.detailMode) {
      this.errors.push('Você está em modo de visualização de detalhes.');
      return;
    }

    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    this.isBusy = true;
    model = this.beforeSave(model);
    this.service.lancar(model).subscribe(
      (model) => {
        this.form.patchValue({ id: model.id, profissionalExecutanteId: model.profissionalExecutanteId });
        this.afterSave(model);
        if (!autoSave) {
          this.close();
          this.modalSave.emit(model.id);
        }
        this.isBusy = false;
        this.lancadoRascunho = true;
      },
      (errors) => {
        this.verifyErrors(errors);
        this.isBusy = false;
        this.commonService.mensagem(
          'Oops! Algo deu errado',
          errors.join('/n'),
          'error');
      }
    );
  }

  changeMatriculaConvenio(matriculaConvenio: string) {
    if (matriculaConvenio) {
      this.convenioService.getByMatricula(matriculaConvenio).subscribe(convenio => {
        if (convenio) {
          this.form.patchValue({ convenioId: convenio.id });
        }
      });
    }
  }

  async editarNumFiscal(id: string, informarNum: boolean) {
    const model = await this.service.getById(id).toPromise();
    let atualizado = false;

    if (!informarNum) {
      model.numeroNotaFiscal = 0;
      model.gerouNotaFiscal = false;
      atualizado = true;
    } else {

      let result = await Swal.fire({
        title: 'Informar o número da Nota Fiscal',
        html: `Informe o número.`,
        icon: 'question',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (result.isConfirmed) {
        model.numeroNotaFiscal = Number(result.value);
        model.gerouNotaFiscal = true;
        atualizado = true;
      }    
    }

    if (atualizado) {
      await this.service.edit(model).toPromise();

      this.form.patchValue({
        numeroNotaFiscal: model.numeroNotaFiscal,
        gerouNotaFiscal: model.gerouNotaFiscal
      });

      this.commonService.mensagem('Nota fiscal atualizada com sucesso!', '', 'success');
    }
  }

  get isEquipamento() {
    return this.profissionalRecurso?.tipo === ProfissionalRecursoType.Equipamento;
  }
}
