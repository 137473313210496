<div class="card">
    <div class="body" *ngIf="form">
        <div class="row">
            <div class="col-md-6">
                <form method="post" (ngSubmit)="save()" [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12">
							<mat-form-field>
								<mat-label>Título da página inicial</mat-label>
								<input matInput formControlName="seoTitle" type="text" maxlength="100" />
								<mat-error>Informe um Título válido.</mat-error>
							</mat-form-field>
                        </div>                                                

                        <div class="col-md-12">
							<mat-form-field>
								<mat-label>Título curto para outras páginas</mat-label>
								<input matInput formControlName="seoShortTitle" type="text" maxlength="50" />
								<mat-error>Informe um Título válido.</mat-error>
							</mat-form-field>
                        </div> 

                        <div class="col-md-12">
							<mat-form-field>
								<mat-label>Descrição para indexação nos sites de pesquisa</mat-label>
								<textarea matInput formControlName="seoDescription" type="text" rows="4" maxlength="400"></textarea>
								<mat-error>Informe uma Descrição válida.</mat-error>
							</mat-form-field>
                        </div>                          

                        <div class="col-md-12">
							<mat-form-field>
								<mat-label>Palavras Chave para indexação nos sites de pesquisa</mat-label>
								<input matInput formControlName="seoKeywords" type="text" maxlength="400" />
								<mat-error>Informe Palavras Chaves válidas.</mat-error>
							</mat-form-field>
                        </div>                          

                        <div class="col-md-12">
                            <button mat-flat-button type="submit" color="primary" class="button-row">
                                <span>Salvar Alterações</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-md-6">
                <label class="control-label">Pré-visualização Página Inicial</label>
                <div class="gresult">
                    <div id="title" class="gresult-title gresult-overflow">{{form.value.seoTitle || "Por favor insira o título"}}</div>
                    <div class="gresult-link gresult-overflow">{{url}}</div>
                    <div id="description" class="gresult-text">
                        {{form.value.seoDescription || "Por favor insira uma descrição"}}
                    </div>
                </div>
                <hr/>
                <label class="control-label">Pré-visualização Outras Páginas</label>
                <div class="gresult">
                    <div id="title" class="gresult-title gresult-overflow">Pagina XYZ | {{form.value.seoShortTitle || "Por favor insira o título de sufixo"}}</div>
                    <div class="gresult-link gresult-overflow">{{url}}</div>
                    <div id="description" class="gresult-text">
                        {{form.value.seoDescription || "Por favor insira uma descrição"}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>