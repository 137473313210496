import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { PacienteComponent } from './paciente.component';
import { PacienteModalComponent } from './paciente-modal/paciente-modal.component';
import { TagHelperDirective } from 'projects/ProjetoBaseAngular/directives/tag-helper.directive';
import { PacienteSelectModalComponent } from './paciente-select-modal/paciente-select-modal.component';
import { DocSusModule } from '../../atendimento/doc-sus/doc-sus.module';
import { PacienteArquivoComponent } from './paciente-arquivo/paciente-arquivo.component';
import { PacienteArquivoModalComponent } from './paciente-arquivo/paciente-arquivo-modal/paciente-arquivo-modal.component';
import { TermoModule } from '../../atendimento/termo/termo.module';
import { PacienteExameIndicadorModalComponent } from './paciente-exame-indicador-modal/paciente-exame-indicador-modal.component';
import { PacienteExameIndicadorItemModalComponent } from './paciente-exame-indicador-modal/paciente-exame-indicador-item-modal/paciente-exame-indicador-item-modal.component';
import { ExameDashboardModalComponent } from "./exame-dashboard-modal/exame-dashboard-modal.component";
import { PacienteProntuarioModalComponent } from './paciente-prontuario-modal/paciente-prontuario-modal.component';
import { PacienteTermoModalComponent } from './paciente-termos-modal/paciente-termo-modal.component';
import { PacienteTermoDetalhesModalComponent } from './paciente-termos-modal/paciente-termo-detalhes-modal/paciente-termo-detalhes-modal.component';

@NgModule({
  imports: [
    SharedModule,
    NgxMatSelectSearchModule,
    GaleriaModule,
    ChartsModule,
    DocSusModule,
    TermoModule
  ],
  declarations: [
    TagHelperDirective,
    PacienteComponent,
    PacienteModalComponent,
    PacienteSelectModalComponent,
    PacienteArquivoComponent,
    PacienteArquivoModalComponent,
    PacienteExameIndicadorModalComponent,
    PacienteExameIndicadorItemModalComponent,
    ExameDashboardModalComponent,
    PacienteProntuarioModalComponent,
    PacienteTermoModalComponent,
    PacienteTermoDetalhesModalComponent
  ],
  exports: [
    PacienteModalComponent,
    PacienteSelectModalComponent,
    PacienteArquivoComponent,
    PacienteArquivoModalComponent
  ]
})
export class PacienteModule { }
