<div class="row clearfix">

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-md-2 col-sm-6">
            <mat-form-field>
              <mat-label>Período de Inclusão</mat-label>
              <input matInput [(ngModel)]="searchDataInicial.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-2 col-sm-6">
            <mat-form-field>
              <input matInput [(ngModel)]="searchDataFinal.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Vaga</mat-label>
              <mat-select [(ngModel)]="searchVaga.searchTerm">
                <mat-option value="">Todas as Vagas</mat-option>
                <mat-option *ngFor="let vaga of vagas" [value]="vaga.id">{{vaga.titulo}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Status do Currículo</mat-label>
              <mat-select [(ngModel)]="searchStatus.searchTerm">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let status of statusCurriculo" [value]="status.value">{{status.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select [(ngModel)]="searchEstado.searchTerm" (selectionChange)="changeEstado($event.value)">
                <mat-option value="">Todos Estados</mat-option>
                <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <mat-select [(ngModel)]="searchCidade.searchTerm">
                <mat-option value="">Todas Cidades</mat-option>
                <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Sexo</mat-label>
              <mat-select [(ngModel)]="searchSexo.searchTerm">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let sexo of sexoCurriculo" [value]="sexo.value">{{sexo.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="nome">Nome</mat-option>
                <mat-option value="areaAtuacao">Área de Atuação</mat-option>
                <mat-option value="celular">Celular</mat-option>                
                <mat-option value="cidade.nome">Cidade</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um Nome válido.</mat-error>
            </mat-form-field>
          </div>          
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="card main-content">
          <div class="header">
              <h2>Currículo</h2>
          </div>
          <div class="body table-responsive">
              <!--query end-->
              <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover" style="width: 100%;">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-center" width="80">Data</th>
                    <th class="text-center" width="80">Status</th>
                    <th class="text-center">Vaga</th>
                    <th class="text-center">Nome</th>
                    <th class="text-center" width="100">Área Atuação</th>
                    <th class="text-center" width="80">Sexo</th>
                    <th class="text-center" width="150">Cidade</th>
                    <th class="text-center" width="100">Celular</th>                    
                    <th class="text-center" width="80">Enviados</th>                    
                    <th class="text-center" width="50"></th>
                  </tr>
                </thead>
                <tbody *ngIf="models?.length != 0">
                  <tr *ngFor="let curriculo of models">
                    <td>
                      {{curriculo.dataInclusao | date :'dd/MM/yyyy'}}
                    </td>
                    <td class="text-center">
                      <span [ngClass]="{
                        'bg-red': curriculo.status === StatusCurriculoType.Pendente,
                        'bg-orange': curriculo.status === StatusCurriculoType.Visto,
                        'bg-green': curriculo.status === StatusCurriculoType.Contratado,
                        'bg-grey': curriculo.status === StatusCurriculoType.NaoAtende
                      }" class="label">
                        {{getStatusCurriculo(curriculo.status)}}
                      </span>
                    </td>
                    <td>
                      {{curriculo.vagaTitulo}}
                    </td>
                    <td>
                      {{curriculo.nome}}
                    </td>
                    <td>
                      {{curriculo.areaAtuacao}}
                    </td>
                    <td>
                      {{getSexoCurriculo(curriculo.sexo)}}
                    </td>
                    <td>
                      {{curriculo.cidadeUf}}
                    </td>
                    <td>
                      {{curriculo.celular}}
                    </td>                    
                    <td>
                      {{curriculo.total}}
                    </td>                    
                    <td class="text-center">
                      <button mat-icon-button type="button" (click)="showCurriculos(curriculo.cpf)" matTooltip="Ver Currículos">
                        <mat-icon>open_in_browser</mat-icon>
                      </button>                      
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
                <mat-icon>add</mat-icon>
            </button>
        </div>
      </div>
  </div>
</div>
<app-curriculo-cpf-modal #curriculoCpfModal (modalSave)="onResponse($event)"></app-curriculo-cpf-modal>
<app-curriculo-modal #curriculoModal (modalSave)="onResponse($event)"></app-curriculo-modal>