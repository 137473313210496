import { Component, OnInit, Injector } from '@angular/core';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ArmazenamentoService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-status-armazenamento',
  templateUrl: './status-armazenamento.component.html'
})
export class StatusArmazenamentoComponent extends BaseComponent implements OnInit {
  armazenamento: any = {};

  constructor(
      injector: Injector,
      private readonly armazenamentoService: ArmazenamentoService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.armazenamentoService.get().subscribe(armazenamento => this.armazenamento = armazenamento);
  }

}
