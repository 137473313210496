import { Component, OnInit, ViewChild, Injector, QueryList, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AgendaBloqueioService, AgendaEsperaService, AgendamentoService, AtendimentoService, ClassificacaoAgendamentoService, LayoutMensagemService, SalaAtendimentoService } from '../../domain/services';
import { AgendaBloqueioModel, AgendamentoFilterDto, AgendamentoModel, AgendamentoRecorrenteModel, AtendimentoFilterDto, AtendimentoModel, ClassificacaoDto, LayoutMensagemModel } from '../../domain/models';
import { ProfissionalRecursoService } from '../../domain/services/profissional-recurso.service';
import { PacienteModel } from '../../../paciente/domain/models';
import { ConvenioService } from '../../../parceiro/domain/services/convenio.service';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PacienteService } from '../../../paciente/domain/services';
import { DataTablesRequest, ModelSearch, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { AgendamentoStatusParcialType, AgendamentoStatusType, AgendamentoType, AtendimentoStatusType, AtendimentoType, LayoutMesangemType, RecorrenciaPeriodoType } from '../../domain/types';
import { TypesService } from 'projects/admin/src/domain/services';
import { AgendamentoLogComponent } from '../agendamento-log/agendamento-log.component';
import { AtendimentoEntradaModalComponent } from '../../atendimento/atendimento-entrada-modal/atendimento-entrada-modal.component';
import { PacienteModalComponent } from '../../../paciente/paciente/paciente-modal/paciente-modal.component';
import { DocStatusType, OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { AgendaEsperaComponent } from '../../agenda-espera/agenda-espera.component';
import * as moment from 'moment';
import { ConfigService, TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { ConvenioDto } from '../../../parceiro/domain/models';
import { WhatsChatService } from '../../../atendimento-online/domain/services/whatschat.service';
import { LocalStorageService } from 'projects/ProjetoBaseAngular/domain/services/local-storage.service';
import { SolicitacaoOnlineModel } from '../../../atendimento-online/domain/models';
import { AgendamentoRecorrenteService } from '../../domain/services/agendamento-recorrente.service';
import { AgendamentoRecorrenteComponent } from '../agendamento-recorrente/agendamento-recorrente.component';
import { ConfigAgendamentoConsts } from 'projects/admin/src/domain/consts';


@Component({
  selector: 'app-agendamento-modal',
  templateUrl: './agendamento-modal.component.html'
})
export class AgendamentoModalComponent extends BaseCrudModalComponent<AgendamentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('agendamentoLogModal') agendamentoLogModal: AgendamentoLogComponent;
  @ViewChild('atendimentoEntradaModal') atendimentoEntradaModal: AtendimentoEntradaModalComponent;
  @ViewChild('pacienteAtendimentoModal') pacienteAtendimentoModal: PacienteModalComponent;
  @ViewChild('agendaEsperaModal') agendaEsperaModal: AgendaEsperaComponent;
  @ViewChild('agendaRecorrenteModal') agendaRecorrenteModal: AgendamentoRecorrenteComponent;
  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;

  salas: SelectDto[];
  recursos: SelectDto[];

  classificacoes: ClassificacaoDto[];
  filteredClassificacoes: ClassificacaoDto[] = [];
  public classificacaoFilterCtrl: FormControl = new FormControl();

  convenios: ConvenioDto[];
  filteredConvenios: ConvenioDto[] = [];
  public convenioFilterCtrl: FormControl = new FormControl();

  paciente: PacienteModel;
  agendamentosPaciente: any[] = [];
  atendimentosPaciente: any[] = [];
  atendimentoType: typeof AtendimentoType = AtendimentoType;
  agendamentoType: typeof AgendamentoType = AgendamentoType;

  // Usado para inativar agenda de espera ao ser selecionado e confirmado no agendamento
  agendaEsperaId: string;

  dtOptionsAgendamento: DataTables.Settings = null;
  dtOptionsAtendimento: DataTables.Settings = null;

  finalizarChatFila: boolean;
  usaAgendamentoRecorrente: boolean = false;

  constructor(
    injector: Injector,
    readonly service: AgendamentoService,
    readonly recorrenteService: AgendamentoRecorrenteService,
    readonly atendimentoService: AtendimentoService,
    readonly salaService: SalaAtendimentoService,
    readonly classificacaoService: ClassificacaoAgendamentoService,
    readonly profissionalRecursoService: ProfissionalRecursoService,
    readonly convenioService: ConvenioService,
    readonly pacienteService: PacienteService,
    readonly typesService: TypesService,
    readonly agendaBloqService: AgendaBloqueioService,
    readonly agendaEsperaService: AgendaEsperaService,
    readonly layoutMensagemService: LayoutMensagemService,
    readonly whatsChatService: WhatsChatService,
    readonly storageService: LocalStorageService,
    readonly tenantService: TenantService,
    readonly configService: ConfigService,
  ) {
    super(injector, service);
  }
  ngOnInit() {
    super.ngOnInit();
  }

  protected init() {
    super.init();

    this.configService.getModelByChave(ConfigAgendamentoConsts.UsaAgendamentoRecorrente)
      .subscribe(x => this.usaAgendamentoRecorrente = x.valor === 'true');

    this.salaService.getSelectList().subscribe(x => this.salas = x);
    this.profissionalRecursoService.getSelectListUser().subscribe(x => this.recursos = x);

    this.classificacaoService.getSelectList().subscribe(x => {
      this.classificacoes = x;

      this.classificacoes.forEach(item => {
        //normalizando texto (tirando acentos) para filtro
        item["normalizado"] = item.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      });

      this.filteredClassificacoes = x;
    });
    this.classificacaoFilterCtrl.valueChanges.subscribe(() => this.filterClassificacao());

    this.convenioService.getSelectList().subscribe(x => {
      this.convenios = x;
      this.convenios.forEach(item => {
        //normalizando texto (tirando acentos) para filtro
        item["normalizado"] = item.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      });

      this.filteredConvenios = x;
    });
    this.convenioFilterCtrl.valueChanges.subscribe(() => this.filterConvenio());
  }

  protected filterConvenio() {
    if (!this.convenios) {
      return;
    }
    // pega a palavra chave da consulta
    let search = this.convenioFilterCtrl.value;

    if (!search) {
      this.filteredConvenios = this.convenios.slice();
      return;
    } else {
      search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    }

    // filtra para a nova lista
    this.filteredConvenios = this.convenios.filter(x =>
      x["normalizado"].toLowerCase().startsWith(search)
    );
  }

  protected filterClassificacao() {
    if (!this.classificacoes) {
      return;
    }
    // pega a palavra chave da consulta
    let search = this.classificacaoFilterCtrl.value;

    if (!search) {
      this.filteredClassificacoes = this.classificacoes.slice();
      return;
    } else {
      search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    }

    // filtra para a nova lista
    this.filteredClassificacoes = this.classificacoes.filter(x =>
      x["normalizado"].toLowerCase().startsWith(search)
    );
  }

  protected initializeForm(model: AgendamentoModel) {
    this.agendamentosPaciente = [];
    this.agendaEsperaId = null;
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      tipo: [model.tipo],
      status: [model.status, Validators.required],
      profissionalRecursoId: [model.profissionalRecursoId, Validators.required],
      pacienteAnotacao: [model.pacienteAnotacao],
      pacienteId: [model.pacienteId],
      nomePaciente: [model.nomePaciente, Validators.maxLength(60)],
      telefonePaciente: [model.telefonePaciente, Validators.maxLength(15)],
      celularPaciente: [model.celularPaciente, Validators.maxLength(15)],
      convenioId: [model.convenioId, Validators.required],
      classificacaoId: [model.classificacaoId, Validators.required],
      salaId: [model.salaId],
      data: [this.fromJsonDate(model.data), Validators.required],
      horario: [model.horario, Validators.required],
      observacoes: [model.observacoes, Validators.maxLength(1000)],
      preAtendimento: [model.preAtendimento, Validators.maxLength(1000)],
      motivoCancelamento: [model.motivoCancelamento, Validators.maxLength(500)],
      dataSolicConfirmacao: [model.dataSolicConfirmacao],
      agendamentoRecorrenteId: [model.agendamentoRecorrenteId],
      itemRecorrencia: [model.itemRecorrencia],
      totalItensRecorrencia: [model.totalItensRecorrencia]
    });
  }

  beforeShowCreate(model: AgendamentoModel): AgendamentoModel {
    this.novoPaciente();
    model.pacienteAnotacao = false;
    model.status = model.status || AgendamentoStatusType.Agendado;
    return super.beforeShowCreate(model);
  }

  beforeShowEdit(model: AgendamentoModel): AgendamentoModel {
    this.agendamentosPaciente = [];
    if (model.pacienteId) {
      this.pacienteService.getById(model.pacienteId).subscribe(x => this.setPaciente(x));
      this.refreshLancamentos();
    } else {
      this.novoPaciente();
    }
    return super.beforeShowEdit(model);
  }

  novoPaciente() {
    this.paciente = new PacienteModel();
    this.paciente.pessoa = new PessoaModel();
    this.paciente.pessoa.cnpjCpf = '';
    this.paciente.pessoa.nomeFantasia = '';
  }

  async selectLayoutMensagemAsync(): Promise<LayoutMensagemModel> {
    let layouts = await this.layoutMensagemService.getSelectList().toPromise();
    layouts = layouts.filter(x => x.tipo == LayoutMesangemType.AgendamentoConfirmacaoWhatsApp);

    if (layouts.length === 0) {
      this.commonService.mensagem('Nenhum layout de mensagem configurado.', '', 'warning');
      return null;
    }

    if (layouts.length === 1) {
      return this.layoutMensagemService.getById(layouts[0].id).toPromise();
    }

    let buttons: string = '';
    layouts.forEach(layout => {
      buttons += `<button id="layout${layout.id}" type="button" class="swal2-confirm swal2-styled">${layout.nome}</button>`;
    });
    const result = await Swal.fire({
      title: 'Layout de Mensagens',
      html:
        `<h5 class="text-center">Selecione um layout abaixo: *</h5>${buttons}`,
      icon: 'question',
      focusCancel: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Fechar',
      didOpen: () => {
        layouts.forEach(layout => {
          document.getElementById(`layout${layout.id}`).addEventListener('click', () => Swal.close({ isConfirmed: true, isDismissed: false, isDenied: false, value: layout.id }));
        });
      }
    });

    if (result.value) {
      return await this.layoutMensagemService.getById(result.value).toPromise();
    }

    return null;
  }

  marcarAtendido(id: string) {
    this.service.getById(id).subscribe(agendamento => {
      agendamento.status = AgendamentoStatusType.Atendido;
      this.service.atenderManual(agendamento).subscribe(
        (model) => {
          this.close();
          this.modalSave.emit(model);
          this.isBusy = false;
        },
        (errors) => {
          this.verifyErrors(errors);
          this.isBusy = false;
          Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
        }
      );
    });
  }

  desfazerAtendido(id: string) {
    this.service.getById(id).subscribe(agendamento => {
      agendamento.status = AgendamentoStatusType.LancadoEntrada;
      this.service.desfazAtendidoManual(agendamento).subscribe(
        (model) => {
          this.close();
          this.modalSave.emit(model);
          this.isBusy = false;
        },
        (errors) => {
          this.verifyErrors(errors);
          this.isBusy = false;
          Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
        }
      );
    });
  }

  solicitarConfirmacao(id: string) {
    if (this.isBusy) return;

    this.commonService.spinnerOpen('Aguarde...');
    this.service.getById(id).subscribe(async agendamento => {
      let paciente: PacienteModel;
      if (!agendamento.pacienteAnotacao) {
        paciente = await this.pacienteService.getById(agendamento.pacienteId).toPromise();
      }
      let celular = agendamento.pacienteAnotacao ? agendamento.celularPaciente : paciente.pessoa.celular;

      if (celular) {
        this.selectLayoutMensagemAsync().then(
          layout => {
            if (layout) {
              agendamento.dataSolicConfirmacao = new Date();
              this.service.edit(agendamento).subscribe(
                async (model) => {
                  celular = celular.replace(/[^0-9]/g, '');
                  if (!celular.startsWith('55')) {
                    celular = `55${celular}`;
                  }

                  const tenant = await this.tenantService.getCurrentTenant().toPromise();

                  //Acontece esta situação na 1° chanada de envio de mensagem, 
                  //caso ainda não tenha entrado no modal (pois não passou pelo Init()).
                  if (!this.recursos) {
                    this.recursos = await this.profissionalRecursoService.getSelectListUser().toPromise();
                  }
                  const profissionalEquipamento = this.recursos.find(x => x.value === agendamento.profissionalRecursoId).text;

                  const texto = layout.texto
                    .replace(/{{linkConfirmacao}}/g, `https://${location.host}/confirma-agendamento/${agendamento.id}?tenant=${this.accountService.getTenant()}`)
                    .replace(/{{data}}/g, moment(agendamento.data).format('DD/MM/YYYY'))
                    .replace(/{{horario}}/g, agendamento.horario.substring(0, 5))
                    .replace(/{{pacienteNome}}/g, paciente?.pessoa?.nomeFantasia || agendamento.nomePaciente)
                    .replace(/{{profissionalEquipamentoNome}}/g, profissionalEquipamento)
                    .replace(/{{empresaNome}}/g, tenant.nomeFantasia)
                    .replace(/{{empresaEndereco}}/g, `${tenant.ruaAvenida}${tenant.numero ? ', ' + tenant.numero : ''}, ${tenant.bairro}, ${tenant.cidadeNome}/${tenant.estadoUf}`)
                    .replace(/{{empresaCelular}}/g, tenant.celular);
                  window.open(`https://wa.me/${celular}?text=${texto}`, '_blank');

                  this.modalSave.emit(model);
                  this.isBusy = false;
                  this.commonService.spinnerClose();
                },
                (errors) => {
                  this.verifyErrors(errors);
                  this.isBusy = false;
                  this.commonService.spinnerClose();
                  Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
                }
              );
            } else {
              this.commonService.spinnerClose();
            }
          },
          () => {
            this.commonService.spinnerClose();
            this.commonService.mensagem("Mensagem não pode ser enviada.", "Verifique configuração de Layout de mensagem do tipo 'Confirmação de WhatsApp'", "info");
          }
        );
      } else {
        this.commonService.spinnerClose();
        this.commonService.mensagem("Mensagem não pode ser enviada", "Paciente sem informação de telefone celular, verifique o cadastro ou anotação (pré-cadastro).", "info");
      }
    });
  }

  async confirmarPresenca(id: string) {
    let model = await this.service.getById(id).toPromise();
    model.status = AgendamentoStatusType.Confirmado;
    try {
      model = await this.service.edit(model).toPromise();

      await this.whatsChatService.finalizarByAgendamentoId(model.id).toPromise();

      this.close();
      this.modalSave.emit(model);
      this.isBusy = false;

    } catch (errors) {
      this.verifyErrors(errors);
      this.isBusy = false;
      Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
    }
  }

  naoCompareceu(id: string) {
    this.service.getById(id).subscribe(agendamento => {
      agendamento.status = AgendamentoStatusType.NaoCompareceu;
      this.service.edit(agendamento).subscribe(
        async (model) => {
          await this.bloquearPacienteAsync(agendamento);
          this.close();
          this.modalSave.emit(model);
          this.isBusy = false;
        },
        (errors) => {
          this.verifyErrors(errors);
          this.isBusy = false;
          Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
        }
      );
    });
  }

  async bloquearPacienteAsync(agendamento: AgendamentoModel) {
    if (!agendamento.pacienteAnotacao && agendamento.pacienteId) {
      const paciente = await this.pacienteService.getById(agendamento.pacienteId).toPromise();
      if (!paciente.bloquearAgendamento) {
        const bloquear = await this.commonService.mensagemConfirmacao('', `Deseja bloquear agendamento para ${paciente.pessoa.nomeFantasia}?`, 'question');
        if (bloquear) {
          const resultDias = await Swal.fire({
            title: 'Confirmar bloqueio?',
            html: `Informe os dias para bloqueio do paciente no campo abaixo.`,
            icon: 'question',
            input: 'number',
            inputValue: 30,
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
          });
          if (resultDias.isConfirmed) {
            const dias = parseInt(resultDias.value);

            const resultMotivo = await Swal.fire({
              title: 'Confirmar bloqueio?',
              html: `Informe o motivo do bloqueio no campo abaixo.`,
              icon: 'question',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Bloquear',
              cancelButtonText: 'Cancelar',
            });

            if (resultMotivo.isConfirmed) {
              const motivo: string = resultMotivo.value + '';
              try {
                await this.pacienteService.bloquearAgendamento(agendamento.pacienteId, motivo, dias).toPromise();
                await this.commonService.mensagem('Paciente bloqueado com sucesso!', '', 'success');
              } catch (error) {
                await this.commonService.mensagem('Ocorreu um erro ao bloquear Paciente.', error, 'error');
              }
            }
          }
        }
      }
    }
  }

  desconfirmarPresenca(id: string) {
    this.service.getById(id).subscribe(agendamento => {
      agendamento.status = AgendamentoStatusType.Agendado;
      agendamento.dataSolicConfirmacao = null;
      this.service.edit(agendamento).subscribe(
        (model) => {
          this.close();
          this.modalSave.emit(model);
          this.isBusy = false;
        },
        (errors) => {
          this.verifyErrors(errors);
          this.isBusy = false;
          Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
        }
      );
    });
  }

  async encaixar(id: string) {
    const modelAtual = await this.service.getById(id).toPromise();

    const newModel = new AgendamentoModel();
    newModel.tipo = AgendamentoType.Encaixe;
    newModel.data = modelAtual.data;
    newModel.horario = modelAtual.horario;
    newModel.classificacaoId = modelAtual.classificacaoId;
    newModel.profissionalRecursoId = modelAtual.profissionalRecursoId;
    newModel.id = this.commonService.newGuid();
    newModel.profissionalRecursoId = modelAtual.profissionalRecursoId;
    newModel.status = AgendamentoStatusType.Encaixe;
    newModel.pacienteAnotacao = false;
    newModel.pacienteId = "";
    newModel.convenioId = "";
    newModel.itemRecorrencia = 0;
    newModel.totalItensRecorrencia = 0;
    newModel.agendamentoRecorrenteId = "";

    this.showCreate(newModel);
  }

  async lancar(id: string) {
    const agendamento = await this.service.getById(id).toPromise();

    if (agendamento.pacienteAnotacao) {
      let result = await this.commonService.mensagemConfirmacao('', 'Você deve cadastrar o paciente para continuar. Deseja cadastrá-lo agora?', "question");
      if (result) {
        const paciente = new PacienteModel();
        paciente.id = this.commonService.newGuid();
        paciente.dataInclusao = new Date();
        paciente.pessoa = new PessoaModel();
        paciente.pessoa.id = paciente.id;
        paciente.pessoa.dataInclusao = paciente.dataInclusao;
        paciente.pessoa.nomeFantasia = agendamento.nomePaciente;
        paciente.pessoa.celular = agendamento.celularPaciente;
        paciente.pessoa.telefone = agendamento.telefonePaciente;
        this.pacienteAtendimentoModal.showCreate(paciente);
      }
      return;
    }

    let atendimento = await this.atendimentoService.getByAgendamentoId(agendamento.id).toPromise();
    if (!atendimento?.id) {
      atendimento = new AtendimentoModel();
      atendimento.id = this.commonService.newGuid();
      atendimento.dataInclusao = new Date();
      atendimento.agendamentoId = agendamento.id;
      if (agendamento.preAtendimento) {
        let preAtendiemnto = agendamento.preAtendimento.replace(/\n/g, "<br>");
        atendimento.anamnese = `Pré-Atendimento:<br>${preAtendiemnto}`;
      }
    }

    atendimento.status = AtendimentoStatusType.NaoAtendido;
    atendimento.profissionalRecursoId = agendamento.profissionalRecursoId;
    atendimento.pacienteId = agendamento.pacienteId;
    atendimento.convenioId = agendamento.convenioId;
    this.atendimentoEntradaModal.showLancar(atendimento);
  }

  async onPacienteAtendimentoResponse(pacienteId: string) {
    const paciente = await this.pacienteService.getById(pacienteId).toPromise();

    this.form.patchValue({
      pacienteId,
      pacienteAnotacao: false,
      nomePaciente: '',
      celularPaciente: '',
      telefonePaciente: ''
    });

    this.setPaciente(paciente);

    await this.save(true);
    this.lancar(this.form.value.id);
  }

  async cancelar(id: string) {
    const model = await this.service.getById(id).toPromise();

    if (model.status === AgendamentoStatusType.Atendido
      || model.status === AgendamentoStatusType.Cancelado) {
      this.commonService.mensagem("Agendamento não pode ser cancelado.", "Já está atendido ou cancelado.", "warning");
      return;
    }

    if (model.statusParcial !== AgendamentoStatusParcialType.Pendente) {
      this.commonService.mensagem("Agendamento não pode ser cancelado.", "Já possuí atendimento total ou parcial.", "warning");
      return;
    }

    Swal.fire({
      title: 'Confirmar cancelamento?',
      html: `Informe o motivo do cancelamento no campo abaixo.`,
      icon: 'question',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        const motivo: string = result.value + '';
        model.status = AgendamentoStatusType.Cancelado;
        this.service.cancel(model.id, motivo).subscribe(
          (model) => {
            this.close();
            this.modalSave.emit(model);
            this.isBusy = false;
          },
          (errors) => {
            this.verifyErrors(errors);
            this.isBusy = false;
            Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
          }
        );
      }
    });
  }

  async desfazerLancamento(id: string) {
    const model = await this.service.getById(id).toPromise();

    if (model.status === AgendamentoStatusType.Atendido
      || model.status === AgendamentoStatusType.Cancelado) {
      this.commonService.mensagem("Agendamento não pode ser desfeito.", "Já está atendido ou cancelado.", "warning");
      return;
    }

    if (model.statusParcial !== AgendamentoStatusParcialType.Pendente) {
      this.commonService.mensagem("Agendamento não pode ser desfeito.", "Já possuí atendimento total ou parcial.", "warning");
      return;
    }

    let confirmar = await this.commonService.mensagemConfirmacao("Desfazer lançamento?", "Confirmar?", "question");
    if (!confirmar) return;

    let forcado = false;
    const validaDto = await this.atendimentoService.podeDesfazerLancto(id).toPromise();

    if (!validaDto.podeDesfazer) {
      let titulo = `Atenção: ${validaDto.texto}`;
      let mensagem = "Deseja forçar o desfazer?";
      mensagem += "<br><br>Ao confirmar:<br>O atendimento será excluído e o agendamento será reaberto.";
      forcado = await this.commonService.mensagemConfirmacao(titulo, mensagem, "question");
    }

    if (validaDto.podeDesfazer || forcado) {
      let result = await this.atendimentoService.desfazerLancto(id, forcado).toPromise();
      if (result) {
        model.status = model.tipo === AgendamentoType.Encaixe ? AgendamentoStatusType.Encaixe : AgendamentoStatusType.Confirmado;
        model.statusParcial = AgendamentoStatusParcialType.Pendente;
        this.service.edit(model).subscribe(
          (model) => {
            this.close();
            this.modalSave.emit(model);
            this.isBusy = false;
          },
          (errors) => {
            this.verifyErrors(errors);
            this.isBusy = false;
            Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
          }
        );
      }
    }
  }

  bloquearAgenda() {
    const model: AgendamentoModel = Object.assign({}, this.form.value);
    const recurso = this.recursos.find(x => x.value == model.profissionalRecursoId);

    Swal.fire({
      title: 'Confirmar bloqueio?',
      html: `Atenção, está ação irá bloqueiar data/horário selecionado na agenda de ${recurso.text}.<p>Informe o motivo do bloqueio no campo abaixo.<p>`,
      icon: 'question',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        const bloqueioAgenda = new AgendaBloqueioModel();
        bloqueioAgenda.id = this.commonService.newGuid();
        bloqueioAgenda.dataInclusao = new Date();
        bloqueioAgenda.profissionalRecursoId = model.profissionalRecursoId;
        bloqueioAgenda.dataInicial = model.data;
        bloqueioAgenda.dataFinal = model.data;
        bloqueioAgenda.horarioInicial = model.horario;
        bloqueioAgenda.horarioFinal = model.horario;
        bloqueioAgenda.observacao = result.value + '';

        this.agendaBloqService.create(bloqueioAgenda).subscribe(
          () => {
            this.close();
            this.modalSave.emit();
            this.isBusy = false;
          },
          (errors) => {
            this.verifyErrors(errors);
            this.isBusy = false;
            Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
          }
        );
      }
    });
  }

  async save(autoSave: boolean = false) {
    if (!this.form.value.pacienteAnotacao && (!this.paciente || this.paciente.pessoa.nomeFantasia === "")) {
      this.commonService.mensagem('Paciente é obrigatório!', '', 'warning');
      return;
    }

    if (this.paciente?.bloquearAgendamento) {
      if (!this.paciente.dataExpiraBloqueio || moment(this.fromJsonDate(this.paciente.dataExpiraBloqueio)).toDate() >= moment(this.fromJsonDate(new Date())).toDate()) {
        this.commonService.mensagem('Paciente Bloqueado para Agendamento', this.paciente?.mensagemBloqAlerta, 'error');
        return;
      }
    }

    this.finalizarChatFila = false;
    if (!this.newItem) {
      if (this.form.value.data !== this.oldModel.data || this.form.value.horario !== this.oldModel.horario) {
        this.form.value.dataSolicConfirmacao = null;
        this.finalizarChatFila = true;
      }
    }

    //Remover dados do Documento Bot da área de transferência.
    this.storageService.removeItem('app.solicClipboard');

    if (this.form.value.tipo === AgendamentoType.Agendamento) {
      let isRetorno = this.classificacoes.find(x => x.value === this.form.value.classificacaoId).retorno;
      if (isRetorno) {
        this.form.value.tipo = AgendamentoType.Retorno;
      }
    }
    await super.save(autoSave);
  }


  afterSave(model: AgendamentoModel) {
    this.inativarAgendaEspera();

    if (this.finalizarChatFila) {
      this.whatsChatService.finalizarByAgendamentoId(model.id).subscribe();
    }

    return super.afterSave(model);
  }

  inativarAgendaEspera() {
    if (this.agendaEsperaId) {
      this.agendaEsperaService.getById(this.agendaEsperaId).subscribe(agendaEspera => {
        agendaEspera.registroAtivo = false;
        this.agendaEsperaService.edit(agendaEspera).subscribe();
      });
    }
  }

  showAgendamentoLog(id: string) {
    if (!this.newItem) {
      this.agendamentoLogModal.show(id);
    }
  }

  showAgendaEspera() {
    if (this.form.value.profissionalRecursoId) {
      const recurso = this.recursos.find(x => x.value === this.form.value.profissionalRecursoId);
      this.agendaEsperaModal.show(recurso.value, recurso.text);
    }
  }

  async copiarBotDocDeTransf() {
    if (!this.newItem) {
      await this.commonService.mensagem("Operação não executada", "Esta operação é aceita somente para novos agendamentos", "warning");
      return;
    }

    const json = this.storageService.getItem('app.solicClipboard');
    if (!json) {
      await this.commonService.mensagem("Bot - Documento não disponível", "Não foi encontrado documento na área de transferência", "info");
      return;
    }
    let botDoc: SolicitacaoOnlineModel = JSON.parse(json);

    const model: AgendamentoModel = Object.assign({}, this.form.value);
    if (botDoc.pacienteId) {
      model.pacienteId = botDoc.pacienteId;

      this.paciente = await this.pacienteService.getById(botDoc.pacienteId).toPromise();
      model.pacienteId = this.paciente.id;
      model.pacienteAnotacao = false;
      model.nomePaciente = this.paciente.pessoa.nomeFantasia;
      model.convenioId = this.paciente.convenioId;

      this.form.patchValue({
        pacienteId: model.pacienteId,
        nomePaciente: model.nomePaciente,
        pacienteAnotacao: model.pacienteAnotacao,
        convenioId: model.convenioId
      });

      if (this.paciente.mensagemBloqAlerta) {
        this.commonService.mensagem('Alerta do Paciente', this.paciente.mensagemBloqAlerta, 'warning');
      }
    } else {
      model.pacienteAnotacao = true;
      model.nomePaciente = botDoc.nomePaciente;
      model.observacoes = botDoc.mensagemSolicitacao;
      model.celularPaciente = this.commonService.idWhatsToCelular(botDoc.numeroPaciente);

      this.form.patchValue({
        nomePaciente: model.nomePaciente,
        pacienteAnotacao: model.pacienteAnotacao,
        celularPaciente: model.celularPaciente
      });
    }
  }

  onAtendimentoResponse(atendimentoId) {
    if (atendimentoId) {
      this.atendimentoService.getById(atendimentoId).subscribe(atendimento => {
        if (atendimento.agendamentoId) {
          this.service.getById(atendimento.agendamentoId).subscribe(agendamento => {
            agendamento.status = AgendamentoStatusType.LancadoEntrada;
            this.service.edit(agendamento).subscribe(
              (model) => {
                this.close();
                this.modalSave.emit(model);
                this.isBusy = false;
              },
              (errors) => {
                this.verifyErrors(errors);
                this.isBusy = false;
                Swal.fire('Oops! Algo deu errado', errors.join('/n'), 'error');
              }
            );
          });
        }
      });
    }
  }

  onPacienteResponse(pacienteId: string) {
    this.form.patchValue({ pacienteId });
    if (!pacienteId) {
      this.novoPaciente();
    } else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.form.patchValue({ pacienteAnotacao: false });
        this.setPaciente(x);
        if (!this.form.value.convenioId) {
          this.form.patchValue({ convenioId: this.paciente.convenioId });
        }
      });
    }

    this.refreshLancamentos();
  }


  onChangeAnotacao() {
    console.log("changeAnotação");

    const model: AgendamentoModel = Object.assign({}, this.form.value);
    if (model.pacienteAnotacao) {
      this.onPacienteResponse("");
    }
  }

  onAgendaEsperaResponse(agendaEsperaId: string) {
    this.agendaEsperaService.getById(agendaEsperaId).subscribe(x => {
      this.agendaEsperaId = x.id;
      this.form.patchValue({
        convenioId: x.convenioId
      });
      this.onPacienteResponse(x.pacienteId);
    });
  }

  refreshLancamentos() {
    !this.dtOptionsAgendamento && this.initAgendamentos();
    !this.dtOptionsAtendimento && this.initAtendimentos();

    this.dtElements?.forEach((dtElement: DataTableDirective) => {
      dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    });
  }

  initAgendamentos() {
    this.dtOptionsAgendamento = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      paging: false,
      info: false,
      scrollY: "200px",
      scrollCollapse: true,
      searching: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        const agendamentoFilterDto = new AgendamentoFilterDto();
        agendamentoFilterDto.naoFiltrarRecurso = true;
        agendamentoFilterDto.pacienteId = this.form.value.pacienteId;
        agendamentoFilterDto.listaStatus = [AgendamentoStatusType.Agendado, AgendamentoStatusType.Encaixe, AgendamentoStatusType.Confirmado];
        agendamentoFilterDto.start = this.fromJsonDate(new Date());
        dataTablesParameters.customParam = JSON.stringify(agendamentoFilterDto);

        if (!agendamentoFilterDto.pacienteId) {
          this.agendamentosPaciente = [];
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        } else {
          this.service.getResponse(dataTablesParameters).subscribe(resp => {
            this.agendamentosPaciente = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }
      },
      order: [[0, 'desc'], [1, 'desc']],
      columns: [
        { data: 'dataHoraInicial' },
        { data: 'horario' },
        { data: 'profissionalRecursoNome' },
        { data: 'convenioNome' },
        { data: 'observacoes' }
      ]
    };
  }

  initAtendimentos() {
    this.dtOptionsAtendimento = {
      language: {
        url: '/assets-base/json/translation/datatable/pt-br.json'
      },
      paging: false,
      info: false,
      scrollY: "200px",
      scrollCollapse: true,
      searching: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: DataTablesRequest, callback) => {
        dataTablesParameters.searches = dataTablesParameters.searches || [];
        dataTablesParameters.searches.push(new ModelSearch('statusDocumento', OptionSearchType.GreaterThan, SearchType.Int, DocStatusType.Draft));
        dataTablesParameters.searches.push(new ModelSearch('status', OptionSearchType.NotEquals, SearchType.Int, AtendimentoStatusType.Cancelado));

        dataTablesParameters.selectColumns = dataTablesParameters.columns;
        dataTablesParameters.selectColumns.push({ data: 'status' });
        const atendimentoFilterDto = new AtendimentoFilterDto();
        atendimentoFilterDto.naoFiltrarData = true;
        atendimentoFilterDto.naoFiltrarRecurso = true;
        atendimentoFilterDto.pacienteId = this.form.value.pacienteId;
        dataTablesParameters.customParam = JSON.stringify(atendimentoFilterDto);

        if (!atendimentoFilterDto.pacienteId) {
          this.atendimentosPaciente = [];
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        } else {
          this.atendimentoService.getResponse(dataTablesParameters).subscribe(resp => {
            this.atendimentosPaciente = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }
      },
      order: [[0, 'desc']],
      columns: [
        { data: 'dataHoraEntrada' },
        { data: 'profissionalRecurso.nome' },
        { data: 'convenio.nome' },
        { data: 'observacoes' },
        { data: 'agendamento?.classificacao.retorno', orderable: false },
        { data: 'agendamentoId' }
      ],
      columnDefs: [
        { targets: [5], visible: false, searchable: false }
      ]
    };
  }

  async editarObservacoes(id: string) {
    let model = await this.service.getById(id).toPromise();
    let result = await Swal.fire({
      title: 'Edição de Observação',
      html: `Informe a observação no campo abaixo.`,
      icon: 'info',
      input: 'textarea',
      inputValue: model.observacoes,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      model.observacoes = result.value + '';

      this.isBusy = true;
      model = await this.service.edit(model).toPromise();
      this.close();
      this.modalSave.emit(model);
      this.isBusy = false;
    }
  }

  setPaciente(paciente: PacienteModel) {
    this.paciente = paciente;
    this.form.patchValue({ nomePaciente: paciente?.pessoa?.nomeFantasia });

    if (paciente.mensagemBloqAlerta) {
      this.commonService.mensagem('Alerta do Paciente', paciente.mensagemBloqAlerta, 'warning');
    }
  }

  get hasLancamentos(): boolean {
    return this.hasAgendamentos || this.hasAtendimentos;
  }

  get hasAgendamentos(): boolean {
    return this.agendamentosPaciente.length > 0;
  }

  get hasAtendimentos(): boolean {
    return this.atendimentosPaciente.length > 0;
  }

  get hasPaciente(): boolean {
    return !this.form?.value.pacienteAnotacao && this.form?.value.pacienteId;
  }

  get statusAgendadoEncaixe(): boolean {
    return this.form?.value.status === AgendamentoStatusType.Agendado || this.form?.value.status === AgendamentoStatusType.Encaixe;
  }

  get statusEntradaAtendidoCancelado(): boolean {
    return this.form?.value.status === AgendamentoStatusType.LancadoEntrada || this.form?.value.status === AgendamentoStatusType.Atendido || this.form?.value.status === AgendamentoStatusType.Cancelado;
  }

  get isRecorrenciaAtiva(): boolean {
    if (!this.usaAgendamentoRecorrente) return false;

    if (this.form.value.tipo === AgendamentoType.Agendamento
      || this.form.value.tipo === AgendamentoType.Recorrente) {
      return true;
    }

    return false;
  }


  async telaRecorrencia() {

    const model: AgendamentoModel = Object.assign({}, this.form.value);

    if (!model.pacienteId || model.pacienteAnotacao) {
      await this.commonService.mensagem("Atenção", "Informe um paciente cadastrado para criar recorrência", "info");
      return;
    }

    let modelRecorr: AgendamentoRecorrenteModel;
    let novo = true;

    if (model.tipo === AgendamentoType.Recorrente) {
      modelRecorr = await this.recorrenteService.getById(model.agendamentoRecorrenteId).toPromise();
      novo = false;
    } else {

      let data = moment(model.data).toDate();

      modelRecorr = new AgendamentoRecorrenteModel();
      modelRecorr.id = this.commonService.newGuid();
      modelRecorr.dataInclusao = new Date();
      modelRecorr.pacienteId = model.pacienteId;
      modelRecorr.convenioId = model.convenioId;
      modelRecorr.profissionalRecursoId = model.profissionalRecursoId;
      modelRecorr.classificacaoId = model.classificacaoId;
      modelRecorr.periodo = RecorrenciaPeriodoType.Semana2;
      modelRecorr.totalItens = 0;
      modelRecorr.dataInicial = model.data;
      modelRecorr.diaSemana = data.getDay();
      modelRecorr.horario = model.horario;
    }

    const configRecorr = {
      recursoDto: this.recursos.find(x => x.value === model.profissionalRecursoId),
      agendamentoId: model.id,
      novo: novo,
      detailMode: this.detailMode,
      model: modelRecorr,
      paciente: this.paciente,
      convenios: this.convenios,
      classificacoes: this.classificacoes,
    };

    this.agendaRecorrenteModal.show(configRecorr);
  }

  onRecorrenciaResponse(id: string) {
    if (!this.detailMode) {
      this.isBusy = false;
      this.close();
      this.modalSave.emit();
    }
  }

}
