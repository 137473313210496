<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
	[config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-xl">
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Componente</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<h4 class="card-inside-title">Produto</h4>
					<div class="row">
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Código</mat-label>
								<input matInput formControlName="codigoItem" type="text" maxlength="20" />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Nome</mat-label>
								<input matInput formControlName="nome" type="text" maxlength="60" />
								<mat-error></mat-error>
							</mat-form-field>
						</div>						
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Quantidade</mat-label>
								<input matInput formControlName="quantidade" type="number" />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Tipo</mat-label>
								<mat-select formControlName="tipoItem">
									<mat-option *ngFor="let componenteSistema of typeService.componentesSistema" [value]="componenteSistema.value">{{componenteSistema.text}}</mat-option>
								</mat-select>								
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>