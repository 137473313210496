import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { AreaAtuacaoMedModel, EspecialidadeMedModel } from '../domain/models';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AreaAtuacaoMedModalComponent } from './area-atuacao-med-modal/area-atuacao-med-modal.component';
import { EspecialidadeMedService, AreaAtuacaoMedService } from '../domain/services';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-area-atuacao-med',
  templateUrl: './area-atuacao-med.component.html'
})
export class AreaAtuacaoMedComponent extends BaseCrudComponent<AreaAtuacaoMedModel> implements OnInit {
  @ViewChild('areaAtuacaoMedModal') modal: AreaAtuacaoMedModalComponent;

  especialidadesMed: EspecialidadeMedModel[];
  listaAreas: AreaAtuacaoMedModel[];

  searchEspecialidades = new ModelSearch('especialidadeMed.id', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'ordem' }, 
    { data: 'especialidadeMed.nome' }, 
    { data: 'nome' }
  ];

  constructor(
    injector: Injector,
    readonly areaAtuacaoMedService: AreaAtuacaoMedService,
    readonly especialidadeMedService: EspecialidadeMedService
  ) {
    super(injector, areaAtuacaoMedService);
  }

  ngOnInit() {
    super.ngOnInit();    
    this.especialidadeMedService.getAll().subscribe(especialidadesMed => this.especialidadesMed = especialidadesMed);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchEspecialidades.searchTerm) && searches.push(this.searchEspecialidades);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }  

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listaAreas, event.previousIndex, event.currentIndex);
    const ids = this.listaAreas.map(x => x.id);
    this.areaAtuacaoMedService.updateOrdens(ids).subscribe();
  }

  atualizaOrdem() {
    this.areaAtuacaoMedService.getAll().subscribe(lista => this.listaAreas = lista);
  }

  getEspecialidadeNome(especialidadeId: string): string {
    return this.especialidadesMed.find(x => x.id === especialidadeId).nome;
  }
}
