import { OnInit, ViewChild, Injector, Directive } from '@angular/core';
import { Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';
import { PessoaType } from 'projects/ProjetoBaseAngular/domain/types';
import { CidadeService, EstadoService } from 'projects/ProjetoBaseAngular/domain/services';
import { EstadoModel, CidadeModel, ImagemModel, BaseEntidadePessoaModel, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from '../../galeria/imagens/imagem-select-modal/imagem-select-modal.component';

@Directive()
export abstract class BaseEntidadePessoaModalComponent<TEntidadePessoaModel extends BaseEntidadePessoaModel> extends BaseCrudModalComponent<TEntidadePessoaModel> implements OnInit {
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  estados: EstadoModel[];
  cidades: CidadeModel[];

  cnpjCpfMask = '';
  cnpjCpfPlaceholder = '';

  readonly pessoaJuridica = PessoaType.Juridica;
  readonly pessoaFisica = PessoaType.Fisica;

  protected readonly entidadePessoaService: BaseEntidadePessoaService<TEntidadePessoaModel>;
  protected readonly estadoService: EstadoService;
  protected readonly cidadeService: CidadeService;

  constructor(
    injector: Injector,
    entidadePessoaService: BaseEntidadePessoaService<TEntidadePessoaModel>
  ) {
    super(injector, entidadePessoaService);
    this.entidadePessoaService = entidadePessoaService;
    this.estadoService = injector.get<EstadoService>(EstadoService);
    this.cidadeService = injector.get<CidadeService>(CidadeService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected init() {
    super.init();
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  protected initializeForm(entidadePessoa: TEntidadePessoaModel) {
    this.form = this.formBuilder.group({
      id: entidadePessoa.id,
      dataInclusao: [entidadePessoa.dataInclusao, Validators.required],
      dataAlteracao: entidadePessoa.dataAlteracao,
      registroAtivo: entidadePessoa.registroAtivo,
      
      observacoes: [entidadePessoa.observacoes, Validators.maxLength(500)],
      pessoa: this.formBuilder.group({
        id: entidadePessoa.pessoa.id,
        dataInclusao: [entidadePessoa.pessoa.dataInclusao, Validators.required],
        dataAlteracao: entidadePessoa.pessoa.dataAlteracao,
        codigo: [entidadePessoa.pessoa.codigo],
        tipoPessoa: [entidadePessoa.pessoa.tipoPessoa, Validators.required],
        cnpjCpf: [entidadePessoa.pessoa.cnpjCpf, [
          Validators.required,
          Validators.maxLength(18)]
        ],
        iestadualRg: [entidadePessoa.pessoa.iestadualRg, [
          Validators.maxLength(18)]
        ],
        imunicipal: [entidadePessoa.pessoa.imunicipal, [
          Validators.maxLength(18)]
        ],
        nomeFantasia: [entidadePessoa.pessoa.nomeFantasia, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(60)]
        ],
        razaoSocial: [entidadePessoa.pessoa.razaoSocial, [
          Validators.maxLength(60)]
        ],
        sexo: [entidadePessoa.pessoa.sexo, Validators.required],
        site: [entidadePessoa.pessoa.site, [
          Validators.maxLength(50)]
        ],
        dataNascimento: [this.fromJsonDate(entidadePessoa.pessoa.dataNascimento)],
        imagemUrl: [entidadePessoa.pessoa.imagemUrl],
        imagemUrlBase: [entidadePessoa.pessoa.imagemUrlBase],
        imagemPath: [entidadePessoa.pessoa.imagemPath],
        celular: [entidadePessoa.pessoa.celular, [
          Validators.maxLength(15)]
        ],
        telefone: [entidadePessoa.pessoa.telefone, [
          Validators.maxLength(15)]
        ],
        email: [entidadePessoa.pessoa.email, [
          Validators.maxLength(50)]
        ],
        ruaAvenida: [entidadePessoa.pessoa.ruaAvenida, [
          Validators.maxLength(50)]
        ],
        numero: [entidadePessoa.pessoa.numero, Validators.maxLength(10)],
        complemento: [entidadePessoa.pessoa.complemento, Validators.maxLength(50)],
        bairro: [entidadePessoa.pessoa.bairro, Validators.maxLength(50)],
        cep: [entidadePessoa.pessoa.cep, Validators.maxLength(10)],
        estadoId: entidadePessoa.pessoa.estadoId,
        cidadeId: entidadePessoa.pessoa.cidadeId
      })
    });
    this.changeTipoPessoa(entidadePessoa.pessoa.tipoPessoa);
  }

  protected newModel(): TEntidadePessoaModel {
    const model = super.newModel();
    model.pessoa = new PessoaModel();
    model.pessoa.codigo = 0;
    return model;
  }

  getIdadePessoa(data: Date): string {

    if (!data) {
      return '';
    }

    return this.commonService.getIdade(data);
  }

  changeTipoPessoa(pessoaType: PessoaType) {
    switch (Number(pessoaType)) {
      case PessoaType.Juridica:
        this.cnpjCpfMask = '00.000.000/0000-00';
        this.cnpjCpfPlaceholder = '00.000.000/0000-00';
        break;

      case PessoaType.Fisica:
        this.cnpjCpfMask = '000.000.000-00';
        this.cnpjCpfPlaceholder = '000.000.000-00';
        break;
    }
  }

  async changeCnpjCpf(cnpjCpf: string) {
    if (cnpjCpf && cnpjCpf !== this.oldModel?.pessoa.cnpjCpf) {
      try {
        const pessoa = await this.entidadePessoaService.getByCnpjCpf(cnpjCpf.replace(/[^0-9]/g, '')).toPromise();  
        if (this.newItem && pessoa.id) {
          Swal.fire({
            title: 'Cadastro encontrado!',
            html: `Já existe uma pessoa cadastrada com este do documento em "${pessoa.tipoEntidade}". Deseja continuar?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {
              this.form.patchValue({
                id: pessoa.id,
                pessoa
              });

              if (pessoa.estadoId) {
                this.changeEstado(pessoa.estadoId);
              }
            } else {
              this.form.patchValue({ pessoa: { cnpjCpf: null } });
              setTimeout(() => {
                const htmlElement: any = document.querySelector('input[formcontrolname="cnpjCpf"]');
                htmlElement.focus();
              }, 500);
            }
          });
        }
      } catch (errors) {
        if (this.newItem) {
          this.form.patchValue({ pessoa: { cnpjCpf: null } });
        } else {
          this.form.patchValue({ pessoa: { tipoPessoa: this.oldModel?.pessoa, cnpjCpf: this.oldModel?.pessoa.cnpjCpf } });
        }
        Swal.fire('Erro', errors[0], 'error').then(() => {
          setTimeout(() => {
            const htmlElement: any = document.querySelector('input[formcontrolname="cnpjCpf"]');
            htmlElement.focus();
          }, 500);
        });
      }
    }
  }

  changeCep(cep: string) {
    if (cep) {
      this.commonService.queryPostalCode(cep).subscribe((endereco: any) => {
        this.form.patchValue({
          pessoa: {
            ruaAvenida: endereco.logradouro,
            bairro: endereco.bairro
          }
        });

        const estadoId = this.estados.find(x => x.uf === endereco.uf).id;
        this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => {
          this.cidades = cidades;
          const cidadeId = this.cidades.find(x => x.codigoIbge === endereco.ibge).id;

          this.form.patchValue({
            pessoa: { estadoId, cidadeId }
          });
        });
      });
    }
  }

  changeEstado(estadoId: string) {
    this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
  }

  beforeSave(model: TEntidadePessoaModel) {
    if (this.newItem && model.pessoa.id === null) {
      model.pessoa.id = undefined;
    }

    return super.beforeSave(model);
  }

  beforeShowCreate(model: TEntidadePessoaModel) {
    model = super.beforeShowCreate(model);

    if (!model.pessoa) {
      model.pessoa = new PessoaModel();
      model.pessoa.tipoPessoa = PessoaType.Fisica;
    } else {
      this.changeCnpjCpf(model.pessoa.cnpjCpf);
    }

    model.pessoa.codigo = 0;
    model.pessoa.dataInclusao = new Date();
    return model;
  }

  beforeShowEdit(model: TEntidadePessoaModel) {
    model.pessoa.estadoId && this.changeEstado(model.pessoa.estadoId);
    model.dataAlteracao = new Date();
    return super.beforeShowEdit(model);
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(500);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      pessoa: {
        imagemUrl: imagem.url,
        imagemUrlBase: imagem.urlBase,
        imagemPath: imagem.path
      }
    });
  }

  deselectImage() {
    this.form.patchValue({
      pessoa: {
        imagemUrl: null,
        imagemUrlBase: null,
        imagemPath: null
      }
    });
  }
}
