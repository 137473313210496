import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ReportModule } from '../../report/report.module';
import { RelAtendimentosChatbotComponent } from './rel-atendimentos-chatbot.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    SharedModule,
    ReportModule,
    ChartsModule,
  ],
  declarations: [
    RelAtendimentosChatbotComponent,
  ]
})
export class RelAtendimentosChatbotModule { }
