import { Injectable, Injector } from '@angular/core';
import { PaginaAcaoModel } from '../../models/controle-acesso/pagina-acao-model';
import { BaseCrudService } from '../base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PaginaModel } from '../../models/controle-acesso/pagina-model';

@Injectable({
  providedIn: 'root'
})
export class PaginaAcaoService extends BaseCrudService<PaginaAcaoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'PaginaAcao';
  }

  getByPaginaId(paginaId: string): Observable<PaginaAcaoModel[]> {
    return this
      .httpClient
      .get<PaginaAcaoModel[]>(`${this.getApiUrl()}${this.getController()}/GetByPaginaId/${paginaId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
