import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { AtendimentoService } from '../../domain/services';
import { AtestadoConvertMaskDto, AtestadoDto } from '../../domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import * as moment from 'moment';

@Component({
  selector: 'app-atendimento-atestado-historico-modal',
  templateUrl: './atendimento-atestado-historico-modal.component.html'
})

export class AtendimentoAtestadoHistoricoModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  @Input('atendimentoId') atendimentoId: string;
  @Input('profissionalId') profissionalId: string;
  @Input('pacienteId') pacienteId: string;
  @Input('detailMode') detailMode: boolean;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() cloneAtestado: EventEmitter<AtestadoDto> = new EventEmitter<AtestadoDto>();

  listaAtestadoDto: any[] = [];

  constructor(
    injector: Injector,
    readonly service: AtendimentoService,
    readonly pacienteService: PacienteService,
    readonly typesService: TypesService,
    readonly sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  async show() {
    this.autoSave.emit();

    this.modal.show();
    this.commonService.spinnerOpen("Pesquisando Histórico de Atestados...");
    const lista = await this.service.getAtestadosByPacienteId(this.pacienteId).toPromise();
    if (this.atendimentoId){
      this.listaAtestadoDto = lista.filter(x => x.atendimentoId !== this.atendimentoId);
    } else {
      this.listaAtestadoDto = lista;
    }

    const paciente = await this.pacienteService.getById(this.pacienteId).toPromise();

    this.listaAtestadoDto.forEach(item => {
      
      let data = moment(item.data).format('DD/MM/YYYY');
      const convMaskDto: AtestadoConvertMaskDto = {
        pacienteCpf: paciente.pessoa.cnpjCpf ?? "", 
        pacienteNome: paciente.pessoa.nomeFantasia, 
        profissionalNome: item.profissionalNome, 
        profissionalCrm: item.profissionalCrm,
        profissionalCrn: item.profissionalCrn,
        profissionalRqe: item.profissionalRqe,
        data: data};
  
      let texto = this.service.atestadoTextmaskToText(item.atestado, convMaskDto);
      //injetando campo dinâmico para apresentação
      item['descricaoHtml'] = this.commonService.formatPlaintextToHtml(texto);
    });

    this.commonService.spinnerClose();
  }

  async clonar(atestado: any) {
    let mensaTitulo = "";
    let mensaCorpo = "Clonar atestado selecionado?";
    let mensaTipo: any = "question";

    if (atestado.profissionalId !== this.profissionalId){
      mensaTitulo = `Atestado com origem profissional: ${atestado.nomeProfissional}`;
      mensaTipo = "warning";
    }

    let result = await this.commonService.mensagemConfirmacao(mensaTitulo, mensaCorpo, mensaTipo);
    if (result) {
      this.close();
      this.cloneAtestado.emit(atestado);
    }
  }

  close() {
    this.modal.hide();
  }
}
