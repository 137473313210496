import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';

import { TipoParceiroModalComponent } from './tipo-parceiro-modal/tipo-parceiro-modal.component';
import { TipoParceiroModel } from '../domain/models';
import { TipoParceiroService } from '../domain/services';

@Component({
  selector: 'app-tipo-parceiro',
  templateUrl: './tipos-parceiros.component.html',
})
export class TiposParceirosComponent extends BaseCrudComponent<TipoParceiroModel> implements OnInit {
  @ViewChild('tipoParceiroModal') modal: TipoParceiroModalComponent;

  selectColumns = [
    { data: 'nome' }
  ];

  constructor(
    injector: Injector,
    readonly tipoParceiroService: TipoParceiroService
  ) {
    super(injector, tipoParceiroService);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
