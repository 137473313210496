import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EspecialidadeMedComponent } from './especialidade-med.component';
import { EspecialidadeMedModalComponent } from './especialidade-med-modal/especialidade-med-modal.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { IconeModule } from '../../Icone/icone/icone.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule,
    IconeModule
  ],
  declarations: [
    EspecialidadeMedComponent,
    EspecialidadeMedModalComponent
  ]
})
export class EspecialidadeMedModule { }
