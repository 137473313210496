import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { EntregaExameModalComponent } from './entrega-exame-modal/entrega-exame-modal.component';
import { EntregaExameModel } from '../domain/models';
import { EntregaExameService } from '../domain/services';
import { DataTablesRequest, ModelSearch, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { PacienteModel } from '../../paciente/domain/models';
import { PacienteService } from '../../paciente/domain/services';
import * as moment from 'moment';

@Component({
  selector: 'app-entrega-exame',
  templateUrl: './entrega-exame.component.html'
})
export class EntregaExameComponent extends BaseCrudComponent<EntregaExameModel> implements OnInit {
  @ViewChild('modal') modal: EntregaExameModalComponent;

  searchStatus = new ModelSearch('entregue', OptionSearchType.Equals, SearchType.Boolean, false);    
  searchPaciente = new ModelSearch('atendimento.pacienteId', OptionSearchType.Equals, SearchType.Guid);  
  searchProcedimento = new ModelSearch('procedimentoId', OptionSearchType.Equals, SearchType.Guid);  
  searchDataInicial = new ModelSearch('dataInclusao', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime, this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinal = new ModelSearch('dataInclusao', OptionSearchType.LessThanOrEqual, SearchType.DateTime, this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)));

  paciente: PacienteModel;

  selectColumns = [ 
    { data: 'dataInclusao'},
    { data: 'atendimento.codigoDocMovto'},
    { data: 'atendimento.paciente.pessoa.nomeFantasia as pacienteNome'},
    { data: 'procedimento.nome'},
    { data: 'entregue'},
    { data: 'dataEntrega'},
    { data: 'entreguePara'},
    { data: 'observacoes'},
  ];

  constructor(
    injector: Injector,
    readonly service: EntregaExameService,
    readonly pacienteService: PacienteService
  ) {
    super(injector, service);
    this.onPacienteResponse(null);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchStatus.searchTerm !== null) && searches.push(this.searchStatus);
    (this.searchPaciente.searchTerm) && searches.push(this.searchPaciente);
    (this.searchProcedimento.searchTerm) && searches.push(this.searchProcedimento);
    if (this.searchDataInicial.searchTerm) {
      const searchDataInicial = Object.assign({}, this.searchDataInicial);
      searchDataInicial.searchTerm = `${this.fromJsonDate(searchDataInicial.searchTerm)} 00:00`;
      searches.push(searchDataInicial);
    }
    if (this.searchDataFinal.searchTerm) {
      const searchDataFinal = Object.assign({}, this.searchDataFinal);
      searchDataFinal.searchTerm = `${this.fromJsonDate(searchDataFinal.searchTerm)} 23:59`;
      searches.push(searchDataFinal);
    }

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }  

  ngOnInit() {
    super.ngOnInit();    
  }

  onPacienteResponse(pacienteId: string) {    
    this.searchPaciente.searchTerm = pacienteId;
    if (!pacienteId) {      
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
      this.filter();
    }
    else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;
        this.filter();
      });
    }        
  }
}
