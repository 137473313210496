<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
	[config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-xl">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Questionário</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<ul class="nav nav-tabs">
						<li class="active"><a class="nav-link active" data-toggle="tab" id="navPrincipal" href="#tabPrincipal"
								role="tab" aria-selected="true">Principal</a>
						</li>
						<li><a class="nav-link" data-toggle="tab" id="navApresentacao" href="#tabApresentacao" role="tab"
								aria-selected="false">Apresentação</a>
						</li>
					</ul>
					<div class="tab-content" id="nav-tabContent">
						<div class="tab-pane fade in active" id="tabPrincipal" role="tabpanel" aria-labelledby="navPrincipal">
							<div class="row">
								<div class="col-md-5">
									<mat-form-field>
										<mat-label>Título</mat-label>
										<input matInput formControlName="titulo" maxlength="100" required #focus />
										<mat-error>Informe um Título válido.</mat-error>
									</mat-form-field>
								</div>
								<div class="col-md-5">
									<mat-form-field>
										<mat-label>Pergunta Filtro</mat-label>
										<input matInput formControlName="questaoFiltro" maxlength="100" required />
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field>
										<mat-label>Status</mat-label>
										<input matInput value={{typesService.getStatusPublicacaoType(form.value.statusPublicacao)}}
											type="text" disabled />
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<mat-form-field>
										<mat-label>Descrição Externa</mat-label>
										<input matInput formControlName="descricaoExterna" maxlength="200" />
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field>
										<mat-label>Descrição Interna</mat-label>
										<input matInput formControlName="descricaoInterna" maxlength="200" />
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<hr>
								</div>
								<div class="col-md-4 text-center" style="margin-top: 10px; margin-bottom: 10px;">
									<strong>Classificação de Respostas (Valores mínimos por cor)</strong>
								</div>
								<div class="col-md-4">
									<hr>
								</div>
							</div>
							<div class="row">
								<div class="col-md-2">
									<mat-form-field>
										<mat-label>Pontuação Verde</mat-label>
										<input matInput formControlName="pontuacaoVerde" type="text" required />
										<mat-icon matPrefix
											[style.color]="typesService.getPesquisaStatusCorTypeColorHex(statusCorType.Verde)">circle
										</mat-icon>
										<mat-error>Informe um valor válido.</mat-error>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field>
										<mat-label>Etiqueta</mat-label>
										<input matInput formControlName="nomeVerde" type="text" required />
										<mat-error>Informe uma etiqueta válida.</mat-error>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field>
										<mat-label>Pontuação Laranja</mat-label>
										<input matInput formControlName="pontuacaoLaranja" type="text" required />
										<mat-icon matPrefix
											[style.color]="typesService.getPesquisaStatusCorTypeColorHex(statusCorType.Laranja)">circle
										</mat-icon>
										<mat-error>Informe um valor válido.</mat-error>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field>
										<mat-label>Etiqueta</mat-label>
										<input matInput formControlName="nomeLaranja" type="text" required />
										<mat-error>Informe uma etiqueta válida.</mat-error>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field>
										<mat-label>Pontuação Vermelha</mat-label>
										<input matInput formControlName="pontuacaoVermelha" type="text" required />
										<mat-icon matPrefix
											[style.color]="typesService.getPesquisaStatusCorTypeColorHex(statusCorType.Vermelho)">circle
										</mat-icon>
										<mat-error>Informe um valor válido.</mat-error>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field>
										<mat-label>Etiqueta</mat-label>
										<input matInput formControlName="nomeVermelha" type="text" required />
										<mat-error>Informe uma etiqueta válida.</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<hr>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-tab-group animationDuration="0ms">
										<mat-tab label="Filtros">
											<ng-template matTabContent>
												<br>
												<div class="container-fluid">
													<app-questionario-filtro #questionarioFiltro [questionarioId]="form.value.id"
														(autoSave)="save(true)">
													</app-questionario-filtro>
												</div>
											</ng-template>
										</mat-tab>
										<mat-tab label="Grupos de Questões">
											<ng-template matTabContent>
												<br>
												<div class="container-fluid">
													<app-grupo-questao #grupoQuestao [questionarioId]="form.value.id" (autoSave)="save(true)">
													</app-grupo-questao>
												</div>
											</ng-template>
										</mat-tab>
									</mat-tab-group>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="tabApresentacao" role="tabpanel" aria-labelledby="navApresentacao">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group align-center">
										<h4 class="card-inside-title">Imagem Padrão</h4>
										<img src="{{form.value.imagem1Url}}" *ngIf="form.value.imagem1Url"
											style="height: 350px; width: 100%; object-fit: contain;">
										<button mat-icon-button type="button" (click)="showSelectImage(1)" matTooltip="Selecionar Imagem">
											<mat-icon>add_photo_alternate</mat-icon>
										</button>
										<button mat-icon-button type="button" [disabled]="!form.value.imagem1Url" (click)="deselectImage(1)"
											matTooltip="Desvincular Imagem">
											<mat-icon>cancel</mat-icon>
										</button>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group align-center">
										<h4 class="card-inside-title">Imagem Mobile</h4>
										<img src="{{form.value.imagem2Url}}" *ngIf="form.value.imagem2Url"
											style="height: 350px; width: 100%; object-fit: contain;">
										<button mat-icon-button type="button" (click)="showSelectImage(2)" matTooltip="Selecionar Imagem">
											<mat-icon>add_photo_alternate</mat-icon>
										</button>
										<button mat-icon-button type="button" [disabled]="!form.value.imagem1Url" (click)="deselectImage(2)"
											matTooltip="Desvincular Imagem">
											<mat-icon>cancel</mat-icon>
										</button>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<mat-form-field>
										<mat-label>Posição Card Pesquisa</mat-label>
										<mat-select formControlName="posicaoCard">
											<mat-option *ngFor="let opcao of typesService.questionarioPosicaoCardType" [value]="opcao.value">
												{{opcao.text}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponseImagem($event)"></app-imagem-select-modal>