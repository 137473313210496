import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { TypesService } from 'projects/admin/src/domain/services';
import { UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { AtendimentoModel, EntregaExameModel, ProcedimentoModel } from '../../domain/models';
import { AtendimentoService, EntregaExameService, ProcedimentoService } from '../../domain/services';

@Component({
  selector: 'app-entrega-exame-print',
  templateUrl: './entrega-exame-print.component.html'
})
export class EntregaExamePrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  entregaExame: EntregaExameModel;  
  usuarioSolicitante: UsuarioModel;  
  atendimento: AtendimentoModel;
  paciente: PacienteModel;
  procedimento: ProcedimentoModel;

  constructor(
    readonly atendimentoService: AtendimentoService,    
    readonly pacienteService: PacienteService,    
    readonly procedimentoService: ProcedimentoService,    
    readonly entregaExameService: EntregaExameService,    
    readonly usuarioService: UsuarioService,    
    readonly printService: PrintService,
    readonly typesService: TypesService
  ) { }

  ngOnInit() {
  }

  async print(entregaExameId: string) {
    this.entregaExame = await this.entregaExameService.getById(entregaExameId).toPromise();    
    this.usuarioSolicitante = await this.usuarioService.getById(this.entregaExame.usuarioIdAlteracao).toPromise();    
    this.atendimento = await this.atendimentoService.getById(this.entregaExame.atendimentoId).toPromise();    
    this.paciente = await this.pacienteService.getById(this.atendimento.pacienteId).toPromise();    
    this.procedimento = await this.procedimentoService.getById(this.entregaExame.procedimentoId).toPromise();    

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('EntregaExame', content);
    }, 500);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }
}
