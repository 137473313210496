import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AtestadoModeloModel } from '../../domain/models';
import { AtestadoService } from '../../domain/services/atestado.service';

@Component({
  selector: 'app-atestado-modal',
  templateUrl: './atestado-modal.component.html'
})
export class AtestadoModalComponent extends BaseCrudModalComponent<AtestadoModeloModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;  

  constructor(
    injector: Injector,
    service: AtestadoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: AtestadoModeloModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      nome: [model.nome, [
        Validators.maxLength(50),
        Validators.required
      ]],
      texto: [model.texto, [
        Validators.required
      ]],
      cids: [model.cids, [
        Validators.maxLength(100)
      ]],
    });
  }
}
