<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl" *ngIf="pacienteId">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Laudos - {{pacienteNome}}</h2>
      </div>
      <div class="modal-body table-responsive">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Status do Laudo</mat-label>
              <mat-select [(ngModel)]="searchStatus.searchTerm" (selectionChange)="filter()">
                <mat-option value="-1">Todos</mat-option>
                <mat-option *ngFor="let status of typesService.laudoStatusType" [value]="status.value">{{status.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
          class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Data</th>
              <th class="text-center">Profissional Solicitante</th>
              <th class="text-center">Profissional Laudante</th>
              <th class="text-center">Paciente</th>
              <th class="text-center">Convênio</th>
              <th class="text-center">Procedimento</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models" (click)="showItem(item)" style="cursor: pointer;">
              <td>{{item.dataInclusao | date: 'dd/MM/yyyy'}}</td>
              <td [innerHTML]="item.profissionalSolicitantePessoaNomeFantasia"></td>
              <td [innerHTML]="item.profissionalLaudantePessoaNomeFantasia"></td>
              <td [innerHTML]="item.pacientePessoaNomeFantasia"></td>
              <td [innerHTML]="item.convenioNome"></td>
              <td [innerHTML]="item.procedimentoNome"></td>              
              <td>
                <span class="badge" [style.backgroundColor]="typesService.getLaudoStatusTypeColor(item.status)">
                  {{typesService.getLaudoStatusType(item.status)}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-laudo-modal #modal (modalSave)="onResponse($event)"></app-laudo-modal>