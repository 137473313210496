<div #relatorio class="visible-print">
  <table class="report-container" *ngIf="captura">
    <thead class="report-header">
      <tr>
        <th>          
          <!-- <app-report-header></app-report-header>           -->
          <div class="row">
            <div class="col-md-12">
              Paciente: {{paciente.pessoa.nomeFantasia}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              Data Atendimento: {{captura.dataInclusao | date: 'dd/MM/yyyy'}}
            </div>
          </div>
        </th>        
      </tr>      
    </thead>
    <tfoot class="report-footer">
      <tr>
        <td>
          <!-- <app-report-footer></app-report-footer> -->
        </td>
      </tr>
    </tfoot>
    <tbody class="report-content">
      <tr>
        <td>
          <!-- <h4 class="text-center font-weight-bold">Capturas</h4> -->
          <hr>
          <div class="row" *ngIf="capturaImagens?.length > 0">            
            <div class="col-md-6 text-center" style="margin-bottom: 10px;" *ngFor="let imagem of capturaImagens">
              <div class="output">
                <img [src]="imagem.imagemUrl" style="width: 100%;">
              </div>
              <strong class="text-center" *ngIf="imprimirNomeImagem">{{imagem.procedimentoNome}}</strong>                
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>