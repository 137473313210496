import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { EquipamentoService } from '../../domain/services';
import { EquipamentoModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-equipamento-modal',
  templateUrl: './equipamento-modal.component.html'
})
export class EquipamentoModalComponent extends BaseCrudModalComponent<EquipamentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: EquipamentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: EquipamentoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: [this.fromJsonDate(model.dataAlteracao)],
      registroAtivo: model.registroAtivo,

      nomeApresentacao: [model.nomeApresentacao, Validators.maxLength(60)],
      preRequisitos:
        [model.preRequisitos, [
          Validators.maxLength(200)
        ]],
      capturaImagem: [model.capturaImagem, [
        Validators.required
      ]],
      usaConfirmacaoLote: [model.usaConfirmacaoLote, [
        Validators.required
      ]],
    });
  }

  protected newModel(): EquipamentoModel {
    const equipamento = super.newModel();
    equipamento.id = this.commonService.newGuid();
    equipamento.capturaImagem = false;

    return equipamento;
  }
}
