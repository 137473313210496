import { OnInit, Injector, ViewChild, Output, EventEmitter, Input, Directive } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { BaseCrudComponent } from './base-crud.component';
import { BaseModel } from '../domain/models/base/base-model';

@Directive()
export abstract class BaseSelectModalComponent<TModel extends BaseModel> extends BaseCrudComponent<TModel> implements OnInit {  
  @ViewChild('selectModal') selectModal: ModalDirective;
  
  @Input('id') id: string;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();  
  
  constructor(
    injector: Injector,
    service: BaseCrudService<TModel>
  ) {
    super(injector, service);    
  }  

  ngOnInit() {
    super.ngOnInit();
        
    if (this.id) {
      this.service.getById(this.id).subscribe(model => {
        this.select(model.id);
      });
    }
  }  

  showSelect() {
    this.filter();
    this.selectModal.show();    
  }  

  select(model: any) {
    this.modalClose.emit(model);
    this.selectModal.hide();    
  }

  close() {    
    this.selectModal.hide();
  }

  onResponse(id: string) {        
    if (id) {      
      this.select(id);
      return;      
    }
    super.onResponse(id);
  }

}
