<div mat-dialog-title>
  <button type="button" class="close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
  <h4>Laudo</h4>
</div>
<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Laudos/Obs/Conclusões">
      <ng-template matTabContent>
        <br>
        <button mat-flat-button color="primary" type="button" (click)="adicionarTexto()" style="margin-bottom: 10px;">Adicionar</button>                  
        <table class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Texto</th>
              <th class="text-center" width="100"></th>
            </tr>
          </thead>
          <tbody *ngIf="textos?.length != 0">
            <tr *ngFor="let item of textos">
              <td>{{item.texto}}</td>
              <td class="text-center">                  
                <button mat-icon-button type="button" (click)="removerTexto(item.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>                  
      </ng-template>
    </mat-tab>
    <mat-tab label="Resultado de Exames" *ngIf="respostas?.length > 0">
      <ng-template matTabContent>
        <br>        
        <table class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Pergunta</th>
              <th class="text-center" width="100"></th>
            </tr>
          </thead>
          <tbody *ngIf="respostas?.length != 0">
            <tr *ngFor="let item of respostas">
              <td><strong>{{item.pergunta}}</strong></td>
              <td>
                <input type="text" [(ngModel)]="item.resposta" currencyMask />
              </td>
            </tr>
          </tbody>
        </table>        
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" class="button-row" (click)="montarLaudo()">
    <span> Montar Resultado</span>
  </button>
  <button mat-stroked-button type="button" [routerLink]="" mat-dialog-close class="button-row">
    <span> Fechar</span>
  </button>
</mat-dialog-actions>