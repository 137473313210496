import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { CategoriaFinanceiraComponent } from './categoria-financeira.component';
import { CategoriaFinanceiraModalComponent } from './categoria-financeira-modal/categoria-financeira-modal.component';


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    CategoriaFinanceiraComponent,
    CategoriaFinanceiraModalComponent
  ]
})
export class CategoriaFinanceiraModule { }
