<div class="card">
  <div class="header">
    <h2>Filtro</h2>
  </div>
  <div class="body">
    <div class="row">      
      <div class="col-md-2 col-sm-6">
        <mat-form-field>
          <mat-label>Período de Inclusão</mat-label>
          <input matInput [(ngModel)]="searchDataInicial.searchTerm" type="date" max="2999-12-31">
        </mat-form-field>
      </div>
      <div class="col-md-2 col-sm-6">
        <mat-form-field>
          <input matInput [(ngModel)]="searchDataFinal.searchTerm" type="date" max="2999-12-31">
        </mat-form-field>
      </div>             
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Status da Captura</mat-label>
          <mat-select [(ngModel)]="searchStatus.searchTerm">
            <mat-option *ngFor="let status of typesService.capturaStatusType" [value]="status.value">{{status.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-form-field>
          <mat-label>Equipamento</mat-label>
          <mat-select [(ngModel)]="searchEquipamento.searchTerm">
            <mat-option *ngFor="let equipamento of equipamentos" [value]="equipamento.id">{{equipamento.nomeApresentacao}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Cód./CPF Paciente</mat-label>
          <input id="cpfPaciente" #cpf matInput [(value)]="paciente.pessoa.cnpjCpf" type="text"
            (keyup.enter)="pacienteSelect.changeDocumento(cpf.value)"
            (blur)="pacienteSelect.changeDocumento($event.target.value)" />
          <button matSuffix mat-icon-button type="button" (click)="pacienteSelect.showSelect()"
            matTooltip="Selecionar Paciente">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-form-field>
          <mat-label>Paciente</mat-label>
          <input matInput disabled [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia" type="text" />
          <button [disabled]="!paciente?.id" matSuffix mat-icon-button type="button"
            (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <mat-error></mat-error>
        </mat-form-field>
      </div>     
    </div>
  </div>
  <div class="footer">
    <button mat-flat-button color="primary" (click)="filter()">
      <span>Filtrar</span>
    </button>
  </div>
</div>
<div class="card">
  <div class="header">
    <h2>Capturas</h2>
  </div>
  <div class="body table-responsive">    
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center">Data</th>
          <th class="text-center">Atendimento</th>
          <th class="text-center">Paciente</th>
          <th class="text-center">Convênio</th>
          <th class="text-center">Status</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="models?.length != 0">
        <tr *ngFor="let item of models">
          <td>{{item.dataInclusao | date: 'dd/MM/yyyy'}}</td>
          <td [innerHTML]="item.atendimentoCodigoDocMovto"></td>
          <td [innerHTML]="item.atendimentoPacienteNome"></td>
          <td [innerHTML]="item.atendimentoConvenioNome"></td>
          <td>
            <span class="badge" [style.backgroundColor]="typesService.getCapturaStatusTypeColor(item.status)">
              {{typesService.getCapturaStatusType(item.status)}}
            </span>
          </td>
          <td class="text-center">
            <button *ngIf="item.status===typesService.CapturaStatusType.Capturado" mat-icon-button type="button"
              (click)="capturaPrint.print(item.id)" matTooltip="Imprimir Laudo">
              <mat-icon>print</mat-icon>
            </button>
            <button *ngIf="item.status!==typesService.CapturaStatusType.Capturado" [disabled]="!isAllowed('Update')"
              mat-icon-button type="button" (click)="edit(item)" matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="item.status===typesService.CapturaStatusType.Capturado" [disabled]="!isAllowed('Read')"
              mat-icon-button type="button" (click)="detail(item)" matTooltip="Detalhes">
              <mat-icon>description</mat-icon>
            </button>            
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-captura-modal #modal (modalSave)="onResponse($event)"></app-captura-modal>
<app-captura-print #capturaPrint></app-captura-print>
<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>