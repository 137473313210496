import { Component, Injector, ViewEncapsulation, OnInit } from '@angular/core';
import { ChangeUiThemeInput } from 'projects/ProjetoBaseAngular/shared/service-proxies/service-proxies';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';

class UiThemeInfo {
    constructor(
        public name: string,
        public cssClass: string
    ) { }
}

@Component({
    templateUrl: './right-sidebar.component.html',
    selector: 'right-sidebar',
    encapsulation: ViewEncapsulation.None
})
export class RightSideBarComponent extends BaseComponent implements OnInit {

    themes: UiThemeInfo[] = [
        new UiThemeInfo("Padrão", "default"),
        new UiThemeInfo('Vermelho', 'red'),
        new UiThemeInfo('Rosa', 'pink'),
        new UiThemeInfo('Roxo', 'purple'),
        new UiThemeInfo('Rocho Escuro', 'deep-purple'),
        new UiThemeInfo('Índigo', 'indigo'),
        new UiThemeInfo('Azul', 'blue'),
        new UiThemeInfo('Azul Claro', 'light-blue'),
        new UiThemeInfo('Ciano', 'cyan'),
        new UiThemeInfo('Cerceta', 'teal'),
        new UiThemeInfo('Verde', 'green'),
        new UiThemeInfo('Verde Claro', 'light-green'),
        new UiThemeInfo('Lima', 'lime'),
        new UiThemeInfo('Amarelo', 'yellow'),
        new UiThemeInfo('Âmbar', 'amber'),
        new UiThemeInfo('Laranja', 'orange'),
        new UiThemeInfo('Laranja Escuro', 'deep-orange'),
        new UiThemeInfo('Marrom', 'brown'),
        new UiThemeInfo('Cinzento', 'grey'),
        new UiThemeInfo('Acinzentado', 'blue-grey'),
        new UiThemeInfo('Preto', 'black')
    ];

    selectedThemeCssClass = 'default';

    constructor(
        injector: Injector,        
    ) {
        super(injector);
    }

    ngOnInit(): void {        
        const user = this.accountService.getUser();
        if (user) {
            this.selectedThemeCssClass = user.temaAtual || this.selectedThemeCssClass;
        }        
        $('body').addClass('theme-' + this.selectedThemeCssClass);
    }

    setTheme(theme: UiThemeInfo): void {
        const input = new ChangeUiThemeInput();
        input.theme = theme.cssClass;
        const user = this.accountService.getUser();
        this.accountService.changeUiTheme(user.id, theme.cssClass).subscribe(() => {
            const $body = $('body');

            $('.right-sidebar .demo-choose-skin li').removeClass('active');
            $body.removeClass('theme-' + this.selectedThemeCssClass);
            $('.right-sidebar .demo-choose-skin li div.' + theme.cssClass).closest('li').addClass('active');
            $body.addClass('theme-' + theme.cssClass);

            this.selectedThemeCssClass = theme.cssClass;

            user.temaAtual = theme.cssClass;
            localStorage.setItem('app.user', JSON.stringify(user));
        });
    }

    logout() {
        this.accountService.logout();
        this.router.navigate(['/account/login']);
    }
}

