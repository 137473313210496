import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ReportModule } from '../../report/report.module';
import { TermoListModalComponent } from './termo-list-modal/termo-list-modal.component';
import { TermoModalComponent } from './termo-modal/termo-modal.component';
import { TermoComponent } from './termo.component';

@NgModule({
  imports: [    
    SharedModule,
    ReportModule
  ],
  declarations: [
    TermoComponent,
    TermoModalComponent,
    TermoListModalComponent
  ],
  exports: [
    TermoListModalComponent
  ]
})
export class TermoModule { }
