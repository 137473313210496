<div class="menu">
    <ul class="list">
        <li class="header">Administrador do Sistema</li>
        <ng-template ngFor let-menuItem [ngForOf]="menuItems" let-mainMenuItemIndex="index">            
            <li routerLinkActive="active">
                <!-- route name -->
                <a (click)="onClick()" [class.disabled]="isDisabled(menuItem)" [routerLink]="[menuItem.route]" *ngIf="!menuItem.items.length && menuItem.route.indexOf('http') != 0">
                    <i *ngIf="menuItem.icon" class="material-icons">{{menuItem.icon}}</i>
                    <i *ngIf="menuItem.faIcon" class="{{menuItem.faIcon}}"></i>
                    <span>{{menuItem.name}}</span>
                </a>
                <!-- Static link (starts with 'http') -->
                <a href="{{menuItem.route}}" target="_blank" rel="noopener" *ngIf="!menuItem.items.length && menuItem.route.indexOf('http') == 0">
                    <i *ngIf="menuItem.icon" class="material-icons">{{menuItem.icon}}</i>
                    <i *ngIf="menuItem.faIcon" class="{{menuItem.faIcon}}"></i>
                    <span>{{menuItem.name}}</span>
                </a>
                <!-- Has child menu items (so, this is a parent menu) -->
                <a href="javascript:void(0);" [ngClass]="{'menu-toggle': menuItem.items.length}" *ngIf="menuItem.items.length">
                    <i *ngIf="menuItem.icon" class="material-icons">{{menuItem.icon}}</i>
                    <i *ngIf="menuItem.faIcon" class="{{menuItem.faIcon}}"></i>
                    <span>{{menuItem.name}}</span>
                </a>
                <ul class="ml-menu" *ngIf="menuItem.items.length">
                    <ng-template ngFor let-subMenuItem [ngForOf]="menuItem.items" let-mainMenuItemIndex="index">
                        <li routerLinkActive="active">
                            <!-- route name -->
                            <a (click)="onClick()" [class.disabled]="isDisabled(subMenuItem)" [routerLink]="[subMenuItem.route]" class="toggled" *ngIf="!subMenuItem.items.length && subMenuItem.route.indexOf('http') != 0">
                                <i *ngIf="subMenuItem.icon" class="material-icons">{{subMenuItem.icon}}</i>
                                <span>{{subMenuItem.name}}</span>
                            </a>
                            <!-- Static link (starts with 'http') -->
                            <a href="{{subMenuItem.route}}" class="toggled" target="_blank" rel="noopener" *ngIf="!subMenuItem.items.length && subMenuItem.route.indexOf('http') == 0">
                                <i *ngIf="subMenuItem.icon" class="material-icons">{{subMenuItem.icon}}</i>
                                <span>{{subMenuItem.name}}</span>
                            </a>
                            <!-- Has child menu items (so, this is a parent menu) -->
                            <a href="javascript:void(0);" [ngClass]="{'menu-toggle': subMenuItem.items.length}" *ngIf="subMenuItem.items.length">
                                <i *ngIf="subMenuItem.icon" class="material-icons">{{subMenuItem.icon}}</i>
                                <span>{{subMenuItem.name}}</span>
                            </a>
                            <ul class="ml-menu" *ngIf="subMenuItem.items.length">
                                <ng-template ngFor let-subSubMenuItem [ngForOf]="subMenuItem.items" let-mainMenuItemIndex="index">
                                    <li routerLinkActive="active">
                                        <!-- route name -->
                                        <a (click)="onClick()" [class.disabled]="isDisabled(subSubMenuItem)" [routerLink]="[subSubMenuItem.route]" *ngIf="subSubMenuItem.route.indexOf('http') != 0">
                                            <i *ngIf="subSubMenuItem.icon" class="material-icons">{{subSubMenuItem.icon}}</i>
                                            <span>{{subSubMenuItem.name}}</span>
                                        </a>
                                        <!-- Static link (starts with 'http') -->
                                        <a [class.disabled]="isDisabled(subSubMenuItem)" href="{{subSubMenuItem.route}}" target="_blank" rel="noopener" *ngIf="subSubMenuItem.route.indexOf('http') == 0">
                                            <i *ngIf="subSubMenuItem.icon" class="material-icons">{{subSubMenuItem.icon}}</i>
                                            <span>{{subSubMenuItem.name}}</span>
                                        </a>
                                    </li>
                                </ng-template>
                            </ul>
                        </li>
                    </ng-template>
                </ul>
            </li>
        </ng-template>
    </ul>
</div>
