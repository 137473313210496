<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Pergunta do Procedimento</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input matInput formControlName="codigo" type="text" #focus/>
                <mat-error>Informe um Código válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9">
              <mat-form-field>
                <mat-label>Pergunta</mat-label>
                <input matInput formControlName="pergunta" #pergunta type="text" maxlength="500" />
                <mat-hint align="end">{{pergunta.value.length}} / 500</mat-hint>
                <mat-error>Informe um Pergunta válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Unidade Pergunta</mat-label>
                <input matInput formControlName="unidadePergunta" type="text" />
                <mat-error>Informe um Unidade Pergunta válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9">
              <mat-form-field>
                <mat-label>Resposta</mat-label>
                <input matInput formControlName="resposta" #resposta type="text" maxlength="500" />
                <mat-hint align="end">{{resposta.value.length}} / 500</mat-hint>
                <mat-error>Informe um Resposta válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Unidade Resposta</mat-label>
                <input matInput formControlName="unidadeResposta" type="text" />
                <mat-error>Informe um Unidade Resposta válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Fórmula</mat-label>
                <input matInput formControlName="formula" type="text" />
                <mat-error>Informe um Fórmula válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>