<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Menus</h2>
      </div>
      <div class="body table-responsive">

        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          <div *ngFor="let menuPai of menusPai" cdkDrag>
            <div class="example-box">
              {{menuPai.titulo}}
              <div>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(menuPai)"
                  matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(menuPai.id)"
                  matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>              
            </div>
            <div style="padding-left: 25px;">
              <div class="example-box" *ngFor="let menuFilho of menuPai.filhos">
                [Menu Item] - {{menuFilho.titulo}}
                <div>
                  <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(menuFilho)"
                    matTooltip="Editar">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(menuFilho.id)"
                    matTooltip="Excluir">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>                
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div class="fixed-action-btn">
        <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
          matTooltipPosition="above">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<app-menu-modal #menuModal (modalSave)="onResponse($event)"></app-menu-modal>