<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="contatoSiteModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}"
  (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Contato: Pré-Agendamento</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-9">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" required #focus/>
                <mat-hint align="end">Máx 60 caracteres</mat-hint>
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data do Contato</mat-label>
                <input matInput [value]="form.value.dataInclusao" type="date" disabled/>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" (selectionChange)="onChangeStatus()">
                  <mat-option *ngFor="let status of typesService.contatoPreAgendamentoStatusType" [value]="status.value" >
                    {{status.text}}</mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Data Status</mat-label>
                <input matInput [value]="form.value.dataStatus" type="date" disabled/>
                <mat-error>Informe uma Data válida.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular" type="tel" [dropSpecialCharacters]="false"
                  mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" required/>
                  <mat-hint align="end">Máx 16 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com" />
                <mat-hint align="end">Máx 100 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Consulta (Especialidade)</mat-label>
                <mat-select formControlName="consultaId" (selectionChange)="onChangeEspecialidade($event.value)">
                  <mat-option value="">Não Informado</mat-option>
                  <mat-option *ngFor="let item of listaEspecialidadeDto" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Profissional da Saúde</mat-label>
                <mat-select formControlName="profissionalSaudeId">
                  <mat-option value="">Não Informado</mat-option>
                  <mat-option *ngFor="let item of listaFiltroProfissionalDto" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> 
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Convênio</mat-label>
                <mat-select formControlName="convenioId">
                  <mat-option value="">Não Informado</mat-option>
                  <mat-option *ngFor="let item of listaConvenioDto" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>           
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Exame 1</mat-label>
                <mat-select formControlName="exameId1">
                  <mat-option value="">Não Informado</mat-option>
                  <mat-option *ngFor="let item of listaServicoDto" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Exame 2</mat-label>
                <mat-select formControlName="exameId2">
                  <mat-option value="">Não Informado</mat-option>
                  <mat-option *ngFor="let item of listaServicoDto" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Turno</mat-label>
                <mat-select formControlName="turno" required>
                  <mat-option *ngFor="let item of typesService.turnoType" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observação</mat-label>
                <textarea matInput #mensagem formControlName="observacao" rows="3" maxlength="200"></textarea>
                <mat-hint align="end">{{mensagem.value.length}} / 200</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observação Interna</mat-label>
                <textarea matInput #observacaoInterna formControlName="observacaoInterna" rows="3" maxlength="200"></textarea>
                <mat-hint align="end">{{observacaoInterna.value.length}} / 200</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
