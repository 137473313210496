import { Injectable, Injector } from '@angular/core';
import { ArtigoTagModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArtigoTagService extends BaseCrudService<ArtigoTagModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'artigoTag';
  }

  get(): Observable<ArtigoTagModel[]> {
    return this
      .httpClient
      .get<ArtigoTagModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getBySlug(slug: string): Observable<ArtigoTagModel> {
    return this
      .httpClient
      .get<ArtigoTagModel>(`${this.getApiUrl()}${this.getController()}/getBySlug/${slug}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
