<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg" *ngIf="recursoId">
    <div class="card modal-content">
      <div class="header">
        <h2>Bloqueios de Agenda - {{recursoNome}}</h2>
      </div>
      <div class="modal-body table-responsive">
        <button mat-flat-button color="primary" type="button" [disabled]="!isAllowed('Create')"
          (click)="create()">Adicionar</button>
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
          class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Data Inicial</th>
              <th class="text-center">Data Final</th>
              <th class="text-center">Horário Inicial</th>
              <th class="text-center">Horário Final</th>
              <th class="text-center">Observação</th>
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models">
              <td>{{item.dataInicial | date: 'dd/MM/yyyy'}}</td>
              <td>{{item.dataFinal | date: 'dd/MM/yyyy'}}</td>
              <td>{{item.horarioInicial | mask: 'hh:MM'}}</td>
              <td>{{item.horarioFinal | mask: 'hh:MM'}}</td>
              <td>{{item.observacao}}</td>
              <td class="text-center">
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)"
                  matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(item.id)"
                  matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">        
        <div class="row">
          <div class="col-md-6 text-left">
            <mat-slide-toggle [(ngModel)]="listarBloqAnteriores" color="primary" (change)="filter()" style="margin-right: 10px;">
              Listar Bloqueios Anteriores
            </mat-slide-toggle>
          </div>          

          <div class="col-md-6">
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span>Fechar</span>
            </button>
          </div>
        </div>                
      </div>
    </div>
  </div>
</div>
<app-agenda-bloqueio-modal #modal (modalSave)="onResponse($event)"></app-agenda-bloqueio-modal>