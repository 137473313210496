import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { TipoServicoModel } from '../../domain/models';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TipoServicoService } from '../../domain/services';
import { Validators } from '@angular/forms';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-tipo-servico-modal',
  templateUrl: './tipo-servico-modal.component.html',
})
export class TipoServicoModalComponent extends BaseCrudModalComponent<TipoServicoModel> implements OnInit {
  @ViewChild('tipoServicoModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  constructor(
    injector: Injector,
    tipoServicoService: TipoServicoService
  ) {
    super(injector, tipoServicoService);
  }

  protected initializeForm(tipoServico: TipoServicoModel) {
    this.form = this.formBuilder.group({
      id: tipoServico.id,
      dataInclusao: [tipoServico.dataInclusao, Validators.required],
      dataAlteracao: tipoServico.dataAlteracao,
      ordem: [tipoServico.ordem, [
        Validators.required
      ]],
      nome: [tipoServico.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)
      ]],
      descricao: [tipoServico.descricao,
      Validators.maxLength(200)
      ],
      imagemUrl: [tipoServico.imagemUrl],
      imagemUrlBase: [tipoServico.imagemUrlBase],
      imagemPath: [tipoServico.imagemPath]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }
}
