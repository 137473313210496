import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TemaModel, TemaPaginaModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TemaService extends BaseCrudService<TemaModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Tema';
  }

  get(): Observable<TemaModel[]> {
    return this
      .httpClient
      .get<TemaModel[]>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  updateCodigo(temaCodigo) {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/UpdateCodigo/${temaCodigo}`, {}, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getCurrent(): Observable<TemaModel> {
    return this
      .httpClient
      .get<TemaModel>(`${this.getApiUrl()}${this.getController()}/GetCurrent`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getTemaPagina(arquivoNome): Observable<TemaPaginaModel> {
    return this
      .httpClient
      .get<TemaPaginaModel>(`${this.getApiUrl()}${this.getController()}/GetTemaPagina/${arquivoNome}`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }
}
