<div class="panel-group" role="tablist" aria-multiselectable="true">
  <div class="panel" id="panelFilter">
    <div class="panel-heading" role="tab" id="headingFilter">
      <h4 class="panel-title">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionFilter" href="#collapseFilter"
          aria-expanded="false" aria-controls="collapseFilter">
          <span class="material-icons">filter_alt</span> Filtros
        </a>
      </h4>
    </div>
    <div id="collapseFilter" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFilter"
      aria-expanded="false">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro de Nome</mat-label>
              <mat-select [(ngModel)]="searchNome.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Pesquisar por nome ...</mat-label>
              <input matInput type="text" [(ngModel)]="searchNome.searchTerm" />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Uso Frequente?</mat-label>
              <mat-select [(ngModel)]="searchUsoFrequente.searchTerm">
                <mat-option value="">Todos</mat-option>
                <mat-option *ngFor="let frequente of typesService.boolTypes" [value]="frequente.value">{{frequente.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Serviço</mat-label>
              <mat-select [(ngModel)]="searchTipoServico.searchTerm">
                <mat-option value="1000">Todos</mat-option>
                <mat-option *ngFor="let tipo of typesService.procedimentoServicoType" [value]="tipo.value">{{tipo.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="panel-footer" style="text-align: right">
        <mat-slide-toggle style="margin-right: 10px;" color="primary" [(ngModel)]="searchRegistroAtivo.searchTerm">
          Somente Reg.Ativos
        </mat-slide-toggle>
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Procedimentos</h2>
      </div>
      <div class="body table-responsive">        
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center" width="50">Código</th>
              <th class="text-center">Nome</th>
              <th class="text-center">Grupo</th>
              <th class="text-center" width="100">Tipo</th>
              <th class="text-center" width="80">Uso Freq.</th>
              <th class="text-center" width="80">Serviço</th>
              <th class="text-center" width="50">Ativo</th>
              <th class="text-center" width="120"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models">              
              <td>{{item.codigo}}</td>
              <td>{{item.nome}}</td>
              <td>{{item.grupoNome}}</td>
              <td>{{item.exame ? 'Exame' : 'Procedimento'}}</td>              
              <td>{{typesService.getBoolTypeNome(item.usoFrequente)}}</td>              
              <td>{{typesService.getProcedimentoServicoTypeNome(item.tipoServico)}}</td>              
              <td>{{typesService.getBoolTypeNome(item.registroAtivo)}}</td>            
              <td class="text-center">
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="procedimentoPerguntaModal.show(item.id, item.nome)" matTooltip="Perguntas do Procedimento">
                  <mat-icon>article</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(item.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
      </table>
    </div>
    <div class="fixed-action-btn">
      <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<app-procedimento-modal #modal (modalSave)="onResponse($event)"></app-procedimento-modal>
<app-procedimento-pergunta #procedimentoPerguntaModal></app-procedimento-pergunta>
