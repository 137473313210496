import { Injectable, Injector } from '@angular/core';
import { BaseCrudService } from '../base-crud.service';
import { GrupoUsuarioModel } from '../../models/controle-acesso/grupo-usuario-model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GrupoUsuarioService extends BaseCrudService<GrupoUsuarioModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'GrupoUsuario';
  }

  getAll(): Observable<GrupoUsuarioModel[]> {
    return this
      .httpClient
      .get<GrupoUsuarioModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
