import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewsComponent } from './previews.component';
import { Layout1Component } from './layout1/layout1.component';
import { Layout2Component } from './layout2/layout2.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PreviewsComponent,
    Layout1Component,
    Layout2Component
  ],
  exports: [
    PreviewsComponent    
  ]
})
export class PreviewsModule { }
