import { Component, OnInit, Input, Injector, ViewChild, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { TenantComponenteModalComponent } from './tenant-componente-modal/tenant-componente-modal.component';
import { TenantComponenteModel } from 'projects/ProjetoBaseAngular/domain/models';
import { BaseTypesService } from 'projects/ProjetoBaseAngular/domain/services/base-types.service';

@Component({
  selector: 'app-tenant-componentes',
  templateUrl: './tenant-componentes.component.html'
})
export class TenantComponentesComponent extends BaseComponent implements OnInit {
  @ViewChild('modal', { static: false }) modal: TenantComponenteModalComponent;
  @Input('itens') itens: TenantComponenteModel[];
  @Output() componenteUpdate: EventEmitter<any> = new EventEmitter<any>();  
  
  dtOptions: DataTables.Settings = {
    language: {
      url: '/assets-base/json/translation/datatable/pt-br.json'
    },    
    lengthChange: false,
    paging: false,
    searching: false    
  };
  dtTrigger: Subject<TenantComponenteModel[]> = new Subject();

  constructor(
    injector: Injector,
    readonly typeService: BaseTypesService
  ) {    
    super(injector);    
  }

  ngOnInit() {
    super.ngOnInit();        
  }

  create() {
    this.modal.showCreate();
  }

  edit(model: TenantComponenteModel) {
    this.modal.showEdit(model);    
  }  

  delete(id: string) {
    Swal.fire({
      title: 'Tem certeza de que deseja excluir este registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
        if (result.value) {          
          const index = this.itens.findIndex(x => x.id === id);
          this.itens.splice(index, 1);        
        }
    });
  } 

  onResponse(model: TenantComponenteModel) {        
    if (!model) {
      return;
    }

    this.snackBar.open('Salvo com sucesso!', 'Ok', {
      duration: 3000
    });

    const index = this.itens.findIndex(x => x.id === model.id);
    if (index === -1) {
      this.itens.push(model);
    } else {
      this.itens[index] = model;
    }    
    this.componenteUpdate.emit(this.itens);
  }
}
