import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { TypesService } from 'projects/admin/src/domain/services';
import { SolicitacaoOnlineFiltroDto } from '../../domain/models';

@Component({
  selector: 'app-docfiltro-modal',
  templateUrl: './docfiltro-modal.component.html'
})
export class DocFiltroModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  filtro: SolicitacaoOnlineFiltroDto;
  status: number;
  
  constructor(
    injector: Injector,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  show(filtro: SolicitacaoOnlineFiltroDto) {
    if (!filtro){
      filtro = new SolicitacaoOnlineFiltroDto();
    }
    this.filtro = filtro;
    
    this.modal.show();
  }

  close(filtrar: boolean) {
    //injetar comando do usuário (se é para executar = true)
    this.filtro['filtrar'] = filtrar;
    
    this.modalClose.emit(this.filtro);

    this.modal.hide();
  }
}
