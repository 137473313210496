import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    @Optional() @Inject(REQUEST) private request: any,    
    @Inject(PLATFORM_ID) private platformId: Object) {    
  }

  get hostname() : string {
    let result = '';
    if (isPlatformServer(this.platformId)) {
      result = this.request.hostname.split('.')[0];
      if (result === 'www') {
        result = this.request.hostname.split('.')[1];
      }
    } else {
      result = document.location.hostname.split('.')[0];
      if (result === 'www') {
        result = document.location.hostname.split('.')[1];
      }
    }
    return result;
  }
}