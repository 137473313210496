<div class="card">
  <div class="body" *ngIf="form">
    <form method="post" (ngSubmit)="save()" [formGroup]="form">
      <h4 class="card-inside-title">HTML do Cabeçalho</h4>
      <div class="alert alert-danger" *ngIf="errors.length > 0">
        <h3>Oops! Algo deu errado:</h3>
        <ul>
          <li *ngFor="let error of errors">{{ error }}</li>
        </ul>
      </div>
      <div class="row">        
        <div class="col-md-6">
          <mat-slide-toggle formControlName="headerShowLogotipo" color="primary">
            Mostrar Logotipo
          </mat-slide-toggle>
        </div>
        <div class="col-md-6">
          <mat-slide-toggle formControlName="headerShowContact" color="primary">
            Mostrar Contatos e Redes Sociais
          </mat-slide-toggle>
        </div>
        <div class="col-md-12">
          <editor formControlName="html" [init]="tinymceConfig(700)">
          </editor>
        </div>

        <div class="col-md-12">
          <button mat-flat-button type="submit" color="primary" class="button-row">
            <span>Salvar Alterações</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>