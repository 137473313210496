import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { TermoModel } from '../domain/models';
import { TermoService } from '../domain/services';
import { TermoModalComponent } from './termo-modal/termo-modal.component';

@Component({
  selector: 'app-termo',
  templateUrl: './termo.component.html'
})
export class TermoComponent extends BaseCrudComponent<TermoModel> implements OnInit {
  @ViewChild('modal') modal: TermoModalComponent;

  searchNome = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);  
  OptionSearchType: typeof OptionSearchType = OptionSearchType;
  
  selectColumns = [ 
    { data: 'nome'}
  ];

  constructor(
    injector: Injector,
    readonly service: TermoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    (this.searchNome.searchTerm) && searches.push(this.searchNome);

    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }
}
