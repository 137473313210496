import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { DocSusComponent } from './doc-sus.component';
import { DocSusModalComponent } from './doc-sus-modal/doc-sus-modal.component';
import { ProcedimentoModule } from '../procedimento/procedimento.module';
import { ReportModule } from '../../report/report.module';
import { DocSusPrintComponent } from './doc-sus-print/doc-sus-print.component';
import { DocSusTfdPrintComponent } from './doc-sus-tfd-print/doc-sus-tfd-print.component';

@NgModule({
  imports: [    
    SharedModule,
    ProcedimentoModule,
    ReportModule
  ],
  declarations: [
    DocSusComponent,
    DocSusModalComponent,
    DocSusPrintComponent,
    DocSusTfdPrintComponent
  ],
  exports: [
    DocSusComponent
  ]
})
export class DocSusModule { }
