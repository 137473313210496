import { Component, OnInit, ViewChild, Injector } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { BaseComponent } from "projects/ProjetoBaseAngular/app/base.component";

@Component({
  selector: 'app-imagem-apresentacao-modal',
  templateUrl: './imagem-apresentacao-modal.component.html'
})
export class ImagemApresentacaoModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  imagemUrl: string = null;

  constructor(
    injector: Injector,
    ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  close() {
    this.errors = [];
    this.form = null;
    this.modal.hide();
  }

  showImagem(imagemUrl: string) {
    this.imagemUrl = imagemUrl;
    this.modal.show();
  }
}
