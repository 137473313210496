import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { EspecialidadeMedModel } from '../../domain/models';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EspecialidadeMedService } from '../../domain/services';
import { Validators } from '@angular/forms';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { IconeSelectModalComponent } from '../../../Icone/icone/icone-select-modal/icone-select-modal.component';
import { IconeModel } from '../../../Icone/domain/models';

@Component({
  selector: 'app-especialidade-med-modal',
  templateUrl: './especialidade-med-modal.component.html',
})
export class EspecialidadeMedModalComponent extends BaseCrudModalComponent<EspecialidadeMedModel> implements OnInit {
  @ViewChild('especialidadeMedModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;
  @ViewChild('iconeSelectModal') iconeSelectModal: IconeSelectModalComponent;


  constructor(
    injector: Injector,
    especialidadeMedService: EspecialidadeMedService
  ) {
    super(injector, especialidadeMedService);
  }

  protected initializeForm(especialidadeMed: EspecialidadeMedModel) {
    this.form = this.formBuilder.group({
      id: especialidadeMed.id,
      dataInclusao: [especialidadeMed.dataInclusao, Validators.required],
      dataAlteracao: especialidadeMed.dataAlteracao,
      ordem: [especialidadeMed.ordem, [
        Validators.required
      ]],
      iconeHtml: [especialidadeMed.iconeHtml, [
        Validators.maxLength(200)
      ]],
      tituloIcone: [especialidadeMed.tituloIcone, [
        Validators.maxLength(60)
      ]],
      nome: [especialidadeMed.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60)]
      ],
      descricao: [especialidadeMed.descricao, [
        Validators.maxLength(500)
      ]],
      imagemUrl: [especialidadeMed.imagemUrl],
      imagemUrlBase: [especialidadeMed.imagemUrlBase],
      imagemPath: [especialidadeMed.imagemPath]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  beforeShowCreate(model: EspecialidadeMedModel) {
    model.ordem = 0;
    return super.beforeShowCreate(model);
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {    
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({ 
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null 
    });
  }

  showSelectIcone() {
    this.iconeSelectModal.showSelect();
  }

  onResponseIcone(icone: IconeModel) {
    this.form.patchValue({ iconeHtml: icone.iconeHtml });
  }

  deselectIcone() {
    this.form.patchValue({ iconeHtml: null });
  }
}
