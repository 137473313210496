import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AgendaEsperaService } from '../../domain/services';
import { AgendaEsperaModel } from '../../domain/models';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ConvenioService } from '../../../parceiro/domain/services/convenio.service';

@Component({
  selector: 'app-agenda-espera-modal',
  templateUrl: './agenda-espera-modal.component.html'
})
export class AgendaEsperaModalComponent extends BaseCrudModalComponent<AgendaEsperaModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  paciente: PacienteModel;
  convenios: SelectDto[];

  constructor(
    injector: Injector,
    service: AgendaEsperaService,
    readonly pacienteService: PacienteService,
    readonly convenioService: ConvenioService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: AgendaEsperaModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      profissionalRecursoId: [model.profissionalRecursoId, [
        Validators.required
      ]],
      pacienteId: [model.pacienteId, [
        Validators.required
      ]],
      convenioId: [model.convenioId, [
        Validators.required
      ]],      
      observacao: [model.observacao, [
        Validators.maxLength(500)
      ]],
    });

    if (model.pacienteId) {
      this.onPacienteResponse(model.pacienteId);
    }    
  }

  protected init() {
    super.init();
    this.convenioService.getSelectList().subscribe(x => this.convenios = x);
  }

  ngOnInit() {
    super.ngOnInit();    
  }    

  onPacienteResponse(pacienteId: string) {
    this.form.patchValue({ pacienteId });
    if (!pacienteId) {
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
    } else {
      this.pacienteService.getById(pacienteId).subscribe(x => {        
        this.paciente = x;        
      });
    }    
  }
}
