import { BaseEntidadePessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { SexoType } from 'projects/ProjetoBaseAngular/domain/types';
import { StatusCurriculoType } from 'projects/admin/src/domain/types';

export class CargoModel extends BaseModel {
  public nome: string;
  public cargoSuperiorId: string;
  public cargoSuperiorNome: string;

  public setorId: string;
  public setorNome: string;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;

  public missaoObjetivos: string;
  public atividades: string;
  public experiencia: string;
  public formacaoAcademica: string;
  public competencias: string;
  public treinamentos: string;
}

export class VagaModel extends BaseModel {
  public titulo: string;
  public descricao: string;
  public cargoId: string;
  public cargoNome: string;  
  public html: string;
}

export class CurriculoModel extends BaseEntidadePessoaModel {

  public nome: string;
  public cpf: string;
  public sexo: SexoType = SexoType.NaoDefinido;
  public site: string;
  public dataNascimento: Date;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;
  public celular: string;
  public telefone: string;
  public email: string;
  
  public arquivoCurriculoFile: string;
  public arquivoCurriculoUrl: string;
  public arquivoCurriculoUrlBase: string;
  public arquivoCurriculoPath: string;

  public vagaId: string;
  public vagaTitulo: string;

  public observacaoInterna: string;
  public areaAtuacao: string;

  public status: StatusCurriculoType;

  public ruaAvenida: string;
  public numero: string;
  public complemento: string;
  public bairro: string;
  public cep: string;
  public estadoId: string;
  public estadoNome: string;
  public estadoUF: string;
  public cidadeId: string;
  public cidadeNome: string;

  public total: number;
}
