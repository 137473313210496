<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-md">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header" [ngClass]="{'bg-red' : !form.value.registroAtivo}">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="row">
            <div class="col-md-6">
              <h2>Equipamento</h2>
            </div>
            <div class="col-md-5 text-right">
              <mat-slide-toggle formControlName="registroAtivo" color="primary">
                Reg.Ativo
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>                    
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Nome p/ Apresentação na Agenda</mat-label>
                <input matInput formControlName="nomeApresentacao" type="text" maxlength="60" #focus/>
                <mat-hint align="end">Máx 60 caracteres</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Usa Confirmação Lote</mat-label>
                <mat-select formControlName="usaConfirmacaoLote">
                  <mat-option *ngFor="let item of typesService.boolTypes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
                <mat-hint>Define se Equipamento usa confirmação de agendamento em lote.</mat-hint>                    
              </mat-form-field>
            </div>
          </div>          
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Pré-Requisitos</mat-label>
                <textarea matInput formControlName="preRequisitos" rows="4"></textarea>                
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-slide-toggle formControlName="capturaImagem" color="primary">
                Utiliza captura de imagem
              </mat-slide-toggle>              
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <div class="row">
          <div class="col-sm-3 text-left">
            <p style="margin: 0;">Incluído:{{form.value.dataInclusao | date:"dd/MM/yyyy"}}</p>
            <p style="margin: 0;">Alterado:{{form.value.dataAlteracao | date:"dd/MM/yyyy"}}</p>
          </div>
          <div class="col-sm-2 text-left">
          </div>
          <div class="col-sm-7">
            <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
              Continuar Incluindo
            </mat-slide-toggle>
            <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
              class="button-row">
              <span> Salvar</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span> Cancelar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>