import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ReportModule } from '../../report/report.module';
import { RelDemonstrativoPeriodoComponent } from './rel-demonstrativo-periodo.component';

@NgModule({
  imports: [
    SharedModule,
    ReportModule
  ],
  declarations: [
    RelDemonstrativoPeriodoComponent,
  ]
})
export class RelDemonstrativoPeriodoModule { }
