<div bsModal #paginaWebModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="paginaWebModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-xl">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Página Web</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<ul class="nav nav-tabs">
						<li class="active"><a class="nav-link active" data-toggle="tab" id="navPrincipal" href="#tabPrincipal"
								role="tab" aria-selected="true">Principal</a>
						</li>
						<li><a class="nav-link" data-toggle="tab" id="navScript" href="#tabScript" role="tab"
								aria-selected="false">Scripts</a>
						</li>
					</ul>
					<div class="tab-content" id="nav-tabContent">
						<div class="tab-pane fade in active" id="tabPrincipal" role="tabpanel" aria-labelledby="navPrincipal">
							<div class="row">
								<div class="col-md-2">
									<div class="form-group align-center">
										<div style="height: 150px; width: 100%; object-fit: contain;">
											<img src="{{form.value.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
												*ngIf="form.value.imagemUrl">
										</div>
										<div class="align-center">
											<button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
												<mat-icon>add_photo_alternate</mat-icon>
											</button>
											<button mat-icon-button type="button" [disabled]="!form.value.imagemUrl" (click)="deselectImage()"
												matTooltip="Desvincular Imagem">
												<mat-icon>cancel</mat-icon>
											</button>
										</div>
									</div>
								</div>
								<div class="col-md-10">
									<div class="row">
										<div class="col-md-6">
											<mat-form-field>
												<mat-label>Título</mat-label>
												<input matInput formControlName="titulo" type="text" (blur)="slugify($event.target.value)"
													#focus />
												<mat-hint align="end">Máx 100 caracteres</mat-hint>
												<mat-error>Informe um Título válido.</mat-error>
											</mat-form-field>
										</div>

										<div class="col-md-6">
											<mat-form-field>
												<mat-label>URL Amigável</mat-label>
												<input matInput formControlName="slug" type="text" />
												<mat-hint align="end">Máx 20 caracteres</mat-hint>
												<mat-error>Informe uma URL Amigável válida.</mat-error>
											</mat-form-field>
										</div>

										<div class="col-md-12">
											<mat-form-field>
												<mat-label>Resumo</mat-label>
												<textarea matInput formControlName="resumo"></textarea>
												<mat-hint align="end">Máx 400 caracteres</mat-hint>
												<mat-error>Informe um Resumo válido.</mat-error>
											</mat-form-field>
										</div>

										<div class="col-md-12">
											<mat-form-field>
												<mat-label>Palavras Chaves</mat-label>
												<input matInput formControlName="palavrasChave" type="text" />
												<mat-hint align="end">Máx 400 caracteres</mat-hint>
												<mat-error>Informe Palavras Chaves válidas.</mat-error>
											</mat-form-field>
										</div>
									</div>
								</div>
							</div>
							<h4 class="card-inside-title">Html</h4>
							<div class="row">
								<div class="col-md-12">
									<editor formControlName="html" [init]="tinymceConfig(700)">
									</editor>
								</div>
							</div>
						</div>
						<div class="tab-pane fade" id="tabScript" role="tabpanel" aria-labelledby="navScript">
							<h4 class="card-inside-title">Script Head</h4>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field>
										<mat-label>Script Head</mat-label>
										<textarea matInput formControlName="scriptHead" rows="15"></textarea>
										<mat-hint align="end">Máx 400 caracteres</mat-hint>
												<mat-error>Informe Palavras Chaves válidas.</mat-error>
									</mat-form-field>
								</div>
							</div>
							<h4 class="card-inside-title">Script Body</h4>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field>
										<mat-label>Script Body</mat-label>
										<textarea matInput formControlName="scriptBody" rows="15"></textarea>
										<mat-hint align="end">Máx 400 caracteres</mat-hint>
												<mat-error>Informe Palavras Chaves válidas.</mat-error>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>