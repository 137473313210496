import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { AgendaDisponibilidadeService, ClassificacaoAgendamentoService } from '../../domain/services';
import { AgendaDisponibilidadeModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

@Component({
  selector: 'app-agenda-disponibilidade-modal',
  templateUrl: './agenda-disponibilidade-modal.component.html'
})
export class AgendaDisponibilidadeModalComponent extends BaseCrudModalComponent<AgendaDisponibilidadeModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  private recursoId: string;

  classificacoes: SelectDto[];

  constructor(
    injector: Injector,
    service: AgendaDisponibilidadeService,
    readonly typesService: TypesService,
    readonly classificacaoService: ClassificacaoAgendamentoService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: AgendaDisponibilidadeModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,            
      profissionalRecursoId: [this.recursoId, [        
        Validators.required
      ]],
      classificacaoId: [model.classificacaoId, [        
        Validators.required
      ]],
      diaSemana: [model.diaSemana],
      diasSemana: [model.diasSemana],
      horarioInicial: [model.horarioInicial, [        
        Validators.required       
      ]],
      horarioFinal: [model.horarioFinal, [        
        Validators.required
      ]],
      duracao: [model.duracao, [        
        Validators.required
      ]],
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.classificacaoService.getSelectList().subscribe(x => this.classificacoes = x);
  }

  async showCreate(model: AgendaDisponibilidadeModel) {
    this.recursoId = model.profissionalRecursoId;
    super.showCreate(model);    
  }

  async showEditByRecursoId(id, profissionalRecursoId) {
    this.recursoId = profissionalRecursoId;
    super.showEdit(id);    
  }
}
