import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { MedicamentoModalComponent } from './medicamento-modal/medicamento-modal.component';
import { MedicamentoModel } from '../domain/models';
import { MedicamentoService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-medicamento',
  templateUrl: './medicamento.component.html'
})
export class MedicamentoComponent extends BaseCrudComponent<MedicamentoModel> implements OnInit {
  @ViewChild('modal') modal: MedicamentoModalComponent;

  searchDescricao = new ModelSearch('descricao', OptionSearchType.Contains, SearchType.String);
  searchUsoFrequente = new ModelSearch('usoFrequente', OptionSearchType.Equals, SearchType.Boolean);
  OptionSearchType: typeof OptionSearchType = OptionSearchType;

  selectColumns = [
    { data: 'descricao' },
    { data: 'concentracao' },
    { data: 'qtdEmbalagem' },
    { data: 'via' },
    { data: 'formaUso' },
    { data: 'usoFrequente' }
  ];

  constructor(
    injector: Injector,
    readonly service: MedicamentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchUsoFrequente.searchTerm = "";
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    (this.searchDescricao.searchTerm) && searches.push(this.searchDescricao);
    if (this.searchUsoFrequente.searchTerm &&
      this.searchUsoFrequente.searchTerm === true ||
      this.searchUsoFrequente.searchTerm === false) {

      searches.push(this.searchUsoFrequente);
    }


    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }
}
