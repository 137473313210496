<div bsModal #cargoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="cargoModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Cargo</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-2">
              <div class="form-group align-center">
                <div style="height: 100px; width: 100%; object-fit: contain;">
                  <img src="{{form.value.imagemUrl}}" style="height: 100px; width: 100%; object-fit: contain;"
                    *ngIf="form.value.imagemUrl">
                </div>
                <div class="align-center">
                  <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                    <mat-icon>add_photo_alternate</mat-icon>
                  </button>
                  <button mat-icon-button type="button" [disabled]="!form.value.imagemUrl" (click)="deselectImage()"
                    matTooltip="Desvincular Imagem">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-10">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label> Setor </mat-label>
                    <mat-select formControlName="setorId" #focus>
                      <mat-option *ngFor="let setor of setores" [value]="setor.id">{{setor.nome}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione um Setor.</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="nome" type="text" />
                    <mat-error>Informe um Nome válido.</mat-error>
                    <mat-hint align="end">Máx 100 caracteres</mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label> Cargo Superior </mat-label>
                    <mat-select formControlName="cargoSuperiorId" #focus>
                      <mat-option>Nenhum Cargo Superior</mat-option>
                      <mat-option *ngFor="let cargo of cargos" [value]="cargo.id">{{cargo.nome}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione um Cargo Superior.</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Missão/Objetivos</mat-label>
                    <textarea matInput #missaoObjetivos formControlName="missaoObjetivos" rows="3"
                      maxlength="500"></textarea>
                    <mat-hint align="end">{{missaoObjetivos.value.length}} / 500</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Atividades</mat-label>
                <textarea matInput #atividades formControlName="atividades" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{atividades.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Fomação Acadêmica</mat-label>
                <textarea matInput #formacaoAcademica formControlName="formacaoAcademica" rows="3"
                  maxlength="500"></textarea>
                <mat-hint align="end">{{formacaoAcademica.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Experiência</mat-label>
                <textarea matInput #experiencia formControlName="experiencia" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{experiencia.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Competências</mat-label>
                <textarea matInput #competencias formControlName="competencias" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{competencias.value.length}} / 500 </mat-hint>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Treinamentos</mat-label>
                <textarea matInput #treinamentos formControlName="treinamentos" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{treinamentos.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>
          </div>

        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
