import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AtendimentoModel, AtendimentoValidaDesfazerDto, AtestadoConvertMaskDto, AtestadoDto } from '../models';
import { BaseDocContainerService } from 'projects/ProjetoBaseAngular/domain/services/doc-movimento/base-doc-container.service';
@Injectable({
  providedIn: 'root'
})
export class AtendimentoService extends BaseDocContainerService<AtendimentoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Atendimento';
  }

  /**
   * Converter texto/modelos com máscaras para texto plano com dados substituídos.
   */
  atestadoTextmaskToText(texto: string, convMaskDto: AtestadoConvertMaskDto): string {
    let strResult = texto
      .replace(/{{dataAtual}}/g, convMaskDto.data)
      .replace(/{{pacienteCpf}}/g, convMaskDto.pacienteCpf)
      .replace(/{{pacienteNome}}/g, convMaskDto.pacienteNome)
      .replace(/{{profissionalNome}}/g, convMaskDto.profissionalNome)
      .replace(/{{profissionalCrm}}/g, convMaskDto.profissionalCrm)
      .replace(/{{profissionalCrn}}/g, convMaskDto.profissionalCrn)
      .replace(/{{profissionalRqe}}/g, convMaskDto.profissionalRqe);

    return strResult;
  }

  calcularTotais(id: string): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/CalcularTotais/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAtestadosByPacienteId(pacienteId: string): Observable<AtestadoDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetAtestadosByPacienteId/${pacienteId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByAgendamentoId(agendamentoId: string): Observable<AtendimentoModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/${agendamentoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  lancar(model: AtendimentoModel): Observable<AtendimentoModel> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Lancar`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  podeDesfazerLancto(agendamentoId: string): Observable<AtendimentoValidaDesfazerDto> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/PodeDesfazerLancto/${agendamentoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  desfazerLancto(agendamentoId: string, forcado: boolean = false): Observable<boolean> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/DesfazerLancto/${agendamentoId}/${forcado}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  excluirRascunho(model: AtendimentoModel): Observable<boolean> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/ExcluirRascunho`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}
