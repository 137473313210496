import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { BaseEntidadePessoasComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoas.component';
import { PacienteService } from '../domain/services';
import { PacienteModel } from '../domain/models';
import { PacienteModalComponent } from './paciente-modal/paciente-modal.component';

@Component({
  selector: 'app-admin-paciente',
  templateUrl: './paciente.component.html'
})
export class PacienteComponent extends BaseEntidadePessoasComponent<PacienteModel> implements OnInit {
  @ViewChild('pacienteModal') modal: PacienteModalComponent;

  constructor(
    injector: Injector,
    readonly pacienteService: PacienteService
  ) {
    super(injector, pacienteService);    
  }

  selectColumns = [
    { data: 'pessoa.codigo' },
    { data: 'pessoa.nomeFantasia' },
    { data: 'pessoa.cnpjCpf' },
    { data: 'pessoa.dataNascimento' },
    { data: '(pessoa.cidade.nome + "/" + pessoa.estado.uf) as pessoaCidadeUf' }
  ];

  ngOnInit() {
    super.ngOnInit();    
  }

  editProntuario(model: PacienteModel) {
    this.modal.showEditProntuario(model.id);
  }
}
