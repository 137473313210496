import { BaseCrudService } from "projects/ProjetoBaseAngular/domain/services/base-crud.service";
import { ChatBotNodoModel } from "../models";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { BotFluxoType } from "../types";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ChatBotNodoService extends BaseCrudService<ChatBotNodoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ChatBotNodo';
  }

  getInicioByFluxo(tipoFluxo: BotFluxoType): Observable<ChatBotNodoModel> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetInicioByFluxo/${tipoFluxo}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAtendimentoInitByFluxo(tipoFluxo: BotFluxoType): Observable<ChatBotNodoModel> {
    return this.httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetAtendimentoInitByFluxo/${tipoFluxo}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }  
}