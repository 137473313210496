import { BaseModel } from '../base/base-model';

export class GrupoUsuarioPermissaoModel extends BaseModel {


  public grupoUsuarioId: string;
  public paginaId: string;
  public paginaNome: string;
  public paginaDescricao: string;
  public acaoId: string;
  public acaoNome: string;
  public acaoDescricao: string;
  public temPermissao: boolean;
}
