import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { EnqueteModel, EnqueteOpcaoModel } from '../../domain/models';
import { EnqueteService } from '../../domain/services';

@Component({
  selector: 'app-enquete-respostas-modal',
  templateUrl: './enquete-respostas-modal.component.html',
})
export class EnqueteRespostasModalComponent implements OnInit {
  @ViewChild('enqueteRespostasModal') modal: ModalDirective;
  opcoesRespostas: EnqueteOpcaoModel[];

  // Pie
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  pieChartLabels: Label[] = [];
  pieChartData: number[] = [];
  pieChartType: ChartType = 'pie';
  pieChartLegend = true;
  pieChartPlugins = [];

  constructor(
    readonly enqueteService: EnqueteService
  ) { 
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
  }

  show(enquete: EnqueteModel) {    
    this.enqueteService.getOpcoesRespostas(enquete.id).subscribe(opcoesRespostas => {
      this.opcoesRespostas = opcoesRespostas;

      this.pieChartLabels = [];
      this.pieChartData = [];
      this.opcoesRespostas.forEach(opcao => {
        this.pieChartLabels.push(opcao.descricaoOpcao);
        this.pieChartData.push(opcao.respostas);
      });      
    });
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }  
}
