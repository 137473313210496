import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { AtendimentoSolicExameModel, ResultadoExameDto } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoSolicExameService extends BaseCrudService<AtendimentoSolicExameModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'AtendimentoSolicExame';
  }

  getDescricaoHtml(descricao: string): string {
    if (descricao){
      let linhas = descricao.split('\n');
      let result = "<span>";
      linhas.forEach(item => {
        result += `${item}<br>`;
      });
  
      result += "</span>";
  
      return result;
    } 

    return "";
  }

  getByAtendimentoId(atendimentoId): Observable<AtendimentoSolicExameModel[]> {
    return this.httpClient.get(`${this.getApiUrl()}${this.getController()}/GetByAtendimentoId/${atendimentoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByPacienteProfissionalId(pacienteId: string, profissionalId: string): Observable<AtendimentoSolicExameModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByPacienteProfissionalId/${pacienteId}/${profissionalId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByPacienteId(pacienteId: string): Observable<any[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/getByPacienteId/${pacienteId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getComparativoResultado(pacienteId, atendimentoSolicExameId): Observable<ResultadoExameDto> {
    return this.httpClient.get(`${this.getApiUrl()}${this.getController()}/GetComparativoResultado/${pacienteId}/${atendimentoSolicExameId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
