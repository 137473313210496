import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { RevistaType } from 'projects/admin/src/domain/types';


export class RevistaModel extends BaseModel {
  public tipo: RevistaType;
  public titulo: string;
  public data: Date;
  public edicao: string;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;
  public arquivoUrl: string;
  public arquivoUrlBase: string;
  public arquivoPath: string;
  public linkExterno: string;
  public publicado: boolean;
  public slug: string;
  public file: any;
}
