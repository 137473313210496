import { Directive, HostBinding, Self } from '@angular/core';
import { AbstractControl, FormControlName } from '@angular/forms';

@Directive({ 
  selector: '[formControlName]'
})
export class TagHelperDirective {
  constructor(@Self() private formControl: FormControlName) { }

  @HostBinding('attr.required')
  get isInvalid() {    
    const control = this.formControl.control;    
    const validator = control.validator && control.validator({}as AbstractControl);
    return validator ? validator.required : false;
  }
}
