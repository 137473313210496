import { Injectable, Injector } from '@angular/core';

import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServicoModel, TipoServicoModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ServicoService extends BaseCrudService<ServicoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Servico';
  }

  getAll(): Observable<ServicoModel[]> {
    return this
      .httpClient
      .get<ServicoModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByTipoServicoId(tipoServicoId: string): Observable<ServicoModel[]> {
    return this
      .httpClient
      .get<TipoServicoModel>(`${this.getApiUrl()}${this.getController()}/GetByTipoServicoId/${tipoServicoId}`
      , this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  updateOrdens(servicoIds) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, servicoIds, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}

