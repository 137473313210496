import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ResultadoExameDto } from '../../../domain/models';
import { AtendimentoSolicExameService } from '../../../domain/services';

@Component({
  selector: 'app-atendimento-solic-exame-comparacao',
  templateUrl: './atendimento-solic-exame-comparacao.component.html'
})
export class AtendimentoSolicExameComparacaoComponent extends BaseComponent implements OnInit {  
  resultado: ResultadoExameDto;

  chartTypes = [
    { text: 'Pizza', value: 'pie' },
    { text: 'Linha', value: 'line' },
    { text: 'Barra', value: 'bar' },
    { text: 'Barra Horizontal', value: 'horizontalBar' },
    { text: 'Radar', value: 'radar' },
    { text: 'Rosquinha', value: 'doughnut' },
    { text: 'Área Polar', value: 'polarArea' }
  ];

  chartOptions: ChartOptions;
  chartLabels: Label[] = [];
  chartData: number[] = [];
  chartDatasets = [];
  chartType: ChartType = 'pie';
  chartLegend = true;
  chartPlugins = [];
  chartColors = [];

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) private data: any,
    readonly atendimentoSolicExameService: AtendimentoSolicExameService
  ) {
    super(injector);
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {    
    this.atendimentoSolicExameService.getComparativoResultado(this.data.pacienteId, this.data.solicExame.id).subscribe(x => {
      this.resultado = x;
      this.popularChart();
    });
  }

  getProcedimentoNome(procedimentoId: string): string {
    return this.resultado?.itens?.find(x => x.procedimentoId === procedimentoId)?.procedimentoNome;
  }

  getResultado(procedimentoId: string, data: string): string {
    return this.resultado?.itens?.find(x => x.procedimentoId === procedimentoId && x.data === data)?.resultado || '';
  }

  popularChart() {
    this.chartLabels = [];
    this.chartData = [];
    this.chartDatasets = [];
    this.chartOptions = {
      responsive: true
    }    

    this.resultado.itens.forEach(item => {
      this.chartLabels.push(item.procedimentoNome);
      const data = parseFloat(item.resultado);
      this.chartData.push(data);
    });    

    if (this.chartType === 'pie' || this.chartType === 'polarArea' || this.chartType === 'doughnut') {
      this.chartLegend = true;
      this.chartColors = [];
    } else {
      if (this.chartType !== 'radar') {
        this.chartOptions = {
          responsive: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        };
      }

      this.chartLegend = false;
      this.chartDatasets.push({ data: this.chartData, label: 'Total' });
      this.chartColors = [
        {
          backgroundColor: [
            'rgba(0,0,255,0.4)',
            'rgba(0,255,0,0.4)',
            'rgba(255,0,0,0.4)',

            'rgba(0,0,200,0.4)',
            'rgba(0,200,0,0.4)',
            'rgba(200,0,0,0.4)',

            'rgba(0,0,150,0.4)',
            'rgba(0,150,0,0.4)',
            'rgba(150,0,0,0.4)',

            'rgba(0,0,100,0.4)',
            'rgba(0,100,0,0.4)',
            'rgba(100,0,0,0.4)'
          ]
        }
      ];
    }
  }
}
