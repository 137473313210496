import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';

import { PaginaWebModel } from '../domain/models';
import { PaginaWebService } from '../domain/services';
import { PaginaWebModalComponent } from './pagina-web-modal/pagina-web-modal.component';

@Component({
  selector: 'app-paginas-web',
  templateUrl: './paginas-web.component.html'
})
export class PaginasWebComponent extends BaseCrudComponent<PaginaWebModel> implements OnInit {
  @ViewChild('paginaWebModal') modal: PaginaWebModalComponent;

  selectColumns = [
    { data: 'titulo' },
    { data: 'resumo' },
    { data: 'slug' }
  ];

  constructor(
    injector: Injector,
    readonly paginaWebService: PaginaWebService
  ) {
    super(injector, paginaWebService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
