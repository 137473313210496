import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { MicroRegiaoModel } from 'projects/ProjetoBaseAngular/domain/models';
import { MicroRegiaoService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-micro-regiao-modal',
  templateUrl: './micro-regiao-modal.component.html',
})
export class MicroRegiaoModalComponent extends BaseCrudModalComponent<MicroRegiaoModel> implements OnInit {
  @ViewChild('microRegiaoModal') modal: ModalDirective;

  constructor(
    injector: Injector,
    microRegiaoService: MicroRegiaoService
  ) {
    super(injector, microRegiaoService);
  }

  protected initializeForm(microRegiao: MicroRegiaoModel) {
    this.form = this.formBuilder.group({
      id: microRegiao.id,
      dataInclusao: [microRegiao.dataInclusao, Validators.required],
      dataAlteracao: microRegiao.dataAlteracao,
      codigoIbge: [microRegiao.codigoIbge, [
        Validators.pattern(/^[0-9]*$/),
      ]
      ],
      nome: [microRegiao.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40)]
      ]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
