import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { TypesService } from 'projects/admin/src/domain/services';

import { TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProfissionalSaudeModel } from '../../../../equipe/domain/models';
import { ProfissionalSaudeService } from '../../../../equipe/domain/services';
import { PacienteModel } from '../../../../paciente/domain/models';
import { PacienteService } from '../../../../paciente/domain/services';
import { AtendimentoModel, AtestadoConvertMaskDto } from '../../../domain/models';
import { AtendimentoService } from '../../../domain/services';

@Component({
  selector: 'app-atendimento-atestado-print',
  templateUrl: './atendimento-atestado-print.component.html'
})
export class AtendimentoAtestadoPrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  tenant: TenantModel;  
  paciente: PacienteModel;  
  profissionalSaude: ProfissionalSaudeModel;
  atendimento: AtendimentoModel;
  atestado: string;
  dataAtual: string;

  constructor(
    readonly tenantService: TenantService,
    readonly atendimentoService: AtendimentoService,
    readonly pacienteService: PacienteService,    
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly printService: PrintService,
    readonly typesService: TypesService
    //readonly sanitizer: DomSanitizer
  ) { }

  ngOnInit() {    
  }

  async print(atestado, atendimentoId: string) {        
    this.tenant = await this.tenantService.getCurrentTenant().toPromise();
    
    this.atendimento = await this.atendimentoService.getById(atendimentoId).toPromise();
    this.profissionalSaude = await this.profissionalSaudeService.getByProfissionalRecursoId(this.atendimento.profissionalRecursoId).toPromise();
    this.paciente = await this.pacienteService.getById(this.atendimento.pacienteId).toPromise();

    this.dataAtual = moment().format('DD/MM/YYYY');
    const convMaskDto: AtestadoConvertMaskDto = {
      pacienteCpf: this.paciente.pessoa.cnpjCpf ?? "", 
      pacienteNome: this.paciente.pessoa.nomeFantasia, 
      profissionalNome: this.profissionalSaude.pessoa.nomeFantasia, 
      profissionalCrm: this.profissionalSaude.crmMedico,
      profissionalCrn: this.profissionalSaude.crnMedico,
      profissionalRqe: this.profissionalSaude.rqeMedico,
      data: this.dataAtual};

    this.atestado = this.atendimentoService.atestadoTextmaskToText(atestado, convMaskDto);
      
    setTimeout(() => {      
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Atestado', content);    
    }, 500);
  }  
}

