import { Component, OnInit, Injector } from '@angular/core';
import { Validators } from '@angular/forms';

import { LayoutImpressaoService } from '../domain/services';
import { LayoutImpressaoModel } from '../domain/models';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';

@Component({
  selector: 'app-layout-impressao',
  templateUrl: './layout-impressao.component.html'
})
export class LayoutImpressaoComponent extends BaseComponent implements OnInit {
  isBusy: boolean;
  newItem: boolean;

  constructor(
    injector: Injector,
    readonly service: LayoutImpressaoService
  ) {
    super(injector);
  }

  protected initializeForm(model: LayoutImpressaoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      cabecalho: [model.cabecalho],
      rodape: [model.rodape]
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.commonService.spinnerOpen('Carregando...');
    this.form = null;
    this.isBusy = false;

    this.service.getAll().subscribe(
      layouts => {
        let model: LayoutImpressaoModel;
        if (layouts?.length > 0) {
          this.newItem = false;
          model = layouts[0];
        } else {
          this.newItem = true;
          model = new LayoutImpressaoModel();
          model.id = this.commonService.newGuid();
          model.dataInclusao = new Date();
          model.registroAtivo = true;
        }
        this.initializeForm(model);
        this.commonService.spinnerClose();
      },
      () => {
        this.commonService.spinnerClose();
      }
    );
  }

  async save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    this.isBusy = true;
    let model: LayoutImpressaoModel = Object.assign({}, this.form.value);
    try {
      model = this.newItem ? await this.service.create(model).toPromise() : await this.service.edit(model).toPromise();
      this.form.patchValue({ id: model.id });
      this.newItem = false;
      this.isBusy = false;
      this.snackBar.open('Salvo com sucesso!', 'Ok', {
        duration: 3000
      });
    } catch (errors) {
      this.verifyErrors(errors);
      this.isBusy = false;
      this.commonService.mensagem(
        'Oops! Algo deu errado',
        errors.join('/n'),
        'error');
    }
  }
}
