import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ReceitaComponent } from './receita.component';
import { ReceitaModalComponent } from './receita-modal/receita-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ReceitaComponent,
    ReceitaModalComponent
  ]
})
export class ReceitaModule { }
