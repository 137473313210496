<div class="row">
  <div class="col-md-12">
    <button mat-flat-button color="primary" type="button" (click)="create()">Adicionar Item</button>
  </div>
  <hr/>
  <div class="col-md-12">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-bordered table-striped table-hover" style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center">Codigo</th>
          <th class="text-center">Nome Produto</th>
          <th class="text-center">Quantidade</th>
          <th class="text-center">Tipo</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="itens?.length != 0">
        <tr *ngFor="let item of itens">
          <td>
            {{item.codigoItem}}
          </td>
          <td>
            {{item.nome}}
          </td>
          <td>
            {{item.quantidade}}
          </td>
          <td>
            {{typeService.getComponenteSistemaNome(item.tipoItem)}}
          </td>
          <td class="text-center">
            <button mat-icon-button type="button" (click)="edit(item)" matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="delete(item.id)" matTooltip="Excluir">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-tenant-componente-modal #modal (modalSave)="onResponse($event)">
  </app-tenant-componente-modal>
</div>