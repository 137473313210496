import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseService } from './base.service';
import { DataTablesResponse } from '../models';

export abstract class BaseCrudService<TModel> extends BaseService {
  
  constructor(
    injector: Injector
  ) {
    super(injector)
  }  

  public abstract getController(): string;

  // Temporariamente desabilitado e sera excluido, pois agora é controlado pelo token e claims
  // hasPermission(action): Observable<boolean> {
  //   return this.httpClient.get(`${this.getApiUrl()}${this.getController()}/HasPermission/${action}`, this.getAuthHeaderJson())
  //     .pipe(
  //       map((response: any) => response),
  //       catchError(this.serviceError)
  //     );
  // }
  
  create(model: TModel): Observable<TModel> {
    return this.httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Create`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  edit(model: TModel): Observable<TModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Edit`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  delete(id: string): Observable<TModel> {
    return this.httpClient.delete(`${this.getApiUrl()}${this.getController()}/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getById(id: string): Observable<TModel> {
    return this
      .httpClient
      .get<TModel>(`${this.getApiUrl()}${this.getController()}/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelectById(id: string, selectColumns: any[]): Observable<any> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/GetSelectById/${id}`, JSON.stringify(selectColumns), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getResponse(dataTablesParameters: any): Observable<DataTablesResponse> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}`, dataTablesParameters, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }  
}
