import { BaseModel } from "../base/base-model";

import { DocStatusType } from "../../types";
import { DocMovtoModel } from "./doc-movto-model";

export class BaseDocContainerModel extends BaseModel {
    public codigoDocMovto: number;
    public docMovto: DocMovtoModel = new DocMovtoModel;    
    public statusDocumento: DocStatusType = DocStatusType.Draft;
    public observacoes: string;
    public subTotal: number;
    public percentualDesconto?: number;
    public valorDesconto?: number;
    public percentualAcrescimo?: number;
    public valorAcrescimo?: number;        
    public total: number;

    //Servem para facilitar a manipulação de dados no html
    public dataEmissao?: Date;
    public dataAplicacao?: Date;
    //#endregion
}