import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';
import { CidadesModule } from './cidades/cidades.module';
import { ConfigsModule } from './configs/configs.module';
import { CoresModule } from './cores/cores.module';
import { EstadosModule } from './estados/estados.module';
import { MicroRegiaoModule } from './micro-regioes/micro-regiao.module';
import { UnidadeMedidasModule } from './unidadeMedidas/unidadeMedidas.module';

@NgModule({
  imports: [
    SharedModule,
    CidadesModule,
    ConfigsModule,
    CoresModule,
    EstadosModule,
    UnidadeMedidasModule,
    MicroRegiaoModule
  ]
})
export class GeralModule { }
