import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { TypesService } from 'projects/admin/src/domain/services';
import { SolicitacaoOnlineService } from '../../domain/services/solicitacao-online.service';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { SolicitacaoOnlineModel, WhatsChatModel, WhatsChatMsgModel } from '../../domain/models';
import { WhatsChatMsgService } from '../../domain/services/whatschat-msg.service';
import * as moment from 'moment';
import { WhatsChatMsgMidiaType, WhatsChatMsgType } from '../../domain/types';
import { WhatsChatAtendenteService } from '../../domain/services/whatschat-atendente.service';

@Component({
  selector: 'app-bot-doc-modal',
  templateUrl: './bot-doc-modal.component.html',
  styleUrls: ['./bot-doc-modal.component.css']
})
export class BotDocModalComponent extends BaseComponent implements OnInit{
  @ViewChild('modal') modal: ModalDirective;
  
  model: any = null;

  listaMsg: WhatsChatMsgModel[] = [];
  whatsChatMsgType: typeof WhatsChatMsgType = WhatsChatMsgType;
  whatsChatMsgMidiaType: typeof WhatsChatMsgMidiaType = WhatsChatMsgMidiaType;
  
  constructor(
    injector: Injector,
    readonly service: SolicitacaoOnlineService,
    readonly msgService: WhatsChatMsgService,
    readonly atendenteService: WhatsChatAtendenteService,
    readonly typesService: TypesService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();  
  }

  async show(model: SolicitacaoOnlineModel) {
    const strSpace = "\xa0";

    model['statusNome'] = this.typesService.getSolicitacaoOnlineStatusTypeNome(model.status);
    model['statusNome'] = strSpace + model['statusNome'] + strSpace;
    model['statusColor'] = this.typesService.getSolicitacaoOnlineStatusTypeColor(model.status);
    
    model['tipoNome'] = this.typesService.getBotAtendimentoTypeNome(model.tipo);
    model['tipoNome'] = strSpace + model['tipoNome'] + strSpace;
    model['tipoColor'] = this.typesService.getBotAtendimentoTypeColor(model.tipo);

    this.model = model;

    await this.getMsgChat();

    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  async getMsgChat() {
    this.listaMsg = await this.msgService.getByChatId(this.model.chatId).toPromise();

    this.listaMsg.forEach(item => {
      item.mensagem = this.commonService.formatWhatsToHtml(item.mensagem);
      item.mensagem += `<br><br><sup>${moment(item.dataHora).format('DD/MM/YYYY HH:mm')}</sup>`;
    });
  }
}
