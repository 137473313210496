import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { SolicitacaoLgpdType, StatusLgpdType } from 'projects/admin/src/domain/types';
import { RequisicaoLgpdModel } from '../domain/models';
import { RequisicaoLgpdService } from '../domain/services';
import { RequisicaoLgpdModalComponent } from './requisicao-lgpd-modal/requisicao-lgpd-modal.component';

@Component({
  selector: 'app-requisicao-lgpd',
  templateUrl: './requisicao-lgpd.component.html'
})
export class RequisicaoLgpdComponent extends BaseCrudComponent<RequisicaoLgpdModel> implements OnInit {
  @ViewChild('requisicaoLgpdModal', { static: false }) modal: RequisicaoLgpdModalComponent;

  tiposSolicitacoes = [
    { text: 'Verificar Dados', value: SolicitacaoLgpdType.VerificarDados },
    { text: 'Alterar/Consultar Dados', value: SolicitacaoLgpdType.AlterarConsultarDados },
    { text: 'Exclusão de Dados', value: SolicitacaoLgpdType.ExclusaoDados },
    { text: 'Outros', value: SolicitacaoLgpdType.Outros }
  ];

  statusPossiveis = [
    { text: 'Pendente', value: StatusLgpdType.Pendente },
    { text: 'Em Processo', value: StatusLgpdType.EmProcesso },
    { text: 'Finalizado', value: StatusLgpdType.Finalizado }
  ];

  opcoesDatas = [
    { text: 'Data do Contato', value: 1 },
    { text: 'Data do Status', value: 2 }
  ];

  filtroDataSelecionado: number;

  searchStatus = new ModelSearch('status', OptionSearchType.Equals, SearchType.Int);
  searchTipoSolicitacao = new ModelSearch('tipoSolicitacao', OptionSearchType.Equals, SearchType.Int);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  searchDataInicialContato = new ModelSearch('data', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinalContato = new ModelSearch('data', OptionSearchType.LessThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)));

  searchDataInicialStatus = new ModelSearch('dataStatus', OptionSearchType.GreaterThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(moment().startOf('month').toDate()));
  searchDataFinalStatus = new ModelSearch('dataStatus', OptionSearchType.LessThanOrEqual, SearchType.DateTime,
    this.fromJsonDate(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)));

  selectColumns = [
    { data: 'data' },
    { data: 'status' },
    { data: 'dataStatus' },
    { data: 'nome' },
    { data: 'tipoSolicitacao' },
    { data: 'observacaoInterna' }
  ];

  constructor(
    injector: Injector,
    readonly setorService: RequisicaoLgpdService,
  ) {
    super(injector, setorService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchStatus.searchTerm) && searches.push(this.searchStatus);
    (this.search.searchTerm) && searches.push(this.search);
    (this.searchTipoSolicitacao.searchTerm) && searches.push(this.searchTipoSolicitacao);

    if (this.filtroDataSelecionado === 1) {
      (this.searchDataInicialContato.searchTerm) && searches.push(this.searchDataInicialContato);
      (this.searchDataFinalContato.searchTerm) && searches.push(this.searchDataFinalContato);

    } else if (this.filtroDataSelecionado === 2) {
      (this.searchDataInicialStatus.searchTerm) && searches.push(this.searchDataInicialStatus);
      (this.searchDataFinalStatus.searchTerm) && searches.push(this.searchDataFinalStatus);
    }

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.order = [[0, 'desc']];
  }

  getTipoSolicitacao(solicitacao: SolicitacaoLgpdType): string {
    switch (solicitacao) {
      case SolicitacaoLgpdType.VerificarDados:
        return 'Verificar Existencia de Dados';
      case SolicitacaoLgpdType.AlterarConsultarDados:
        return 'Alterar/Consultar Dados';
      case SolicitacaoLgpdType.ExclusaoDados:
        return 'Exclusão de Dados';
      case SolicitacaoLgpdType.Outros:
        return 'Outros';
    }
  }

  getStatusPossiveis(status: StatusLgpdType): string {
    switch (status) {
      case StatusLgpdType.Pendente:
        return 'Pendente';
      case StatusLgpdType.EmProcesso:
        return 'Em Processo';
      case StatusLgpdType.Finalizado:
        return 'Finalizado';
    }
  }

  getSearchData(value: number): string {
    switch (value) {
      case 1:
        return 'Data do Contato';
      case 2:
        return 'Data do Status';
      case 3:
        return 'Nao filtrar data';
    }
  }
}
