import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { VagaModel } from '../domain/models';
import { VagaService } from '../domain/services';
import { VagaModalComponent } from './vaga-modal/vaga-modal.component';

@Component({
  selector: 'app-vaga',
  templateUrl: './vaga.component.html',
})
export class VagaComponent extends BaseCrudComponent<VagaModel> implements OnInit {
  @ViewChild('vagaModal') modal: VagaModalComponent;

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'titulo' },
    { data: 'cargo.nome' },
    { data: 'registroAtivo' }
  ];

  constructor(
    injector: Injector,
    readonly vagaService: VagaService
  ) {
    super(injector, vagaService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
