import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { EntregaExameComponent } from './entrega-exame.component';
import { EntregaExameModalComponent } from './entrega-exame-modal/entrega-exame-modal.component';
import { PacienteModule } from '../../paciente/paciente/paciente.module';
import { EntregaExamePrintComponent } from './entrega-exame-print/entrega-exame-print.component';
import { ReportModule } from '../../report/report.module';

@NgModule({
  imports: [    
    SharedModule,
    PacienteModule,
    ReportModule
  ],
  declarations: [
    EntregaExameComponent,
    EntregaExameModalComponent,
    EntregaExamePrintComponent
  ]
})
export class EntregaExameModule { }
