<div bsModal #cidadeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="cidadeModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-lg">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Cidade</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{error}}</li>
						</ul>
					</div>
					<div class="row">
						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Código IBGE</mat-label>
								<input matInput formControlName="codigoIbge" type="text" #focus />
								<mat-error>Informe um Código IBGE válido.</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-3">
							<mat-form-field>
								<mat-label>Estado</mat-label>
								<mat-select formControlName="estadoId">
									<mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.uf}}
									</mat-option>
								</mat-select>
								<mat-error>Selecione um estado.</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-6">
							<mat-form-field>
								<mat-label>Nome</mat-label>
								<input matInput formControlName="nome" type="text" />
								<mat-error>Informe um Nome válido.</mat-error>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button  [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>