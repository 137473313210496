import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { DocSusConfigModel } from '../../domain/models';
import { DocSusConfigService } from '../../domain/services/doc-sus.-config.service';


@Component({
  selector: 'app-doc-sus-config-modal',
  templateUrl: './doc-sus-config-modal.component.html'
})
export class DocSusConfigModalComponent extends BaseCrudModalComponent<DocSusConfigModel> implements OnInit {
  @ViewChild('configModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  constructor(
    injector: Injector,
    readonly service: DocSusConfigService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: DocSusConfigModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataAlteracao: model.dataAlteracao,
      codigoCnes: [model.codigoCnes],
      tfdImagemUrl: [model.tfdImagemUrl, [
        Validators.maxLength(200)]
      ],
      tfdImagemUrlBase: [model.tfdImagemUrlBase],
      tfdImagemPath: [model.tfdImagemPath]
    });
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    console.log(imagem);
    this.form.patchValue({
      tfdImagemUrl: imagem.url,
      tfdImagemUrlBase: imagem.urlBase,
      tfdImagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      tfdImagemUrl: null,
      tfdImagemUrlBase: null,
      tfdImagemPath: null
    });
  }
}