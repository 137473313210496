import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { GrupoPacienteService } from '../../domain/services';
import { GrupoPacienteModel } from '../../domain/models';

@Component({
  selector: 'app-grupo-paciente-modal',
  templateUrl: './grupo-paciente-modal.component.html'
})
export class GrupoPacienteModalComponent extends BaseCrudModalComponent<GrupoPacienteModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: GrupoPacienteService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: GrupoPacienteModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      
      nome: [model.nome, [        
        Validators.required, Validators.maxLength(50)        
      ]],
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
