import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PacienteProntuarioDto, PacienteProntuarioModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PacienteProntuarioService extends BaseCrudService<PacienteProntuarioModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'PacienteProntuario';
  }

  getByPacienteId(pacienteId: string): Observable<PacienteProntuarioModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByPacienteId/${pacienteId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelectListByPacienteId(id: string): Observable<PacienteProntuarioDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectListByPacienteId/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getCountByPacienteId(id: string): Observable<number> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetCountByPacienteId/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}
