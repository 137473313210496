import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AgendaDisponibilidadeModalComponent } from './agenda-disponibilidade-modal/agenda-disponibilidade-modal.component';
import { AgendaDisponibilidadeEventualModel, AgendaDisponibilidadeModel } from '../domain/models';
import { AgendaDisponibilidadeService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { AgendaDisponibilidadeEventualModalComponent } from './agenda-disponibilidade-eventual-modal/agenda-disponibilidade-eventual-modal.component';
import { AgendaDisponibilidadeEventualService } from '../domain/services/agenda-disponibilidade-eventual.service';

@Component({
  selector: 'app-agenda-disponibilidade',
  templateUrl: './agenda-disponibilidade.component.html'
})
export class AgendaDisponibilidadeComponent extends BaseCrudComponent<AgendaDisponibilidadeModel> implements OnInit {
  @ViewChild('modal') modal: AgendaDisponibilidadeModalComponent;
  @ViewChild('modalEventual') modalEventual: AgendaDisponibilidadeEventualModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;

  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  recursoId: string;
  recursoNome: string;
  data: Date;

  selectColumns = [
    { data: 'diaSemana' },
    { data: 'horarioInicial' },
    { data: 'horarioFinal' },
    { data: 'duracao' },
    { data: 'classificacao.nome' }
  ];

  listaEventual: AgendaDisponibilidadeEventualModel[];

  constructor(
    injector: Injector,
    readonly service: AgendaDisponibilidadeService,
    readonly serviceEventual: AgendaDisponibilidadeEventualService,

    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.order = [[0, 'asc'], [1, 'asc']];
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.recursoId) {
      dataTablesParameters.searches.push(new ModelSearch('profissionalRecursoId', OptionSearchType.Equals, SearchType.Guid, this.recursoId));
    }
    return super.beforeDtRequest(dataTablesParameters);
  }

  onResponse(id: string) {
    super.onResponse(id);
    this.filter();
  }

  async show(recursoId: string, recursoNome: string, data?: Date) {
    this.recursoId = recursoId;
    this.recursoNome = recursoNome;

    
    if (data) {
      this.data = data;
    } else {
      this.data = new Date();
    }

    this.filter();
    this.listaEventual = await this.serviceEventual.getByRecursoIdAndPeriodo(this.recursoId, this.fromJsonDate(this.data), "null").toPromise();

    this.listModal.show();
  }

  close() {
    this.listModal.hide();
    this.modalClose.emit();
  }

  create() {
    const model = new AgendaDisponibilidadeModel();
    model.dataInclusao = new Date();
    model.id = this.commonService.newGuid();
    model.profissionalRecursoId = this.recursoId;
    super.create(model);
  }

  edit(model: AgendaDisponibilidadeModel) {
    this.modal.showEditByRecursoId(model.id, this.recursoId);
  }

  onResponseEventual(id: string) {
    if (!id || !(this.listaEventual?.length > 0)) {
      this.serviceEventual.getByRecursoIdAndPeriodo(this.recursoId, this.fromJsonDate(this.data), "null")
        .subscribe(x => this.listaEventual = x);
      return;
    }

    this.serviceEventual.getById(id).subscribe(model => {
      const index = this.listaEventual.findIndex(x => x.id === model.id);
      if (index === -1) {
        this.listaEventual.push(model);
      } else {
        this.listaEventual[index] = model;
      }
    });

    this.snackBar.open('Salvo com sucesso!', 'Ok', {
      duration: 3000
    });
  }

  createEventual() {
    const model = new AgendaDisponibilidadeEventualModel();
    model.dataInclusao = new Date();
    model.data = new Date();
    model.id = this.commonService.newGuid();
    model.profissionalRecursoId = this.recursoId;
    this.modalEventual.showCreate(model);
  }

  editEventual(model: AgendaDisponibilidadeModel) {
    this.modalEventual.showEditByRecursoId(model.id, this.recursoId);
  }

  async deleteEventual(id: string) {
    let result = await this.commonService.mensagemConfirmacao("Confirmar exclusão deste registro?", "", "warning");
    if (result) {
      this.serviceEventual.delete(id).subscribe();
      let index = this.listaEventual.findIndex(x => x.id === id);
      this.listaEventual.splice(index, 1);
    }
  }
}
