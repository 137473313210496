import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SetorService } from '../../domain/services/setor.service';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { SetorModel } from '../../domain/models';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { ImagemModel, UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-setor-modal',
  templateUrl: './setor-modal.component.html'
})
export class SetorModalComponent extends BaseCrudModalComponent<SetorModel> implements OnInit {
  @ViewChild('setorModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  usuarios: UsuarioModel[];

  constructor(
    injector: Injector,
    setorService: SetorService,
    private readonly usuarioService: UsuarioService
  ) {
    super(injector, setorService);
  }

  protected initializeForm(setor: SetorModel) {
    this.form = this.formBuilder.group({
      id: setor.id,
      dataInclusao: [setor.dataInclusao, Validators.required],
      dataAlteracao: setor.dataAlteracao,
      nome: [setor.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40)]
      ],
      imagemUrl: [setor.imagemUrl],
      imagemUrlBase: [setor.imagemUrlBase],
      imagemPath: [setor.imagemPath],
      usuarioResponsavelId: [setor.usuarioResponsavelId, Validators.required],
      liberadoContatoExterno: [setor.liberadoContatoExterno]
    });
  }

  beforeShowCreate(model: SetorModel): SetorModel {
    model.liberadoContatoExterno = false;
    return super.beforeShowCreate(model);
  }

  ngOnInit() {
    super.ngOnInit();
    this.usuarioService.getAll().subscribe(usuarios => this.usuarios = usuarios);
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({ 
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }
}

