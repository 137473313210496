import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import * as moment from 'moment';
moment.locale('pt-BR');

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { AtendimentoModalComponent } from './atendimento-modal/atendimento-modal.component';
import { AtendimentoFilterDto, AtendimentoModel } from '../domain/models';
import { AgendamentoService, AtendimentoService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PacienteModel } from '../../paciente/domain/models';
import { PacienteService } from '../../paciente/domain/services';
import { ProfissionalRecursoService } from '../domain/services/profissional-recurso.service';
import { AtendimentoStatusType, AtendimentoType } from '../domain/types';
import { DocStatusType, OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { ProfissionalRecursoType } from 'projects/admin/src/domain/types';
// import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
// import { environment } from 'environments/environment';

@Component({
  selector: 'app-atendimento',
  templateUrl: './atendimento.component.html'
})
export class AtendimentoComponent extends BaseCrudComponent<AtendimentoModel> implements OnInit {
  @ViewChild('modal') modal: AtendimentoModalComponent;

  // private hubConnection: HubConnection;

  isBusy: boolean = true;
  atendimentoFilterDto = new AtendimentoFilterDto();
  recursos: SelectDto[];
  paciente: PacienteModel;
  anotacoes: string;
  anotacoesOld: string;

  atendimentoType: typeof AtendimentoType = AtendimentoType;

  selectColumns = [
    { data: 'dataHoraEntrada' },    
    { data: 'agendamento?.horario' },        
    { data: 'profissionalRecurso.nome'},
    { data: 'agendamento?.classificacao?.nome' },
    { data: 'status' },
    { data: 'paciente.pessoa.nomeFantasia' },    
    { data: 'convenio.nome' },
    { data: 'agendamentoId', hidden: true } 
  ];

  constructor(
    injector: Injector,
    readonly service: AtendimentoService,
    readonly agendamentoService: AgendamentoService,
    readonly typesService: TypesService,
    readonly pacienteService: PacienteService,
    readonly profissionalRecursoService: ProfissionalRecursoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    
    this.atendimentoFilterDto.data = this.fromJsonDate(new Date());
    this.atendimentoFilterDto.status = [AtendimentoStatusType.EmAtendimento, AtendimentoStatusType.EsperaAtendimento, AtendimentoStatusType.NaoAtendido];
    
    super.ngOnInit();

    this.dtOptions.order = [[0, 'desc']];
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
    
    this.atendimentoFilterDto.recursoIds = [];
    const recursoIds = localStorage.getItem('app.recursoIds');
    if (recursoIds) {
      this.atendimentoFilterDto.recursoIds = localStorage.getItem('app.recursoIds').split(',');
      this.changeRecurso(this.atendimentoFilterDto.recursoIds);
    }

    this.profissionalRecursoService.getSelectListUser().subscribe(x => {
      this.recursos = x; 
      
      this.isBusy = false;
    });

    this.paciente = new PacienteModel();
    this.paciente.pessoa = new PessoaModel();
    this.paciente.pessoa.cnpjCpf = '';
    this.paciente.pessoa.nomeFantasia = '';

    this.refreshAnotacoes();

    // let builder = new HubConnectionBuilder();
    // this.hubConnection = builder.withUrl(`${environment.baseUrl}/hub?tenant=${localStorage.getItem('app.tenant')}`).build();
    // this.hubConnection.start();
    // this.hubConnection.on('Send', (type: string) => {      
    //   if (type === 'update-anotacoes') {
    //     this.refreshAnotacoes();
    //   }
    // });
    // this.hubConnection.onclose(() => {
    //   this.hubConnection.start();
    // });
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {    
    dataTablesParameters = super.beforeDtRequest(dataTablesParameters);
    dataTablesParameters.searches.push(new ModelSearch('statusDocumento', OptionSearchType.GreaterThan, SearchType.Int, DocStatusType.Draft));        
    dataTablesParameters.searches.push(new ModelSearch('profissionalRecurso.tipo', OptionSearchType.Equals, SearchType.Int, ProfissionalRecursoType.ProfissionalSaude));        
    
    const data = this.atendimentoFilterDto.data;
    this.atendimentoFilterDto.data = this.fromJsonDate(moment(data).toDate());
    dataTablesParameters.customParam = JSON.stringify(this.atendimentoFilterDto);
    this.atendimentoFilterDto.data = data;
    return dataTablesParameters;
  }  

  async undoAppliedDoc(nota: AtendimentoModel) {
    let desfazer = await this.commonService.mensagemConfirmacao("Atenção", "Defazer aplicação de nota de compra?", "question");
    if (desfazer) {
      this.service.undoAppliedDoc(nota.id)
        .subscribe(
          result => {
            if (result) {
              this.service.getById(nota.id).subscribe(model => {
                this.onResponse(model.id);
              });
            }
          },
          errors => this.commonService.mensagem("Erro!", errors.join('<br/>'), "error")
        );
    }
  }
  
  prevDayClick() {
    this.atendimentoFilterDto.data = this.fromJsonDate(moment(this.atendimentoFilterDto.data).add(-1, 'days').toDate());
    this.filter();
  }

  nextDayClick() {
    this.atendimentoFilterDto.data = this.fromJsonDate(moment(this.atendimentoFilterDto.data).add(1, 'days').toDate());
    this.filter();
  }

  todayClick() {
    this.atendimentoFilterDto.data = this.fromJsonDate(new Date());
    this.filter();
  }

  get isToday(): boolean {
    return this.fromJsonDate(moment(this.atendimentoFilterDto.data).toDate()) === this.fromJsonDate(new Date());
  }

  onPacienteResponse(pacienteId: string) {
    this.atendimentoFilterDto.pacienteId = pacienteId;
    if (!pacienteId) {      
      this.paciente = new PacienteModel();
      this.paciente.pessoa = new PessoaModel();
      this.paciente.pessoa.cnpjCpf = '';
      this.paciente.pessoa.nomeFantasia = '';
      this.filter();
    }
    else {
      this.pacienteService.getById(pacienteId).subscribe(x => {
        this.paciente = x;
        this.filter();
      });
    }
  }

  changeRecurso(recursoIds) {    
    localStorage.setItem('app.recursoIds', recursoIds.join(','));        
    this.filter();    
  }

  saveAnotacoes() {
    this.agendamentoService.saveAnotacoes(this.anotacoes).subscribe(() => {
      this.anotacoesOld = this.anotacoes;
    });
  }  

  refreshAnotacoes() {
    this.agendamentoService.getAnotacoes().subscribe(x => {
      this.anotacoes = x;
      this.anotacoesOld = x;
    });
  }  
}
