import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EquipeModel } from '../models';
import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';


@Injectable({
  providedIn: 'root'
})
export class EquipeService extends BaseEntidadePessoaService<EquipeModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Equipe';
  }

  get(): Observable<EquipeModel[]> {
    return this
      .httpClient
      .get<EquipeModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAll(): Observable<EquipeModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getBySetorCargoId(setorId: string, cargoId: string): Observable<EquipeModel[]> {
    return this
      .httpClient
      .get<EquipeModel>(`${this.getApiUrl()}${this.getController()}/GetBySetorCargoId/${setorId}/${cargoId}`,
        this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  updateOrdens(equipeIds) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, equipeIds, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
