import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';

import { ContatoSiteModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ContatoSiteService extends BaseCrudService<ContatoSiteModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ContatoSite';
  }

  getAsync(): Observable<ContatoSiteModel[]> {
    return this
      .httpClient
      .get<ContatoSiteModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getMsgAceiteContatoSite(): Observable<string> {
    return this
      .httpClient
      .get<string>(`${this.getApiUrl()}${this.getController()}/GetMsgAceiteContatoSite`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  send(model: ContatoSiteModel): Observable<ContatoSiteModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Send`, JSON.stringify(model), this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
