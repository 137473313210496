import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ImagemModel, PaginaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ResponsePage } from 'projects/ProjetoBaseAngular/domain/models/response-page';
import { ImagemService } from 'projects/ProjetoBaseAngular/domain/services';
import { ImagemModalComponent } from './imagem-modal/imagem-modal.component';
import { ImagemVinculosModalComponent } from './imagem-vinculos-modal/imagem-vinculos-modal.component';

@Component({
  selector: 'app-admin-imagem',
  templateUrl: './imagens.component.html'
})
export class ImagensComponent extends BaseCrudComponent<ImagemModel> implements OnInit {
  @ViewChild('imagemModal') modal: ImagemModalComponent;
  @ViewChild('imagemVinculosModal') vinculosModal: ImagemVinculosModalComponent;

  responsePage: ResponsePage<ImagemModel>;
  vinculoPaginaIds: string = '';
  paginas: PaginaModel[];

  constructor(
    injector: Injector,
    private readonly imagemService: ImagemService
  ) {
    super(injector, imagemService);
  }  

  ngOnInit() {
    super.ngOnInit();
    this.filter();

    this.imagemService.getVinculoPaginas().subscribe(paginas => {
      this.paginas = paginas;      
    });
  }

  filter(page = 1) {    
    this.imagemService.getByPage(this.vinculoPaginaIds, page).subscribe(responsePage => {
      this.responsePage = responsePage;
      $(document).ready(() => {        
        $('#aniimated-thumbnials').lightGallery({
          thumbnail: true,
          selector: 'a.image-galery'
        });
      });
      $('#aniimated-thumbnials').data('lightGallery').destroy(true);
    });
  }

  fromNow(imagem: ImagemModel){
    return moment(imagem.dataAlteracao || imagem.dataInclusao).fromNow();
  }  

  showVinculosImagem(imagem: ImagemModel) {
    this.vinculosModal.show(imagem);
  }
}
