import { BaseModel } from "projects/ProjetoBaseAngular/domain/models/base/base-model";
import { BotAtendimentoType, BotFluxoType, BotNodoType, ConfPresencaFilaStatusType, SolicitacaoOnlineStatusType, WhatsChatMsgCampoType, WhatsChatMsgMidiaType, WhatsChatMsgType, WhatsChatStatusType, WhatsChatType } from "./types";


export class WhatsChatModel extends BaseModel {
  public numeroWhatsApp: string;
  public nomeContato: string;
  public profileImgUrl: string;
  public pacienteId: string;
  public agendamentoId: string;
  public usuarioId: string;
  public ultimaIteracao: Date;
  public status: WhatsChatStatusType;
  public tipoChat: WhatsChatType;
  public tipoFluxo: BotFluxoType;
  public tipoAtendimento: BotAtendimentoType;
  public tempoLimite: string;
  public tempoVida: string;
  public ultimaMsgEnviada: string;
  public dataHoraUltimaMsgEnviada: Date;
  public ultimaMsgRecebida: string;
  public dataHoraUltimaMsgRecebida: Date;
  public chatBotNodoMascara: string;
  public chatBotNodoId: string;
  public qtdMsgNaoVista: number;

  //este campo só existe no frontend
  public selected: string;
  public nomeUsuario: string;
  public textoRascunho: string;
}

export class WhatsChatMsgModel extends BaseModel {
  public chatId: string;
  public tipo: WhatsChatMsgType;
  public tipoMidia: WhatsChatMsgMidiaType;
  public dataHora: Date;
  public mensagem: string;
  public arquivoUrlBase: string;
  public arquivoPath: string;
  public arquivoUrl: string;
  public usuarioId: string;
  public msgValida: boolean;
  public tipoCampo: WhatsChatMsgCampoType;
  public valorCampo: string;
  public whatsAppMsgId: string;
  public whatsAppTimestamp: Date;
  public media: WhatsChatMsgMediaModel;
}

export class WhatsChatMsgMediaModel extends BaseModel {
  public chatMsgId: string;
  public url: string;
  public directPath: string;
  public mediaKey: string;
  public mimetype: string;
  public fileEncSha256: string;
  public fileSha256: string;
  public fileLength: number;
  public caption: string;
  public height: number;
  public width: number;
  public pageCount: number;
  public seconds: number;
  public thumbnailUrlBase: string;
  public thumbnailPath: string;
  public thumbnailUrl: string;
}

/**
 * Classe de dto de filtro de pesquisa
 */
export class WhatsChatFiltroDto {
  public idWhatsApp: string;
  public nomeContato: string;
  public dataInicial: Date;
  public dataFinal: Date;
  public pacienteId: string;
  public agendamentoId: string;
  public usuarioId: string;
  public status: WhatsChatStatusType;
  public tipoChat: WhatsChatType;
  public tipoFluxo: BotFluxoType;
}

export class ChatBotNodoModel extends BaseModel {
  public mascara: string;
  public nome: string;
  public tipoFluxo: BotFluxoType;
  public tipoAtendimento: BotAtendimentoType;
  public tipo: BotNodoType;
  public mensagem: string;
  public validarResposta: boolean;
  public respostaOpcoes: string;
  public respostaValores: string;
  public proxNodo: string;
  public tipoCampo: WhatsChatMsgCampoType;
  public tempoLimite: string;
}

export class ChatBotRespAlternativaModel extends BaseModel {
  public chatBotNodoId: string;
  public respostaAlternativa: string;
  public valor: string;
}

export class SolicitacaoOnlineModel extends BaseModel {
  public chatId: string;
  public codigo: number;
  public tipo: BotAtendimentoType;
  public status: SolicitacaoOnlineStatusType;
  public numeroPaciente: string
  public nomePaciente: string;
  public nomeMedico: string;
  public convenio: string;
  public mensagemSolicitacao: string;
  public usuarioId: string;
  public pacienteId: string;

  //este campo só existe no frontend
  public selected: string;
  public nomeUsuario: string;
}

export class WhatsMsgAtendenteDto {
  public idWhatsApp: string;
  public nomeContato: string;
  public pacienteId: string;
  public agendamentoId: string;
  public usuarioId: string;
  public usuarioNome: string;
  public texto: string;
  public imagemBase64: string;
  public base64: string;
  public caption: string;
  public fileName: string;
  public mimeType: string;
}

export class WhatsAppUserInfoDto {
  public fone: string;
  public contato: string;
  public isOnWhatsApp: boolean;
  public profileImgUrl: string;
  public status: string;
  public dataInicial: Date;
}

export class SolicitacaoOnlineFiltroDto {
  public status: SolicitacaoOnlineStatusType;
  public codigo: number;
  public tipo: BotAtendimentoType;
  public dataInicial: Date;
  public dataFinal: Date;
  public nomeMedico: string;
}

export class ConfirmacaoPresencaFilaModel extends BaseModel {
  public agendamentoId: string;
  public numeroWhatsApp: string;
  public texto: string;
  public dataVencimento: Date;
  public status: ConfPresencaFilaStatusType;
}

export class ListaConfPresencaFilaDto {
  public reenvio: boolean;
  public lista: ConfirmacaoPresencaFilaModel[];
}