import { BaseModel } from "./base-model";
import { PessoaType, SexoType } from "projects/ProjetoBaseAngular/domain/types"

export class BasePessoaModel extends BaseModel {
    public codigo: number;
    public tipoPessoa: PessoaType = PessoaType.Fisica;
    public nomeFantasia: string;
    public razaoSocial: string;
    public cnpjCpf: string;
    public iestadualRg: string;
    public imunicipal: string;
    public sexo: SexoType = SexoType.NaoDefinido;
    public site: string;
    public dataNascimento: Date;
    public imagemUrl: string;
    public imagemUrlBase: string;
    public imagemPath: string;
    public celular: string;
    public telefone: string;
    public email: string;

    public ruaAvenida: string;
    public numero: string;
    public complemento: string;
    public bairro: string;
    public cep: string;
    public estadoId: string;
    public estadoNome: string;
    public estadoUf: string;
    public cidadeId: string;
    public cidadeNome: string;
}
