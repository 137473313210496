import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { GrupoQuestaoItemModel, GrupoQuestaoOpcaoModel } from '../models';


@Injectable({
  providedIn: 'root'
})
export class GrupoQuestaoOpcaoService extends BaseCrudService<GrupoQuestaoOpcaoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'grupoQuestaoOpcao';
  }

  getByGrupoQuestaoId(id: string): Observable<GrupoQuestaoOpcaoModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByGrupoQuestaoId/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  updateOrdens(ids) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, ids, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
