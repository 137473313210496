import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { TermoService } from '../../domain/services';
import { TermoModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-termo-modal',
  templateUrl: './termo-modal.component.html'
})
export class TermoModalComponent extends BaseCrudModalComponent<TermoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: TermoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }
  
  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: TermoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      nome: [model.nome, [
        Validators.maxLength(50)
      ]],
      texto: [model.texto],
    });
  }
}
