import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services/types.service';

import { AgendamentoRecorrenteModel, ClassificacaoDto } from '../../domain/models';
import { AgendamentoRecorrenteService } from '../../domain/services/agendamento-recorrente.service';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { FormControl, Validators } from '@angular/forms';
import { PacienteModel } from '../../../paciente/domain/models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ConvenioDto } from '../../../parceiro/domain/models';

@Component({
  selector: 'app-agendamento-recorrente',
  templateUrl: './agendamento-recorrente.component.html'
})
export class AgendamentoRecorrenteComponent extends BaseCrudModalComponent<AgendamentoRecorrenteModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  emitirSalvamento: boolean = false;
  registroAtivo: boolean = true;

  agendamentoId: string;
  paciente: PacienteModel;
  recursoDto: SelectDto;

  classificacoes: ClassificacaoDto[];
  filteredClassificacoes: ClassificacaoDto[] = [];
  public classificacaoFilterCtrl: FormControl = new FormControl();

  convenios: ConvenioDto[];
  filteredConvenios: ConvenioDto[] = [];
  public convenioFilterCtrl: FormControl = new FormControl();

  constructor(
    injector: Injector,
    readonly service: AgendamentoRecorrenteService,
    readonly typesService: TypesService,
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  show(configRecorr) {
    this.agendamentoId = configRecorr.agendamentoId;
    this.paciente = configRecorr.paciente;
    this.recursoDto = configRecorr.recursoDto;

    this.convenios = configRecorr.convenios;
    this.filteredConvenios = this.convenios;
    this.convenioFilterCtrl.valueChanges.subscribe(() => this.filterConvenio());

    this.classificacoes = configRecorr.classificacoes;
    this.filteredClassificacoes = this.classificacoes;
    this.classificacaoFilterCtrl.valueChanges.subscribe(() => this.filterClassificacao());

    if (configRecorr.novo) {
      this.showCreate(configRecorr.model);
    } else {
      if (!configRecorr.detailMode) {
        this.showEdit(configRecorr.model.id);
      } else {
        this.showDetail(configRecorr.model.id);
      }
    }
  }
  
  private filterConvenio() {
    if (!this.convenios) {
      return;
    }
    // pega a palavra chave da consulta
    let search = this.convenioFilterCtrl.value;

    if (!search) {
      this.filteredConvenios = this.convenios.slice();
      return;
    } else {
      search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    }

    // filtra para a nova lista
    this.filteredConvenios = this.convenios.filter(x =>
      x["normalizado"].toLowerCase().startsWith(search)
    );
  }

  private filterClassificacao() {
    if (!this.classificacoes) {
      return;
    }
    // pega a palavra chave da consulta
    let search = this.classificacaoFilterCtrl.value;

    if (!search) {
      this.filteredClassificacoes = this.classificacoes.slice();
      return;
    } else {
      search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    }

    // filtra para a nova lista
    this.filteredClassificacoes = this.classificacoes.filter(x =>
      x["normalizado"].toLowerCase().startsWith(search)
    );
  }

  initializeForm(model: AgendamentoRecorrenteModel) {
    this.form = this.formBuilder.group({
      id: [model.id],
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: [this.fromJsonDate(model.dataAlteracao)],
      registroAtivo: [model.registroAtivo],
      periodo: [model.periodo],
      pacienteId: [model.pacienteId],
      profissionalRecursoId: [model.profissionalRecursoId],
      convenioId: [model.convenioId],
      classificacaoId: [model.classificacaoId],
      dataInicial: [this.fromJsonDate(model.dataInicial)],
      diaSemana: [model.diaSemana],
      horario: [model.horario],
      totalItens: [model.totalItens],
      observacoes: [model.observacoes, Validators.maxLength(500)],
      //Auxiliares
      codigoPessoa: [this.paciente.pessoa.codigo],
      nomePessoa: [this.paciente.pessoa.nomeFantasia],
    });

    this.registroAtivo = model.registroAtivo;    
  }

  get modoCrud(): string {
    if (this.newItem) {
      return 'Novo Registro';
    } else if (this.detailMode) {
      return 'Detalhes';
    } else {
      return 'Edição';
    }    
  }

  get diaSemana(): string {
    let diaSemana = this.form.controls['diaSemana'].value;
    return this.typesService.getDayOfWeekType(diaSemana);
  }

  get periodo(): string {
    let periodo = this.form.controls['periodo'].value;
    return this.typesService.recorrenciaPeriodoTypeNome(periodo);
  } 

  async validateToSave(autoSave: boolean = false): Promise<boolean> {
    let result = await super.validateToSave(autoSave);
    if (result) {
      const model: AgendamentoRecorrenteModel = Object.assign({}, this.form.value);

      if (model.totalItens <= 1) {
        this.errors.push('É obrigatório informar o número de itens de agendamento maior que 1!');
        result = false;
      }
    }

    if (!result) {
      let mensagem = this.errors.join('<br>');
      await this.commonService.mensagem('Validação de Erros', mensagem, 'error');
    }

    return result;
  }

  async cancelar() {
    let result = await this.commonService.mensagemConfirmacao('Cancelar Recorrência?', 'Todos agendamentos relacionados em aberto e não confirmados serão excluídos.', "question");
    if (result) {
      const model: AgendamentoRecorrenteModel = Object.assign({}, this.form.value);

      result = await this.service.cancelar(model).toPromise();
      if (result) {
        this.emitirSalvamento = true;
        this.close();        
      }
    }
  }

  afterSave(model: AgendamentoRecorrenteModel): AgendamentoRecorrenteModel {
    this.emitirSalvamento = true;
    return model;
  }

  close() {
    this.modal.hide();
    if (this.emitirSalvamento) {
      this.modalClose.emit(this.form.value.id);
    }    
  }

}
