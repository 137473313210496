import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';
import { AcoesModule } from './acoes/acoes.module';
import { PaginasModule } from './pagina/paginas.module';
import { GrupoUsuariosModule } from './grupo-usuario/grupo-usuarios.module';


@NgModule({
  imports: [
    SharedModule,
    AcoesModule,
    PaginasModule,
    GrupoUsuariosModule,
  ]
})
export class ControleAcessoModule { }
