import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CapturaImagemModel } from '../models';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class CapturaImagemService extends BaseCrudService<CapturaImagemModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'CapturaImagem';
  }

  getByCapturaId(capturaId): Observable<CapturaImagemModel[]> {
    return this.httpClient.get(`${this.getApiUrl()}${this.getController()}/GetByCapturaId/${capturaId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  updateOrdens(imagemIds) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, imagemIds, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
