import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { BlocoPosicaoType, BlocoType, LinkTargetType } from '../domain/enums';
import { BlocoModel, ConfigSiteTenantModel, LinkModel } from '../domain/models';
import { BlocoService } from '../domain/services/bloco.service';
import { ConfigSiteTenantService } from '../domain/services/config-site-tenant.service';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html'
})
export class CabecalhoComponent extends BaseComponent implements OnInit {
  newItem: boolean;
  configSite: ConfigSiteTenantModel;
  
  constructor(
    injector: Injector,    
    private readonly blocoService: BlocoService,
    private readonly configSiteTenantService: ConfigSiteTenantService
  ) {
    super(injector);    
  }

  private saveConfigSite() {
    this.configSite.headerShowContact = this.form.value.headerShowContact;
    this.configSite.headerShowLogotipo = this.form.value.headerShowLogotipo;
    this.configSiteTenantService.editByTipo('Header', this.configSite).subscribe(
      () => {
        this.snackBar.open('Salvo com sucesso!', 'Ok', {
          duration: 3000
        });
      },
      (errors) => this.verifyErrors(errors)
    );
  }

  protected initializeForm(bloco: BlocoModel, configSite: ConfigSiteTenantModel) {
    this.form = this.formBuilder.group({
      id: bloco.id,
      dataInclusao: [bloco.dataInclusao, Validators.required],
      dataAlteracao: bloco.dataAlteracao,      
      tipo: [bloco.tipo, Validators.required],
      posicao: [bloco.posicao],
      titulo: [bloco.titulo, [
        Validators.required,                
        Validators.maxLength(100)]
      ],
      legenda: [bloco.legenda, [        
        Validators.maxLength(200)]
      ],      
      html: [bloco.html],
      headerShowContact: [configSite.headerShowContact],
      headerShowLogotipo: [configSite.headerShowLogotipo]
    });  
  }

  ngOnInit() {
    this.blocoService.get(BlocoType.Html, BlocoPosicaoType.Cabecalho).subscribe(list => {
      let model = list[0];
      if (!model) {
        this.newItem = true;
        model = new BlocoModel();
        model.id = this.commonService.newGuid();
        model.dataInclusao = new Date();
        model.titulo = "Cabeçalho";
        model.tipo = BlocoType.Html;
        model.posicao = BlocoPosicaoType.Cabecalho;
        model.link = new LinkModel();
        model.link.id = this.commonService.newGuid();
        model.link.dataInclusao = new Date();
        model.link.target = LinkTargetType.Self;
        model.linkId = model.link.id;
      } 

      this.configSiteTenantService.get().subscribe(configSite => {
        this.configSite = configSite;
        this.initializeForm(model, configSite);
      });                
    });    
  }  
  
  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: BlocoModel = Object.assign({}, this.form.value);
    if (this.newItem) {
      this.blocoService.create(model).subscribe(
        () => {        
          this.newItem = false;
          this.saveConfigSite();
        },
        (errors) => this.verifyErrors(errors)
      );
    } else {
      this.blocoService.edit(model).subscribe(
        () => {        
          this.saveConfigSite();
        },
        (errors) => this.verifyErrors(errors)
      );
    }    
  }  
}
