import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from '../base-crud.service';
import { SelectDto } from '../../models/select-dto';
import { BaseDocContainerModel } from '../../models/doc-movimento/base-doc-container-model';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseDocContainerService<TDocContainer extends BaseDocContainerModel> extends BaseCrudService<TDocContainer> {

  constructor(injector: Injector) {
    super(injector);
  }

  applyDoc(docId: string): Observable<boolean> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/ApplyDoc/${docId}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  undoAppliedDoc(docId: string): Observable<boolean> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UndoAppliedDoc/${docId}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  cancelDoc(docId: string): Observable<boolean> {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/CancelDoc/${docId}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  activate(docId: string): Observable<TDocContainer> {
    return this
    .httpClient
    .put(`${this.getApiUrl()}${this.getController()}/Activate/${docId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  inactivate(docId: string): Observable<TDocContainer> {
    return this
    .httpClient
    .put(`${this.getApiUrl()}${this.getController()}/Inactivate/${docId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
    
  calcularTotais(docId: string): Observable<any> {    
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/CalcularTotais/${docId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelect(docId: string): Observable<SelectDto> {    
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelect/${docId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}