import { BaseModel } from "../base/base-model";
import { SelectDto } from "../select-dto";
import { PessoaModel } from "./pessoa-model";

export class BaseEntidadePessoaModel extends BaseModel {
    public pessoaId: string;
    public pessoa: PessoaModel;
    public observacoes: string;
}

export class PessoaDto extends SelectDto {
    public codigo: number;
    public tipoEntidade: string;
}
