<div bsModal #scriptModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="scriptModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-xl">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Script</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<div class="row">
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Tipo</mat-label>
								<mat-select formControlName="tipo" (selectionChange)="changeTipo($event.value)" #focus>
									<mat-option *ngFor="let tipo of tipos" [value]="tipo.value">{{tipo.text}}
									</mat-option>
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Posição</mat-label>
								<mat-select formControlName="posicao">
									<mat-option *ngFor="let posicao of posicoes" [value]="posicao.value">
										{{posicao.text}}
									</mat-option>
								</mat-select>
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Título</mat-label>
								<input matInput formControlName="titulo" type="text"
									(blur)="slugify($event.target.value)" />
								<mat-hint align="end">Máx 100 caracteres</mat-hint>
								<mat-error>Informe um Título válido.</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Ordem</mat-label>
								<input matInput formControlName="ordem" type="number" />
								<mat-error>Informe uma Ordem de Apresentação válida.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Registro Ativo</mat-label>
								<mat-select formControlName="registroAtivo">
									<mat-option [value]="true">Sim</mat-option>
									<mat-option [value]="false">Não</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>Script</mat-label>
								<textarea matInput formControlName="html" rows="10"></textarea>																
							</mat-form-field>
						</div>						
					</div>
				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" color="primary"
						class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>