import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';

import { MenuModel } from '../domain/models';
import { MenuService } from '../domain/services';
import { MenuModalComponent } from './menu-modal/menu-modal.component';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent extends BaseCrudComponent<MenuModel> implements OnInit {
  @ViewChild('menuModal') modal: MenuModalComponent;

  menusPai: MenuModel[];  

  constructor(
    injector: Injector,
    readonly menuService: MenuService
  ) {
    super(injector, menuService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dtOptions.serverSide = false;
    this.menuService.getAll().subscribe(menus => {
      this.models = menus;
      this.menusPai = menus.filter(x => !x.menuPaiId);
    });

  }

  drop(event: CdkDragDrop<string[]>) {    
    moveItemInArray(this.menusPai, event.previousIndex, event.currentIndex);    
    const menuIds = this.menusPai.map(x => x.id);
    this.menuService.updateOrdens(menuIds).subscribe();
  }

  create() {
    const menu = new MenuModel();
    menu.ordem = this.menusPai.length;
    this.modal.showCreate(menu);
  }  
}
