import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvenioComponent } from './convenio.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ConvenioModalComponent } from './convenio-modal/convenio-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    ConvenioComponent,
    ConvenioModalComponent
  ]
})
export class ConvenioModule { }
