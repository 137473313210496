<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
<div class="card">
  <div class="header">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>Tabela de Procedimentos / Convênios</h2>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Procedimentos</mat-label>
          <mat-select [(ngModel)]="procedimentoIds" multiple>
            <mat-option *ngFor="let item of procedimentos" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Convênios</mat-label>
          <mat-select [(ngModel)]="convenioIds" multiple>
            <mat-option *ngFor="let item of convenios" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12 text-center">
        <button mat-flat-button color="primary" [disabled]="isBusy  || !isAllowed()" (click)="print()">
          <span>Gerar Relatório</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="text-center font-weight-bold">Tabela de Procedimentos / Convênios</h4>          
          <div class="row mb-2">
            <div class="col">
              <strong>Procedimentos:</strong> {{procedimentoNomes}}
            </div>
            <div class="col">
              <strong>Convênios:</strong> {{convenioNomes}}
            </div>          
          </div>

          <table border="1" cellpadding="5" cellspacing="5" style="width: 100%;" *ngIf="listaImpressao">
            <thead>
              <tr style="background-color: silver;">
                <th class="text-center">Convênio</th>
                <th class="text-center">Tabela</th>
                <th class="text-center">Cod.Tabela</th>
                <th class="text-center">Valor</th>
                <th class="text-center">% Clínica</th>
                <th class="text-center">% Médico</th>
              </tr>
            </thead>
            <tbody>
              <ng-template ngFor let-item [ngForOf]="listaImpressao" let-isLast="last">
                <tr>
                  <td colspan="5">
                    <strong>Procedimento: {{item.nome}}</strong>
                  </td>                  
                </tr>
                <tr *ngFor="let subItem of item.itens">
                  <td>
                    {{subItem.convenioNome}}
                  </td>
                  <td>                    
                    {{subItem.tabela}}
                  </td>
                  <td>                    
                    {{subItem.codigoTabela}}
                  </td>
                  <td class="text-right">
                    {{subItem.valor | currency: 'BRL':''}}
                  </td>
                  <td class="text-right">
                    {{subItem.percentualClinica | currency: 'BRL':''}}
                  </td>
                  <td class="text-right">
                    {{subItem.percentualProfissional | currency: 'BRL':''}}
                  </td>
                </tr>
                <ng-container *ngIf="isLast && !contentPrinted">
                  {{ onContentPrinted() }}
               </ng-container>
              </ng-template>              
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>