import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { LayoutImpressaoComponent } from './layout-impressao.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    LayoutImpressaoComponent    
  ]
})
export class LayoutImpressaoModule { }
