import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from '../base-crud.service';
import { TenantModel } from '../../models/tenant/tenant-model';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends BaseCrudService<TenantModel> {  
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Tenant';
  }  

  getCurrentTenant(): Observable<TenantModel> {
    return this
      .httpClient
      .get<TenantModel>(`${this.getApiUrl()}${this.getController()}/GetCurrentTenant`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  getAll(): Observable<TenantModel[]> {
    return this
      .httpClient
      .get<TenantModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  createStartup(model: TenantModel): Observable<any> {
    return this
    .httpClient
    .post(`${this.getApiUrl()}${this.getController()}/CreateStartup`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
