import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { TipoParceiroService } from '../../domain/services';
import { TipoParceiroModel } from '../../domain/models';

@Component({
  selector: 'app-tipo-parceiro-modal',
  templateUrl: './tipo-parceiro-modal.component.html',
})
export class TipoParceiroModalComponent extends BaseCrudModalComponent<TipoParceiroModel> implements OnInit {
  @ViewChild('tipoParceiroModal') modal: ModalDirective;

  constructor(
    injector: Injector,

    tipoParceiroService: TipoParceiroService,
  ) {
    super(injector, tipoParceiroService);
  }

  protected initializeForm(tipoParceiro: TipoParceiroModel) {
    this.form = this.formBuilder.group({
      id: tipoParceiro.id,
      dataInclusao: [tipoParceiro.dataInclusao, Validators.required],
      dataAlteracao: tipoParceiro.dataAlteracao,
      nome: [tipoParceiro.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
