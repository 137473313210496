import { Component, OnInit, Input, Injector, ViewChild, Output, EventEmitter } from '@angular/core';

import { ProcedimentoConvenioModel } from '../../domain/models';
import { ProcedimentoConvenioModalComponent } from './procedimento-convenio-modal/procedimento-convenio-modal.component';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { ProcedimentoConvenioService } from '../../domain/services/procedimento-convenio.service';

@Component({
  selector: 'app-procedimento-convenio',
  templateUrl: './procedimento-convenio.component.html'
})
export class ProcedimentoConvenioComponent extends BaseCrudComponent<ProcedimentoConvenioModel> implements OnInit {
  @ViewChild('modal') modal: ProcedimentoConvenioModalComponent;
  
  @Input('procedimentoId') procedimentoId: string;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  searchProcedimentoId = new ModelSearch('procedimentoId', OptionSearchType.Equals, SearchType.Guid);  
  
  selectColumns = [ 
    { data: 'convenio.nome'},
    { data: 'procedimentoTabela'},
    { data: 'valor'},
    { data: 'percLaudo'},
    { data: 'percClinica'}
  ];

  constructor(
    injector: Injector,
    readonly service: ProcedimentoConvenioService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();      
  }

  beforeDtRequest(dataTablesParameters: DataTablesRequest) {
    const searches = [];

    this.searchProcedimentoId.searchTerm = this.procedimentoId;
    searches.push(this.searchProcedimentoId);
    
    dataTablesParameters.searches = searches;

    return super.beforeDtRequest(dataTablesParameters);
  }

  create() {
    this.autoSave.emit();
    super.create();
  }
}