import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { GrupoQuestaoOpcaoModel } from '../../domain/models';
import { GrupoQuestaoOpcaoService } from '../../domain/services';

@Component({
  selector: 'app-grupo-questao-opcao-modal',
  templateUrl: './grupo-questao-opcao-modal.component.html'
})

export class GrupoQuestaoOpcaoModalComponent extends BaseCrudModalComponent<GrupoQuestaoOpcaoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  @Input('grupoQuestaoId') grupoQuestaoId: string;
  @Input('usaPontuacao') usaPontuacao: boolean;

  constructor(
    injector: Injector,
    service: GrupoQuestaoOpcaoService,
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: GrupoQuestaoOpcaoModel) {
    this.form = this.formBuilder.group({
      id: [model.id],
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: [model.dataAlteracao],

      grupoQuestaoId: [model.grupoQuestaoId, Validators.required],
      ordem: [model.ordem],
      opcao: [model.opcao, [Validators.required, Validators.maxLength(100)]],  
      opcaoEmoji: [model.opcaoEmoji, [Validators.maxLength(10)]],
      pontuacao: [model.pontuacao]
    });
  };

  protected newModel(): GrupoQuestaoOpcaoModel {
    let model = new GrupoQuestaoOpcaoModel();
    model.grupoQuestaoId = this.grupoQuestaoId;
    model.ordem = 1;
    model.pontuacao = 0;
    return model;
  };

}
