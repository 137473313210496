<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg" *ngIf="profissionalId">
    <div class="card modal-content">
      <div class="header">
        <h2>Textos Padrões do Profissional - {{profissionalNome}}</h2>
      </div>
      <div class="modal-body table-responsive">      
        <button mat-flat-button color="primary" type="button" [disabled]="!isAllowed('Create')"
          (click)="create()">Adicionar</button>
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>              
              <th class="text-center">Texto</th>              
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models">                            
              <td [innerHTML]="item.texto"></td>              
              <td class="text-center">
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(item.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
      </table>
    </div>    
    <div class="footer">
      <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
        <span>Fechar</span>
      </button>
    </div>
  </div>
</div>
<app-profissional-saude-texto-padrao-modal #modal (modalSave)="onResponse($event)"></app-profissional-saude-texto-padrao-modal>
