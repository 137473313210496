import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { DocSusConfigModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DocSusConfigService extends BaseCrudService<DocSusConfigModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'DocSusConfig';
  }
}
