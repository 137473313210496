import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ProfissionalSaudeTextoPadraoComponent } from './profissional-saude-texto-padrao.component';
import { ProfissionalSaudeTextoPadraoModalComponent } from './profissional-saude-texto-padrao-modal/profissional-saude-texto-padrao-modal.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    ProfissionalSaudeTextoPadraoComponent,
    ProfissionalSaudeTextoPadraoModalComponent
  ],
  exports: [
    ProfissionalSaudeTextoPadraoComponent
  ]
})
export class ProfissionalSaudeTextoPadraoModule { }
