<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card" *ngIf="tableItens && chartDatasets">
        <div class="header">
          <h4 class="modal-title">Questionário - Dashboard Geral</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Questionário</mat-label>
                <input matInput [value]="questionarioTitulo" type="text" disabled>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Filtros</mat-label>
                <mat-select [(ngModel)]="filtroId" required #focus>
                  <mat-option *ngFor="let filtro of listaFiltro" [value]="filtro.id">
                    {{filtro.opcao}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Filtro.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Inicial</mat-label>
                <input matInput [(ngModel)]="dataInicial" type="date" max="2999-12-31">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data Final</mat-label>
                <input matInput [(ngModel)]="dataFinal" type="date" max="2999-12-31">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <button mat-flat-button color="primary" (click)="filtrar()">
                <span>Filtrar</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <canvas #canvas height="200" baseChart [data]="chartData" [datasets]="chartDatasets"
                [labels]="chartLabels" [chartType]="chartType" [options]="chartOptions" [plugins]="chartPlugins"
                [legend]="chartLegend">
              </canvas>
            </div>
            <div class="col-md-6">
              <div #tableRespostas>
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead>                    
                    <tr>
                      <th class="text-center">Status</th>
                      <th class="text-center" [width]="100">Total</th>
                      <th class="text-center" [width]="50">Perc.</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="tableItens?.length != 0">
                    <tr *ngFor="let item of tableItens">
                      <td class="text-center">
                        <span [ngClass]="typesService.getPesquisaStatusCorTypeColor(item.statusCor)" class="label">
                          {{item.nomeStatusCor}}
                        </span>
                      </td>
                      <td class="text-right">
                        {{item.total}}
                      </td>
                      <td class="text-right">
                        {{item.percentual | currency:'BRL':''}} %
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan="3" class="text-center">Total Reg.: {{qtdTotal}}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-flat-button color="primary" type="button" [routerLink]="" (click)="print()" class="button-row">
            <span>Imprimir</span>
          </button>
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>