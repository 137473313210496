import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { GrupoProcedimentoService, ProcedimentoService } from '../../domain/services';
import { ProcedimentoConvenioModel, ProcedimentoModel } from '../../domain/models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { TypesService } from 'projects/admin/src/domain/services';
import { ProcedimentoServicoType } from '../../domain/types';
import { ProcedimentoConvenioService } from '../../domain/services/procedimento-convenio.service';

@Component({
  selector: 'app-procedimento-modal',
  templateUrl: './procedimento-modal.component.html'
})
export class ProcedimentoModalComponent extends BaseCrudModalComponent<ProcedimentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  
  grupos: SelectDto[];
  procedimentoServicoType: typeof ProcedimentoServicoType = ProcedimentoServicoType;

  constructor(
    injector: Injector,
    service: ProcedimentoService,
    readonly procConvenioService: ProcedimentoConvenioService,
    readonly grupoProcedimentoService: GrupoProcedimentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.grupoProcedimentoService.getSelectList().subscribe(x => this.grupos = x);
  }

  protected initializeForm(model: ProcedimentoModel) {
    this.form = this.formBuilder.group({
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: this.fromJsonDate(model.dataAlteracao),
      registroAtivo: [model.registroAtivo],
      id: model.id,

      codigo: [model.codigo, [Validators.required]],
      nome: [model.nome, [
        Validators.required, Validators.maxLength(100)
      ]],
      exame: [model.exame],
      exameEntregueAto: [model.exameEntregueAto],
      usoFrequente:  [model.usoFrequente],
      tipoServico:  [model.tipoServico, Validators.required],
      grupoId: [model.grupoId, [Validators.required]],
    });
  }

  newModel() : ProcedimentoModel {
    var model = super.newModel();
    model.codigo = 0;
    model.exame = false;
    model.exameEntregueAto = false;
    model.usoFrequente = false;
    model.tipoServico = ProcedimentoServicoType.Interno;
    return model;
  }

  autoSave() {
    this.save(true);
  }

  afterSave(model: ProcedimentoModel): ProcedimentoModel {
    this.form.patchValue({
      codigo: model.codigo
    })
    return model;
  }

}
