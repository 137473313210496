import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { UnidadeMedidasComponent } from './unidadeMedidas.component';
import { UnidadeMedidaModalComponent } from './unidadeMedida-modal/unidadeMedida-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    UnidadeMedidasComponent,
    UnidadeMedidaModalComponent
  ]
})
export class UnidadeMedidasModule { }
