import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { GrupoQuestaoItemModel } from '../domain/models';
import { GrupoQuestaoItemService } from '../domain/services';
import { GrupoQuestaoItemModalComponent } from './grupo-questao-item-modal/grupo-questao-item-modal.component';

@Component({
  selector: 'app-grupo-questao-item',
  templateUrl: './grupo-questao-item.component.html'
})

export class GrupoQuestaoItemComponent extends BaseCrudComponent<GrupoQuestaoItemModel> implements OnInit {
  @ViewChild('modal') modal: GrupoQuestaoItemModalComponent;

  @Input('grupoQuestaoId') grupoQuestaoId: string;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    injector: Injector,
    readonly service: GrupoQuestaoItemService
  ) {
    super(injector, service);
    this.useDatatables = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.service.getByGrupoQuestaoId(this.grupoQuestaoId).subscribe(x => this.models = x);
  }

  create(model: GrupoQuestaoItemModel = null) {
    super.create(model);
    this.autoSave.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.models, event.previousIndex, event.currentIndex);
    const ids = this.models.map(x => x.id);
    this.service.updateOrdens(ids).subscribe();
  }
}

