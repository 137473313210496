import { Component, Injector, OnInit, ViewChild } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { BlocoPosicaoType, BlocoType } from '../domain/enums';
import { BlocoModel } from '../domain/models';
import { BlocoService } from '../domain/services/bloco.service';
import { ScriptModalComponent } from './script-modal/script-modal.component';

@Component({
  selector: 'app-scripts',
  templateUrl: './scripts.component.html'
})
export class ScriptsComponent extends BaseCrudComponent<BlocoModel> implements OnInit {
  @ViewChild('scriptModal') modal: ScriptModalComponent;

  selectColumns = [
    { data: 'tipo' },
    { data: 'posicao' },
    { data: 'titulo' },
    { data: 'registroAtivo' }
  ];

  constructor(
    injector: Injector,
    readonly blocoService: BlocoService
  ) {
    super(injector, blocoService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    const searchTipo = new ModelSearch('tipo', OptionSearchType.Equals, SearchType.Int, BlocoType.Script);
    searches.push(searchTipo);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getTipo(tipo: BlocoType): string {
    switch (tipo) {
      case BlocoType.Script:
        return 'Script';
    }
  }

  getPosicao(posicao: BlocoPosicaoType): string {
    switch (posicao) {
      case BlocoPosicaoType.TagHead:
        return 'Cabeçado do HTML <head>';
      case BlocoPosicaoType.TagBody:
        return 'Corpo do HTML <body>';
    }
  }
}
