import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtigoTagComponent } from './artigo-tag.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ArtigoTagModalComponent } from './artigo-tag-modal/artigo-tag-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ArtigoTagComponent,
    ArtigoTagModalComponent
  ]
})
export class ArtigoTagModule { }
