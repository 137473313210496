export enum RegistroSistemaType {
  Inativo = 0,
  Ativo = 1
}

export enum PessoaType {
  Juridica = 1,
  Fisica = 2
}

export enum SexoType {
  NaoDefinido,
  Masculino,
  Feminino
}

//EntidadePessoa é uma string definida por constantes nos projetos
//Desta forma são tipos extensíveis para cada projeto
export const EntidadePessoaConsts = {
  Cliente: "Cliente",
  Fornecedor: "Fornecedor",
  Vendedor: "Vendedor",
  Banco: "Banco",
  Transportadora: "Transportadora"
}

export enum OptionSearchType {
  StartWith,
  Contains,
  EndWith,
  Equals,
  GreaterThan,
  GreaterThanOrEqual,
  LessThan,
  LessThanOrEqual,
  NotEquals
}

export enum SearchType {
  Int = 0,
  Double = 1,
  Guid = 2,
  String = 3,
  DateTime = 4,
  Boolean = 5,
}

export enum ProdutoType {
  Produto = 1,
  Servico = 2,
  MateriaPrima = 3,
  Insumo = 4
}

export enum ComponenteSistemaType {
  Descritivo = 0,
  Funcionalidade = 1,
  Modulo = 2
}

export enum DocMovtoEstoqueType {
  SemMovimento = 0,
  Entrada = 1,
  Saida = 2,
  SaidaTransferencia = 3
}

export enum DocStatusType {
  Draft = 0,
  Saved = 1,
  Applied = 2
}

export enum ComissaoVendedorType {
  SemComissao = 0,
  Venda = 1,
  Liquidacao = 2,
  Recorrencia = 3
}

export enum PeriodicidadeCobrancaType {
  Mensal = 1,
  Trimestral = 2,
  Semestral = 3,
  Anual = 4,
  Customizado = 5
}

export enum CategoriaFinanceiraType {
  Receita = 1,
  Despesa = 2
}

export enum GrupoDreType {
  Receita = 1,
  Despesa = 2,
  Ambos = 3,
  Totalizador = 4
}

export enum ContaFinanType {
  ContaCorrente = 1,
  Carteira = 2,
  Poupanca = 3,
  Investimentos = 4,
  Outros = 5
}

export enum ContaItemType{
  Credito = 1,
  Debito = 2
}

export enum PagamentoType {
  Dinheiro = 1,
  Cartao = 2,
  Boleto = 3,
  Cheque = 4
}

export enum DocFinanType {
  Receber = 1,
  Pagar = 2
}

export enum DocFinanStatusType {
  Pendente = 0,
  Pago = 1
}

export enum RecorrenciaType {
  SemRecorrencia = 0,
  Fixa = 1,
  Parcelas = 2
}

export enum PeriodicidadeRecorrenciaType {
  Dias = 1,
  Semanas = 2,
  Meses = 3,
  Anos = 4
}

export enum DocFinanRecOperationType {
  SomenteEste = 1,
  TodosPendentes = 2,
  Todos = 3
}

export enum PushStatusType {
  Pendente = 0,
  Agendado = 1,
  EmAndamento = 2,
  Concluido = 3,
  Cancelado = 4
}

export enum VendedorType {
  Vendedor = 1,
  Representante = 2,
  AgenteComercial = 3,
  Consultor = 4,
  Parceiro = 5
}
