import { Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';

import { environment } from 'environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LocationService } from './location.service';

export abstract class BaseService {

  private localStorage: LocalStorageService;
  private location: LocationService;  
  protected httpClient: HttpClient;

  constructor(
    private injector: Injector,
  ) {
    this.httpClient = this.injector.get<HttpClient>(HttpClient);
    this.localStorage = this.injector.get<LocalStorageService>(LocalStorageService);
    this.location = this.injector.get<LocationService>(LocationService);
  }

  public getTenant(): string {        
    return this.localStorage.getItem('app.tenant') || this.location.hostname;
  }
    
  protected getApiUrl() {
    return `${environment.baseUrl}/api/v1/`;
  }  

  protected getHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Tenant: `${this.getTenant()}`
      })
    };
  }  

  protected getHeader() {
    return {
      headers: new HttpHeaders({
        Tenant: `${this.getTenant()}`        
      })
    };
  }

  protected getAuthHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getUserToken()}`,
        Tenant: `${this.getTenant()}`
      })
    };
  }

  protected getAuthHeader() {
    return {
      headers: new HttpHeaders({        
        Authorization: `Bearer ${this.getUserToken()}`,
        Tenant: `${this.getTenant()}`
      })
    };
  }

  protected getUserToken(): string {    
    return this.localStorage.getItem('app.token');
  }  

  protected extractData(response: any) {
    if(response.data === undefined || response.data === null){
      return {};
    }
    return response.data;
  }

  protected serviceError(response: Response | any) {
    let errors: any[] = [];
    if (response instanceof Response) {
      console.log(response.status);
      errors.push(`${response.status} - ${response.statusText || ''}`);
    } else {      
      if (response.error === null) {
        if (response.status === 401) {
          const error401 = 'Você não tem autorização para acessar este módulo!';
          errors.push(error401);
          Swal.fire(error401, '', 'warning').then(value => {
            if (value) {
              if (localStorage.getItem('app.token') === null || new Date(localStorage.getItem('app.expiresAt')) <= new Date()) {                
                location.href = '/account/login';
              }
            }
          });
        }      
      } else {
        if (response.error.errors instanceof Array) {
          response.error.errors.forEach((error: string) => {
            errors.push(error);
          });
        } else {
          Object.entries(response.error.errors).forEach(([key, value]) => {
            errors.push({key, value});
          });
        }                               
      }
    }
    return throwError(errors);
  }
}
