<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg">
    <div class="card modal-content">
      <div class="header">
        <h2>Modelos de Termos</h2>
      </div>
      <div class="modal-body table-responsive">
        <div class="row">
          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
              class="table table-bordered table-striped table-hover" style="width: 100%;">
              <thead>
                <tr>
                  <th class="text-center">Nome</th>
                  <th class="text-center" width="120"></th>
                </tr>
              </thead>
              <tbody *ngIf="models?.length != 0">
                <tr *ngFor="let item of models">
                  <td>{{item.nome}}</td>
                  <td class="text-center">
                    <button mat-icon-button type="button" (click)="print(item.id)" 
                      matTooltip="Abrir arquivo em nova aba"><mat-icon>print</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div #relatorio class="visible-print">
  <table class="report-container" *ngIf="paciente">
    <thead class="report-header">
      <tr>
        <th>
          <div class="mb-2">
            <app-report-header></app-report-header>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <div class="mt-5" [innerHTML]="sanitizer.bypassSecurityTrustHtml(conteudo)"></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>