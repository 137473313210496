<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="titulo">Titulo</mat-option>
                <mat-option value="descricaoInterna">Descrição Interna</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2 col-sm-5">
            <mat-form-field>
              <mat-label>Período de Inclusão</mat-label>
              <input matInput [(ngModel)]="searchDataInicial.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-2 col-sm-5">
            <mat-form-field>
              <input matInput [(ngModel)]="searchDataFinal.searchTerm" type="date" max="2999-12-31">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um Titulo válido.</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-12">
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Enquetes</h2>
      </div>
      <div class="body table-responsive">
        <!--query end-->
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Status Publicação</th>
              <th class="text-center">Titulo</th>
              <th class="text-center">Data Inicial</th>
              <th class="text-center">Data Final</th>
              <th class="text-center">Descrição Interna</th>
              <th class="text-center" width="220"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let enquete of models">
              <td>
                {{getTipoStatusPublicacao(enquete.statusPublicacao)}}
              </td>
              <td>
                {{enquete.titulo}}
              </td>
              <td>
                {{enquete.dataInicial | date :'dd/MM/yyyy'}}
              </td>
              <td>
                {{enquete.dataFinal | date :'dd/MM/yyyy'}}
              </td>
              <td>
                {{enquete.descricaoInterna}}
              </td>
              <td class="text-center">
                <button *ngIf="enquete.statusPublicacao !== StatusPublicacaoType.Rascunho" mat-icon-button type="button" (click)="showRespotas(enquete)" matTooltip="Ver Respostas">
                  <mat-icon>event_note</mat-icon>
                </button>
                <button
                  *ngIf="dataFinalMenorQueInicial(enquete) === false && enquete.statusPublicacao === StatusPublicacaoType.Rascunho || enquete.statusPublicacao === StatusPublicacaoType.Agendada"
                  mat-icon-button type="button" (click)="publicar(enquete)" matTooltip="Publicar Enquete">
                  <mat-icon>done</mat-icon>
                </button>
                <button *ngIf="enquete.statusPublicacao === StatusPublicacaoType.Rascunho" mat-icon-button type="button"
                  (click)="agendar(enquete)" matTooltip="Agendar Enquete">
                  <mat-icon>calendar_today</mat-icon>
                </button>
                <button
                  *ngIf="enquete.statusPublicacao !== StatusPublicacaoType.Rascunho && enquete.statusPublicacao !== StatusPublicacaoType.Encerrada"
                  mat-icon-button type="button" (click)="encerrar(enquete)" matTooltip="Encerrar Enquete">
                  <mat-icon>highlight_off</mat-icon>
                </button>
                <button mat-icon-button type="button" (click)="duplicar(enquete)" matTooltip="Duplicar Enquete">
                  <mat-icon>content_copy</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(enquete)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(enquete.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fixed-action-btn">
        <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <app-enquete-modal #enqueteModal (modalSave)="onResponse($event)"></app-enquete-modal>
  <app-enquete-respostas-modal #enqueteRespostasModal></app-enquete-respostas-modal>
