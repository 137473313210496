import { NgModule } from '@angular/core';


import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GrupoUsuariosComponent } from './grupo-usuarios.component';
import { GrupoUsuarioModalComponent } from './grupo-usuario-modal/grupo-usuario-modal.component';



@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    GrupoUsuariosComponent,
    GrupoUsuarioModalComponent
  ]
})
export class GrupoUsuariosModule { }
