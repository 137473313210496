import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { SalaAtendimentoModalComponent } from './sala-atendimento-modal/sala-atendimento-modal.component';
import { SalaAtendimentoModel } from '../domain/models';
import { SalaAtendimentoService } from '../domain/services';

@Component({
  selector: 'app-sala-atendimento',
  templateUrl: './sala-atendimento.component.html'
})
export class SalaAtendimentoComponent extends BaseCrudComponent<SalaAtendimentoModel> implements OnInit {
  @ViewChild('modal') modal: SalaAtendimentoModalComponent;

  selectColumns = [ 
    { data: 'nome'},
  ];

  constructor(
    injector: Injector,
    readonly service: SalaAtendimentoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
