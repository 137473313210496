import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequisicaoLgpdComponent } from './requisicao-lgpd.component';
import { RequisicaoLgpdModalComponent } from './requisicao-lgpd-modal/requisicao-lgpd-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    RequisicaoLgpdComponent,
    RequisicaoLgpdModalComponent
  ]
})
export class RequisicaoLgpdModule { }
