<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Armazenamento</h2>
      </div>
      <div class="body">
        <app-status-armazenamento></app-status-armazenamento>
      </div>
    </div>
    <div class="panel-group" role="tablist" aria-multiselectable="true">
      <div class="panel" id="panelFilter">
        <div class="panel-heading" role="tab" id="headingFilter">
          <h4 class="panel-title">
            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionFilter"
              href="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
              <span class="material-icons">filter_alt</span> Filtros
            </a>
          </h4>
        </div>
        <div id="collapseFilter" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFilter"
          aria-expanded="false">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Pesquisar por vínculos</mat-label>
                  <mat-select [(ngModel)]="vinculoPaginaIds" multiple>
                    <mat-option *ngFor="let pagina of paginas" [value]="pagina.id">{{pagina.nome}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="panel-footer" style="text-align: right">
            <mat-slide-toggle style="margin-right: 10px;" color="primary" [(ngModel)]="searchRegistroAtivo.searchTerm">
              Somente Reg.Ativos
            </mat-slide-toggle>
            <button mat-flat-button color="primary" (click)="filter()">
              <span>Filtrar</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card main-content" *ngIf="responsePage">
      <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
      <!-- <div class="card" *ngIf="responsePage"> -->
      <div class="header">
        <h2>Imagens</h2>
      </div>
      <div class="body">
        <div class="text-center">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center p-0 m-0">
              <li class="page-item" [ngClass]="{'disabled': responsePage.page == 1}">
                <a class="page-link" (click)="filter(responsePage.page-1)" tabindex="-1"
                  aria-disabled="true">Anterior</a>
              </li>
              <li class="page-item" [ngClass]="{'active': responsePage.page === i+1}"
                *ngFor="let in of [].constructor(responsePage.totalPages); let i = index">
                <a class="page-link" (click)="filter(i+1)">{{i+1}}</a>
              </li>
              <li class="page-item" [ngClass]="{'disabled': responsePage.page == responsePage.totalPages}">
                <a class="page-link" (click)="filter(responsePage.page+1)">Próxima</a>
              </li>
            </ul>
          </nav>
        </div>
        <div id="aniimated-thumbnials" class="row clearfix">
          <div class="col-md-3 col-sm-4 col-xs-6" *ngFor="let imagem of responsePage.results">
            <div class="card">
              <a class="image-galery" href="{{imagem.url}}">
                <img class="img-responsive bg-grey" src="{{imagem.url}}"
                  style="width: 100%; height: 200px !important; object-fit: contain;" />
                <span class="label fixed-bottom"
                  style="position: absolute; top: 0; left: 0; background-color: rgba(0, 0, 0, 0.30);">{{fromNow(imagem)}}</span>
              </a>
              <div class="footer">
                <div class="align-center">
                  <div *ngIf="imagem.vinculos.length > 0">
                    <div class="align-center" matTooltip="Mostrar Vínculos">
                      <div (click)="showVinculosImagem(imagem)" style="cursor: pointer">
                        <span *ngFor="let vinculo of imagem.vinculos" class="label bg-black"
                          style="margin: 4px;">{{vinculo.paginaNome}}</span>
                      </div>
                    </div>
                  </div>
                  <span class="label bg-grey" *ngIf="imagem.vinculos.length === 0">Sem vínculos</span>
                </div>
                <hr />
                <div class="align-center">
                  <button mat-icon-button type="button" (click)="commonService.copyStringToClipboard(imagem.url)"
                    matTooltip="Copiar URL">
                    <mat-icon>file_copy</mat-icon>
                  </button>
                  <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(imagem)"
                    matTooltip="Editar">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(imagem.id)"
                    matTooltip="Excluir">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="fixed-action-btn">
          <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()"
            matTooltip="Adicionar (F9)" matTooltipPosition="above">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="text-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center p-0 m-0">
            <li class="page-item" [ngClass]="{'disabled': responsePage.page == 1}">
              <a class="page-link" (click)="filter(responsePage.page-1)" tabindex="-1" aria-disabled="true">Anterior</a>
            </li>
            <li class="page-item" [ngClass]="{'active': responsePage.page === i+1}"
              *ngFor="let in of [].constructor(responsePage.totalPages); let i = index">
              <a class="page-link" (click)="filter(i+1)">{{i+1}}</a>
            </li>
            <li class="page-item" [ngClass]="{'disabled': responsePage.page == responsePage.totalPages}">
              <a class="page-link" (click)="filter(responsePage.page+1)">Próxima</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

<app-imagem-modal #imagemModal (modalClose)="filter()"></app-imagem-modal>
<app-imagem-vinculos-modal #imagemVinculosModal></app-imagem-vinculos-modal>
