<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg">
    <div class="card modal-content" *ngIf="filtro">
      <div class="header">
        <h2>Filtro - Chats</h2>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Celular Paciente</mat-label>
              <input matInput [(ngModel)]="celularPaciente" type="text" (blur)="onExitNumero()" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>IdWhatsApp (Fone)</mat-label>
              <input matInput [(ngModel)]="filtro.idWhatsApp" readonly class="readonly-input" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Nome Contato (Whats)</mat-label>
              <input matInput [(ngModel)]="filtro.nomeContato" type="text" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Tipo</mat-label>
              <mat-select [(ngModel)]="filtro.tipoChat">
                <mat-option value=-1>Todos</mat-option>
                <mat-option *ngFor="let item of typesService.whatsChatType" [value]="item.value">
                  {{item.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="filtro.status">
                <mat-option value=-1>Todos</mat-option>
                <mat-option *ngFor="let item of typesService.whatsChatStatusType" [value]="item.value">
                  {{item.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Data Inicial</mat-label>
              <input matInput [(ngModel)]="filtro.dataInicial" type="date" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Data Final</mat-label>
              <input matInput [(ngModel)]="filtro.dataFinal" type="date" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button type="submit" (click)="close(true)" color="primary" class="button-row">
          <span> Filtrar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close(false)" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>