<div #relatorio class="visible-print">
  <table class="report-container" *ngIf="entregaExame">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tfoot class="report-footer">
      <tr>
        <td>
          <footer>
            <app-report-footer></app-report-footer>
          </footer>
        </td>
      </tr>
    </tfoot>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="p-0 m-0 text-center font-weight-bold">Entrega de Exames</h4>
          <p class="p-0 m-0"><strong>Atendimento:</strong> {{atendimento.codigoDocMovto}}</p>
          <p class="p-0 m-0"><strong>Procedimento:</strong> {{procedimento.nome}}</p>
          <p class="p-0 m-0"><strong>Paciente:</strong> {{paciente.pessoa.nomeFantasia}}</p>
          <p class="p-0 m-0"><strong>Data Solicitação:</strong> {{entregaExame.dataInclusao | date: 'dd/MM/yyyy'}}</p>
          <p class="p-0 m-0"><strong>Solicitante:</strong> {{usuarioSolicitante.nome}}</p>
          <p class="p-0 m-0" *ngIf="entregaExame.entregue"><strong>Entregue para:</strong> {{entregaExame.entreguePara}}</p>          
          <p class="p-0 m-0" *ngIf="entregaExame.entregue"><strong>Data Entrega:</strong> {{entregaExame.dataEntrega | date: 'dd/MM/yyyy'}}</p>                    
        </td>
      </tr>
    </tbody>
  </table>
</div>