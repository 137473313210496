import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { CategoriaIconeModel, IconeModel } from '../../domain/models';
import { CategoriaIconeService, IconeService } from '../../domain/services';
import { IconeModalComponent } from '../icone-modal/icone-modal.component';

@Component({
  selector: 'app-icone-select-modal',
  templateUrl: './icone-select-modal.component.html'
})
export class IconeSelectModalComponent extends BaseComponent implements OnInit {
  @ViewChild('iconeSelectModal') modal: ModalDirective;
  @ViewChild('iconeModal') iconeModal: IconeModalComponent;
  @Output() modalClose: EventEmitter<IconeModel> = new EventEmitter<IconeModel>();

  icones: IconeModel[];
  iconesFiltrados: IconeModel[];
  categorias: CategoriaIconeModel[];
  searchTerm: string;

  constructor(
    injector: Injector,
    private readonly iconeService: IconeService,
    private readonly categoriaIconeService: CategoriaIconeService
  ) {
    super(injector);
  }

  private initialize() {
    this.categoriaIconeService.getAll().subscribe(categorias => {
      this.categorias = categorias;
    });
    this.iconeService.getAll().subscribe(icones => {
      this.icones = icones;
      this.iconesFiltrados = icones;
    });

  }

  ngOnInit() {
    super.ngOnInit();
  }

  showSelect() {
    this.initialize();
    this.modal.show();
  }

  select(icone: IconeModel) {
    this.modal.hide();
    this.modalClose.emit(icone);
  }

  close() {
    this.modal.hide();
  }

  create() {
    this.iconeModal.showCreate();
  }

  onResponse() {
    this.initialize();
    console.log('on response');
  }

  filtrar() {
    if (this.searchTerm) {
      this.iconesFiltrados = this.icones.filter(x => x.nome.includes(this.searchTerm.toLowerCase()));
    } else {
      this.iconeService.getAll().subscribe(icones => {
        this.iconesFiltrados = icones;
      });
    }
  }
}

