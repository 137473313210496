<div #relatorio class="visible-print">
  <table class="report-container" *ngIf="protocoloDoc">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tfoot class="report-footer">
      <tr>
        <td>
          <footer>
            <app-report-footer></app-report-footer>
          </footer>
        </td>
      </tr>
    </tfoot>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="p-0 m-0 text-center font-weight-bold">Protocolo de Documento (Requisição Interna)</h4>                    
          <p class="p-0 m-0"><strong>Data Solicitação:</strong> {{protocoloDoc.dataInclusao | date: 'dd/MM/yyyy'}}</p>
          <p class="p-0 m-0"><strong>Solicitante:</strong> {{usuarioSolicitante.nome}}</p>
          <p class="p-0 m-0"><strong>Responsavel:</strong> {{protocoloDoc.responsavel}}</p>
          <p class="p-0 m-0"><strong>Paciente:</strong> {{paciente?.pessoa?.nomeFantasia}}</p>
          <p class="p-0 m-0" *ngIf="protocoloDoc.entregue"><strong>Entregue para:</strong> {{protocoloDoc.entreguePara}}</p>          
          <p class="p-0 m-0" *ngIf="protocoloDoc.entregue"><strong>Data Entrega:</strong> {{protocoloDoc.dataEntrega | date: 'dd/MM/yyyy'}}</p>                    
          <br />
          <p class="p-0 m-0"><strong>Título Requisição:</strong></p>
          <p class="p-0 m-0">{{protocoloDoc.titulo}}</p>
          <br />
          <p class="p-0 m-0"><strong>Requisição:</strong></p>
          <p class="p-0 m-0">{{protocoloDoc.detalhes}}</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>