<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <div class="container">
        <tr>
          <th>
            <app-report-header></app-report-header>
          </th>
        </tr>
      </div>
    </thead>    
    <tbody class="report-content">
      <tr>
        <td>
          <div>
            <br>
            <br>
            <br>
            <h3 class="text-center font-weight-bold mb-5">Solicitação de Exames</h3>
            <div class="row">
              <div class="col-8">
                <h4>
                  <strong>Paciente:</strong> {{paciente?.pessoa.nomeFantasia}}
                </h4>
              </div>
              <div class="col-4">
                <h4>
                  <strong>Data Atend.:</strong> {{dataAtual}}
                </h4>
              </div>              
            </div>
            <hr/>
            <br>
            <span style="white-space: pre-line">
              <h4>
                {{solicExame?.descricao}}
              </h4>
            </span> 
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot class="report-footer">
      <tr>
        <td>
          <div class="container">
            <div class="row mt-5 pt-5">
              <div class="col-12 text-center">
                <span>_________________________________________________________________________</span>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12 text-center">
                <h4>
                  <strong>{{profissionalSaude?.pessoa.nomeFantasia + ' CRM ' + profissionalSaude?.crmMedico}}</strong>
                </h4>
              </div>
            </div>

            <!-- <div class="mt-5 pt-5 mb-5 text-center">
              <p class="p-0 m-0">_______________________________________________________</p>
              <p class="p-0 m-0">{{profissionalSaude?.pessoa.nomeFantasia + ' CRM ' + profissionalSaude?.crmMedico}}</p>
            </div> -->
            <footer>
              <app-report-footer></app-report-footer>
            </footer>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>