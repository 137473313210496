import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { WhatsChatModel, WhatsMsgAtendenteDto } from '../../domain/models';
import { TenantModel, UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { WhatsChatAtendenteService } from '../../domain/services/whatschat-atendente.service';

@Component({
  selector: 'app-whats-file-upload-modal',
  templateUrl: './whats-file-upload-modal.component.html'
})
export class WhatsFileUploadModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();


  selectedChat: WhatsChatModel = null;
  usuarioModel: UsuarioModel;
  tenantModel: TenantModel;
  //selectedDoc: SolicitacaoOnlineModel = null;

  //#region File Upload
  fileUploadDialog: boolean = false
  file: any = {};
  localUrl: any;
  fileType: string = 'Imagem';
  fileName: string;
  fileDescription: string;
  fileBase64: string;
  fileTypes: any[] = [
    { label: 'Imagem', value: 'Imagem' },
    { label: 'PDF', value: 'PDF' }
  ];
  //#endregion

  constructor(
    injector: Injector,
    readonly atendenteService: WhatsChatAtendenteService,
  ) {
    super(injector);
  }

  async show(selectedChat: WhatsChatModel, usuarioModel: UsuarioModel, tenantModel: TenantModel) {
    this.selectedChat = selectedChat;
    this.usuarioModel = usuarioModel;
    this.tenantModel = tenantModel;

    this.modal.show();
  }  


  async anexarItem() {
    document.getElementById("inputUpfile").click();
  }

  async prepareFile(event: any) {
    this.file = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }

    let confirmacao: boolean;
    confirmacao = await this.commonService.mensagemConfirmacao("Upload", "Confirmar upload de arquivo?", "question");

    if (confirmacao) {
      try {
        this.fileBase64 = await this.convertFileToBase64(this.file);
        // Identificar o tipo de arquivo
        //!posso prover tambem um Caption, que é o que vai na descrição do PDf com a mensagme no whatsapp
        const fileType = this.file.type;

        //! FileName, será pre-definido com o mesmo nome de arquivo do Storage        
        this.fileName = this.file.name;

        this.fileDescription = this.file.name;

        if (fileType.startsWith('image/')) {
          this.fileType = 'Imagem';
        } else if (fileType === 'application/pdf') {
          this.fileType = 'PDF';
        } else {
          this.fileType = 'Outro';
        }

        console.log(this.fileName);
        console.log(this.fileDescription);
        console.log(this.fileType);
        console.log(this.fileBase64);

      } catch (error) {
        console.error('Erro ao converter arquivo para Base64, tente um arquivo menor:', error);
      }
    }
  }

  async enviarFile() {
    try {

      if (this.selectedChat.usuarioId !== this.usuarioModel.id) {
        await this.commonService.mensagem("Atenção Chat em atendimento por outro usuário", "Transfira a responsabilidade para o seu usuário para poder enviar mensagens.", "info");
        return;
      }

      this.commonService.spinnerOpen('Enviando arquivo...');
      const dto = new WhatsMsgAtendenteDto();
      dto.idWhatsApp = this.selectedChat.numeroWhatsApp;
      dto.usuarioId = this.usuarioModel.id;
      dto.usuarioNome = this.usuarioModel.nome;

      dto.texto = this.fileBase64;
      dto.base64 = this.fileBase64;
      dto.caption = this.fileDescription;
      dto.fileName = this.fileName;
      //dto.mimeType = this.file.type;
      let result;

      if (this.fileType === 'Imagem') {
        result = await this.atendenteService.enviarImagem(this.selectedChat.id, dto).toPromise();
      } else if (this.fileType === 'PDF') {
        result = await this.atendenteService.enviarDoc(this.selectedChat.id, dto).toPromise();
      } else {
        await this.commonService.mensagem("Tipo de arquivo não permitido", "Apenas Imagens e PDFs são permitidos.", "info");
      }

      this.fileUploadDialog = false;


      if (result) {
        // this.limparChatInput();
        // await this.getMsgChat(this.selectedChat.id);  
        // console.log('Arquivo enviado com sucesso: ', result);

        this.close('enviado');
      }
    } catch (error) {
      console.error('Erro ao converter arquivo para Base64:', error);
      this.file.inProgress = false;
      this.file.completed = false;
      this.close('Erro ao converter arquivo para Base64: ' + error);
    }
    this.commonService.spinnerClose();
    this.close('enviado');
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64String = reader.result as string;
        if (file.type === 'application/pdf') {
          base64String = base64String.replace(/^data:application\/pdf;base64,/, 'data:application/octet-stream;base64,');
        }
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
    });
  }

  close(retorno: string) {
    this.fileName = "";
    this.fileDescription = "";
    this.fileType = "";
    this.fileBase64 = "";
    this.file = null;
    
    this.modalClose.emit(retorno);
    this.modal.hide();
  }

}
