import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseEntidadePessoaModalComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoa-modal.component';
import { EquipeModel, SetorModel } from '../../domain/models';
import { EquipeService, SetorService } from '../../domain/services';
import { PessoaType } from 'projects/ProjetoBaseAngular/domain/types';
import { CargoModel } from '../../../rh/domain/models';
import { CargoService } from '../../../rh/domain/services';

@Component({
  selector: 'app-equipe-modal',
  templateUrl: './equipe-modal.component.html',
})
export class EquipeModalComponent extends BaseEntidadePessoaModalComponent<EquipeModel> implements OnInit {
  @ViewChild('equipeModal') modal: ModalDirective;

  setores: SetorModel[];
  cargos: CargoModel[];

  tipos = [
    { text: 'Física', value: PessoaType.Fisica },
  ];

  constructor(
    injector: Injector,
    equipeService: EquipeService,
    private setorService: SetorService,
    private cargoService: CargoService
  ) {
    super(injector, equipeService);
  }

  protected initializeForm(equipe: EquipeModel) {
    super.initializeForm(equipe);
    this.form.addControl('ordem',
      new FormControl(equipe.ordem)
    );
    this.form.addControl('setorId',
      new FormControl(
        equipe.setorId, [
        Validators.required
      ])
    );
    this.form.addControl('cargoId',
      new FormControl(
        equipe.cargoId
      )
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.setorService.get().subscribe(setores => this.setores = setores);
  }

  changeSetor(setorId: string) {
    this.cargoService.getBySetorId(setorId).subscribe(cargos => this.cargos = cargos);
  }

  beforeShowCreate(model: EquipeModel) {    
    model = super.beforeShowCreate(model);
    model.pessoa.tipoPessoa = PessoaType.Fisica;
    model.ordem = 0;
    return model;
  }

  beforeShowEdit(model: EquipeModel) {
    model.setorId && this.changeSetor(model.setorId);
    model.pessoa.estadoId && this.changeEstado(model.pessoa.estadoId);
    return super.beforeShowEdit(model);
  }
}
