import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { PerfilAnamneseComponent } from './perfil-anamnese.component';
import { PerfilAnamneseModalComponent } from './perfil-anamnese-modal/perfil-anamnese-modal.component';
import { PerfilAnamneseQuestComponent } from './perfil-anamnese-quest/perfil-anamnese-quest.component';
import { PerfilAnamneseQuestModalComponent } from './perfil-anamnese-quest/perfil-anamnese-quest-modal/perfil-anamnese-quest-modal.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    PerfilAnamneseComponent,
    PerfilAnamneseModalComponent,
    
    PerfilAnamneseQuestComponent,
    PerfilAnamneseQuestModalComponent
  ],
  exports: [
    PerfilAnamneseComponent
  ]
})
export class PerfilAnamneseModule { }
