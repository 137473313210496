import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { LayoutMensagemModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LayoutMensagemService extends BaseCrudService<LayoutMensagemModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'LayoutMensagem';
  }

  getSelectList(): Observable<any[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
