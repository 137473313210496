import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';
import { ImagensComponent } from './imagens/imagens.component';
import { ImagemModalComponent } from './imagens/imagem-modal/imagem-modal.component';
import { ImagemSelectModalComponent } from './imagens/imagem-select-modal/imagem-select-modal.component';
import { ImagemVinculosModalComponent } from './imagens/imagem-vinculos-modal/imagem-vinculos-modal.component';
import { ArquivosComponent } from './arquivos/arquivos.component';
import { ArquivoModalComponent } from './arquivos/arquivo-modal/arquivo-modal.component';
import { StatusArmazenamentoComponent } from './status-armazenamento/status-armazenamento.component';
import { ImagemApresentacaoModalComponent } from './imagens/imagem-apresentacao-modal/imagem-apresentacao-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ImagensComponent,
    ImagemModalComponent,
    ImagemSelectModalComponent,
    ImagemVinculosModalComponent,
    ArquivosComponent,
    ArquivoModalComponent,
    StatusArmazenamentoComponent,
    ImagemApresentacaoModalComponent
  ],
  exports: [
    ImagemSelectModalComponent,
    ImagemApresentacaoModalComponent
  ]
})
export class GaleriaModule { }
