

import { Injectable, Injector } from '@angular/core';
import { ProfissionalSaudeModel, ProfissionalSaudeAreaAtuacaoMedModel, ProfissionalSaudeDto } from '../models';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';


@Injectable({
  providedIn: 'root'
})
export class ProfissionalSaudeService extends BaseEntidadePessoaService<ProfissionalSaudeModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ProfissionalSaude';
  }

  create(model: ProfissionalSaudeModel): Observable<ProfissionalSaudeModel> {
    this.popularProfissionalSaudeAreaAtuacaoMed(model);
    return super.create(model);
  }

  edit(model: ProfissionalSaudeModel): Observable<ProfissionalSaudeModel> {
    this.popularProfissionalSaudeAreaAtuacaoMed(model);
    return super.edit(model);
  }

  getByEspecialidadeMedId(especialidadeMedId: string, areaAtuacaoId: string): Observable<ProfissionalSaudeModel[]> {
    return this
      .httpClient
      .get<ProfissionalSaudeModel>(`${this.getApiUrl()}${this.getController()}/GetByEspecialidadeMedId/${especialidadeMedId}/${areaAtuacaoId}`,
       this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAll(): Observable<ProfissionalSaudeModel[]> {
    return this
      .httpClient
      .get<ProfissionalSaudeModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelectList(): Observable<ProfissionalSaudeDto[]> {
    return this
      .httpClient
      .get<ProfissionalSaudeModel>(`${this.getApiUrl()}${this.getController()}/GetSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getById(id: string): Observable<ProfissionalSaudeModel> {
    return super.getById(id)
      .pipe(
        map(this.popularAreaAtuacaoMedIds),
        catchError(this.serviceError)
      );
  }

  getBySlug(profissionalSaudeId: string): Observable<ProfissionalSaudeModel> {
    return this
      .httpClient
      .get<ProfissionalSaudeModel>(`${this.getApiUrl()}${this.getController()}/GetBySlug/${profissionalSaudeId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByProfissionalRecursoId(profissionalRecursoId: string): Observable<ProfissionalSaudeModel> {
    return this
      .httpClient
      .get<ProfissionalSaudeModel>(`${this.getApiUrl()}${this.getController()}/GetByProfissionalRecursoId/${profissionalRecursoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByUsuarioId(id: string): Observable<ProfissionalSaudeModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByUsuarioId/${id}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }


  private popularAreaAtuacaoMedIds(model: ProfissionalSaudeModel) {
    if (model.areasAtuacaoMed) {
      model.areaAtuacaoMedIds = [];
      model.areasAtuacaoMed.forEach(parceiroTipo => {
        model.areaAtuacaoMedIds.push(parceiroTipo.areaAtuacaoMedId);
      });
    }
    return model;
  }

  private popularProfissionalSaudeAreaAtuacaoMed(model: ProfissionalSaudeModel) {
    model.areasAtuacaoMed = [];

    model.areaAtuacaoMedIds?.forEach(tipoId => {
      let corpoClinicoAreaAtuacaoMed = new ProfissionalSaudeAreaAtuacaoMedModel();
      corpoClinicoAreaAtuacaoMed.profissionalSaudeId = model.id;
      corpoClinicoAreaAtuacaoMed.areaAtuacaoMedId = tipoId;
      model.areasAtuacaoMed.push(corpoClinicoAreaAtuacaoMed);
    });
  }

  updateOrdens(ids) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, ids, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
