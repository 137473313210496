<div class="row clearfix">

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="pessoa.nomeFantasia">Nome</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um Nome válido.</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Setor</mat-label>
              <mat-select [(ngModel)]="searchSetor.searchTerm" (selectionChange)="changeSetor($event.value)">
                <mat-option value="">Todos os Setores</mat-option>
                <mat-option *ngFor="let setor of setores" [value]="setor.id">{{setor.nome}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Função</mat-label>
              <mat-select [(ngModel)]="searchCargo.searchTerm">
                <mat-option value="">Todas as Funções</mat-option>
                <mat-option *ngFor="let cargo of cargos" [value]="cargo.id">{{cargo.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="card main-content">
      <div class="header">

        <ul class="nav nav-tabs">
          <li class="active"><a class="nav-link active" id="nav-equipe-tab" data-toggle="tab"
              href="#nav-equipe" role="tab" aria-controls="nav-equipe" aria-selected="true" (click)="filter()">Equipe</a></li>
          <li><a class="nav-link" id="nav-ordenacao-tab" data-toggle="tab" href="#nav-ordenacao" role="tab"
              aria-controls="nav-ordenacao" aria-selected="false" (click)="atualizaOrdem()">Ordenação</a></li>
        </ul>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade in active" id="nav-equipe" role="tabpanel" aria-labelledby="nav-equipe-tab">
          <div class="body table-responsive">
            <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover" style="width: 100%;">
              <thead class="thead-dark">
                <tr>
                  <th class="text-center" width="250">Setor</th>
                  <th class="text-center" width="250">Cargo</th>
                  <th class="text-center">Nome</th>

                  <th class="text-center" width="150"></th>
                </tr>
              </thead>
              <tbody *ngIf="models?.length != 0">
                <tr *ngFor="let equipe of models">
                  <td>
                    {{equipe.setorNome}}
                  </td>
                  <td>
                    {{equipe.cargoNome}}
                  </td>
                  <td>
                    {{equipe.pessoaNomeFantasia}}
                  </td>
                  <td class="text-center">
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(equipe)" matTooltip="Editar">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(equipe.id)" matTooltip="Excluir">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()"
              matTooltip="Adicionar (F9)" matTooltipPosition="above">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <div class="tab-pane fade" id="nav-ordenacao" role="tabpanel" aria-labelledby="nav-ordenacao-tab" >
          <div class="body">
            <div mat-dialog-content>
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let equipe of equipes" cdkDrag>
                  {{equipe.pessoa.nomeFantasia}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-equipe-modal #equipeModal (modalSave)="onResponse($event)"></app-equipe-modal>
