import { NgModule } from '@angular/core';
import { DocumentEditorContainerModule, DocumentEditorModule } from '@syncfusion/ej2-angular-documenteditor';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { LaudoComponent } from './laudo.component';
import { LaudoModalComponent } from './laudo-modal/laudo-modal.component';
import { LaudoListModalComponent } from './laudo-list-modal/laudo-list-modal.component';
import { PacienteModule } from '../../paciente/paciente/paciente.module';
import { ReportModule } from '../../report/report.module';
import { LaudoPrintComponent } from './laudo-print/laudo-print.component';
import { LaudoMontarModalComponent } from './laudo-montar-modal/laudo-montar-modal.component';
import { LaudoMontarAddTextoComponent } from './laudo-montar-modal/laudo-montar-add-texto/laudo-montar-add-texto.component';

@NgModule({
  imports: [    
    NgxMatSelectSearchModule,
    SharedModule,
    DocumentEditorModule,
    DocumentEditorContainerModule,
    PacienteModule,
    ReportModule    
  ],
  declarations: [
    LaudoComponent,
    LaudoModalComponent,    
    LaudoListModalComponent,
    LaudoPrintComponent,
    LaudoMontarModalComponent,
    LaudoMontarAddTextoComponent
  ],
  exports: [
    LaudoListModalComponent
  ]
})
export class LaudoModule { }
