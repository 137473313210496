import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { BlocoPosicaoType, BlocoType, LinkTargetType } from '../domain/enums';
import { BlocoModel, LinkModel } from '../domain/models';
import { BlocoService } from '../domain/services/bloco.service';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html'
})
export class RodapeComponent extends BaseComponent implements OnInit {
  newItem: boolean;
  
  constructor(
    injector: Injector,    
    private readonly blocoService: BlocoService
  ) {
    super(injector);    
  }

  protected initializeForm(bloco: BlocoModel) {
    this.form = this.formBuilder.group({
      id: bloco.id,
      dataInclusao: [bloco.dataInclusao, Validators.required],
      dataAlteracao: bloco.dataAlteracao,      
      tipo: [bloco.tipo, Validators.required],
      posicao: [bloco.posicao],
      titulo: [bloco.titulo, [
        Validators.required,                
        Validators.maxLength(100)]
      ],
      legenda: [bloco.legenda, [        
        Validators.maxLength(200)]
      ],      
      html: [bloco.html]
    });  
  }

  ngOnInit() {
    this.blocoService.get(BlocoType.Html, BlocoPosicaoType.Rodape).subscribe(list => {
      let model = list[0];
      if (!model) {
        this.newItem = true;
        model = new BlocoModel();
        model.id = this.commonService.newGuid();
        model.dataInclusao = new Date();
        model.titulo = "Rodapé";
        model.tipo = BlocoType.Html;
        model.posicao = BlocoPosicaoType.Rodape;
        model.link = new LinkModel();
        model.link.id = this.commonService.newGuid();
        model.link.dataInclusao = new Date();
        model.link.target = LinkTargetType.Self;
        model.linkId = model.link.id;
      }      
      this.initializeForm(model);
    });    
  }  
  
  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: BlocoModel = Object.assign({}, this.form.value);
    if (this.newItem) {
      this.blocoService.create(model).subscribe(
        () => {        
          this.newItem = false;
          this.snackBar.open('Salvo com sucesso!', 'Ok', {
            duration: 3000
          });
        },
        (errors) => this.verifyErrors(errors)
      );
    } else {
      this.blocoService.edit(model).subscribe(
        () => {        
          this.snackBar.open('Salvo com sucesso!', 'Ok', {
            duration: 3000
          });
        },
        (errors) => this.verifyErrors(errors)
      );
    }    
  }
}
