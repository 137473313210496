import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProfissionalSaudeModel } from '../../../../equipe/domain/models';
import { ProfissionalSaudeService } from '../../../../equipe/domain/services';
import { PacienteModel } from '../../../../paciente/domain/models';
import { PacienteService } from '../../../../paciente/domain/services';
import { AtendimentoModel, AtendimentoSolicExameModel } from '../../../domain/models';
import { AtendimentoService } from '../../../domain/services';

@Component({
  selector: 'app-atendimento-solic-exame-print',
  templateUrl: './atendimento-solic-exame-print.component.html'
})
export class AtendimentoSolicExamePrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  tenant: TenantModel;
  solicExame: AtendimentoSolicExameModel;
  paciente: PacienteModel;  
  profissionalSaude: ProfissionalSaudeModel;
  atendimento: AtendimentoModel;

  constructor(
    readonly tenantService: TenantService,
    readonly atendimentoService: AtendimentoService,
    readonly pacienteService: PacienteService,    
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly printService: PrintService
  ) { }

  ngOnInit() {
  }

  async print(solicExame: AtendimentoSolicExameModel) {
    this.tenant = await this.tenantService.getCurrentTenant().toPromise();

    this.solicExame = solicExame;

    this.atendimento = await this.atendimentoService.getById(solicExame.atendimentoId).toPromise();    
    this.profissionalSaude = await this.profissionalSaudeService.getByProfissionalRecursoId(this.atendimento.profissionalRecursoId).toPromise();
    this.paciente = await this.pacienteService.getById(this.atendimento.pacienteId).toPromise();

    setTimeout(() => {      
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Solicitações de Exames', content);    
    }, 500);
  }

  get dataAtual() {
    return moment(this.atendimento?.dataHoraEntrada).format('DD/MM/YYYY');
  }  
}
