import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { GrupoProcedimentoModalComponent } from './grupo-procedimento-modal/grupo-procedimento-modal.component';
import { GrupoProcedimentoModel } from '../domain/models';
import { GrupoProcedimentoService } from '../domain/services';

@Component({
  selector: 'app-grupo-procedimento',
  templateUrl: './grupo-procedimento.component.html'
})
export class GrupoProcedimentoComponent extends BaseCrudComponent<GrupoProcedimentoModel> implements OnInit {
  @ViewChild('modal') modal: GrupoProcedimentoModalComponent;

  selectColumns = [ 
    { data: 'nome'},
  ];

  constructor(
    injector: Injector,
    readonly service: GrupoProcedimentoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
