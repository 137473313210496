import { Injectable, Injector } from '@angular/core';
import { AreaAtuacaoMedModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AreaAtuacaoMedService extends BaseCrudService<AreaAtuacaoMedModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'areaAtuacaoMed';
  }

  getByEspecialidadeMedId(especialidadeMedId: string): Observable<AreaAtuacaoMedModel[]> {
    return this
      .httpClient
      .get<AreaAtuacaoMedModel>(`${this.getApiUrl()}${this.getController()}/GetByEspecialidadeMedId/${especialidadeMedId}`,
        this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  getAll(): Observable<AreaAtuacaoMedModel[]> {
    return this
      .httpClient
      .get<AreaAtuacaoMedModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  updateOrdens(areaIds) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, areaIds, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}
