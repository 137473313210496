import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ProtocoloDocComponent } from './protocolo-doc.component';
import { ProtocoloDocModalComponent } from './protocolo-doc-modal/protocolo-doc-modal.component';
import { ProtocoloDocPrintComponent } from './protocolo-doc-print/protocolo-doc-print.component';
import { ReportModule } from '../../report/report.module';
import { PacienteModule } from '../../paciente/paciente/paciente.module';

@NgModule({
  imports: [    
    SharedModule,
    ReportModule,
    PacienteModule
  ],
  declarations: [
    ProtocoloDocComponent,
    ProtocoloDocModalComponent,
    ProtocoloDocPrintComponent
  ]
})
export class ProtocoloDocModule { }
