import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { IconeModalComponent } from './icone-modal/icone-modal.component';
import { IconeComponent } from './icone.component';
import { IconeSelectModalComponent } from './icone-select-modal/icone-select-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    IconeComponent,
    IconeModalComponent,
    IconeSelectModalComponent
  ],
  exports: [
    IconeSelectModalComponent
  ]
})
export class IconeModule { }
