import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { EnqueteOpcaoModel } from '../domain/models';
import { EnqueteOpcaoModalComponent } from './enquete-opcao-modal/enquete-opcao-modal.component';

@Component({
  selector: 'app-enquete-opcao',
  templateUrl: './enquete-opcao.component.html'
})
export class EnqueteOpcaoComponent extends BaseComponent implements OnInit {
  @ViewChild('enqueteOpcaoModal') modal: EnqueteOpcaoModalComponent;
  @Input() opcoes: EnqueteOpcaoModel[];
  @Output() opcaoUpdate: EventEmitter<any> = new EventEmitter<any>();

  dtOptions: DataTables.Settings = {
    language: {
      url: '/assets-base/json/translation/datatable/pt-br.json'
    },
    lengthChange: false,
    paging: false,
    searching: false
  };
  dtTrigger: Subject<EnqueteOpcaoModel[]> = new Subject();

  constructor(
    injector: Injector    
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  create() {
    this.modal.showCreate();
  }

  edit(model: EnqueteOpcaoModel) {
    this.modal.showEdit(model);
  }

  delete(id: string) {
    Swal.fire({
      title: 'Tem certeza de que deseja excluir esta opção da enquete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const index = this.opcoes.findIndex(x => x.id === id);
        this.opcoes.splice(index, 1);
      }
    });
  }

  onResponse(model: EnqueteOpcaoModel) {
    if (!model) {
      return;
    }

    this.snackBar.open('Salvo com sucesso!', 'Ok', {
      duration: 3000
    });

    const index = this.opcoes.findIndex(x => x.id === model.id);
    if (index === -1) {
      this.opcoes.push(model);
    } else {
      this.opcoes[index] = model;
    }
    this.opcaoUpdate.emit(this.opcoes);
  }
}
