import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';
import { PacienteModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PacienteService extends BaseEntidadePessoaService<PacienteModel> {  
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Paciente';
  }  

  public bloquearAgendamento(pacienteId: string, motivo: string, dias: number): Observable<any[]> {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/BloquearAgendamento/${pacienteId}/${dias}`, JSON.stringify(motivo), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}
