import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { LinkTargetType, MenuType, BlocoType, BlocoPosicaoType } from './enums';

export class PaginaWebModel extends BaseModel {
  public slug: string;
  public titulo: string;
  public resumo: string;
  public palavrasChave: string;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;
  public html: string;
  public scriptHead: string;
  public scriptBody: string;
}

export class MenuModel extends BaseModel {
  public tipo: MenuType;
  public titulo: string;
  public iconeHtml: string;
  public menuPaiId: string;
  public menuPai: MenuModel;
  public ordem: number;
  public linkId: string;
  public link: LinkModel;
  public filhos: MenuModel[];
}

export class LinkModel extends BaseModel {
  public url: string;
  public target: LinkTargetType;
}

export class BlocoModel extends BaseModel {
  public tipo: BlocoType;
  public posicao: BlocoPosicaoType;
  public imagemUrl: string;
  public imagemUrlBase: string;
  public imagemPath: string;
  public titulo: string;
  public legenda: string;
  public ordem: number;
  public html: string;
  public linkId: string;
  public link: LinkModel;
}

export class TemaModel extends BaseModel {
  public codigo: number;
  public descricao: string;
  public paginas: TemaPaginaModel[];
}

export class TemaPaginaBlocoModel extends BaseModel {
  public temaPaginaId: string;
  public ordem: number;
  public nomeArquivo: string;
  public codigoLayout: number;
  public conteudoFull: boolean;
}

export class TemaPaginaModel extends BaseModel {
  public temaId: string;
  public nomeArquivo: string;
  public codigoLayout: number;
  public conteudoFull: boolean;
  public blocos: TemaPaginaBlocoModel[];
}

export class ConfigSiteTenantModel extends BaseModel
{
    public tenantId: string;

    public imgLogotipoUrl: string;
    public imgLogotipoUrlBase: string;
    public imgLogotipoPath: string;

    public imgFavIconUrl: string;    
    public imgFavIconUrlBase: string;
    public imgFavIconPath: string;

    public imgSharingUrl: string;
    public imgSharingUrlBase: string;
    public imgSharingPath: string;

    public themeFontFamily: string;    
    public themeColorPrimary: string;    
    public themeColorSecondary: string;
    public themeTextColorPrimary: string;    
    public themeTextColorSecondary: string;    

    public headerBackgroundColor: string;
    public headerTextColor: string;
    
    public contactWhatsApp: string;    
    public contactFacebook: string;    
    public contactInstagram: string;
    public contactTwitter: string;
    public contactYouTube: string;
    public contactEmail: string;    
    public contactTelefone: string;    
    public imgWhatsAppUrl: string;
    public imgWhatsAppUrlBase: string;
    public imgWhatsAppPath: string;

    public headerShowContact: boolean;
    public headerShowLogotipo: boolean;
    
    public seoTitle: string;
    public seoShortTitle: string;
    public seoKeywords: string;            
    public seoDescription: string;  
    
    public pwaName: string;
    public pwaShortName: string;
    public pwaDescription: string;
    public pwaIconUrl: string;
    public pwaIconUrlBase: string;
    public pwaIconPath: string;
}

export class WebFonts {
  public kind: string;
  public items: WebFontsItem[];
}

export class WebFontsItem {
  public family: string;
  public bariants: string[];
  public subsets: string[];
  public version: string;
  public lastModified: string;
  public category: string;
  public kind: string;
}
