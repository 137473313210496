<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="solicitacaoOnlineModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>ChatBot Etapa</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Tipo Fluxo</mat-label>
                <mat-select formControlName="tipoFluxo">
                  <mat-option *ngFor="let item of typesService.botFluxoTypes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Máscara / Código</mat-label>
                <input matInput formControlName="mascara" type="text" maxlength="8" />
                <mat-hint align="end">Máx 8 caracteres</mat-hint>
                <mat-error>Informe um Código válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" maxlength="100" />
                <mat-hint align="end">Máx 100 caracteres</mat-hint>
                <mat-error>Informe um Código válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Tipo Atendimento</mat-label>
                <mat-select formControlName="tipoAtendimento">
                  <mat-option *ngFor="let item of typesService.botAtendimentoTypes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Tipo Etapa</mat-label>
                <mat-select formControlName="tipo">
                  <mat-option *ngFor="let item of typesService.botNodoTypes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3" 
              *ngIf="form.value.tipo === botNodoType.InicioFluxo 
              && form.value.tipoFluxo !== botFluxoType.ConfirmacaoPresenca">
              <mat-form-field>
                <mat-label>Tempo Limite (Resposta)</mat-label>
                <input matInput formControlName="tempoLimite" type="text" maxlength="10" 
                  placeholder="dd.hh:mm"/>
                <mat-hint align="end">Máx 10 caracteres</mat-hint>
                <mat-error>Informe um tempo válido.</mat-error>
              </mat-form-field>              
            </div>
            <div *ngIf="form.value.tipo === botNodoType.InicioFluxo 
            && form.value.tipoFluxo === botFluxoType.ConfirmacaoPresenca">
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Tempo Limite (1° Resposta)</mat-label>
                  <input matInput formControlName="tempoLimite" type="text" maxlength="10" 
                    placeholder="dd.hh:mm"/>
                  <mat-hint align="end">Máx 10 caracteres</mat-hint>
                  <mat-error>Informe um tempo válido.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Tempo Limite (2° em diante)</mat-label>
                  <input matInput value="0.00:05" type="text" readonly/>                  
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Explicação</mat-label>
                <textarea matInput [value]="typesService.getBotNodoTypeHelp(form.value.tipo)" rows="3" disabled></textarea>
              </mat-form-field>
            </div>
          </div>
          <br>
          <mat-accordion multi>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Mensagem
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field>
                <mat-label>Mensagem</mat-label>
                <textarea matInput #mensagem formControlName="mensagem" rows="10" maxlength="500"></textarea>
                <mat-hint align="end">{{mensagem.value.length}} / 500</mat-hint>
              </mat-form-field>
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="form.value.tipoFluxo === botFluxoType.ConfirmacaoPresenca 
              && form.value.tipo === botNodoType.AtendimentoInit">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Comandos especiais
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p><strong>Palavras Chaves</strong></p>
              <p>
                <strong>#PacienteNome</strong> - Nome completo do paciente <br>
                <strong>#ProcedimentoNome</strong> - Nome do procedimento <br>
                <strong>#Data</strong> - Data do agendamento <br>
                <strong>#Horario</strong> - Horário do agendamento <br>
                <strong>#ProfissionalNome</strong> - Nome de apresentação do profissional da saúde<br>
                <strong>#EquipamentoNome</strong> - Nome de apresentação do equipamento
              </p>
              <hr>
              <p><strong>Estrutura condicional</strong></p>
              <p>
                <strong>@If</strong> &nbsp;<i>"#PalavraChave"</i> &nbsp; <strong>In (</strong>Valor1, Valor2, ..., N<strong>)</strong> <br>
                <strong>Begin</strong><br>
                Texto abcd <i>"#PalavraChave"</i> &nbsp; Texto efgh<br>
                <strong>End;</strong><br>
                <strong>@Else</strong><br>
                <strong>Begin</strong><br> 
                Texto xxxxx <i>"#PalavraChave"</i> &nbsp; Texto zzzzz<br> 
                <strong>End;</strong>
              </p>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="row">
            <div class="col-md-10">
              <mat-form-field>
                <mat-label>Tipo Campo</mat-label>
                <mat-select formControlName="tipoCampo">
                  <mat-option *ngFor="let item of typesService.whatsChatMsgCampoType" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Validar Resposta?</mat-label>
                <mat-select formControlName="validarResposta" (selectionChange)="onChangeValidar()">
                  <mat-option *ngFor="let item of typesService.boolTypes" [value]="item.value">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Opções de Resposta (Separar por ",")</mat-label>
                <input matInput formControlName="respostaOpcoes" type="text" maxlength="200" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe um texto válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Valores de Resposta (Correnpondete à Opção)</mat-label>
                <input matInput formControlName="respostaValores" type="text" maxlength="200" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe um texto válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <h4 class="card-inside-title">Opções Alternativas</h4>
            <app-chatbot-resp-alternativa (autoSave)="autoSave()" [chatBotNodoId]="form.value.id">
            </app-chatbot-resp-alternativa>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Próxima Etapa (Nodo)</mat-label>
                <input matInput formControlName="proxNodo" type="text" maxlength="200" />
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe um texto válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>