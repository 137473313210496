<div bsModal #configModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="configModal"
	aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
	<div class="modal-dialog modal-lg">
		<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
		<div class="card modal-content" *ngIf="form">
			<form (ngSubmit)="save()" [formGroup]="form">
				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Configuração</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>
					<div class="row">

						<div class="col-md-2">
							<mat-form-field>
								<mat-label>Grupo</mat-label>
								<input disabled matInput [value]="form.value.grupo" type="text" />
								<mat-error>Informe uma Grupo válido.</mat-error>
							</mat-form-field>
						</div>

						<div class="col-md-7">
							<mat-form-field>
								<mat-label>Descrição</mat-label>
								<input disabled matInput #descricao [value]="form.value.descricao" />
							</mat-form-field>
						</div>

						<div *ngIf="!form.value.opcoes" class="col-md-3">
							<mat-form-field>
								<mat-label>Valor</mat-label>
								<input matInput formControlName="valor" type="text" #focus />
								<mat-error>Informe um Valor válido.</mat-error>
							</mat-form-field>
						</div>

						<div *ngIf="form.value.opcoes" class="col-md-3">
							<mat-form-field>
								<mat-label>Valor</mat-label>
								<mat-select formControlName="valor">
									<mat-option *ngFor="let opcao of opcoes" [value]="opcao.value">{{opcao.text}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

					</div>
				</div>
				<div class="footer">
					<button mat-flat-button  [disabled]="!form.valid || isBusy" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>