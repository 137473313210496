import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { CategoriaIconeModel } from '../domain/models';
import { CategoriaIconeService } from '../domain/services';
import { CategoriaIconeModalComponent } from './categoria-icone-modal/categoria-icone-modal.component';

@Component({
  selector: 'app-categoira-icone',
  templateUrl: './categoria-icone.component.html',
})
export class CategoriaIconeComponent extends BaseCrudComponent<CategoriaIconeModel> implements OnInit {
  @ViewChild('categoriaIconeModal') modal: CategoriaIconeModalComponent;

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  selectColumns = [{ data: 'nome'}];

  constructor(
    injector: Injector,
    readonly categoriaIconeService: CategoriaIconeService
  ) {
    super(injector, categoriaIconeService);
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);
    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();    
    this.categoriaIconeService.getAll().subscribe(categoriasIcone => this.models = categoriasIcone);
  }
}
