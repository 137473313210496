import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { VagaComponent } from './vaga.component';
import { VagaModalComponent } from './vaga-modal/vaga-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    VagaComponent,
    VagaModalComponent
  ]
})
export class VagaModule { }
