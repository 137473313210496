import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { PesquisaQuestaoType } from 'projects/admin/src/domain/types';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { Subject } from 'rxjs';
import { QuestionarioFiltroModel, QuestionarioModel, QuestionarioRespModel, RespItemApresentacao } from '../../../domain/models';
import { QuestionarioFiltroService, QuestionarioRespItemService, QuestionarioRespService, QuestionarioService } from '../../../domain/services';

@Component({
  selector: 'app-questionario-resp-detalhe',
  templateUrl: './questionario-resp-detalhe.component.html'
})

export class QuestionarioRespDetalheComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild(DataTableDirective) datatable: DataTableDirective;
  @ViewChild('tableRespostas') tableRespostas: ElementRef;

  dtOptions: DataTables.Settings = {
    language: {
      url: '/assets-base/json/translation/datatable/pt-br.json'
    },
    order: [[0, "asc"],[1,"asc"]],
    //ordering: false,
    lengthChange: false,
    paging: false,
    searching: false,
    dom: ''
  };
  dtTrigger: Subject<any[]> = new Subject();

  questionario: QuestionarioModel;
  filtro: QuestionarioFiltroModel;
  resposta: QuestionarioRespModel;
  listaApres: RespItemApresentacao[] = [];

  constructor(
    injector: Injector,
    readonly respService: QuestionarioRespService,
    readonly respItemService: QuestionarioRespItemService,
    readonly questionarioService: QuestionarioService,
    readonly filtroService: QuestionarioFiltroService,
    readonly printService: PrintService,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  private updateDtTrigger() {
    if (this.datatable.dtInstance) {
      this.datatable.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  async show(repsId: string) {

    this.resposta = await this.respService.getById(repsId).toPromise();
    this.questionario = await this.questionarioService.getById(this.resposta.questionarioId).toPromise();
    this.filtro = await this.filtroService.getById(this.resposta.filtroId).toPromise();

    debugger;
    let listaResp = await this.respItemService.getListabyRespId(this.resposta.id).toPromise();
    //Retorno de getListabyRespId any[]
    // grupoId
    // grupoTitulo
    // grupoItemId
    // grupoItemQuestao
    // grupoOpcaoId
    // grupoOpcaoQuestao
    // tipo
    // descricao
    // usaPontuacao
    // pontuacao

    this.prepararApresentacao(listaResp);

    this.updateDtTrigger();

    this.modal.show();
  }

  prepararApresentacao(listaResp: any[]) {
    this.listaApres = [];
    let itemIdAux = "";
    let listaOpcao: any[];
    
    listaResp.forEach(item => {
      if (item.grupoItemId !== itemIdAux) {
        itemIdAux = item.grupoItemId;
        listaOpcao = [];

        if (item.tipo === PesquisaQuestaoType.MultiSelecao) {
          listaOpcao = listaResp
            .filter(x => x.grupoItemId === itemIdAux)
            .reduce((prev, atual) => prev.concat(atual.grupoOpcaoQuestao), []);
        }

        this.addApresentacao(item, listaOpcao);
      }
    });
  }

  addApresentacao(item: any, listaOpcao: any[]) {
    let modelApres = new RespItemApresentacao();
    modelApres.grupoTitulo = item.grupoTitulo;
    modelApres.grupoItemQuestao = item.grupoItemQuestao;
    modelApres.usaPontuacao = item.usaPontuacao;
    modelApres.pontuacao = item.pontuacao;
    switch (item.tipo) {
      case PesquisaQuestaoType.Selecao:
        modelApres.resposta = item.grupoOpcaoQuestao;
        break;
      case PesquisaQuestaoType.MultiSelecao:
        let inicio = true;
        listaOpcao.forEach(opcao => {
          if (opcao !== null && opcao !== "") {
            if (inicio) {
              modelApres.resposta = opcao;
              inicio = false;
            } else {
              modelApres.resposta += ", " + opcao;
            }
          }
        });
        break;
      case PesquisaQuestaoType.Descritivo:
        modelApres.resposta = item.descricao;
        break;
      default:
        break;
    }
    this.listaApres.push(modelApres);
  }

  close() {
    this.modal.hide();
  }

  toDateTime(date: Date): string {
    if (!date) {
      return null;
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  print() {
    const content =
      `
          <h4 class="text-center font-weight-bold">Questionário - Detalhamento de Respostas</h4>
          </br>
          </br>
          ${this.tableRespostas?.nativeElement.innerHTML || ''}
        `;
    this.printService.print('Questionário - Detalhamento de Respostas', content);
  }

}
