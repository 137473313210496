<div class="row clearfix">

  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="titulo">Titulo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-8">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um Nome válido.</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-12">
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Revistas</h2>
      </div>
      <div class="body table-responsive">
        <!--query end-->
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center" width="100">Data</th>
              <th class="text-center" width="50">Publicada</th>
              <th class="text-center">Titulo</th>
              <th class="text-center">Edição</th>
              <th class="text-center" width="200"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let revista of models">
              <td>
                {{revista.data | date :'dd/MM/yyyy'}}
              </td>
              <td *ngIf="revista.publicado != true">
                <span [ngClass]="'bg-grey'" class="label">
                  Não
                </span>
              </td>
              <td *ngIf="revista.publicado == true">
                <span [ngClass]="'bg-green'" class="label">
                  Sim
                </span>
              </td>
              <td>
                {{revista.titulo}}
              </td>
              <td>
                {{revista.edicao}}
              </td>

              <td class="text-center">
                <a *ngIf="revista.arquivoUrl || revista.linkExterno"
                  href="{{revista.arquivoUrl || revista.linkExterno}}" target="_blank" rel="noopener" mat-icon-button matTooltip="Ler Revista">
                  <mat-icon>article</mat-icon>
                </a>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(revista)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(revista.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fixed-action-btn">
        <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <app-revista-modal #revistaModal (modalSave)="onResponse($event)"></app-revista-modal>
