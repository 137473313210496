<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">

				<div class="header">
					<button type="button" class="close" (click)="close()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h2>Tabela - Convênios (Tabela Padrão)</h2>
				</div>
				<div class="modal-body">
					<div class="alert alert-danger" *ngIf="errors.length > 0">
						<h3>Oops! Algo deu errado:</h3>
						<ul>
							<li *ngFor="let error of errors">{{ error }}</li>
						</ul>
					</div>					
					<div class="row">
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Procedimento - Convênio</mat-label>
								<mat-select formControlName="convenioId" #focus required>
									<mat-option *ngFor="let convenio of listaConvenio" [value]="convenio.value">
										{{convenio.text}}
									</mat-option>
								</mat-select>
								<mat-error>Selecione uma Convênio.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Código Tabela</mat-label>
								<input matInput formControlName="codigoTabela" type="text" maxlength="20" required />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Tabela</mat-label>
								<mat-select formControlName="procedimentoTabela" required>
									<mat-option *ngFor="let procedimentoTabela of typesService.procedimentoTabelaType"
										[value]="procedimentoTabela.value">
										{{procedimentoTabela.text}}
									</mat-option>
								</mat-select>
								<mat-error>Selecione uma Tabela.</mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Valor</mat-label>
								<input matInput formControlName="valor" type="text" currencyMask required />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Pagto Laudo(%)</mat-label>
								<input matInput formControlName="percLaudo" type="text" currencyMask required />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field>
								<mat-label>Clinica(%)</mat-label>
								<input matInput formControlName="percClinica" type="text" currencyMask required />
								<mat-error></mat-error>
							</mat-form-field>
						</div>
					</div>
					
					<h4 class="card-inside-title">Tabela Especial - Por Profissional</h4>
            <app-procedimento-convenio-profissional (autoSave)="autoSave()"
							[procedimentoConvenioId]="form.value.id"></app-procedimento-convenio-profissional>
				</div>
				<div class="footer">
					<button mat-flat-button [disabled]="!form.valid" type="submit" color="primary" class="button-row">
						<span> Salvar</span>
					</button>
					<button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
						<span> Cancelar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>