import { Component, OnInit, ViewChild, Output, EventEmitter, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { AcaoService } from 'projects/ProjetoBaseAngular/domain/services';
import { AcaoModel } from 'projects/ProjetoBaseAngular/domain/models';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';

@Component({
  selector: 'app-categoria-produto-modal',
  templateUrl: './acao-modal.component.html'
})
export class AcaoModalComponent extends BaseCrudModalComponent<AcaoModel> implements OnInit {
  @ViewChild('acaoModal') modal: ModalDirective;

  @Output() modalSave: EventEmitter<AcaoModel> = new EventEmitter<AcaoModel>();

  constructor(
    injector: Injector,
    acaoService: AcaoService,
  ) {
    super(injector, acaoService);
  }

  protected initializeForm(acao: AcaoModel) {
    this.form = this.formBuilder.group({
      id: acao.id,
      dataInclusao: [acao.dataInclusao, Validators.required],
      dataAlteracao: acao.dataAlteracao,
      codigo: [acao.codigo, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)]
      ],
      nome: [acao.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
      descricao: [acao.descricao, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(500)]
      ],
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
