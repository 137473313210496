<div class="card" *ngIf="form">
    <form method="post" (ngSubmit)="save()" [formGroup]="form">
        <div class="body">
            <div class="row" style="display: flex; flex-wrap: wrap;">
                <div class="col-md-3">
                    <h3>Chat WhatsApp</h3>
                    <div class="body">                        
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div class="form-group align-center">
                                    <div style="height: 50px; width: 100%; object-fit: contain;">
                                        <img src="{{form.value.imgWhatsAppUrl || 'assets-base/images/whatsapp.png'}}"
                                            style="height: 50px; width: 100%; object-fit: contain;">
                                    </div>
                                    <div class="align-center">
                                        <button mat-icon-button type="button" (click)="showSelectImage()"
                                            matTooltip="Selecionar Imagem">
                                            <mat-icon>add_photo_alternate</mat-icon>
                                        </button>
                                        <button mat-icon-button type="button" [disabled]="!form.value.imgWhatsAppUrl"
                                            (click)="deselectImage()" matTooltip="Desvincular Imagem">
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div>Ao informar o número do WhatsApp, será mostrado o ícone acima na parte inferior
                                    direita do site.</div>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field>
                                    <mat-label>Número para contato via WhatsApp</mat-label>
                                    <i matSuffix class="fab fa-whatsapp"></i>
                                    <input matInput formControlName="contactWhatsApp" type="tel"
                                        [dropSpecialCharacters]="false" mask="(00) 00000-0000"
                                        placeholder="Ex: (48) 98888-8888" />
                                    <mat-error>Informe um Número válido.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="col-md-9">
                    <h3>Links para Redes Sociais</h3>
                    <div class="body">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Facebook</mat-label>
                                    <i matSuffix class="fab fa-facebook"></i>
                                    <input matInput formControlName="contactFacebook" type="url">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Instagram</mat-label>
                                    <i matSuffix class="fab fa-instagram"></i>
                                    <input matInput formControlName="contactInstagram" type="url">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Twitter</mat-label>
                                    <i matSuffix class="fab fa-twitter"></i>
                                    <input matInput formControlName="contactTwitter" type="url">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>YouTube</mat-label>
                                    <i matSuffix class="fab fa-youtube"></i>
                                    <input matInput formControlName="contactYouTube" type="url">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>E-mail</mat-label>
                                    <i matSuffix class="fas fa-envelope"></i>
                                    <input matInput formControlName="contactEmail" type="email">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Telefone</mat-label>
                                    <i matSuffix class="fas fa-phone"></i>
                                    <input matInput formControlName="contactTelefone" type="tel"
                                        placeholder="Ex: (48) 8888-8888">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <button mat-flat-button type="submit" color="primary" class="button-row">
                <span>Salvar Alterações</span>
            </button>
        </div>
    </form>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
