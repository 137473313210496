import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-imagem-vinculos-modal',
  templateUrl: './imagem-vinculos-modal.component.html'
})
export class ImagemVinculosModalComponent implements OnInit {
  @ViewChild('imagemVinculosModal') modal: ModalDirective;
  imagem: ImagemModel;

  constructor() { }

  ngOnInit() {
  }

  show(imagem: ImagemModel) {
    this.imagem = imagem;
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }
}
