<div bsModal #imagemVinculosModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="imagemVinculosModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card">
        <div class="header">
          <h4 class="modal-title">Vínculos</h4>
        </div>
        <div class="body">
          <table class="table table-bordered table-striped table-hover js-dataTable dataTable-slim" style="width: 100%">
            <thead>
              <tr>
                <th>
                  Inclusão
                </th>
                <th>
                  Página
                </th>
              </tr>
            </thead>
            <tbody *ngIf="imagem?.vinculos">              
              <tr *ngFor="let vinculo of imagem.vinculos">
                <td>
                  {{vinculo.dataInclusao | date :'dd/MM/yyyy HH:mm'}}
                </td>
                <td>
                  {{vinculo.paginaNome}}                  
                </td>
              </tr>              
            </tbody>
          </table>
        </div>
        <div class="footer">
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
