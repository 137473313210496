import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ConfigsComponent } from './configs.component';
import { ConfigModalComponent } from './config-modal/config-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ConfigsComponent,
    ConfigModalComponent
  ]
})
export class ConfigsModule { }
