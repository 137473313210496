import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { MedicamentoService } from '../../domain/services';
import { MedicamentoModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-medicamento-modal',
  templateUrl: './medicamento-modal.component.html'
})
export class MedicamentoModalComponent extends BaseCrudModalComponent<MedicamentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: MedicamentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: MedicamentoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      
      descricao: [model.descricao, [        
        Validators.maxLength(30),
        Validators.required
      ]],
      concentracao: [model.concentracao, [        
        Validators.maxLength(20),
        Validators.required
      ]],
      qtdEmbalagem: [model.qtdEmbalagem, [        
        Validators.maxLength(20),
        Validators.required
      ]],
      via: [model.via, [        
        Validators.required
      ]],
      vlrUnitario: [model.vlrUnitario, [        
        Validators.required
      ]],
      formaUso: [model.formaUso, [        
        Validators.maxLength(500)        
      ]],
      apresentacao: [model.apresentacao, [        
        Validators.maxLength(200)        
      ]],
      principioAtivo: [model.principioAtivo, [        
        Validators.maxLength(200)        
      ]],
      usoFrequente: [model.usoFrequente, Validators.required]
    });
  }

  newModel() : MedicamentoModel {
    var model = super.newModel();
    model.usoFrequente = false;
    return model;
  }
}
