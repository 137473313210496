import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { BaseAdminModule } from 'projects/ProjetoBaseAngular/app/admin/base-admin.module';
import { AdminRoutes } from './admin.routing';
import { SideBarNavComponent } from './layout/sidebar-nav.component';

import { ParceiroModule } from './parceiro/parceiro.module';
import { TelaInicialComponent } from './tela-inicial/tela-inicial.component';
import { AdminComponent } from './admin.component';
import { TopBarComponent } from './layout/topbar.component';
import { SideBarUserAreaComponent } from './layout/sidebar-user-area.component';
import { SideBarFooterComponent } from './layout/sidebar-footer.component';
import { RightSideBarComponent } from './layout/right-sidebar.component';
import { TipoServicoModule } from './servico/tipo-servico/tipo-servico.module';
import { ServicoModule } from './servico/servico/servico.module';
import { ConvenioModule } from './parceiro/convenio/convenio.module';
import { SetorModule } from './equipe/setor/setor.module';
import { EspecialidadeMedModule } from './equipe/especialidade-med/especialidade-med.module';
import { AreaAtuacaoMedModule } from './equipe/area-atuacao-med/area-atuacao-med.module';
import { EquipeModule } from './equipe/equipe/equipe.module';
import { ProfissionalSaudeModule } from './equipe/profissional-saude/profissional-saude.module';
import { ArtigoTagModule } from './artigo/artigo-tag/artigo-tag.module';
import { ArtigoModule } from './artigo/artigo/artigo.module';
import { EnqueteModule } from './enquete/enquete/enquete.module';
import { CargoModule } from './rh/cargo/cargo.module';
import { VagaModule } from './rh/vaga/vaga.module';
import { CurriculoModule } from './rh/curriculo/curriculo.module';
import { RevistaModule } from './revista/revista/revista.module';
import { MeuSiteModule } from './meu-site/meu-site.module';
import { ContatoSiteModule } from './contato/contato-site/contato-site.module';
import { RequisicaoLgpdModule } from './lgpd/requisicao-lgpd/requisicao-lgpd.module';
import { PoliticaPrivacidadeModule } from './lgpd/politica-privacidade/politica-privacidade.module';
import { IconeModule } from './Icone/icone/icone.module';
import { CategoriaIconeModule } from './Icone/categoria-icone/categoria-icone.module';

import { AtendimentoGroupModule } from './atendimento/atendimento-group.module';
import { ProfissionalSaudeTextoPadraoModule } from './equipe/profissional-saude-texto-padrao/profissional-saude-texto-padrao.module';
import { UsuariosModule } from './usuario/usuarios.module';
import { EmpresaModule } from './empresa/empresa.module';
import { PesquisaAdmModule } from './pesquisa-adm/pesquisa-adm.module';
import { ContatoPreAgendamentoModule } from './contato/contato-pre-agendamento/contato-pre-agendamento.module';
import { FaturamentoModule } from './faturamento/faturamento.module';
import { PacienteModule } from './paciente/paciente/paciente.module';
import { GrupoPacienteModule } from './paciente/grupo-paciente/grupo-paciente.module';
import { ExameModule } from './modelos/exame/exame.module';
import { ReceitaModule } from './modelos/receita/receita.module';
import { AtestadoModule } from './modelos/atestado/atestado.module';
import { WhatsAppChatModule } from './atendimento-online/whasapp-chat/whatsapp-chat.module';
import { ChatBotConfigModule } from './atendimento-online/chatbot-config/chatbot-config.module';
import { RelRepasseModule } from './relatorios/rel-repasse/rel-repasse.module';
import { RelRepasseSimplificadoModule } from './relatorios/rel-repasse-simplificado/rel-repasse-simplificado.module';
import { RelRecebimentoConvenioModule } from './relatorios/rel-recebimento-convenio/rel-recebimento-convenio.module';
import { RelDemonstrativoPeriodoModule } from './relatorios/rel-demonstrativo-periodo/rel-demonstrativo-periodo.modulo';
import { RelTabelaModule } from './relatorios/rel-tabela/rel-tabela.module';
import { RelPacientesPeriodoModule } from './relatorios/rel-pacientes-periodo/rel-pacientes-periodo.module';
import { RelAtendimentosChatbotModule } from './relatorios/rel-atendimentos-chatbot/rel-atendimentos-chatbot.modulo';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BaseAdminModule,
    AdminRoutes,    

    // Servico
    TipoServicoModule,
    ServicoModule,

    // Parceiros
    ParceiroModule,
    ConvenioModule,

    // Equipe
    EquipeModule,
    SetorModule,
    EspecialidadeMedModule,
    AreaAtuacaoMedModule,
    ProfissionalSaudeModule,
    ProfissionalSaudeTextoPadraoModule,

    // Artigo
    ArtigoModule,
    ArtigoTagModule,

    // Enquete
    EnqueteModule,

    // RH
    CargoModule,
    VagaModule,
    CurriculoModule,

    // Revista
    RevistaModule,

    // Site
    MeuSiteModule,

    // Contato Site
    ContatoSiteModule,
    ContatoPreAgendamentoModule,

    // Lgpd
    RequisicaoLgpdModule,

    // Politica de Privacidade
    PoliticaPrivacidadeModule,

    // Icone
    IconeModule,
    CategoriaIconeModule,

    PesquisaAdmModule,
    EmpresaModule,

    PacienteModule,
    GrupoPacienteModule,
    AtendimentoGroupModule,
    FaturamentoModule,
    
    //Relatorios
    RelRepasseModule,
    RelRepasseSimplificadoModule,
    RelRecebimentoConvenioModule,
    RelDemonstrativoPeriodoModule,
    RelTabelaModule,
    RelPacientesPeriodoModule,
    RelAtendimentosChatbotModule,

    //Modelos
    ExameModule,
    ReceitaModule,
    AtestadoModule,

    //ChatBot
    ChatBotConfigModule,
    WhatsAppChatModule,

    UsuariosModule
  ],
  declarations: [
    AdminComponent,
    TelaInicialComponent,

    TopBarComponent,
    SideBarNavComponent,
    SideBarUserAreaComponent,
    SideBarFooterComponent,
    RightSideBarComponent,
  ],
  exports: [
    AdminComponent,
  ]
})
export class AdminModule { }
