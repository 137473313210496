import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

import { TypesService } from 'projects/admin/src/domain/services';
import { ConfigService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { LaudoModel } from '../../domain/models';
import { LaudoService } from '../../domain/services';

@Component({
  selector: 'app-laudo-print',
  templateUrl: './laudo-print.component.html'
})
export class LaudoPrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  laudo: LaudoModel;  
  imprimirCabRod: boolean = true;
  

  constructor(
    readonly laudoService: LaudoService,    
    readonly printService: PrintService,
    readonly typesService: TypesService,
    readonly configService: ConfigService,
    readonly sanitizer: DomSanitizer
  ) { 
  }

  ngOnInit() {
  }

  async print(laudoId: string) {
    this.laudo = await this.laudoService.getById(laudoId).toPromise();    
    let configRes = await this.configService.getConfigByGrupoChave("Impressao", "LaudoImprimeCabRod").toPromise();
    this.imprimirCabRod = (configRes.valor === "S");
    //this.laudo.cabecalho = this.laudo.cabecalho.replace("\n", "<br>");

    setTimeout(() => {
      const content = this.relatorio.nativeElement.innerHTML;
      this.printService.print('Laudo', content);
    }, 1000);
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }
}
