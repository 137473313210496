import { Component, OnInit, ViewChild, Output, Injector, EventEmitter } from '@angular/core';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { GrupoUsuarioModel, PaginaModel } from 'projects/ProjetoBaseAngular/domain/models';

import { GrupoUsuarioService, PaginaService } from 'projects/ProjetoBaseAngular/domain/services';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GrupoUsuarioPermissaoModel } from 'projects/ProjetoBaseAngular/domain/models/controle-acesso/grupo-usuario-permissao-model';

@Component({
  selector: 'app-grupo-usuario-modal',
  templateUrl: './grupo-usuario-modal.component.html',
})
export class GrupoUsuarioModalComponent extends BaseCrudModalComponent<GrupoUsuarioModel> implements OnInit {
  @ViewChild('grupoUsuarioModal') modal: ModalDirective;

  @Output() modalSave: EventEmitter<GrupoUsuarioModel> = new EventEmitter<GrupoUsuarioModel>();

  paginas: PaginaModel[] = [];
  permissoes: GrupoUsuarioPermissaoModel[] = [];

  constructor(
    injector: Injector,
    grupoUsuarioService: GrupoUsuarioService,
    private paginaService: PaginaService,
  ) {
    super(injector, grupoUsuarioService);
  }

  protected initializeForm(grupoUsuario: GrupoUsuarioModel) {
    if (grupoUsuario.permissoes) {
      this.permissoes = grupoUsuario.permissoes;
    }
    this.setPermissoes();
    this.form = this.formBuilder.group({
      id: grupoUsuario.id,
      dataInclusao: [grupoUsuario.dataInclusao, Validators.required],
      dataAlteracao: grupoUsuario.dataAlteracao,
      nome: [grupoUsuario.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
      permissoes: null
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async save(autoSave: boolean = false) {
    this.form.patchValue({ permissoes: this.permissoes });
    await super.save(autoSave);
  }

  close() {
    super.close();
    this.permissoes = [];
    this.paginas = [];
  }

  getPermissoesByPagina(pagina: PaginaModel) {
    if (!pagina || !this.permissoes) {
      return;
    }
    return this.permissoes.filter(x => x.paginaId === pagina.id).sort((a, b) => a.acaoNome > b.acaoNome ? 1 : -1);
  }

  setPermissoes() {
    this.paginaService.get().subscribe(paginas => {
      this.paginas = paginas.sort((a, b) => a.nome > b.nome ? 1 : -1);
      this.paginas.forEach(pagina => {
        pagina.acoes.forEach(paginaAcao => {
          if (!pagina) {
            return;
          }
            if (!this.permissoes.find(x => x.paginaId === pagina.id && x.acaoId === paginaAcao.acaoId)) {
              const permissao = new GrupoUsuarioPermissaoModel();
              permissao.acaoId = paginaAcao.acaoId;
              permissao.acaoNome = paginaAcao.acaoNome;
              permissao.acaoDescricao = paginaAcao.acaoDescricao;
              permissao.paginaId = pagina.id;
              permissao.paginaNome = pagina.nome;
              permissao.grupoUsuarioId = this.form.value.id;
              permissao.temPermissao = this.newItem;
              this.permissoes.push(permissao);
            }
        });
      });
    });
  }
}
