import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ProfissionalSaudeTextoPadraoService } from '../../domain/services';
import { ProfissionalSaudeTextoPadraoModel } from '../../domain/models';

@Component({
  selector: 'app-profissional-saude-texto-padrao-modal',
  templateUrl: './profissional-saude-texto-padrao-modal.component.html'
})
export class ProfissionalSaudeTextoPadraoModalComponent extends BaseCrudModalComponent<ProfissionalSaudeTextoPadraoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  private profissionalSaudeId: string;

  constructor(
    injector: Injector,
    service: ProfissionalSaudeTextoPadraoService
  ) {
    super(injector, service);
  }

  protected initializeForm(model: ProfissionalSaudeTextoPadraoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      profissionalSaudeId: [model.profissionalSaudeId, [
        Validators.required
      ]],
      texto: [model.texto, [
        Validators.required
      ]],
      palavrasChave: [model.palavrasChave, [
        Validators.maxLength(100)
      ]],
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  newModel(): ProfissionalSaudeTextoPadraoModel  {
    const model = super.newModel();
    model.profissionalSaudeId = this.profissionalSaudeId;
    return model;
  }

  async showCreate(model: ProfissionalSaudeTextoPadraoModel) {
    this.profissionalSaudeId = model.profissionalSaudeId;
    super.showCreate(model);
  }
}
