import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tela-inicial',
  templateUrl: './tela-inicial.component.html'
})
export class TelaInicialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
