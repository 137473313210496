<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
<div class="card" *ngIf="form">
  <form (ngSubmit)="save()" [formGroup]="form">
    <div class="header">      
      <h2>Layout de Impressão</h2>
    </div>
    <div class="body">
      <div class="alert alert-danger" *ngIf="errors.length > 0">
        <h3>Oops! Algo deu errado:</h3>
        <ul>
          <li *ngFor="let error of errors">{{error}}</li>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-label>Cabeçalho</mat-label>
          <editor formControlName="cabecalho" [init]="tinymceConfig(200)">
          </editor>
        </div>
        <div class="col-md-12">
          <mat-label>Rodapé</mat-label>
          <editor formControlName="rodape" [init]="tinymceConfig(200)">
          </editor>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">    
    <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
      class="button-row">
      <span> Salvar</span>
    </button>
    <button mat-stroked-button type="button" [routerLink]="" (click)="ngOnInit()" class="button-row">
      <span> Cancelar</span>
    </button>
  </div>
</div>