import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { EstadoModel, CidadeModel, ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { EstadoService, CidadeService } from 'projects/ProjetoBaseAngular/domain/services';
import { ConvenioService } from '../../domain/services/convenio.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';

import { Validators } from '@angular/forms';
import { ConvenioModel } from '../../domain/models';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';

@Component({
  selector: 'app-convenio-modal',
  templateUrl: './convenio-modal.component.html',
})
export class ConvenioModalComponent extends BaseCrudModalComponent<ConvenioModel> implements OnInit {
  @ViewChild('convenioModal') modal: ModalDirective;
  @ViewChild('imagemSelectModal') imagemSelectModal: ImagemSelectModalComponent;

  estados: EstadoModel[];
  cidades: CidadeModel[];
  formBuilder: any;

  constructor(
    injector: Injector,
    private readonly estadoService: EstadoService,
    private readonly cidadeService: CidadeService,
    service: ConvenioService,
  ) {
    super(injector, service);
  }
  
  ngOnInit() {
    super.ngOnInit();    
    this.estadoService.getAll().subscribe(estados => this.estados = estados);
  }

  protected initializeForm(model: ConvenioModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: this.fromJsonDate(model.dataAlteracao),
      registroAtivo: [model.registroAtivo],
      ordem: [model.ordem],
      nome: [model.nome, [
        Validators.required,
        Validators.maxLength(60)
      ]
      ],
      telefone: [model.telefone, Validators.maxLength(15)],
      urlSite: [model.urlSite,
      Validators.maxLength(200)
      ],
      imagemUrl: [model.imagemUrl],
      imagemUrlBase: [model.imagemUrlBase],
      imagemPath: [model.imagemPath],
      ruaAvenida: [model.ruaAvenida, [
        Validators.minLength(5),
        Validators.maxLength(50)]
      ],
      numero: [model.numero, Validators.maxLength(10)],
      complemento: [model.complemento, Validators.maxLength(50)],
      bairro: [model.bairro, Validators.maxLength(50)],
      cep: [model.cep, Validators.maxLength(10)],
      estadoId: model.estadoId,
      cidadeId: model.cidadeId,
      digitosMatricula: [model.digitosMatricula, Validators.maxLength(20)],
      matriculaObrigatoria: [model.matriculaObrigatoria || false, Validators.required],
      permiteAlterarValor: [model.permiteAlterarValor || false, Validators.required],
      codigo: [model.codigo, Validators.required]
    });
  }

  protected newModel(): ConvenioModel {
    const model = new ConvenioModel();
    model.ordem = 0;
    model.codigo = 0;

    return model;
  }

  changeCep(cep: string) {
    if (cep) {
      this.commonService.queryPostalCode(cep).subscribe((endereco: any) => {
        this.form.patchValue({
          ruaAvenida: endereco.logradouro,
          bairro: endereco.bairro
        });

        const estadoId = this.estados.find(x => x.uf === endereco.uf).id;
        this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => {
          this.cidades = cidades;
          const cidadeId = this.cidades.find(x => x.codigoIbge === endereco.ibge).id;

          this.form.patchValue({ estadoId, cidadeId });
        });
      });
    }
  }

  changeEstado(estadoId: string) {
    this.cidadeService.getByEstadoId(estadoId).subscribe(cidades => this.cidades = cidades);
  }

  beforeShowEdit(model: ConvenioModel) {
    model.estadoId && this.changeEstado(model.estadoId);
    return model;
  }

  showSelectImage() {
    this.imagemSelectModal.showSelect(800);
  }

  onResponse(imagem: ImagemModel) {
    this.form.patchValue({
      imagemUrl: imagem.url,
      imagemUrlBase: imagem.urlBase,
      imagemPath: imagem.path
    });
  }

  deselectImage() {
    this.form.patchValue({
      imagemUrl: null,
      imagemUrlBase: null,
      imagemPath: null
    });
  }

}

