import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { GrupoUsuarioService, TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { GrupoUsuarioModel, TenantModel, UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { ProfissionalRecursoService } from '../../atendimento/domain/services/profissional-recurso.service';
import { UsuarioCustomService } from '../domain/services/usuario/usuario-custom.service';
import { UsuarioCustomModel } from '../domain/models';

@Component({
  selector: 'app-usuario-modal',
  templateUrl: './usuario-modal.component.html'
})
export class UsuarioModalComponent extends BaseComponent implements OnInit {
  @ViewChild('usuarioModal') modal: ModalDirective;
  @ViewChild('focus') focusElement: ElementRef;

  @Output() modalSave: EventEmitter<UsuarioModel> = new EventEmitter<UsuarioModel>();

  isBusy: boolean;
  newItem: boolean;
  isKleinCode: boolean;

  gruposUsuario: GrupoUsuarioModel[];
  recursos: SelectDto[];
  tenants: TenantModel[];

  constructor(
    injector: Injector,
    private readonly usuarioCustomService: UsuarioCustomService,
    private readonly grupoUsuarioService: GrupoUsuarioService,
    private readonly tenantService: TenantService,
    private readonly profissionalRecursoService: ProfissionalRecursoService
  ) {
    super(injector);
  }

  private initializeForm(usuario: UsuarioCustomModel) {
    this.form = this.formBuilder.group({
      id: usuario.id,
      nome: [usuario.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
      sobrenome: [usuario.sobrenome, Validators.maxLength(50)],
      email: [usuario.email, [
        Validators.maxLength(256),
        Validators.required
      ]],
      userNameTenant: [usuario.userNameTenant, [
        Validators.maxLength(256),
        Validators.required
      ]],
      profissionalRecursoIds: [usuario.profissionalRecursoIds],
      superUsuarioTenant: usuario.superUsuarioTenant,
      ativo: usuario.ativo,
      password: usuario.password,
      confirmPassword: usuario.confirmPassword,
      grupoUsuarioId: usuario.grupoUsuarioId,
      tenantId: usuario.tenantId
    });
  }

  private create(usuario: UsuarioModel) {
    this.usuarioCustomService.create(usuario).subscribe(
      (usuario) => {
        this.close();
        this.modalSave.emit(usuario);
        this.form = null;
        this.isBusy = false;
      },
      (errors) => {
        this.errors = errors;
        this.isBusy = false;
      });
  }

  private edit(usuario: UsuarioModel) {
    this.usuarioCustomService.edit(usuario).subscribe(
      (usuario) => {
        this.close();
        this.modalSave.emit(usuario);
        this.form = null;
        this.isBusy = false;
      },
      (errors) => {
        this.errors = errors;
        this.isBusy = false;
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.isKleinCode = this.usuarioCustomService.getTenant() === 'kleincode';
    this.grupoUsuarioService.getAll().subscribe(gruposUsuario => this.gruposUsuario = gruposUsuario);
    this.tenantService.getAll().subscribe(tenants => this.tenants = tenants);
    this.profissionalRecursoService.getSelectList().subscribe(recursos => this.recursos = recursos);
  }

  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    this.isBusy = true;
    const usuario: UsuarioModel = Object.assign({}, new UsuarioModel(), this.form.value);
    if (this.newItem) {
      usuario.id = this.commonService.newGuid();
      this.create(usuario);
    } else {
      this.edit(usuario);
    }
  }

  showCreate() {
    this.newItem = true;
    const usuario = new UsuarioModel();
    usuario.superUsuarioTenant = false;
    usuario.ativo = true;
    this.initializeForm(usuario);
    this.modal.show();
  }

  showEdit(id: string) {
    this.newItem = false;
    this.usuarioCustomService.getById(id).subscribe(usuario => {
      this.initializeForm(usuario);
      this.modal.show();
    });
  }

  close() {
    this.form = null;
    this.modal.hide();
  }

  onShown() {
    this.focusElement.nativeElement.focus();
  }
}
