import { BaseModel } from 'projects/ProjetoBaseAngular/domain/models/base/base-model';
import { StatusPublicacaoType } from 'projects/admin/src/domain/types';


export class EnqueteModel extends BaseModel {
  public titulo: string;
  public dataInicial: Date;
  public dataFinal: Date;
  public descricaoSite: string;
  public descricaoInterna: string;

  public opcoes: EnqueteOpcaoModel[];

  public statusPublicacao: StatusPublicacaoType = StatusPublicacaoType.Rascunho;
}

export class EnqueteOpcaoModel extends BaseModel {
  public enqueteId: string;
  public ordem: number;
  public descricaoOpcao: string;
  public respostas: number;
}

export class EnqueteRespostaModel extends BaseModel {
    public enqueteOpcao: EnqueteOpcaoModel;
    public enqueteOpcaoId: string;
    public dataResposta: Date;
}
