import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ArtigoTagModalComponent } from './artigo-tag-modal/artigo-tag-modal.component';
import { ArtigoTagModel } from '../domain/models';
import { ArtigoTagService } from '../domain/services';

@Component({
  selector: 'app-artigo-tag',
  templateUrl: './artigo-tag.component.html'
})
export class ArtigoTagComponent extends BaseCrudComponent<ArtigoTagModel> implements OnInit {
  @ViewChild('artigoTagModal') modal: ArtigoTagModalComponent;

  selectColumns = [{ data: 'nome'}];
  
  constructor(
    injector: Injector,
    readonly artigoTagService: ArtigoTagService
  ) {
    super(injector, artigoTagService);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
