<div class="progress">
  <div class="progress-bar progress-bar-danger" [style.width]="armazenamento.percentualEmUsoImagens + '%'" matTooltip="{{armazenamento.totalEmUsoImagens}}" matTooltipPosition="above">                
    {{(armazenamento.percentualEmUsoImagens | mask: 'separator.2') + '% imagens'}}        
  </div>
  <div class="progress-bar progress-bar-warning" [style.width]="armazenamento.percentualEmUsoArquivos + '%'" matTooltip="{{armazenamento.totalEmUsoArquivos}}" matTooltipPosition="above">        
    {{(armazenamento.percentualEmUsoArquivos | mask: 'separator.2') + '% arquivos'}}        
  </div>
  <div class="progress-bar progress-bar-success" [style.width]="armazenamento.percentualDisponivel + '%'" matTooltip="{{armazenamento.totalDisponivel}}" matTooltipPosition="above">        
    {{(armazenamento.percentualDisponivel | mask: 'separator.2') + '% disponível'}}        
  </div>
</div>