import { OptionSearchType, SearchType } from "../types";

export class ModelSearch {
    constructor(field?: string, option?: OptionSearchType, type?: SearchType, searchTerm?: any) {
        this.field = field;
        this.option = option;
        this.type = type;
        this.searchTerm = searchTerm;
    }

    public field: string;
    public option: OptionSearchType;
    public type: SearchType;
    public searchTerm: any;
}
