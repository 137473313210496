<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Receita do Atendimento</h2>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5">
            <form (ngSubmit)="save()" [formGroup]="form">
              <div class="alert alert-danger" *ngIf="errors.length > 0">
                <h3>Oops! Algo deu errado:</h3>
                <ul>
                  <li *ngFor="let error of errors">{{error}}</li>
                </ul>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field>
                    <mat-label>Seq.</mat-label>
                    <input matInput formControlName="seq" type="text" />
                    <mat-error>Informe um Seq válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-9">
                  <mat-form-field>
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="tipo" required #focus>
                      <mat-option *ngFor="let tipo of typesService.receitaType" [value]="tipo.value">{{tipo.text}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione um Tipo.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Descrição</mat-label>
                    <textarea matInput formControlName="descricao" rows="23" required></textarea>
                    <mat-error>Informe um Descrição válida.</mat-error>
                  </mat-form-field>
                </div>
                <!-- <div class="col-md-12">
                  <mat-label>Descrição</mat-label>
                  <editor formControlName="descricao" [init]="tinymceConfigLocal(350)"></editor>
                </div> -->
              </div>
            </form>
          </div>
          <div class="col-md-7">
            <ul class="nav nav-tabs">
              <li class="active">
                <a class="nav-link active" id="nav-itens-tab" data-toggle="tab" href="#nav-itens" role="tab"
                  aria-controls="nav-itens" aria-selected="true">Itens</a>
              </li>
              <li>
                <a class="nav-link" id="nav-modelos-tab" data-toggle="tab" href="#nav-modelos" role="tab"
                  aria-controls="nav-modelos" aria-selected="false">Modelos</a>
              </li>
            </ul>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade in active" id="nav-itens" role="tabpanel" aria-labelledby="nav-itens-tab">
                <div class="row">
                  <div class="col-md-3">
                    <label>Opção</label>
                    <mat-radio-group [(ngModel)]="searchUsoFrequente.searchTerm" (change)="onChangeOpcao()">
                      <mat-radio-button *ngFor="let opcao of typesService.usoFrequenteTypes" [value]="opcao.value">
                        {{opcao.text}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Tipo Filtro</mat-label>
                      <mat-select [(ngModel)]="searchNome.option">
                        <mat-option *ngFor="let option of typesService.filterOptions" [value]="option.value">
                          {{option.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field>
                      <mat-label>Pesquisar por</mat-label>
                      <input matInput type="text" [(ngModel)]="searchNome.searchTerm"
                        (keyup.enter)="filterMedicamento();" onkeypress="return event.keyCode !== 13;" />
                      <button matSuffix mat-icon-button type="button" (click)="filterMedicamento()"
                        matTooltip="Filtrar">
                        <mat-icon>search</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
                <div class="table-responsive">
                  <table #datatableMedicamento datatable [dtOptions]="dtOptionsMedicamento"
                    class="table table-bordered table-striped table-hover" style="width: 90%;">
                    <thead>
                      <tr>
                        <th class="text-center">Descrição</th>
                        <th width="80"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="listaMedicamento?.length != 0">
                      <tr *ngFor="let item of listaMedicamento" matTooltip="Adicionar medicamento"
                        style="cursor: pointer;">
                        <td (click)="addMedicamento(item.id)">
                          {{item.descricao}}
                        </td>
                        <td class="text-center">
                          <button *ngIf="searchUsoFrequente.searchTerm" [disabled]="!isAllowedMedicamento('Update')"
                            mat-icon-button type="button" (click)="remUsoFreq(item)"
                            matTooltip="Remover de Uso Frequente">
                            <mat-icon>remove_circle_outline</mat-icon>
                          </button>
                          <button *ngIf="!searchUsoFrequente.searchTerm" [disabled]="!isAllowedMedicamento('Update')"
                            mat-icon-button type="button" (click)="addUsoFreq(item)"
                            matTooltip="Adicionar em Uso Frequente">
                            <mat-icon>post_add</mat-icon>
                          </button>
                          <!-- <button [disabled]="!isAllowedMedicamento('Update')"
                                  mat-icon-button type="button" (click)="editMedicamento(item)" matTooltip="Editar">
                                  <mat-icon>edit</mat-icon>
                                </button>
                                <button [disabled]="!isAllowedMedicamento('Delete')"
                                  mat-icon-button type="button" (click)="deleteMedicamento(item.id)" matTooltip="Excluir">
                                  <mat-icon>delete</mat-icon>
                                </button> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-modelos" role="tabpanel" aria-labelledby="nav-modelos-tab">
                <div class="table-responsive">
                  <table #datatableModelos datatable [dtOptions]="dtOptionsModelos" *ngIf="dtOptionsModelos"
                    class="table table-bordered table-striped table-hover" style="width: 100%;">
                    <thead>
                      <tr>
                        <th class="text-center">Nome</th>
                        <th width="5"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="listaModelo?.length != 0">
                      <tr *ngFor="let item of listaModelo" matTooltip="Importar modelo" style="cursor: pointer;">
                        <td (click)="addModelo(item.id)">
                          {{item.nome}}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>