<div class="container-fluid">
  <br />
  <div class="row" *ngIf="models?.length > 0">
    <div class="col-md-4" *ngFor="let model of models">
      <div class="card">
        <div class="header bg-blue">
          <h2>#{{model.seq}} Solicitação de Exames</h2>
        </div>
        <div class="body" style="height: 100px; overflow-y: auto; padding: 0px; padding-left: 5px;" [innerHTML]="sanitizer.bypassSecurityTrustHtml(model.descricaoHtml)">
        </div>
        <div class="footer">
          <div class="row">
            <!-- <div class="col-sm-4 text-left">
              <button type="button" mat-flat-button color="primary" (click)="compararResultados(model)">
                Ver Resultados
              </button>
            </div> -->
            <div class="col-sm-8">
              <button mat-icon-button type="button" (click)="solicExamePrint.print(model)"
                matTooltip="Imprimir Solicitação de Exames">
                <mat-icon>print</mat-icon>
              </button>
              <button [disabled]="detailMode" mat-icon-button type="button" (click)="edit(model)" matTooltip="Editar">
                <mat-icon>edit</mat-icon>
              </button>
              <button [disabled]="detailMode" mat-icon-button type="button" (click)="delete(model.id)"
                matTooltip="Excluir">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2" style="display: flex; justify-content: center; align-items: center; height: 50px;">
      <button [disabled]="detailMode" mat-fab type="button" color="primary" matTooltip="Nova Solicitação"
        (click)="create()">
        <mat-icon>add</mat-icon>
      </button>
      <button [disabled]="detailMode" mat-fab type="button" color="primary" style="margin-right: 5px;"
        matTooltip="Histórico de solicitação de exames (Atendimentos Anteriores)" (click)="showHistorico()">
        <mat-icon>library_add_check</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="!(models?.length > 0)">
    <h4 class="text-center">Nenhuma solicitação adicionada ainda.</h4>
    <div style="display: flex; justify-content: center; align-items: center; height: 200px;">
      <button [disabled]="detailMode" mat-fab type="button" color="primary" matTooltip="Nova Solicitação"
        (click)="create()">
        <mat-icon>add</mat-icon>
      </button>
      <button [disabled]="detailMode" mat-fab type="button" color="primary" style="margin-right: 5px;"
        matTooltip="Histórico de Receitas (Atendimentos Anteriores)" (click)="showHistorico()">
        <mat-icon>library_add_check</mat-icon>
      </button>
    </div>
  </div>
  <br />
</div>
<app-atendimento-solic-exame-modal #modal (modalSave)="onResponse($event)" 
  [atendimentoId]="atendimentoId" [profissionalId]="profissionalId">
</app-atendimento-solic-exame-modal>
<app-atendimento-solic-exame-item-modal #itemModal (modalSave)="onResponse($event)">
</app-atendimento-solic-exame-item-modal>
<app-atendimento-solic-exame-print #solicExamePrint>
</app-atendimento-solic-exame-print>
<app-atendimento-solic-exame-historico-modal #modalHistorico (autoSave)="onResponse($event)"
  (cloneSolicExame)="onCloneResponse($event)" [atendimentoId]="atendimentoId" [profissionalId]="profissionalId"
  [pacienteId]="pacienteId">
</app-atendimento-solic-exame-historico-modal>