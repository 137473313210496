<div class="row">
  <div class="col-md-12">
    <button mat-flat-button color="primary" type="button" [disabled]="detailMode" (click)="create()">Adicionar
      Item</button>
  </div>
  <hr />
  <div class="col-md-12 table-responsive">
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center">Procedimento</th>
          <th class="text-center">Vlr. Unitário</th>
          <th class="text-center">Acréscimo</th>
          <th class="text-center">Desconto</th>
          <th class="text-center">Total</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="models?.length != 0">
        <tr *ngFor="let item of models">
          <td [innerHTML]="item.procedimentoNome"></td>
          <td class="text-right" [innerHTML]="item.vlrUnitario | currency:'BRL':''"></td>
          <td class="text-right" [innerHTML]="item.vlrAcrescimo | currency:'BRL':''"></td>
          <td class="text-right" [innerHTML]="item.vlrDesconto | currency:'BRL':''"></td>
          <td class="text-right" [innerHTML]="item.vlrTotal | currency:'BRL':''"></td>
          <td class="text-center">
            <button mat-icon-button type="button" [disabled]="detailMode" (click)="edit(item)" matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button type="button" [disabled]="detailMode" (click)="delete(item.id)"
              matTooltip="Excluir">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-atendimento-procedimento-modal #modal (modalSave)="onResponse($event)" 
  [atendimentoId]="atendimentoId"
  [convenioId]="convenioId">
</app-atendimento-procedimento-modal>