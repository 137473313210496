import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { TipoServicoModel } from '../domain/models';
import { TipoServicoModalComponent } from './tipo-servico-modal/tipo-servico-modal.component';
import { TipoServicoService } from '../domain/services';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-tipo-servico',
  templateUrl: './tipo-servico.component.html',
  styleUrls: ['./tipo-servico.component.scss']
})
export class TipoServicoComponent extends BaseCrudComponent<TipoServicoModel> implements OnInit {
  @ViewChild('tipoServicoModal') modal: TipoServicoModalComponent;

  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  constructor(
    injector: Injector,
    readonly tipoServicoService: TipoServicoService
  ) {
    super(injector, tipoServicoService);
   }

   protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);
    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  ngOnInit() {
    super.ngOnInit();
    this.useDatatables = false;
    this.tipoServicoService.getAll().subscribe(tiposServico => this.models = tiposServico);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.models, event.previousIndex, event.currentIndex);
    const tipoServicoIds = this.models.map(x => x.id);
    this.tipoServicoService.updateOrdens(tipoServicoIds).subscribe();
  }
}
