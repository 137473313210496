import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { MicroRegiaoModalComponent } from './micro-regiao-modal/micro-regiao-modal.component';
import { MicroRegiaoComponent } from './micro-regiao.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    MicroRegiaoComponent,
    MicroRegiaoModalComponent
  ]
})
export class MicroRegiaoModule { }
