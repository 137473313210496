<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Laudo</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Profissional Solicitante</mat-label>
                <mat-select formControlName="profissionalSolicitanteId">
                  <mat-option *ngFor="let profissional of profissionais" [value]="profissional.value">
                    {{profissional.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Profissional Laudante</mat-label>
                <mat-select formControlName="profissionalLaudanteId" (selectionChange)="changeProfissional($event.value)">
                  <mat-option *ngFor="let profissional of profissionais" [value]="profissional.value">
                    {{profissional.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Profissional Técnico/Radiologista</mat-label>
                <mat-select formControlName="profissionalTecnicoId">
                  <mat-option [value]="">Sem Profissonal</mat-option>
                  <mat-option *ngFor="let profissional of profissionais" [value]="profissional.value">
                    {{profissional.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Profissional válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Cód./CPF Paciente</mat-label>
                <input matInput disabled [value]="paciente?.pessoa?.cnpjCpf" type="text" maxlength="18" required />                                
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <mat-form-field>
                <mat-label>Paciente</mat-label>
                <input matInput disabled [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia" type="text" />
                <button [disabled]="isBusy || !paciente?.id" matSuffix mat-icon-button type="button"
                  (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Convênio</mat-label>
                <mat-select formControlName="convenioId" disabled>
                  <mat-option *ngFor="let convenio of convenios" [value]="convenio.value">
                    {{convenio.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione uma Convênio.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" disabled>
                  <mat-option *ngFor="let status of typesService.laudoStatusType" [value]="status.value">
                    {{status.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Status.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input matInput disabled [value]="procedimento?.codigo" type="number" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Nome do Procedimento</mat-label>
                <input matInput disabled [value]="procedimento?.nome" type="text" />
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Layout do Laudo</mat-label>
                <mat-select formControlName="layoutLaudoId" (selectionChange)="changeLayoutLaudoAsync($event.value)">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Buscar..." noEntriesFoundLabel="Nenhum resultado encontrado!" 
                      [formControl]="layoutFilterCtrl">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let layoutLaudo of filteredLayoutLaudos" [value]="layoutLaudo.value">
                    {{layoutLaudo.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Layout válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3" style="margin-top: 15px;">
              <button mat-flat-button color="primary" type="button" (click)="showMontarLaudo()">
                Montar Resultados de Exames
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Cabeçalho</mat-label>
                <textarea matInput formControlName="cabecalho" rows="4"></textarea>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Rodapé</mat-label>
                <textarea matInput formControlName="rodape" rows="4"></textarea>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <editor [disabled]="detailMode" formControlName="texto" [init]="tinymceConfig(250)">
              </editor>
            </div>
          </div>
        </div>
      </form>
      <div class="footer" *ngIf="!detailMode">
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="laudar()" color="primary"
          class="button-row">
          <span> Laudar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
      <div class="footer" *ngIf="detailMode">
        <button mat-flat-button [disabled]="isBusy" (click)="laudoPrint.print(form.value.id)" color="primary"
          class="button-row">
          <span> Imprimir</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>
<app-laudo-print #laudoPrint></app-laudo-print>