import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { ExameModeloModel } from '../models';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

@Injectable({
  providedIn: 'root'
})
export class ExameService extends BaseCrudService<ExameModeloModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ExameModelo';
  }

  getSelectList(): Observable<SelectDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
