<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl" *ngIf="agendamentoId">
    <div class="card modal-content">
      <div class="header">
        <h2>Históricos do Agendamento</h2>
      </div>
      <div class="body table-responsive">
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
          class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center" width="200">Data</th>
              <th class="text-center" width="200">Operação</th>
              <th class="text-center">Terminal</th>
              <th class="text-center">Usuário</th>
              <th class="text-center">Log - Dados Anteriores</th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models">
              <td>{{item.dataInclusao | date:'dd/MM/yyyy HH:mm'}} </td>
              <td class="text-center">
                <span [ngClass]="typesService.getAgendamentoLogColorType(item.tipo)">
                  {{typesService.getAgendamentoLogType(item.tipo)}}
                </span>
              </td>
              <td>{{item.terminal}}</td>
              <td>{{item.usuarioNome}}</td>
              <td [innerHTML]="item.log"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>