import { Injectable, Injector } from '@angular/core';

import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { CategoriaIconeModel, IconeModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class IconeService extends BaseCrudService<IconeModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Icone';
  }

  getAll(): Observable<IconeModel[]> {
    return this
      .httpClient
      .get<IconeModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByCategoriaIconeId(categoriaIconeId: string): Observable<IconeModel[]> {
    return this
      .httpClient
      .get<CategoriaIconeModel>(`${this.getApiUrl()}${this.getController()}/GetByCategoriaIconeId/${categoriaIconeId}`
      , this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
