import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { SetorModel } from '../../../equipe/domain/models';
import { SetorService } from '../../../equipe/domain/services';
import { ContatoStatusType } from '../../domain/types';
import { ContatoSiteModel } from '../../domain/models';
import { ContatoSiteService } from '../../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-contato-site-modal',
  templateUrl: './contato-site-modal.component.html'
})
export class ContatoSiteModalComponent extends BaseCrudModalComponent<ContatoSiteModel> implements OnInit {
  @ViewChild('contatoSiteModal') modal: ModalDirective;

  setores: SetorModel[];
  usuarios: UsuarioModel[];
  setorSelecionado: SetorModel;

  usuarioResponsavelSelecionado: UsuarioModel;

  constructor(
    injector: Injector,
    readonly service: ContatoSiteService,
    readonly setorService: SetorService,
    readonly usuarioService: UsuarioService,
    readonly typesService: TypesService,
  ) {
    super(injector, service);
  }

  protected initializeForm(contato: ContatoSiteModel) {
    this.form = this.formBuilder.group({
      id: contato.id,
      dataInclusao: [contato.dataInclusao, Validators.required],
      dataAlteracao: [contato.dataAlteracao],
      nome: [contato.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60)]
      ],
      email: [contato.email, [
        Validators.required,
        Validators.maxLength(200)]
      ],
      telefone: [contato.telefone, [
        Validators.maxLength(16)]
      ],
      celular: [contato.celular, [
        Validators.maxLength(16)]
      ],
      mensagem: [contato.mensagem, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(500)]
      ],
      setorId: [contato.setorId, Validators.required],
      status: [contato.status],
      data: [this.fromJsonDate(contato.data)],
      dataStatus: [this.fromJsonDate(contato.dataStatus)],
      usuarioResponsavelId: [contato.usuarioResponsavelId],
      observacaoInterna: [contato.observacaoInterna],
    });
  }

  beforeShowCreate(model: ContatoSiteModel): ContatoSiteModel {    
    model.status = ContatoStatusType.Pendente;
    model.data = new Date();
    model.dataStatus = new Date();
    return super.beforeShowCreate(model);
  }

  onChangeStatus() {
    this.form.patchValue({
      dataStatus: this.fromJsonDate(new Date())
    });
  }

  onChangeSetor(setorId: string) {
    this.setorSelecionado = this.setores.find(x => x.id === setorId);

    this.form.patchValue({
      usuarioResponsavelNome: this.setorSelecionado.usuarioResponsavelNome,
      usuarioResponsavelId: this.setorSelecionado.usuarioResponsavelId
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.setorService.get().subscribe(setores => this.setores = setores);
    this.usuarioService.getAll().subscribe(usuarios => this.usuarios = usuarios);
  }
}
