<div bsModal #iconeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="iconeModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Icones</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">

                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>Categoria Icone</mat-label>
                    <mat-select formControlName="categoriaIconeId" #focus>
                      <mat-option *ngFor="let categoriaIcone of categoriasIcone" [value]="categoriaIcone.id">
                        {{categoriaIcone.nome}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Selecione um Categoria de Serviço.</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-8">
                  <mat-form-field>
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="nome" type="text" />
                    <mat-error>Informe um Nome válido.</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-7">
                  <mat-form-field>
                    <mat-label>Icone HTML</mat-label>
                    <input matInput formControlName="iconeHtml" type="text" />
                    <mat-error>Informe uma IconeHTML válido.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-1 font-size-h1" [innerHTML]="form.value.iconeHtml">
                </div>

                <div class="col-md-12">
                  <a href="https://fontawesome.com/icons?d=gallery" target="_blank" rel="noopener"> Clique Aqui </a>
                  para acessar site de ícones
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
