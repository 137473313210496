export const ConfigChatbotConsts = {
  QtdErrosTransfAtend: "QtdErrosTransfAtend",
  MsgCanceladoCliente: "MsgCanceladoCliente",
  MsgTransferidoAtendente: "MsgTransferidoAtendente"
}

export const ConfigAgendamentoConsts = {
  UsaAgendamentoRecorrente: "UsaAgendamentoRecorrente"  
}

/**
 * Subscribe event types.
 * Valores de tipos de eventos aos quais uma sessão de 
 * usuário da API estará apta a receber mensagens
 */
export const WhatsAppApiEvents = {
  EventMessage: "Message",
  EventReadReceipt: "ReadReceipt",
  EventPresence: "Presence",
  EventHistoricSync: "HistoricSync",
  EventChatPresence: "ChatPresence",
  EventCall: "Call"
}