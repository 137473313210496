import { Component, Injector, ViewEncapsulation } from '@angular/core';

import { MenuItem } from 'projects/ProjetoBaseAngular/shared/layout/menu-item';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { TenantComponenteModel, TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { AccountService, TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { ComponenteSistemaType } from 'projects/ProjetoBaseAngular/domain/types';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideBarNavComponent extends BaseComponent {
  menuItems: MenuItem[] = [];
  tenant: TenantModel;

  constructor(
    injector: Injector,
    readonly typesService: TypesService,
    readonly tenantService: TenantService,
  ) {
    super(injector);
    $.AdminBSB.options.leftSideBar.breakpointWidth = 2000;
  }

  ngOnInit() {
    if (this.accountService.isAdminKleinCode()) {
      this.prepararMenuAdm();
    }
    else {
      this.tenantService.getCurrentTenant().subscribe(tenant => {
        this.tenant = tenant;
        const modulos = this.tenant.componentes.filter(x => x.tipoItem === ComponenteSistemaType.Modulo);

        if (modulos.find(x => x.codigoItem === this.typesService.MODULO_PORTAL)) {
          this.prepararMenuPortalTenant();
        }

        if (modulos.find(x => x.codigoItem === this.typesService.MODULO_ATENDIMENTO)) {
          this.prepararMenuAtendimentoTenant();
        }

        // Para Carregar o menu, senão menu fica bloqueado
        setTimeout(() => {
          $.AdminBSB.leftSideBar.activate();
        }, 500);
      });
    }
  }

  prepararMenuPortalTenant() {
    this.menuItems = [
      new MenuItem('Notificação Push', 'Push', 'send', '/push'),
      new MenuItem('Contatos', '', 'contact_page', '', [
        new MenuItem('Contato', 'ContatoSite', '', '/contato-site'),
        new MenuItem('Pré-Agendamento', 'ContatoPreAgendamento', '', '/contato-pre-agendamento'),
        new MenuItem('Requisição LGPD', 'RequisicaoLgpd', '', '/requisicao-lgpd'),
      ]),
      new MenuItem('Cadastros', '', 'add_circle_outline', '', [
        new MenuItem('Básicos', '', '', '', [
          new MenuItem('Tipo Serviço', 'TipoServico', '', '/tipo-servico'),
          new MenuItem('Serviço', 'Servico', '', '/servico'),
          new MenuItem('Especialidade Médica', 'EspecialidadeMed', '', '/especialidade-med'),
          new MenuItem('Area Atuação Médica', 'AreaAtuacaoMed', '', '/area-atuacao-med'),
        ]),
        new MenuItem('RH', '', '', '', [
          new MenuItem('Equipe', 'Equipe', '', '/equipe'),
          new MenuItem('Profissional da Saúde', 'ProfissionalSaude', '', '/profissional-saude'),
          new MenuItem('Setor', 'Setor', '', '/setor'),
          new MenuItem('Cargo/Função', 'Cargo', '', '/cargo'),
          new MenuItem('Vaga', 'Vaga', '', '/vaga'),
          new MenuItem('Currículo', 'Curriculo', '', '/curriculo'),
        ]),
        new MenuItem('Conteúdo Site', '', '', '', [
          new MenuItem('Artigo', 'Artigo', '', '/artigo'),
          new MenuItem('Artigo Tag', 'ArtigoTag', '', '/artigo-tag'),
          new MenuItem('Enquete', 'Enquete', '', '/enquete'),
          new MenuItem('Questionário', 'Questionario', '', '/questionario'),
          new MenuItem('Revista', 'Revista', '', '/revista'),
        ]),
        new MenuItem('Parceiros', '', '', '', [
          new MenuItem('Parceiros', 'Parceiro', '', '/parceiros'),
          new MenuItem('Tipo Parceiros', 'TipoParceiro', '', '/tiposParceiros'),
          new MenuItem('Convênios', 'Convenio', '', '/convenio'),
        ])
      ]),
      new MenuItem('Configurações', '', 'settings', '', [
        new MenuItem('Meu Site', '', '', '', [
          new MenuItem('Temas', 'Tema', '', '/meu-site/temas'),
          new MenuItem('Aparência', 'ConfigSiteTenant', '', '/meu-site/aparencia'),
          new MenuItem('Logotipo', 'ConfigSiteTenant', '', '/meu-site/logotipo'),
          new MenuItem('Páginas Web', 'PaginaWeb', '', '/meu-site/paginas-web'),
          new MenuItem('Menus', 'Menu', '', '/meu-site/menus'),
          new MenuItem('Banners', 'Bloco', '', '/meu-site/banners'),
          new MenuItem('Cabeçalho', 'Bloco', '', '/meu-site/cabecalho'),
          new MenuItem('Rodapé', 'Bloco', '', '/meu-site/rodape'),
          new MenuItem('Scripts', 'Bloco', '', '/meu-site/scripts'),
          new MenuItem('SEO (Otimização de Buscadores)', 'ConfigSiteTenant', '', '/meu-site/seo'),
          new MenuItem('PWA (Progressive Web App)', 'ConfigSiteTenant', '', '/meu-site/pwa'),
          new MenuItem('Contatos e Redes Sociais', 'ConfigSiteTenant', '', '/meu-site/contato')
        ]),
        new MenuItem('Politicas de Privacidade', 'PoliticaPrivacidade', '', '/politica-privacidade'),
        new MenuItem('Dados Empresa', 'Empresa', '', '/empresa'),
        new MenuItem('Configurações', 'Config', '', '/configs'),
        new MenuItem('Controle de Acesso', '', '', '', [
          new MenuItem('Usuários', 'Usuario', '', '/usuarios'),
          new MenuItem('Ações', 'Acao', '', '/acoes'),
          new MenuItem('Paginas', 'Pagina', '', '/paginas'),
          new MenuItem('Grupo de Usuario', 'GrupoUsuario', '', '/grupoUsuarios'),
        ]),
      ]),
      new MenuItem('Galeria', '', 'collections', '', [
        new MenuItem('Imagens', 'Imagem', '', '/imagens'),
        new MenuItem('Arquivos', 'Arquivo', '', '/arquivos'),
        new MenuItem('Icone', 'Icone', '', '/icone'),
        new MenuItem('Categoria Icones', 'CategoriaIcone', '', '/categoria-icone'),
      ]),
    ];
  }

  prepararMenuAtendimentoTenant() {
    const auxArr01: MenuItem[] = [
      //new MenuItem('Agendamentos', 'Agendamento', 'calendar_today', '/agendamento'),
      new MenuItem('Atendimentos', 'Atendimento', '', '/atendimento', null, 'fas fa-user-md fa-2x'),
      new MenuItem('Laudos', 'Laudo', 'sticky_note_2', '/laudo'),
      new MenuItem('Capturas', 'Captura', 'camera_enhance', '/captura'),
      new MenuItem('Equipamentos', 'Equipamento', 'monitor', '/equipamento'),
      new MenuItem('Profissionais da Saúde', 'ProfissionalSaude', 'groups', '/profissional-saude'),
      new MenuItem('Entrega de Exames', 'EntregaExame', 'fact_check', '/entrega-exame'),
      new MenuItem('Protocolos Documento', 'ProtocoloDoc', 'note_alt', '/protocolo-doc'),
      new MenuItem('Cadastros', '', 'add_circle_outline', '', [
        new MenuItem('Básicos', '', '', '', [
          new MenuItem('Especialidade Médica', 'EspecialidadeMed', '', '/especialidade-med'),
          new MenuItem('Area Atuação Médica', 'AreaAtuacaoMed', '', '/area-atuacao-med'),
        ]),
        new MenuItem('Parceiros', '', '', '', [
          new MenuItem('Convênios', 'Convenio', '', '/convenio'),
        ]),
        new MenuItem('Pacientes', '', '', '', [
          new MenuItem('Grupos de Pacientes', 'GrupoPaciente', '', '/grupo-paciente'),
          new MenuItem('Pacientes', 'Paciente', '', '/paciente')
        ]),
        new MenuItem('Atendimento', '', '', '', [
          new MenuItem('Salas de Atendimento', 'SalaAtendimento', '', '/sala-atendimento'),
          new MenuItem('Classificações de Agendamento', 'ClassificacaoAgendamento', '', '/classificacao-agendamento'),
          new MenuItem('Grupos de Procedimento', 'GrupoProcedimento', '', '/grupo-procedimento'),
          new MenuItem('Procedimentos', 'Procedimento', '', '/procedimento'),
          new MenuItem('Medicamentos', 'Medicamento', '', '/medicamento'),
          // new MenuItem('Grupos de Anamnese', 'GrupoAnamnese', '', '/grupo-anamnese'),
          // new MenuItem('Questionários de Anamnese', 'QuestAnamnese', '', '/quest-anamnese'),
          new MenuItem('Modelos de Atestados', 'AtestadoModelo', '', '/atestado'),
          new MenuItem('Modelos de Receitas', 'ReceitaModelo', '', '/receita'),
          new MenuItem('Modelos de Exames', 'ExameModelo', '', '/exame'),
          //new MenuItem('Documentos do SUS', 'DocSus', '', '/doc-sus')
        ]),
      ])
    ];

    if (!this.accountService.hasModulo(this.typesService.MODULO_ATENDIMENTOONLINE)) {
      let itemAgend = new MenuItem('Agendamentos', 'Agendamento', 'calendar_today', '/agendamento');
      auxArr01.splice(0, 0, itemAgend);
    } else {
      let itemAgend = new MenuItem('Agendamentos', 'Agendamento', 'calendar_today', '', [
        new MenuItem('Agendamentos', 'Agendamento', '', '/agendamento'),
        new MenuItem('WhatsApp Chat / Bot', 'WhatsAppChat', '', '/whatsapp-chat'),
        new MenuItem('WhatsApp Dashboard', 'WhatsAppDashboard', '', '/whatsapp-dashboard'),
      ]);

      auxArr01.splice(0, 0, itemAgend);
    }

    const auxArr02: MenuItem[] = [
      new MenuItem('Relatórios', '', 'receipt', '', [
        new MenuItem('Repasse Médico', 'RelRepasseMed', '', '/rel-repasse-med'),
        new MenuItem('Repasse Médico Simplificado', 'RelRepasseMedSimplificado', '', '/rel-repasse-med-simplificado'),
        new MenuItem('Recebimento Convênio', 'RelRecConvenio', '', '/rel-rec-convenio'),
        new MenuItem('Demonstrativo Período', 'RelDemonstrativoPer', '', '/rel-demonstrativo-per'),
        new MenuItem('Pacientes por Período', 'RelPacientePeriodo', '', '/rel-paciente-periodo'),
        

        new MenuItem('Tabelas', '', '', '', [
          new MenuItem('Tabelas de Preço', 'RelTabelasPreco', '', '/rel-tabelas-preco'),
        ]),
      ]),

      new MenuItem('Faturamento', '', 'calculate', '/faturamento'),

      new MenuItem('Pesquisa Satisfação', 'Questionario', 'ballot', '/questionario'),

      new MenuItem('Configurações', '', 'settings', '', [
        new MenuItem('Dados Empresa', 'Empresa', '', '/empresa'),
        new MenuItem('Formulário SUS', 'DocSusConfig', '', '/doc-sus-config'),
        new MenuItem('Configurações', 'ConfigTenant', '', '/configs'),

        new MenuItem('Layouts de Laudo', 'LayoutLaudo', '', '/layout-laudo'),
        new MenuItem('Layouts de Impressão', 'LayoutImpressao', '', '/layout-impressao'),
        new MenuItem('Layouts de Mensagem', 'LayoutMensagem', '', '/layout-mensagem'),
        new MenuItem('Termos', 'Termo', '', '/termo'),
        new MenuItem('Controle de Acesso', '', '', '', [
          new MenuItem('Usuários', 'Usuario', '', '/usuarios'),
          new MenuItem('Grupo de Usuario', 'GrupoUsuario', '', '/grupoUsuarios'),
        ]),
      ]),
      new MenuItem('Galeria', '', 'collections', '', [
        new MenuItem('Imagens', 'Imagem', '', '/imagens'),
        new MenuItem('Arquivos', 'Arquivo', '', '/arquivos'),
        new MenuItem('Icone', 'Icone', '', '/icone'),
        new MenuItem('Categoria Icones', 'CategoriaIcone', '', '/categoria-icone'),
      ]),
    ];

    if (this.accountService.hasModulo(this.typesService.MODULO_ATENDIMENTOONLINE)) {
      let index = auxArr02.findIndex(x => x.name === 'Configurações');

      const itemArr = new MenuItem('ChatBot', '', '', '', [
        new MenuItem('ChatBot Config.', 'ChatBotConfig', '', '/chatbot-config'),
      ]);

      auxArr02[index].items.splice(7, 0, itemArr);
    }

    this.menuItems = auxArr01.concat(auxArr02);
  }

  prepararMenuAdm() {
    //Verificar como montar o menu AdmKleinCode
    this.menuItems = [
      new MenuItem('Cadastros', '', 'add_circle_outline', '', [
        new MenuItem('Tenants', 'Tenant', '', '/tenants'),
        new MenuItem('Regiões', '', '', '', [
          new MenuItem('Estados', 'Estado', '', '/estados'),
          new MenuItem('Cidades', 'Cidade', '', '/cidades'),
        ]),
        new MenuItem('Geral', '', '', '', [
          new MenuItem('Configurações', 'Config', '', '/configs'),
        ]),
      ]),
      new MenuItem('Galeria', '', 'collections', '', [
        new MenuItem('Imagens', 'Imagem', '', '/imagens'),
        new MenuItem('Arquivos', 'Arquivo', '', '/arquivos'),
        new MenuItem('Icone', 'Icone', '', '/icone'),
        new MenuItem('Categoria Icones', 'CategoriaIcone', '', '/categoria-icone'),
      ]),
      new MenuItem('Controle de Acesso', '', 'fireplace', '', [
        new MenuItem('Usuários', 'Usuario', '', '/usuarios'),
        new MenuItem('Ações', 'Acao', '', '/acoes'),
        new MenuItem('Paginas', 'Pagina', '', '/paginas'),
        new MenuItem('Grupo de Usuario', 'GrupoUsuario', '', '/grupoUsuarios'),
      ]),
    ];
  }

  isDisabled(menuItem: MenuItem): boolean {
    //return menuItem.permissionName && !this.isAllowed('Read', menuItem.permissionName);
    return false;
  }

  onClick() {
    $('body').removeClass('overlay-open');
    $('.overlay').css('display', 'none');
  }
}
