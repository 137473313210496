import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';

import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class ArmazenamentoService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Armazenamento';
  }

  get(): Observable<any> {
    return this
      .httpClient
      .get<any>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
