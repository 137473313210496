import { Injectable, Injector } from '@angular/core';
import { EspecialidadeMedModel } from '../models';
import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EspecialidadeMedService extends BaseCrudService<EspecialidadeMedModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'especialidadeMed';
  }

  getAll(): Observable<EspecialidadeMedModel[]> {
    return this
      .httpClient
      .get<EspecialidadeMedModel>(`${this.getApiUrl()}${this.getController()}`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  updateOrdens(especialidadeMedIds) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, especialidadeMedIds, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
