<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-md">
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Solicitação de Exame</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">            
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Procedimento</mat-label>
                <input matInput [value]="model.procedimentoCodigo" disabled type="number"/>
              </mat-form-field>
            </div>            
            <div class="col-md-9">
              <mat-form-field>
                <mat-label>Nome Procedimento</mat-label>
                <input matInput [value]="model.procedimentoNome" disabled type="text" />                
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Detalhes do Exame Solicitado</mat-label>
                <textarea matInput formControlName="detalhes" rows="6"></textarea>
                <mat-error>Informe um Detalhes válido.</mat-error>
              </mat-form-field>
            </div>            
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Resultado do Exame Solicitado</mat-label>
                <textarea matInput formControlName="resultado" rows="2"></textarea>
                <mat-error>Informe um Resultado válido.</mat-error>
              </mat-form-field>
            </div>            
          </div>
        </div>
      </form>
      <div class="footer" *ngIf="!detailMode">
        <button mat-flat-button [disabled]="!form.valid" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
      <div class="footer" *ngIf="detailMode">        
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>