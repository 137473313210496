import { Component, OnInit, ViewChild, Injector } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { CidadeModalComponent } from './cidade-modal/cidade-modal.component';
import { CidadeModel } from 'projects/ProjetoBaseAngular/domain/models';
import { CidadeService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-cidades',
  templateUrl: './cidades.component.html'
})
export class CidadesComponent extends BaseCrudComponent<CidadeModel> implements OnInit {
  @ViewChild('cidadeModal') modal: CidadeModalComponent;

  selectColumns = [
    { data: 'estado.nome' },
    { data: 'estado.uf'},
    { data: 'codigoIbge' },
    { data: 'nome' }
  ];

  constructor(
    injector: Injector,
    readonly cidadeService: CidadeService
  ) {
    super(injector, cidadeService);
  }

  ngOnInit() {
    super.ngOnInit();    
  }
}
