import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { GrupoQuestaoOpcaoModel } from '../domain/models';
import { GrupoQuestaoOpcaoService } from '../domain/services';
import { GrupoQuestaoOpcaoModalComponent } from './grupo-questao-opcao-modal/grupo-questao-opcao-modal.component';

@Component({
  selector: 'app-grupo-questao-opcao',
  templateUrl: './grupo-questao-opcao.component.html'
})

export class GrupoQuestaoOpcaoComponent extends BaseCrudComponent<GrupoQuestaoOpcaoModel> implements OnInit {
  @ViewChild('modal') modal: GrupoQuestaoOpcaoModalComponent;

  @Input('grupoQuestaoId') grupoQuestaoId: string;
  @Input('usaPontuacao') usaPontuacao: boolean;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();  

  constructor(
    injector: Injector,
    readonly service: GrupoQuestaoOpcaoService
  ) {
    super(injector, service);
    this.useDatatables = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.service.getByGrupoQuestaoId(this.grupoQuestaoId).subscribe(x => this.models = x);
  }

  create(model: GrupoQuestaoOpcaoModel = null) {
    super.create(model);
    this.autoSave.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.models, event.previousIndex, event.currentIndex);
    const ids = this.models.map(x => x.id);
    this.service.updateOrdens(ids).subscribe();
  }
}

