<div bsModal #parceiroModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="parceiroModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2> Parceiro </h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="form-group align-center">
                <div style="height: 150px; width: 100%; object-fit: contain;">
                  <img src="{{form.value.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;" *ngIf="form.value.imagemUrl">
                </div>
                <div class="align-center">
                  <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                    <mat-icon>add_photo_alternate</mat-icon>
                  </button>
                  <button mat-icon-button type="button" [disabled]="!form.value.imagemUrl" (click)="deselectImage()"
                    matTooltip="Desvincular Imagem">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
                <p class="font-15 col-deep-blue text-center">
                  O tamanho ideal para Imagens é <strong>800x600</strong>
                </p>
              </div>
            </div>

            <div class="col-md-10">
              <h4 class="card-inside-title">Dados Principais</h4>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Nome</mat-label>
                    <input matInput #nome formControlName="nome" type="text" maxlength="100"/>
                    <mat-hint align="end">{{nome.value.length}} / 100</mat-hint>
                    <mat-error>Informe um Nome</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Tipo Parceiro</mat-label>
                    <mat-select formControlName="tipoIds" multiple>
                      <mat-option *ngFor="let tipo of tiposParceiros" [value]="tipo.id">
                        {{tipo.nome}}</mat-option>
                    </mat-select>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">

                <div class="col-md-5">
                  <mat-form-field>
                    <mat-label>Telefone</mat-label>
                    <input matInput formControlName="telefone" type="tel" placeholder="Ex: (48) 3333-3333" />
                    <mat-hint align="end">Máx 15 caracteres</mat-hint>
                    <mat-error></mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-7">
                  <mat-form-field>
                    <mat-label>Url Site</mat-label>
                    <input matInput formControlName="urlSite" type="text" />
                    <mat-hint align="end">Máx 200 caracteres</mat-hint>
                    <mat-error>Informe uma url</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <h4 class="card-inside-title">Dados de endereço</h4>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>CEP</mat-label>
                <input matInput formControlName="cep" type="text" [dropSpecialCharacters]="false" mask="00.000-000"
                  placeholder="Ex: 00.000-000" (blur)="changeCep($event.target.value)" />
                  <mat-hint align="end">Máx 10 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Rua / Avenida</mat-label>
                <input matInput formControlName="ruaAvenida" type="text" />
                <mat-hint align="end">Máx 50 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Número</mat-label>
                <input matInput formControlName="numero" type="text" />
                <mat-hint align="end">Máx 10 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Complemento</mat-label>
                <input matInput formControlName="complemento" type="text" />
                <mat-hint align="end">Máx 50 caracteres</mat-hint>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Bairro</mat-label>
                <input matInput formControlName="bairro" type="text" />
                <mat-hint align="end">Máx 50 caracteres</mat-hint>

                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Estado</mat-label>
                <mat-select formControlName="estadoId" (selectionChange)="changeEstado($event.value)">
                  <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.uf}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label> Cidade </mat-label>
                <mat-select formControlName="cidadeId">
                  <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
