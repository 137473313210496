<div bsModal #areaAtuacaoMedModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="areaAtuacaoMedModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Área de Atuação Médica</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label> Especialidade Médica </mat-label>
                <mat-select formControlName="especialidadeMedId" #focus>
                  <mat-option *ngFor="let especialidadeMed of especialidadesMed" [value]="especialidadeMed.id">{{especialidadeMed.nome}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione uma Especialidade Médica.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" />
                <mat-hint align="end">Máx 60 caracteres</mat-hint>
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field>
                <mat-label>URL Saiba Mais</mat-label>
                <input matInput formControlName="saibaMaisUrl" type="text"/>
                <mat-hint align="end">Máx 200 caracteres</mat-hint>
                <mat-error>Informe uma URL válida.</mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
