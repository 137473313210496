import { NgModule } from "@angular/core";
import { GaleriaModule } from "projects/ProjetoBaseAngular/app/admin/galeria/galeria.module";
import { SharedModule } from "projects/ProjetoBaseAngular/app/shared.module";
import { DocSusConfigModalComponent } from "./doc-sus-config-modal/doc-sus-config-modal.component";
import { DocSusConfigComponent } from "./doc-sus-config.component";

@NgModule({
    imports: [
      SharedModule,
      GaleriaModule
    ],
    declarations: [
      DocSusConfigModalComponent,
      DocSusConfigComponent
    ]
  })
  export class DocSusConfigModule { }
  