<div class="card">
  <div class="header">
    <h2>Filtro</h2>
  </div>
  <div class="body">
    <div class="row">      
      <div class="col-md-2 col-sm-6">
        <mat-form-field>
          <mat-label>Período de Inclusão</mat-label>
          <input matInput [(ngModel)]="searchDataInicial.searchTerm" type="date" max="2999-12-31">
        </mat-form-field>
      </div>
      <div class="col-md-2 col-sm-6">
        <mat-form-field>
          <input matInput [(ngModel)]="searchDataFinal.searchTerm" type="date" max="2999-12-31">
        </mat-form-field>
      </div>     
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="searchStatus.searchTerm" (selectionChange)="filter()">
            <mat-option [value]="false">Não Entregues</mat-option>
            <mat-option [value]="true">Entregues</mat-option>
            <mat-option [value]="null">Todos</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Cód./CPF Paciente</mat-label>
          <input id="cpfPaciente" #cpf matInput [(value)]="paciente.pessoa.cnpjCpf" type="text"
            (keyup.enter)="pacienteSelect.changeDocumento(cpf.value)"
            (blur)="pacienteSelect.changeDocumento($event.target.value)" />
          <button matSuffix mat-icon-button type="button" (click)="pacienteSelect.showSelect()"
            matTooltip="Selecionar Paciente">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Paciente</mat-label>
          <input matInput disabled
            [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia"
            type="text" />
          <button [disabled]="!paciente?.id" matSuffix mat-icon-button type="button"
            (click)="pacienteSelect.edit(paciente)" matTooltip="Editar dados do Paciente">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="footer">
    <button mat-flat-button color="primary" (click)="filter()">
      <span>Filtrar</span>
    </button>
  </div>
</div>        

<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Protocolos de Documento</h2>
      </div>
      <div class="body table-responsive">        
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
          style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center">Código</th>
              <th class="text-center">Título</th>              
              <th class="text-center">Paciente</th>
              <th class="text-center">Responsavel</th>
              <th class="text-center">Entregue</th>
              <th class="text-center">Data de Entrega</th>
              <th class="text-center">Entregue Para</th>              
              <th class="text-center" width="150"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let item of models">              
              <td [innerHTML]="item.codigo"></td>
              <td [innerHTML]="item.titulo"></td>              
              <td [innerHTML]="item.pacienteNome"></td>
              <td [innerHTML]="item.responsavel"></td>
              <td [innerHTML]="item.entregue ? 'Sim' : 'Não'"></td>
              <td>{{item.dataEntrega | date: 'dd/MM/yyyy'}}</td>
              <td [innerHTML]="item.entreguePara"></td>              
              <td class="text-center">
                <button mat-icon-button type="button" (click)="protocoloDocPrint.print(item.id)" matTooltip="Imprimir">
                  <mat-icon>print</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)" matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(item.id)" matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
      </table>
    </div>
    <div class="fixed-action-btn">
      <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)" matTooltipPosition="above">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<app-protocolo-doc-modal #modal (modalSave)="onResponse($event)"></app-protocolo-doc-modal>
<app-protocolo-doc-print #protocoloDocPrint></app-protocolo-doc-print>
<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>
