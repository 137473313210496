import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { GrupoDreComponent } from './grupo-dre.component';
import { GrupoDreModalComponent } from './grupo-dre-modal/grupo-dre-modal.component';


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    GrupoDreComponent,
    GrupoDreModalComponent
  ]
})
export class GrupoDreModule { }
