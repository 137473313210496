import { BaseModel } from "../base/base-model";
import { DocMovtoEstoqueType, DocStatusType } from "../../types";

export class DocMovtoModel extends BaseModel {
    public dataEmissao?: Date;
    public dataAplicacao?: Date;
    public tipoDocMovto: string;        
    public tipoMovtoEstoque: DocMovtoEstoqueType;
    public statusDocumento: DocStatusType;    
}
