import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { ProcedimentoConvenioModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProcedimentoConvenioService extends BaseCrudService<ProcedimentoConvenioModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'ProcedimentoConvenio';
  }

  getByProcedimentoId(procedimentoId: string): Observable<ProcedimentoConvenioModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByProcedimentoId/${procedimentoId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByProcedimentoIdAndConvenioId(procedimentoId: string, convenioId: string): Observable<ProcedimentoConvenioModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByProcedimentoIdAndConvenioId/${procedimentoId}/${convenioId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
