<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="nome">Nome</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-5">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um Cnpj/Cpf válido.</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Tipo Parceiro</mat-label>
              <mat-select [(ngModel)]="searchTipos.searchTerm">
                <mat-option value="">Todos os Tipos</mat-option>
                <mat-option *ngFor="let tipo of tiposParceiros" [value]="tipo.id">{{tipo.nome}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select [(ngModel)]="searchEstado.searchTerm" (selectionChange)="changeEstado($event.value)">
                <mat-option value="">Todos Estados</mat-option>
                <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <mat-select [(ngModel)]="searchCidade.searchTerm">
                <mat-option value="">Todos Cidades</mat-option>
                <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <ul class="nav nav-tabs">
          <li class="active"><a class="nav-link active" id="nav-parceiro-tab" data-toggle="tab" href="#nav-parceiro"
              role="tab" aria-controls="nav-parceiro" aria-selected="true" (click)="filter()">Parceiros</a></li>
          <li><a class="nav-link" id="nav-ordenacao-tab" data-toggle="tab" href="#nav-ordenacao" role="tab"
              aria-controls="nav-ordenacao" aria-selected="false" (click)="atualizaOrdem()">Ordenação</a></li>
        </ul>
      </div>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade in active" id="nav-parceiro" role="tabpanel" aria-labelledby="nav-parceiro-tab">
          <div class="body table-responsive">
            <!--query end-->
            <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
              class="table table-bordered table-striped table-hover" style="width: 100%;">
              <thead>
                <tr>
                  <th class="text-center" width="50">Ordem</th>
                  <th class="text-center">Nome</th>
                  <th class="text-center">Tipos Parceiro</th>
                  <th class="text-center">Site</th>
                  <th class="text-center" width="250">Cidade/UF</th>
                  <th class="text-center" width="150"></th>
                </tr>
              </thead>
              <tbody *ngIf="models?.length != 0">
                <tr *ngFor="let parceiro of models">
                  <td>
                    {{parceiro.ordem}}
                  </td>
                  <td>
                    {{parceiro.nome}}
                  </td>
                  <td>
                    {{parceiro.tipoNomes.join(', ')}}
                  </td>
                  <td>
                    {{parceiro.urlSite}}
                  </td>
                  <td>
                    {{parceiro.cidadeUf}}
                  </td>
                  <td class="text-center">
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(parceiro)"
                      matTooltip="Editar">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button"
                      (click)="delete(parceiro.id)" matTooltip="Excluir">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()"
              matTooltip="Adicionar (F9)" matTooltipPosition="above">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-ordenacao" role="tabpanel" aria-labelledby="nav-ordenacao-tab">
          <div class="body">
            <div mat-dialog-content>
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let parceiro of parceiros" cdkDrag>
                  {{parceiro.nome}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<app-parceiro-modal #parceiroModal (modalSave)="onResponse($event)"></app-parceiro-modal>
