import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { QuestionarioModel } from '../models';


@Injectable({
  providedIn: 'root'
})
export class QuestionarioService extends BaseCrudService<QuestionarioModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'questionario';
  }

  getQuestionarioAtivo(): Observable<QuestionarioModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetQuestionarioAtivo`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  publicar(id: string) {
    return this
      .httpClient
      .put(`${this.getApiUrl()}${this.getController()}/Publicar/${id}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  encerrar(id: string) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/Encerrar/${id}`, null, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
