<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Filtro</h2>
      </div>
      <div class="body">
        <div class="row">

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="pessoa.nomeFantasia">Nome</mat-option>
                <mat-option value="pessoa.cnpjCpf">CNPJ/CPF</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um CNPJ/CPF válido.</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select [(ngModel)]="searchEstado.searchTerm" (selectionChange)="changeEstado($event.value)">
                <mat-option value="">Todos Estados</mat-option>
                <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <mat-select [(ngModel)]="searchCidade.searchTerm">
                <mat-option value="">Todos Cidades</mat-option>
                <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>Pacientes</h2>
      </div>
      <div class="body table-responsive">
        <!--query end-->
        <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
          class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th class="text-center" width="100">Código</th>
              <th class="text-center">Nome</th>
              <th class="text-center" width="150">CNPJ/CPF</th>
              <th class="text-center" width="100">Nascimento</th>
              <th class="text-center" width="150">Cidade/UF</th>
              <th class="text-center" width="250"></th>
            </tr>
          </thead>
          <tbody *ngIf="models?.length != 0">
            <tr *ngFor="let paciente of models">
              <td>
                {{paciente.pessoaCodigo}}
              </td>
              <td>
                {{paciente.pessoaNomeFantasia}}
              </td>
              <td>
                {{paciente.pessoaCnpjCpf}}
              </td>
              <td>
                {{paciente.pessoaDataNascimento | date: 'dd/MM/yyyy'}}
              </td>
              <td>
                {{paciente.pessoaCidadeUf}}
              </td>
              <td class="text-center">
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button"
                  (click)="arquivoListModal.show(paciente.id, paciente.pessoaNomeFantasia)"
                  matTooltip="Arquivos do Paciente">
                  <mat-icon>folder_shared</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Read')" mat-icon-button type="button"
                  (click)="termoLista.show(paciente.id)"
                  matTooltip="Impressão de Termos">
                  <mat-icon>receipt_long</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Read')" mat-icon-button type="button"
                  (click)="listaPacienteTermo.show(paciente.id)"
                  matTooltip="Gerenciar Termos">
                  <mat-icon>content_paste</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button"
                  (click)="docSusListModal.show(paciente.id, paciente.pessoaNomeFantasia)"
                  matTooltip="Documentos do SUS">
                  <mat-icon>description</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(paciente)"
                  matTooltip="Editar">
                  <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="!isAllowed('DadosProntuario')" mat-icon-button type="button" 
                  (click)="editProntuario(paciente)" matTooltip="Editar com dados de prontuário">
                  <mat-icon>edit_note</mat-icon>
                </button>
                <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(paciente.id)"
                  matTooltip="Excluir">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fixed-action-btn">
        <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()" matTooltip="Adicionar (F9)"
          matTooltipPosition="above">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<app-paciente-modal #pacienteModal (modalSave)="onResponse($event)"></app-paciente-modal>
<app-doc-sus #docSusListModal></app-doc-sus>
<app-paciente-arquivo #arquivoListModal></app-paciente-arquivo>
<app-termo-list-modal #termoLista></app-termo-list-modal>
<app-paciente-termo-modal #listaPacienteTermo></app-paciente-termo-modal>