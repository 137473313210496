<div bsModal #artigoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="artigoModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2> Artigo </h2>
        </div>

        <div class="modal-body">
          <ul class="nav nav-tabs">
            <li class="active"><a class="nav-link active" id="nav-artigo-tab" data-toggle="tab" href="#nav-artigo"
                role="tab" aria-controls="nav-artigo" aria-selected="true">Artigo</a></li>
            <li><a class="nav-link" id="nav-imagens-tab" data-toggle="tab" href="#nav-imagens" role="tab"
                aria-controls="nav-imagens" aria-selected="false">Imagens do Artigo</a></li>
          </ul>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade in active" id="nav-artigo" role="tabpanel" aria-labelledby="nav-artigo-tab">
              <div class="alert alert-danger" *ngIf="errors.length > 0">
                <h3>Oops! Algo deu errado:</h3>
                <ul>
                  <li *ngFor="let error of errors">{{ error }}</li>
                </ul>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group align-center">
                    <div style="height: 150px; width: 100%; object-fit: contain;">
                      <img src="{{imagemCapa.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                        *ngIf="imagemCapa">
                    </div>
                    <div class="align-center">
                      <button mat-icon-button type="button" (click)="showSelectImage(imagemCapa?.id)"
                        matTooltip="Selecionar Imagem">
                        <mat-icon>add_photo_alternate</mat-icon>
                      </button>
                      <button mat-icon-button type="button" [disabled]="!imagemCapa"
                        (click)="deselectImage(imagemCapa.id)" matTooltip="Desvincular Imagem">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </div>
                  <p class="font-15 col-deep-blue text-center">
                    O tamanho ideal para Imagens é <strong>800x600</strong>
                  </p>
                </div>

                <div class="col-md-10">
                  <h4 class="card-inside-title">Dados Principais</h4>
                  <div class="row">

                    <div class="col-md-2">
                      <mat-form-field>
                        <mat-label>Data Artigo</mat-label>
                        <input matInput formControlName="dataArtigo" type="date" #focus />
                        <mat-error>Informe uma Data válida.</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-10">
                      <mat-form-field>
                        <mat-label>Titulo</mat-label>
                        <input matInput formControlName="titulo" #titulo type="text" max="100" (blur)="slugify($event.target.value)" />
                        <mat-hint align="end">{{titulo.length}}/100</mat-hint>
                        <mat-error>Informe um Titulo válido.</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field>
                        <mat-label>Url Amigável</mat-label>
                        <input matInput formControlName="slug" #slug type="text" max="50"/>
                        <mat-hint align="end">{{slug.length}}/50</mat-hint>
                        <mat-error>Informe uma Url válido.</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-4">
                      <mat-form-field>
                        <mat-label>Autor</mat-label>
                        <input matInput formControlName="autor" #autor type="text" max="50" />
                        <mat-hint align="end">{{autor.length}}/50</mat-hint>
                        <mat-error>Informe um Autor válido.</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field>
                        <mat-label>Locais Publicação</mat-label>
                        <mat-select formControlName="locaisSelecionados" multiple>
                          <mat-option *ngFor="let localPublicacao of locaisDisponiveis" [value]="localPublicacao.value">
                            {{localPublicacao.text}}</mat-option>
                        </mat-select>
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field>
                        <mat-label>Resumo</mat-label>
                        <input matInput formControlName="resumo" #resumo type="text" max="200"/>
                        <mat-hint align="end">{{resumo.length}}/200</mat-hint>
                        <mat-error>Informe um Resumo válido.</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <tag-input formControlName="tagNomes" placeholder="Adicione uma Tag"
                    secondaryPlaceholder="Adicione uma Tag" [separatorKeyCodes]="[188]" [identifyBy]="'value'"
                    [displayBy]="'value'">
                    <tag-input-dropdown [autocompleteItems]="allTags"></tag-input-dropdown>
                  </tag-input>
                </div>

                <mat-slide-toggle formControlName="publicado" color="primary">
                  Artigo Publicado
                </mat-slide-toggle>
              </div>

              <h4 class="card-inside-title">HTML</h4>
              <div class="row">
                <div class="col-md-12">
                  <editor formControlName="html" [init]="tinymceConfig(700)">
                  </editor>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-imagens" role="tabpanel" aria-labelledby="nav-imagens-tab">
              <div class="row">

                <h4 class="card-inside-title">Imagens</h4>

                <div *ngFor="let imagem of form.value.imagens; let i = index;">
                  <div class="col-md-2">
                    <div class="form-group align-center">

                      <div style="height: 150px; width: 100%; object-fit: contain;">
                        <img src="{{imagem.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                          *ngIf="imagem.imagemUrl">
                      </div>
                      <div class="align-center">
                        <button mat-icon-button type="button" *ngIf="i > 0" (click)="moveImagemEsquerda(imagem.id, i)"
                          matTooltip="Move Imagem Para Esquerda">
                          <mat-icon>skip_previous</mat-icon>
                        </button>
                        <button mat-icon-button type="button" (click)="showSelectImage(imagem.id)"
                          matTooltip="Selecionar Imagem">
                          <mat-icon>add_photo_alternate</mat-icon>
                        </button>
                        <button mat-icon-button type="button" [disabled]="!imagem.imagemUrl"
                          (click)="deselectImage(imagem.id)" matTooltip="Desvincular Imagem">
                          <mat-icon>cancel</mat-icon>
                        </button>
                        <button mat-icon-button type="button" *ngIf="imagens.length !== i + 1"
                          (click)="moveImagemDireita(imagem.id, i)" matTooltip="Move Imagem Para Direita">
                          <mat-icon>skip_next</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group align-center">
                    <button mat-icon-button type="button" (click)="showSelectImage(null)"
                      matTooltip="Selecionar Imagem">
                      <mat-icon>add_photo_alternate</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <p class="font-15 col-deep-blue text-center">
                O tamanho ideal para Imagens é <strong>800x600</strong>
              </p>

              <div class="row">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Legenda das Imagens</mat-label>
                    <input matInput formControlName="legendaImagens" type="text" />
                    <mat-hint align="end">Máx 200 caracteres</mat-hint>
                    <mat-error>Informe uma Legenda válido.</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
