import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { DocSusModalComponent } from './doc-sus-modal/doc-sus-modal.component';
import { DocSusModel } from '../domain/models';
import { DocSusService } from '../domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { TypesService } from 'projects/admin/src/domain/services';
import { DocSusType } from '../domain/types';

@Component({
  selector: 'app-doc-sus',
  templateUrl: './doc-sus.component.html'
})
export class DocSusComponent extends BaseCrudComponent<DocSusModel> implements OnInit {
  @ViewChild('modal') modal: DocSusModalComponent;
  @ViewChild('listModal') listModal: ModalDirective;
    
  pacienteId: string;
  pacienteNome: string;

  docSusType: typeof DocSusType = DocSusType;

  selectColumns = [
    { data: 'dataSolicitacao' },
    { data: 'tipo' },
    { data: 'paciente.pessoa.nomeFantasia as pacienteNome' },
    { data: 'profissionalSolicitante.pessoa.nomeFantasia as solicitanteNome' },
    { data: 'profissionalAutorizador.pessoa.nomeFantasia as autorizadorNome' }
  ];

  constructor(
    injector: Injector,
    readonly service: DocSusService,
    readonly typesService: TypesService
  ) {
    super(injector, service);    
  }  

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.order = [[0, 'asc'], [1, 'asc']];
    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    if (this.pacienteId) {
      dataTablesParameters.searches.push(new ModelSearch('pacienteId', OptionSearchType.Equals, SearchType.Guid, this.pacienteId));
    }
    return super.beforeDtRequest(dataTablesParameters);
  }

  show(pacienteId, pacienteNome) {
    this.pacienteId = pacienteId;
    this.pacienteNome = pacienteNome;
    this.listModal.show();
    this.filter();
  }

  close() {
    this.listModal.hide();    
  }

  create() {
    const model = new DocSusModel();    
    model.pacienteId = this.pacienteId;
    super.create(model);
  }

  edit(model: DocSusModel) {    
    this.modal.showEdit(model.id);
  }
}
