import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ChatBotRespAlternativaModel } from '../../../domain/models';
import { ChatBotRespAlternativaService } from '../../../domain/services/chatbot-opcao-alternativa.service';

@Component({
  selector: 'app-chatbot-resp-alternativa-modal',
  templateUrl: './chatbot-resp-alternativa-modal.component.html'
})
export class ChatBotRespAlternativaModalComponent extends BaseCrudModalComponent<ChatBotRespAlternativaModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Input('chatBotNodoId') chatBotNodoId: string;

  constructor(
    injector: Injector,
    readonly service: ChatBotRespAlternativaService,
    readonly typesService: TypesService
  ) {
    super(injector,service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: ChatBotRespAlternativaModel) {
    this.form = this.formBuilder.group({      
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      id: [model.id],
      chatBotNodoId: [model.chatBotNodoId],
      respostaAlternativa: [model.respostaAlternativa, [Validators.required, Validators.maxLength(30)]],
      valor: [model.valor, [Validators.required, Validators.maxLength(30)]]
    });
  }

  protected newModel(): ChatBotRespAlternativaModel {
    let model = new ChatBotRespAlternativaModel();
    model.id = this.commonService.newGuid();
    model.dataInclusao = new Date();    
    model.chatBotNodoId = this.chatBotNodoId;
    model.respostaAlternativa = "";
    model.valor = "";

    return model;
  }

  autoSave() {
    this.save(true);
  }

  async save(autoSave: boolean = false) {
    const model: ChatBotRespAlternativaModel = Object.assign({}, this.form.value);
    model.respostaAlternativa = model.respostaAlternativa.trim();
    model.valor = model.valor.trim();

    this.form.patchValue({
      respostaAlternativa: model.respostaAlternativa,
      valor: model.valor
    });

    super.save(autoSave);
  }
}