import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ClassificacaoAgendamentoService } from '../../domain/services';
import { ClassificacaoAgendamentoModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-classificacao-agendamento-modal',
  templateUrl: './classificacao-agendamento-modal.component.html'
})
export class ClassificacaoAgendamentoModalComponent extends BaseCrudModalComponent<ClassificacaoAgendamentoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: ClassificacaoAgendamentoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: ClassificacaoAgendamentoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: this.fromJsonDate(model.dataAlteracao),
      registroAtivo: [model.registroAtivo],

      nome: [model.nome, [
        Validators.required,
        Validators.maxLength(50)
      ]],
      retorno: [model.retorno, Validators.required]
    });
  }

  protected newModel(): ClassificacaoAgendamentoModel {
    const model = super.newModel();
    model.id = this.commonService.newGuid();
    model.retorno = false;

    return model;
  }

}
