<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header" [ngClass]="'bg-' + typesService.getAtendimentoStatusColorType(form.value.status)">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>
            <span *ngIf="form.value.codigoDocMovto">
              {{'#' + form.value?.codigoDocMovto + ' - '}}
            </span>
            Atendimento de Paciente ({{typesService.getAtendimentoStatusType(form.value.status)}})
            <span *ngIf="!form.value.agendamentoId"> | Não Agendado </span>
            <span class="badge bg-red" *ngIf="readOnly && form.value.status === typesService.AtendimentoStatusType.Atendido 
              || form.value.status === typesService.AtendimentoStatusType.Cancelado">
              Somente Leitura
            </span>
            <span class="badge bg-red"
              *ngIf="!readOnly && form.value.status === typesService.AtendimentoStatusType.Atendido">
              Edição
            </span>
          </h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data/Hora Entrada</mat-label>
                <input matInput disabled [value]="form.value.dataHoraEntrada | date: 'dd/MM/yyyy HH:mm:ss'" />
                <mat-error>Informe um Data válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Hora Inicial Atendimento</mat-label>
                <input matInput disabled [value]="form.value.dataHoraInicial | date: 'HH:mm'" />
                <mat-error>Informe um Data válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Hora Final Atendimento</mat-label>
                <input matInput disabled [value]="horaFinal" />
                <mat-error>Informe um Horario válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Duração</mat-label>
                <input matInput disabled [value]="duracaoAtendimento" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Profissional da Saúde</mat-label>
                <input matInput disabled [value]="profissionalAtendimento?.nomeApresentacao" type="text" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Cód./CPF Paciente</mat-label>
                <input matInput disabled [value]="paciente?.pessoaCnpjCpf" type="text" maxlength="18" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Paciente</mat-label>
                <input matInput disabled
                  [value]="(paciente?.pessoaCodigo ? paciente?.pessoaCodigo + ' - ' : '') + paciente?.pessoaNomeFantasia"
                  type="text" />
                <!-- <button [disabled]="isBusy || !paciente?.id" matSuffix mat-icon-button type="button"
                  (click)="showEditPacienteProntuario(paciente.id)" matTooltip="Editar dados do Paciente">
                  <mat-icon>mode_edit</mat-icon>
                </button> -->
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Sexo</mat-label>
                <mat-select disabled [value]="paciente?.pessoaSexo">
                  <mat-option *ngFor="let sexo of typesService.sexoTypes" [value]="sexo.value">{{sexo.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data de Nascimento</mat-label>
                <input matInput disabled [value]="paciente?.pessoaDataNascimento | date: 'dd/MM/yyyy'" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Idade</mat-label>
                <input matInput disabled [value]="paciente?.idade" />
                <mat-error>Informe uma Data de Nascimento válida.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Estado Civil</mat-label>
                <mat-select disabled [value]="paciente?.estadoCivil">
                  <mat-option *ngFor="let estadoCivil of typesService.estadoCivilType" [value]="estadoCivil.value">
                    {{estadoCivil.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Convênio</mat-label>
                <input matInput disabled [value]="convenio?.nome" type="text" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput disabled [value]="paciente?.pessoaEmail" type="email" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Profissão</mat-label>
                <input matInput disabled [value]="paciente?.profissao" type="text" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Cidade</mat-label>
                <input matInput disabled [value]="paciente?.pessoaCidadeUf" type="text" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div [ngClass]="{'col-md-10': !readOnly, 'col-md-12': readOnly}">
              <mat-tab-group [selectedIndex]="currentTabIndex" *ngIf="this.currentTabIndex >= 0">
                <mat-tab label="Anamnese">
                  <ng-template matTabContent>
                    <editor [disabled]="detailMode" formControlName="anamnese" [init]="tinymceConfig(250)">
                    </editor>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Diagnóstico">
                  <ng-template matTabContent>
                    <editor [disabled]="detailMode" formControlName="diagnostico" [init]="tinymceConfig(250)">
                    </editor>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Receita Médica">
                  <ng-template matTabContent>
                    <app-atendimento-receita [atendimentoId]="form.value.id"
                      [profissionalId]="form.value.profissionalRecursoId" [pacienteId]="form.value.pacienteId"
                      [detailMode]="detailMode" (autoSave)="save(true)">
                    </app-atendimento-receita>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Solic. Exames">
                  <ng-template matTabContent>
                    <app-atendimento-solic-exame [atendimentoId]="form.value.id"
                      [profissionalId]="form.value.profissionalRecursoId" [pacienteId]="form.value.pacienteId"
                      [detailMode]="detailMode" (autoSave)="save(true)">
                    </app-atendimento-solic-exame>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Atestados">
                  <ng-template matTabContent>
                    <div class="container-fluid">
                      <br />
                      <div class="row">
                        <div class="col-md-6">
                          <mat-form-field appearance="outline">
                            <mat-label>Atestado Médico</mat-label>
                            <textarea matInput formControlName="atestado" rows="9"></textarea>
                          </mat-form-field>
                          <button mat-flat-button type="button" color="primary"
                            (click)="save(true);atestadoPrint.print(form.value.atestado, form.value.id)"
                            style="margin-top: 0px;">
                            <mat-icon>print</mat-icon> Imprimir Atestado
                          </button>
                          <button mat-flat-button type="button" color="primary"
                            (click)="showAtestadoHistorico()"
                            style="margin-top: 0px; margin-left: 5px;">
                            <mat-icon>library_add_check</mat-icon> Histórico de Atestados
                          </button>
                        </div>
                        <div class="col-md-6 table-responsive">
                          <table datatable [dtOptions]="dtOptionsAtestado" *ngIf="dtOptionsAtestado"
                            class="table table-bordered table-striped table-hover" style="width: 100%;">
                            <thead>
                              <tr>
                                <th colspan="3" class="text-center">Atestados</th>
                              </tr>
                              <tr>
                                <th class="text-center" width="100">Nome</th>
                                <th class="text-center">CID(s)</th>
                              </tr>
                            </thead>
                            <tbody *ngIf="atestados?.length != 0">
                              <tr *ngFor="let item of atestados" [matTooltip]="item.texto" style="cursor: pointer;"
                                (click)="addAtestado(item)">
                                <td>{{item.nome}}</td>
                                <td>{{item.cids}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-tab>
                <!-- <mat-tab label="Exames Físicos">
                  <ng-template matTabContent>
                    <editor [disabled]="detailMode" formControlName="examesFisicos" [init]="tinymceConfig(250)">
                    </editor>
                  </ng-template>
                </mat-tab> -->
                <!-- <mat-tab label="Doc. SUS">
                  <ng-template matTabContent>
                  </ng-template>
                </mat-tab> -->
              </mat-tab-group>
            </div>
            <div class="col-md-2" *ngIf="!readOnly">
              <div class="table-responsive">
                <table datatable [dtOptions]="dtOptionsAtendimento" *ngIf="dtOptionsAtendimento"
                  class="table table-bordered table-striped table-hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th class="text-center">Atendimentos</th>
                    </tr>
                    <tr>
                      <th class="text-center">Data/Hora</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="atendimentosPaciente?.length != 0">
                    <tr *ngFor="let item of atendimentosPaciente" matTooltip="Ver Detalhes" style="cursor: pointer;"
                      (click)="atendimentoModal.emit(item.id)">
                      <td>
                        {{item.dataHoraEntrada | date:'dd/MM/yyyy HH:mm'}}
                        <span *ngIf="!item.agendamentoId">N.Agend.</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <div class="row">
          <div class="col-sm-7 text-left">
            <button mat-flat-button [disabled]="isBusy" type="button" class="button-row" color="primary"
              (click)="showHistorico()" matTooltip="Histórico de Atendimentos">
              <mat-icon>history</mat-icon>
            </button>
            <button mat-flat-button type="button" class="button-row" color="primary"
              (click)="laudoListModal.show(paciente.id, paciente.pessoaNomeFantasia)" matTooltip="Laudos">
              <mat-icon>text_snippet</mat-icon>
            </button>
            <button mat-flat-button type="button" class="button-row" color="primary"
              (click)="capturaListModal.show(paciente.id, paciente.pessoaNomeFantasia)" matTooltip="Capturas">
              <mat-icon>camera</mat-icon>
            </button>
            <button mat-flat-button type="button" class="button-row" color="primary"
              (click)="docSusListModal.show(paciente.id, paciente.pessoaNomeFantasia)" matTooltip="Documentos do SUS">
              <mat-icon>description</mat-icon>
            </button>
            <button mat-flat-button type="button" class="button-row" color="primary"
              (click)="pacienteArquivoListModal.show(paciente.id, paciente.pessoaNomeFantasia)"
              matTooltip="Arquivos do Paciente"><mat-icon>folder_copy</mat-icon>
            </button>
            <button mat-flat-button type="button" class="button-row" color="primary" 
              (click)="esperaModal.show()" matTooltip="Pacientes em Espera">
              <mat-icon>groups</mat-icon>
            </button>
            <button mat-flat-button type="button" class="button-row" color="primary" 
              (click)="prontuarioPrint.print(form.value.id)" matTooltip="Imprimir Prontuário">
              <mat-icon>print</mat-icon>
            </button>
            <button [disabled]="!readOnly && !detailMode" mat-flat-button type="button" class="button-row" color="primary" 
              (click)="showReceitaHistorico()" matTooltip="Histórico de Receitas">
              <mat-icon>receipt_long</mat-icon>
            </button>
            <button [disabled]="!readOnly && !detailMode" mat-flat-button type="button" class="button-row" color="primary" 
              (click)="showExameHistorico()" matTooltip="Histórico Solicitação de Exames">
              <mat-icon>receipt</mat-icon>
            </button>
            <button [disabled]="!readOnly && !detailMode" mat-flat-button type="button" class="button-row" color="primary" 
              (click)="showAtestadoHistorico()" matTooltip="Histórico Atestados">
              <mat-icon>list_alt</mat-icon>
            </button>
            <button mat-raised-button matBadge="*" [matBadgeHidden]="!avisoAnotacaoAtivo" matBadgeColor="warn"
              color="primary" (click)="showEditPacienteProntuario(paciente.id)" matTooltip="{{txtPacienteTooltip}}">
              <mat-icon>person</mat-icon>
            </button>
          </div>
          <div class="col-sm-5" *ngIf="!readOnly && !detailMode">
            <button *ngIf="form.value.status !== typesService.AtendimentoStatusType.Atendido"
              mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
              class="button-row" matTooltip="Salva e Finaliza o Atendimento">
              <span>Finalizar Atendimento</span>
            </button>
            <button *ngIf="form.value.status === typesService.AtendimentoStatusType.Atendido"
              mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()"
              color="primary" class="button-row" matTooltip="Salva as alterações do Atendimento">
              <span> Salvar</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row"
              matTooltip="Sair da tela de Atendimento, poderá escolher 'Salvar ou Não Salvar (abandonar alterações)'.">
              <span> Sair</span>
            </button>
          </div>
          <div class="col-sm-5" *ngIf="!readOnly && detailMode">
            <button mat-flat-button type="button" (click)="novoAtendimento()" color="primary" class="button-row"
              *ngIf="form.value.status === typesService.AtendimentoStatusType.Atendido"
              matTooltip="Criar um Atendimento avulso/não agendado (Ex: Complemento / Emissão de receitas/exames/atestados)">
              <span>Novo Atendimento (Não Agendado)</span>
            </button>
            <button mat-flat-button type="button" (click)="iniciarAtendimento()" color="primary" class="button-row"
              *ngIf="form.value.status === typesService.AtendimentoStatusType.NaoAtendido"
              matTooltip="Iniciar um Atendimento agendado">
              <span>Iniciar Atendimento</span>
            </button>
            <button mat-flat-button type="button" (click)="continuarAtendimento()" color="primary" class="button-row"
              *ngIf="form.value.status === typesService.AtendimentoStatusType.EmAtendimento"
              matTooltip="Dar continuidade a um Atendimento não finalizado">
              <span>Continuar Atendimento</span>
            </button>
            <button mat-flat-button type="button" (click)="editarAtendimento()" color="primary" class="button-row"
              *ngIf="form.value.status === typesService.AtendimentoStatusType.Atendido"
              matTooltip="Editar um Atendimento já finalizado (somente dentro do período de 24hs)">
              <span>Editar Atendimento</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span>Sair</span>
            </button>
          </div>
          <div class="col-xs-6" *ngIf="readOnly">
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span>Sair</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-paciente-modal #pacienteModal (modalSave)="loadPaciente()"></app-paciente-modal>
<app-atendimento-historico-modal #historicoModal></app-atendimento-historico-modal>
<app-doc-sus #docSusListModal></app-doc-sus>
<app-paciente-arquivo #pacienteArquivoListModal></app-paciente-arquivo>
<app-atendimento-espera-modal #esperaModal></app-atendimento-espera-modal>
<app-laudo-list-modal #laudoListModal></app-laudo-list-modal>
<app-captura-list-modal #capturaListModal></app-captura-list-modal>
<app-atendimento-prontuario-print #prontuarioPrint></app-atendimento-prontuario-print>
<app-atendimento-atestado-print #atestadoPrint></app-atendimento-atestado-print>
<app-atendimento-receita-historico-modal #modalReceitaHistorico 
[pacienteId]="paciente?.id">
</app-atendimento-receita-historico-modal>
<app-atendimento-solic-exame-historico-modal #modalExameHistorico 
[pacienteId]="paciente?.id">
</app-atendimento-solic-exame-historico-modal>
<app-atendimento-atestado-historico-modal #modalAtestadoHistorico 
(cloneAtestado)="onCloneAtestadoResponse($event)" [atendimentoId]="form?.value?.id"
[pacienteId]="paciente?.id" [profissionalId]="form?.value?.profissionalRecursoId" [detailMode]="detailMode">
</app-atendimento-atestado-historico-modal>