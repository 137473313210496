<div #relatorio class="visible-print">

  <table class="report-container" *ngIf="receita?.tipo === typesService.ReceitaType.Normal">
    <thead class="report-header">
      <div class="container">
        <tr>
          <th>
            <app-report-header></app-report-header>
          </th>
        </tr>
      </div>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <h3 class="text-center font-weight-bold mb-5">Receita Médica</h3>
          <div class="row mb-2">
            <div class="col-8">
              <h4>
                <strong>Paciente:</strong> {{paciente?.pessoa.nomeFantasia}}
              </h4>
            </div>
            <div class="col-4" *ngIf="mostrarData">
              <h4>
                <strong>Data:</strong> {{dataAtual}}
              </h4>
            </div>
          </div>
          <hr>
          <span style="white-space: pre-line">
            <h4>
              {{receita?.descricao}}
            </h4>
          </span>
        </td>
      </tr>
    </tbody>
    <tfoot class="report-footer">
      <tr>
        <td>
          <div class="container">
            <div class="row mt-5 pt-5">
              <div class="col-12 text-center">
                <span>_________________________________________________________________________</span>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12 text-center">
                <h4>
                  <strong>{{profissionalSaude?.pessoa.nomeFantasia + ' CRM ' + profissionalSaude?.crmMedico}}</strong>
                </h4>
              </div>
            </div>
            <app-report-footer></app-report-footer>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
  <div *ngIf="receita?.tipo === typesService.ReceitaType.Controlada">
    <div #Via1>
      <table class="report-container" *ngIf="receita?.tipo === typesService.ReceitaType.Controlada">
        <thead class="report-header">
          <div class="container">
            <tr>
              <th>
                <div class="container">
                  <app-report-header></app-report-header>
                </div>
            </tr>
          </div>
        </thead>
        <tbody class="report-content">
          <tr>
            <td>
              <h4 class="text-center font-weight-bold mb-5">Receita de Controle Especial</h4>
              <div class="row">
                <div class="col-8">
                  <h4>
                    <strong>Paciente:</strong> {{paciente?.pessoa.nomeFantasia}}
                  </h4>
                </div>
                <div class="col-4">
                  <h4>1º VIA: FARMÁCIA</h4>
                </div>
              </div>
              <hr>
              <div style="white-space: pre-line">
                <h4>
                  {{receita?.descricao}}
                </h4>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot class="report-footer">
          <tr>
            <td>
              <div class="container">
                <div class="row mt-5 pt-5">
                  <div class="col-12 text-center">
                    <span>_________________________________________________________________________</span>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-12 text-center">
                    <h4>
                      <strong>{{profissionalSaude?.pessoa.nomeFantasia + ' CRM ' +
                        profissionalSaude?.crmMedico}}</strong>
                    </h4>
                  </div>
                </div>
                <p *ngIf="mostrarData">Data: {{dataAtual}}</p>
                <div class="form-row">
                  <div class="col text-right">
                    <table border="1" cellpadding="5" cellspacing="5" style="width: 100%; height: 100%;">
                      <tbody>
                        <tr class="text-center">
                          <td>Identificação do Comprador</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Nome
                              </div>
                              <div class="col">
                              </div>
                            </div>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Ident. nº
                              </div>
                              <div class="col">
                              </div>
                              <div class="col-3">
                                Órgão Emissor
                              </div>
                              <div class="col">
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-1">
                                End.
                              </div>
                              <div class="col">
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Cidade
                              </div>
                              <div class="col-6">
                              </div>
                              <div class="col-1">
                                UF
                              </div>
                              <div class="col-3">
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Telefone
                              </div>
                              <div class="col">
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col">
                    <table border="1" cellpadding="5" cellspacing="5" style="width: 100%; height: 100%;">
                      <tbody>
                        <tr class="text-center">
                          <td>Identificação do Fornecedor</td>
                        </tr>
                        <tr class="text-center">
                          <td>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div class="form-row mt-2 mb-1">
                              <div class="col-8">
                                <hr class="p-0 m-0 mr-2 ml-2" style="border-top: 1px solid;">
                                <p class="p-0 m-0">Assinatura do Farmacêutico</p>
                              </div>
                              <div class="col-4">
                                <hr class="p-0 m-0 mr-2 ml-2" style="border-top: 1px solid;">
                                <p class="p-0 m-0">Data</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <app-report-footer></app-report-footer>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div #Via2 class="pagebreakbefore">
      <table class="report-container" *ngIf="receita?.tipo === typesService.ReceitaType.Controlada">
        <thead class="report-header">
          <div class="container">
            <tr>
              <th>
                <div class="container">
                  <app-report-header></app-report-header>
                </div>
              </th>
            </tr>
          </div>
        </thead>
        <tbody class="report-content">
          <tr>
            <td>
              <h4 class="text-center font-weight-bold mb-5">Receita de Controle Especial</h4>
              <div class="row">
                <div class="col-8">
                  <h4>
                    <strong>Paciente:</strong> {{paciente?.pessoa.nomeFantasia}}
                  </h4>
                </div>
                <div class="col-4">
                  <h4>2ª VIA: PACIENTE</h4>
                </div>
              </div>
              <hr>
              <div style="white-space: pre-line">
                <h4>
                  {{receita?.descricao}}
                </h4>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot class="report-footer">
          <tr>
            <td>
              <div class="container">
                <div class="row mt-5 pt-5">
                  <div class="col-12 text-center">
                    <span>_________________________________________________________________________</span>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-12 text-center">
                    <h4>
                      <strong>{{profissionalSaude?.pessoa.nomeFantasia + ' CRM ' +
                        profissionalSaude?.crmMedico}}</strong>
                    </h4>
                  </div>
                </div>
                <p *ngIf="mostrarData">Data: {{dataAtual}}</p>
                <div class="form-row">
                  <div class="col text-right">
                    <table border="1" cellpadding="5" cellspacing="5" style="width: 100%; height: 100%;">
                      <tbody>
                        <tr class="text-center">
                          <td>Identificação do Comprador</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Nome
                              </div>
                              <div class="col">
                              </div>
                            </div>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Ident. nº
                              </div>
                              <div class="col">
                              </div>
                              <div class="col-3">
                                Órgão Emissor
                              </div>
                              <div class="col">
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-1">
                                End.
                              </div>
                              <div class="col">
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Cidade
                              </div>
                              <div class="col-6">
                              </div>
                              <div class="col-1">
                                UF
                              </div>
                              <div class="col-3">
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-row">
                              <div class="col-2">
                                Telefone
                              </div>
                              <div class="col">
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col">
                    <table border="1" cellpadding="5" cellspacing="5" style="width: 100%; height: 100%;">
                      <tbody>
                        <tr class="text-center">
                          <td>Identificação do Fornecedor</td>
                        </tr>
                        <tr class="text-center">
                          <td>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div class="form-row mt-2 mb-1">
                              <div class="col-8">
                                <hr class="p-0 m-0 mr-2 ml-2" style="border-top: 1px solid;">
                                <p class="p-0 m-0">Assinatura do Farmacêutico</p>
                              </div>
                              <div class="col-4">
                                <hr class="p-0 m-0 mr-2 ml-2" style="border-top: 1px solid;">
                                <p class="p-0 m-0">Data</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <app-report-footer></app-report-footer>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>