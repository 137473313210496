import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { TypesService } from 'projects/admin/src/domain/services';
import { ChatBotNodoModel } from '../../domain/models';
import { BotAtendimentoType, BotFluxoType, BotNodoType, WhatsChatMsgCampoType } from '../../domain/types';
import { ChatBotNodoService } from '../../domain/services/chatbot-nodo.service';
import { MatAccordion } from '@angular/material/expansion';


@Component({
  selector: 'app-chatbot-nodo-modal',
  templateUrl: './chatbot-nodo-modal.component.html'
})
export class ChatBotNodoModalComponent extends BaseCrudModalComponent<ChatBotNodoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  botFluxoType: typeof BotFluxoType = BotFluxoType;
  botAtendimentoType: typeof BotAtendimentoType = BotAtendimentoType;
  botNodoType: typeof BotNodoType = BotNodoType;
  dtOptions: DataTables.Settings;

  constructor(
    injector: Injector,
    readonly service: ChatBotNodoService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: ChatBotNodoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: [this.fromJsonDate(model.dataAlteracao)],
      mascara: [model.mascara, [Validators.required, Validators.maxLength(8)]],
      nome: [model.nome, Validators.maxLength(100)],
      tipoFluxo: [model.tipoFluxo],
      tipoAtendimento: [model.tipoAtendimento],
      tipo: [model.tipo],
      tempoLimite: [model.tempoLimite],
      mensagem: [model.mensagem, Validators.maxLength(1000)],
      validarResposta: [model.validarResposta],
      respostaOpcoes: [model.respostaOpcoes, Validators.maxLength(500)],
      respostaValores: [model.respostaValores, Validators.maxLength(500)],
      proxNodo: [model.proxNodo, Validators.maxLength(500)],
      tipoCampo: [model.tipoCampo],      
    });
  }

  newModel(): ChatBotNodoModel {
    const model = new ChatBotNodoModel();
    // fazer pegar a ultima mascara criada e adicionar 1
    model.mascara = "01.00.00";
    model.nome = "";
    model.tipoAtendimento = BotAtendimentoType.NaoInformado
    model.tipoFluxo = BotFluxoType.IniciadoCliente;
    model.tipo = BotNodoType.InicioFluxo;
    model.tempoLimite = "1.12:00";
    model.mensagem = "";
    model.validarResposta = false;
    model.respostaOpcoes = "";
    model.respostaValores = "";
    model.proxNodo = "";
    model.tipoCampo = WhatsChatMsgCampoType.Ignorado;

    return model;
  }

  async onChangeValidar() {
    const model: ChatBotNodoModel = Object.assign({}, this.form.value);
    if (!model.validarResposta && (model.respostaOpcoes || model.respostaValores)){
      let result = await this.commonService.mensagemConfirmacao("Limpar os campos Opções e Valores de respostas?","Confirmar?","question");
      if (result) {
        this.form.patchValue({
          respostaOpcoes: "",
          respostaValores: ""
        });
      } else {
        this.form.patchValue({
          validarResposta: true
        });
      }
    }
  }

  autoSave() {
    this.save(true);
  }
}
