import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { StatusPublicacaoType } from 'projects/admin/src/domain/types';
import { EnqueteModel, EnqueteOpcaoModel } from '../../domain/models';
import { EnqueteService } from '../../domain/services';
import { EnqueteOpcaoModalComponent } from '../../enquete-opcao/enquete-opcao-modal/enquete-opcao-modal.component';
import { EnqueteOpcaoComponent } from '../../enquete-opcao/enquete-opcao.component';

@Component({
  selector: 'app-enquete-modal',
  templateUrl: './enquete-modal.component.html'
})
export class EnqueteModalComponent extends BaseCrudModalComponent<EnqueteModel> implements OnInit {
  @ViewChild('enqueteModal') modal: ModalDirective;
  @ViewChild('enqueteOpcao') opcaoModal: EnqueteOpcaoComponent;


  statusPublicacoes = [
    { text: 'Rascunho', value: StatusPublicacaoType.Rascunho }
  ];

  constructor(
    injector: Injector,
    private EnqueteService: EnqueteService
  ) {
    super(injector, EnqueteService);
  }

  protected initializeForm(enquete: EnqueteModel) {
    this.form = this.formBuilder.group({
      id: enquete.id,
      dataInclusao: [enquete.dataInclusao, Validators.required],
      dataAlteracao: enquete.dataAlteracao,
      titulo: [enquete.titulo, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
      ]],
      dataInicial: [this.fromJsonDate(enquete.dataInicial), [
        Validators.required
      ]],
      dataFinal: [this.fromJsonDate(enquete.dataFinal), [
        Validators.required
      ]],
      descricaoInterna: [enquete.descricaoInterna, [
        Validators.maxLength(200)
      ]],
      descricaoSite: [enquete.descricaoSite, [
        Validators.maxLength(200)
      ]],
      opcoes: [enquete.opcoes],
      statusPublicacao: [enquete.statusPublicacao, [
      ]]
    });
  }

  beforeShowCreate(model: EnqueteModel) {
    model = super.beforeShowCreate(model);
    model.statusPublicacao = StatusPublicacaoType.Rascunho;
    return model;
  }

  newModel() {
    const enquete = super.newModel();
    enquete.opcoes = [];
    return enquete;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onItemResponse(opcoes: EnqueteOpcaoModel[]) {
    opcoes.forEach(x => x.enqueteId = this.form.value.id);
    this.form.patchValue({ opcoes });
  }

  showEditarAgendamento(model: EnqueteModel) {
    this.statusPublicacoes = [];
    this.statusPublicacoes.push({
      text: 'Agendada', value: StatusPublicacaoType.Agendada
    });
    this.showEditWithModel(model);
  }

  showEditarEncerramento(model: EnqueteModel) {
    this.statusPublicacoes = [];
    this.statusPublicacoes.push({
      text: 'Encerrada', value: StatusPublicacaoType.Encerrada
    });
    this.showEditWithModel(model);
  }

  showCriarDuplicada(model: EnqueteModel) {
    this.form = null;
    this.newItem = true;
    model.dataInicial = new Date();
    model = this.beforeShowEdit(model);
    this.initializeForm(model);
    this.modal.show();
  }
}
