export enum StatusPublicacaoType {
  Rascunho = 0,
  Agendada = 1,
  Publicada = 2,
  Encerrada = 3
}

export enum EquipeType {
  Equipe = 1,
  CorpoClínico = 2
}

export enum LayoutArtigoType {
  ImagemNaEsquerda = 1,
  ImagemEmCima = 2
}

export enum ProfissionalRecursoType {
  ProfissionalSaude = 1,
  Equipamento = 2
}

export enum IconeType {
  FlatIcon = 1,
  FontAwesome = 2
}

export enum SolicitacaoLgpdType {
  VerificarDados = 1,
  AlterarConsultarDados = 2,
  ExclusaoDados = 3,
  Outros = 4,
}

export enum StatusLgpdType {
  Pendente = 1,
  EmProcesso = 2,
  Finalizado = 3
}

export enum RevistaType {
  LinkExterno = 1,
  ArquivoPdf = 2,
}

export enum StatusCurriculoType {
  Pendente = 1,
  Visto = 2,
  Contratado = 3,
  NaoAtende = 4
}

export enum LocalPublicacaoType {
  Noticia = 1,
  ResponsabilidadeSocial = 2,
  Blog = 3,
  HotSite = 4
}

export enum RacaType {
  Branca = 1,
  Preta = 2,
  Amarela = 2,
  Indigena = 2,
}

export enum EstadoCivilType {
  Solteiro = 1,
  Casado = 2,
  Separado = 3,
  Divorciado = 4,
  Viuvo = 5
}

export enum SangueType {
  NaoInformado = 0,
  Aneg = 1,
  Apos = 2,
  Bneg = 3,
  Bpos = 4,
  ABneg = 5,
  ABpos = 6,
  Oneg = 7,
  Opos = 8,
}

export enum PacienteIndicadoPorType {
  SemIndicacao = 0,
  Medico = 1,
  Paciente = 2,
  Amigo = 3,
  Parente = 4,
  Propaganda = 5
}

export enum PacienteProntuarioType {
  Evolucao = 0,
  Atestado = 1,
  Receita = 2,
  SolitacaoExame = 3
}

export enum DayOfWeekType {
  Domingo = 0,
  Segunda = 1,
  Terca = 2,
  Quarta = 3,
  Quinta = 4,
  Sexta = 5,
  Sabado = 6
}

export enum PesquisaQuestaoType {
  Selecao = 0,
  MultiSelecao = 1,
  Descritivo = 2
}

export enum PesquisaStatusCorType {
  Verde = 0,
  Laranja = 1,
  Vermelho = 2,
  Purpura = 3
}

export enum QuestionarioPosicaoCardType {
  Centro = 0,
  Esquerda = 1,
  Direita = 2
}

export enum TermoStatusType {
  Pendente = 0,
  Finalizado = 1
}

export enum TermoControleType {
  Bot = 0,
  App = 1
}