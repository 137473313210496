<div class="panel-group" role="tablist" aria-multiselectable="true">
  <div class="panel" id="panelFilter">
    <div class="panel-heading" role="tab" id="headingFilter">
      <h4 class="panel-title">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordionFilter" href="#collapseFilter"
          aria-expanded="false" aria-controls="collapseFilter">
          <span class="material-icons">filter_alt</span> Filtros
        </a>
      </h4>
    </div>
    <div id="collapseFilter" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFilter"
      aria-expanded="false">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Filtrando por:</mat-label>
              <mat-select [(ngModel)]="search.field">
                <mat-option value="nome">Nome</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Tipo Filtro</mat-label>
              <mat-select [(ngModel)]="search.option">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Pesquisar por</mat-label>
              <input matInput type="text" [(ngModel)]="search.searchTerm" (keyup.enter)="filter()" />
              <mat-error>Informe um Nome válido.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select [(ngModel)]="searchEstado.searchTerm" (selectionChange)="changeEstado($event.value)">
                <mat-option value="">Todos Estados</mat-option>
                <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <mat-select [(ngModel)]="searchCidade.searchTerm">
                <mat-option value="">Todos Cidades</mat-option>
                <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="panel-footer" style="text-align: right">
        <mat-slide-toggle style="margin-right: 10px;" color="primary" [(ngModel)]="searchRegistroAtivo.searchTerm">
          Somente Reg.Ativos
        </mat-slide-toggle>
        <button mat-flat-button color="primary" (click)="filter()">
          <span>Filtrar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <ul class="nav nav-tabs">
          <li class="active"><a class="nav-link active" id="nav-model-tab" data-toggle="tab" href="#nav-model"
              role="tab" aria-controls="nav-model" aria-selected="true" (click)="filter()">Convênios</a></li>
          <li><a class="nav-link" id="nav-ordenacao-tab" data-toggle="tab" href="#nav-ordenacao" role="tab"
              aria-controls="nav-ordenacao" aria-selected="false" (click)="atualizaOrdem()">Ordenação</a></li>
        </ul>
      </div>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade in active" id="nav-model" role="tabpanel" aria-labelledby="nav-model-tab">
          <div class="body table-responsive">
            <!--query end-->
            <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
              class="table table-bordered table-striped table-hover" style="width: 100%;">
              <thead>
                <tr>
                  <th class="text-center" width="50">Ordem</th>
                  <th class="text-center" width="50">Código</th>
                  <th class="text-center">Nome</th>
                  <th class="text-center">Telefone</th>
                  <th class="text-center">Cidade/UF</th>
                  <th class="text-center" width="50">Ativo</th>
                  <th class="text-center" width="150"></th>
                </tr>
              </thead>
              <tbody *ngIf="models?.length != 0">
                <tr *ngFor="let model of models">
                  <td>
                    {{model.ordem}}
                  </td>
                  <td>
                    {{model.codigo}}
                  </td>
                  <td>
                    {{model.nome}}
                  </td>
                  <td>
                    {{model.telefone}}
                  </td>
                  <td>
                    {{model.cidadeUf}}
                  </td>
                  <td>
                    {{typesService.getBoolTypeNome(model.registroAtivo)}}
                  </td>
                  <td class="text-center">
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(model)"
                      matTooltip="Editar">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(model.id)"
                      matTooltip="Excluir">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="fixed-action-btn">
            <button [disabled]="!isAllowed('Create')" mat-fab color="primary" (click)="create()"
              matTooltip="Adicionar (F9)" matTooltipPosition="above">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <!-- TAB CONVENIOS -->
        <div class="tab-pane fade" id="nav-ordenacao" role="tabpanel" aria-labelledby="nav-ordenacao-tab">
          <div class="body">
            <div mat-dialog-content>
              <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let model of models" cdkDrag>
                  {{model.nome}}
                </div>
              </div> -->

              <div cdkDropList [cdkDropListData]="convenios" class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of convenios" cdkDrag>
                  {{item.text}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<app-convenio-modal #convenioModal (modalSave)="onResponse($event)"></app-convenio-modal>