<div class="card">
    <div class="body" *ngIf="form">        
        <form method="post" (ngSubmit)="save()" [formGroup]="form">
            <h4 class="card-inside-title">Ícone PWA</h4>
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <p class="font-15 col-deep-orange text-center">
                        O tamanho ideal da imagem é <strong>512 x 512 pixels</strong>
                    </p>
                    <div class="form-group align-center">
                        <div style="height: 150px; width: 100%; object-fit: contain;">
                            <img src="{{form.value.pwaIconUrl}}"
                                style="height: 150px; width: 100%; object-fit: contain;"
                                *ngIf="form.value.pwaIconUrl">
                        </div>
                        <div class="align-center">
                            <button mat-icon-button type="button" (click)="showSelectImage()"
                                matTooltip="Selecionar Imagem">
                                <mat-icon>add_photo_alternate</mat-icon>
                            </button>
                            <button mat-icon-button type="button" [disabled]="!form.value.pwaIconUrl"
                                (click)="deselectImage()" matTooltip="Desvincular Imagem">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">                        
                <div class="col-md-12">
                    <mat-form-field>
                        <mat-label>Nome do app</mat-label>
                        <input matInput formControlName="pwaName" type="text" maxlength="100" />
                        <mat-error>Informe um Nome válido.</mat-error>
                    </mat-form-field>
                </div>                                                

                <div class="col-md-12">
                    <mat-form-field>
                        <mat-label>Nome curto do app</mat-label>
                        <input matInput formControlName="pwaShortName" type="text" maxlength="50" />
                        <mat-error>Informe um Nome válido.</mat-error>
                    </mat-form-field>
                </div> 

                <div class="col-md-12">
                    <mat-form-field>
                        <mat-label>Descrição do app</mat-label>
                        <textarea matInput formControlName="pwaDescription" type="text" rows="4" maxlength="400"></textarea>
                        <mat-error>Informe uma Descrição válida.</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <button mat-flat-button type="submit" color="primary" class="button-row">
                        <span>Salvar Alterações</span>
                    </button>
                </div>
            </div>
        </form>            
    </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>