import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ContatoPreAgendamentoModalComponent } from './contato-pre-agendamento-modal/contato-pre-agendamento-modal.component';
import { ContatoPreAgendamentoComponent } from './contato-pre-agendamento.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    ContatoPreAgendamentoModalComponent,
    ContatoPreAgendamentoComponent
  ]
})
export class ContatoPreAgendamentoModule { }
