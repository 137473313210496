<div bsModal #iconeSelectModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="iconeSelectModal" aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="card">
        <div class="header">
          <h4 class="modal-title">Selecione um icone</h4>
          <ul class="header-dropdown m-r--5">
            <li>
              <button mat-flat-button color="primary" type="button" (click)="create()">Adicionar</button>
            </li>
          </ul>
        </div>
        <div class="body">
          <mat-form-field>
            <mat-label>Filtro</mat-label>
            <input matInput [(ngModel)]="searchTerm" type="text" />
            <mat-error>Informe Filtro válido.</mat-error>
            <button mat-button type="button" matSuffix (click)="filtrar()">
              <span> Filtrar</span>
            </button>
          </mat-form-field>
          <div class="row" *ngFor="let categoria of categorias">
            <div class="col-md-12">
              <strong>{{categoria.nome}}</strong>
            </div>
            <div *ngFor="let icone of iconesFiltrados">
              <div class="col-xs-4 col-sm-2 col-md-1" (click)="select(icone)" *ngIf="icone.categoriaIconeNome == categoria.nome"
                style="cursor: pointer; height: 100px;">
                <div class="text-center">
                  <div class="font-size-h1" [innerHTML]="icone.iconeHtml">
                  </div>
                  <small>
                    {{icone.nome}}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
            <span> Fechar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-icone-modal #iconeModal (modalSave)="onResponse()"></app-icone-modal>
