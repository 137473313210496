import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Injector } from '@angular/core';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PaginaService, PaginaAcaoService } from 'projects/ProjetoBaseAngular/domain/services';
import { PaginaModel, PaginaAcaoModel } from 'projects/ProjetoBaseAngular/domain/models';

@Component({
  selector: 'app-pagina-modal',
  templateUrl: './pagina-modal.component.html',
})
export class PaginaModalComponent extends BaseCrudModalComponent<PaginaModel> implements OnInit {
  @ViewChild('paginaModal') modal: ModalDirective;

  @Output() modalSave: EventEmitter<PaginaModel> = new EventEmitter<PaginaModel>();

  paginaAcoes: PaginaAcaoModel[] = [];

  constructor(
    injector: Injector,
    paginaService: PaginaService,
    private paginaAcaoService: PaginaAcaoService,
  ) {
    super(injector, paginaService);
  }

  protected initializeForm(pagina: PaginaModel) {
    this.form = this.formBuilder.group({
      id: pagina.id,
      dataInclusao: [pagina.dataInclusao, Validators.required],
      dataAlteracao: pagina.dataAlteracao,
      permissionName: [pagina.permissionName, [
        Validators.required,
        Validators.min(2)
      ]],
      nome: [pagina.nome, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)]
      ],
      descricao: [pagina.descricao, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(500)]
      ],
      helpUrl: [pagina.helpUrl, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(200)]
      ],
      acoes: [pagina.acoes]
    });

    this.paginaAcaoService.getByPaginaId(pagina.id).subscribe(paginaAcoes => this.paginaAcoes = paginaAcoes);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
