import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { AgendamentoComponent } from './agendamento.component';
import { AgendamentoModalComponent } from './agendamento-modal/agendamento-modal.component';
import { AgendamentoLogComponent } from './agendamento-log/agendamento-log.component';
import { PacienteModule } from '../../paciente/paciente/paciente.module';
import { AgendaDisponibilidadeModule } from '../agenda-disponibilidade/agenda-disponibilidade.module';
import { AgendaBloqueioModule } from '../agenda-bloqueio/agenda-bloqueio.module';
import { AgendaEsperaModule } from '../agenda-espera/agenda-espera.module';
import { AtendimentoModule } from '../atendimento/atendimento.module';
import { ConfirmacaoLoteComponent } from './confirmacao-lote/confirmacao-lote.component';
import { AgendamentoRecorrenteComponent } from './agendamento-recorrente/agendamento-recorrente.component';


FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [    
    SharedModule,
    NgxMatSelectSearchModule,
    FullCalendarModule,    
    PacienteModule,
    AgendaDisponibilidadeModule,
    AgendaBloqueioModule,
    AgendaEsperaModule,
    AtendimentoModule
  ],
  declarations: [
    AgendamentoComponent,
    AgendamentoModalComponent,
    AgendamentoRecorrenteComponent,
    AgendamentoLogComponent,
    ConfirmacaoLoteComponent
  ]
})
export class AgendamentoModule { }
