
export enum WhatsChatStatusType {
  Ativo = 0,
  Finalizado = 1,
  Cancelado = 2
}

export enum WhatsChatType {
  Bot = 0,
  Atendente = 1
}

export enum WhatsChatMsgType {
  Recebida = 0,
  Enviada = 1
}

export enum WhatsChatMsgCampoType {
  Ignorado = 0,

  SolicNomePaciente = 1,
  SolicNomeMedico = 2,
  SolicConvenio = 3,
  SolicMensagem = 4,

  PresencaConfirmacao = 100,
  PresencaMotivoCanc = 101,

  AceiteTermoConfirmacao = 200,
}

export enum BotAtendimentoType {
  NaoInformado = 0,
  ConsultaRetorno = 1,
  Exames = 2,
  Receitas = 3,
  Atestados = 4,
  Atendente = 5
}

export enum BotFluxoType {
  NaoInformado = 0,
  IniciadoCliente = 1,
  ConfirmacaoPresenca = 2,
  PesquisaSatisfacao = 3,
  AceiteTermo = 4,
}

export enum BotNodoType {
  InicioFluxo = 0,
  Mensagem = 1,
  Menu = 2,
  AtendimentoInit = 3,
  AtendimentoItem = 4,
  AtendimentoFim = 5
}

export enum SolicitacaoOnlineStatusType {
  //Não existe (somente para facilitar filtro de tela) 
  Pendente_EmAtendimento = -1,
  //Quando Bot gera o documento
  Pendente = 0,
  //Pelo atendente
  EmAtendimento = 1,
  Finalizado = 2,
  Cancelado = 3,
}

export enum ConfPresencaFilaStatusType {
  Pendente = 0,
  Enviado = 1,
  CanceladaVencida = 2,
  CanceladaManual = 3
}

export enum WhatsChatMsgMidiaType
{
    Texto = 0,
    Imagem = 1,
    Documento = 2,
    Video = 3,
    Audio = 4
}