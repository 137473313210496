<div mat-dialog-title>
  <button type="button" class="close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
  <h4>Selecionar Laudos/Obs/Conclusões</h4>
</div>
<mat-dialog-content>
  <div class="container-fluid">
    <mat-form-field>
      <mat-label>Texto Padrão</mat-label>
      <mat-select [(ngModel)]="textoId" #focus>
        <mat-option>
          <ngx-mat-select-search placeholderLabel="Buscar..." 
          noEntriesFoundLabel="Nenhum resultado encontrado!" [formControl]="textoFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let textoPadrao of filteredTextos" [value]="textoPadrao.id">{{textoPadrao.texto}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" class="button-row" (click)="adicionar()">
    <span> Adicionar</span>
  </button>
  <button mat-stroked-button type="button" [routerLink]="" mat-dialog-close class="button-row">
    <span> Fechar</span>
  </button>
</mat-dialog-actions>