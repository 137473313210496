import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseEntidadePessoaModalComponent } from 'projects/ProjetoBaseAngular/app/admin/entidade-pessoa/base-entidade-pessoas/base-entidade-pessoa-modal.component';
import { ProfissionalSaudeModel, EspecialidadeMedModel, AreaAtuacaoMedModel } from '../../domain/models';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProfissionalSaudeService, EspecialidadeMedService, AreaAtuacaoMedService } from '../../domain/services';
import { Validators, FormControl } from '@angular/forms';
import { ComponenteSistemaType, PessoaType } from 'projects/ProjetoBaseAngular/domain/types';
import { ImagemModel, PessoaModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ImagemSelectModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-select-modal/imagem-select-modal.component';
import { LayoutArtigoType } from 'projects/admin/src/domain/types';
import { IconeModel } from '../../../Icone/domain/models';
import { IconeSelectModalComponent } from '../../../Icone/icone/icone-select-modal/icone-select-modal.component';
import { TenantService, UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';

@Component({
  selector: 'app-profissional-saude-modal',
  templateUrl: './profissional-saude-modal.component.html'
})
export class ProfissionalSaudeModalComponent extends BaseEntidadePessoaModalComponent<ProfissionalSaudeModel> implements OnInit {
  @ViewChild('profissionalSaudeModal') modal: ModalDirective;
  @ViewChild('imagemBannerSelectModal') imagemBannerSelectModal: ImagemSelectModalComponent;
  @ViewChild('iconeSelectModal') iconeSelectModal: IconeSelectModalComponent;

  moduloPortal: boolean;
  moduloAtendimento: boolean;

  especialidadesMed: EspecialidadeMedModel[];
  areasAtuacaoMed: AreaAtuacaoMedModel[];
  listaDtoUsuario: SelectDto[];

  tipos = [
    { text: 'Física', value: PessoaType.Fisica },
  ];

  layoutsArtigos = [
    { text: 'Imagem na Esquerda', value: LayoutArtigoType.ImagemNaEsquerda },
    { text: 'Imagem em Cima', value: LayoutArtigoType.ImagemEmCima }
  ];

  constructor(
    injector: Injector,
    profissionalSaudeService: ProfissionalSaudeService,
    private especialidadeMedService: EspecialidadeMedService,
    private areaAtuacaoMedService: AreaAtuacaoMedService,
    private tenantService: TenantService,
    readonly usuarioService: UsuarioService,
    readonly typesService: TypesService
  ) {
    super(injector, profissionalSaudeService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.especialidadeMedService.getAll().subscribe(especialidadesMed => this.especialidadesMed = especialidadesMed);

    this.tenantService.getCurrentTenant().subscribe(tenant => {
      const modulos = tenant.componentes.filter(x => x.tipoItem === ComponenteSistemaType.Modulo);
      if (modulos.find(x => x.codigoItem === this.typesService.MODULO_PORTAL)) {
        this.moduloPortal = true;
      }

      if (modulos.find(x => x.codigoItem === this.typesService.MODULO_ATENDIMENTO)) {
        this.moduloAtendimento = true;
      }
    });

    this.usuarioService.getSelectList().subscribe(x => this.listaDtoUsuario = x);
  }

  protected initializeForm(profissionalSaude: ProfissionalSaudeModel) {
    super.initializeForm(profissionalSaude);

    this.form.addControl('ordem',
      new FormControl(profissionalSaude.ordem)
    );

    this.form.addControl('crmMedico',
      new FormControl(
        profissionalSaude.crmMedico, [
        Validators.maxLength(7)
      ])
    );
    this.form.addControl('crnMedico',
      new FormControl(
        profissionalSaude.crnMedico, [
        Validators.maxLength(7)
      ])
    );
    this.form.addControl('rqeMedico',
      new FormControl(
        profissionalSaude.rqeMedico, [
        Validators.maxLength(20)
      ])
    );
    this.form.addControl('slug',
      new FormControl(
        profissionalSaude.slug, [
        Validators.maxLength(50)
      ])
    );
    this.form.addControl('temHotsite',
      new FormControl(
        profissionalSaude.temHotsite)
    );
    this.form.addControl('especialidadeMedId',
      new FormControl(
        profissionalSaude.especialidadeMedId, [
        Validators.required
      ])
    );
    this.form.addControl('areaAtuacaoMedIds',
      new FormControl(
        profissionalSaude.areaAtuacaoMedIds
      )
    );
    this.form.addControl('curriculoHtml',
      new FormControl(
        profissionalSaude.curriculoHtml
      )
    );
    this.form.addControl('linkFacebook',
      new FormControl(
        profissionalSaude.linkFacebook
      )
    );
    this.form.addControl('linkYoutube',
      new FormControl(
        profissionalSaude.linkYoutube
      )
    );
    this.form.addControl('linkInstagram',
      new FormControl(
        profissionalSaude.linkInstagram
      )
    );
    this.form.addControl('linkTwitter',
      new FormControl(
        profissionalSaude.linkTwitter
      )
    );
    this.form.addControl('numeroWhatsApp',
      new FormControl(
        profissionalSaude.numeroWhatsApp, [
        Validators.maxLength(15)
      ])
    );
    this.form.addControl('numeroTelegram',
      new FormControl(
        profissionalSaude.numeroTelegram, [
        Validators.maxLength(15)
      ])
    );
    this.form.addControl('emailHotSite',
      new FormControl(
        profissionalSaude.emailHotSite, [
        Validators.maxLength(100)
      ])
    );
    this.form.addControl('bannerHotSite',
      new FormControl(
        profissionalSaude.bannerHotSite, [
        Validators.maxLength(200)
      ])
    );
    this.form.addControl('bannerHotSiteUrlBase',
      new FormControl(
        profissionalSaude.bannerHotSiteUrlBase, [
        Validators.maxLength(150)
      ])
    );
    this.form.addControl('bannerHotSitePath',
      new FormControl(
        profissionalSaude.bannerHotSitePath, [
        Validators.maxLength(150)
      ])
    );
    this.form.addControl('corPrimaria',
      new FormControl(
        profissionalSaude.corPrimaria, [
        Validators.maxLength(7)
      ])
    );
    this.form.addControl('corSecundaria',
      new FormControl(
        profissionalSaude.corSecundaria, [
        Validators.maxLength(7)
      ])
    );
    this.form.addControl('layoutArtigoHotSite',
      new FormControl(
        profissionalSaude.layoutArtigoHotSite)
    );

    this.form.addControl('iconeCrmCrnHtml',
      new FormControl(
        profissionalSaude.iconeCrmCrnHtml, [
        Validators.maxLength(200)
      ])
    );

    this.form.addControl('nomeApresentacao',
      new FormControl(
        profissionalSaude.nomeApresentacao, [
        Validators.maxLength(60)
      ])
    );
    this.form.addControl('usuarioId',
      new FormControl(profissionalSaude.usuarioId)
    );
    this.form.addControl('usaConfirmacaoLote',
      new FormControl(profissionalSaude.usaConfirmacaoLote)
    );
  }

  protected newModel(): ProfissionalSaudeModel {
    const model = new ProfissionalSaudeModel();
    model.pessoa = new PessoaModel();
    model.pessoa.codigo = 0;
    model.rqeMedico = "";
    model.crmMedico = "";
    model.crnMedico = "";
    model.usuarioId = null;
    model.usaConfirmacaoLote = true;
    return model;
  }

  prepararNome(nome: string) {
    this.slugify(nome);
    let model: ProfissionalSaudeModel = Object.assign({}, this.form.value);
    if (!model.nomeApresentacao) {
      model.nomeApresentacao = nome;
      this.form.patchValue({
        nomeApresentacao: model.nomeApresentacao
      });
    }
  }


  ImprimirConsole() {
    let model: ProfissionalSaudeModel = Object.assign({}, this.form.value);
    console.log(model);
  }

  changeEspecialidadeMed(especialidadeMedId: string) {
    this.areaAtuacaoMedService.getByEspecialidadeMedId(especialidadeMedId)
      .subscribe(areasAtuacaoMed => this.areasAtuacaoMed = areasAtuacaoMed);
  }

  beforeShowCreate(model: ProfissionalSaudeModel) {
    model = super.beforeShowCreate(model);
    model.temHotsite = false;
    model.pessoa.tipoPessoa = PessoaType.Fisica;
    model.layoutArtigoHotSite = LayoutArtigoType.ImagemNaEsquerda;
    model.ordem = 0;
    return model;
  }

  beforeShowEdit(model: ProfissionalSaudeModel) {
    model.especialidadeMedId && this.changeEspecialidadeMed(model.especialidadeMedId);
    model.pessoa.estadoId && this.changeEstado(model.pessoa.estadoId);
    return super.beforeShowEdit(model);
  }

  showSelectImageBanner() {
    this.imagemBannerSelectModal.showSelect(1920);
  }

  onResponseBanner(imagem: ImagemModel) {
    this.form.patchValue({
      bannerHotSite: imagem.url,
      bannerHotSiteUrlBase: imagem.urlBase,
      bannerHotSitePath: imagem.path
    });
  }

  deselectImageBanner() {
    this.form.patchValue({
      bannerHotSite: null,
      bannerHotSiteUrlBase: null,
      bannerHotSitePath: null
    });
  }

  setCorPrimaria(corPrimaria: string) {
    this.form.patchValue({
      corPrimaria
    });
  }

  setCorSecundaria(corSecundaria: string) {
    this.form.patchValue({
      corSecundaria
    });
  }

  showSelectIcone() {
    this.iconeSelectModal.showSelect();
  }

  onResponseIcone(icone: IconeModel) {
    this.form.patchValue({ iconeCrmCrnHtml: icone.iconeHtml });
  }

  deselectIcone() {
    this.form.patchValue({ iconeCrmCrnHtml: null });
  }
}

