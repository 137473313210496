import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ServicoModel, TipoServicoModel } from '../domain/models';
import { ServicoModalComponent } from './servico-modal/servico-modal.component';
import { ServicoService, TipoServicoService } from '../domain/services';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TypesService } from 'projects/admin/src/domain/services';

@Component({
  selector: 'app-servico',
  templateUrl: './servico.component.html',
  styleUrls: ['./servico.component.scss']
})
export class ServicoComponent extends BaseCrudComponent<ServicoModel> implements OnInit {
  @ViewChild('servicoModal') modal: ServicoModalComponent;

  tiposServico: TipoServicoModel[];
  servicos: ServicoModel[] = [];

  searchTipos = new ModelSearch('tipoServicoId', OptionSearchType.Equals, SearchType.Guid);
  search = new ModelSearch('nome', OptionSearchType.Contains, SearchType.String);

  private readonly tipoServicoService: TipoServicoService;

  selectColumns = [
    { data: 'ordem' },
    { data: 'tipoServico.nome' },
    { data: 'exame' },
    { data: 'nome' },
    { data: 'descricao' }
  ];

  constructor(
    injector: Injector,
    readonly servicoService: ServicoService,
    readonly typesService: TypesService
  ) {
    super(injector, servicoService);
    this.tipoServicoService = injector.get<TipoServicoService>(TipoServicoService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.tipoServicoService.getAll().subscribe(tiposServico => this.tiposServico = tiposServico);
    this.atualizaOrdem();
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.searchTipos.searchTerm) && searches.push(this.searchTipos);
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.servicos, event.previousIndex, event.currentIndex);
    const ids = this.servicos.map(x => x.id);
    this.servicoService.updateOrdens(ids).subscribe();
  }

  atualizaOrdem() {
    this.servicoService.getAll().subscribe(servicos => this.servicos = servicos);
    //console.log(this.servicos);
  }

  getTipoServico_Nome(servico: ServicoModel): string {
    let tipoNome = this.tiposServico.find(x => x.id === servico.tipoServicoId).nome;
    return `${tipoNome} - ${servico.nome}`;
  }
}
