import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import Swal from 'sweetalert2';
import { PacienteModel } from '../../../paciente/domain/models';
import { PacienteService } from '../../../paciente/domain/services';
import { AtendimentoModel, CapturaImagemModel, CapturaModel } from '../../domain/models';
import { AtendimentoService, CapturaImagemService, CapturaService } from '../../domain/services';

@Component({
  selector: 'app-captura-print',
  templateUrl: './captura-print.component.html'
})
export class CapturaPrintComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;

  captura: CapturaModel;
  atendimento: AtendimentoModel;
  capturaImagens: CapturaImagemModel[];
  paciente: PacienteModel;
  imprimirNomeImagem: boolean;

  constructor(
    readonly capturaService: CapturaService,
    readonly atendimentoService: AtendimentoService,
    readonly capturaImagemService: CapturaImagemService,
    readonly pacienteService: PacienteService,
    readonly printService: PrintService
  ) { }

  ngOnInit() {
  }

  async print(capturaId: string) {
    this.captura = await this.capturaService.getById(capturaId).toPromise();
    this.paciente = await this.pacienteService.getById(this.captura.pacienteId).toPromise();
    this.capturaImagens = await this.capturaImagemService.getByCapturaId(capturaId).toPromise();

    let procedimentos: any[] = [];
    this.capturaImagens.forEach(x => {
      if (!procedimentos.find(y => y.id === x.procedimentoId)) {
        procedimentos.push({ id: x.procedimentoId, nome: x.procedimentoNome });
      }
    });

    let buttons: string = '';
    procedimentos.forEach(procedimento => {      
      buttons += `<button id="procedimento${procedimento.id}" type="button" class="swal2-confirm swal2-styled">${procedimento.nome}</button>`;
    });
    this.imprimirNomeImagem = procedimentos.length > 1;
    
    Swal.fire({
      html:
        `Selecione um Procedimento abaixo: *</h5><br/>${buttons}`,
      focusCancel: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Fechar',
      didOpen: () => {
        procedimentos.forEach(procedimento => {          
          document.getElementById(`procedimento${procedimento.id}`).addEventListener('click', () => Swal.close({ isConfirmed: true, isDismissed: false, isDenied: false, value: procedimento.id }));
        });
      }
    }).then(result => {
      if (result.value) {
        this.capturaImagens = this.capturaImagens.filter(x => x.procedimentoId === result.value);

        setTimeout(() => {
          const content = this.relatorio.nativeElement.innerHTML;
          this.printService.print('Capturas', content);
        }, 500);
      }
    })    
  }
}
