import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { EquipeComponent } from './equipe.component';
import { EquipeModalComponent } from './equipe-modal/equipe-modal.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule,
  ],
  declarations: [
    EquipeComponent,
    EquipeModalComponent
  ]
})
export class EquipeModule { }
