import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { QuestAnamneseComponent } from './quest-anamnese.component';
import { QuestAnamneseModalComponent } from './quest-anamnese-modal/quest-anamnese-modal.component';
import { QuestAnamneseRepostaComponent } from './quest-anamnese-resposta/quest-anamnese-resposta.component';
import { QuestAnamneseRepostaModalComponent } from './quest-anamnese-resposta/quest-anamnese-resposta-modal/quest-anamnese-resposta-modal.component';

@NgModule({
  imports: [    
    SharedModule
  ],
  declarations: [
    QuestAnamneseComponent,
    QuestAnamneseModalComponent,

    QuestAnamneseRepostaComponent,
    QuestAnamneseRepostaModalComponent
  ]
})
export class QuestAnamneseModule { }
