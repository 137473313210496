import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { PacienteProntuarioModel } from '../../domain/models';
import { PacienteProntuarioService } from '../../domain/services/paciente-prontuario.service';
import { ProfissionalSaudeService } from '../../../equipe/domain/services';
import { ProfissionalSaudeDto } from '../../../equipe/domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { PacienteProntuarioType } from 'projects/admin/src/domain/types';

@Component({
  selector: 'app-paciente-prontuario-modal',
  templateUrl: './paciente-prontuario-modal.component.html'
})
export class PacienteProntuarioModalComponent extends BaseCrudModalComponent<PacienteProntuarioModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;
  @Output() itemUpdate: EventEmitter<any> = new EventEmitter<any>();  

  pacienteProntuarioType: typeof PacienteProntuarioType = PacienteProntuarioType;
  listaProfissionalDto: ProfissionalSaudeDto[];

  constructor(
    injector: Injector,
    service: PacienteProntuarioService,
    readonly profissionalService: ProfissionalSaudeService,
    readonly typesService: TypesService,
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.profissionalService.getSelectList().subscribe(x => this.listaProfissionalDto = x);
  }

  protected initializeForm(model: PacienteProntuarioModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [this.fromJsonDate(model.dataInclusao), Validators.required],
      dataAlteracao: model.dataAlteracao,

      pacienteId: [model.pacienteId, Validators.required],
      profissionalSaudeId: [model.profissionalSaudeId, Validators.required],
      atendimentoId: [model.atendimentoId],
      tipo: [model.tipo,Validators.required],
      receitaTipo: [model.receitaTipo],
      sequencial: [model.sequencial,Validators.required],
      descricao: [model.descricao, Validators.required],
    });
  }

  async save(autoSave?: boolean): Promise<void> {
    console.log(this.form.value)
    
    debugger
    if (!await this.validateToSave(autoSave)) {
			return;
		}
      super.save(autoSave)
  }
}
