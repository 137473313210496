import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { LayoutImpressaoModel } from '../../atendimento/domain/models';
import { LayoutImpressaoService } from '../../atendimento/domain/services';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html'
})
export class ReportHeaderComponent implements OnInit {
  tenant: TenantModel;
  layoutImpressao: LayoutImpressaoModel;

  constructor(
    readonly tenantService: TenantService,
    readonly layoutImpressaoService: LayoutImpressaoService,
    readonly sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.tenantService.getCurrentTenant().subscribe(x => this.tenant = x);
    this.layoutImpressaoService.getAll().subscribe(x => this.layoutImpressao = x[0]);
  }

}
