<mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
<div class="card">
  <div class="header">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>{{title}}</h2>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Inicial</mat-label>
          <input matInput [(ngModel)]="dataInicial" type="date" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-label>Data Final</mat-label>
          <input matInput [(ngModel)]="dataFinal" type="date" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">      
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Convênios</mat-label>
          <mat-select [(ngModel)]="convenioIds" multiple>
            <mat-option *ngFor="let item of convenios" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Grupos de Procedimento</mat-label>
          <mat-select [(ngModel)]="grupoProcedimentoIds" multiple>
            <mat-option *ngFor="let item of gruposProcedimento" [value]="item.value">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12 text-center">
        <button mat-flat-button color="primary" [disabled]="isBusy  || !isAllowed()" (click)="print()">
          <span>Gerar Relatório</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="text-center font-weight-bold">{{title}}</h4>
          <div class="row">
            <div class="col-3">
              <strong>Data de Emissão:</strong> {{dataAtual}}
            </div>
            <div class="col">
              <strong>Período:</strong> {{dataInicial | date: 'dd/MM/yyyy'}} à {{dataFinal | date: 'dd/MM/yyyy'}}
            </div>
          </div>
          <div class="row mb-2">            
            <div class="col">
              <strong>Convênios:</strong> {{convenioNomes}}
            </div>
            <div class="col">
              <strong>Grupos Procedimento:</strong> {{grupoProcedimentoNomes}}
            </div>
          </div>

          <table border="1" cellpadding="5" cellspacing="5" style="width: 100%;" *ngIf="modelDto">
            <thead>
              <tr style="background-color: silver;">
                <th class="text-center">Cód Atend</th>
                <th class="text-center">Profissional</th>
                <th class="text-center">Data Lancto</th>
                <th class="text-center">Procedimento</th>
                <th class="text-center">Convênio</th>
                <th class="text-center">Paciente</th>
                <th class="text-center">Matrícula</th>
                <th class="text-center">Senha</th>
                <th class="text-center">Nº Guia</th>
                <th class="text-center">Vlr Proc</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of modelDto.list">
                <td>
                  {{item.codigoDocMovto}}
                </td>
                <td>
                  {{item.profissionalNome}}
                </td>                
                <td>
                  {{item.dataAtendimento | date: 'dd/MM/yyyy'}}
                </td>                
                <td>
                  {{item.procedimentoNome}}
                </td>
                <td>
                  {{item.convenioNome}}
                </td>
                <!-- <td>
                  {{item.pacienteCodigo}} - {{item.pacienteNome}}
                </td> -->
                <td>
                  {{item.pacienteNome}}
                </td>   
                <td>
                  {{item.matriculaConvenio}}
                </td>
                <td>
                  {{item.senhaGuia}}
                </td>
                <td>
                  {{item.numGuia}}
                </td>
                <td class="text-right">
                  {{item.vlrTotal | currency: 'BRL':''}}
                </td>                
              </tr>
              <tr style="background-color: silver;">
                <td class="text-center" colspan="9">
                  <strong>Total</strong>
                </td>
                <td class="text-right">
                  <strong>{{modelDto.vlrTotal | currency: 'BRL':''}}</strong>
                </td>                                
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>