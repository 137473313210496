<div bsModal #equipeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="equipeModal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Equipe</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
          <div formGroupName="pessoa">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group align-center">
                  <div style="height: 150px; width: 100%; object-fit: contain;">
                    <img src="{{form.value.pessoa.imagemUrl}}" style="height: 150px; width: 100%; object-fit: contain;"
                      *ngIf="form.value.pessoa.imagemUrl">
                  </div>
                  <div class="align-center">
                    <button mat-icon-button type="button" (click)="showSelectImage()" matTooltip="Selecionar Imagem">
                      <mat-icon>add_photo_alternate</mat-icon>
                    </button>
                    <button mat-icon-button type="button" [disabled]="!form.value.pessoa.imagemUrl"
                      (click)="deselectImage()" matTooltip="Desvincular Imagem">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                  <p class="font-15 col-deep-blue text-center">
                    O tamanho ideal para Imagens é <strong>800x600</strong>
                  </p>
                </div>
              </div>
              <div class="col-md-10">
                <h4 class="card-inside-title">Dados Principais</h4>
                <div class="row">
                  <div class="col-md-2">
                    <mat-form-field>
                      <mat-label>Tipo Pessoa</mat-label>
                      <mat-select formControlName="tipoPessoa" (selectionChange)="changeTipoPessoa($event.value)"
                        #focus>
                        <mat-option *ngFor="let tipo of tipos" [value]="tipo.value">
                          {{tipo.text}}</mat-option>
                      </mat-select>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaJuridica">CNPJ
                      </mat-label>
                      <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaFisica">CPF
                      </mat-label>
                      <input matInput formControlName="cnpjCpf" type="text" [dropSpecialCharacters]="false"
                        mask="{{cnpjCpfMask}}" placeholder="{{cnpjCpfPlaceholder}}"
                        (blur)="changeCnpjCpf($event.target.value)" />
                        <mat-hint align="end">Máx 18 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <mat-form-field>
                      <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaJuridica">IE
                      </mat-label>
                      <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaFisica">RG
                      </mat-label>
                      <input matInput formControlName="iestadualRg" type="text" />
                      <mat-hint align="end">Máx 18 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2" *ngIf="form.value.pessoa.tipoPessoa == pessoaJuridica">
                    <mat-form-field>
                      <mat-label>Insc. Municipal</mat-label>
                      <input matInput formControlName="imunicipal" type="text" />
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3" *ngIf="form.value.pessoa.tipoPessoa == pessoaJuridica">
                    <mat-form-field>
                      <mat-label>Razão Social</mat-label>
                      <input matInput formControlName="razaoSocial" type="text" />
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-5">
                    <mat-form-field>
                      <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaJuridica">Nome
                        Fantasia
                      </mat-label>
                      <mat-label *ngIf="form.value.pessoa.tipoPessoa == pessoaFisica">Nome
                        Completo
                      </mat-label>
                      <input matInput formControlName="nomeFantasia" type="text" />
                      <mat-hint align="end">Máx 60 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <h4 class="card-inside-title">Dados para Contato e Cobrança</h4>
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Telefone</mat-label>
                      <input matInput formControlName="telefone" type="tel" placeholder="Ex: (48) 3333-3333" />
                      <mat-hint align="end">Máx 15 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Celular</mat-label>
                      <input matInput formControlName="celular" type="tel" [dropSpecialCharacters]="false"
                        mask="(00) 00000-0000" placeholder="Ex: (48) 98888-8888" />
                        <mat-hint align="end">Máx 15 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>E-mail</mat-label>
                      <input matInput formControlName="email" type="email" placeholder="Ex: jose@email.com" />
                      <mat-hint align="end">Máx 50 caracteres</mat-hint>
                      <mat-error></mat-error>
                    </mat-form-field>
                  </div>
                  <div class="pessoa-juridica">
                    <div class="col-md-3">
                      <mat-form-field>
                        <mat-label>Site</mat-label>
                        <input matInput formControlName="site" type="url" placeholder="Ex: meusite.com" />
                        <mat-hint align="end">Máx 50 caracteres</mat-hint>
                        <mat-error></mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="card-inside-title">Dados de endereço</h4>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>CEP</mat-label>
                  <input matInput formControlName="cep" type="text" [dropSpecialCharacters]="false" mask="00.000-000"
                    placeholder="Ex: 00.000-000" (blur)="changeCep($event.target.value)" />
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Rua / Avenida</mat-label>
                  <input matInput formControlName="ruaAvenida" type="text" />
                  <mat-hint align="end">Máx 50 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Número</mat-label>
                  <input matInput formControlName="numero" type="text" />
                  <mat-hint align="end">Máx 10 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Complemento</mat-label>
                  <input matInput formControlName="complemento" type="text" />
                  <mat-hint align="end">Máx 50 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Bairro</mat-label>
                  <input matInput formControlName="bairro" type="text" />
                  <mat-hint align="end">Máx 50 caracteres</mat-hint>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Estado</mat-label>
                  <mat-select formControlName="estadoId" (selectionChange)="changeEstado($event.value)">
                    <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.uf}}
                    </mat-option>
                  </mat-select>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Cidade</mat-label>
                  <mat-select formControlName="cidadeId">
                    <mat-option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}
                    </mat-option>
                  </mat-select>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <h4 class="card-inside-title">Dados Adicionais</h4>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Setor</mat-label>
                <mat-select formControlName="setorId" (selectionChange)="changeSetor($event.value)">
                  <mat-option *ngFor="let setor of setores" [value]="setor.id">{{setor.nome}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Cargo</mat-label>
                <mat-select formControlName="cargoId">
                  <mat-option *ngFor="let cargo of cargos" [value]="cargo.id">{{cargo.nome}}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Observações</mat-label>
                <textarea matInput #observacoes formControlName="observacoes" rows="3" maxlength="500"></textarea>
                <mat-hint align="end">{{observacoes.value.length}} / 500</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>

        </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-imagem-select-modal #imagemSelectModal (modalClose)="onResponse($event)"></app-imagem-select-modal>
