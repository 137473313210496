<div #relatorio class="visible-print">
  <table class="mb-1" border="1" cellspacing="5" cellpadding="5" style="width: 100%;" *ngIf="docSus">
    <tbody>
      <tr>
        <td valign="bottom" style="width: 50%;">
          <div class="row">
            <div class="col">
              <img src={{docSusConfig.tfdImagemUrl}} height="80" />
            </div>
            <div class="col">
              <h5 class="font-weight-bold p-0 m-0">Estado de Santa Catarina</h5>
              <h5 class="font-weight-bold p-0 m-0">Ministério da Saúde/SUS</h5>
            </div>
          </div>
        </td>
        <td style="width: 50%;">
          <h5 class="font-weight-bold p-0 m-0" align="center">Laudo Médico</h5>
          <h5 class="font-weight-bold p-0 m-0" align="center">Tratamento Fora Domícilio</h5>
          <h5 class="font-weight-bold p-0 m-0" align="center">Para Dentro do Estado</h5>
        </td>
      </tr>
    </tbody>
  </table>
  <table border="1" cellspacing="5" cellpadding="5" style="width: 100%;" *ngIf="paciente">
    <tbody>
      <tr>
        <td colspan="11" valign="top">
          <small>NOME DO PACIENTE</small>
          <p class="p-0 m-0">{{paciente.pessoa.nomeFantasia}}</p>
        </td>
        <td colspan="2" valign="top">
          <small>IDADE</small>
          <p class="p-0 m-0">{{idadePaciente}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top">
          <p class="p-0 m-0">NOME DO MÉDICO SOLICITANTE</p>
          <p class="p-0 m-0">{{profissionalSolicitante?.pessoa?.nomeFantasia}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7" valign="top">
          <small>CPF</small>
          <p class="p-0 m-0">{{paciente.pessoa.cnpjCpf}}</p>
        </td>
        <td colspan="3" valign="top">
          <small>TELEFONE</small>
          <p class="p-0 m-0">{{(paciente.pessoa.telefone || '')}}</p>
        </td>
        <td colspan="3" valign="top">
          <small>TELEFONE</small>
          <p class="p-0 m-0">{{(paciente.pessoa.celular || '')}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="8" valign="top" height="50">
          <small>PROCEDIMENTO SOLICITADO</small>
          <p class="p-0 m-0">{{docSus.procedimentos[0].procedimentoNome}}</p>
        </td>
        <td colspan="5" valign="top">
          <small>CÓDIGO DO PROCEDIMENTO SIA/SIH/SUS</small>
        </td>
      </tr>
      <tr>
        <td colspan="10" valign="top" height="50">
          <small>DIAGN&#211;STICO INICIAL</small>
          <p class="p-0 m-0">{{docSus.diagnosticoInicial}}</p>
        </td>
        <td colspan="3" valign="top">
          <small>CID-10</small>
          <p class="p-0 m-0">{{docSus.cid10Principal}}</p>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="50">
          <small>CARÁTER DE ATENDIMENTO</small>
          <div *ngIf="docSus.caracterAtendimento === caracterAtendimentoType.Hospitalar">
            <p class="p-0 m-0">(X) HOSPITALAR     ( ) AMBULATORIAL COM EMERGÊNCIA</p>  
          </div>
          <div *ngIf="docSus.caracterAtendimento !== caracterAtendimentoType.Hospitalar">
            <p class="p-0 m-0">( ) HOSPITALAR     (X) AMBULATORIAL COM EMERGÊNCIA</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="100">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">1 - HISTÓRICO DA DOENÇA</p>
            <p class="p-0 m-0">{{docSus.historicoDoenca}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="80">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">2 - EXAME FÍSICO</p>
            <p class="p-0 m-0">{{docSus.exameFisico}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="80">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">3 - DIAGNÓSTICO PROVÁVEL</p>
            <p class="p-0 m-0">{{docSus.diagnosticoProvavel}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="100">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">4 - EXAMES COMPLEMENTARES REALIZADOS (ANEXAR CÓPIAS)</p>
            <p class="p-0 m-0">{{docSus.examesComplementares}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="80">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">5 - TRATAMENTOS REALIDADOS</p>
            <p class="p-0 m-0">{{docSus.tratamentoRealizado}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="80">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">6 - PROCEDIMENTO / TRATAMENTO SOLICITADO</p>
            <p class="p-0 m-0">{{docSus.tratamentoSolicitado}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="80">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">7 - JUSTIFICAR AS RAZÕES QUE IMPOSSIBILITAM O TRATAMENTO NA LOCALIDADE</p>
            <p class="p-0 m-0">{{docSus.justifTratamento}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="80">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">8 - JUSTIFICAR EM CASO DE ACOMPANHANTE</p>
            <p class="p-0 m-0">{{docSus.justifAcompanante}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="50">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">9 - TRANSPORTE RECOMENDÁVEL (JUSTIFICAR)</p>
            <p class="p-0 m-0">{{'( ' + (docSus.transpRodoviario ? 'X' : ' ')  + ' )'}} RODOVIÁRIO    
              {{'( ' + (docSus.transpAereo ? 'X' : ' ')  + ' )'}} AÉREO    
              {{'( ' + (docSus.transpAmbulancia ? 'X' : ' ')  + ' )'}} AMBULÂNCIA</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" valign="top" height="80">
          <div style="word-break: break-all; white-space: normal;">
            <p class="p-0 m-0">JUSTIFICAR</p>
            <p class="p-0 m-0">{{docSus.justifTransp}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="13" style="background: silver;">
          <p class="font-weight-bold p-0 m-0" align="center">ASSINATURA DO PROFISSIONAL SOLICITANTE</p>
        </td>
      </tr>
      <tr>
        <td colspan="6" valign="top" height="150">
          <small>LOCAL E DATA</small>
          <!-- <p class="p-0 m-0">{{cidade.nome}},  {{docSus.dataInclusao | date: 'dd/MM/yyyy'}}</p> -->
          <p class="p-0 m-0">{{docSus.dataInclusao | date: 'dd/MM/yyyy'}}</p>

        </td>
        <td colspan="7" valign="top" height="100">
          <small>ASSINATURA E CARIMBO DO PROFISSIONAL</small>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="p-0 m-0">&#160;</p>
</div>