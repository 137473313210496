import { NgModule } from '@angular/core';

import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { ExameComponent } from './exame.component';
import { ExameModalComponent } from './exame-modal/exame-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ExameComponent,
    ExameModalComponent
  ]
})
export class ExameModule { }
