import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';
import { CurriculoModalComponent } from './curriculo-modal/curriculo-modal.component';
import { CurriculoComponent } from './curriculo.component';
import { GaleriaModule } from 'projects/ProjetoBaseAngular/app/admin/galeria/galeria.module';
import { CurriculoCpfModalComponent } from './curriculo-cpf-modal/curriculo-cpf-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GaleriaModule
  ],
  declarations: [
    CurriculoComponent,
    CurriculoModalComponent,
    CurriculoCpfModalComponent
  ]
})
export class CurriculoModule { }
