import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ConfigSiteTenantModel, WebFonts } from 'projects/ProjetoBaseAngular/app/admin/meu-site/domain/models';
import { ConfigSiteTenantService } from 'projects/ProjetoBaseAngular/app/admin/meu-site/domain/services/config-site-tenant.service';
import { TemaService } from 'projects/ProjetoBaseAngular/app/admin/meu-site/domain/services/tema.service';

@Component({
  selector: 'app-aparencia',
  templateUrl: './aparencia.component.html',
  styleUrls: ['./aparencia.component.scss']
})
export class AparenciaComponent extends BaseComponent implements OnInit {  
  webFonts: WebFonts;  
  temaAtual: number;

  suggestionColors = [
    new SuggestionColor("#002D5E", "#009ADE"),    
    new SuggestionColor("#373737", "#38663a"),
    new SuggestionColor("#373737", "#752b40"),
    new SuggestionColor("#373737", "#1d3c6a"),
    new SuggestionColor("#373737", "#4c1d6a"),
    new SuggestionColor("#373737", "#6a331d"),
    new SuggestionColor("#373737", "#1d6a64"),
    new SuggestionColor("#373737", "#526a1d"),
    new SuggestionColor("#373737", "#1a3189"),
    new SuggestionColor("#373737", "#891a1a")
  ]

  constructor(
    injector: Injector,    
    private readonly configSiteTenantService: ConfigSiteTenantService,
    private readonly temaService: TemaService
  ) {
    super(injector);
  }

  protected initializeForm(model: ConfigSiteTenantModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,      
      themeFontFamily: [model.themeFontFamily, Validators.required],
      themeColorPrimary: [model.themeColorPrimary, [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7)]
      ],
      themeColorSecondary: [model.themeColorSecondary, [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7)]
      ]
    });
    this.changeFont(model.themeFontFamily);
  }

  ngOnInit() {
    this.configSiteTenantService.get().subscribe(model => this.initializeForm(model));
    this.configSiteTenantService.getWebFonts().subscribe(webFonts => this.webFonts = webFonts);    
    this.temaService.getCurrent().subscribe(tema => this.temaAtual = tema.codigo);
  }

  changeColor(suggestionColor: SuggestionColor) {
    this.form.patchValue({
      themeColorPrimary: suggestionColor.colorPrimary,
      themeColorSecondary: suggestionColor.colorSecondary
    });    
  }

  setColorPrimary(themeColorPrimary: string) {
    this.form.patchValue({
      themeColorPrimary
    });
  }

  setColorSecondary(themeColorSecondary: string) {
    this.form.patchValue({
      themeColorSecondary
    });
  }

  changeFont(fontFamily) {    
    var apiUrl = [];
    apiUrl.push('https://fonts.googleapis.com/css?display=swap&family=');
    apiUrl.push(fontFamily.replace(/ /g, '+'));
    const url = apiUrl.join('');
    document.getElementById('google-font').setAttribute('href', url);    
  }  

  save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    }

    let model: ConfigSiteTenantModel = Object.assign({}, this.form.value);
    this.configSiteTenantService.editByTipo('Theme', model).subscribe(
      () => {        
        this.snackBar.open('Salvo com sucesso!', 'Ok', {
          duration: 3000
        });
      },
      (errors) => this.verifyErrors(errors)
    );
  }
}

class SuggestionColor {
  constructor(colorPrimary: string, colorSecondary: string) {
    this.colorPrimary = colorPrimary;
    this.colorSecondary = colorSecondary;
  }

  public colorPrimary: string;
  public colorSecondary: string;
}
