import { Injectable, Injector } from '@angular/core';

import { BaseEntidadePessoaService } from 'projects/ProjetoBaseAngular/domain/services/entidade-pessoa/base-entidade-pessoa.service';
import { ParceiroModel, ParceiroTipoModel } from '../models';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ParceiroResponsePage } from 'projects/site/src/app/view-models/parceiros-view-model';

@Injectable({
  providedIn: 'root'
})
export class ParceiroService extends BaseEntidadePessoaService<ParceiroModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Parceiro';
  }

  create(model: ParceiroModel): Observable<ParceiroModel> {
    this.popularParceiroTipo(model);
    return super.create(model);
  }

  edit(model: ParceiroModel): Observable<ParceiroModel> {
    this.popularParceiroTipo(model);
    return super.edit(model);
  }

  get(searchTerm, tipoIds, cidadeIds, ordenacao, page, resultsPerPage = '10'): Observable<ParceiroResponsePage> {
    return this
      .httpClient
      .get<ParceiroModel>(`${this.getApiUrl()}${this.getController()}`, {
        headers: super.getAuthHeaderJson().headers,
        params: { searchTerm, tipoIds, cidadeIds, ordenacao, page, resultsPerPage }
      }).pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAll(): Observable<ParceiroModel[]> {
    return this
      .httpClient
      .get<ParceiroModel>(`${this.getApiUrl()}${this.getController()}/GetAll`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getById(id: string): Observable<ParceiroModel> {
    return super.getById(id)
      .pipe(
        map(this.popularTipoIds),
        catchError(this.serviceError)
      );
  }

  private popularTipoIds(model: ParceiroModel) {
    if (model.tipos) {
      model.tipoIds = [];
      model.tipos.forEach(parceiroTipo => {
        model.tipoIds.push(parceiroTipo.tipoParceiroId);
      });
    }
    return model;
  }

  private popularParceiroTipo(model: ParceiroModel) {
    model.tipos = [];

    model.tipoIds.forEach(tipoId => {
      let parceiroTipo = new ParceiroTipoModel();
      parceiroTipo.parceiroId = model.id;
      parceiroTipo.tipoParceiroId = tipoId;
      model.tipos.push(parceiroTipo);
    });
  }

  updateOrdens(parceiroIds) {
    return this
      .httpClient
      .post(`${this.getApiUrl()}${this.getController()}/UpdateOrdens`, parceiroIds, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
