import { PesquisaQuestaoType, PesquisaStatusCorType, QuestionarioPosicaoCardType, StatusPublicacaoType } from "projects/admin/src/domain/types";
import { BaseModel } from "projects/ProjetoBaseAngular/domain/models/base/base-model";

export class QuestionarioModel extends BaseModel {
  public titulo: string;
  public descricaoExterna: string;
  public descricaoInterna: string;
  public statusPublicacao: StatusPublicacaoType;
  public questaoFiltro: string;
  public pontuacaoVerde: number;
  public nomeVerde: string;
  public pontuacaoLaranja: number;
  public nomeLaranja: string;
  public pontuacaoVermelha: number;
  public nomeVermelha: string;
  public codigoResposta: number;
  public imagem1Url: string; 
  public imagem1UrlBase: string; 
  public imagem1Path: string; 
  public imagem2Url: string; 
  public imagem2UrlBase: string; 
  public imagem2Path: string; 
  public posicaoCard: QuestionarioPosicaoCardType;
}

export class  QuestionarioFiltroModel extends BaseModel {
  public questionarioId: string;
  public ordem: number;
  public opcao: string;
}

export class GrupoQuestaoModel extends BaseModel {
  public questionarioId: string;
  public filtroId: string;
  public titulo: string;
  public ordem: number;
  public tipo: PesquisaQuestaoType;
  public itens: GrupoQuestaoItemModel[];
  public opcoes: GrupoQuestaoOpcaoModel[];  
  public usaPontuacao: boolean;
}

export class GrupoQuestaoItemModel extends BaseModel {
  public grupoQuestaoId: string;
  public ordem: number;
  public questao: string;
}

export class GrupoQuestaoOpcaoModel extends BaseModel {
  public grupoQuestaoId: string;
  public ordem: number;
  public opcao: string;
  public opcaoEmoji: string;
  public pontuacao: number;
}

export class QuestionarioRespModel extends BaseModel {
  public questionarioId: string;
  public filtroId: string;
  public itens: QuestionarioRespItemModel[];
  public codigo: number;
  public pontuacao: number;
  public statusCor: PesquisaStatusCorType;
}

export class QuestionarioRespItemModel extends BaseModel {
  public questionarioRespId: string;
  public filtroId: string;
  public grupoQuestao: GrupoQuestaoModel;
  public grupoQuestaoId: string;
  public grupoQuestaoItemId: string;
  public grupoQuestaoOpcaoId: string;
  public respostaDescritiva: string;
  public pontuacao: number;
}

export class GrupoQuestaoRespDto {
  public grupoQuestao: GrupoQuestaoModel;
  public questoes: QuestionarioRespItemDto[];
}

export class QuestionarioRespItemDto extends QuestionarioRespItemModel {
  public grupoQuestaoItem: GrupoQuestaoItemModel;
  public grupoQuestaoOpcaoIds: string[];
}

export class RespItemApresentacao {
  public grupoTitulo: string;
  public grupoItemQuestao: string;
  public resposta: string;
  public usaPontuacao: boolean;
  public pontuacao: number;    
}