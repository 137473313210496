import { Component, OnInit, ViewChild, Injector, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { PacienteArquivoService } from '../../../domain/services';
import { PacienteArquivoModel } from '../../../domain/models';

@Component({
  selector: 'app-paciente-arquivo-modal',
  templateUrl: './paciente-arquivo-modal.component.html'
})
export class PacienteArquivoModalComponent extends BaseCrudModalComponent<PacienteArquivoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  constructor(
    injector: Injector,
    service: PacienteArquivoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: PacienteArquivoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      pacienteId: [model.pacienteId, Validators.required],
      nome: [model.nome, [
        Validators.required, Validators.maxLength(50)
      ]],
      arquivoUrl: [model.arquivoUrl],
      arquivoUrlBase: [model.arquivoUrlBase],
      arquivoPath: [model.arquivoPath],

      arquivoBase64: [model.arquivoBase64],
      extensaoBase64: [model.extensaoBase64]
    });
  }

  private manipularReader(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    const arquivoBase64 = btoa(binaryString);
    this.form.patchValue({ arquivoBase64 });
  }

  upload(file: any) {
    // Necessário para o upload via base64
    const reader = new FileReader();
    reader.onload = this.manipularReader.bind(this);
    reader.readAsBinaryString(file[0]);

    const fileNameArr = file[0].name.split('.');
    const extensaoBase64 = fileNameArr[fileNameArr.length - 1];
    this.form.patchValue({ extensaoBase64 });
  }

  showLinq(url: string) {
    window.open(url, "_blank");
  }
}
