import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';

import { TypesService } from 'projects/admin/src/domain/services';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { QuestionarioFiltroModel } from '../domain/models';
import { QuestionarioFiltroService } from '../domain/services';
import { QuestionarioFiltroModalComponent } from './questionario-filtro-modal/questionario-filtro-modal.component';

@Component({
  selector: 'app-questionario-filtro',
  templateUrl: './questionario-filtro.component.html'
})

export class QuestionarioFiltroComponent extends BaseCrudComponent<QuestionarioFiltroModel> implements OnInit {
  @ViewChild('modal') modal: QuestionarioFiltroModalComponent;

  @Input('questionarioId') questionarioId: string;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();
    
  constructor(
    injector: Injector,
    readonly service: QuestionarioFiltroService,
    readonly typesService: TypesService
  ) {
    super(injector, service);
    this.useDatatables = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.service.getByQuestionarioId(this.questionarioId).subscribe(x => this.models = x);    
  }  

  create(model: QuestionarioFiltroModel = null) {
    super.create(model);
    this.autoSave.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.models, event.previousIndex, event.currentIndex);
    const ids = this.models.map(x => x.id);
    this.service.updateOrdens(ids).subscribe();
  }
}

