import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { ModelSearch, DataTablesRequest } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';
import { PoliticaPrivacidadeModel } from '../domain/models';
import { PoliticaPrivacidadeService } from '../domain/services';
import { PoliticaPrivacidadeModalComponent } from './politica-privacidade-modal/politica-privacidade-modal.component';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html'
})
export class PoliticaPrivacidadeComponent extends BaseCrudComponent<PoliticaPrivacidadeModel> implements OnInit {
  @ViewChild('politicaPrivacidadeModal') modal: PoliticaPrivacidadeModalComponent;

  search = new ModelSearch('versao', OptionSearchType.Contains, SearchType.String);

  selectColumns = [
    { data: 'dataInclusao' },
    { data: '(versao.ToString() + "." + subVersao.ToString()) as versaoCompleta', orderable: false }
  ];

  constructor(
    injector: Injector,
    readonly politicaPrivacidadeService: PoliticaPrivacidadeService
  ) {
    super(injector, politicaPrivacidadeService);
  }

  ngOnInit() {
    super.ngOnInit();    
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    const searches = dataTablesParameters.searches || [];
    (this.search.searchTerm) && searches.push(this.search);

    dataTablesParameters.searches = searches;
    return super.beforeDtRequest(dataTablesParameters);
  }
}
