<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-xl">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Pacientes em Espera</h2>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Profissional da Saúde/Equipamento</mat-label>
              <mat-select [(ngModel)]="atendimentoFilterDto.recursoIds" (selectionChange)="changeRecurso($event.value)"
                *ngIf="!atendimentoFilterDto.naoFiltrarRecurso" multiple>
                <mat-option *ngFor="let recurso of recursos" [value]="recurso.value">
                  {{recurso.text}}
                </mat-option>
              </mat-select>
              <input matInput disabled value="Todos Profissionais da Saúde/Equipamentos"
                *ngIf="atendimentoFilterDto.naoFiltrarRecurso" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Status do Atendimento</mat-label>
              <mat-select [(ngModel)]="atendimentoFilterDto.status" (selectionChange)="filter()" multiple disabled>
                <mat-option *ngFor="let status of typesService.atendimentoStatusType" [value]="status.value">
                  {{status.text}}
                </mat-option>
              </mat-select>
              <mat-error></mat-error>
            </mat-form-field>
          </div>          
        </div>
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
            class="table table-bordered table-striped table-hover" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center" colspan="2">Agendamento</th>
                <th class="text-center" colspan="6">Atendimento</th>
              </tr>
              <tr>
                <th class="text-center" width="50">Data</th>
                <th class="text-center" width="30">Horário</th>
                <th class="text-center" width="60">Hora Ent.</th>
                <th class="text-center">Profissional/Equipamento</th>
                <th class="text-center">Classificação</th>
                <th class="text-center">Status</th>
                <th class="text-center">Paciente</th>
                <th class="text-center">Convênio</th>
              </tr>
            </thead>
            <tbody *ngIf="models?.length != 0">
              <tr *ngFor="let item of models">
                <td *ngIf="item.agendamentoId">{{item.agendamentoData | date: 'dd/MM/yyyy'}}</td>
                <td *ngIf="item.agendamentoId">{{item.agendamentoHorario | mask: 'hh:MM'}}</td>
                <td colspan="2" *ngIf="!item.agendamentoId">{{item.dataHoraEntrada | date: 'dd:MM:yyyy'}} compl.
                </td>
                <td>{{item.dataHoraEntrada | date: 'HH:mm:ss'}}</td>
                <td>{{item.profissionalRecursoNome}}</td>
                <td>{{item.agendamentoClassificacaoNome}}</td>
                <td>
                  <span class="badge" [style.backgroundColor]="typesService.getAtendimentoStatusColorType(item.status)"
                    [matTooltip]="item.status === typesService.AtendimentoStatusType.Atendido ? 'Ver Detalhes' : 'Iniciar/Continuar Atendimento'">
                    {{typesService.getAtendimentoStatusType(item.status)}}
                  </span>
                </td>
                <td [innerHTML]="item.pacientePessoaNomeFantasia"></td>
                <td [innerHTML]="item.convenioNome"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-4" style="margin-bottom: 0;">
            <mat-form-field>
              <mat-label>Anotações</mat-label>
              <textarea matInput [(ngModel)]="anotacoes" rows="2"></textarea>
              <button matSuffix mat-icon-button type="button" [disabled]="anotacoes===anotacoesOld"
                (click)="saveAnotacoes()" color="primary" matTooltip="Salvar Anotações">
                <mat-icon>done</mat-icon>
              </button>
              <button matSuffix mat-icon-button type="button" (click)="refreshAnotacoes()" color="primary"
                matTooltip="Atualizar Anotações">
                <mat-icon>refresh</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>