import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriaIconeComponent } from './categoria-icone.component';
import { CategoriaIconeModalComponent } from './categoria-icone-modal/categoria-icone-modal.component';
import { SharedModule } from 'projects/ProjetoBaseAngular/app/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    CategoriaIconeComponent,
    CategoriaIconeModalComponent
  ]
})
export class CategoriaIconeModule { }
