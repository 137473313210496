<div bsModal #listModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
  aria-hidden="true" [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg" *ngIf="recursoId">
    <div class="card modal-content">
      <div class="header">
        <h2>Disponibilidade de Agenda - {{recursoNome}}</h2>
      </div>
      <div class="modal-body table-responsive">
        <mat-tab-group #tabs>
          <mat-tab label="Geral">
            <br>
            <button mat-flat-button color="primary" type="button" [disabled]="!isAllowed('Create')"
              (click)="create()">Adicionar</button>
            <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions"
              class="table table-bordered table-striped table-hover" style="width: 100%;">
              <thead>
                <tr>
                  <th class="text-center">Dia da Semana</th>
                  <th class="text-center">Horário Inicial</th>
                  <th class="text-center">Horário Final</th>
                  <th class="text-center">Duração</th>
                  <th class="text-center">Classificação</th>
                  <th class="text-center" width="80"></th>
                </tr>
              </thead>
              <tbody *ngIf="models?.length != 0">
                <tr *ngFor="let item of models">
                  <td>{{typesService.getDayOfWeekType(item.diaSemana)}}</td>
                  <td>{{item.horarioInicial | mask: 'hh:MM'}}</td>
                  <td>{{item.horarioFinal | mask: 'hh:MM'}}</td>
                  <td>{{item.duracao | mask: 'hh:MM'}}</td>
                  <td>{{item.classificacaoNome}}</td>
                  <td class="text-center">
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="edit(item)"
                      matTooltip="Editar">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="delete(item.id)"
                      matTooltip="Excluir">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Eventual">
            <br>
            <button mat-flat-button color="primary" type="button" [disabled]="!isAllowed('Create')"
              (click)="createEventual()">Adicionar</button>
            <table class="table table-bordered table-striped table-hover" style="width: 100%;">
              <thead>
                <tr>
                  <th class="text-center">Data</th>
                  <th class="text-center">Horário Inicial</th>
                  <th class="text-center">Horário Final</th>
                  <th class="text-center">Duração</th>
                  <th class="text-center">Classificação</th>
                  <th class="text-center" width="120"></th>
                </tr>
              </thead>
              <tbody *ngIf="listaEventual?.length != 0">
                <tr *ngFor="let item of listaEventual">
                  <td>{{item.data | date :'dd/MM/yyyy'}}</td>
                  <td>{{item.horarioInicial | mask: 'hh:MM'}}</td>
                  <td>{{item.horarioFinal | mask: 'hh:MM'}}</td>
                  <td>{{item.duracao | mask: 'hh:MM'}}</td>
                  <td>{{item.classificacaoNome}}</td>
                  <td class="text-center">
                    <button [disabled]="!isAllowed('Update')" mat-icon-button type="button" (click)="editEventual(item)"
                      matTooltip="Editar">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button [disabled]="!isAllowed('Delete')" mat-icon-button type="button" (click)="deleteEventual(item.id)"
                      matTooltip="Excluir">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-agenda-disponibilidade-modal #modal (modalSave)="onResponse($event)"></app-agenda-disponibilidade-modal>
<app-agenda-disponibilidade-eventual-modal #modalEventual (modalSave)="onResponseEventual($event)"></app-agenda-disponibilidade-eventual-modal>