<div class="row clearfix">
  <div class="col-md-12">
    <button mat-flat-button color="primary" type="button" (click)="create()">Adicionar Item</button>
  </div>
  <hr />
  <div class="col-md-12">
    <!--query end-->
    <table datatable [dtOptions]="dtOptions" *ngIf="dtOptions" class="table table-bordered table-striped table-hover"
      style="width: 100%;">
      <thead>
        <tr>
          <th class="text-center">Ordem</th>
          <th class="text-center">Descrição da Opção</th>
          <th class="text-center" width="150"></th>
        </tr>
      </thead>
      <tbody *ngIf="opcoes?.length != 0">
        <tr *ngFor="let enqueteOpcao of opcoes">
          <td>
            {{enqueteOpcao.ordem}}
          </td>
          <td>
            {{enqueteOpcao.descricaoOpcao}}
          </td>
          <td class="text-center">
            <button mat-icon-button type="button" (click)="edit(enqueteOpcao)" matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="delete(enqueteOpcao.id)" matTooltip="Excluir">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-enquete-opcao-modal #enqueteOpcaoModal (modalSave)="onResponse($event)"></app-enquete-opcao-modal>
