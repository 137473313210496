import { Component, OnInit, ViewChild, ElementRef, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { environment } from 'environments/environment';
import { ImagemService } from 'projects/ProjetoBaseAngular/domain/services';
import { ImagemModel } from 'projects/ProjetoBaseAngular/domain/models';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-imagem-modal',
  templateUrl: './imagem-modal.component.html',
  styleUrls: ['./imagem-modal.component.scss']
})
export class ImagemModalComponent extends BaseCrudModalComponent<ImagemModel> implements OnInit {
  @ViewChild('imagemModal') modal: ModalDirective;
  @ViewChild("fileUploadCreate") fileUploadCreate: ElementRef;

  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  cropperWidth: number = 0;
  slimOptionsEdit: {};
  slimOptionsList = [];
  imagemId: string;

  constructor(
    injector: Injector,
    readonly imagemService: ImagemService
  ) {
    super(injector, imagemService);
  }

  protected initializeForm(model: ImagemModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,
      url: [model.url, Validators.maxLength(200)]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  beforeShowEdit(model: ImagemModel) {
    this.slimOptionsEdit = {
      initialImage: `${environment.baseUrl}/api/v1/Imagem/GetImage?url=${model.url}`,
      instantEdit: 'true',
      push: true,
      willRequest: this.handleRequest,
      service: `${environment.baseUrl}/api/v1/Imagem/Upload/${model.id}?width=${this.cropperWidth}`
    }
    return model;
  }

  showCreateCropper(cropperWidth: number) {
    this.cropperWidth = cropperWidth;
    super.showCreate();
  }

  afterShowCreate() {
    this.imagemId = null;
  }

  close() {
    super.close();
    if (this.imagemId) {
      this.imagemService.getById(this.imagemId).subscribe(imagem => {
        this.modalClose.emit(imagem);
        this.slimOptionsList = [];
        this.slimOptionsEdit = null;
      });
    } else {
      this.modalClose.emit();
      this.slimOptionsList = [];
      this.slimOptionsEdit = null;
    }
  }

  uploadFiles() {
    const fileUpload = this.fileUploadCreate.nativeElement;
    fileUpload.onchange = () => {
      this.errors = [];
      console.log(fileUpload.files);
      for (let i = 0; i < fileUpload.files.length; i++) {
        this.imagemId = this.commonService.newGuid();
        const slimOptions = {
          push: true,
          didInit: function () {
            this.load(fileUpload.files[i]);
          },
          willRequest: this.handleRequest,
          service: `${environment.baseUrl}/api/v1/Imagem/Upload/${this.imagemId}?width=${this.cropperWidth}`
        }
        this.slimOptionsList.push(slimOptions);
      }
    };
    fileUpload.click();
  }

  private handleRequest(xhr) {
    xhr.setRequestHeader('Tenant', localStorage.getItem('app.tenant'));
  }
}
