<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-xl">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header" [ngClass]="{'bg-red' : !form.value.registroAtivo}">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="row">
            <div class="col-md-6">
              <h2>Procedimento</h2>
            </div>
            <div class="col-md-5 text-right">
              <mat-slide-toggle formControlName="registroAtivo" color="primary">
                Reg.Ativo
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input matInput formControlName="codigo" type="text"/>
                <mat-error>Informe um Código válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-10">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" maxlength="100" required #focus/>
                <mat-hint align="end">{{form?.value?.nome?.length}} / 100</mat-hint>
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Grupo</mat-label>
                <mat-select formControlName="grupoId" required>
                  <mat-option *ngFor="let grupo of grupos" [value]="grupo.value">
                    {{grupo.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione uma Classificação.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <section>
                <mat-checkbox formControlName="exame" color="primary">
                  Exame</mat-checkbox>
              </section>
            </div>
            <div class="col-md-3" *ngIf="form.value.exame">
              <section>
                <mat-checkbox formControlName="exameEntregueAto" style="margin: 5px;" color="primary">
                  Exame entregue no ato</mat-checkbox>
                <mat-checkbox formControlName="usoFrequente" style="margin: 5px;" color="primary">
                  Uso Frequente</mat-checkbox>
              </section>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Tipo Serviço</mat-label>
                <mat-select formControlName="tipoServico" required>
                  <mat-option *ngFor="let tipo of typesService.procedimentoServicoType" [value]="tipo.value">
                    {{tipo.text}}
                  </mat-option>
                </mat-select>
                <mat-error>Selecione um Tipo de Serviço.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="form.value.tipoServico === procedimentoServicoType.Interno">
            <h4 class="card-inside-title">Tabela - Convênios</h4>
            <app-procedimento-convenio (autoSave)="autoSave()" [procedimentoId]="form.value.id">
            </app-procedimento-convenio>
          </div>
        </div>
      </form>
      <div class="footer">
        <div class="row">
          <div class="col-sm-3 text-left">
            <p style="margin: 0;">Incluído:{{form.value.dataInclusao | date:"dd/MM/yyyy"}}</p>
            <p style="margin: 0;">Alterado:{{form.value.dataAlteracao | date:"dd/MM/yyyy"}}</p>
          </div>
          <div class="col-sm-2 text-left">
          </div>
          <div class="col-sm-7">
            <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
              Continuar Incluindo
            </mat-slide-toggle>
            <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
              class="button-row">
              <span> Salvar</span>
            </button>
            <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
              <span> Cancelar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>