import { OnInit, Injector, ViewChild, HostListener, Directive } from '@angular/core';
import Swal from 'sweetalert2';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { BaseModel } from '../domain/models/base/base-model';
import { BaseCrudService } from '../domain/services/base-crud.service';
import { BaseCrudModalComponent } from './base-crud-modal.component';
import { DataTableDirective } from 'angular-datatables';
import { OptionSearchType, SearchType } from '../domain/types';
import { DataTablesRequest, ModelSearch } from '../domain/models';

@Directive()
export abstract class BaseCrudComponent<TModel extends BaseModel> extends BaseComponent implements OnInit {
  @ViewChild(DataTableDirective) datatable: DataTableDirective;

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F9' && document.querySelectorAll('.modal-backdrop.fade.in').length === 0) {
      this.create();
    }
  }

  searchRegistroAtivo = new ModelSearch('registroAtivo', OptionSearchType.Equals, SearchType.Boolean, true);

  isBusy: boolean = false;
  models: any[];
  protected verifyPermissionCreate: boolean = true;
  protected verifyPermissionRead: boolean = true;
  protected verifyPermissionUpdate: boolean = true;
  protected verifyPermissionDelete: boolean = true;
  protected useDatatables: boolean = true;

  ativoOptions = [
    { text: 'Inativo', value: false },
    { text: 'Ativo', value: true }
  ];

  filterOptions = [
    { text: 'Começando com', value: OptionSearchType.StartWith },
    { text: 'Contendo', value: OptionSearchType.Contains },
    { text: 'Terminando com', value: OptionSearchType.EndWith },
    { text: 'Igual', value: OptionSearchType.Equals }
  ];

  selectColumns: any[];

  dtOptions: DataTables.Settings;

  protected abstract modal: BaseCrudModalComponent<TModel>;
  protected readonly service: BaseCrudService<TModel>;

  constructor(
    injector: Injector,
    service: BaseCrudService<TModel>
  ) {
    super(injector);
    this.service = service;
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {

    dataTablesParameters.searches = dataTablesParameters.searches || [];

    (this.searchRegistroAtivo.searchTerm) && dataTablesParameters.searches.push(this.searchRegistroAtivo);
    dataTablesParameters.selectColumns = this.selectColumns;
    return dataTablesParameters;
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.useDatatables) {
      this.dtOptions = {
        language: {
          url: '/assets-base/json/translation/datatable/pt-br.json'
        },
        pageLength: 10,
        searching: false,
        serverSide: true,
        processing: true,
        ajax: (dataTablesParameters: DataTablesRequest, callback) => {
          dataTablesParameters = this.beforeDtRequest(dataTablesParameters);
          this.service.getResponse(dataTablesParameters).subscribe(
            (resp) => {
              this.models = resp.data;
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: []
              });
              this.commonService.spinnerClose();
            },
            () => {
              this.commonService.spinnerClose();
            });
        },
        columns: this.selectColumns?.filter(x => !x.hidden)
      };
    }

    if (this.verifyPermissionRead) {
      if (!this.isAllowed("Read")) {
        this.dtOptions = null;
        Swal.fire("Você não tem permissão de leitura neste módulo!");
      }
    }

    this.selectColumns = this.selectColumns;
  }

  create(model: TModel = null) {
    this.modal.showCreate(model);
  }

  edit(model: TModel) {
    this.modal.showEdit(model.id);
  }

  detail(model: TModel) {
    this.modal.showDetail(model.id);
  }

  delete(id: string) {
    if (!this.isAllowed("Delete")) {
      Swal.fire("Você não tem premisssão de exclusão neste módulo!");
      return;
    }
    this.commonService.mensagemConfirmacao("Confirmar exclusão deste registro?", "", "warning")
      .then((result) => {
        if (result) {
          this.service.delete(id).subscribe(
            () => this.afterDelete(id),
            errors => Swal.fire('Erro!', errors.join('<br/>'), 'error')
          );
        }
      });
  }

  afterDelete(id: string) {
    const index = this.models.findIndex(x => x.id === id);
    this.models.splice(index, 1);
    this.snackBar.open('Excluído com sucesso!', 'Ok', {
      duration: 3000
    });
  }

  onResponse(id: string) {
    if (!id || !(this.selectColumns?.length > 0)) {
      this.filter();
      return;
    }

    this.service.getSelectById(id, this.selectColumns).subscribe(model => {
      this.applyToList(model);
    });

    this.snackBar.open('Salvo com sucesso!', 'Ok', {
      duration: 3000
    });
  }

  applyToList(model: TModel) {
    const index = this.models.findIndex(x => x.id === model.id);
    if (index === -1) {
      this.models.push(model);
    } else {
      this.models[index] = model;
    }
  }

  filter() {
    this.datatable?.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  isAllowed(value: string) {
    const type = this.service.getController();
    return super.isAllowed(value, type);
  }
}
