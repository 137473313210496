<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Procedimento do Atendimento</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input matInput [disabled]="detailMode" [value]="procedimento?.codigo" type="number"
                  (blur)="changeProcedimentoCodigo($event.target.value)" required #focus autocomplete="off" />
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button" (click)="procedimentoSelect.showSelect()"
                  matTooltip="Selecionar Procedimento">
                  <mat-icon>search</mat-icon>
                </button>
                <button [disabled]="detailMode" matSuffix mat-icon-button type="button" (click)="procedimentoSelect.create()"
                  matTooltip="Adicionar Procedimento">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <mat-form-field>
                <mat-label>Nome do Procedimento</mat-label>
                <input matInput disabled [value]="procedimento?.nome" type="text" />
                <button [disabled]="isBusy || !procedimento?.id" matSuffix mat-icon-button type="button" (click)="procedimentoSelect.edit(procedimento)"
                  matTooltip="Editar Dados do Procedimento">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <mat-error></mat-error>
              </mat-form-field>
            </div>            
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Vlr. Unitario</mat-label>
                <input *ngIf="convenio?.permiteAlterarValor" matInput formControlName="vlrUnitario" type="text" currencyMask (blur)="atualizarTotal()" required />
                <input *ngIf="!convenio?.permiteAlterarValor" matInput disabled value="{{form.value.vlrUnitario | currency:'BRL':''}}" currencyMask type="text" />
                <mat-error>Informe um Vlr. Unitario válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Vlr. Acréscimo</mat-label>
                <input *ngIf="convenio?.permiteAlterarValor" matInput formControlName="vlrAcrescimo" type="text" currencyMask (blur)="atualizarTotal()" required />
                <input *ngIf="!convenio?.permiteAlterarValor" matInput disabled value="{{form.value.vlrAcrescimo | currency:'BRL':''}}" currencyMask type="text" />
                <mat-error>Informe um Vlr. Acrescimo válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Vlr. Desconto</mat-label>
                <input *ngIf="convenio?.permiteAlterarValor" matInput formControlName="vlrDesconto" type="text" currencyMask (blur)="atualizarTotal()" required />
                <input *ngIf="!convenio?.permiteAlterarValor" matInput disabled value="{{form.value.vlrDesconto | currency:'BRL':''}}" currencyMask type="text" />
                <mat-error>Informe um Vlr. Desconto válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Vlr. Total</mat-label>
                <input matInput class="font-weight-bold" disabled value="{{form.value.vlrTotal | currency:'BRL':''}}" currencyMask />
                <mat-error>Informe um Vlr. Total válido.</mat-error>
              </mat-form-field>
            </div>            
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Nº Guia</mat-label>
                <input matInput formControlName="numGuia" type="text" />                
                <mat-error>Informe um nº de guia válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Senha</mat-label>
                <input matInput formControlName="senhaGuia" type="text" />                
                <mat-error>Informe uma Senha válida.</mat-error>
              </mat-form-field>
            </div>            
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="!form.valid || isBusy" type="submit" (click)="save()" color="primary"
          class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-procedimento-select-modal #procedimentoSelect (modalClose)="onProcedimentoResponse($event)"></app-procedimento-select-modal>