import { HostBinding, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  // _userFontFamily: string =  "Sylfaen";
  // _userFontSize: string = "30px";

  // @HostBinding("style.font.size")
  // get userFontSize() {
  //   return this._userFontSize;
  // }

  // @HostBinding("style.font.family")
  // get userFontFamily() {
  //   return this._userFontFamily;
  // }

  constructor() { }

  print(title: string, content: any) {
    let myHead = this.prepareHeadHtml(title);
    let myBody = this.prepareBodyHtml(content);
    let myHtml = `<!DOCTYPE html><html>${myHead}${myBody}</html>`;

    const popupWin = window.open('', '_blank');
    popupWin.document.open("text/html");

    popupWin.document.write(myHtml);

    popupWin.document.close();
    popupWin.focus();

    // setTimeout(() => {
    //   popupWin.close();  
    // }, 500);
  }

  prepareHeadHtml(title: string): string {
    let txtHtml = `
       <head>
        <title>${title}</title>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
        <style>   
          table.report-container {
            page-break-after:always;
            width: 100%;
          }
          p {
            margin: 1px; padding: 1px;
          }          
          @media print {
            .pagebreakbefore { page-break-before: always; }
            .pagebreakafter { page-break-after: always; }

            thead.report-header {
              display:table-header-group;
            }
            tfoot.report-footer {
              display:table-footer-group;
              position: fixed;            
              bottom: 0;       
            } 
          }
          .fs-10 {
              font-size: 10px;
          }  
          .fs-12 {
              font-size: 12px;
          } 
          .fs-14 {
              font-size: 14px;
          }
          .fs-16 {
              font-size: 16px;
          }
          .fs-18 {
              font-size: 18px;
          }
          .fs-20 {
              font-size: 20px;
          }
          .fs-22 {
              font-size: 22px;
          }
          .fs-24 {
              font-size: 24px;
          }
          .fs-26 {
              font-size: 26px;
          }
          .fs-28 {
              font-size: 28px;
          }
          .fs-30 {
              font-size: 30px;
          }
        </style>
      </head>   
    `;

    return txtHtml;
  }

  prepareBodyHtml(content: any): string {
    let txtHtml = `
     <body onload="window.print();">
       ${content}                           
       <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
       <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
       <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
     </body>`;

    //  <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
    //  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
    //  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>

    return txtHtml;
  }
}