import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseCrudService } from '../base-crud.service';
import { EstadoModel } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class EstadoService extends BaseCrudService<EstadoModel> {
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Estado';
  }

  getAll(): Observable<EstadoModel[]> {
    return this
      .httpClient
      .get<EstadoModel>(`${this.getApiUrl()}${this.getController()}`, this.getHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
