import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypesService } from 'projects/admin/src/domain/services';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { AtendimentoReceitaModel } from '../../../domain/models';
import { AtendimentoReceitaService } from '../../../domain/services';

@Component({
  selector: 'app-atendimento-receita-historico-modal',
  templateUrl: './atendimento-receita-historico-modal.component.html'
})

export class AtendimentoReceitaHistoricoModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  @Input('atendimentoId') atendimentoId: string;
  @Input('profissionalId') profissionalId: string;
  @Input('pacienteId') pacienteId: string;
  @Input('detailMode') detailMode: boolean;
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() cloneReceita: EventEmitter<AtendimentoReceitaModel> = new EventEmitter<AtendimentoReceitaModel>();

  listaReceitaDto: any[] = [];

  constructor(
    injector: Injector,
    readonly service: AtendimentoReceitaService,
    readonly typesService: TypesService,
    readonly sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  async show() {
    this.autoSave.emit();

    this.modal.show();
    this.commonService.spinnerOpen("Pesquisando Histórico de Receita...");
    const lista = await this.service.getByPacienteId(this.pacienteId).toPromise();
    if (this.atendimentoId){
      this.listaReceitaDto = lista.filter(x => x.atendimentoId !== this.atendimentoId);
    } else {
      this.listaReceitaDto = lista;
    }

    this.listaReceitaDto.forEach(item => {
      //injetando campo dinâmico para apresentação
      item['descricaoHtml'] = this.commonService.formatPlaintextToHtml(item.descricao);
    });

    this.commonService.spinnerClose();
  }

  async clonar(receita: any) {
    let mensaTitulo = "";
    let mensaCorpo = "Clonar receita selecionada?";
    let mensaTipo: any = "question";

    if (receita.profissionalId !== this.profissionalId){
      mensaTitulo = `Receita com origem profissional: ${receita.profissionalSaudeNome}`;
      mensaTipo = "warning";
    }

    let result = await this.commonService.mensagemConfirmacao(mensaTitulo, mensaCorpo, mensaTipo);
    if (result) {
      this.close();
      this.cloneReceita.emit(receita);
    }
  }

  close() {
    this.modal.hide();
  }
}
