import { Injectable, Injector } from "@angular/core";
import { TenantModel } from "projects/ProjetoBaseAngular/domain/models";
import { BaseCrudService } from "projects/ProjetoBaseAngular/domain/services/base-crud.service";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends BaseCrudService<TenantModel> {
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Empresa';
  }

  getEmpresa(): Observable<TenantModel> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetEmpresa`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }  

  edit(model: TenantModel): Observable<TenantModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/Edit`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}