import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { ClassificacaoAgendamentoService } from '../../domain/services';
import { AgendaDisponibilidadeEventualModel } from '../../domain/models';
import { TypesService } from 'projects/admin/src/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { AgendaDisponibilidadeEventualService } from '../../domain/services/agenda-disponibilidade-eventual.service';

@Component({
  selector: 'app-agenda-disponibilidade-eventual-modal',
  templateUrl: './agenda-disponibilidade-eventual-modal.component.html'
})
export class AgendaDisponibilidadeEventualModalComponent extends BaseCrudModalComponent<AgendaDisponibilidadeEventualModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  private recursoId: string;
  classificacoes: SelectDto[];

  constructor(
    injector: Injector,
    service: AgendaDisponibilidadeEventualService,
    readonly typesService: TypesService,
    readonly classificacaoService: ClassificacaoAgendamentoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.classificacaoService.getSelectList().subscribe(x => this.classificacoes = x);
  }

  protected initializeForm(model: AgendaDisponibilidadeEventualModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,            
      profissionalRecursoId: [this.recursoId, [        
        Validators.required
      ]],
      classificacaoId: [model.classificacaoId, [        
        Validators.required
      ]],
      data: [this.fromJsonDate(model.data), Validators.required],
      horarioInicial: [model.horarioInicial, [        
        Validators.required       
      ]],
      horarioFinal: [model.horarioFinal, [        
        Validators.required
      ]],
      duracao: [model.duracao, [        
        Validators.required
      ]],
    });
  }

  async showCreate(model: AgendaDisponibilidadeEventualModel) {
    this.recursoId = model.profissionalRecursoId;
    super.showCreate(model);    
  }

  async showEditByRecursoId(id, profissionalRecursoId) {
    this.recursoId = profissionalRecursoId;
    super.showEdit(id);    
  }
}
