import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseCrudService } from '../base-crud.service';
import { UsuarioModel } from '../../models/usuario/usuario-model';
import { SelectDto } from '../../models/select-dto';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseCrudService<UsuarioModel> {
  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Usuario';
  }

  getAll(): Observable<UsuarioModel[]> {
    return this
      .httpClient
      .get<UsuarioModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  editPassword(model: UsuarioModel): Observable<UsuarioModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/EditPassword`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  editSuperUsuario(model: UsuarioModel): Observable<UsuarioModel> {
    return this.httpClient.post(`${this.getApiUrl()}${this.getController()}/EditSuperUsuario`, JSON.stringify(model), this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
  
  getSelectList(): Observable<SelectDto[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectList`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}
