<div #relatorio class="visible-print">
  <table class="report-container">
    <thead class="report-header">
      <tr>
        <th>
          <app-report-header></app-report-header>
        </th>
      </tr>
    </thead>
    <tbody class="report-content">
      <tr>
        <td>
          <h4 class="text-center font-weight-bold">Atestado</h4>
          <br>
          <h4>Data: {{dataAtual}}</h4>
          <br>
          <br>
          <div class="row">
            <div class="col-12">
              <h4 style="white-space: pre-line;">
                {{atestado}}
              </h4>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot class="report-footer">
      <tr>
        <td>
          <div class="container">
            <div class="row mt-5 pt-5">
              <div class="col-12 text-center">
                <span>_________________________________________________________________________</span>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12 text-center">
                <h4>
                  <strong>{{profissionalSaude?.pessoa.nomeFantasia + ' CRM ' + profissionalSaude?.crmMedico}}</strong>
                </h4>
              </div>
            </div>
            <app-report-footer></app-report-footer>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>