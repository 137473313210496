import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { BaseCrudService } from "projects/ProjetoBaseAngular/domain/services/base-crud.service";
import { WhatsChatMsgModel } from "../models";
import { WhatsChatMsgType } from "../types";
import { WaMessageDownloadResultDto } from "projects/ProjetoBaseAngular/domain/models/whatsAppApi/models";

@Injectable({
  providedIn: 'root'
})
export class WhatsChatMsgService extends BaseCrudService<WhatsChatMsgModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'WhatsChatMsg';
  }

  downloadImage(chatId: string): Observable<WaMessageDownloadResultDto> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/DownloadImage/${chatId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getByChatId(chatId: string): Observable<WhatsChatMsgModel[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetByChatId/${chatId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSelectListByChatId(chatId: string): Observable<any[]> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSelectListByChatId/${chatId}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}