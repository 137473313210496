import { Component, OnInit, ViewChild, Injector, Input, Output, EventEmitter } from '@angular/core';

import { BaseCrudComponent } from 'projects/ProjetoBaseAngular/app/base-crud.component';
import { AtendimentoProcedimentoModalComponent } from './atendimento-procedimento-modal/atendimento-procedimento-modal.component';
import { AtendimentoProcedimentoModel } from '../../domain/models';
import { AtendimentoProcedimentoService } from '../../domain/services';
import { DataTablesRequest, ModelSearch } from 'projects/ProjetoBaseAngular/domain/models';
import { OptionSearchType, SearchType } from 'projects/ProjetoBaseAngular/domain/types';

@Component({
  selector: 'app-atendimento-procedimento',
  templateUrl: './atendimento-procedimento.component.html'
})
export class AtendimentoProcedimentoComponent extends BaseCrudComponent<AtendimentoProcedimentoModel> implements OnInit {
  @ViewChild('modal') modal: AtendimentoProcedimentoModalComponent;

  @Input('atendimentoId') atendimentoId: string;
  @Input('convenioId') convenioId: string;
  @Input('profissionalRecursoId') profissionalRecursoId: string;
  @Input('profissionalExecutanteId') profissionalExecutanteId: string;
  @Input('detailMode') detailMode: boolean;

  @Output() itemUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  selectColumns = [
    { data: 'procedimento.nome' },
    { data: 'vlrUnitario' },
    { data: 'vlrAcrescimo' },
    { data: 'vlrDesconto' },
    { data: 'vlrTotal' }
  ];

  constructor(
    injector: Injector,
    readonly service: AtendimentoProcedimentoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dtOptions.paging = false;
    this.dtOptions.info = false;
    this.dtOptions.dom = '';
  }

  protected beforeDtRequest(dataTablesParameters: DataTablesRequest): DataTablesRequest {
    dataTablesParameters.searches = [];
    dataTablesParameters.searches.push(new ModelSearch('atendimentoId', OptionSearchType.Equals, SearchType.Guid, this.atendimentoId));

    return super.beforeDtRequest(dataTablesParameters);
  }

  create() {
    this.autoSave.emit();
    if (this.atendimentoId) {
      this.setModalProfissionalId();
      super.create();
    }
  }

  edit(model: AtendimentoProcedimentoModel) {
    this.autoSave.emit();
    this.setModalProfissionalId();
    super.edit(model);
  }

  setModalProfissionalId() {
    if (this.profissionalExecutanteId) {
      //É agenda de equipamento
      //pegar o profissional executante
      this.modal.profissionalId = this.profissionalExecutanteId;
    } else {
      //É agenda de profissional
      this.modal.profissionalId = this.profissionalRecursoId;
    }
  }

  afterDelete(id: string) {
    super.afterDelete(id);
    this.itemUpdate.emit();
  }

  onResponse(id: string) {
    super.onResponse(id);
    this.itemUpdate.emit();
  }
}
