import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { ProfissionalSaudeTextoPadraoModel } from '../../../equipe/domain/models';
import { ProcedimentoPerguntaService } from '../../domain/services';
import { LaudoMontarAddTextoComponent } from './laudo-montar-add-texto/laudo-montar-add-texto.component';

export interface ProcedimentoRespostaDto {
  perguntaCodigo: number;
  pergunta: string;
  resposta?: number;
}

@Component({
  selector: 'app-laudo-montar-modal',
  templateUrl: './laudo-montar-modal.component.html'
})
export class LaudoMontarModalComponent extends BaseComponent implements OnInit {
  respostas: ProcedimentoRespostaDto[];
  textos: ProfissionalSaudeTextoPadraoModel[] = [];

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) private data: any,
    readonly dialogRef: MatDialogRef<LaudoMontarModalComponent>,
    readonly dialog: MatDialog,
    readonly procedimentoPerguntaService: ProcedimentoPerguntaService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.respostas = [];
    this.procedimentoPerguntaService
      .getByProcedimentoId(this.data.procedimentoId)
      .subscribe(procedimentoPerguntas => {
        procedimentoPerguntas.forEach(x => {
          const resposta: ProcedimentoRespostaDto = { perguntaCodigo: x.codigo, pergunta: x.pergunta, resposta: null };
          this.respostas.push(resposta);
        });
      });
  }

  montarLaudo() {    
    if (this.respostas.length > 0) {
      if (this.respostas.filter(x => x.resposta === null).length > 0) {
        this.commonService.mensagem('Oops! Algo deu errado', 'Preencha todos as respostas na aba "Resultado de Exames"', 'warning');
        return;
      }
      this.procedimentoPerguntaService.montarResultado(this.data.procedimentoId, this.respostas).subscribe(
        (resultado) => {        
          this.montarResultadoFinal(resultado);
        },
        (errors) => {
          this.commonService.spinnerClose();
          this.commonService.mensagem('Oops! Algo deu errado', errors.join('/n'), 'error');
        }
      );
    } else {
      this.montarResultadoFinal();
    } 
  }

  montarResultadoFinal(resultado: string = '') {
    if (this.textos.length > 0){      
      this.textos.forEach(x => {
        resultado += `${x.texto}<br>`;
      });
    }   
    resultado = `<p>${resultado}</p>`;     
    this.dialogRef.close(resultado);
  }

  adicionarTexto() {
    const dialogRef = this.dialog.open(LaudoMontarAddTextoComponent, {
      width: '600px',
      data: { profissionalId: this.data.profissionalId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result.texto);
        
        this.textos.push(result);
      }
    });
  }

  removerTexto(id: string) {
    const index = this.textos.findIndex(x => x.id === id);
    this.textos.splice(index, 1);    
  }
}
