import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';

import { CategoriaFinanceiraModule } from './categoria-financeira/categoria-financeira.module';
import { GrupoCategoriaFinanModule } from './grupo-categoria-finan/grupo-categoria-finan.module';
import { GrupoDreModule } from './grupo-dre/grupo-dre.module';
import { CentroCustoModule } from './centro-custo/centro-custo.module';
import { ContaModule } from './conta/conta.module';

@NgModule({
  imports: [
    SharedModule,
    CategoriaFinanceiraModule,
    GrupoCategoriaFinanModule,
    GrupoDreModule,
    CentroCustoModule,
    ContaModule
  ]
})
export class BaseFinanceiroModule { }
