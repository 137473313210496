import { Injectable, Injector } from '@angular/core';

import { BaseCrudService } from 'projects/ProjetoBaseAngular/domain/services/base-crud.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BlocoModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class BlocoService extends BaseCrudService<BlocoModel> {

  constructor(injector: Injector) {
    super(injector);
  }

  public getController(): string {
    return 'Bloco';
  }

  get(tipoBloco, posicaoBloco): Observable<BlocoModel[]> {
    return this
      .httpClient
      .get<BlocoModel>(`${this.getApiUrl()}${this.getController()}/${tipoBloco}/${posicaoBloco}`, this.getHeaderJson())
      .pipe(
        map(this.extractData)
      );
  }

  getAll(): Observable<BlocoModel[]> {
    return this
      .httpClient
      .get<BlocoModel>(`${this.getApiUrl()}${this.getController()}`, this.getAuthHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

}
