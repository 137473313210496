<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-xl">
    <div class="card modal-content">
      <div class="header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>Histórico - Solicitação de Exames</h2>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="listaExameDto?.length > 0">
          <div [ngClass]="{'col-md-6': listaExameDto.length > 2, 'col-md-5': listaExameDto.length <= 2}"
            *ngFor="let exameDto of listaExameDto">
            <div class="card">
              <div class="header" class="header bg-blue">
                <h2>{{exameDto.dataInclusao | date: 'dd/MM/yyyy'}} - ({{exameDto.profissionalSaudeNome}})
                </h2>
              </div>
              <div class="body" style="height: 100px; overflow-y: auto; padding: 0px; padding-left: 5px;" [innerHTML]="sanitizer.bypassSecurityTrustHtml(exameDto.descricaoHtml)">
              </div>
              <div class="footer">
                <div class="row">
                  <div class="col-sm-12">
                    <button mat-icon-button type="button"                      
                      (click)="examePrint.print(exameDto)"
                      matTooltip="Imprimir Receita">
                      <mat-icon>print</mat-icon>
                    </button>
                    <button [disabled]="!profissionalId" mat-icon-button type="button" 
                      (click)="clonar(exameDto)"
                      matTooltip="Clonar Solicitação de Exame">
                      <mat-icon>note_add</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!(listaExameDto?.length > 0)">
          <h4 class="text-center">Não foram encontrados registros históricos de solicitação de exames.</h4>
        </div>
      </div>
      <div class="footer">
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-atendimento-solic-exame-print #examePrint>
</app-atendimento-solic-exame-print>